import styled from '@emotion/styled';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';

export const TitleIcon = styled(ExclamationCircleOutlined)`
  color: #faad14;
  margin-right: 16px;
  font-size: 22px;
`;

export const TitleText = styled.span`
  font-size: 16px;
`;

export const ContentText = styled.div`
  font-size: 14px;
  margin: 8px 0 0 38px;
`;

export const ContentContainer = styled(Row)`
  margin-top: 16px !important;
  margin-left: 32px !important;
`;

export const CompletedButton = styled(Button)`
  background-color: ${props => props.theme.color.white};
  border-color: ${props => props.theme.color.red};
  color: ${props => props.theme.color.red};

  &:hover {
    background-color: ${props => props.theme.color.white};
    border-color: ${props => props.theme.color.red};
    color: ${props => props.theme.color.red};
  }
`;

export const CancelledButton = styled(Button)`
  background-color: ${props => props.theme.color.white};
  border-color: ${props => props.theme.color.red};
  color: ${props => props.theme.color.red};

  &:hover {
    background-color: ${props => props.theme.color.white};
    border-color: ${props => props.theme.color.red};
    color: ${props => props.theme.color.red};
  }
`;

export const ViewOrdersButton = styled(Button)`
  background-color: ${props => props.theme.color.white};
  border-color: ${props => props.theme.color.tertiary};
  color: ${props => props.theme.color.tertiary};

  &:hover {
    background-color: ${props => props.theme.color.white};
    border-color: ${props => props.theme.color.tertiary};
    color: ${props => props.theme.color.tertiary};
  }
`;
