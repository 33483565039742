import { useMemo, useState } from 'react';
import { Alert, Button, Col, Divider, Form, message, Row, Skeleton, Tag } from 'antd';
import { MinusOutlined, PlusOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import FormInput from 'components/FormInput/FormInput';
import OutOfStockLabel from 'components/OutOfStockLabel/OutOfStockLabel';
import ImagePlaceholder from 'images/no-image-placeholder.jpg';
import { patchCheckoutOrder } from 'apis/external';

import { useLocationQuery } from 'hooks/router';

import {
  OriPricingInfoLabel,
  PhotoCol,
  PricingInfoLabel,
  ProductDesc,
  ProductKeyword,
  ProductKeywordWithVariances,
  ProductName,
  ProductNameWithVariances,
  QtyButtonGroupContainer,
  QtyButton,
  StyledImagesPreview,
  ContainerModal
} from './FeatureProductDetailsModal.styles';

const { useForm } = Form;

const ProductInfo = ({ name, keyword, desc, tags, isVariance = false }) => {
  return (
    <>
      {!isVariance ? <ProductName>{name}</ProductName> : <ProductNameWithVariances>{name}</ProductNameWithVariances>}
      {!isVariance ? <ProductKeyword>{keyword}</ProductKeyword> : <ProductKeywordWithVariances>{keyword}</ProductKeywordWithVariances>}
      {desc && <ProductDesc>{desc}</ProductDesc>}
      {tags &&
        tags.map(tag => (
          <Tag key={tag.storeMsCategoryId} style={{ marginBottom: '12px' }}>
            #{tag.label}
          </Tag>
        ))}
    </>
  );
};

const PricingAndAddToCart = ({
  form,
  formInputName,
  priceAmt,
  oriPrice,
  inventory,
  limitQty,
  minPurchaseQty,
  spaceTop = '24px',
  hasStock,
  existingQuantity,
  onChangeQtyByBtn
}) => {
  const { t } = useTranslation(['pageCheckout']);
  const handleOnClickAddQty = () => {
    const formValue = form.getFieldsValue([formInputName]);
    const currentValue = formValue[formInputName];
    const newValue = !currentValue ? 1 : Number(currentValue) + 1;

    form.setFieldsValue({ [formInputName]: newValue });
    form.validateFields([formInputName]);

    onChangeQtyByBtn(formInputName, newValue);
  };

  const handleOnClickDeductQty = () => {
    const formValue = form.getFieldsValue([formInputName]);
    const currentValue = formValue[formInputName];
    const newValue = currentValue > 1 ? Number(currentValue) - 1 : undefined;

    if (!currentValue) {
      return;
    } else {
      form.setFieldsValue({ [formInputName]: newValue });
      form.validateFields([formInputName]);

      onChangeQtyByBtn(formInputName, newValue);
    }
  };

  return (
    <Row justify="space-between" style={{ marginTop: spaceTop }}>
      <Col>
        {oriPrice && <OriPricingInfoLabel amount={oriPrice} />}
        <PricingInfoLabel amount={priceAmt} hasOriPrice={!!oriPrice} />
      </Col>
      <Col span={24} sm={12}>
        {hasStock ? (
          <QtyButtonGroupContainer style={{ width: '100%' }}>
            <QtyButton style={{ marginRight: '8px' }} icon={<MinusOutlined />} onClick={handleOnClickDeductQty} />
            <FormInput
              name={formInputName}
              placeholder="e.g.: 2"
              isNumeric
              onChange={() => {
                form.validateFields([formInputName]);
              }}
              extraRules={[
                {
                  validator: (_, value) => {
                    if (Number(value) > inventory) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                  message: t('pageCheckout:product-error-message-not-enough-stock', { inventory })
                },
                {
                  validator: (_, value) => {
                    if (!!limitQty && Number(value) > limitQty) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                  message: t('pageCheckout:product-error-message-total-purchase-limit', { limitQty })
                },
                {
                  validator: (_, value) => {
                    if (existingQuantity + Number(value) < minPurchaseQty) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                  message: t('pageCheckout:product-error-message-minimum-purchase-limit', { minPurchaseQty })
                },
                {
                  validator: (_, value) => {
                    if (!!value && Number(value) < 1) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                  message: t('pageCheckout:product-error-message-invalid-quantity')
                }
              ]}
            />
            <QtyButton style={{ marginLeft: '8px' }} icon={<PlusOutlined />} onClick={handleOnClickAddQty} />
          </QtyButtonGroupContainer>
        ) : (
          <OutOfStockLabel />
        )}
        {existingQuantity > 0 && <Alert type="success" message={t('pageCheckout:order-existing-product-quantity-alert', { existingQuantity })} />}
      </Col>
    </Row>
  );
};

const MainProductView = ({ form, selectedFeaturedProduct, existingQuantity, onChangeQtyByBtn }) => {
  const { t } = useTranslation(['pageCheckout']);

  const hasStock = selectedFeaturedProduct.inventory > 0;
  const productPhotoUrl = selectedFeaturedProduct.coverPhoto?.original?.url || selectedFeaturedProduct.coverPhotoFile?.url;

  const productPhotoUrls =
    selectedFeaturedProduct.photos?.map(photo => {
      return photo.original.url;
    }) ||
    selectedFeaturedProduct.photoFiles?.map(photoFile => {
      return photoFile.url;
    });

  return (
    <Row gutter={32} style={{ margin: 0 }}>
      <PhotoCol span={24} md={8}>
        <StyledImagesPreview
          thumbnailImageUrl={productPhotoUrl || ImagePlaceholder}
          previewImageUrl={productPhotoUrl || ImagePlaceholder}
          imageUrls={productPhotoUrls}
        />
      </PhotoCol>
      <Col span={24} md={16}>
        <ProductInfo
          t={t}
          name={selectedFeaturedProduct.label}
          keyword={selectedFeaturedProduct.keyword}
          desc={selectedFeaturedProduct.description}
          tags={selectedFeaturedProduct.msCategories}
        />
        <PricingAndAddToCart
          form={form}
          formInputName="quantity"
          priceAmt={selectedFeaturedProduct.msPrice}
          oriPrice={selectedFeaturedProduct.oriPrice}
          inventory={selectedFeaturedProduct.inventory}
          limitQty={selectedFeaturedProduct.purchaseLimit}
          minPurchaseQty={selectedFeaturedProduct.minPurchaseLimit}
          hasStock={hasStock}
          existingQuantity={existingQuantity}
          onChangeQtyByBtn={onChangeQtyByBtn}
        />
      </Col>
    </Row>
  );
};

const ProductWithVariancesCompactView = ({
  form,
  selectedFeaturedProduct,
  selectedVariance,
  existingOrderProducts,
  onChangeQtyByBtn,
  setSelectedVariance
}) => {
  const productPhotoUrl =
    selectedVariance && (selectedVariance.coverPhoto || selectedVariance.coverPhotoFile)
      ? selectedVariance.coverPhoto?.original?.url || selectedVariance.coverPhotoFile?.url
      : selectedFeaturedProduct.coverPhoto?.original?.url || selectedFeaturedProduct.coverPhotoFile?.url;

  const productPhotoUrls =
    (selectedVariance ? selectedVariance : selectedFeaturedProduct).photos?.map(photo => {
      return photo.original.url;
    }) ||
    (selectedVariance ? selectedVariance : selectedFeaturedProduct).photoFiles?.map(photoFile => {
      return photoFile.url;
    });

  const existingQuantity =
    existingOrderProducts.find(existingOrderProduct => existingOrderProduct.varianceId === selectedVariance?._id)?.quantity || 0;

  return (
    <>
      <Row gutter={[32, 8]} style={{ margin: 0 }}>
        <Col span={24}>
          <ProductName>{selectedFeaturedProduct.label}</ProductName>
        </Col>
        <Col span={24} md={8}>
          <PhotoCol span={24} style={{ height: '100%' }}>
            <StyledImagesPreview
              thumbnailImageUrl={productPhotoUrl || ImagePlaceholder}
              previewImageUrl={productPhotoUrl || ImagePlaceholder}
              imageUrls={productPhotoUrls}
            />
          </PhotoCol>
        </Col>
        <Col span={24} md={16}>
          <ProductInfo
            name={selectedVariance ? selectedVariance.label : ''}
            keyword={selectedVariance ? `${selectedFeaturedProduct.keyword}${selectedVariance.keyword}` : selectedFeaturedProduct.keyword}
            keywordComment={selectedVariance ? `${selectedFeaturedProduct.keyword}${selectedVariance.keyword}` : ''}
            desc={selectedVariance ? selectedVariance.description : selectedFeaturedProduct.description}
            tags={selectedVariance ? [] : selectedFeaturedProduct.msCategories}
            isVariance={!!selectedVariance}
          />
          {selectedVariance && (
            <PricingAndAddToCart
              form={form}
              formInputName="quantity"
              priceAmt={selectedVariance.msPrice}
              oriPrice={selectedVariance.oriPrice}
              inventory={selectedVariance.inventory}
              limitQty={selectedVariance.purchaseLimit}
              minPurchaseQty={selectedVariance.minPurchaseLimit}
              hasStock={selectedVariance.inventory > 0}
              existingQuantity={existingQuantity}
              onChangeQtyByBtn={onChangeQtyByBtn}
            />
          )}
        </Col>
        <Col span={24}>
          <Divider style={{ margin: '12px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {selectedFeaturedProduct.variances.map(variance => {
              return (
                <Button
                  type="primary"
                  style={{ margin: '0 8px 16px' }}
                  ghost={selectedVariance?._id !== variance._id}
                  disabled={!(variance.inventory > 0)}
                  onClick={() => {
                    if (selectedVariance?._id === variance._id) {
                      setSelectedVariance(null);
                      return;
                    }
                    setSelectedVariance(variance);
                  }}
                >
                  {variance.label}
                </Button>
              );
            })}
          </div>
        </Col>
      </Row>
    </>
  );
};

const FeatureProductDetailsModal = ({ isLoading, selectedFeaturedProduct, orderProducts, onAfterAddToCart, onCancel }) => {
  const { t } = useTranslation(['pageCheckout']);
  const [form] = useForm();
  const { orderNumber } = useParams();
  const { accessCode } = useLocationQuery();

  const [isAllowAddToCart, setIsAllowAddToCart] = useState(false);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [selectedVariance, setSelectedVariance] = useState(null);

  const existingOrderProducts = useMemo(
    () => (isLoading ? [] : orderProducts.filter(orderProduct => orderProduct.productId === selectedFeaturedProduct._id)),
    [isLoading, orderProducts, selectedFeaturedProduct]
  );

  const totalExistingQuantity = existingOrderProducts.reduce((total, orderProduct) => {
    return (total += orderProduct.quantity);
  }, 0);

  const handleOnClickAddToCart = async e => {
    e.preventDefault();
    setIsAddingToCart(true);
    try {
      const formValues = await form.validateFields();
      await patchCheckoutOrder(
        { accessCode, orderNumber },
        { ...formValues, productId: selectedFeaturedProduct._id, varianceId: selectedVariance?._id }
      );
      message.success(t('pageCheckout:order-display-desc-label-add-to-cart-success'));
      setIsAddingToCart(false);
      onAfterAddToCart();
      onCancel();
    } catch (error) {
      error && error.errorFields && error.errorFields.forEach(field => message.error(field.errors[0]));
      setIsAddingToCart(false);
    }
  };

  const handleOnChangeQtyByBtn = (fieldName, newValue) => {
    const allFieldsValue = {
      ...form.getFieldsValue(),
      [fieldName]: newValue
    };
    const foundFormWithValue = Object.values(allFieldsValue).find(formProductQty => !!formProductQty && Number(formProductQty) > 0);
    setIsAllowAddToCart(!!foundFormWithValue);
  };

  return (
    <ContainerModal
      visible={true}
      onCancel={onCancel}
      footer={
        <Row gutter={8}>
          <Col flex="auto">
            <Button
              type="primary"
              ghost
              block
              icon={<ShoppingCartOutlined />}
              onClick={handleOnClickAddToCart}
              loading={isAddingToCart}
              disabled={isLoading || isAddingToCart || !isAllowAddToCart || selectedFeaturedProduct.inventory <= 0}
            >
              {t('pageCheckout:add-to-cart-button')}
            </Button>
          </Col>
        </Row>
      }
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          onValuesChange={(_, allValues) => {
            const foundFormWithValue = Object.values(allValues).find(formProductQty => !!formProductQty && Number(formProductQty) > 0);
            setIsAllowAddToCart(!!foundFormWithValue);
          }}
        >
          {selectedFeaturedProduct.variances && selectedFeaturedProduct.variances.length > 0 ? (
            <ProductWithVariancesCompactView
              form={form}
              selectedFeaturedProduct={selectedFeaturedProduct}
              selectedVariance={selectedVariance}
              existingOrderProducts={existingOrderProducts}
              onChangeQtyByBtn={handleOnChangeQtyByBtn}
              setSelectedVariance={setSelectedVariance}
            />
          ) : (
            <MainProductView
              form={form}
              selectedFeaturedProduct={selectedFeaturedProduct}
              existingQuantity={totalExistingQuantity}
              onChangeQtyByBtn={handleOnChangeQtyByBtn}
            />
          )}
        </Form>
      )}
    </ContainerModal>
  );
};

export default FeatureProductDetailsModal;
