import styled from '@emotion/styled';
import { Card } from 'antd';

export const ContainerCard = styled(Card)`
  margin: auto;
`;

export const SubTitleText = styled.p`
  font-size: 16px;
`;

export const AlertText = styled(SubTitleText)`
  font-weight: bold;
  color: ${props => props.theme.color.secondary};
`;
