import { Card, Col, Row } from 'antd';

import {
  CoverImageContainer,
  InfoText,
  ProductLabelText,
  ProductDescText,
  ProductQuantityAndPriceContainer,
  PriceContainer,
  FinalPrice,
  OriPrice
} from './ProductCard.styles';

const ProductCard = ({
  label,
  description,
  keyword,
  quantity,
  total,
  oriPrice,
  previewImageUrl,
  thumbnailImageUrl,
  storeCurrency,
  openProductDetailsModal
}) => {
  return (
    <Card
      hoverable
      bodyStyle={{ padding: '16px 8px' }}
      cover={previewImageUrl && <CoverImageContainer coverPhotoUrl={thumbnailImageUrl} />}
      onClick={() => openProductDetailsModal({ label, description, keyword, quantity, total, oriPrice, previewImageUrl, thumbnailImageUrl })}
    >
      <Card.Meta
        description={
          <Row>
            <Col span={24}>
              <ProductLabelText>{label}</ProductLabelText>
            </Col>
            <Col span={24}>
              <ProductDescText>{description}</ProductDescText>
            </Col>
            <Col span={12}>
              <InfoText>{keyword}</InfoText>
            </Col>
            <ProductQuantityAndPriceContainer span={12}>
              <InfoText>x{quantity}</InfoText>
              <PriceContainer>
                <FinalPrice storeCurrency={storeCurrency} amount={total} hasOriPrice={!!oriPrice} />
                {!!oriPrice && <OriPrice storeCurrency={storeCurrency} amount={oriPrice * quantity} />}
              </PriceContainer>
            </ProductQuantityAndPriceContainer>
          </Row>
        }
      />
    </Card>
  );
};

export default ProductCard;
