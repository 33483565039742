import { useState, useCallback, useEffect } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import PhotoManager from 'components/PhotoManager/PhotoManager';
import { withAppContext } from 'contexts/AppContext/AppContext';

import { patchUpdatePostById, useGetPostPhotos } from 'apis/post';

const { error } = Modal;

export const UploadPhotosModal = withAppContext(({ store, visible, isLoading, initialPhotos, onOk, onCancel, photosRef }) => {
  const { t } = useTranslation(['common', 'compUploadPostPhotos']);
  const [isCompressing, setIsCompressing] = useState(false);
  const [currentPhotos, setCurrentPhotos] = useState(initialPhotos || []);

  const [isInit, setIsInit] = useState(false);

  const handleOnOk = useCallback(() => {
    const shouldUpdate = JSON.stringify(currentPhotos) !== JSON.stringify(initialPhotos);
    onOk(shouldUpdate);
  }, [currentPhotos, initialPhotos, onOk]);

  useEffect(() => {
    if (visible && !isInit) {
      setIsInit(true);
      setCurrentPhotos(initialPhotos || []);
    }
  }, [isInit, visible, initialPhotos]);

  return (
    <Modal
      title={t('compUploadPostPhotos:modal-title')}
      visible={visible}
      okText={t('common:form-submit-update-button-text')}
      cancelText={t('common:modal-cancel-text')}
      destroyOnClose
      onOk={handleOnOk}
      onCancel={onCancel}
      okButtonProps={{ loading: isLoading || isCompressing }}
      cancelButtonProps={{ loading: isLoading || isCompressing }}
      maskClosable={false}
      afterClose={() => {
        setCurrentPhotos([]);
        setIsInit(false);
      }}
    >
      <Alert type="info" message={t('compUploadPostPhotos:alert-message-upload-photos')} style={{ marginBottom: '12px' }} />
      <PhotoManager
        value={currentPhotos}
        onChange={setCurrentPhotos}
        onStatusChange={setIsCompressing}
        buttonLabel={t('pageProductDetails:form-submit-cover-photo-upload-button-text')}
        fileSizeThresholdToCompressInKb={200}
        ref={photosRef}
        cropAspect={9 / 16}
        multiple
        shouldRemoveDocsMarkedForDelete
        storeId={store._id}
      />
    </Modal>
  );
});

export const UploadPhotosTriggerButton = ({ isMinimal, isLoading, isDisabled, onClick }) => {
  const { t } = useTranslation(['compUploadPostPhotos']);

  return (
    <Button type="primary" block icon={<UploadOutlined />} loading={isLoading} onClick={onClick} disabled={isDisabled}>
      {!isMinimal && t('compUploadPostPhotos:button-text-label-photos')}
    </Button>
  );
};

export const UploadPhotosTriggerLinkButton = ({ isLoading, isDisabled, onClick }) => {
  const { t } = useTranslation(['compUploadPostPhotos']);

  return (
    <Button type="link" icon={<UploadOutlined />} loading={isLoading} onClick={onClick} disabled={isDisabled}>
      {t('compUploadPostPhotos:button-link-text-label-photos')}
    </Button>
  );
};

export const useUploadPhotosModal = ({ updatePostPhotos }) => {
  const { t } = useTranslation(['common']);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [postId, setPostId] = useState(undefined);

  const { data: photos = [], isLoading: isPhotosLoading, refetch: refetchPhotos } = useGetPostPhotos(postId, { shouldFetch: isModalVisible });

  const openModal = useCallback(selectedPostId => {
    setIsModalVisible(true);
    setPostId(selectedPostId);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
    setPostId(undefined);
  }, []);

  const handleOnOk = useCallback(
    async shouldUpdate => {
      if (!shouldUpdate) {
        closeModal();
      } else {
        setIsModalLoading(true);

        const updatePayload = { photos: await updatePostPhotos() };

        const updatePhotoPromise = patchUpdatePostById(postId, updatePayload);

        updatePhotoPromise
          .then(() => {
            message.success(t('common:update-success-message'));
            refetchPhotos();
          })
          .catch(ex => {
            error({
              title: ex.message
            });
          })
          .finally(() => {
            setIsModalLoading(false);
            closeModal();
          });
      }
    },
    [t, postId, refetchPhotos, closeModal, updatePostPhotos]
  );

  const handleOnOpen = useCallback(
    selectedPostId => () => {
      openModal(selectedPostId);
    },
    [openModal]
  );

  const handleOnClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleOnFileUploadChange = useCallback(isUploading => {
    setIsModalLoading(isUploading);
  }, []);

  return {
    isUploadPhotosModalLoading: isModalLoading,
    isUploadPhotosTriggerBtnLoading: isPhotosLoading,
    isUploadPhotosModalVisible: isModalVisible && !isPhotosLoading,

    photos,

    handleOnConfirmUploadPhotos: handleOnOk,
    handleOnOpenUploadPhotosModal: handleOnOpen,
    handleOnCloseUploadPhotosModal: handleOnClose,
    handleOnFileUploadPhotosModalChange: handleOnFileUploadChange
  };
};
