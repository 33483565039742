import { useState, useEffect, createContext, useCallback } from 'react';

import { REACT_APP_FB_APP_ID } from 'config/env';
import { useScript } from 'hooks/utils';

const FbContext = createContext();

export const FB_LOGIN_STATUS_CONNECTED = 'connected';
export const FB_LOGIN_STATUS_NOT_AUTHORIZED = 'not_authorized';
export const FB_LOGIN_STATUS_UNKNOWN = 'unknown';

const FB_API_METHOD_GET = 'get';
// NOTE: For future use
// const FB_API_METHOD_POST = 'post';
// const FB_API_METHOD_DELETE = 'delete';

const callFbGraphApiAsync = async (pathname, apiMethod = FB_API_METHOD_GET, params) => {
  return new Promise((resolve, reject) => {
    try {
      window.FB.api(pathname, apiMethod, params, response => {
        if (response.error) {
          return reject(response.error);
        }
        return resolve(response);
      });
    } catch (ex) {
      reject(ex);
    }
  });
};

export const FbContextProvider = ({ children }) => {
  const [isFbLoaded, setIsFbLoaded] = useState(false);
  const [isFbUserConnected, setIsFbUserConnected] = useState(false);
  const [fbUser, setFbUser] = useState({});

  const status = useScript('https://connect.facebook.net/en_US/sdk.js');

  const handleOnFbAfterLogin = useCallback(response => {
    if (response.status === FB_LOGIN_STATUS_CONNECTED) {
      setIsFbUserConnected(true);
      setFbUser(response.authResponse);
    } else if ([FB_LOGIN_STATUS_NOT_AUTHORIZED, FB_LOGIN_STATUS_UNKNOWN].includes(response.status)) {
      setIsFbUserConnected(false);
      setFbUser({});
    }
  }, []);

  const getFbUserPages = useCallback(async () => {
    const userAccountRes = await callFbGraphApiAsync('/me/accounts', FB_API_METHOD_GET, {
      limit: 100,
      fields: 'id,access_token,name,instagram_business_account{id,name,username}'
    });
    return userAccountRes.data;
  }, []);

  const loginFb = useCallback(
    (callback, options) => {
      return window.FB.login(response => {
        handleOnFbAfterLogin(response);
        callback(response);
      }, options);
    },
    [handleOnFbAfterLogin]
  );

  const logoutFb = useCallback((callback, options) => {
    window.FB.logout(callback, options);
    setIsFbUserConnected(false);
    setFbUser({});
  }, []);

  const refreshFbUser = useCallback(() => {
    window.FB.getLoginStatus(response => {
      handleOnFbAfterLogin(response);
    });
  }, [handleOnFbAfterLogin]);

  useEffect(() => {
    if (status === 'ready') {
      window.FB.init({
        appId: REACT_APP_FB_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v15.0'
      });
      window.FB.AppEvents.logPageView();

      setIsFbLoaded(true);

      refreshFbUser();
    }
  }, [status, refreshFbUser]);

  return (
    <FbContext.Provider value={{ isFbLoaded, isFbUserConnected, fbUser, refreshFbUser, loginFb, logoutFb, getFbUserPages }}>
      {children}
    </FbContext.Provider>
  );
};

export const FbContextConsumer = FbContext.Consumer;

export const withFbContext = Component => {
  const FbContextComponent = props => <FbContextConsumer>{fbContextProps => <Component {...fbContextProps} {...props} />}</FbContextConsumer>;
  return FbContextComponent;
};
