import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Col, Form, message, Modal, Row, Space, Tag } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';

import { patchSplitBulkOrders } from 'apis/order';
import { useGetProductSuggestions } from 'apis/product';

import FormSelection from 'components/FormSelection/FormSelection';

const { useForm } = Form;

/* =================================================== Exported Functions =================================================== */
export const useOrderSplitModal = () => {
  const [isOrderSplitModalVisible, setIsOrderSplitModalVisible] = useState(false);

  const handleOnSplitOrderBtnClick = useCallback(() => {
    setIsOrderSplitModalVisible(true);
  }, []);

  const handleOnSplitOrderModalCancel = useCallback(() => {
    setIsOrderSplitModalVisible(false);
  }, []);

  return { isOrderSplitModalVisible, handleOnSplitOrderBtnClick, handleOnSplitOrderModalCancel };
};

/* =================================================== Main Component =================================================== */
const OrderSplitModal = ({ orderStatuses, onSplitOrder, onCancel }) => {
  const { t } = useTranslation(['common', 'pageOrder']);
  const [form] = useForm();

  const [isSplitting, setIsSplitting] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');

  const { isLoading: isProductSuggestionsLoading, paginatedData: productSuggestions } = useGetProductSuggestions({
    currentPage: 1,
    limit: 100,
    filter: { label: searchKeyword },
    extraQuery: { flatVariance: true, allowDisabled: true },
    sorter: { _id: -1 }
  });

  const delaySetSearchKeyword = useMemo(() => debounce(setSearchKeyword, 300), []);
  const nonCompletedOrderStatuses = useMemo(() => orderStatuses.filter(orderStatus => !orderStatus.isEnded), [orderStatuses]);

  const handleOnConfirm = useCallback(() => {
    form.validateFields().then(({ existingStatuses, newStatus, productIdsToSplit }) => {
      setIsSplitting(true);

      Modal.confirm({
        title: <span>{t('pageOrder:modal-split-order-select-confirm-modal-title')}</span>,
        content: <span>{t('pageOrder:modal-split-order-select-confirm-modal-content')}</span>,
        onOk: () => {
          patchSplitBulkOrders({ existingStatuses, newStatus, productIdsToSplit })
            .then(() => {
              message.success(t('pageOrder:modal-split-order-select-confirm-modal-sucess'));
              onSplitOrder();
            })
            .catch(() => {
              message.error(t('pageOrder:modal-split-order-select-confirm-modal-error'));
            })
            .finally(() => {
              setIsSplitting(false);
            });
        },
        onCancel: () => setIsSplitting(false),
        width: 'fit-content',
        okText: t('pageOrder:modal-split-order-select-confirm-modal-button-confirm'),
        cancelText: t('common:modal-cancel-text')
      });
    });
  }, [t, form, onSplitOrder]);

  const handleOnBlurProductSelection = () => {
    setSearchKeyword('');
  };

  return (
    <Modal
      visible
      width="60%"
      title={t('pageOrder:modal-split-order-title')}
      okText={isSplitting ? t('pageOrder:modal-split-order-ok-button-wait') : t('pageOrder:modal-split-order-ok-button-text')}
      cancelText={t('common:modal-cancel-text')}
      confirmLoading={isSplitting}
      cancelButtonProps={{ loading: isSplitting }}
      onOk={handleOnConfirm}
      onCancel={onCancel}
    >
      <Alert
        type="warning"
        style={{ marginBottom: 20 }}
        message={
          <Space direction="vertical">
            <Row align="middle">
              <Tag color="#ffba00">
                <b>
                  <InfoCircleOutlined />
                </b>
              </Tag>
              <span>
                <b>{t('pageOrder:modal-split-order-note')}:</b>
              </span>
            </Row>
            <ul>
              <li>{t('pageOrder:modal-split-order-note-desc-1')}</li>
              <li>{t('pageOrder:modal-split-order-note-desc-2')}</li>
              <li>{t('pageOrder:modal-split-order-note-desc-3')}</li>
            </ul>
          </Space>
        }
      />
      <Form form={form}>
        <Row>
          <Col span={24}>
            <FormSelection
              name="existingStatuses"
              label={t('pageOrder:modal-split-order-select-existing-statuses-label')}
              placeholder={t('pageOrder:modal-split-order-select-existing-statuses-placeholder')}
              isMultiple
              selections={nonCompletedOrderStatuses}
              requiredErrorMessage={t('pageOrder:modal-split-order-select-existing-statuses-required-error-message')}
            />
          </Col>
          <Col span={24}>
            <FormSelection
              name="newStatus"
              label={t('pageOrder:modal-split-order-select-new-status-label')}
              placeholder={t('pageOrder:modal-split-order-select-new-status-placeholder')}
              selections={orderStatuses}
              requiredErrorMessage={t('pageOrder:modal-split-order-select-new-status-required-error-message')}
            />
          </Col>
          <Col span={24}>
            <FormSelection
              name="productIdsToSplit"
              label={t('pageOrder:modal-split-order-select-products-label')}
              placeholder={t('pageOrder:modal-split-order-select-products-placeholder')}
              isMultiple
              selections={productSuggestions ? productSuggestions : []}
              isSearching={isProductSuggestionsLoading}
              onSearch={delaySetSearchKeyword}
              onBlur={handleOnBlurProductSelection}
              requiredErrorMessage={t('pageOrder:modal-split-order-select-products-required-error-message')}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default OrderSplitModal;
