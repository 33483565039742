import styled from '@emotion/styled';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';

export const TitleContainer = styled(Row)`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const TooltipIcon = styled(InfoCircleOutlined)`
  cursor: pointer;
  color: ${props => props.theme.color.lightTertiary};
`;

export const PopoverContainer = styled.div`
  width: 200px;
`;

export const DangerUpdateContentCol = styled(Col)`
  color: #f5222d;
`;
