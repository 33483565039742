import { useMemo, useState } from 'react';
import { Button, Checkbox, Empty, message, Modal, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetLuckyDrawById, patchDeleteLuckyDrawPlayers } from 'apis/luckyDraw';
import { useFetchConstant } from 'hooks/constants';

import { guard } from 'utils/general';

import { PlayerProfileContainer, PlayerProfilePic, PlayersDisplayContainer, PlayerTag, PlayerCheckbox } from './LuckyDrawPlayersModal.styles';

const LuckyDrawPlayersModal = ({ visible, luckyDrawId, onClose }) => {
  const { t } = useTranslation(['common', 'modalLuckyDrawDetails']);
  const [deletedFbPlayers, setDeletedFbPlayers] = useState([]);
  const [deletedExtraPlayers, setDeletedExtraPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { data: luckyDrawDetails, isLoading: isLuckyDrawDetailsLoading, refetch: refetchLuckyDrawDetails } = useGetLuckyDrawById(luckyDrawId);
  const { data: luckyDrawStatuses, isLoading: isLuckyDrawStatusesLoading } = useFetchConstant('luckyDrawStatuses');

  const isEditable = !isLuckyDrawStatusesLoading && !isLuckyDrawDetailsLoading && luckyDrawDetails.status !== luckyDrawStatuses.ENDED.code;

  const fbPlayers = useMemo(() => guard(() => luckyDrawDetails.players.filter(player => player.source), []), [luckyDrawDetails]);
  const extraPlayers = useMemo(() => guard(() => luckyDrawDetails.players.filter(player => !player.source), []), [luckyDrawDetails]);

  const allPlayers = useMemo(() => guard(() => [...fbPlayers, ...extraPlayers].map(player => player._id), []), [fbPlayers, extraPlayers]);
  const allDeletedPlayers = useMemo(() => [...deletedFbPlayers, ...deletedExtraPlayers], [deletedFbPlayers, deletedExtraPlayers]);

  const handleOnToggleSelectAll = e => {
    setDeletedFbPlayers(e.target.checked ? fbPlayers.map(player => player._id) : []);
    setDeletedExtraPlayers(e.target.checked ? extraPlayers.map(player => player._id) : []);
  };

  const handleOnSave = () => {
    setIsLoading(true);
    patchDeleteLuckyDrawPlayers(luckyDrawId, allDeletedPlayers)
      .then(() => {
        message.success(t('modalLuckyDrawDetails:delete-success-message'));
        refetchLuckyDrawDetails();
      })
      .catch(ex => {
        message.error(`Something went wrong: ${ex.message}`);
      })
      .finally(() => {
        setIsLoading(false);
        setDeletedFbPlayers([]);
        setDeletedExtraPlayers([]);
      });
  };

  return (
    <Modal width="70%" visible={visible} onCancel={onClose} maskClosable={false} destroyOnClose={true} footer={null}>
      {isLuckyDrawDetailsLoading ? (
        <Skeleton active />
      ) : (
        <>
          <div>
            <Checkbox
              checked={allPlayers.length !== 0 && allDeletedPlayers.length === allPlayers.length}
              indeterminate={allDeletedPlayers.length > 0 && allDeletedPlayers.length < allPlayers.length}
              disabled={allPlayers.length === 0}
              style={{ marginBottom: '8px' }}
              onChange={handleOnToggleSelectAll}
            >
              {t('modalLuckyDrawDetails:label-select-all')}
            </Checkbox>
            <p>{t('modalLuckyDrawDetails:players-modal-fb-label', { numOfPlayers: fbPlayers.length })}</p>
            {fbPlayers.length > 0 ? (
              <PlayersDisplayContainer value={deletedFbPlayers} onChange={setDeletedFbPlayers}>
                {fbPlayers.map(fbPlayer => (
                  <PlayerTag key={fbPlayer._id}>
                    <PlayerCheckbox value={fbPlayer._id}>
                      <PlayerProfileContainer>
                        {fbPlayer.profilePicUrl && <PlayerProfilePic src={fbPlayer.profilePicUrl} />}
                        <span>{fbPlayer.name}</span>
                      </PlayerProfileContainer>
                    </PlayerCheckbox>
                  </PlayerTag>
                ))}
              </PlayersDisplayContainer>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
          <br />
          <hr />
          <br />
          <div>
            <p>{t('modalLuckyDrawDetails:players-modal-extra-label', { numOfPlayers: extraPlayers.length })}</p>
            {extraPlayers.length > 0 ? (
              <PlayersDisplayContainer value={deletedExtraPlayers} onChange={setDeletedExtraPlayers}>
                {extraPlayers.map(extraPlayer => (
                  <PlayerTag key={extraPlayer._id}>
                    <Checkbox value={extraPlayer._id}>{extraPlayer.name}</Checkbox>
                  </PlayerTag>
                ))}
              </PlayersDisplayContainer>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {isEditable && (
              <Button
                disabled={allDeletedPlayers.length === 0}
                type="primary"
                style={{ marginTop: '32px' }}
                block
                onClick={handleOnSave}
                loading={isLoading}
              >
                {t('modalLuckyDrawDetails:delete-participants-button')}
              </Button>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default LuckyDrawPlayersModal;
