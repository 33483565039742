import i18n from 'i18next';
import Backend from 'i18next-fluent-backend';
import Fluent from 'i18next-fluent';
import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';

export const localeConfigs = {
  en: {
    locale: 'en',
    label: 'English',
    default: true
  },
  zh_cn: {
    locale: 'zh-CN',
    label: '简体'
  },
  ms_my: {
    locale: 'ms-MY',
    label: 'BM'
  },
  zh_tw: {
    locale: 'zh-TW',
    label: '繁體'
  },
  id: {
    locale: 'id',
    label: 'Bahasa Indonesia'
  },
  vn: {
    locale: 'vn',
    label: 'Tiếng Việt'
  },
  th_th: {
    locale: 'th-TH',
    label: 'ไทย'
  },
  lo_la: {
    locale: 'lo-LA',
    label: 'ພາສາລາວ'
  }
};

export const supportedLocales = Object.values(localeConfigs);

export const storeCurrencyToLocaleConfigMapping = {
  MYR: { locales: [localeConfigs['en'].locale, localeConfigs['zh_cn'].locale, localeConfigs['ms_my'].locale] },
  SGD: { locales: [localeConfigs['en'].locale, localeConfigs['zh_cn'].locale] },
  THB: { locales: [localeConfigs['en'].locale, localeConfigs['th_th'].locale] },
  VND: { locales: [localeConfigs['en'].locale, localeConfigs['vn'].locale] },
  PHP: { locales: [localeConfigs['en'].locale] },
  IDR: { locales: [localeConfigs['en'].locale, localeConfigs['id'].locale] },
  TWD: { locales: [localeConfigs['en'].locale, localeConfigs['zh_tw'].locale] },
  INR: { locales: [localeConfigs['en'].locale] },
  LAK: { locales: [localeConfigs['en'].locale, localeConfigs['lo_la'].locale] },
  USD: { locales: [localeConfigs['en'].locale, localeConfigs['zh_cn'].locale] },
  GBP: { locales: [localeConfigs['en'].locale] },
  RMB: { locales: [localeConfigs['en'].locale, localeConfigs['zh_cn'].locale] },
  HKD: { locales: [localeConfigs['en'].locale, localeConfigs['zh_cn'].locale] }
};

i18n
  .use(Fluent)
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: localeConfigs.en.locale,
    supportedLngs: supportedLocales.map(supportedLocale => supportedLocale.locale),
    debug: false,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    customHeaders: () => ({
      'Cache-Control': 'no-cache'
    }),
    react: {}
  });

export default i18n;
