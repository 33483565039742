import styled from '@emotion/styled';
import { Row } from 'antd';

export const MainContainer = styled.div`
  width: 100%;
  padding: 0;

  @media (min-width: 992px) {
    padding: 0 8%;
  }

  @media (min-width: 1200px) {
    padding: 0 16%;
  }
`;

export const PageTitle = styled.div`
  text-align: center;
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: 24px;
`;

export const PlanCardsRow = styled(Row)`
  width: 100%;
  margin: 0 !important;
`;

export const LearnMoreLinkContainer = styled.div`
  margin-top: ${props => props.theme.spacing.regular};
  text-align: center;
  width: 100%;
`;

export const DisclaimerRow = styled.div`
  font-size: 10px;
  color: ${props => props.theme.color.grey};
  padding: 4px 16px;
  font-style: italic;
`;
