import { useEffect, useState } from 'react';
import { message, Modal, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import Input from 'components/Input/Input';

import { useGetStoreCustomer, patchStoreCustomer } from 'apis/storecustomer';

export const useCustomerRemarksModal = () => {
  const [isCustomerRemarksModalVisible, setIsCustomerRemarksModalVisible] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState();

  const handleOnCustomerRemarksBtnClick = customerId => {
    setIsCustomerRemarksModalVisible(true);
    setSelectedCustomerId(customerId);
  };

  return {
    isCustomerRemarksModalVisible,
    setIsCustomerRemarksModalVisible,

    selectedCustomerId,

    handleOnCustomerRemarksBtnClick
  };
};

const CustomerRemarksModal = ({ isCustomerRemarksModalVisible, setIsCustomerRemarksModalVisible, selectedCustomerId }) => {
  const { t } = useTranslation(['common', 'modalCustomerRemarks']);
  const [remarks, setRemarks] = useState();
  const [isSaving, setIsSaving] = useState(false);

  const { data, isLoading } = useGetStoreCustomer(selectedCustomerId);

  useEffect(() => {
    if (!isLoading && data && data.remarks) {
      setRemarks(data.remarks);
    }
  }, [isLoading, data]);

  const handleOnCustomerRemarksModalOk = () => {
    setIsSaving(true);
    patchStoreCustomer(selectedCustomerId, { remarks })
      .then(() => {
        setIsCustomerRemarksModalVisible(false);
        message.success(t('common:update-success-message'));
      })
      .catch(ex => {
        message.error(ex.message);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleOnCustomerRemarksModalCancel = () => {
    setIsCustomerRemarksModalVisible(false);
  };

  const handleOnRemarksChange = value => {
    setRemarks(value);
  };

  return (
    <Modal
      title={t('modalCustomerRemarks:title')}
      visible={isCustomerRemarksModalVisible}
      onOk={handleOnCustomerRemarksModalOk}
      onCancel={handleOnCustomerRemarksModalCancel}
      confirmLoading={isSaving}
      okText={t('common:form-submit-create-button-text')}
      cancelText={t('common:modal-cancel-text')}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Input type="textarea" value={remarks} onChange={handleOnRemarksChange} showCount={true} maxLength={2000} rows={6} />
      )}
    </Modal>
  );
};

export default CustomerRemarksModal;
