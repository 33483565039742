import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Card, Col, Form, message, Row, Skeleton, Table } from 'antd';
import { CheckOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import { useGetProductBulkPurchaseSettings, patchBulkPurchaseSettings } from 'apis/product';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormCheckboxToggle from 'components/Checkbox/FormCheckboxToggle/FormCheckboxToggle';
import TextButton from 'components/TextButton/TextButton';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';
import BulkCreateModal, { useBulkCreateModal } from './BulkCreateModal/BulkCreateModal';

import { constructColumn } from 'utils/table/table';

const { useForm } = Form;

const defaultData = {
  quantity: 2
};

const getNewDefaultData = (data = []) => {
  if (data) {
    const lastData = data[data.length - 1];
    if (lastData) {
      return { quantity: lastData.quantity + 1, price: lastData.price };
    }
  }
  return defaultData;
};

const constructColumns = ({ t, editingKey, handleOnClickEditTier, handleOnClickSaveTier, handleOnClickConfirmDeleteTier }) => [
  {
    ...constructColumn(t('pageProductDetails:bulk-tier-table-header-quantity'), 'quantity', { width: '10%' }),
    editable: true
  },
  {
    ...constructColumn(t('pageProductDetails:bulk-tier-table-header-price'), 'price', { width: '10%' }),
    editable: true,
    render: text => <PriceDisplay amount={text} />
  },
  {
    ...constructColumn(t('pageProductDetails:bulk-tier-table-header-actions'), 'actions', { width: '15%' }),
    render: (okText, record, index) => {
      const editable = index === editingKey;
      return editable ? (
        <div>
          <TextButton
            icon={<CheckOutlined />}
            onClick={e => handleOnClickSaveTier(e, index)}
            text={t('pageProductDetails:bulk-tier-text-button-update')}
            tooltipMessage={t('common:text-button-tooltip-message', { action: t('pageProductDetails:bulk-tier-tooltip-message-action-update') })}
            htmlType="submit"
          />
          <TextButton
            icon={<DeleteOutlined />}
            onClick={() => handleOnClickConfirmDeleteTier(index)}
            text={t('pageProductDetails:bulk-tier-text-button-remove')}
            tooltipMessage={t('common:text-button-tooltip-message', { action: t('pageProductDetails:bulk-tier-tooltip-message-action-remove') })}
          />
        </div>
      ) : (
        <div>
          <TextButton
            icon={<EditOutlined />}
            onClick={() => handleOnClickEditTier(record, index)}
            text={t('pageProductDetails:bulk-tier-text-button-edit')}
            tooltipMessage={t('common:text-button-tooltip-message', { action: t('pageProductDetails:bulk-tier-tooltip-message-action-edit') })}
            disabled={editingKey !== ''}
          />
        </div>
      );
    }
  }
];

const BulkPurchaseSettings = ({ productId, onError = () => {} }) => {
  const { t } = useTranslation(['common', 'pageProductDetails']);
  const [form] = useForm();

  const {
    isLoading: isBulkPurchaseSettingsLoading,
    data: bulkPurchaseSettings,
    refetch: refetchBulkPurchaseSettings
  } = useGetProductBulkPurchaseSettings(productId);

  const { isBulkCreateModalVisible, setIsBulkCreateModalVisible, handleOnBulkCreateBtnClick } = useBulkCreateModal();

  const [data, setData] = useState([]);
  const [bulkPurchaseTiersErrorMsg, setBulkPurchaseTiersErrorMsg] = useState('');
  const [editingKey, setEditingKey] = useState('');
  const [isBulkPurchaseEnabled, setIsBulkPurchaseEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ isBulkPurchaseEnabled: !!bulkPurchaseSettings });
    const defaultData = !!bulkPurchaseSettings ? bulkPurchaseSettings : [];
    setData(defaultData);
    setBulkPurchaseTiersErrorMsg('');
    setIsBulkPurchaseEnabled(!!bulkPurchaseSettings);
  }, [bulkPurchaseSettings, form]);

  const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    const getInputNode = () => {
      switch (inputType) {
        case 'number':
          return (
            <FormInputNumber
              name={dataIndex}
              requiredErrorMessage={t('pageProductDetails:bulk-tier-cell-form-error-message', { itemName: title.toLowerCase() })}
              placeholder="2"
              minValue={2}
            />
          );

        case 'price':
          return (
            <FormInputNumber
              name={dataIndex}
              type="financial"
              requiredErrorMessage={t('pageProductDetails:bulk-tier-cell-form-error-message', { itemName: title.toLowerCase() })}
              placeholder="100.00"
            />
          );
        default:
          return (
            <FormInput
              name={dataIndex}
              requiredErrorMessage={t('pageProductDetails:bulk-tier-cell-form-error-message', { itemName: title.toLowerCase() })}
              placeholder={title}
            />
          );
      }
    };

    return <td {...restProps}>{editing ? getInputNode() : children}</td>;
  };

  const handleOnClickAddTier = () => {
    const newDefaultData = getNewDefaultData(data);
    const newData = [...data, newDefaultData];
    setData(newData);
    setBulkPurchaseTiersErrorMsg('');
    form.setFieldsValue(newDefaultData);
    setEditingKey(newData.length - 1);
  };

  const handleOnClickEditTier = (record, index) => {
    form.setFieldsValue({
      ...record
    });
    setEditingKey(index);
  };

  const handleOnClickSaveTier = async (e, index) => {
    e.preventDefault();
    try {
      const { quantity, price } = await form.validateFields();
      const currentPurchaseTier = { quantity: Number(quantity), price: Number(price) };
      const newData = [...data];
      if (
        !newData.find(data => data.quantity === currentPurchaseTier.quantity) ||
        newData.findIndex(data => data.quantity === currentPurchaseTier.quantity) === index
      ) {
        newData.splice(index, 1, currentPurchaseTier);
        const sortedNewData = newData.sort((dataA, dataB) => dataA.quantity - dataB.quantity);
        setData(sortedNewData);
        setEditingKey('');
        setBulkPurchaseTiersErrorMsg('');
      } else {
        setBulkPurchaseTiersErrorMsg(t('pageProductDetails:bulk-tier-same-condition'));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      message.error(t('common:update-fail-message'));
    }
  };

  const handleOnClickConfirmDeleteTier = index => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
    setEditingKey('');
    setBulkPurchaseTiersErrorMsg('');
  };

  const handleOnBulkCreateTiers = ({ fromQuantity, toQuantity, unitPrice }) => {
    const newData = [...data];
    for (let quantity = fromQuantity; quantity <= toQuantity; quantity++) {
      const newTier = { quantity, price: Number(quantity * unitPrice) };
      const existingTierIndex = newData.findIndex(item => item.quantity === quantity);

      if (existingTierIndex > -1) {
        newData[existingTierIndex] = newTier;
      } else {
        newData.push(newTier);
      }
    }

    const sortedNewData = newData.sort((dataA, dataB) => dataA.quantity - dataB.quantity);
    setData(sortedNewData);
    setIsBulkCreateModalVisible(false);
    form.submit();
  };

  const getEditableCols = () => {
    const columns = constructColumns({
      t,
      editingKey,
      handleOnClickEditTier,
      handleOnClickSaveTier,
      handleOnClickConfirmDeleteTier
    });

    return columns.map(col => {
      if (!col.editable) {
        return col;
      }

      const getInputType = () => {
        switch (col.dataIndex) {
          case 'quantity':
            return 'number';

          case 'price':
            return 'price';

          default:
            return 'text';
        }
      };

      return {
        ...col,
        onCell: (record, index) => {
          return {
            record,
            key: index,
            inputType: getInputType(col.dataIndex),
            dataIndex: col.dataIndex,
            title: col.title,
            editing: index === editingKey
          };
        }
      };
    });
  };

  const handleOnSaveSettings = values => {
    const { isBulkPurchaseEnabled } = values;
    setIsSubmitting(true);
    patchBulkPurchaseSettings(productId, isBulkPurchaseEnabled ? data : [])
      .then(() => {
        message.success(t('common:update-success-message'));
        refetchBulkPurchaseSettings();
      })
      .catch(ex => {
        message.error(ex.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  return (
    <Form form={form} onFinish={handleOnSaveSettings} onFinishFailed={handleOnSaveFailed}>
      <Card>
        {isBulkPurchaseSettingsLoading ? (
          <Skeleton active />
        ) : (
          <>
            <FormCheckboxToggle
              name="isBulkPurchaseEnabled"
              label={t('pageProductDetails:bulkpurchase-checkbox-text')}
              onChange={() => setIsBulkPurchaseEnabled(!isBulkPurchaseEnabled)}
            />
            {isBulkPurchaseEnabled && (
              <>
                <Row gutter={[8, 16]}>
                  <Col>
                    <Button type="primary" onClick={handleOnClickAddTier} icon={<PlusOutlined />} disabled={editingKey !== ''}>
                      {t('pageProductDetails:bulk-tier-add-button')}
                    </Button>
                  </Col>
                  <Col>
                    <Button type="primary" onClick={handleOnBulkCreateBtnClick} icon={<PlusOutlined />} disabled={editingKey !== ''}>
                      {t('pageProductDetails:bulk-tier-bulk-add-button')}
                    </Button>
                  </Col>
                </Row>
                <Table
                  rowKey={record => record.quantity}
                  size="small"
                  components={{
                    body: {
                      cell: EditableCell
                    }
                  }}
                  bordered
                  dataSource={data}
                  columns={getEditableCols()}
                  scroll={{ x: 'max-content' }}
                  locale={{
                    triggerDesc: t('common:table-header-sort-trigger-desc'),
                    triggerAsc: t('common:table-header-sort-trigger-asc'),
                    cancelSort: t('common:table-header-sort-cancel-sort')
                  }}
                  pagination={false}
                />
                {bulkPurchaseTiersErrorMsg && <Alert type="error" message={bulkPurchaseTiersErrorMsg} style={{ marginTop: 10 }} />}
              </>
            )}
          </>
        )}
        <Row gutter={8} style={{ margin: '16px 0px' }}>
          <Col>
            <Button htmlType="submit" type="primary" icon={<CheckOutlined />} loading={isSubmitting}>
              {t('pageProductDetails:bulkpurchase-update-button-text')}
            </Button>
          </Col>
        </Row>
      </Card>
      {isBulkCreateModalVisible && <BulkCreateModal setIsBulkCreateModalVisible={setIsBulkCreateModalVisible} onOk={handleOnBulkCreateTiers} />}
    </Form>
  );
};

export default BulkPurchaseSettings;
