import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Radio, Row, Col, Form, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { getOrdersCSVData, getOrderProductsCSVData } from 'apis/order';

import CsvDownload from 'components/CsvDownload/CsvDownload';
import FormPasswordInput from 'components/FormPasswordInput/FormPasswordInput';

import {
  REPORT_TYPES,
  REPORT_ORDER_FIELDS_TYPE,
  ORDER_FULL_CSV_HEADERS,
  ORDER_PACKAGING_CSV_HEADERS,
  ORDER_PRODUCTS_CSV_HEADERS,
  PASSCODE_REGEX
} from 'utils/constants';
import { formatToDateTimeWithPMAM } from 'utils/date';
import { guard } from 'utils/general';

import { FieldLabel } from './ModalDownloadCSV.styles';

const { useForm } = Form;
const { Group: RadioGroup } = Radio;

/* ================================================== Local Functions ================================================== */
const getFomattedOrdersCSVData = async query => {
  return getOrdersCSVData(query).then(orders => {
    const totalObj = orders.reduce(
      (amountObj, order) => {
        const productTotalPrice = order.product.totalPrice;

        return {
          productTotalPrice: amountObj.productTotalPrice + productTotalPrice
        };
      },
      { productTotalPrice: 0 }
    );

    return [
      {
        orderNumber: 'Total Sales',
        product: {
          totalPrice: parseFloat(totalObj.productTotalPrice).toFixed(2)
        }
      },
      ...orders.map(order => {
        const product = order.product;

        return {
          ...order,
          purchasedDate: formatToDateTimeWithPMAM(order.purchasedDate),
          checkoutDate: formatToDateTimeWithPMAM(order.checkoutDate),
          product: {
            ...product,
            purchasedDate: formatToDateTimeWithPMAM(product.purchasedDate),
            liveStreamers: product.liveStreamers && product.liveStreamers.join(', ')
          }
        };
      })
    ];
  });
};

const sendEventDataDownloadCsv = (params = {}) => {
  let actionData = '';
  const objectData = Object.entries(params);
  objectData.forEach((value, index) => {
    if (value[1] !== null) {
      actionData = `${actionData}${index === 0 ? '' : '@'}${value[0]}:${value[1]}`;
    }
  });
  window.dataLayer.push({ event: 'download_csv', ...params, action: actionData });
};

const useDownloadCSV = ({ form, t, postData, storeShortName, storeSecurityConfig }) => {
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState(REPORT_TYPES.ORDER.value);
  const [selectedReportOrderFieldType, setSelectedReportOrderFieldType] = useState(REPORT_ORDER_FIELDS_TYPE.FULL.value);

  const isOrderReportTypeSelected = useMemo(() => selectedReportType === REPORT_TYPES.ORDER.value, [selectedReportType]);
  const isRequirePasscode = guard(
    () =>
      selectedReportType === REPORT_TYPES.ORDER.value &&
      selectedReportOrderFieldType === REPORT_ORDER_FIELDS_TYPE.FULL.value &&
      storeSecurityConfig.hasSecurityPasscode
  );

  const downloadCSVFunc = useCallback(async () => {
    try {
      let finalQuery = { postId: postData._id, fieldType: isOrderReportTypeSelected ? selectedReportOrderFieldType : undefined };

      if (isRequirePasscode) {
        const values = await form.validateFields(['securityPasscode']);
        finalQuery.securityPasscode = values.securityPasscode;
      }
      const csvData = isOrderReportTypeSelected ? await getFomattedOrdersCSVData(finalQuery) : await getOrderProductsCSVData(finalQuery);
      sendEventDataDownloadCsv({
        page: 'overview',
        category: 'order',
        report_order_field: selectedReportOrderFieldType,
        report_type: selectedReportType,
        payment_type: null,
        payment_status: null,
        days: null
      });
      return csvData;
    } catch (ex) {
      message.error(t('pageOverview:product-sold-section-csv-download-security-passcode-form-validation-failed-message'));
    }
  }, [form, t, isOrderReportTypeSelected, isRequirePasscode, postData, selectedReportOrderFieldType, selectedReportType]);

  const downloadCSVHeaders = useMemo(() => {
    if (isOrderReportTypeSelected) {
      return selectedReportOrderFieldType === REPORT_ORDER_FIELDS_TYPE.FULL.value ? ORDER_FULL_CSV_HEADERS : ORDER_PACKAGING_CSV_HEADERS;
    } else {
      return ORDER_PRODUCTS_CSV_HEADERS;
    }
  }, [selectedReportOrderFieldType, isOrderReportTypeSelected]);

  const downloadCSVFilename = useMemo(() => {
    const orderReportTypeLabel = guard(
      () => Object.values(REPORT_ORDER_FIELDS_TYPE).find(reportOrderField => reportOrderField.value === selectedReportOrderFieldType).label
    );

    return `${storeShortName}_${isOrderReportTypeSelected ? `Orders (${orderReportTypeLabel})` : 'ProductsSold'}_Live_${formatToDateTimeWithPMAM(
      postData.createdAt
    )}.csv`;
  }, [isOrderReportTypeSelected, selectedReportOrderFieldType, storeShortName, postData.createdAt]);

  const handleOnChangeReportType = useCallback(e => {
    setSelectedReportType(e.target.value);
  }, []);

  const handleOnChangeReportOrderFieldType = useCallback(e => {
    setSelectedReportOrderFieldType(e.target.value);
  }, []);

  return {
    isDownloadingCSV,
    isRequirePasscode,
    selectedReportType,
    selectedReportOrderFieldType,

    downloadCSVFunc,
    downloadCSVHeaders,
    downloadCSVFilename,

    setIsDownloadingCSV,
    handleOnChangeReportType,
    handleOnChangeReportOrderFieldType
  };
};

/* ================================================== Main Component ================================================== */
const ModalDownloadCSV = ({ postData, storeShortName, storeSecurityConfig, onCancel }) => {
  const [form] = useForm();
  const { t } = useTranslation(['common', 'commonConstants', 'pageOverview']);

  const {
    isDownloadingCSV,
    isRequirePasscode,
    selectedReportType,
    selectedReportOrderFieldType,

    downloadCSVFunc,
    downloadCSVHeaders,
    downloadCSVFilename,

    setIsDownloadingCSV,
    handleOnChangeReportType,
    handleOnChangeReportOrderFieldType
  } = useDownloadCSV({ form, t, postData, storeShortName, storeSecurityConfig });

  return (
    <Modal
      visible
      width="60%"
      maskClosable={false}
      onCancel={onCancel}
      footer={
        <CsvDownload
          asyncExportMethod={downloadCSVFunc}
          onDataLoading={() => setIsDownloadingCSV(true)}
          onDataLoaded={() => setIsDownloadingCSV(false)}
          headers={downloadCSVHeaders}
          filename={downloadCSVFilename}
        >
          <Button loading={isDownloadingCSV} type="primary" icon={<DownloadOutlined />}>
            {t('pageOverview:product-sold-section-csv-download-button-text')}
          </Button>
        </CsvDownload>
      }
    >
      <Row>
        <Col span={24} style={{ marginBottom: '32px' }}>
          <FieldLabel>{t('pageOverview:product-sold-section-csv-download-report-type-text')}</FieldLabel>
          <RadioGroup
            options={Object.values(REPORT_TYPES).map(reportType => {
              return { ...reportType, label: t(`commonConstants:${reportType.transKey}`) };
            })}
            onChange={handleOnChangeReportType}
            value={selectedReportType}
            optionType="button"
            buttonStyle="solid"
          />
        </Col>
        {selectedReportType === REPORT_TYPES.ORDER.value && (
          <Col span={24} style={{ marginBottom: '32px' }}>
            <FieldLabel margin="0 0 16px 0">{t('pageOverview:product-sold-section-csv-download-report-order-fields-type-text')}</FieldLabel>
            <Radio.Group
              options={Object.values(REPORT_ORDER_FIELDS_TYPE).map(reportOrderFieldsType => {
                return { ...reportOrderFieldsType, label: t(`commonConstants:${reportOrderFieldsType.transKey}`) };
              })}
              onChange={handleOnChangeReportOrderFieldType}
              value={selectedReportOrderFieldType}
              optionType="button"
              buttonStyle="solid"
            />
          </Col>
        )}
        {isRequirePasscode && (
          <Col span={24}>
            {/* Using FormPasswordInput for consitency & form here for styling*/}
            <Form form={form}>
              <FormPasswordInput
                label={t('pageOverview:product-sold-section-csv-download-form-input-label-security-passcode')}
                name="securityPasscode"
                placeholder={t('pageOverview:product-sold-section-csv-download-form-input-placeholder-security-passcode')}
                requiredErrorMessage={t('pageOverview:product-sold-section-csv-download-form-input-required-message-security-passcode')}
                customPattern={PASSCODE_REGEX}
                customPatternErrorMessage={t('pageOverview:product-sold-section-csv-download-form-input-invalid-pattern-message-security-passcode')}
              />
            </Form>
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default ModalDownloadCSV;
