import { AppstoreAddOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Alert, Card as AntDCard, Col, Form, Row } from 'antd';

export const SMCContainer = styled(AntDCard)`
  margin: ${props => props.theme.spacing.regular} 0;
`;

export const SMCTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.xxs};
`;

export const SMCTitleIcon = styled(AppstoreAddOutlined)`
  font-size: 24px;
  color: ${props => props.theme.color.tertiary};
  margin-right: ${props => props.theme.spacing.xs}; ;
`;

export const SMCTitle = styled.span`
  font-size: 16px;
  color: ${props => props.theme.color.tertiary};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const SMCDesc = styled.p`
  color: ${props => props.theme.color.grey};
`;

export const RootContainer = styled(Row)`
  width: 100%;
  margin: auto;
`;

export const FullWidthCard = styled(AntDCard)`
  width: 100%;
`;

export const FullHeightRow = styled(Row)`
  height: 100%;
`;

export const FullWidthForm = styled(Form)`
  width: 100%;
`;

export const CardTitle = styled.span`
  color: ${props => props.theme.color.tertiary};
`;

export const ProfileNameCol = styled.div`
  height: 100%;
  font-family: ${props => props.theme.fontFamily.title};
  font-size: 16px;
  margin-right: ${props => props.theme.spacing.xs};
`;

export const ProfileSubtext = styled.span`
  color: ${props => props.theme.color.grey};
  font-size: 12px;
`;

export const ProfileAvatarCol = styled(Col)`
  height: 72px;
`;

export const ResetPasswordAlert = styled(Alert)``;

export const CursiveText = styled.span`
  font-family: ${props => props.theme.fontFamily.title};
  font-size: 24px;
`;
