import { useTranslation } from 'react-i18next';
import { Button, Modal, Input, message } from 'antd';

import { RootContainer, FeedbackContainer, DescriptionContainer, BannerImage } from './LearnMoreModal.styles';
import mobileAppBanner from './mobile-app-banner.png';

const FEEBACK_FORM_LINK = 'https://forms.gle/Qk5q74sVVy1B1aew8';

const NotifSettingModal = ({ storeShortName, onCancel }) => {
  const { t } = useTranslation(['common', 'pageNotification']);

  const downloadLink = `https://ulive.me/app?utm_campaign=app-share-${String(storeShortName).toLowerCase()}`;

  const handleOnCopy = () => {
    navigator.clipboard.writeText(downloadLink);
    message.success(t('pageNotification:learn-more-modal-copy-success-message'));
  };

  return (
    <Modal
      visible
      width="90%"
      style={{ maxWidth: '580px' }}
      title={t('pageNotification:learn-more-modal-title')}
      okText={t('common:form-submit-create-button-text')}
      footer={null}
      onCancel={onCancel}
    >
      <RootContainer>
        <DescriptionContainer>
          <BannerImage alt="Banner about ulive mobile app" src={mobileAppBanner} />
          <p>{t('pageNotification:learn-more-modal-heading-text')}</p>
          <ul>
            <li>
              <b>{t('pageNotification:learn-more-modal-one-time-notif-highlight')}</b> {t('pageNotification:learn-more-modal-one-time-notif-desc')}
            </li>
            <li>
              <b>{t('pageNotification:learn-more-modal-auto-notif-highlight')}</b> {t('pageNotification:learn-more-modal-auto-notif-desc')}
            </li>
            <li>
              <b>{t('pageNotification:learn-more-modal-timeline-highlight')}</b> {t('pageNotification:learn-more-modal-timeline-desc')}
            </li>
            <li>
              <b>{t('pageNotification:learn-more-modal-statistic-highlight')}</b>
            </li>
          </ul>
          <p>{t('pageNotification:learn-more-modal-share-text')}</p>
          <Input.Group compact>
            <Input style={{ width: 'calc(100% - 72px)' }} defaultValue={downloadLink} disabled></Input>
            <Button type="primary" onClick={handleOnCopy}>
              {t('pageNotification:learn-more-modal-button-copy-text')}
            </Button>
          </Input.Group>
        </DescriptionContainer>
        <FeedbackContainer>
          <span>{t('pageNotification:learn-more-modal-feedbacks-text')} </span>
          <a href={FEEBACK_FORM_LINK} target="_blank" rel="noreferrer">
            {t('pageNotification:learn-more-modal-feedbacks-link-text')}
          </a>
        </FeedbackContainer>
      </RootContainer>
    </Modal>
  );
};

export default NotifSettingModal;
