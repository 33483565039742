import styled from '@emotion/styled';
import { Col, Row, Select, Tag, Typography } from 'antd';
import { DeleteOutlined, FacebookOutlined, InstagramOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

export const LiveSessionSelection = styled.div``;

export const LiveSessionSelectionLiveIndicator = styled(Tag)`
  margin-left: 4px;
`;

export const LiveSessionSelect = styled(Select)`
  width: 0;
  flex: 1 0 auto;
`;

export const ProductCardContentRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
`;

export const ProductCardInfoCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ProductCardNameLabel = styled(Paragraph)`
  margin-bottom: 8px;
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const ProductCardInventoryLabel = styled.p`
  margin-bottom: 8px;
  color: ${props => props.theme.color.grey};
`;

export const ProductCardVarianceLabel = styled.p`
  margin-bottom: 4px;
  color: ${props => props.theme.color.grey};
`;

export const PositionSelection = styled(Select)`
  width: 100%;
`;

export const DeleteButton = styled(DeleteOutlined)`
  font-size: 20px;
  vertical-align: middle;
  margin-left: 8px;
`;

export const StyledFacebookOutlined = styled(FacebookOutlined)`
  color: ${props => props.theme.color.socialMediaFB};
  margin-right: 4px;
`;

export const StyledInstagramOutlined = styled(InstagramOutlined)`
  color: ${props => props.theme.color.socialMediaIG};
  margin-right: 4px;
`;
