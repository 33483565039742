import styled from '@emotion/styled';
import { Row } from 'antd';

export const TitleContainerRow = styled(Row)``;

export const BuyEventContainer = styled.div`
  word-break: break-word;
`;

export const BuyEventIconContainer = styled.div`
  display: inline-block;
  margin-right: 4px;
  cursor: ${props => (props.canClick ? 'pointer' : undefined)};
`;

export const NameContainer = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`;

export const NameLabel = styled.span`
  text-align: left;
  color: ${props => (props.isUserBlacklisted ? props.theme.color.grey : props.theme.color.lightTertiary)};
`;
