import { useState } from 'react';
import { Alert, Button, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import Input from 'components/Input/Input';

import { postSendFBMessage } from 'apis/storecustomer';

export const useFBModal = () => {
  const [isFBModalVisible, setIsFBModalVisible] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState();
  const [orderSummary, setOrderSummary] = useState('');

  const handleOnFBBtnClick = (customerId, orderSummary) => {
    setIsFBModalVisible(true);
    setSelectedCustomerId(customerId);
    window.dataLayer.push({
      event: 'messenger_event',
      action: 'messenger_button_clicked'
    });
    setOrderSummary(orderSummary);
  };

  return {
    isFBModalVisible,
    setIsFBModalVisible,

    selectedCustomerId,
    orderSummary,

    handleOnFBBtnClick
  };
};

const FBModal = ({ isFBModalVisible, setIsFBModalVisible, selectedCustomerId, storeCustomWAMessage, orderSummary }) => {
  const { t } = useTranslation(['modalFBMessage']);
  const [fbMessage, setFBMessage] = useState(storeCustomWAMessage);
  const [isSendingFBMessage, setIsSendingFBMessage] = useState(false);

  const handleOnFBModalOk = () => {
    setIsSendingFBMessage(true);
    postSendFBMessage(selectedCustomerId, fbMessage)
      .then(() => {
        setIsFBModalVisible(false);
        window.dataLayer.push({
          event: 'messenger_event',
          action: 'messenger_send_button_clicked'
        });
      })
      .catch(ex => {
        message.error(ex.message);
      })
      .finally(() => {
        setIsSendingFBMessage(false);
      });
  };

  const handleOnFBModalCancel = () => {
    setIsFBModalVisible(false);
  };

  const handleOnFBMessageChange = value => {
    setFBMessage(value);
  };

  const handleOnCopyOrderSummaryBtnClick = () => {
    let updatedFBMessage = fbMessage;

    if (fbMessage) {
      updatedFBMessage += '\n\n';
    }

    setFBMessage(updatedFBMessage + orderSummary);
  };

  return (
    <Modal
      title={t('modalFBMessage:title')}
      visible={isFBModalVisible}
      onOk={handleOnFBModalOk}
      onCancel={handleOnFBModalCancel}
      confirmLoading={isSendingFBMessage}
      okText={t('modalFBMessage:ok-text')}
      cancelText={t('modalFBMessage:cancel-text')}
    >
      <Alert
        message={
          <span>
            {t('modalFBMessage:alert-message-warning')}
            <br />
            <b>{t('modalFBMessage:alert-message-recommendation')}</b>
            <br />
            <a
              style={{ marginBottom: 0 }}
              href="https://developers.facebook.com/docs/messenger-platform/send-messages/#messaging_types"
              target="_blank"
              rel="noreferrer"
            >
              {t('modalFBMessage:alert-message-info')}
            </a>
          </span>
        }
        type="warning"
        style={{ marginBottom: '16px' }}
      />
      {!!orderSummary && (
        <Button type="primary" style={{ marginBottom: '16px' }} onClick={handleOnCopyOrderSummaryBtnClick}>
          {t('modalFBMessage:apply-order-summary-btn')}
        </Button>
      )}
      <Input type="textarea" value={fbMessage} onChange={handleOnFBMessageChange} showCount={true} maxLength={2000} rows={6} />
    </Modal>
  );
};

export default FBModal;
