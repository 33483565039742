import { useState, useMemo } from 'react';
import { Col, Row, Radio } from 'antd';
import { useTranslation } from 'react-i18next';

import { Column } from '@ant-design/plots';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { constructDisplayPrice } from 'utils/general';

import PercentageChangedCard from '../PercentageChangedCard/PercentageChangedCard';

const REVENUE_DAILY_VALUE = 'daily';
const REVENUE_WEEKLY_VALUE = 'weekly';

const statusToColorMapping = {
  Pending: '#ffc107',
  'Pre-Checkout': '#ff9800',
  Checkout: '#2196f3',
  Paid: '#4caf50',
  Completed: '#009688',
  Cancelled: '#607d8b'
};

const Revenue = ({ reportingData, storeCurrency }) => {
  const { t } = useTranslation(['pageReporting']);

  const [selectedDateRange, setSelectedDateRange] = useState(REVENUE_DAILY_VALUE);
  const [currentPage, setCurrentPage] = useState(1);

  const dateRangeSelections = [
    { label: t('pageReporting:revenue-daily-button'), value: REVENUE_DAILY_VALUE },
    { label: t('pageReporting:revenue-weekly-button'), value: REVENUE_WEEKLY_VALUE }
  ];

  const dailyPaginates = [
    { label: <LeftOutlined />, value: -1 },
    { label: <RightOutlined />, value: 1 }
  ];

  const checkIsDisablePaginateButton = value => {
    if (value > 0) {
      return Object.keys(reportingData.dailyRevenue).length <= currentPage * 7;
    } else {
      return currentPage <= 1;
    }
  };

  const handleDateRangeButtonClick = e => {
    setCurrentPage(1);
    setSelectedDateRange(e.target.value);
  };

  const handlePaginateButtonClick = e => {
    setCurrentPage(currentPage + e.target.value);
  };

  const columnData = useMemo(() => {
    const revenueData = reportingData[selectedDateRange === REVENUE_DAILY_VALUE ? 'dailyRevenue' : 'weeklyRevenue'];

    let formattedRevenueData = [];
    for (const date in revenueData) {
      for (const status in revenueData[date]) {
        const day = date.substring(8);
        const month = date.substring(5, 7);

        formattedRevenueData.push({
          date: `${parseInt(day)}/${parseInt(month)}`,
          status,
          revenue: revenueData[date][status]
        });
      }
    }

    if (selectedDateRange === REVENUE_DAILY_VALUE) {
      const allStatuses = 6;
      const statusDataPerWeek = 7 * allStatuses;
      return formattedRevenueData.splice((currentPage - 1) * statusDataPerWeek, statusDataPerWeek);
    }
    return formattedRevenueData;
  }, [currentPage, reportingData, selectedDateRange]);

  return (
    <div>
      <Row gutter={24} align="middle">
        <Col xs={24} md={8}>
          <PercentageChangedCard
            title={t('pageReporting:revenue-title')}
            value={reportingData.revenue}
            isCurrency={true}
            storeCurrency={storeCurrency}
          />
          <PercentageChangedCard title={t('pageReporting:total-number-of-orders')} value={reportingData.numOfOrders} />
        </Col>
        <Col xs={24} md={16}>
          <Row justify="space-between">
            <Radio.Group
              onChange={handleDateRangeButtonClick}
              value={selectedDateRange}
              style={{ marginBottom: 16 }}
              optionType="button"
              buttonStyle="solid"
            >
              {dateRangeSelections.map(dateRange => (
                <Radio.Button key={dateRange.value} value={dateRange.value}>
                  {dateRange.label}
                </Radio.Button>
              ))}
            </Radio.Group>
            {selectedDateRange === REVENUE_DAILY_VALUE && Object.keys(reportingData.dailyRevenue).length > 7 && (
              <Radio.Group
                onChange={handlePaginateButtonClick}
                value={selectedDateRange}
                style={{ marginBottom: 16 }}
                optionType="button"
                buttonStyle="solid"
              >
                {dailyPaginates.map(dateRange => (
                  <Radio.Button key={dateRange.value} value={dateRange.value} disabled={checkIsDisablePaginateButton(dateRange.value)}>
                    {dateRange.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            )}
          </Row>
          <Column
            height={300}
            color={({ status }) => statusToColorMapping[status]}
            data={columnData}
            isStack={true}
            xField="date"
            yField="revenue"
            seriesField="status"
            tooltip={{
              customItems: originalItems => {
                const filterItems = originalItems.filter(item => item.value > 0);
                return filterItems.map(filterItem => {
                  return {
                    ...filterItem,
                    name: filterItem.name,
                    value: `${storeCurrency.symbol} ${constructDisplayPrice(filterItem.value, storeCurrency.iso)}`
                  };
                });
              }
            }}
            legend={{
              layout: 'horizontal',
              position: 'top'
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Revenue;
