import api from './apiHelper';
import { useCustomQuery, useRefetchQuery } from 'hooks/reactQuery';

export const postStartLiveChat = async ({ id, message }) => {
  return api.post(`/igmessage/start-live-chat`, { id, message });
};

export const useGetIGMessages = () => {
  const getIGMessages = async () => {
    return api.get(`/igmessage`);
  };

  return useCustomQuery('igMessages', [], getIGMessages, {
    shouldDefaultEmptyObject: false
  });
};

export const useRefetchIGMessages = () => {
  const { refetch } = useRefetchQuery(['igMessages']);

  return refetch;
};
