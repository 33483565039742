import styled from '@emotion/styled';
import { Col } from 'antd';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';

export const CoverImageContainer = styled.div`
  background-image: ${props => `url("${props.coverPhotoUrl}")`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 160px;
  width: auto;
  margin: 0 auto;
`;

export const ProductLabelText = styled.p`
  margin-bottom: 0;
  color: #000000;
`;

export const ProductDescText = styled.p`
  margin-bottom: 8px;
  font-size: 12px;
  color: #797979;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: normal;
`;

export const InfoText = styled.p`
  margin-bottom: 0px;
`;

export const ProductQuantityAndPriceContainer = styled(Col)`
  display: flex;
  justify-content: space-between;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FinalPrice = styled(PriceDisplay)`
  color: ${props => (props.hasOriPrice ? props.theme.color.secondary : props.theme.color.grey)};
`;

export const OriPrice = styled(PriceDisplay)`
  font-size: 12px;
  text-decoration: line-through;
`;
