import { useState } from 'react';
import { TagOutlined, ToTopOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal, Popover, Row, Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetProductSuggestions } from 'apis/product';
import { withAppContext } from 'contexts/AppContext/AppContext';

import { checkIsArrayEmpty, constructDisplayPrice } from 'utils/general';
import { constructColumn, constructColumnFilterSearch, handleOnAggregationTableChange } from 'utils/table/table';
import { FormActionButtonsContainer, TooltipIcon } from './TiktokBumpProductsModal.style';
import { postCreateTiktokPostProductsAndBump } from 'apis/post';

const constructQtyLeftDisplay = (productKeyword, variances = []) => {
  return (
    <Popover
      trigger="click"
      content={variances.map(variance => (
        <p key={variance.keyword}>
          <TagOutlined /> {variance.label} x {variance.inventory}
        </p>
      ))}
    >
      <TooltipIcon />
    </Popover>
  );
};

const constructPriceDisplayForProductWithVariances = (variances = [], currencyISO) => {
  const variancesPriceList = variances.map(variance => Number(variance.price));
  const maxPrice = Math.max(...variancesPriceList);
  const minPrice = Math.min(...variancesPriceList);
  const isMinMaxPriceEqual = maxPrice === minPrice;

  return checkIsArrayEmpty(variances)
    ? '-'
    : isMinMaxPriceEqual
    ? constructDisplayPrice(maxPrice, currencyISO)
    : `${constructDisplayPrice(minPrice, currencyISO)} - ${constructDisplayPrice(maxPrice, currencyISO)}`;
};

const constructColumns = (t, currencyISO) => [
  {
    ...constructColumn(t('modalTiktokBumpProducts:table-header-label'), 'label', { hasAggregationSorter: true, width: '60%' }),
    ...constructColumnFilterSearch('label', t('modalTiktokBumpProducts:table-search-label'), { hasAggregationFilter: true })
  },
  {
    ...constructColumn(t('modalTiktokBumpProducts:table-header-qty-left'), 'inventory', { hasAggregationSorter: true, width: '20%' }),
    render: (inventory, record) => (
      <Row>
        <Col span={4}>{checkIsArrayEmpty(record.variances) ? '' : constructQtyLeftDisplay(record.keyword, record.variances)}</Col>
        <Col>{String(inventory)}</Col>
      </Row>
    )
  },
  {
    ...constructColumn(t('modalTiktokBumpProducts:table-header-price'), 'price', { isPrice: true, width: '20%' }),
    render: (price, record) => (
      <Row>
        <Col>
          {checkIsArrayEmpty(record.variances)
            ? constructDisplayPrice(price, currencyISO)
            : constructPriceDisplayForProductWithVariances(record.variances, currencyISO)}
        </Col>
      </Row>
    )
  }
];

const TiktokProductKeywordModal = ({ visible, postId, onClose, storeCurrency }) => {
  const { t } = useTranslation(['common', 'modalTiktokBumpProducts']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productSuggestionsQuery, setProductSuggestionsQuery] = useState({ currentPage: 1, limit: 100 });
  const { isLoading: isProductSuggestionsLoading, paginatedData: productSuggestions, total: productSuggestionsTotal } = useGetProductSuggestions({
    ...productSuggestionsQuery,
    filter: { ...productSuggestionsQuery.filter, isRemoveDisabled: true }
  });

  const getFormattedProduct = product => {
    return {
      _id: product._id,
      orderKeyword: product.orderKeyword,
      oriLabel: product.oriLabel,
      variances: product.variances,
      price: product.price,
      allBuyKeyword: product.allBuyKeyword
    };
  };

  const handleOnBumpProducts = async () => {
    try {
      setIsSubmitting(true);
      const selectedProductIds = selectedProducts.map(product => product._id);

      const payload = {
        bumpProductIds: selectedProductIds,
        postId
      };

      await postCreateTiktokPostProductsAndBump(payload);
      setIsSubmitting(false);
      onClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsSubmitting(false);
      message.error(t('common:update-fail-message'));
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      style={{ top: 40, minWidth: '70%' }}
      title={t('modalTiktokBumpProducts:modal-title', { count: productSuggestionsTotal || 0 })}
      footer={null}
    >
      <div>
        <Table
          size="small"
          rowKey={record => record._id}
          rowSelection={{
            selectedRowKeys: selectedProducts.map(selectedProduct => selectedProduct._id),
            onSelect: product => {
              const hasSelected = selectedProducts.find(selectedProduct => selectedProduct._id === product._id);
              setSelectedProducts(
                hasSelected
                  ? selectedProducts.filter(selectedProduct => selectedProduct._id !== product._id)
                  : [...selectedProducts, getFormattedProduct(product)]
              );
            },
            onSelectAll: isSelectAll => {
              setSelectedProducts(
                isSelectAll
                  ? [
                      ...new Map(
                        [...selectedProducts, ...productSuggestions.map(product => getFormattedProduct(product))].map(selectedProduct => [
                          selectedProduct._id,
                          selectedProduct
                        ])
                      ).values()
                    ]
                  : selectedProducts.filter(selectedProduct => !productSuggestions.find(product => product._id === selectedProduct._id))
              );
            }
          }}
          loading={isProductSuggestionsLoading}
          columns={constructColumns(t, storeCurrency.iso)}
          dataSource={productSuggestions}
          pagination={{ total: productSuggestionsTotal, defaultPageSize: 100 }}
          locale={{
            triggerDesc: t('common:table-header-sort-trigger-desc'),
            triggerAsc: t('common:table-header-sort-trigger-asc'),
            cancelSort: t('common:table-header-sort-cancel-sort')
          }}
          scroll={{ x: 'fit-content', y: '30vh' }}
          onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setProductSuggestionsQuery)}
        />
        <FormActionButtonsContainer>
          <Space wrap={true}>
            <Button type="secondary" onClick={() => onClose()} disabled={isSubmitting}>
              {t('modalTiktokBumpProducts:modal-bump-products-cancel-button-text')}
            </Button>
            <Button
              type="primary"
              icon={<ToTopOutlined />}
              onClick={handleOnBumpProducts}
              loading={isSubmitting}
              disabled={selectedProducts.length === 0}
            >
              {t('modalTiktokBumpProducts:modal-bump-products-ok-button-text')}
            </Button>
          </Space>
        </FormActionButtonsContainer>
      </div>
    </Modal>
  );
};

export default withAppContext(TiktokProductKeywordModal);
