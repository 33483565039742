import { useEffect, useState } from 'react';
import { Button, Row, Col, Form, Modal, Alert, message } from 'antd';

import { FontSizeOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import FormInput from 'components/FormInput/FormInput';
import TextButton from 'components/TextButton/TextButton';

import { patchAllKeyword, useGetProductAllKeyword } from 'apis/product';

const { useForm } = Form;

const AllKeywordModal = ({ t, form, visible, isLoading, onOk, onCancel, onDelete }) => {
  return (
    <Modal
      title={t('pageProductDetails:all-keyword-modal-title')}
      visible={visible}
      onCancel={onCancel}
      footer={
        <Row justify="end" gutter={[8, 8]}>
          <Col>
            <Button loading={isLoading} type="default" onClick={onCancel}>
              {t('common:modal-cancel-text')}
            </Button>
          </Col>
          <Col>
            <Button loading={isLoading} danger onClick={onDelete}>
              {t('modalPaymentInfo:delete-text')}
            </Button>
          </Col>
          <Col>
            <Button loading={isLoading} type="primary" onClick={onOk}>
              {t('common:form-submit-create-button-text')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Alert
        message={<b>{t('pageProductDetails:all-keyword-modal-alert-message')}</b>}
        description={t('pageProductDetails:all-keyword-modal-alert-description')}
        type="warning"
        showIcon
        style={{ marginBottom: '24px' }}
      />
      <Form form={form}>
        <FormInput label={t('pageProductDetails:all-keyword-modal-label')} name="allKeyword" placeholder="e.g: FFFLD" />
      </Form>
    </Modal>
  );
};

const AllKeywordSettings = ({ productId }) => {
  const { t } = useTranslation(['common', 'pageProductDetails']);
  const [form] = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: product, refetch: refetchProductAllKeyword } = useGetProductAllKeyword(productId);
  const allKeyword = product.allKeyword;

  useEffect(() => {
    if (allKeyword) {
      form.setFieldsValue({ allKeyword });
    }
  }, [form, allKeyword]);

  const handleOnSaveAllKeyword = () => {
    setIsLoading(true);
    const newAllKeyword = form.getFieldValue('allKeyword');

    patchAllKeyword(productId, product.keyword, newAllKeyword)
      .then(() => {
        refetchProductAllKeyword();
        message.success(t('common:update-success-message'));
        setIsModalVisible(false);
      })
      .catch(ex => {
        message.error(ex.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnDeleteAllKeyword = () => {
    form.setFieldsValue({ allKeyword: '' });
    handleOnSaveAllKeyword();
  };

  const handleOnCancelAllKeyword = () => {
    setIsModalVisible(false);
    form.setFieldsValue({ allKeyword });
  };

  return (
    <>
      {allKeyword ? (
        <div style={{ marginTop: '8px' }}>
          <span>
            {t('pageProductDetails:all-keyword-purchase-all-keyword')}: <b>{allKeyword}</b>
          </span>
          <TextButton
            icon={<EditOutlined />}
            onClick={() => {
              setIsModalVisible(true);
            }}
            text={t('pageProductDetails:all-keyword-edit-button-text')}
          />
        </div>
      ) : (
        <TextButton
          icon={<FontSizeOutlined />}
          style={{ marginLeft: '-16px', marginTop: '8px' }}
          onClick={() => {
            setIsModalVisible(true);
          }}
          text={t('pageProductDetails:all-keyword-set-button-text')}
        />
      )}

      <AllKeywordModal
        t={t}
        form={form}
        visible={isModalVisible}
        isLoading={isLoading}
        onOk={handleOnSaveAllKeyword}
        onDelete={handleOnDeleteAllKeyword}
        onCancel={handleOnCancelAllKeyword}
      />
    </>
  );
};

export default AllKeywordSettings;
