import styled from '@emotion/styled';

export const RootContainer = styled.div`
  align-items: center;
  background: linear-gradient(135deg, #181650 0%, #2c278a 100%);
  display: flex;
  justify-content: center;
  min-height: 100vh;

  ::before {
    content: '';
    position: fixed;
    left: -22%;
    top: 60%;
    right: 0;
    width: 125%;
    height: 100%;
    background: linear-gradient(270deg, #ffba00 0%, #ff7212 100%);
    transform: rotate(15deg);
  }
`;
