import { useState } from 'react';
import { ToTopOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import TiktokBumpProductsModal from '../TiktokBumpProductsModal/TiktokBumpProductsModal';

const TiktokBumpProductsButton = ({ postId }) => {
  const { t } = useTranslation(['pageOverview']);
  const [isBumpKeywordsClicked, setIsBumpKeywordsClicked] = useState(false);

  const handleOnBumpKeywordsClick = () => {
    setIsBumpKeywordsClicked(true);
  };

  return (
    <>
      <Button
        type="secondary"
        block
        icon={<ToTopOutlined />}
        style={{ color: '#2c278a', borderColor: '#2c278a' }}
        onClick={handleOnBumpKeywordsClick}
      >
        {t('pageOverview:form-action-button-tiktok-bump-products')}
      </Button>

      {isBumpKeywordsClicked && (
        <TiktokBumpProductsModal visible={isBumpKeywordsClicked} postId={postId} onClose={() => setIsBumpKeywordsClicked(false)} />
      )}
    </>
  );
};

export default TiktokBumpProductsButton;
