import { useEffect, useState } from 'react';

const potentialUrlRegex = /(https?:\/\/)?[\w]+\.[\S]{2,}/gm;
const matchUrlRegex = /^(https?:\/\/)?(?:[a-zA-Z0-9]{1,256}\.){1,}(?:[A-Za-z]{2,6}){1,}\b([()@:%_+~#?&//=][-a-zA-Z0-9()@:%_+.~#?&//=]*)?$/gm;
const hasHttpPrefixRegex = /^(https?:\/\/)./g;

const constructTextWithUrlObj = (text, isUrl = false) => {
  return {
    text,
    isUrl,
    ...(isUrl && { hasHttpPrefix: !!text.match(hasHttpPrefixRegex) })
  };
};

const TextWithUrl = ({ text }) => {
  const [textWithUrls, setTextWithUrls] = useState([]);

  useEffect(() => {
    const potentialUrls = text.match(potentialUrlRegex);
    const validUrls = potentialUrls
      ? potentialUrls.filter(potentialUrl => {
          return !!potentialUrl.match(matchUrlRegex);
        })
      : [];

    let mutatedText = text;
    let newTextWithUrls = [];
    let loop = 0;
    while (mutatedText.length > 0) {
      if (validUrls.length <= loop) {
        newTextWithUrls.push(constructTextWithUrlObj(mutatedText));
        mutatedText = '';
      } else {
        const validUrl = validUrls[loop];
        const validUrlIndex = mutatedText.indexOf(validUrl);
        const pureText = mutatedText.slice(0, validUrlIndex);
        newTextWithUrls.push(constructTextWithUrlObj(pureText));
        newTextWithUrls.push(constructTextWithUrlObj(validUrl, true));
        mutatedText = mutatedText.slice(validUrlIndex + validUrl.length);
        loop++;
      }
    }
    setTextWithUrls(newTextWithUrls);
  }, [text]);

  return (
    <p>
      {textWithUrls.map(textWithUrl => {
        return !textWithUrl.isUrl ? (
          textWithUrl.text
        ) : (
          <a key={textWithUrl.text} href={textWithUrl.hasHttpPrefix ? textWithUrl.text : `//${textWithUrl.text}`} target="_blank" rel="noreferrer">
            {textWithUrl.text}
          </a>
        );
      })}
    </p>
  );
};

export default TextWithUrl;
