import { Button, Row, Skeleton, Table } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useFetchConstant } from 'hooks/constants';

import Title from 'components/Title/Title';
import CsvDownload from 'components/CsvDownload/CsvDownload';

import { useGetProductSoldDetailsByOrders } from 'apis/reporting';
import { formatToDateTimeWithPMAM } from 'utils/date';
import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';
import { getOrderDetailRoute } from 'utils/routes';

import { PRODUCT_SOLD_CSV_HEADERS } from 'utils/constants';

import { ContentContainer, RootModal } from './ProductSoldModal.styles';

const constructColumns = ({ t, orderStatusesConst }) => [
  {
    ...constructColumn(t('pageReporting:product-modal-header-name'), 'label', { width: '20%' })
  },
  {
    ...constructColumn(t('pageReporting:product-modal-header-keyword'), 'matchingKeyword', { width: '10%' })
  },
  {
    ...constructColumn(t('pageReporting:product-modal-header-purchased-date'), 'purchasedDate', {
      hasSorter: true,
      width: '20%'
    }),
    render: purchasedDate => <span>{formatToDateTimeWithPMAM(purchasedDate)}</span>
  },
  {
    ...constructColumn(t('pageReporting:product-modal-header-quantity-sold'), 'quantitySold', {
      hasSorter: true,
      isNumber: true,
      width: '10%'
    })
  },
  {
    ...constructColumn(t('pageReporting:product-modal-header-total-price'), 'totalPrice', {
      hasSorter: true,
      isNumber: true,
      isPrice: true,
      width: '10%'
    })
  },
  {
    ...constructColumn(t('pageReporting:product-modal-header-order-number'), 'orderNumber', {
      hasSorter: true,
      width: '15%'
    }),
    ...constructColumnFilterSearch('orderNumber', t('pageReporting:product-modal-header-action-search-order-number'), {}),
    render: (label, record) => {
      return record.status === orderStatusesConst.CANCELLED.oriLabel || record.status === orderStatusesConst.COMPLETED.oriLabel ? (
        <p style={{ marginBottom: 0 }}>
          {label}
          <br />
          <small>{record.status}</small>
        </p>
      ) : (
        <a style={{ marginBottom: 0 }} href={getOrderDetailRoute(record.orderId).path} target="_blank" rel="noopener noreferrer">
          {label}
          <br />
          <small>{record.status}</small>
        </a>
      );
    }
  },
  {
    ...constructColumn(t('pageReporting:product-modal-header-buyer-name'), 'buyerName', {
      hasSorter: true,
      width: '20%'
    }),
    ...constructColumnFilterSearch('buyerName', t('pageReporting:product-modal-header-action-search-buyer-name'), {})
  }
];

const ProductSoldModal = ({ securityPasscode, startDate, endDate, selectedProductSold, onClose }) => {
  const { t } = useTranslation(['pageReporting']);
  const { isLoading: isOrderStatusesLoading, data: orderStatusesConst } = useFetchConstant('orderStatuses');
  const { isLoading: isReportingProductSoldDetailsLoading, data: productSoldDetails } = useGetProductSoldDetailsByOrders({
    securityPasscode,
    productId: selectedProductSold.productId,
    varianceId: selectedProductSold.varianceId,
    orderIds: selectedProductSold.orders
  });

  const isLoading = isOrderStatusesLoading || isReportingProductSoldDetailsLoading;

  const asyncExportMethod = async () => {
    return productSoldDetails.map(productSoldDetail => {
      return { ...productSoldDetail, purchasedDate: formatToDateTimeWithPMAM(productSoldDetail.purchasedDate) };
    });
  };

  return (
    <RootModal
      visible
      onCancel={onClose}
      bodyStyle={{ overflowX: 'auto' }}
      title={
        <Row justify="space-between" align="middle" style={{ marginRight: 24 }}>
          <Title>{t('pageReporting:product-modal-title')}</Title>

          <CsvDownload
            asyncExportMethod={asyncExportMethod}
            headers={PRODUCT_SOLD_CSV_HEADERS}
            onDataLoading={() => {}}
            onDataLoaded={() => {}}
            filename={`Product_Sold_${selectedProductSold.matchingKeyword}_${startDate}-${endDate}.csv`}
          >
            <Button style={{ marginLeft: 10 }} type="primary" onClick={() => {}}>
              <DownloadOutlined />
            </Button>
          </CsvDownload>
        </Row>
      }
      footer={null}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <ContentContainer>
          <Table columns={constructColumns({ t, orderStatusesConst })} dataSource={productSoldDetails} />
        </ContentContainer>
      )}
    </RootModal>
  );
};

export default ProductSoldModal;
