import { useCallback, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Alert, Col, Form, message, Modal, Row, Space, Tag } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { getMergedOrdersSummary } from 'apis/order';
import { guard } from 'utils/general';

import FormInput from 'components/FormInput/FormInput';
import OrderMergeSummaryModal, { useOrderMergeSummaryModal } from './components/OrderMergeSummaryModal/OrderMergeSummaryModal';

const { useForm } = Form;

/* =================================================== Exported Functions =================================================== */
export const useOrderMergeModal = () => {
  const [isOrderMergeModalVisible, setIsOrderMergeModalVisible] = useState(false);

  const handleOnMergeOrderBtnClick = useCallback(() => {
    setIsOrderMergeModalVisible(true);
  }, []);

  const handleOnMergeOrderModalCancel = useCallback(() => {
    setIsOrderMergeModalVisible(false);
  }, []);

  return { isOrderMergeModalVisible, handleOnMergeOrderBtnClick, handleOnMergeOrderModalCancel };
};

/* =================================================== Main Component =================================================== */
const OrderMergeModal = ({
  orderStatuses,
  orderStatusesConst,
  paymentMethods,
  countries,
  productTypesConst,
  socialMediaSourcesConst,
  storeCustomWAMessage,
  isEmailCheckoutLinkEnabled,
  onMergeOrder,
  onCancel
}) => {
  const { t } = useTranslation(['pageOrder']);
  const [form] = useForm();

  const {
    isOrderMergeSummaryModalVisible,
    mergedOrder,
    handleOnViewSummaryBtnClick,
    handleOnMergeOrderSummaryModalCancel
  } = useOrderMergeSummaryModal();

  const [isPreviewing, setIsPreviewing] = useState(false);

  const handleOnPreview = useCallback(() => {
    setIsPreviewing(true);

    form
      .validateFields()
      .then(async ({ mainOrderNumber, subOrderNumber }) => {
        await getMergedOrdersSummary({ mainOrderNumber, subOrderNumber }).then(mergedOrder => {
          handleOnViewSummaryBtnClick(mergedOrder);
        });
      })
      .catch(ex => {
        if (guard(() => ex.message.includes('Ended Order(s).'), false)) {
          const endedOrdersDetailsInString = ex.message.split(':')[1];
          const endedOrdersDetails = endedOrdersDetailsInString.split(',');

          endedOrdersDetails.forEach(endedOrderDetailsInString => {
            const [orderNumber, orderStatus] = endedOrderDetailsInString.split('|');

            message.error({
              content: (
                <span>
                  {orderStatus === orderStatusesConst.CANCELLED.code
                    ? t('pageOrder:merge-order-modal-error-order-cancelled', { orderNumber })
                    : t('pageOrder:merge-order-modal-error-order-completed', { orderNumber })}
                </span>
              )
            });
          });
        } else {
          message.error({
            content: <span>{t('pageOrder:merge-order-modal-error-preview-merged-order')}</span>
          });
        }
        setIsPreviewing(false);
      });
  }, [t, form, orderStatusesConst, handleOnViewSummaryBtnClick]);

  const handleOnMergeOrder = useCallback(() => {
    setIsPreviewing(false);
    onMergeOrder();
  }, [onMergeOrder]);

  const onMergeOrderSummaryModalCancel = useCallback(() => {
    setIsPreviewing(false);
    handleOnMergeOrderSummaryModalCancel();
  }, [handleOnMergeOrderSummaryModalCancel]);

  return (
    <>
      <Modal
        visible
        width="60%"
        title={t('pageOrder:merge-order-title')}
        okText={t('pageOrder:merge-order-modal-preview-button')}
        cancelText={t('common:modal-cancel-text')}
        confirmLoading={isPreviewing}
        cancelButtonProps={{ loading: isPreviewing }}
        onOk={handleOnPreview}
        onCancel={onCancel}
      >
        <Alert
          type="warning"
          style={{ marginBottom: 20 }}
          message={
            <Space direction="vertical">
              <Row align="middle">
                <Tag color="#ffba00">
                  <b>
                    <InfoCircleOutlined />
                  </b>
                </Tag>
                <span>
                  <b>{t('pageOrder:merge-order-modal-note')}:</b>
                </span>
              </Row>
              <ul>
                <li>{t('pageOrder:merge-order-modal-note-desc-1')}</li>
                <li>{t('pageOrder:merge-order-modal-note-desc-2')}</li>
                <li>
                  <Trans i18nKey="pageOrder:merge-order-modal-note-desc-3">
                    You can only merge orders that are <b>NOT</b> completed or cancelled
                  </Trans>
                </li>
              </ul>
            </Space>
          }
        />
        <Form form={form}>
          <Row>
            <Col span={24}>
              <FormInput
                label={t('pageOrder:merge-order-modal-main-order-number-label')}
                name="mainOrderNumber"
                placeholder={t('pageOrder:merge-order-modal-main-order-number-placeholder')}
                requiredErrorMessage={t('pageOrder:merge-order-modal-main-order-number-requiredErrorMessage')}
              />
            </Col>
            <Col span={24}>
              <FormInput
                label={t('pageOrder:merge-order-modal-sub-order-number-label')}
                name="subOrderNumber"
                placeholder={t('pageOrder:merge-order-modal-sub-order-number-placeholder')}
                requiredErrorMessage={t('pageOrder:merge-order-modal-sub-order-number-requiredErrorMessage')}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
      {isOrderMergeSummaryModalVisible && (
        <OrderMergeSummaryModal
          orderStatuses={orderStatuses}
          paymentMethods={paymentMethods}
          countries={countries}
          productTypesConst={productTypesConst}
          socialMediaSourcesConst={socialMediaSourcesConst}
          mergedOrder={mergedOrder}
          storeCustomWAMessage={storeCustomWAMessage}
          isEmailCheckoutLinkEnabled={isEmailCheckoutLinkEnabled}
          onMergeOrder={handleOnMergeOrder}
          onCancel={onMergeOrderSummaryModalCancel}
        />
      )}
    </>
  );
};

export default OrderMergeModal;
