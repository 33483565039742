export const ROOT_LAYOUT_SCROLL_ID = 'scroll-main-layout'; // For layout scroll purpose

/** -------------------------------------Scroll------------------------------------- */
const getScrollElement = () => {
  return document.getElementById(ROOT_LAYOUT_SCROLL_ID);
};

const scrollTo = (top, behavior) => {
  const scrollElement = getScrollElement() || window;
  scrollElement.scrollTo({ top, behavior });
};

export const scrollToTop = isSmooth => {
  scrollTo(0, isSmooth ? 'smooth' : undefined);
};

export const scrollToBottom = isSmooth => {
  const scrollElement = getScrollElement();
  scrollTo(scrollElement.scrollHeight, isSmooth ? 'smooth' : undefined);
};
