import { useCallback, useEffect, useState, useMemo } from 'react';
import { Col, message, Modal } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  patchLuckyDrawEnd,
  patchLuckyDrawStartRolling,
  postLuckyDrawWinner,
  postLuckyDrawConfig,
  useGetLuckyDrawByIdForSlotMachine
} from 'apis/luckyDraw';
import { postPublishCommentToPost } from 'apis/post';
import { useRefetchStoreOrderLimit } from 'apis/store';

import { withAppContext } from 'contexts/AppContext/AppContext';
import { useFetchConstant } from 'hooks/constants';
import { logError } from 'utils/logging';

import {
  WrapperContainer,
  ContainerMask,
  ContentContainer,
  ContainerWrapperGlow,
  ScrollableWrapper,
  SlotMachineContainer,
  SlotMachineContentContainer,
  SlotMachineHeader,
  SlotMachineBody,
  SlotMachineFooter,
  SMBodyContainter,
  StoreName
} from './LuckyDrawSlotMachine.styles';

import Slot from './components/Slot/Slot';
import WinnerBoard from './components/WinnerBoard/WinnerBoard';
import PotentialWinnersModal from './components/PotentialWinnersModal/PotentialWinnersModal';
import SlotMachineHeadPart from './images/slot-machine-1.png';
import SlotMachineFootPart from './images/slot-machine-2.png';

const PLAYERS_LIMIT = 50;

const shuffle = arr => {
  let currentIndex = arr.length;
  let randomIndex;
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [arr[currentIndex], arr[randomIndex]] = [arr[randomIndex], arr[currentIndex]];
  }

  return arr;
};

const constructParticipantsIntoSlotItems = (spinNowButtonText, participants, potentialWinners) => {
  const potentialWinnerIndex = participants.findIndex(participant => participant._id === potentialWinners[0]);
  const potentialWinnerDetails = participants[potentialWinnerIndex];
  participants.splice(potentialWinnerIndex, 1);
  if (participants.length < PLAYERS_LIMIT) {
    const iterator = PLAYERS_LIMIT - participants.length;
    for (let i = 0; i < iterator; i++) {
      const j = i > participants.length ? i - participants.length : i;
      participants.push(participants[j]);
    }
  }

  const slotItems1 = [spinNowButtonText, ...participants];
  const slotItems2 = [spinNowButtonText, ...[...participants].reverse()];
  const slotItems3 = [spinNowButtonText, ...shuffle(participants)];

  if (potentialWinnerDetails) {
    slotItems1.push(potentialWinnerDetails);
    slotItems2.push(potentialWinnerDetails);
    slotItems3.push(potentialWinnerDetails);
  }

  return {
    slotItems1,
    slotItems2,
    slotItems3
  };
};

const getWinnerCommentMessage = (name, prizeRemarks, storeCurrencyISO) => {
  const englishMessage = `Hi ${name}! You have received gift - ${prizeRemarks}.`;
  // FIXME: This is temporary to support india test market
  if (storeCurrencyISO !== 'MYR') {
    return englishMessage;
  }

  const chineseMessage = `嗨 ${name}！您已获得礼物 - ${prizeRemarks}！`;
  return `${englishMessage}\n\n${chineseMessage}`;
};

const SlotMachine = ({ children }) => {
  return (
    <SlotMachineContainer>
      <SlotMachineContentContainer justify="center">
        <SMHeader />
        <SMBody>{children}</SMBody>
        <SMFooter />
      </SlotMachineContentContainer>
    </SlotMachineContainer>
  );
};

const SMHeader = () => {
  return (
    <SlotMachineHeader>
      <img src={SlotMachineHeadPart} alt="slot-machine-head-part" width="100%" style={{ height: 'intrinsic' }} />
    </SlotMachineHeader>
  );
};

const SMBody = ({ children }) => {
  return <SlotMachineBody>{children}</SlotMachineBody>;
};

const SMFooter = () => {
  return (
    <SlotMachineFooter>
      <img src={SlotMachineFootPart} alt="logo" width="100%" style={{ height: 'intrinsic' }} />
    </SlotMachineFooter>
  );
};

const useGetLuckyDrawDetails = luckyDrawId => {
  const { data: socialMediaSourcesConst, isLoading: isSocialMediaSourcesLoading } = useFetchConstant('socialMediaSources');

  const luckyDrawPostProcessFunc = useCallback(
    luckyDrawDetails => {
      return {
        ...luckyDrawDetails,
        isAllowPostSocialMedia: socialMediaSourcesConst && luckyDrawDetails.post.source === socialMediaSourcesConst.FB.code
      };
    },
    [socialMediaSourcesConst]
  );

  const { data: luckyDrawDetails, isLoading: isLuckyDrawDetailsLoading, refetch: refetchLuckyDrawDetails } = useGetLuckyDrawByIdForSlotMachine(
    luckyDrawId,
    { playerLimit: PLAYERS_LIMIT },
    { postProcessFunc: luckyDrawPostProcessFunc }
  );

  return { isLuckyDrawDetailsLoading: isSocialMediaSourcesLoading || isLuckyDrawDetailsLoading, luckyDrawDetails, refetchLuckyDrawDetails };
};

const LuckyDrawSlotMachine = ({ store, storeCurrency }) => {
  const { t } = useTranslation(['common', 'pageLuckyDrawSlotMachine', 'pageOverview']);
  const { id: luckyDrawId } = useParams();
  const { luckyDrawDetails, isLuckyDrawDetailsLoading, refetchLuckyDrawDetails } = useGetLuckyDrawDetails(luckyDrawId);
  const { data: luckyDrawStatusesConst, isLoading: isLuckyDrawStatusesLoading } = useFetchConstant('luckyDrawStatuses');
  const { data: productTypesConst, isLoading: isProductTypesLoading } = useFetchConstant('productTypes');
  const [timer, setTimer] = useState(0);
  const [isSpinClick, setIsSpinClick] = useState(false);
  const [isOpenPotentialWinnersModal, setIsOpenPotentialWinnersModal] = useState(false);
  const [isSelectingWinner, setIsSelectingWinner] = useState(false);

  const spinNowButtonText = useMemo(() => t('pageLuckyDrawSlotMachine:spin-now-button'), [t]);
  const [slotItems1, setSlotItems1] = useState([spinNowButtonText]);
  const [slotItems2, setSlotItems2] = useState([spinNowButtonText]);
  const [slotItems3, setSlotItems3] = useState([spinNowButtonText]);

  const refetchStoreOrderLimit = useRefetchStoreOrderLimit();

  const isLoading = isLuckyDrawDetailsLoading || isLuckyDrawStatusesLoading || isProductTypesLoading;

  const isRolling = useMemo(() => !isLoading && luckyDrawDetails && luckyDrawDetails.status === luckyDrawStatusesConst.ROLLING.code, [
    isLoading,
    luckyDrawDetails,
    luckyDrawStatusesConst
  ]);
  const isEnded = useMemo(() => !isLoading && luckyDrawDetails && luckyDrawDetails.status === luckyDrawStatusesConst.ENDED.code, [
    isLoading,
    luckyDrawDetails,
    luckyDrawStatusesConst
  ]);
  const hasPotentialWinner = useMemo(
    () => !isLuckyDrawDetailsLoading && luckyDrawDetails && luckyDrawDetails.potentialWinners && luckyDrawDetails.potentialWinners.length > 0,
    [isLuckyDrawDetailsLoading, luckyDrawDetails]
  );
  const hasRollablePlayers = useMemo(
    () => !isLuckyDrawDetailsLoading && luckyDrawDetails && luckyDrawDetails.rollablePlayers && luckyDrawDetails.rollablePlayers.length > 0,
    [isLuckyDrawDetailsLoading, luckyDrawDetails]
  );
  const potentialWinnersDetails = useMemo(() => {
    let potentialWinners = [];
    if (luckyDrawDetails.potentialWinners && luckyDrawDetails.potentialWinners.length > 0) {
      luckyDrawDetails.potentialWinners.forEach(potentialWinner => {
        potentialWinners.push(luckyDrawDetails.rollablePlayers.find(player => potentialWinner === player._id));
      });
    }
    return potentialWinners;
  }, [luckyDrawDetails]);

  useEffect(() => {
    if (hasPotentialWinner && hasRollablePlayers) {
      const slots = constructParticipantsIntoSlotItems(
        spinNowButtonText,
        [...luckyDrawDetails.rollablePlayers],
        [...luckyDrawDetails.potentialWinners]
      );
      setSlotItems1(slots.slotItems1);
      setSlotItems2(slots.slotItems2);
      setSlotItems3(slots.slotItems3);
    }
  }, [hasPotentialWinner, hasRollablePlayers, luckyDrawDetails, spinNowButtonText]);

  useEffect(() => {
    if (isSpinClick) {
      const randomTimer = Math.floor(Math.random() * 6000);
      const timer = randomTimer > 4000 ? randomTimer : 4000;
      setTimer(timer); // use timer directly will be too fast, add this for adequate time for scrolling effect
      const pauseAndOpenModalTimer = setTimeout(() => {
        setIsOpenPotentialWinnersModal(true);
      }, timer + 1500); // for pause effect on winner selection
      return () => clearTimeout(pauseAndOpenModalTimer);
    }
  }, [isSpinClick]);

  const handleOnStartRoll = () => {
    const numOfTotalPlayers = luckyDrawDetails && luckyDrawDetails.totalPlayers;
    if (!numOfTotalPlayers) {
      Modal.error({
        title: <span>{t('pageLuckyDrawSlotMachine:start-roll-error-title')}</span>,
        content: <span>{t('pageLuckyDrawSlotMachine:start-roll-error-content')}</span>,
        centered: true,
        width: 'fit-content'
      });
    } else {
      Modal.confirm({
        title: <span>{t('pageLuckyDrawSlotMachine:start-roll-confirm-title')}</span>,
        content: <span>{t('pageLuckyDrawSlotMachine:start-roll-confirm-content')}</span>,
        onOk: () =>
          patchLuckyDrawStartRolling(luckyDrawId)
            .then(() => {
              refetchLuckyDrawDetails();
            })
            .catch(err => {
              // eslint-disable-next-line no-console
              console.log('Fail to update lucky draw status to rolling.');
              message.error(t('pageLuckyDrawSlotMachine:start-roll-confirm-fail'));
            }),
        centered: true,
        width: 'fit-content',
        okText: t('pageLuckyDrawSlotMachine:start-roll-confirm-ok'),
        cancelText: t('common:modal-cancel-text')
      });
    }
  };

  const handleOnStartSpin = () => {
    if (hasRollablePlayers && luckyDrawDetails.rollablePlayers.length > 0) {
      if (!isSpinClick) {
        setIsSpinClick(true);
      }
    } else {
      message.error(t('pageLuckyDrawSlotMachine:no-rollable-players-error-message'));
    }
  };

  const postWinnerToFb = (winnerName, prizeRemarks) => {
    postPublishCommentToPost(luckyDrawDetails.post._id, { message: getWinnerCommentMessage(winnerName, prizeRemarks, storeCurrency.iso) })
      .then(() => {
        message.success(t('pageOverview:live-comment-post-success-message'));
      })
      .catch(error => {
        logError(error);
        message.error(t('pageOverview:live-comment-post-fail-message'));
      });
  };

  const handleOnAcceptWinner = ({ selectedPrizeType, prizeRemarks, prizeProductKeyword, isPostingWinnerToFB, winnerName, onSuccess, onFail }) => {
    const numOfPotentialWinners = luckyDrawDetails.potentialWinners.length;

    setIsSelectingWinner(true);

    postLuckyDrawWinner(luckyDrawId, { isAccepted: true, selectedPrizeType, prizeRemarks, prizeProductKeyword, numOfPotentialWinners })
      .then(() => {
        message.success(t('pageLuckyDrawSlotMachine:accept-winner-success'));
        onSuccess();
        setIsSpinClick(false);
        setIsOpenPotentialWinnersModal(false);
        refetchLuckyDrawDetails();
        refetchStoreOrderLimit();
        if (isPostingWinnerToFB) {
          postWinnerToFb(winnerName, prizeRemarks);
        }
      })
      .catch(error => {
        onFail();
        message.error(error.message);
      })
      .finally(() => {
        setIsSelectingWinner(false);
      });
  };

  const handleOnSkipWinner = () => {
    const numOfPotentialWinners = luckyDrawDetails.potentialWinners.length;

    postLuckyDrawWinner(luckyDrawId, { isAccepted: false, numOfPotentialWinners })
      .then(() => {
        message.success(t('pageLuckyDrawSlotMachine:skip-winner-success'));
        setIsSpinClick(false);
        setIsOpenPotentialWinnersModal(false);
        refetchLuckyDrawDetails();
      })
      .catch(() => {
        message.error(t('pageLuckyDrawSlotMachine:skip-winner-fail'));
      });
  };

  const handleOnSetLuckyDrawConfig = ({ numOfPotentialWinners, onSuccess, onFail }) => {
    return postLuckyDrawConfig(luckyDrawId, { numOfPotentialWinners })
      .then(() => {
        message.success(t('pageLuckyDrawSlotMachine:config-success'));
        onSuccess();
        refetchLuckyDrawDetails();
      })
      .catch(() => {
        onFail();
        message.error(t('pageLuckyDrawSlotMachine:config-fail'));
      });
  };

  const handleOnEndLuckyDraw = () => {
    Modal.confirm({
      title: <span>{t('pageLuckyDrawSlotMachine:end-roll-confirm-title')}</span>,
      content: <span>{t('pageLuckyDrawSlotMachine:end-roll-confirm-content')}</span>,
      onOk: () =>
        patchLuckyDrawEnd(luckyDrawId)
          .then(() => {
            refetchLuckyDrawDetails();
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log('Fail to update lucky draw status to rolling.');
            message.error(t('pageLuckyDrawSlotMachine:end-roll-confirm-fail'));
          }),
      centered: true,
      width: 'fit-content',
      okText: t('pageLuckyDrawSlotMachine:end-roll-confirm-ok'),
      cancelText: t('common:modal-cancel-text')
    });
  };

  return (
    <WrapperContainer>
      <Helmet>
        <meta name="title" id="gtm-title" content="Lucky Draw" />
        <title>{t('pageLuckyDrawSlotMachine:page-title')}</title>
      </Helmet>
      <ContainerMask>
        <ScrollableWrapper>
          <ContentContainer>
            {!isEnded && (
              <SlotMachine>
                <SMBodyContainter>
                  {store && (
                    <Col span={24}>
                      <StoreName>{store.name}</StoreName>
                    </Col>
                  )}
                  {!isLoading &&
                    (!isRolling ? (
                      <Col span={24}>
                        <ContainerWrapperGlow onClick={handleOnStartRoll}>{t('pageLuckyDrawSlotMachine:start-to-roll-button')}</ContainerWrapperGlow>
                      </Col>
                    ) : (
                      <>
                        <Col span={24} md={12} xl={8}>
                          <Slot
                            isGlowing={!isSpinClick}
                            timer={timer}
                            winnerIndex={isSpinClick ? PLAYERS_LIMIT + 1 : 0}
                            slotItems={slotItems1}
                            onSpinClick={handleOnStartSpin}
                          />
                        </Col>
                        <Col span={0} md={12} xl={8}>
                          <Slot
                            isGlowing={!isSpinClick}
                            timer={timer}
                            winnerIndex={isSpinClick ? PLAYERS_LIMIT + 1 : 0}
                            slotItems={slotItems2}
                            onSpinClick={handleOnStartSpin}
                          />
                        </Col>
                        <Col span={0} xl={8}>
                          <Slot
                            isGlowing={!isSpinClick}
                            timer={timer}
                            winnerIndex={isSpinClick ? PLAYERS_LIMIT + 1 : 0}
                            slotItems={slotItems3}
                            onSpinClick={handleOnStartSpin}
                          />
                        </Col>
                      </>
                    ))}
                </SMBodyContainter>
              </SlotMachine>
            )}
            <WinnerBoard
              isLuckyDrawEnded={isEnded}
              isAllowConfig={hasRollablePlayers}
              winners={luckyDrawDetails.winners}
              maxNumOfPotentialWinners={Math.min(luckyDrawDetails.totalRollablePlayers || 0, PLAYERS_LIMIT)}
              totalPotentialWinners={luckyDrawDetails.potentialWinners && luckyDrawDetails.potentialWinners.length}
              totalParticipants={luckyDrawDetails.totalPlayers}
              onSetLuckyDrawConfig={handleOnSetLuckyDrawConfig}
              onEndLuckyDraw={handleOnEndLuckyDraw}
            />
            {!isLoading && isOpenPotentialWinnersModal && (
              <PotentialWinnersModal
                visible={isOpenPotentialWinnersModal}
                winners={potentialWinnersDetails}
                isSubmitting={isSelectingWinner}
                isAllowPostSocialMedia={luckyDrawDetails.isAllowPostSocialMedia}
                productTypesConst={productTypesConst}
                onAcceptWinner={handleOnAcceptWinner}
                onSkipWinner={handleOnSkipWinner}
              />
            )}
          </ContentContainer>
        </ScrollableWrapper>
      </ContainerMask>
    </WrapperContainer>
  );
};

export default withAppContext(LuckyDrawSlotMachine);
