import styled from '@emotion/styled';

import Img4 from './images/4.png';
import MImg4 from './images/4-mobile.png';

export const FourthContentTitle = styled.p`
  font-size: 1.2em;
  line-height: 1.5em;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 0;
  margin-left: 4vw;
  margin-top: 30vh;
  text-align: left;

  @media (min-width: 390px) {
    font-size: 1.5em;
    line-height: 1.5em;
    margin-left: 24%;
  }

  @media (min-width: 414px) {
    font-size: 1.5em;
    margin-top: 24vh;
  }

  @media (min-width: 768px) {
    font-size: 2.2em;
    margin-left: 24%;
    margin-top: 48vh;
  }

  @media (min-width: 820px) {
    font-size: 2.5em;
    margin-left: 2vw;
    margin-top: 44vh;
  }

  @media (min-width: 1024px) {
    font-size: 2em;
    margin-left: 16vw;
    margin-right: 16vw;
    margin-top: 20vh;
  }

  @media (min-width: 1200px) {
    font-size: 2.5em;
    margin-left: 16vw;
    margin-right: 16vw;
    margin-top: 24vh;
  }
`;

export const FourthImg = styled.div`
  display: block;
  position: absolute;
  z-index: -1; // To prevent btn event being covered

  background-image: url(${MImg4});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  height: 30vh;
  width: 66vw;
  top: 0vh;

  @media (min-width: 414px) {
    height: 24vh;
    width: 66vw;
  }

  @media (min-width: 768px) {
    margin-left: 0;
    height: 78vh;
    width: 72vw;
    top: -16vh;
  }

  @media (min-width: 820px) {
    margin-left: 0;
    height: 84vh;
    width: 64vw;
    top: -20vh;
  }

  @media (min-width: 1024px) {
    background-image: url(${Img4});
    height: 68vh;
    width: 64vw;
    top: -6vh;
  }

  @media (min-width: 1200px) {
    height: 84vh;
    width: 72vw;
    top: -4vh;
  }
`;
