import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Table } from 'antd';
import { CheckOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import TextButton from 'components/TextButton/TextButton';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';

import { useFetchConstant } from 'hooks/constants';
import { constructColumn } from 'utils/table/table';
import { constructAbbrWeightValue } from 'utils/general';

const { useForm } = Form;

const defaultData = {
  min: 0,
  fee: 3
};

const getNewDefaultData = data => {
  const lastData = data[data.length - 1];
  if (lastData) {
    return { min: lastData.min + 1, fee: lastData.fee };
  }
  return defaultData;
};

const constructColumns = ({ t, editingKey, handleOnSaveClick, handleOnConfirmDeleteClick, handleOnEditClick }) => [
  {
    ...constructColumn(t('pageShipmentDetails:weight-tier-table-header-min-weight'), 'min', { width: '10%' }),
    editable: editingKey !== 0,
    render: text => constructAbbrWeightValue(text)
  },
  {
    ...constructColumn(t('pageShipmentDetails:weight-tier-table-header-fee'), 'fee', { width: '10%' }),
    editable: true,
    render: text => <PriceDisplay amount={text} />
  },
  {
    ...constructColumn(t('pageShipmentDetails:weight-tier-table-header-actions'), 'actions', { width: '15%' }),
    render: (okText, record, index) => {
      const editable = index === editingKey;
      return editable ? (
        <div>
          <TextButton
            icon={<CheckOutlined />}
            onClick={() => handleOnSaveClick(index)}
            text={t('pageShipmentDetails:weight-tier-text-button-update')}
            tooltipMessage={t('common:text-button-tooltip-message', { action: t('pageShipmentDetails:weight-tier-tooltip-message-action-update') })}
            htmlType="submit"
          />
          {index !== 0 && (
            <TextButton
              icon={<DeleteOutlined />}
              onClick={() => handleOnConfirmDeleteClick(index)}
              text={t('pageShipmentDetails:weight-tier-text-button-remove')}
              tooltipMessage={t('common:text-button-tooltip-message', { action: t('pageShipmentDetails:weight-tier-tooltip-message-action-remove') })}
            />
          )}
        </div>
      ) : (
        <div>
          <TextButton
            icon={<EditOutlined />}
            onClick={() => handleOnEditClick(record, index)}
            text={t('pageShipmentDetails:weight-tier-text-button-edit')}
            tooltipMessage={t('common:text-button-tooltip-message', { action: t('pageShipmentDetails:weight-tier-tooltip-message-action-edit') })}
            disabled={editingKey !== ''}
          />
        </div>
      );
    }
  }
];

const WeightTiers = ({ weightTiers, onUpdate = () => {}, onError = () => {} }) => {
  const { t } = useTranslation(['common', 'pageShipmentDetails']);
  const [form] = useForm();
  const [data, setData] = useState(weightTiers);
  const [editingKey, setEditingKey] = useState('');
  const { selection: shipmentStatuses, data: shipmentStatusesConst } = useFetchConstant('shipmentStatuses');

  useEffect(() => {
    if (weightTiers.length) {
      setData(weightTiers);
    } else {
      setData([defaultData]);
      onUpdate([defaultData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weightTiers]);

  const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    const getInputNode = () => {
      switch (inputType) {
        case 'number':
          return (
            <FormInput
              name={dataIndex}
              requiredErrorMessage={t('pageShipmentDetails:weight-tier-cell-form-error-message', { itemName: title.toLowerCase() })}
              placeholder="20"
              type="numeric"
              extraProps={{ suffix: 'gram' }}
            />
          );

        case 'price':
          return (
            <FormInputNumber
              name={dataIndex}
              type="financial"
              requiredErrorMessage={t('pageShipmentDetails:weight-tier-cell-form-error-message', { itemName: title.toLowerCase() })}
              placeholder="100.00"
            />
          );
        default:
          return (
            <FormInput
              name={dataIndex}
              requiredErrorMessage={t('pageShipmentDetails:weight-tier-cell-form-error-message', { itemName: title.toLowerCase() })}
              placeholder={title}
            />
          );
      }
    };

    return <td {...restProps}>{editing ? getInputNode() : children}</td>;
  };

  const handleOnAddButtonClick = () => {
    const newDefaultData = getNewDefaultData(data);
    const newData = [...data, newDefaultData];
    setData(newData);
    form.setFieldsValue(newDefaultData);
    setEditingKey(newData.length - 1);
  };

  const handleOnEditClick = (record, index) => {
    form.setFieldsValue({
      ...record
    });
    setEditingKey(index);
  };

  const handleOnSaveClick = index => {
    const { min, fee } = form.getFieldsValue();
    const currentWeightTier = { min: Number(min), fee: Number(fee) };
    const newData = [...data];

    if (index === 0) {
      currentWeightTier.min = 0;
    }

    if (!newData.find(data => data.min === currentWeightTier.min) || newData.findIndex(data => data.min === currentWeightTier.min) === index) {
      newData.splice(index, 1, currentWeightTier);
      const sortedNewData = newData.sort((dataA, dataB) => dataA.min - dataB.min);
      setData(sortedNewData);
      setEditingKey('');
      onUpdate(sortedNewData);
    } else {
      onError(t('pageShipmentDetails:weight-tier-same-condition'));
    }
  };

  const handleOnConfirmDeleteClick = index => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);

    setEditingKey('');
    onUpdate(newData);
  };

  const getEditableCols = () => {
    const columns = constructColumns({
      t,
      editingKey,
      shipmentStatuses,
      shipmentStatusesConst,
      handleOnSaveClick,
      handleOnConfirmDeleteClick,
      handleOnEditClick
    });

    return columns.map(col => {
      if (!col.editable) {
        return col;
      }

      const getInputType = () => {
        switch (col.dataIndex) {
          case 'min':
            return 'number';

          case 'fee':
            return 'price';

          default:
            return 'text';
        }
      };

      return {
        ...col,
        onCell: (record, index) => {
          return {
            record,
            key: index,
            inputType: getInputType(col.dataIndex),
            dataIndex: col.dataIndex,
            title: col.title,
            editing: index === editingKey
          };
        }
      };
    });
  };

  return (
    <Form form={form} initialValues={data} component={false}>
      <Row gutter={[0, 16]}>
        <Button type="primary" onClick={handleOnAddButtonClick} icon={<PlusOutlined />} disabled={editingKey !== ''}>
          {t('pageShipmentDetails:weight-tier-add-button')}
        </Button>
      </Row>
      <Table
        rowKey={record => record.min}
        size="small"
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={data}
        columns={getEditableCols()}
        scroll={{ x: 'max-content' }}
        locale={{
          triggerDesc: t('common:table-header-sort-trigger-desc'),
          triggerAsc: t('common:table-header-sort-trigger-asc'),
          cancelSort: t('common:table-header-sort-cancel-sort')
        }}
        pagination={false}
      />
    </Form>
  );
};

export default WeightTiers;
