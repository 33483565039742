import styled from '@emotion/styled';
import { CheckCircleFilled } from '@ant-design/icons';
import { Collapse } from 'antd';

const { Panel } = Collapse;

export const ProgressContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
`;

export const ProgressTitle = styled.p`
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-left: 8px;
  margin-bottom: 0;
`;

export const StyledCollapse = styled(Collapse)`
  background: ${props => props.theme.color.white};
`;

export const StyledPanel = styled(Panel)`
  border-color: #f5f5f5;
  background: ${props => (props.isCompleted ? '#f6ffed' : props.theme.color.lighterTertiary)};
  margin-bottom: ${props => props.theme.spacing.regular};
  border-radius: 8px;
  border: ${props => (props.isCompleted ? '1px solid #b7eb8f' : 'none')};
`;

export const StyledPanelTitle = styled.span`
  color: ${props => (props.isCompleted ? props.theme.color.green : props.theme.color.tertiary)};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const CompletedIcon = styled(CheckCircleFilled)`
  color: ${props => props.theme.color.green};
`;
