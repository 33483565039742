import { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Checkbox, Col, Form, Modal, message, Row, Skeleton } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import {
  deleteShipment,
  postCreateShipment,
  patchShipment,
  useGetShipmentDetails,
  useRefetchShipments,
  getShipmentLinkedOrders
} from 'apis/shipment';

import BackButton from 'components/BackButton/BackButton';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import Title from 'components/Title/Title';
import DeleteShipmentOptionsModal from '../DeleteShipmentOptionsModal/DeleteShipmentOptionsModal';
import WeightTiers from '../WeightTiers/WeightTiers';

import { useFetchConstant } from 'hooks/constants';

import {
  DELETE_SHIPMENT_ORDER_ACTION_SET_TO_CANCEL,
  DELETE_SHIPMENT_ORDER_ACTION_SET_TO_COMPLETE,
  SHIPMENT_ACTION_DELETE,
  SHIPMENT_ACTION_DEACTIVATE,
  getFilteredConstants
} from 'utils/constants';
import { getLabelOfConstant } from 'utils/general';
import { getShipmentDetailRoute, getShipmentsRoute, getOrderDetailRoute } from 'utils/routes';

import { StatusIndicatorContainer, SuccessIcon, DangerUpdateContentCol } from './ShipmentDetails.styles';

const { useForm } = Form;
const { confirm, error } = Modal;

const deleteShipmentFn = ({ shipmentId, orderAction, t, history, setIsSubmitting }) => {
  deleteShipment(shipmentId, orderAction)
    .then(() => {
      message.success(t('common:delete-success-message', { itemName: t('pageShipmentDetails:item-name') }));
      history.push(getShipmentsRoute().path);
    })
    .catch(ex => {
      error({
        title: ex.message
      });
    })
    .finally(() => {
      setIsSubmitting(false);
    });
};

const useDeleteShipmentOptionModal = ({ shipmentId, t, history, setIsSubmitting }) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
    setIsSubmitting(false);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const handleOnDeleteShipmentCancel = () => {
    closeModal();
  };

  const handleOnDeleteShipmentMoveToCancel = () => {
    confirm({
      title: t('common:confirm-modal-delete-title', { itemName: t('pageShipmentDetails:item-name-lowercase') }),
      content: t('common:confirm-modal-delete-content'),
      icon: <ExclamationCircleOutlined />,
      okText: t('pageShipmentDetails:confirm-modal-delete-button-text'),
      cancelText: t('common:modal-cancel-text'),
      okButtonProps: { type: 'primary', danger: true },
      onOk() {
        deleteShipmentFn({ shipmentId, t, history, orderAction: DELETE_SHIPMENT_ORDER_ACTION_SET_TO_CANCEL, setIsSubmitting });
      },
      onCancel() {}
    });
  };

  const handleOnDeleteShipmentMoveToComplete = () => {
    confirm({
      title: t('common:confirm-modal-delete-title', { itemName: t('pageShipmentDetails:item-name-lowercase') }),
      content: t('common:confirm-modal-delete-content'),
      icon: <ExclamationCircleOutlined />,
      okText: t('pageShipmentDetails:confirm-modal-delete-button-text'),
      cancelText: t('common:modal-cancel-text'),
      okButtonProps: { type: 'primary', danger: true },
      onOk() {
        deleteShipmentFn({ shipmentId, t, history, orderAction: DELETE_SHIPMENT_ORDER_ACTION_SET_TO_COMPLETE, setIsSubmitting });
      },
      onCancel() {}
    });
  };

  const handleOnDeleteShipmentViewOrders = () => {
    getShipmentLinkedOrders(shipmentId, SHIPMENT_ACTION_DELETE)
      .then(({ ordersToBeUnset, ordersToBeMoved }) => {
        const totalLinkedOrders = ordersToBeUnset.concat(ordersToBeMoved);
        if (totalLinkedOrders.length <= 10) {
          totalLinkedOrders.forEach(order => window.open(getOrderDetailRoute(order._id).path));
        }
      })
      .finally(() => {
        closeModal();
      });
  };

  return {
    isDeleteShipmentOptionsModalVisible: isModalVisible,
    openDeleteShipmentOptionModal: openModal,
    handleOnDeleteShipmentCancel,
    handleOnDeleteShipmentMoveToCancel,
    handleOnDeleteShipmentMoveToComplete,
    handleOnDeleteShipmentViewOrders
  };
};

const ShipmentDetails = () => {
  const { t } = useTranslation(['common', 'pageShipmentDetails']);
  const history = useHistory();
  const [form] = useForm();
  const { id: shipmentId } = useParams();
  const [weightTiers, setWeightTiers] = useState([]);
  const [isIncludeShippingFee, setIsIncludeShippingFee] = useState(false);
  const [isChargingByWeight, setIsChargingByWeight] = useState(false);
  const [isAllowFreeDeliveryTarget, setIsAllowFreeDeliveryTarget] = useState(false);
  const [weightTiersErrorMsg, setWeightTiersErrorMsg] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isLoading: isShipmentLoading, data: shipment, refetch: refetchShipmentDetails } = useGetShipmentDetails(shipmentId);
  const {
    isDeleteShipmentOptionsModalVisible,
    openDeleteShipmentOptionModal,
    handleOnDeleteShipmentCancel,
    handleOnDeleteShipmentMoveToCancel,
    handleOnDeleteShipmentMoveToComplete,
    handleOnDeleteShipmentViewOrders
  } = useDeleteShipmentOptionModal({ shipmentId, t, history, setIsSubmitting });
  const refetchShipments = useRefetchShipments();
  const { selection: shipmentStatuses, data: shipmentStatusesConst, isLoading: isShipmentStatusesLoading } = useFetchConstant('shipmentStatuses');
  const { selection: deliveryMethods, data: deliveryMethodsConst, isLoading: isDeliveryMethodsLoading } = useFetchConstant('deliveryMethods');
  const { selection: chargingMethods, data: chargingMethodsConst, isLoading: isChargingMethodsLoading } = useFetchConstant('chargingMethods');

  const isEditMode = useMemo(() => !!shipmentId, [shipmentId]);
  const isLoading = isShipmentLoading || isShipmentStatusesLoading || isDeliveryMethodsLoading || isChargingMethodsLoading;

  const getLinkedOrdersForDeletionFn = useCallback(async () => {
    if (shipmentId) {
      return getShipmentLinkedOrders(shipmentId, SHIPMENT_ACTION_DELETE);
    }
  }, [shipmentId]);

  useEffect(() => {
    if (!isLoading) {
      if (!isEditMode) {
        form.setFieldsValue({
          status: shipmentStatusesConst.ACTIVE.code,
          deliveryMethod: deliveryMethodsConst.COURIER.code,
          chargingMethod: chargingMethodsConst.FLAT_RATE.code
        });
        setIsIncludeShippingFee(true);
        setIsChargingByWeight(false);
      } else {
        if (shipment) {
          const isIncludeShippingFeeDeliveryMethods = getFilteredConstants(deliveryMethodsConst, 'isIncludeShippingFee', true);

          setWeightTiers(shipment.shippingFeeWeightTiers);
          setIsIncludeShippingFee(isIncludeShippingFeeDeliveryMethods.includes(shipment.deliveryMethod));
          setIsChargingByWeight(shipment.chargingMethod === chargingMethodsConst.WEIGHT.code);
          setIsAllowFreeDeliveryTarget(!!shipment.freeDeliveryTargetAmount);
        }
      }
    }
  }, [isEditMode, isLoading, form, shipment, shipmentStatusesConst, deliveryMethodsConst, chargingMethodsConst]);

  const handleOnSave = async values => {
    setIsSubmitting(true);
    if (!weightTiersErrorMsg) {
      if (isIncludeShippingFee && isChargingByWeight && !weightTiers.length) {
        setWeightTiersErrorMsg('You must add at least 1 weight tier to proceed.');
      } else {
        setWeightTiersErrorMsg('');

        const payload = {
          ...values,
          shippingFeeWeightTiers: isChargingByWeight ? weightTiers : [],
          ...(!isAllowFreeDeliveryTarget && { freeDeliveryTargetAmount: undefined })
        };

        if (isEditMode) {
          const isShipmentStatusUpdatedToInactive = shipment.status !== payload.status && payload.status === shipmentStatusesConst.INACTIVE.code;
          const { ordersToBeUnset } = isShipmentStatusUpdatedToInactive && (await getShipmentLinkedOrders(shipmentId, SHIPMENT_ACTION_DEACTIVATE));
          confirm({
            title: t('common:confirm-modal-update-title'),
            content: (
              <>
                <Col>{t('common:confirm-modal-update-content')}</Col>
                {isShipmentStatusUpdatedToInactive && ordersToBeUnset.length ? (
                  <DangerUpdateContentCol>
                    {t('pageShipmentDetails:confirm-modal-shipment-update-content', { numberOfOrders: ordersToBeUnset.length })}
                  </DangerUpdateContentCol>
                ) : null}
              </>
            ),
            icon: <ExclamationCircleOutlined />,
            okText: t('common:modal-ok-text'),
            cancelText: t('common:modal-cancel-text'),
            onOk() {
              patchShipment(shipmentId, payload)
                .then(() => {
                  message.success(t('common:update-success-message'));
                  refetchShipmentDetails();
                })
                .catch(ex => {
                  error({
                    title: ex.message
                  });
                })
                .finally(() => {
                  setIsSubmitting(false);
                });
            },
            onCancel() {
              setIsSubmitting(false);
            }
          });
        } else {
          postCreateShipment(payload)
            .then(newShipment => {
              refetchShipments();
              confirm({
                title: t('common:add-success-message'),
                icon: <SuccessIcon />,
                okText: t('pageShipmentDetails:confirm-modal-shipment-created-ok-button-text'),
                cancelText: t('pageShipmentDetails:confirm-modal-shipment-created-cancel-button-text'),
                onOk() {
                  history.push(getShipmentDetailRoute(newShipment._id).path);
                  setIsSubmitting(false);
                },
                onCancel() {
                  history.push(getShipmentsRoute().path);
                  setIsSubmitting(false);
                }
              });
            })
            .catch(ex => {
              error({
                title: t('pageShipmentDetails:error-modal-shipment-created-message'),
                content: ex.message
              });
            });
        }
      }
    }
  };

  const handleOnDelete = async () => {
    setIsSubmitting(true);
    const { ordersToBeUnset, ordersToBeMoved } = await getShipmentLinkedOrders(shipmentId, SHIPMENT_ACTION_DELETE);
    if (ordersToBeMoved.length > 0) {
      openDeleteShipmentOptionModal();
    } else {
      confirm({
        title: t('common:confirm-modal-delete-title', { itemName: t('pageShipmentDetails:item-name-lowercase') }),
        content: (
          <>
            <Col>{t('common:confirm-modal-delete-content')}</Col>
            {ordersToBeUnset.length ? (
              <DangerUpdateContentCol>
                {t('pageShipmentDetails:confirm-modal-shipment-delete-content', { numberOfOrders: ordersToBeUnset.length })}
              </DangerUpdateContentCol>
            ) : null}
          </>
        ),
        icon: <ExclamationCircleOutlined />,
        okText: t('pageShipmentDetails:confirm-modal-delete-button-text'),
        cancelText: t('common:modal-cancel-text'),
        okButtonProps: { type: 'primary', danger: true },
        onOk() {
          deleteShipmentFn({ shipmentId, t, history, setIsSubmitting });
        },
        onCancel() {
          setIsSubmitting(false);
        }
      });
    }
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  const handleOnWeightTiersUpdate = updatedWeightTiers => {
    setWeightTiers(updatedWeightTiers);
    setWeightTiersErrorMsg('');
  };

  const handleOnWeightTiersError = errorMsg => {
    setWeightTiersErrorMsg(errorMsg);
  };

  const handleOnDeliveryMethodChange = e => {
    const isIncludeShippingFeeDeliveryMethods = getFilteredConstants(deliveryMethodsConst, 'isIncludeShippingFee', true);
    const isIncludeShippingFee = isIncludeShippingFeeDeliveryMethods.includes(e.target.value);
    setIsIncludeShippingFee(isIncludeShippingFee);
    setIsChargingByWeight(false);

    form.setFieldsValue({ chargingMethod: isIncludeShippingFee ? chargingMethodsConst.FLAT_RATE.code : undefined });
  };

  return (
    <FullWidthContainer>
      <Helmet>
        <meta name="title" id="gtm-title" content={isEditMode ? 'Shipment Method Details' : 'New Shipment'} />
        <title>
          {isEditMode ? t('pageShipmentDetails:page-details-title', { shipmentMethod: shipment.label }) : t('pageShipmentDetails:page-new-title')}
        </title>
      </Helmet>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Row type="flex" align="middle" style={{ marginBottom: '16px' }}>
            <BackButton backLink={getShipmentsRoute().path} />
            <Title>{isEditMode ? `${shipment.label}` : t('pageShipmentDetails:title')}</Title>
            <StatusIndicatorContainer>
              {isEditMode && (
                <StatusIndicator
                  isActive={shipment.status === shipmentStatusesConst.ACTIVE.code}
                  text={getLabelOfConstant(shipment.status, shipmentStatuses)}
                />
              )}
            </StatusIndicatorContainer>
          </Row>

          <Form
            form={form}
            initialValues={shipment}
            scrollToFirstError={true}
            style={{ width: '100%' }}
            onFinish={handleOnSave}
            onFinishFailed={handleOnSaveFailed}
          >
            <Row gutter={[24, 24]}>
              <Col span={24} lg={12}>
                <Card>
                  <Row gutter={24}>
                    <Col span={24}>
                      <FormInput
                        label={t('pageShipmentDetails:form-input-label-shipment-label')}
                        name="label"
                        placeholder={t('pageShipmentDetails:form-input-placeholder-shipment-label')}
                        requiredErrorMessage={t('pageShipmentDetails:form-input-required-message-shipment-label')}
                      />
                    </Col>
                    <Col>
                      <FormRadioButton
                        label={t('pageShipmentDetails:form-input-label-shipment-delivery-method')}
                        name="deliveryMethod"
                        selections={deliveryMethods}
                        buttonStyle="solid"
                        onChange={handleOnDeliveryMethodChange}
                      />
                    </Col>
                    <Col>
                      <FormRadioButton
                        label={t('pageShipmentDetails:form-input-label-shipment-charging-method')}
                        name="chargingMethod"
                        selections={chargingMethods}
                        buttonStyle="solid"
                        onChange={e => setIsChargingByWeight(e.target.value === chargingMethodsConst.WEIGHT.code)}
                        shouldDisable={!isIncludeShippingFee}
                      />
                    </Col>
                    <Col>
                      <FormRadioButton
                        label={t('pageShipmentDetails:form-input-label-shipment-status')}
                        name="status"
                        selections={shipmentStatuses}
                        buttonStyle="solid"
                      />
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        onChange={() => setIsAllowFreeDeliveryTarget(!isAllowFreeDeliveryTarget)}
                        defaultValue={isAllowFreeDeliveryTarget}
                        value={isAllowFreeDeliveryTarget}
                        checked={isAllowFreeDeliveryTarget}
                        disabled={!isIncludeShippingFee}
                        style={{ marginBottom: '8px' }}
                      >
                        {t('pageShipmentDetails:checkbox-free-delivery-target')}
                      </Checkbox>
                      {isAllowFreeDeliveryTarget && (
                        <FormInputNumber
                          label={t('pageShipmentDetails:form-input-label-shipment-free-delivery-target-amount')}
                          name="freeDeliveryTargetAmount"
                          type="financial"
                          placeholder={t('pageShipmentDetails:form-input-placeholder-shipment-free-delivery-target-amount')}
                          requiredErrorMessage={t('pageShipmentDetails:form-input-required-message-shipment-free-delivery-target-amount')}
                          formatter={value => `≥ ${value}`}
                        />
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
              {isIncludeShippingFee && (
                <Col span={24} lg={12}>
                  <Card>
                    {isChargingByWeight ? (
                      <>
                        <WeightTiers
                          shipmentId={shipmentId}
                          weightTiers={weightTiers}
                          onUpdate={handleOnWeightTiersUpdate}
                          onError={handleOnWeightTiersError}
                        />
                        {weightTiersErrorMsg && <Alert type="error" message={weightTiersErrorMsg} style={{ marginTop: 10 }} />}
                      </>
                    ) : (
                      <FormInputNumber
                        label={t('pageShipmentDetails:form-input-label-shipment-flat-fee')}
                        name="shippingFlatFee"
                        type="financial"
                        placeholder={t('pageShipmentDetails:form-input-placeholder-shipment-flat-fee')}
                        requiredErrorMessage={t('pageShipmentDetails:form-input-required-message-shipment-flat-fee')}
                      />
                    )}
                  </Card>
                </Col>
              )}
            </Row>
            <Row gutter={8} style={{ margin: '16px 0px' }}>
              <Col>
                <Button htmlType="submit" type="primary" icon={<CheckOutlined />} loading={isSubmitting}>
                  {isEditMode ? t('common:form-submit-update-button-text') : t('common:form-submit-create-button-text')}
                </Button>{' '}
                {isEditMode && (
                  <Button type="ghost" icon={<DeleteOutlined />} onClick={handleOnDelete} loading={isSubmitting}>
                    {t('common:form-submit-delete-button-text')}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
          {isEditMode && (
            <DeleteShipmentOptionsModal
              visible={isDeleteShipmentOptionsModalVisible}
              onCancel={handleOnDeleteShipmentCancel}
              onMoveToCancel={handleOnDeleteShipmentMoveToCancel}
              onMoveToComplete={handleOnDeleteShipmentMoveToComplete}
              onViewOrders={handleOnDeleteShipmentViewOrders}
              getLinkedOrdersFn={getLinkedOrdersForDeletionFn}
            />
          )}
        </>
      )}
    </FullWidthContainer>
  );
};

export default ShipmentDetails;
