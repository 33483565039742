import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, message, Modal, Button, Alert } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useTranslation, Trans } from 'react-i18next';

import { postSelectAndPaySubscriptionPlan } from 'apis/store';
import { withAppContext } from 'contexts/AppContext/AppContext';

import { useFetchConstant } from 'hooks/constants';
import { useIsLoadings } from 'hooks/utils';

import { guard, rounds } from 'utils/general';
import { addMoment, getEndOfTodayMoment, getDayDiff, formatToDateTimeWithPMAM } from 'utils/date';

import Plan from './components/Plan/Plan';
import PlanPeriodSelectionPanel from './components/PlanPeriodSelectionPanel/PlanPeriodSelectionPanel';

import { MainContainer, LearnMoreLinkContainer, DisclaimerRow, PageTitle, PlanCardsRow } from './SubscriptionPlan.styles';

/* ============================================= Local Functions ============================================= */
// ============================================= General
const calculateTotalPrice = (planPrice, addOns) => {
  const addOnPrice = addOns.reduce((total, addOn) => total + (addOn.isSelected ? addOn.priceLeft : 0), 0);

  const totalPrice = planPrice + addOnPrice;

  return totalPrice;
};

// ============================================= Hook
const useFetchConstants = () => {
  const { data: subscriptionTypesConst, isLoading: isLoadingsubscriptionTypes } = useFetchConstant('subscriptionTypes');
  const { data: subscriptionPeriodsConst, isLoading: isLoadingSubscriptionPeriods, selection: subscriptionPeriods } = useFetchConstant(
    'subscriptionPeriods'
  );
  const { data: subscriptionAddOnsConst, isLoading: isLoadingsubscriptionAddOns } = useFetchConstant('subscriptionAddOns');

  const { isLoading: isLoadingConstants } = useIsLoadings([isLoadingsubscriptionTypes, isLoadingSubscriptionPeriods, isLoadingsubscriptionAddOns]);

  return {
    isLoadingConstants,
    subscriptionTypesConst,
    subscriptionPeriodsConst,
    subscriptionPeriods,
    subscriptionAddOnsConst
  };
};

const useAddOnsDetails = ({
  t,
  subscriptionAddOnsConst,
  selectedSubscriptionPeriodConst,
  isInitialMicrositeCustomerEnabled,
  isMicrositeCustomerEnabled,
  isCurrentSubscriptionStarter,
  expiryDate,
  setIsMicrositeCustomerEnabled
}) => {
  const constructAddOns = useCallback(() => {
    const subscriptionPeriodDays = selectedSubscriptionPeriodConst.days;
    const dayTillExpiryDate = getDayDiff(getEndOfTodayMoment(), expiryDate, true);
    const dayToPayForMicrositeCustomer = isCurrentSubscriptionStarter || isInitialMicrositeCustomerEnabled ? 0 : dayTillExpiryDate;

    const micrositeCustomerPrice = selectedSubscriptionPeriodConst.isMonthly
      ? subscriptionAddOnsConst.MICROSITE_CUSTOMER.pricePerMonth
      : selectedSubscriptionPeriodConst.isYearly
      ? subscriptionAddOnsConst.MICROSITE_CUSTOMER.pricePerYear
      : subscriptionAddOnsConst.MICROSITE_CUSTOMER.priceHalfYear;
    const micrositeCustomerPriceLeft = rounds(
      micrositeCustomerPrice * (dayToPayForMicrositeCustomer / subscriptionPeriodDays) + micrositeCustomerPrice,
      2
    );

    const addOns = [
      {
        code: subscriptionAddOnsConst.MICROSITE_CUSTOMER.code,
        label: `${t('pagePlan:subscription-plan-add-ons-microsite-title')} + ${t('pagePlan:subscription-plan-add-ons-customer-title')}`,
        desc: (
          <span>
            {t('pagePlan:subscription-plan-add-ons-microsite-desc')}
            <br />
            {t('pagePlan:subscription-plan-add-ons-customer-desc')}
          </span>
        ),
        price: micrositeCustomerPrice,
        priceLeft: micrositeCustomerPriceLeft,
        isSelected: isMicrositeCustomerEnabled,
        onClick: () => setIsMicrositeCustomerEnabled(!isMicrositeCustomerEnabled)
      }
    ];

    return addOns;
  }, [
    t,
    subscriptionAddOnsConst,
    selectedSubscriptionPeriodConst,
    isInitialMicrositeCustomerEnabled,
    isMicrositeCustomerEnabled,
    isCurrentSubscriptionStarter,
    expiryDate,
    setIsMicrositeCustomerEnabled
  ]);

  const addOnsDetails = useMemo(() => guard(() => constructAddOns(), []), [constructAddOns]);

  return addOnsDetails;
};

const useSubscriptionPlan = ({
  t,
  isLoading,
  subscriptionTypeConst,
  subscriptionPeriodsConst,
  subscriptionAddOnsConst,
  initialSubscriptionPeriod,
  isInitialMicrositeCustomerEnabled,
  isCurrentSubscriptionStarter,
  expiryDate,
  handleOnClickSelectAndPay
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [isMicrositeCustomerEnabled, setIsMicrositeCustomerEnabled] = useState(false);

  const selectedSubscriptionPeriodConst = useMemo(
    () =>
      guard(() => Object.values(subscriptionPeriodsConst).find(subscriptionPeriodConst => subscriptionPeriodConst.code === selectedPeriod), {
        isMonthly: true
      }),
    [subscriptionPeriodsConst, selectedPeriod]
  );
  const isMonthly = selectedSubscriptionPeriodConst.isMonthly;
  const isYearly = selectedSubscriptionPeriodConst.isYearly;
  const planPrice = guard(
    () => (isMonthly ? subscriptionTypeConst.pricePerMonth : isYearly ? subscriptionTypeConst.pricePerYear : subscriptionTypeConst.priceHalfYear),
    0
  );

  const addOnsDetails = useAddOnsDetails({
    t,
    subscriptionAddOnsConst,
    selectedSubscriptionPeriodConst,
    isInitialMicrositeCustomerEnabled,
    isMicrositeCustomerEnabled,
    isCurrentSubscriptionStarter,
    expiryDate,
    setIsMicrositeCustomerEnabled
  });
  const totalPrice = useMemo(() => calculateTotalPrice(planPrice, addOnsDetails), [planPrice, addOnsDetails]);

  useEffect(() => {
    if (!isLoading) {
      setSelectedPeriod(initialSubscriptionPeriod);
      setIsMicrositeCustomerEnabled(isInitialMicrositeCustomerEnabled);
    }
  }, [isLoading, initialSubscriptionPeriod, isInitialMicrositeCustomerEnabled]);

  const handleOnChangeSubscriptionPeriod = subscriptionPeriod => {
    setSelectedPeriod(subscriptionPeriod);
  };

  const handleOnClickSubscriptionSelectAndPay = () => {
    handleOnClickSelectAndPay(subscriptionTypeConst.code);
  };

  return {
    selectedSubscriptionPeriod: selectedPeriod,
    selectedSubscriptionPeriodConst,
    planPrice,
    addOnsDetails,
    totalPrice,
    handleOnChangeSubscriptionPeriod,
    handleOnClickSubscriptionSelectAndPay
  };
};

/* ============================================= Local Components ============================================= */
const Header = ({ t }) => {
  return (
    <>
      <Helmet>
        <meta name="title" id="gtm-title" content="Plans and Pricing" />
        <title>{t('pagePlan:page-title')}</title>
      </Helmet>
      <PageTitle>{t('pagePlan:page-title')}</PageTitle>
      <div style={{ textAlign: 'center' }}>{t('pagePlan:page-title-desc')}</div>
    </>
  );
};

const StarterPlan = ({
  t,
  isPaid,
  selectedSubscriptionPeriodConst,
  subscriptionPeriodsConst,
  isLoadingPayment,
  starterPlanPrice,
  starterAddOnsDetails,
  starterTotalPrice,
  onClickStarterSelectAndPay,
  expiryDate,
  storeCurrency
}) => {
  const starterPlanDescItems = useMemo(
    () => [
      t('pagePlan:starter-plan-content-checklist-one'),
      t('pagePlan:starter-plan-content-checklist-two'),
      t('pagePlan:starter-plan-content-checklist-three'),
      t('pagePlan:starter-plan-content-checklist-four')
    ],
    [t]
  );

  let paidProps = {};
  if (isPaid) {
    const nextExpiryDate = formatToDateTimeWithPMAM(addMoment(expiryDate, subscriptionPeriodsConst.days, 'day'));
    const isOutsideOfRenewRange = getDayDiff(undefined, expiryDate) > 3;
    const isSubscriptionPeriodUnavailable = selectedSubscriptionPeriodConst.code !== subscriptionPeriodsConst.code;
    const disablePayment = isSubscriptionPeriodUnavailable || isOutsideOfRenewRange;

    paidProps = {
      selectedSubscriptionPeriod: subscriptionPeriodsConst.code,
      planSubscriptionPeriod: t('pagePlan:subscription-plan-subs-period', { isMonthly: 0 }),
      newNextExpiryDate: nextExpiryDate,
      newSelectedPlanPrice: starterPlanPrice,
      planAddOns: starterAddOnsDetails,
      newSelectedPlanAddOnsDetails: starterAddOnsDetails,
      loadingPayment: isLoadingPayment,
      handleOnClickButtonPay: onClickStarterSelectAndPay,
      disablePayment,
      disablePaymentMessage: isSubscriptionPeriodUnavailable
        ? t('pagePlan:starter-button-subscription-only-support-monthly-disable-message')
        : t('pagePlan:starter-button-subscription-renew-out-of-range-disable-message')
    };
  }

  return (
    <Plan
      t={t}
      isCurrentPlan={true}
      isShowLessDetails={false}
      allowPurchase={isPaid}
      hasAddOns={false}
      storeCurrency={storeCurrency}
      title={t('pagePlan:starter-plan-title')}
      planPrice={starterPlanPrice}
      planDesc={t('pagePlan:starter-plan-content-desc')}
      planDescItems={starterPlanDescItems}
      newTotalPrice={starterTotalPrice}
      {...paidProps}
    />
  );
};

const BusinessPlan = ({
  t,
  selectedSubscriptionPeriodConst,
  isInitialSubscriptionBusiness,
  isLoadingPayment,
  businessPlanPrice,
  businessAddOnsDetails,
  businessTotalPrice,
  onClickBusinessSelectAndPay,
  expiryDate,
  storeCurrency
}) => {
  const businessPlanDescItems = useMemo(
    () => [
      t('pagePlan:business-plan-content-checklist-one'),
      t('pagePlan:business-plan-content-checklist-two'),
      t('pagePlan:business-plan-content-checklist-three'),
      t('pagePlan:business-plan-content-checklist-four'),
      t('pagePlan:business-plan-content-checklist-five')
    ],
    [t]
  );
  const nextExpiryDate = formatToDateTimeWithPMAM(addMoment(expiryDate, selectedSubscriptionPeriodConst.days, 'day'));
  return (
    <Plan
      t={t}
      isCurrentPlan={isInitialSubscriptionBusiness}
      selectedSubscriptionPeriod={selectedSubscriptionPeriodConst.code}
      storeCurrency={storeCurrency}
      title={t('pagePlan:business-plan-title')}
      planPrice={businessPlanPrice}
      planSubscriptionPeriod={t('pagePlan:subscription-plan-subs-period', {
        isMonthly: selectedSubscriptionPeriodConst.isMonthly ? 0 : selectedSubscriptionPeriodConst.isYearly ? 1 : 2
      })}
      planDesc={t('pagePlan:business-plan-content-desc')}
      planDescItems={businessPlanDescItems}
      planAddOns={businessAddOnsDetails}
      newTotalPrice={businessTotalPrice}
      newNextExpiryDate={nextExpiryDate}
      newSelectedPlanPrice={businessPlanPrice}
      newSelectedPlanAddOnsDetails={businessAddOnsDetails}
      loadingPayment={isLoadingPayment}
      handleOnClickButtonPay={onClickBusinessSelectAndPay}
    />
  );
};

const LearnMorePlansBtnLink = ({ label }) => (
  <LearnMoreLinkContainer>
    <Button type="link" onClick={() => window.open('https://ulive.me/pricing', '_blank')}>
      {label}
    </Button>
  </LearnMoreLinkContainer>
);

const CurrentUserSubsPlanSummary = ({
  subscriptionPeriodsConst,
  isMicrositeCustomerEnabled,
  isSubscriptionStarter,
  isSubscriptionPaidStarter,
  isSubscriptionMonthly,
  isSubscriptionYearly,
  renewalDateDisplayInString
}) => {
  const subscriptionPeriodLabel = useMemo(
    () =>
      isSubscriptionMonthly
        ? subscriptionPeriodsConst.MONTHLY.label
        : isSubscriptionYearly
        ? subscriptionPeriodsConst.YEARLY.label
        : subscriptionPeriodsConst.HALF_YEAR.label,
    [subscriptionPeriodsConst, isSubscriptionMonthly, isSubscriptionYearly]
  );

  let subscriptionTypeText;
  let expiryDate;

  if (isSubscriptionStarter) {
    subscriptionTypeText = (
      <Trans i18nKey="pagePlan:banner-subscription-details-starter-plan-title">
        Your plan is <b>Starter Plan</b> with a <b>limit of 50 orders per month</b>
      </Trans>
    );
    expiryDate = (
      <Trans i18nKey="pagePlan:banner-subscription-details-renewal-description" values={{ expiryDate: renewalDateDisplayInString }}>
        , and will be <b> renewed on expiry date </b>
      </Trans>
    );
  } else {
    const plan = isSubscriptionPaidStarter ? 'Starter' : 'Business';
    subscriptionTypeText = (
      <Trans i18nKey="pagePlan:banner-subscription-details-plan-title" values={{ plan, subscriptionPeriod: subscriptionPeriodLabel }}>
        Your plan is <b>{plan} Plan</b>
      </Trans>
    );
    expiryDate = (
      <Trans i18nKey="pagePlan:banner-subscription-details-expiry-description" values={{ expiryDate: renewalDateDisplayInString }}>
        , and will be <b> expired by expiry date </b>
      </Trans>
    );
  }

  return (
    <Alert
      message={
        <span>
          {subscriptionTypeText}
          {isMicrositeCustomerEnabled && (
            <Trans i18nKey="pagePlan:banner-subscription-details-add-ons-description">
              , added <b>Microsite + Customer Management</b>
            </Trans>
          )}
          {expiryDate}
        </span>
      }
      type="success"
    />
  );
};

/* ============================================= Main Component ============================================= */
const SubscriptionPlan = ({ store, storeCurrency, isStoreLoading }) => {
  const { t } = useTranslation(['common', 'commonConstants', 'pagePlan']);

  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const { isLoadingConstants, subscriptionTypesConst, subscriptionPeriodsConst, subscriptionPeriods, subscriptionAddOnsConst } = useFetchConstants();

  const { isLoading } = useIsLoadings([isStoreLoading, isLoadingConstants]);

  const isInitialSubscriptionStarter = useMemo(() => guard(() => store.subscriptionConfig.isSubscriptionStarter), [store]);
  const isInitialSubscriptionPaidStarter = useMemo(() => guard(() => store.subscriptionConfig.isSubscriptionPaidStarter), [store]);
  const isInitialSubscriptionBusiness = useMemo(() => guard(() => store.subscriptionConfig.isSubscriptionBusiness), [store]);
  const initialSubscriptionPeriod = useMemo(() => guard(() => store.subscriptionConfig.subscriptionPeriod), [store]);
  const isInitialMicrositeEnabled = useMemo(() => guard(() => store.subscriptionConfig.isMicrositeEnabled), [store]);
  const isInitialCustomerEnabled = useMemo(() => guard(() => store.subscriptionConfig.isCustomerEnabled), [store]);
  const expiryDateForCalc = useMemo(
    () =>
      guard(() =>
        isInitialSubscriptionStarter
          ? addMoment(getEndOfTodayMoment(), -1, 'days') // -1 for starter because we need to include today when upgrade business plan
          : store.subscriptionConfig.expiryDate
      ),
    [store, isInitialSubscriptionStarter]
  );
  const isMicrositeEnabled = store.subscriptionConfig.isMicrositeEnabled;
  const isCustomerEnabled = store.subscriptionConfig.isCustomerEnabled;
  const isSubscriptionStarter = store.subscriptionConfig.isSubscriptionStarter;
  const isSubscriptionPaidStarter = store.subscriptionConfig.isSubscriptionPaidStarter;
  const isSubscriptionMonthly = store.subscriptionConfig.isSubscriptionMonthly;
  const isSubscriptionYearly = store.subscriptionConfig.isSubscriptionYearly;
  const renewalDateDisplayInString = isSubscriptionStarter
    ? formatToDateTimeWithPMAM(addMoment(store.subscriptionConfig.expiryDate, 1, 'minute'))
    : formatToDateTimeWithPMAM(store.subscriptionConfig.expiryDate);

  const handleOnClickSelectAndPay = selectedSubscriptionType => {
    setIsLoadingPayment(true);
    const isSelectedStarter = [subscriptionTypesConst.STARTER.code, subscriptionTypesConst.PAID_STARTER.code].includes(selectedSubscriptionType);
    const addOnsDetails = isSelectedStarter ? [] : businessAddOnsDetails;
    const selectedAddOnsDetails = addOnsDetails.filter(addOnDetails => addOnDetails.isSelected);
    const addOns = selectedAddOnsDetails.map(addOnDetail => addOnDetail.code);

    const payload = {
      subscriptionType: selectedSubscriptionType,
      subscriptionPeriod: selectedSubscriptionPeriod,
      addOns
    };

    postSelectAndPaySubscriptionPlan(payload)
      .then(({ hasPayment, redirectUrl }) => {
        if (hasPayment) {
          Modal.warning({
            title: t('pagePlan:warning-modal-pg-redirect-title'),
            centered: true,
            okText: t('pagePlan:warning-modal-pg-redirect-button-ok-text'),
            okButtonProps: { loading: true }
          });
        }

        window.location.replace(redirectUrl);
      })
      .catch(e => {
        message.error(e.message);
      })
      .finally(() => {
        setIsLoadingPayment(false);
      });
  };

  const {
    selectedSubscriptionPeriod,
    selectedSubscriptionPeriodConst,
    planPrice: businessPlanPrice,
    addOnsDetails: businessAddOnsDetails,
    totalPrice: businessTotalPrice,
    handleOnChangeSubscriptionPeriod: handleOnChangeBusinessPeriod,
    handleOnClickSubscriptionSelectAndPay: handleOnClickBusinessSelectAndPay
  } = useSubscriptionPlan({
    t,
    isLoading,
    subscriptionTypeConst: subscriptionTypesConst && subscriptionTypesConst.BUSINESS,
    subscriptionPeriodsConst,
    subscriptionAddOnsConst,
    initialSubscriptionPeriod,
    isInitialMicrositeCustomerEnabled: isInitialMicrositeEnabled || isInitialCustomerEnabled,
    isCurrentSubscriptionStarter: isInitialSubscriptionPaidStarter || isInitialSubscriptionStarter,
    expiryDate: expiryDateForCalc,
    handleOnClickSelectAndPay
  });

  const {
    planPrice: starterPlanPrice,
    addOnsDetails: starterAddOnsDetails,
    totalPrice: starterTotalPrice,
    handleOnClickSubscriptionSelectAndPay: handleOnClickStarterSelectAndPay
  } = useSubscriptionPlan({
    t,
    isLoading,
    subscriptionTypeConst:
      subscriptionTypesConst && (isInitialSubscriptionStarter ? subscriptionTypesConst.STARTER : subscriptionTypesConst.PAID_STARTER),
    subscriptionPeriodsConst,
    subscriptionAddOnsConst,
    initialSubscriptionPeriod,
    isInitialMicrositeCustomerEnabled: isInitialMicrositeEnabled || isInitialCustomerEnabled,
    isCurrentSubscriptionStarter: isInitialSubscriptionPaidStarter || isInitialSubscriptionStarter,
    expiryDate: expiryDateForCalc,
    handleOnClickSelectAndPay
  });

  return (
    <MainContainer>
      <Header t={t} />
      {!isLoading && (
        <PlanCardsRow gutter={[24, 12]} justify="center">
          <Col span={24}>
            <PlanPeriodSelectionPanel
              subscriptionPeriods={subscriptionPeriods}
              selectedSubscriptionPeriodConst={selectedSubscriptionPeriodConst}
              onChangePeriod={handleOnChangeBusinessPeriod}
            />
            <CurrentUserSubsPlanSummary
              subscriptionPeriodsConst={subscriptionPeriodsConst}
              isMicrositeCustomerEnabled={isMicrositeEnabled || isCustomerEnabled}
              isSubscriptionStarter={isSubscriptionStarter}
              isSubscriptionPaidStarter={isSubscriptionPaidStarter}
              isSubscriptionMonthly={isSubscriptionMonthly}
              isSubscriptionYearly={isSubscriptionYearly}
              renewalDateDisplayInString={renewalDateDisplayInString}
            />
          </Col>
          {(isInitialSubscriptionStarter || isInitialSubscriptionPaidStarter) && (
            <Col span={24} order={2} md={{ span: 12, order: 1 }}>
              <StarterPlan
                t={t}
                isPaid={isInitialSubscriptionPaidStarter}
                selectedSubscriptionPeriodConst={selectedSubscriptionPeriodConst}
                subscriptionPeriodsConst={subscriptionPeriodsConst.MONTHLY}
                isLoadingPayment={isLoadingPayment}
                starterPlanPrice={starterPlanPrice}
                starterAddOnsDetails={starterAddOnsDetails}
                starterTotalPrice={starterTotalPrice}
                onClickStarterSelectAndPay={handleOnClickStarterSelectAndPay}
                expiryDate={expiryDateForCalc}
                storeCurrency={storeCurrency}
              />
            </Col>
          )}

          <Col span={24} order={1} md={{ span: 12, order: 2 }}>
            <BusinessPlan
              t={t}
              selectedSubscriptionPeriodConst={selectedSubscriptionPeriodConst}
              isInitialSubscriptionBusiness={isInitialSubscriptionBusiness}
              isLoadingPayment={isLoadingPayment}
              businessPlanPrice={businessPlanPrice}
              businessAddOnsDetails={businessAddOnsDetails}
              businessTotalPrice={businessTotalPrice}
              onClickBusinessSelectAndPay={handleOnClickBusinessSelectAndPay}
              expiryDate={expiryDateForCalc}
              storeCurrency={storeCurrency}
            />
          </Col>
        </PlanCardsRow>
      )}
      <DisclaimerRow>
        <Trans i18nKey="pagePlan:subscription-plan-disclaimer">
          <b>Disclaimer:</b> Monthly is equivalent to every 30 days. 6-month is equivalent to every 180 days. Yearly is equivalent to every 365 days.
        </Trans>
      </DisclaimerRow>
      <LearnMorePlansBtnLink label={t('pagePlan:learn-more-about-plans-and-pricing')} />
    </MainContainer>
  );
};

export default withAppContext(SubscriptionPlan);
