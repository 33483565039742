import styled from '@emotion/styled';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';

import Logo from 'images/ulive-logo-wo-bg.png';

const { Header, Footer, Sider } = Layout;

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: auto;
  padding: 8px;
  background-color: ${props => props.theme.color.red};
`;

export const AlertMessage = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;

  color: ${props => props.theme.color.white};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const AlertLink = styled(Link)`
  color: ${props => props.theme.color.white};
  text-decoration: underline;

  :hover {
    color: ${props => props.theme.color.lightSecondary};
  }
`;

export const RootLayout = styled(Layout)`
  height: 100vh;
`;

export const ContentRootLayout = styled(Layout)`
  height: 100vh;
  background-color: ${props => props.theme.color.tertiary};
`;

export const StyledSider = styled(Sider)`
  background-color: ${props => props.theme.color.tertiary};
  color: ${props => props.theme.color.white};
  height: 100%;
`;

export const SiderHeader = styled(Header)`
  background-color: ${props => props.theme.color.tertiary};
  color: ${props => props.theme.color.primary};
  margin: ${props => props.theme.spacing.regular} 0 ${props => props.theme.spacing.xl} 0;
  padding: 0px ${props => props.theme.spacing.regular};
  width: 100%;
`;

export const LogoContainer = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${Logo});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const ContentLayout = styled(Layout)`
  background-color: ${props => props.theme.color.white};
  border-radius: 24px 0px 0px 24px;
  height: 100%;
  overflow-y: auto;
`;

export const ContentHeader = styled(Header)`
  background-color: ${props => props.theme.color.white};
  padding: 0px 24px;
  height: 64px;

  @media (max-width: 425px) {
    min-width: 375px;
  }
`;

export const ContentBody = styled.div`
  background-color: ${props => props.theme.color.white};
  min-height: calc(100vh - 118px); // NOTE: 118px = header height + footer height
  overflow-x: auto;

  @media (max-width: 425px) {
    min-width: 375px;
  }
`;

export const ContentContainer = styled.div`
  background-color: ${props => props.theme.color.white};
  display: flex;
  justify-content: center;
  overflow: auto;
  padding: ${props => props.theme.spacing.regular} ${props => props.theme.spacing.md} ${props => props.theme.spacing.md}
    ${props => props.theme.spacing.md};
`;

export const ContentFooter = styled(Footer)`
  background-color: ${props => props.theme.color.lightGrey};
  padding: ${props => props.theme.spacing.regular} 0px ${props => props.theme.spacing.regular} 50px;
  height: 54px;

  @media (max-width: 425px) {
    min-width: 375px;
  }
`;
