import styled from '@emotion/styled';
import { GiftOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';

export const Container = styled.div`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const LuckyDrawButton = styled(Button)`
  color: ${props => props.theme.color.tertiary};
  background-color: ${props => props.theme.color.primary};
  border-color: ${props => props.theme.color.primary};

  &:hover {
    color: ${props => props.theme.color.tertiary}E6;
    background-color: ${props => props.theme.color.primary}E6;
    border-color: ${props => props.theme.color.primary}E6;
  }
`;

export const LuckyDrawIcon = styled(GiftOutlined)`
  color: ${props => props.theme.color.tertiary};
`;

export const LuckyDrawSessionBanner = styled(Row)`
  background-color: ${props => props.theme.color.lighterPrimary};
  border-radius: 24px;
  padding: 8px 16px;
`;

export const LDSBannerMainText = styled.p`
  color: ${props => props.theme.color.secondary};
  /* font-weight: ${props => props.theme.fontWeight.bold}; */
  margin-bottom: 0px;
`;

export const LDSBannerNoOfParticipantsText = styled.span`
  color: ${props => props.theme.color.secondary};
  font-weight: ${props => props.theme.fontWeight.bolder};
  margin-bottom: 0px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.color.tertiary};
  }
`;

export const LDSBannerRollNowText = styled.p`
  color: ${props => props.theme.color.secondary};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-bottom: 0px;
  border-bottom: 1px solid ${props => props.theme.color.secondary};
  padding: 0px 1px;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.color.tertiary};
    border-color: ${props => props.theme.color.tertiary};
  }
`;

export const LDSBannerAddParticipantsText = styled.p`
  color: ${props => props.theme.color.secondary};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-bottom: 0px;
  border-bottom: 1px solid ${props => props.theme.color.secondary};
  padding: 0px 1px;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.color.tertiary};
    border-color: ${props => props.theme.color.tertiary};
  }
`;
