import { useEffect, useMemo, useState } from 'react';
import { ExclamationCircleOutlined, InfoCircleFilled, LockOutlined, SaveOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Form, message, Modal, Row, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { patchProduct, patchProductVariance, useGetProductDetails, useRefetchProducts } from 'apis/product';

import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import TextButton from 'components/TextButton/TextButton';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';

import { useFetchConstant } from 'hooks/constants';
import { constructColumn } from 'utils/table/table';
import { guard } from 'utils/general';

import { InputContainer } from './ProductQuickUpdateModal.styles';

const { useForm } = Form;
const { confirm, error } = Modal;

const constructColumns = ({
  t,
  canEdit,
  isLocked,
  isNormalProduct,
  hasVariances,
  productStatuses,
  productStatusesConst,
  handleOnSaveChangesBtnClick,
  setIsLocked
}) => [
  {
    ...constructColumn(
      hasVariances ? t('modalOverviewProductQuickEdit:table-header-variance-name') : t('modalOverviewProductQuickEdit:table-header-product-name'),
      'label',
      {
        width: '20%'
      }
    ),
    render: (label, record) => (
      <span>
        <StatusIndicator isActive={record.status === productStatusesConst.ACTIVE.code} /> {label}
      </span>
    )
  },
  {
    ...constructColumn(t('modalOverviewProductQuickEdit:table-header-variance-keyword'), 'keyword', {
      width: '10%'
    })
  },
  {
    ...constructColumn(t('modalOverviewProductQuickEdit:table-header-variance-purchase-limit'), 'purchaseLimit', {
      width: '15%'
    })
  },
  {
    ...constructColumn(t('modalOverviewProductQuickEdit:table-header-variance-min-purchase-limit'), 'minPurchaseLimit', { width: '15%' })
  },
  {
    ...constructColumn(t('modalOverviewProductQuickEdit:table-header-variance-inventory'), 'inventory', { width: '15%' }),
    render: (text, record) => (
      <InputContainer>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ width: '90%' }}>
            <FormInputNumber
              name={hasVariances ? `${record._id}_inventory` : 'inventory'}
              isAllowNegative={isNormalProduct}
              minValue={isNormalProduct ? Number.MIN_SAFE_INTEGER : 0}
              requiredErrorMessage={t('modalOverviewProductQuickEdit:table-variance-cell-form-inventory-error-message')}
              disabled={isLocked}
            />
          </div>
          <Button
            type="text"
            icon={isLocked ? <LockOutlined /> : <UnlockOutlined />}
            style={{ marginLeft: '4px', marginBottom: '24px' }}
            onClick={() => setIsLocked(!isLocked)}
          />
        </div>
      </InputContainer>
    )
  },
  {
    ...constructColumn(t('modalOverviewProductQuickEdit:table-header-variance-price'), 'price', { isPrice: true, width: '20%' }),
    render: (text, record) => (
      <InputContainer>
        <FormInputNumber
          name={hasVariances ? `${record._id}_price` : 'price'}
          type="financial"
          requiredErrorMessage={t('modalOverviewProductQuickEdit:table-variance-cell-form-price-error-message')}
          placeholder="100.00"
        />
      </InputContainer>
    )
  },
  {
    ...constructColumn(t('modalOverviewProductQuickEdit:table-header-variance-status'), 'status'),
    render: (text, record) => (
      <InputContainer>
        <FormRadioButton
          name={hasVariances ? `${record._id}_status` : 'status'}
          selections={productStatuses}
          buttonStyle="solid"
          defaultValue="active"
          shouldDisable={!canEdit}
        />
      </InputContainer>
    )
  },
  {
    ...constructColumn(t('modalOverviewProductQuickEdit:table-header-action'), 'action', { width: '15%' }),
    render: (text, record) => {
      return (
        <Row>
          <TextButton
            icon={<SaveOutlined />}
            onClick={() => handleOnSaveChangesBtnClick(record._id)}
            text={t('modalOverviewProductQuickEdit:table-action-button-save-changes')}
            tooltipMessage={t('common:text-button-tooltip-message', {
              action: t('modalOverviewProductQuickEdit:table-action-tooltip-message-save-changes')
            })}
          />
        </Row>
      );
    }
  }
];

const ProductQuickUpdateModal = ({ visible, productId, onClose }) => {
  const { t } = useTranslation(['common', 'modalOverviewProductQuickEdit']);
  const [form] = useForm();
  const [isLocked, setIsLocked] = useState(true);
  const [formattedProduct, setFormattedProduct] = useState({});

  const { isLoading: isProductTypesLoading, data: productTypesConst } = useFetchConstant('productTypes');
  const { isLoading: isProductStatusesLoading, selection: productStatuses, data: productStatusesConst } = useFetchConstant('productStatuses');
  const { isLoading: isProductLoading, data: product, refetch: refetchProductDetails } = useGetProductDetails(productId);
  const refetchProducts = useRefetchProducts();

  const isLoading = isProductTypesLoading || isProductStatusesLoading || isProductLoading;

  const isNormalProduct = guard(() => product.type === productTypesConst.NORMAL.code, false);
  const hasVariances = useMemo(() => product.variances && product.variances.length > 0, [product]);
  const canEdit = useMemo(() => (product && hasVariances ? product.status !== productStatusesConst.INACTIVE.code : true), [
    product,
    hasVariances,
    productStatusesConst
  ]);

  useEffect(() => {
    let newProductPayload = {
      inventory: product.inventory,
      price: product.price,
      status: product.status
    };
    if (product && hasVariances) {
      product.variances.forEach(variance => {
        newProductPayload[`${variance._id}_inventory`] = variance.inventory;
        newProductPayload[`${variance._id}_price`] = variance.price;
        newProductPayload[`${variance._id}_status`] = variance.status;
      });
    }
    form.setFieldsValue(newProductPayload);
    setFormattedProduct(newProductPayload);
  }, [form, hasVariances, product]);

  const handleOnSaveChangesBtnClick = async selectedId => {
    try {
      const formValues = await form.validateFields();
      if (hasVariances) {
        const updatedVariance = {
          ...(!isLocked && { inventory: formValues[`${selectedId}_inventory`] }),
          price: formValues[`${selectedId}_price`],
          status: formValues[`${selectedId}_status`]
        };
        patchProductVariance(productId, selectedId, updatedVariance)
          .then(updatedProduct => {
            message.success(t('common:update-success-message'));
            refetchProductDetails(productId);
            refetchProducts();
          })
          .catch(ex => {
            error({
              title: ex.message
            });
          });
      } else {
        patchProduct(productId, { ...formValues, shouldUpdateProductInventory: !isLocked })
          .then(updatedProduct => {
            message.success(t('common:update-success-message'));
            refetchProductDetails();
            refetchProducts();
          })
          .catch(ex => {
            error({
              title: ex.message
            });
          });
      }
    } catch (ex) {}
  };

  const handleOnMainProductStatusChange = () => {
    const formValues = form.getFieldsValue();
    const payload = {
      status: formValues.status
    };
    confirm({
      title: t('modalOverviewProductQuickEdit:confirm-modal-update-main-status-title'),
      content: t('modalOverviewProductQuickEdit:confirm-modal-update-main-status-content'),
      okText: t('modalOverviewProductQuickEdit:confirm-modal-update-main-status-ok-text'),
      cancelText: t('common:modal-cancel-text'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        patchProduct(productId, payload)
          .then(updatedProduct => {
            message.success(t('common:update-success-message'));
            refetchProductDetails();
            refetchProducts();
          })
          .catch(ex => {
            error({
              title: ex.message
            });
          });
      },
      onCancel() {
        form.setFieldsValue({
          status: product.status
        });
      }
    });
  };

  return (
    <Modal
      title={t('modalOverviewProductQuickEdit:title', { productLabel: `${product.label} (${product.keyword})` })}
      visible={visible || !isLoading}
      onCancel={onClose}
      footer={null}
      width="fit-content"
      destroyOnClose={true}
    >
      <Form form={form} initialValues={formattedProduct} scrollToFirstError={true} style={{ width: '100%' }}>
        {hasVariances && (
          <FormRadioButton
            name="status"
            label={
              <span>
                {t('modalOverviewProductQuickEdit:form-label-product-status')}{' '}
                <Tooltip title={t('modalOverviewProductQuickEdit:form-label-product-status-tooltip')}>
                  <InfoCircleFilled />
                </Tooltip>
              </span>
            }
            selections={productStatuses}
            buttonStyle="solid"
            defaultValue="active"
            onChange={handleOnMainProductStatusChange}
          />
        )}
        <Table
          size="small"
          loading={isLoading}
          rowKey={record => record._id}
          dataSource={product && hasVariances ? product.variances : [product]}
          columns={constructColumns({
            t,
            canEdit,
            isLocked,
            isNormalProduct,
            hasVariances,
            productStatuses,
            productStatusesConst,
            handleOnSaveChangesBtnClick,
            setIsLocked
          })}
          scroll={{ x: 'max-content' }}
          locale={{
            triggerDesc: t('common:table-header-sort-trigger-desc'),
            triggerAsc: t('common:table-header-sort-trigger-asc'),
            cancelSort: t('common:table-header-sort-cancel-sort')
          }}
          pagination={false}
        />
      </Form>
    </Modal>
  );
};

export default ProductQuickUpdateModal;
