import styled from '@emotion/styled';
import { WhatsAppOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Avatar, Row } from 'antd';

import MessengerIcon from 'components/Icon/MessengerIcon';

export const TitleContainer = styled(Row)`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const NameLabel = styled.span`
  cursor: pointer;
  color: props.theme.color.lightTertiary;
`;

export const RemarksClickableIcon = styled(InfoCircleOutlined)`
  color: ${props => props.theme.color.primary};
  cursor: pointer;
  margin-right: 4px;
`;

export const WAClickableIcon = styled(WhatsAppOutlined)`
  color: #3dc14f;
  cursor: pointer;
  margin-right: 4px;
`;

export const FBClickableIcon = styled(MessengerIcon)`
  color: #0b88ef;
  cursor: pointer;
  margin-right: 4px;
  margin-bottom: 4px;
`;

export const UliveAccountIcon = styled(Avatar)`
  cursor: pointer;
  margin-bottom: 4px;
  border-radius: 8px;
`;
