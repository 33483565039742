import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getLabelOfConstant, guard } from 'utils/general';

import OrderDisplay from 'components/OrderDisplay/OrderDisplay';
import CompactInvoice from './CompactInvoice/CompactInvoice';

import { ContentContainer, CursiveText, StoreInfoContainer, StoreNameText, StoreAddressText, InvoiceTitle } from './Invoice.styles';

const formatAddressSubInfo = ({ city, zipcode, state, country }, countries) =>
  `${zipcode || ''} ${city ? city + ', ' : ''}${state ? state + ', ' : ''}${country ? getLabelOfConstant(country, countries) : ''}`;

const InvoiceHeader = ({ t, order, countries }) => {
  const hasSubInfo = guard(
    () => order.store.address.city || order.store.address.zipcode || order.store.address.state || order.store.address.country,
    false
  );

  return (
    <div>
      <p style={{ textAlign: 'right' }}>
        powered by <CursiveText>ulive</CursiveText> @ {new Date().getFullYear()}
      </p>
      <StoreInfoContainer>
        <StoreNameText>{order.store.name}</StoreNameText>
        {order.store.address?.addressLine1 && <StoreAddressText>{order.store.address.addressLine1}</StoreAddressText>}
        {order.store.address?.addressLine2 && <StoreAddressText>{order.store.address.addressLine2}</StoreAddressText>}
        {hasSubInfo && <StoreAddressText>{formatAddressSubInfo(order.store.address, countries)}</StoreAddressText>}
      </StoreInfoContainer>
      <InvoiceTitle>
        {t('pageOrderDetails:pdf-invoice-title')} #{order.orderNumber}
      </InvoiceTitle>
    </div>
  );
};

const Invoice = ({ order, orderStatuses, paymentMethods, countries, productTypesConst, isCompact }) => {
  const { t } = useTranslation(['pageOrderDetails']);

  return isCompact ? (
    <CompactInvoice t={t} order={order} countries={countries} productTypesConst={productTypesConst} />
  ) : (
    <ContentContainer>
      <InvoiceHeader t={t} order={order} countries={countries} />
      <OrderDisplay
        size="small"
        order={order}
        orderStatuses={orderStatuses}
        paymentMethods={paymentMethods}
        countries={countries}
        productTypesConst={productTypesConst}
        isOnPrintingPage
        isAllowDisplayCheckoutLink={false}
      />
      <p style={{ fontSize: '12px', marginTop: '4px' }}>This invoice is computer generated and no signature is required</p>
    </ContentContainer>
  );
};

Invoice.propTypes = {
  order: PropTypes.object.isRequired,
  orderStatuses: PropTypes.array,
  paymentMethods: PropTypes.array,
  countries: PropTypes.array,

  isCompact: PropTypes.bool
};

export default Invoice;
