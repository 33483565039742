import styled from '@emotion/styled';

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
`;

export const ImageFilesContainer = styled.div`
  display: flex;
`;

export const ImageContainer = styled.div`
  margin-right: 4px;
`;

export const PDFLabel = styled.p`
  font-size: 14px;
  margin-bottom: 2px;
`;
