import styled from '@emotion/styled';
import { Checkbox } from 'antd';

export const IsBuyerRecipientCheckbox = styled(Checkbox)`
  font-size: 16px;
  margin-bottom: 24px;
`;

export const IsUpdateBuyerProfileCheckbox = styled(Checkbox)`
  font-size: 16px;
  margin-bottom: 24px;
  margin-left: 0px;
`;

export const PaymentRefIdLinkTypeText = styled.span`
  color: ${props => props.theme.color.lightTertiary};
  cursor: pointer;
`;
