import { Skeleton, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetProductPendingOrders } from 'apis/product';
import { useFetchConstant } from 'hooks/constants';

import Title from 'components/Title/Title';

import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';
import { constructConstantLabel } from 'utils/constants';
import { getOrderDetailRoute } from 'utils/routes';

import { ContentContainer, RootModal } from './ProductPendingOrderModal.styles';

const constructColumns = ({ t, orderStatuses, isRoleAllowOperation }) => [
  {
    ...constructColumn(t('pageProduct:product-pending-order-modal-header-order-number'), 'orderNumber', {
      hasSorter: true,
      width: '15%'
    }),
    ...constructColumnFilterSearch('orderNumber', t('pageProduct:product-pending-order-modal-header-action-search-order-number'), {}),
    render: (label, record) => {
      const orderNumberComp = (
        <span>
          {label}
          <br />
          <small>{constructConstantLabel(orderStatuses, record.status)}</small>
        </span>
      );

      return isRoleAllowOperation ? (
        <a style={{ marginBottom: 0 }} href={getOrderDetailRoute(record._id).path} target="_blank" rel="noopener noreferrer">
          {orderNumberComp}
        </a>
      ) : (
        orderNumberComp
      );
    }
  },
  {
    ...constructColumn(t('pageProduct:product-pending-order-modal-header-product-quantity'), 'productQuantity', {
      hasSorter: true,
      isNumber: true,
      width: '10%'
    })
  },
  {
    ...constructColumn(t('pageProduct:product-pending-order-modal-header-buyer-name'), 'customerName', { hasSorter: true, width: '10%' }),
    ...constructColumnFilterSearch('customerName', t('pageProduct:product-pending-order-modal-header-action-search-buyer-name'), {})
  }
];

const ProductPendingOrderModal = ({ onCancel, productId, isRoleAllowOperation }) => {
  const { t } = useTranslation(['pageProduct']);
  const { isLoading: isLoadingProductPendingOrders, data: productPendingOrders } = useGetProductPendingOrders(productId);
  const { isLoading: isOrderStatusesLoading, selection: orderStatuses } = useFetchConstant('orderStatuses');

  const isLoading = isOrderStatusesLoading || isLoadingProductPendingOrders;

  return (
    <RootModal
      visible
      onCancel={onCancel}
      bodyStyle={{ overflowX: 'auto' }}
      title={<Title>{t('pageProduct:product-pending-order-modal-title')}</Title>}
      footer={null}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <ContentContainer>
          <Table columns={constructColumns({ t, orderStatuses, isRoleAllowOperation })} dataSource={productPendingOrders} />
        </ContentContainer>
      )}
    </RootModal>
  );
};

export default ProductPendingOrderModal;
