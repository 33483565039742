import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Form, Modal, message, Tooltip, Row, Skeleton } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';

import { patchStoreNotifConfig, useGetStoreNotifConfig } from 'apis/store';

import Input from 'components/Input/Input';
import FormLabel from 'components/FormLabel/FormLabel';
import FormCheckboxToggle from 'components/Checkbox/FormCheckboxToggle/FormCheckboxToggle';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

const { useForm } = Form;

const NotifSettingModal = ({ onOk, onCancel }) => {
  const { t } = useTranslation(['common', 'pageNotification']);
  const [form] = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPaymentReminderChecked, setIsPaymentReminderChecked] = useState(false);
  const [notifMessage, setNotifMessage] = useState('');

  const { isLoading, data: notifConfig } = useGetStoreNotifConfig();

  useEffect(() => {
    if (!isLoading && notifConfig) {
      form.setFieldsValue(notifConfig);
      if (notifConfig.paymentReminder && notifConfig.paymentReminder.isEnabled) {
        setIsPaymentReminderChecked(true);
        setNotifMessage(notifConfig.paymentReminder.customMessage);
      }
    }
  }, [isLoading, form, notifConfig]);

  const handleOnSave = e => {
    e.preventDefault();
    setIsSubmitting(true);

    form
      .validateFields()
      .then(values => {
        let payload = values;
        if (isPaymentReminderChecked) {
          payload.paymentReminder.customMessage = notifMessage;
        }

        patchStoreNotifConfig(payload)
          .then(() => {
            onOk();
            message.success(t('common:update-success-message'));
          })
          .catch(ex => {
            message.error(ex.message);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        setIsSubmitting(false);
      });
  };

  return (
    <Modal
      visible
      width="90%"
      style={{ maxWidth: '768px' }}
      title={t('pageNotification:notif-setting-modal-title')}
      okText={t('common:form-submit-create-button-text')}
      cancelText={t('common:modal-cancel-text')}
      onOk={handleOnSave}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      cancelButtonProps={{ loading: isSubmitting }}
      closable={!isSubmitting}
      maskClosable={!isSubmitting}
    >
      {isLoading ? (
        <Skeleton loading />
      ) : (
        <Form form={form} scrollToFirstError={true} style={{ width: '100%' }}>
          <Row>
            <Col span={24}>
              <FormCheckboxToggle
                name={['paymentReminder', 'isEnabled']}
                label={t('pageNotification:notif-setting-modal-label-payment-reminder')}
                formItemStyle={{ marginBottom: isPaymentReminderChecked ? 0 : '24px' }}
                onChange={e => setIsPaymentReminderChecked(e.target.checked)}
              />
            </Col>
            {isPaymentReminderChecked && (
              <Col span={24}>
                <Card style={{ marginBottom: '24px' }}>
                  <FormInputNumber
                    name={['paymentReminder', 'numOfDay']}
                    label={
                      <>
                        {t('pageNotification:notif-setting-modal-label-payment-reminder-days')}{' '}
                        <Tooltip title={t('pageNotification:notif-setting-modal-tooltip-message-payment-reminder')}>
                          <QuestionCircleFilled />
                        </Tooltip>
                      </>
                    }
                    min={1}
                    requiredErrorMessage={t('pageNotification:notif-setting-modal-required-payment-reminder-days')}
                  />
                  <FormLabel>
                    <>
                      {t('pageNotification:notif-setting-modal-label-payment-reminder-message')}{' '}
                      <Tooltip title={t('pageNotification:notif-setting-modal-tooltip-message-payment-reminder-custom')}>
                        <QuestionCircleFilled />
                      </Tooltip>
                    </>
                  </FormLabel>
                  <Input
                    type="textarea"
                    value={notifMessage}
                    placeholder={t('pageNotification:notif-setting-modal-placeholder-payment-reminder-message')}
                    onChange={setNotifMessage}
                    showCount={true}
                    maxLength={200}
                    rows={4}
                  />
                </Card>
              </Col>
            )}
            <Col span={24}>
              <FormCheckboxToggle
                name={['shipmentUpdate', 'isEnabled']}
                label={
                  <>
                    {t('pageNotification:notif-setting-modal-label-shipment-reminder')}{' '}
                    <Tooltip title={t('pageNotification:notif-setting-modal-tooltip-message-shipment-reminder')}>
                      <QuestionCircleFilled />
                    </Tooltip>
                  </>
                }
              />
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default NotifSettingModal;
