import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload as AntDUpload, Button, message } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import 'antd/es/slider/style';

import { UploadCardTextContainer } from './Upload.styles';

import { getPresignedPostData } from 'apis/s3Upload';

const FILE_STATUS_DONE = 'done';
const FILE_STATUS_ERROR = 'error';
const FILE_STATUS_REMOVED = 'removed';

const getFileSizeInMb = file => {
  return file.size / 1024 / 1024;
};

const ButtonUpload = ({ isDisplayCard = false, isUploading = false, label, onClick }) => {
  return isDisplayCard ? (
    <div>
      <PlusOutlined />
      <UploadCardTextContainer>{isUploading ? 'Uploading...' : label}</UploadCardTextContainer>
    </div>
  ) : (
    <Button type="primary" loading={isUploading} onClick={onClick}>
      <UploadOutlined /> {label}
    </Button>
  );
};

const Upload = ({
  value: formValue,
  acceptTypes,
  fileSizeLimitInMb,
  buttonLabel,

  cropAspect,

  isDisabled,
  isMultiple,
  isDisplayCard,

  onChange,
  updateFormValue,
  onFileUploadChange,
  onClick
}) => {
  const [isInitWithFormValue, setIsInitWithFormValue] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [files, setFiles] = useState([]);
  const [s3Url, setS3Url] = useState('');
  const [presignedFormData, setPresignedFormData] = useState({});
  const fileUrl = useMemo(() => `${s3Url}/${encodeURIComponent(presignedFormData.key)}`, [s3Url, presignedFormData]);

  useEffect(() => {
    if (!isInitWithFormValue && formValue) {
      const filesToUpdate = isMultiple ? formValue : [formValue];
      setIsInitWithFormValue(true);
      setFiles(filesToUpdate);
    }
  }, [formValue, isInitWithFormValue, isMultiple]);

  const updateValue = useCallback(
    newValue => {
      let valueToUpdateExternal = newValue;
      if (!isMultiple) {
        valueToUpdateExternal = newValue[0] || null; // To remove photo from API
      }

      onChange(valueToUpdateExternal);
      updateFormValue(valueToUpdateExternal);
      setFiles(newValue);
    },
    [isMultiple, onChange, updateFormValue]
  );

  const handleOnFileUploadChange = useCallback(
    isUploading => {
      setIsUploading(isUploading);
      onFileUploadChange(isUploading);
    },
    [onFileUploadChange]
  );

  const handleBeforeUpload = useCallback(
    async file => {
      handleOnFileUploadChange(true);

      try {
        if (fileSizeLimitInMb && getFileSizeInMb(file) >= fileSizeLimitInMb) {
          const errMessage = `Image must be smaller than ${fileSizeLimitInMb}MB`;
          message.error(errMessage);

          throw new Error(errMessage);
        }

        const { data: presignedPostData } = await getPresignedPostData(file).catch(ex => {
          message.error(`${file.name} file upload failed.`);
          throw ex;
        });
        setS3Url(presignedPostData.url);
        setPresignedFormData(presignedPostData.fields);

        return true;
      } catch (ex) {
        handleOnFileUploadChange(false);
        throw ex;
      }
    },
    [fileSizeLimitInMb, handleOnFileUploadChange]
  );

  const handleOnFileChange = useCallback(
    ({ event, file: currentFile }) => {
      if (!event) {
        if (currentFile.status === FILE_STATUS_DONE) {
          message.success(`${currentFile.name} file uploaded successfully`);
          const fileToUpdate = { name: currentFile.name, uid: currentFile.uid, url: fileUrl };
          if (isMultiple) {
            updateValue([...files, fileToUpdate]);
          } else {
            updateValue([fileToUpdate]);
          }

          handleOnFileUploadChange(false);
        } else if (currentFile.status === FILE_STATUS_REMOVED) {
          if (isMultiple) {
            const indexOfFileToDelete = files.findIndex(value => value.uid === currentFile.uid);
            const removedFiles = files.slice(indexOfFileToDelete, indexOfFileToDelete + 1);
            const newFilesToUpdate = files.filter(value => !removedFiles.find(removedFile => removedFile.uid === value.uid));

            updateValue(newFilesToUpdate);
          } else {
            updateValue([]);
          }

          handleOnFileUploadChange(false);
        } else if (currentFile.status === FILE_STATUS_ERROR) {
          handleOnFileUploadChange(false);
          return;
        }
      }
    },
    [fileUrl, files, handleOnFileUploadChange, isMultiple, updateValue]
  );

  // Kong - ImgCrop require the ref of the direct component which means we can't wrap them
  // See if there is any better way for this
  const renderdComponent = useMemo(() => {
    return (
      <AntDUpload
        action={s3Url}
        accept={acceptTypes && acceptTypes.join(',')}
        fileList={files}
        listType={isDisplayCard ? 'picture-card' : 'text'}
        headers={{}}
        data={{ ...presignedFormData }}
        disabled={isDisabled}
        beforeUpload={handleBeforeUpload}
        onChange={handleOnFileChange}
      >
        {!isDisabled && <ButtonUpload isDisplayCard={isDisplayCard} isUploading={isUploading} label={buttonLabel} onClick={onClick} />}
      </AntDUpload>
    );
  }, [
    acceptTypes,
    buttonLabel,
    presignedFormData,
    files,
    s3Url,

    handleBeforeUpload,
    handleOnFileChange,
    onClick,

    isDisabled,
    isDisplayCard,
    isUploading
  ]);

  return cropAspect ? (
    <ImgCrop aspect={cropAspect} grid>
      {renderdComponent}
    </ImgCrop>
  ) : (
    renderdComponent
  );
};

Upload.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      uid: PropTypes.string
    }),
    PropTypes.array
  ]),
  acceptTypes: PropTypes.array,
  fileSizeLimitInMb: PropTypes.number,
  buttonLabel: PropTypes.string,

  cropAspect: PropTypes.number,

  isDisabled: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isDisplayCard: PropTypes.bool,

  onChange: PropTypes.func,
  updateFormValue: PropTypes.func,
  onFileUploadChange: PropTypes.func,
  onClick: PropTypes.func
};

Upload.defaultProps = {
  value: undefined,
  acceptTypes: undefined,
  fileSizeLimitInMb: undefined,
  buttonLabel: 'Upload images / documents',

  cropAspect: undefined,

  isDisabled: false,
  isMultiple: false,
  isDisplayCard: false,

  onChange: () => {},
  updateFormValue: () => {},
  onFileUploadChange: () => {},
  onClick: undefined
};

export default Upload;
