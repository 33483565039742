import styled from '@emotion/styled';

export const Sticker = styled.div`
  border: 2px solid black;
  width: 6cm;
  height: 4cm;
  padding: 12px;
  font-size: 16px;
  margin: 8px auto;

  @media print {
    page-break-after: always;
    border: none;
    width: 6cm;
    height: 3.99cm;
    padding: 0.2cm;
    margin: 0px;

    &:last-child {
      page-break-after: auto;
    }
  }
`;

export const ProductNameLabel = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: normal;
  margin: 12px 0;
  font-size: 14px;
`;
