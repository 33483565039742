import styled from '@emotion/styled';
import { Card } from 'antd';

export const CardContainer = styled(Card)`
  width: 100%;
  margin-bottom: 12px;
`;

export const CardTitleText = styled.h4`
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const CardContentText = styled.p`
  font-size: 20px;
  margin-bottom: 0;
  font-weight: bold;
  color: ${props => props.theme.color.tertiary};

  @media (min-width: 768px) {
    font-size: 28px;
  }
`;

export const CardContentPendingText = styled.p`
  font-size: 12px;
  margin-bottom: 0;
  color: ${props => props.theme.color.grey};

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;
