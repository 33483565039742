import { useCallback, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Alert, Col, Form, message, Modal, Row, Space, Tag } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';

import { guard } from 'utils/general';

import { useGetStoreCustomerSuggestions, patchStoreCustomerMerge } from 'apis/storecustomer';

import FormSelection from 'components/FormSelection/FormSelection';

const { useForm } = Form;

/* =================================================== Exported Functions =================================================== */
export const useCustomerMergeModal = () => {
  const [isCustomerMergeModalVisible, setIsCustomerMergeModalVisible] = useState(false);
  const [defaultSelectedMainCustomer, setDefaultSelectedMainCustomer] = useState();

  const handleOnMergeCustomerBtnClick = useCallback(mainCustomer => {
    setDefaultSelectedMainCustomer(mainCustomer);
    setIsCustomerMergeModalVisible(true);
  }, []);

  const handleOnMergeCustomerModalCancel = useCallback(() => {
    setDefaultSelectedMainCustomer(null);
    setIsCustomerMergeModalVisible(false);
  }, []);

  return { isCustomerMergeModalVisible, defaultSelectedMainCustomer, handleOnMergeCustomerBtnClick, handleOnMergeCustomerModalCancel };
};

/* =================================================== Main Component =================================================== */
const CustomerMergeModal = ({ defaultSelectedMainCustomer, onMergeSuccess, onCancel }) => {
  const { t } = useTranslation(['pageCustomers']);
  const [form] = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedMainCustomer, setSelectedMainCustomer] = useState(defaultSelectedMainCustomer);
  const [selectedSubCustomer, setSelectedSubCustomer] = useState();
  const [mainCustomerKeyword, setMainCustomerKeyword] = useState('');
  const [subCustomerKeyword, setSubCustomerKeyword] = useState('');

  const delaySetMainCustomerKeyword = useMemo(() => debounce(setMainCustomerKeyword, 300), []);
  const delaySetSubCustomerKeyword = useMemo(() => debounce(setSubCustomerKeyword, 300), []);

  const { isLoading: isMainCustomerSuggestionsLoading, data: mainCustomerSuggestions } = useGetStoreCustomerSuggestions(
    mainCustomerKeyword,
    defaultSelectedMainCustomer
  );
  const { isLoading: isSubCustomerSuggestionsLoading, data: subCustomerSuggestions } = useGetStoreCustomerSuggestions(subCustomerKeyword);

  const formInitialValues = useMemo(() => {
    return {
      mainCustomer: defaultSelectedMainCustomer
    };
  }, [defaultSelectedMainCustomer]);

  const getCustomerSelection = (customers, excludedCustomerId) => {
    if (!customers) return [];

    return customers
      .filter(customer => customer._id !== excludedCustomerId)
      .map(customer => {
        const customerContactNumber = guard(() => `${customer.contact.countryCode || ''}${customer.contact.contactNumber || ''}`, '');

        let selectionLabel = customer.name;
        if (customer.source) {
          selectionLabel = `${selectionLabel} (${customer.source.toUpperCase()}: ${customer.sourceUserName})`;
        }
        if (customerContactNumber) {
          selectionLabel = `${selectionLabel} (${customerContactNumber})`;
        }

        return {
          ...customer,
          value: customer._id,
          label: selectionLabel
        };
      });
  };

  const mainCustomersSelection = useMemo(
    () => (isMainCustomerSuggestionsLoading ? [] : getCustomerSelection(mainCustomerSuggestions, selectedSubCustomer)),
    [isMainCustomerSuggestionsLoading, mainCustomerSuggestions, selectedSubCustomer]
  );

  const subCustomersSelection = useMemo(
    () => (isSubCustomerSuggestionsLoading ? [] : getCustomerSelection(subCustomerSuggestions, selectedMainCustomer)),
    [isSubCustomerSuggestionsLoading, subCustomerSuggestions, selectedMainCustomer]
  );

  const handleOnSave = e => {
    e.preventDefault();
    setIsSubmitting(true);

    form
      .validateFields()
      .then(() => {
        patchStoreCustomerMerge(selectedMainCustomer, selectedSubCustomer)
          .then(() => {
            onMergeSuccess();
            message.success(t('common:update-success-message'));
          })
          .catch(ex => {
            message.error(ex.message);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        setIsSubmitting(false);
      });
  };

  const handleOnBlurMainCustomerSelection = () => {
    setMainCustomerKeyword('');
  };

  const handleOnBlurSubCustomerSelection = () => {
    setSubCustomerKeyword('');
  };

  return (
    <>
      <Modal
        visible
        width="60%"
        title={t('pageCustomers:merge-customer-modal-title')}
        okText={t('pageCustomers:merge-customer-modal-confirm-merge-button')}
        cancelText={t('common:modal-cancel-text')}
        confirmLoading={isSubmitting}
        cancelButtonProps={{ loading: isSubmitting }}
        onOk={handleOnSave}
        onCancel={onCancel}
      >
        <Alert
          type="warning"
          style={{ marginBottom: 20 }}
          message={
            <Space direction="vertical">
              <Row align="middle">
                <Tag color="#ffba00">
                  <b>
                    <InfoCircleOutlined />
                  </b>
                </Tag>
                <span>
                  <b>{t('pageCustomers:merge-customer-modal-note')}:</b>
                </span>
              </Row>
              <ul>
                <li>{t('pageCustomers:merge-customer-modal-note-desc-1')}</li>
                <li>{t('pageCustomers:merge-customer-modal-note-desc-2')}</li>
                <li>
                  <Trans i18nKey="pageCustomers:merge-customer-modal-note-desc-3">
                    Please make sure you have selected the right one. This action <b>CANNOT</b> be undone.
                  </Trans>
                </li>
              </ul>
            </Space>
          }
        />
        <Form form={form} initialValues={formInitialValues}>
          <Row>
            <Col span={24}>
              <FormSelection
                name="mainCustomer"
                label={t('pageCustomers:merge-customer-modal-main-customer-label')}
                placeholder={t('pageCustomers:merge-customer-modal-main-customer-placeholder')}
                selections={mainCustomersSelection}
                filterOption={false}
                isSearching={isMainCustomerSuggestionsLoading}
                onSearch={value => delaySetMainCustomerKeyword(value)}
                onChange={setSelectedMainCustomer}
                onBlur={handleOnBlurMainCustomerSelection}
                requiredErrorMessage={t('pageCustomers:merge-customer-modal-main-customer-required-error-message')}
                isDisabled={!!defaultSelectedMainCustomer}
              />
            </Col>
            <Col span={24}>
              <FormSelection
                name="subCustomer"
                label={t('pageCustomers:merge-customer-modal-sub-customer-label')}
                placeholder={t('pageCustomers:merge-customer-modal-sub-customer-placeholder')}
                selections={subCustomersSelection}
                filterOption={false}
                isSearching={isSubCustomerSuggestionsLoading}
                onSearch={value => delaySetSubCustomerKeyword(value)}
                onChange={setSelectedSubCustomer}
                onBlur={handleOnBlurSubCustomerSelection}
                requiredErrorMessage={t('pageCustomers:merge-customer-modal-sub-customer-required-error-message')}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CustomerMergeModal;
