import { useState, useEffect } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FormInput from 'components/FormInput/FormInput';
import { withAppContext } from 'contexts/AppContext/AppContext';

import { useLocationQuery } from 'hooks/router';
import { getOverviewRoute, getOrdersRoute, getSignUpRoute, getLoginRoute } from 'utils/routes';
import { LOGIN_ERROR_INVALID_SESSION } from 'utils/constants';

import { FooterText, LogoContainer, LoginContainer, LoginFieldsContainer, WelcomeText } from './Login.styles';

const { useForm } = Form;

const Login = ({ onLogin, storeOperatorRoles }) => {
  const [form] = useForm();
  const history = useHistory();
  const { t } = useTranslation(['pageLogin']);

  const [isLoading, setIsLoading] = useState(false);
  const query = useLocationQuery();

  useEffect(() => {
    if (query.reason === LOGIN_ERROR_INVALID_SESSION) {
      message.error(t('pageLogin:login-error-invalid-session'));
      history.replace(getLoginRoute().path);
    }
  }, [query.reason, t, history]);

  const handleOnLogin = async values => {
    setIsLoading(true);
    return onLogin(values)
      .then(data => {
        message.success(t('pageLogin:login-success-message'));
        history.push(data.user.role === storeOperatorRoles.PACKAGING.code ? getOrdersRoute().path : getOverviewRoute().path);
        window.dataLayer.push({
          event: 'login',
          username: `${data.user.username}`
        });
      })
      .catch(ex => {
        message.error(t('pageLogin:login-fail-message', { reason: ex.message }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <LoginContainer>
      {/* Temporary remove helmet as google wasn't able to crawl this page with right title */}
      {/* <Helmet>
        <meta name="title" id="gtm-title" content="Login" />
        <title>{t('pageLogin:page-title')}</title>
      </Helmet> */}
      <a href="https://ulive.me">
        <LogoContainer />
      </a>
      <WelcomeText>{t('pageLogin:welcome-text')}!</WelcomeText>
      <LoginFieldsContainer>
        <Form form={form} onFinish={handleOnLogin}>
          <FormInput
            extraProps={{
              prefix: <UserOutlined />
            }}
            name="username"
            placeholder={' ' + t('pageLogin:form-input-placeholder-username')}
            requiredErrorMessage={t('pageLogin:form-input-error-msg-username')}
          />
          <FormInput
            extraProps={{
              prefix: <LockOutlined />,
              visibilityToggle: false
            }}
            name="password"
            type="password"
            placeholder={' ' + t('pageLogin:form-input-placeholder-password')}
            requiredErrorMessage={t('pageLogin:form-input-error-msg-password')}
          />
          <Button type="primary" htmlType="submit" loading={isLoading} style={{ width: '100%', height: '38px', marginBottom: '8px' }}>
            {t('pageLogin:form-submit-login-button')}
          </Button>
          <p style={{ fontSize: '10px', fontStyle: 'italic' }}>
            By logging in, you agree to our{' '}
            <a href="https://ulive.me/terms-of-use/" target="_blank" rel="noreferrer">
              Terms of Use
            </a>{' '}
            and have read our{' '}
            <a href="https://ulive.me/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            .
          </p>
          <Button type="link" style={{ width: '100%', height: '38px', textAlign: 'right' }} href={`${getSignUpRoute().path}?source=login`}>
            {t('pageLogin:form-submit-signup-button')}
          </Button>
        </Form>
      </LoginFieldsContainer>
      <FooterText>{t('pageLogin:footer-text')}</FooterText>
      <Row gutter={8} justify="center" style={{ color: 'white' }}>
        <Col>
          <a href="https://ulive.me/privacy-policy/" target="_blank" rel="noreferrer" style={{ color: 'white', textDecoration: 'underline' }}>
            {t('common:privacy-policy-text')}
          </a>
        </Col>
        <Col>
          <span>|</span>
        </Col>
        <Col>
          <a href="https://ulive.me/terms-of-use/" target="_blank" rel="noreferrer" style={{ color: 'white', textDecoration: 'underline' }}>
            {t('common:terms-of-use-text')}
          </a>
        </Col>
      </Row>
    </LoginContainer>
  );
};

export default withAppContext(Login);
