import { useEffect, useState, useMemo, useRef } from 'react';
import { Button, Card, Col, Divider, Form, Input, message, Modal, Row, Skeleton } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined, PlusOutlined, ShopOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { patchStoreMicrositeSettings, useGetStoreMicrositeSettings } from 'apis/store';
import { useGetProductsForMicrosite } from 'apis/product';

import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import FormUpload from 'components/Upload/FormUpload/FormUpload';
import Title from 'components/Title/Title';
import PhotoManager from 'components/PhotoManager/PhotoManager';
import { withAppContext } from 'contexts/AppContext/AppContext';

import { useFetchConstant } from 'hooks/constants';
import { switchElementPosition } from 'utils/general';

import {
  DeleteButton,
  PositionSelection,
  ProductCardContentRow,
  ProductCardNameLabel,
  ProductCardInventoryLabel,
  ProductCardVarianceLabel,
  ProductCardInfoCol
} from './MicrositeSettings.styles';

const { confirm } = Modal;
const { useForm } = Form;
const { error } = message;

const TYPE_PRODUCT = 'product';
const TYPE_CATEGORY = 'category';

const AUITO_BASE_URL = '.ulive.me';

const generatePositionSelection = maxNum => {
  const selections = [];
  for (let i = 1; i <= maxNum; i++) {
    selections.push({ label: i, value: i });
  }
  return selections;
};

const generateMicrositeUrl = (subdomainName, isWithProtocol = false) => {
  return `${isWithProtocol ? 'https://' : ''}${subdomainName}${AUITO_BASE_URL}`;
};

const getProductSelection = (products = [], featuredProducts) => {
  let selections = [...products];
  for (let i = 0; i < featuredProducts.length; i++) {
    const featuredProduct = featuredProducts[i];
    selections = selections.filter(selection => selection.value !== featuredProduct.value);
  }
  return selections;
};

const constructFeaturedProducts = (featuredProductIds = [], micrositeProducts = []) =>
  featuredProductIds.length > 0 && micrositeProducts.length > 0
    ? featuredProductIds.map(productId => micrositeProducts.find(product => product.value === productId))
    : [];

const CardActions = ({ options, position, onPositionChange, onDelete }) => {
  return (
    <Row align="middle" style={{ width: '100%' }}>
      <Col flex="auto">
        <PositionSelection options={options} value={position + 1} onChange={pos => onPositionChange(pos - 1)} />
      </Col>
      <Col>
        <DeleteButton onClick={onDelete} />
      </Col>
    </Row>
  );
};

const ProductCard = ({ product = {}, positionSelection, position, onPositionChange, onDelete }) => {
  return (
    <Card style={{ height: '100%' }} bodyStyle={{ height: '100%' }}>
      <ProductCardContentRow>
        <Col span={24}>
          <ProductCardNameLabel ellipsis={{ rows: 2, tooltip: product.label }}>{product.label}</ProductCardNameLabel>
        </Col>
        <ProductCardInfoCol span={24}>
          <FullWidthContainer>
            <ProductCardVarianceLabel>Variances: {product.numberOfVariance}</ProductCardVarianceLabel>
            <ProductCardInventoryLabel>Total Inventory: {product.totalInventory}</ProductCardInventoryLabel>
          </FullWidthContainer>
          <CardActions
            options={positionSelection}
            position={position}
            onPositionChange={onPositionChange(TYPE_PRODUCT, position)}
            onDelete={onDelete(TYPE_PRODUCT, position)}
          />
        </ProductCardInfoCol>
      </ProductCardContentRow>
    </Card>
  );
};

const CategoryCard = ({ category, positionSelection, position, onPositionChange, onDelete, onUpdate }) => {
  return (
    <Card size="small" style={{ lineHeight: '40px' }}>
      <Input value={category.label} onChange={e => onUpdate(position, category, e.target.value)} style={{ marginBottom: 14 }} />
      <CardActions
        options={positionSelection}
        position={position}
        onPositionChange={onPositionChange(TYPE_CATEGORY, position)}
        onDelete={onDelete(TYPE_CATEGORY, position)}
      />
    </Card>
  );
};

const MicrositeSettings = ({ store }) => {
  const { t } = useTranslation(['common', 'pageMicrositeSettings']);
  const [form] = useForm();
  const bannerRef = useRef(null);

  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentBanner, setCurrentBanner] = useState([]);

  const { isLoading: isLayoutViewsLoading, selection: layoutViews, data: layoutViewsConst } = useFetchConstant('micrositeLayoutViews');
  const { isLoading: isMicrositeSettingsLoading, data: micrositeSettings, refetch: refetchMicrositeSettings } = useGetStoreMicrositeSettings();
  const { isLoading: isMicrositeProductsLoading, data: micrositeProducts } = useGetProductsForMicrosite();
  const shouldUsePhotoManager = !micrositeSettings?.banner;

  useEffect(() => {
    if (!currentBanner.length && micrositeSettings?.bannerV2) {
      setCurrentBanner([micrositeSettings.bannerV2]);
    }
  }, [currentBanner, micrositeSettings]);

  const productPositionSelection = useMemo(() => generatePositionSelection(featuredProducts.length), [featuredProducts]);
  const categoryPositionSelection = useMemo(() => generatePositionSelection(categories.length), [categories]);
  const productSelection = useMemo(() => getProductSelection(micrositeProducts, featuredProducts), [micrositeProducts, featuredProducts]);
  const isLoading = isLayoutViewsLoading || isMicrositeSettingsLoading || isMicrositeProductsLoading;
  const isNew = !micrositeSettings || !micrositeSettings.subdomainName;

  useEffect(() => {
    if (!isLoading) {
      if (micrositeSettings) {
        form.setFieldsValue(micrositeSettings);

        const constructedFeaturedProducts = constructFeaturedProducts(micrositeSettings.featuredProducts, micrositeProducts);
        setFeaturedProducts(constructedFeaturedProducts);
        setCategories(micrositeSettings.categories || []);
      } else {
        form.setFieldsValue({
          layoutView: layoutViewsConst.DEFAULT.code
        });
      }
    }
  }, [isLoading, form, micrositeSettings, micrositeProducts, layoutViewsConst]);

  const handleOnSave = async e => {
    setIsSubmitting(true);
    e.preventDefault();

    try {
      const values = await form.validateFields();

      const payload = {
        ...values,
        featuredProducts: featuredProducts.map(product => product.value),
        categories
      };

      if (isNew) {
        confirm({
          title: t('pageMicrositeSettings:confirm-modal-create-microsite-title'),
          content: (
            <Row gutter={[0, 8]}>
              <Col span={24} style={{ fontWeight: 'bold' }}>
                {generateMicrositeUrl(payload.subdomainName)}
              </Col>
              <Col span={24}>{t('pageMicrositeSettings:confirm-modal-create-microsite-content')}</Col>
            </Row>
          ),
          okText: t('common:modal-ok-text'),
          cancelText: t('common:modal-cancel-text'),
          icon: <ExclamationCircleOutlined />,
          onOk: async () => {
            const [bannerV2] = bannerRef && bannerRef.current ? await bannerRef.current.update() : [];
            patchStoreMicrositeSettings({ ...payload, bannerV2 })
              .then(() => {
                message.success(t('common:create-success-message'));
                refetchMicrositeSettings();
              })
              .catch(ex => {
                error(ex.message);
              })
              .finally(() => {
                setIsSubmitting(false);
              });
          },
          onCancel() {
            setIsSubmitting(false);
          }
        });
      } else {
        const [bannerV2] = bannerRef && bannerRef.current ? await bannerRef.current.update() : [];
        patchStoreMicrositeSettings({ ...payload, bannerV2: bannerV2 || null })
          .then(updatedStore => {
            message.success(t('common:update-success-message'));
            // Update categories for new _id
            setCategories(updatedStore.micrositeSettings.categories);
          })
          .catch(ex => {
            error(ex.message);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    } catch (error) {
      error.errorFields.forEach(field => message.error(field.errors[0]));
      setIsSubmitting(false);
    }
  };

  const handleOnProductSelectSelected = selectedProductId => {
    const matchingProduct = productSelection.find(product => product.value === selectedProductId);
    if (matchingProduct) {
      const newFeaturedProducts = [...featuredProducts, matchingProduct];
      setFeaturedProducts(newFeaturedProducts);

      form.setFieldsValue({ product: '' });
    }
  };

  const handleOnAddCategoryButtonClick = () => {
    const newCategory = form.getFieldValue('category');

    if (newCategory) {
      const newCategories = [...categories, { label: newCategory }];
      setCategories(newCategories);

      form.setFieldsValue({ category: undefined });
    }
  };

  const handleOnPositionChange = (type, oldPosition) => newPosition => {
    if (type === TYPE_PRODUCT) {
      const newFeaturedProducts = switchElementPosition(featuredProducts, oldPosition, newPosition);
      setFeaturedProducts(newFeaturedProducts);
    } else if (type === TYPE_CATEGORY) {
      const newCategories = switchElementPosition(categories, oldPosition, newPosition);
      setCategories(newCategories);
    }
  };

  const handleOnCategoryUpdate = (position, oldCategory, newCategory) => {
    const newCategories = [...categories];
    newCategories[position] = { ...oldCategory, label: newCategory };
    setCategories(newCategories);
  };

  const handleOnDelete = (type, position) => () => {
    setIsSubmitting(true);

    const isTypeProduct = type === TYPE_PRODUCT;
    const isTypeCategory = type === TYPE_CATEGORY;

    let title = t('common:confirm-modal-delete-title');
    let content = t('common:confirm-modal-delete-content');
    if (isTypeProduct) {
      title = t('pageMicrositeSettings:confirm-modal-remove-featured-product-title');
      content = t('pageMicrositeSettings:confirm-modal-remove-featured-product-content');
    } else if (isTypeCategory) {
      title = t('pageMicrositeSettings:confirm-modal-remove-category-title');
      content = t('pageMicrositeSettings:confirm-modal-remove-category-content');
    }

    confirm({
      title,
      content,
      okText: t('common:modal-ok-text'),
      cancelText: t('common:modal-cancel-text'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        if (isTypeProduct) {
          let newFeaturedProducts = [...featuredProducts];
          newFeaturedProducts.splice(position, 1);
          setFeaturedProducts(newFeaturedProducts);
        } else if (isTypeCategory) {
          let newCategories = [...categories];
          newCategories.splice(position, 1);
          setCategories(newCategories);
        }
        setIsSubmitting(false);
      },
      onCancel() {
        setIsSubmitting(false);
      }
    });
  };

  return (
    <FullWidthContainer>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Form form={form} scrollToFirstError={true}>
          <Row gutter={32}>
            <Col span={24} sm={12}>
              {/* TODO: Fix hardcoded ulive.me */}
              <FormInput
                name="subdomainName"
                label={
                  isNew ? (
                    t('pageMicrositeSettings:form-field-label-domain-name')
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>{t('pageMicrositeSettings:form-field-label-domain-name')}</span>
                      <a
                        style={{ fontSize: '14px' }}
                        href={generateMicrositeUrl(micrositeSettings.subdomainName, true)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          {t('pageMicrositeSettings:text-view-microsite')} <ShopOutlined />
                        </span>
                      </a>
                    </div>
                  )
                }
                placeholder="domain-name"
                requiredErrorMessage={isNew ? t('pageMicrositeSettings:form-field-required-error-message-subdomain-name') : undefined}
                extraProps={{ addonAfter: AUITO_BASE_URL }}
                type="domain"
                disabled={!isNew}
              />
            </Col>
            <Col span={24} sm={12}>
              <FormRadioButton
                label={t('pageMicrositeSettings:form-field-label-layout-view')}
                name="layoutView"
                selections={layoutViews}
                buttonStyle="solid"
              />
            </Col>
          </Row>
          {!isNew && (
            <>
              <Row gutter={32}>
                <Col span={24} sm={12}>
                  <FormInput
                    name="descriptions"
                    label={t('pageMicrositeSettings:form-field-label-description')}
                    type="textArea"
                    rows={8}
                    extraProps={{ maxLength: 2000, showCount: true }}
                  />
                </Col>
                <Col span={24} sm={12}>
                  <FormInput
                    name="refundPolicy"
                    label={t('pageMicrositeSettings:form-field-label-refund-policy')}
                    type="textArea"
                    rows={8}
                    extraProps={{ maxLength: 5000, showCount: true }}
                  />
                </Col>
                <Col span={0} sm={12}>
                  {/* Hide this temporary, will enable soon */}
                  {/* <FormInput name="greetingNote" label={t('pageMicrositeSettings:form-field-label-greeting-note')} type="textArea" /> */}
                </Col>
              </Row>
              <Divider />

              <Title>{t('pageMicrositeSettings:title-banner-section')}</Title>
              <p>{t('pageMicrositeSettings:desc-banner-section-1')}</p>
              <p>{t('pageMicrositeSettings:desc-banner-section-2')}</p>
              <Row>
                <Col span={24}>
                  {shouldUsePhotoManager ? (
                    <PhotoManager
                      value={currentBanner}
                      onChange={setCurrentBanner}
                      label={t('pageMicrositeSettings:form-field-label-banner')}
                      buttonLabel={t('pageMicrositeSettings:form-field-label-banner-button')}
                      fileSizeThresholdToCompressInKb={200}
                      cropAspect={768 / 432}
                      ref={bannerRef}
                      shouldRemoveDocsMarkedForDelete
                      storeId={store._id}
                    />
                  ) : (
                    <FormUpload
                      name="banner"
                      label={t('pageMicrositeSettings:form-field-label-banner')}
                      buttonLabel={t('pageMicrositeSettings:form-field-label-banner-button')}
                      acceptTypes={['image/*']}
                      fileSizeLimitInMb={10}
                      maxCount={1}
                      isDisplayCard
                      allowCropping
                      cropAspect={320 / 100}
                    />
                  )}
                </Col>
              </Row>
              <Divider />

              <Title>{t('pageMicrositeSettings:title-social-section')}</Title>
              <Row gutter={32}>
                <Col span={24} sm={12}>
                  <FormInput
                    name={['socialMediaLink', 'fb']}
                    label={t('pageMicrositeSettings:form-field-label-social-fb')}
                    placeholder="store-name"
                    extraProps={{ addonBefore: 'www.facebook.com/' }}
                  />
                </Col>
                <Col span={24} sm={12}>
                  <FormInput
                    name={['socialMediaLink', 'ig']}
                    label={t('pageMicrositeSettings:form-field-label-social-ig')}
                    placeholder="store-name"
                    extraProps={{ addonBefore: 'www.instagram.com/' }}
                  />
                </Col>
              </Row>

              <Divider />

              <Title>{t('pageMicrositeSettings:title-product-section')}</Title>
              <span>{t('pageMicrositeSettings:desc-product-section')}</span>
              <FormSelection
                selections={productSelection}
                onSelect={handleOnProductSelectSelected}
                name="product"
                placeholder={t('pageMicrositeSettings:form-field-placeholder-product')}
                style={{ marginTop: 8 }}
              />
              <Row gutter={[16, 32]}>
                {featuredProducts.map((product, idx) => (
                  <Col key={idx} span={24} sm={8} lg={6}>
                    <ProductCard
                      product={product}
                      positionSelection={productPositionSelection}
                      position={idx}
                      onPositionChange={handleOnPositionChange}
                      onDelete={handleOnDelete}
                    />
                  </Col>
                ))}
              </Row>

              <Divider />

              <Title>{t('pageMicrositeSettings:title-category-section')}</Title>
              <span>{t('pageMicrositeSettings:desc-category-section')}</span>
              <Row gutter={16} style={{ marginTop: 16 }}>
                <Col flex="auto">
                  <FormInput
                    name="category"
                    placeholder={t('pageMicrositeSettings:form-field-placeholder-category')}
                    extraProps={{ onPressEnter: handleOnAddCategoryButtonClick }}
                  />
                </Col>
                <Col>
                  <Button icon={<PlusOutlined />} onClick={handleOnAddCategoryButtonClick} loading={isSubmitting}>
                    {t('pageMicrositeSettings:button-add-category')}
                  </Button>
                </Col>
              </Row>
              <Row gutter={[16, 32]}>
                {categories.map((category, idx) => (
                  <Col key={idx} span={24} sm={8} lg={6}>
                    <CategoryCard
                      category={category}
                      positionSelection={categoryPositionSelection}
                      position={idx}
                      placeholder={t('pageMicrositeSettings:form-field-placeholder-category')}
                      onPositionChange={handleOnPositionChange}
                      onDelete={handleOnDelete}
                      onUpdate={handleOnCategoryUpdate}
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}

          <Button type="primary" icon={<CheckOutlined />} onClick={handleOnSave} loading={isSubmitting}>
            {isNew ? t('pageMicrositeSettings:button-create-microsite') : t('pageMicrositeSettings:button-update-settings')}
          </Button>
        </Form>
      )}
    </FullWidthContainer>
  );
};

export default withAppContext(MicrositeSettings);
