import styled from '@emotion/styled';
import { Checkbox, Tag } from 'antd';

export const PlayersDisplayContainer = styled(Checkbox.Group)`
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: scroll;
`;

export const PlayerTag = styled(Tag)`
  display: flex;
  align-items: center;
  padding: 4px;
`;

export const PlayerCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
`;

export const PlayerProfileContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PlayerProfilePic = styled.div`
  margin-right: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-image: ${props => props.src && `url("${props.src}")`};
  background-position: center;
  background-size: cover;
`;
