import React, { useState } from 'react';

import { Modal, Space, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import { getProductCSVData } from 'apis/product';
import { PRODUCT_CSV_HEADERS } from 'utils/constants';

import CsvDownload from 'components/CsvDownload/CsvDownload';
import { TitleIcon } from './DownloadProductsCSVModal.styles';

const DownloadProductsCSVModal = ({ visible, onCancel }) => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { t } = useTranslation(['pageProduct']);

  const csvAsyncExportMethod = async () => {
    const csvData = await getProductCSVData();

    return csvData;
  };
  return (
    <Modal
      visible={visible}
      maskClosable={false}
      onCancel={() => {
        onCancel();
      }}
      footer={
        <Space>
          <CsvDownload
            asyncExportMethod={csvAsyncExportMethod}
            onDataLoading={() => setIsLoadingData(true)}
            onDataLoaded={() => setIsLoadingData(false)}
            headers={PRODUCT_CSV_HEADERS}
            filename={`Products_${moment().format('YYYYMMDD')}.csv`}
          >
            <Button loading={isLoadingData} type="primary" icon={<DownloadOutlined />}>
              {t('pageProduct:download-products-csv-ok-button-text')}
            </Button>
          </CsvDownload>
        </Space>
      }
    >
      <p style={{ fontSize: '16px', marginTop: '16px' }}>
        <TitleIcon />
        {t('pageProduct:download-products-csv-content')}
      </p>
    </Modal>
  );
};
export default DownloadProductsCSVModal;
