import { EMAIL_ADDRESS_REGEX, CURRENCY_FORMAT } from 'utils/constants';

/* ------------------------------------------------General function------------------------------------------------- */
/* ------------------------------------------------Currency ------------------------------------------------- */
export const constructReadableNum = number => {
  return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const constructDisplayPrice = (number, currencyISO) => {
  const decimalSupported = guard(() => CURRENCY_FORMAT[currencyISO].decimal, 2);
  const numberSeparator = guard(() => CURRENCY_FORMAT[currencyISO].separator, ',') || ',';

  if (!checkIsNumber(number)) {
    return Number(0).toFixed(decimalSupported);
  }

  const numberWithDecimal = Number(number).toFixed(decimalSupported);
  const numberWithDecimalAndComma = numberWithDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, numberSeparator);

  return numberWithDecimalAndComma;
};

export const constructDisplayPriceWithCurrency = ({
  amount,
  currencySymbol,
  currencyISO,
  isHideSymbol,
  isDeduct,
  labelWhenUndefined,
  labelWhenZero
}) => {
  if (labelWhenUndefined) {
    if (!checkHasValue(amount)) {
      return labelWhenUndefined;
    }
  }

  if (labelWhenZero) {
    if (amount === 0) {
      return labelWhenZero;
    }
  }

  const displayFee = String(constructDisplayPrice(amount, currencyISO));
  const displayFeeWithSymbol = !isHideSymbol && currencySymbol ? `${currencySymbol}${displayFee}` : displayFee;

  if (isDeduct) {
    return `- ${displayFeeWithSymbol}`;
  }

  return displayFeeWithSymbol;
};

export const getSingularOrPluralLabel = (amount, singularLabel, { pluralLabel, labelOnly } = {}) => {
  const returnLabel = amount === 1 ? singularLabel : pluralLabel || `${singularLabel}s`;
  const returnAmount = labelOnly ? '' : amount;

  return `${returnAmount} ${returnLabel}`.trim();
};

export const getLabelOfConstant = (selectedConstant, constants, constantField = 'value') => {
  const foundConstant = constants.find(constant => constant[constantField] === selectedConstant);
  return foundConstant && foundConstant.label;
};

export const checkIsObjectEmpty = object => {
  return guard(() => !object || Object.keys(object).length === 0, true);
};

export const checkIsArray = array => {
  return Array.isArray(array);
};

export const checkIsArrayEmpty = array => {
  return !checkIsArray(array) || array.length === 0;
};

export const checkHasValue = value => {
  return value !== undefined && value !== null;
};

export const checkIsStringBoolTrue = str => {
  return str === 'true';
};

export const checkIsNumber = value => {
  return !Number.isNaN(Number(value));
};

export const checkIsEmail = value => EMAIL_ADDRESS_REGEX.test(value);

export const guard = (callback, fallbackValue) => {
  try {
    const value = callback();
    if (value === undefined || value === null) {
      return fallbackValue;
    }

    return value;
  } catch {
    return fallbackValue;
  }
};

export const rounds = (value, decimals = 2) => {
  return Number(`${Math.round(`${Number(value).toFixed(20)}e${decimals}`)}e-${decimals}`);
};

export const generateCode = (prefix = '', postfix = '') => {
  return prefix + (Math.random().toString(36) + Date.now().toString(36)).substr(2, 9).toUpperCase() + postfix;
};

export const escapeRegex = string => {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const switchElementPosition = (array, oldPosition, newPosition) => {
  const newArray = [...array];
  if (oldPosition < newArray.length && newPosition < newArray.length) {
    const element = newArray.splice(oldPosition, 1)[0];
    newArray.splice(newPosition, 0, element);
  }
  return newArray;
};

export const getDistinctArray = array => {
  return array.filter((value, index, self) => self.indexOf(value) === index);
};

export const purifyContactNumber = ({ countryCode, contactNumber }) => {
  let purifiedContactNumber = contactNumber;
  const countryCodeLastChar = countryCode.charAt(countryCode.length - 1);
  const contactNumberFirstChar = contactNumber.charAt(0);

  if (countryCodeLastChar === '0' && contactNumberFirstChar === '0') {
    purifiedContactNumber = contactNumber.substring(1);
  }
  purifiedContactNumber = purifiedContactNumber.replace(/[\s.+-]/g, '');
  return purifiedContactNumber;
};

export const getFormattedContact = contact => {
  if (!(contact && contact.countryCode && contact.contactNumber)) return;
  const { countryCode, contactNumber } = contact;

  let formattedContact;

  if (contactNumber.startsWith(countryCode)) {
    formattedContact = `${contactNumber}`;
  } else {
    const purifiedContactNumber = purifyContactNumber({ countryCode, contactNumber });
    formattedContact = `${countryCode}${purifiedContactNumber}`;
  }
  return formattedContact;
};

/* ------------------------------------------------Specific function------------------------------------------------- */
// Listing
export const convertAmenitiesSelectionToObj = (listOfAmenities, amenitiesSelection) => {
  return guard(() =>
    Object.keys(amenitiesSelection).reduce((objectAmenitiesSelection, currentKey) => {
      objectAmenitiesSelection[currentKey] = listOfAmenities.filter(
        payloadValue => !!amenitiesSelection[currentKey].data.find(amentity => amentity.key === payloadValue)
      );
      return objectAmenitiesSelection;
    }, {})
  );
};

export const constructAbbrWeightValue = (number, hasSuffix = true) => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1).replace(/\.0$/, '') + (hasSuffix && 'ton');
  }
  if (number >= 1000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, '') + (hasSuffix && 'kg');
  }
  return number + (hasSuffix && 'g');
};

// File Lists Formatter (img files and pdf files)
export const formatReceiptFiles = (files = []) => {
  const pdfFiles = files.filter(file => file.url && String(file.url).endsWith('pdf'));
  const imageFiles = files.filter(file => file.url && !String(file.url).endsWith('pdf'));
  return {
    pdfFiles,
    imageFiles
  };
};

export const formatReceipts = (files = []) => {
  const pdfFiles = files.filter(file => file.original?.url && String(file.original.url).endsWith('pdf'));
  const imageFiles = files.filter(file => file.original?.url && !String(file.original.url).endsWith('pdf'));
  return {
    pdfFiles,
    imageFiles
  };
};

export const encodeImageUrl = imageUrl => {
  const imageUrlPaths = imageUrl.split('/');
  const imageUrlFileName = imageUrlPaths[imageUrlPaths.length - 1];
  return imageUrl.replace(imageUrlFileName, encodeURIComponent(imageUrlFileName));
};

export const flatOneLevelArray = initialArray => {
  const flattenedArray = [];
  for (let i = 0; i < initialArray.length; i++) {
    const current = initialArray[i];
    for (let j = 0; j < current.length; j++) {
      flattenedArray.push(current[j]);
    }
  }
  return flattenedArray;
};

export const wait = seconds => {
  return new Promise((resolve, reject) => {
    if (!seconds) {
      return reject(new Error('seconds is not pass in'));
    }
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
};
