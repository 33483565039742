import styled from '@emotion/styled';

const TYPES = {
  narrow: '0px',
  small: '4px',
  default: '1em'
};

export const StyledP = styled.p`
  margin-bottom: ${props => TYPES[props.type]};
`;
