import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const scrollInSlotMachine = y => keyframes`
  100%{
    top: ${y}
  }
`;

const glow = keyframes`
 from {
    box-shadow: 0 0 10px #fff, 0 0 20px #FFBA00, 0 0 30px #FF7212, 0 0 40px #FF7212, 0 0 50px #FF7212, 0 0 60px #FF7212, 0 0 70px #FFBA00;
  }
  
  to {
    box-shadow: 0 0 60px 2px #2196f3, 0.5rem 0.5rem 30px #26115a;
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  text-align: center;
`;

export const ContainerWrapper = styled.div`
  width: 180px;
  height: 180px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  border-color: #ff7212;
  border-style: inset;
  padding: 0px;
  border-width: 16px;
  box-shadow: 0 0 10px #fff, 0 0 20px #ffba00, 0 0 30px #ff7212, 0 0 40px #ff7212, 0 0 50px #ff7212, 0 0 60px #ff7212, 0 0 70px #ffba00;
`;

export const ContainerWrapperGlow = styled.div`
  width: 180px;
  height: 180px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  border-color: ${props => props.theme.color.secondary};
  border-style: inset;
  padding: 0px;
  border-width: 16px;
  animation: ${glow} 1s ease-in-out infinite alternate;
`;

export const TextContainer = styled.div`
  position: absolute;
  font-weight: bold;
  font-size: 20px;
  height: 148px;
  width: 100%;
  top: 0;

  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-duration: ${props => `${props.timer}s`};
  animation-delay: 1s;
  animation-direction: ease-in-out;
  animation-name: ${props => scrollInSlotMachine(`-${props.winnerIndex * 152}px`)};

  &:hover {
    cursor: pointer;
  }
`;

export const SlotItemContainer = styled.div`
  margin-bottom: 4px;
  background-color: #9e9e9e;
  background-image: linear-gradient(to bottom right, #ffba00, #ff7212);
  box-shadow: lavender 4px 8px 16px;
  height: 148px;
  width: 148px;
  white-space: nowrap;
`;

export const SlotItemTextOnly = styled.p`
  margin-bottom: 0px;
  font-weight: 700;
  line-height: 148px;
  white-space: nowrap;
  color: white;
`;

export const AvatarProfilePic = styled.div`
  height: 100%;
  width: 100%;
  background-image: ${props => props.profilePicUrl && `url("${props.profilePicUrl}")`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
