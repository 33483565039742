import { List } from 'antd';

import PriceDisplay from 'components/PriceDisplay/PriceDisplay';
import ImagePreview from 'components/ImagePreview/ImagePreview';

const ProductDisplayListItem = ({ label, keyword, quantity, total, previewImageUrl, thumbnailImageUrl, storeCurrency }) => {
  return (
    <List.Item>
      <List.Item.Meta
        title={label}
        description={
          previewImageUrl ? (
            <div>
              <span>{keyword}</span>
              <div style={{ height: '8px' }} />
              <ImagePreview width={64} previewImageUrl={previewImageUrl} thumbnailImageUrl={thumbnailImageUrl} />
            </div>
          ) : (
            keyword
          )
        }
      />
      <div style={{ width: '20%', marginLeft: '16px' }}>{`x ${quantity}`}</div>
      <div style={{ width: '25%', textAlign: 'right' }}>
        <PriceDisplay storeCurrency={storeCurrency} amount={total} />
      </div>
    </List.Item>
  );
};

export default ProductDisplayListItem;
