module.exports = {
  IS_DEV: !process.env.REACT_APP_DEPLOY_ENV || process.env.REACT_APP_DEPLOY_ENV === 'dev',
  PORT: process.env.PORT || '5001',

  REACT_APP_DEPLOY_ENV: process.env.REACT_APP_DEPLOY_ENV || 'dev',
  REACT_APP_API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:5000/la/v1',
  REACT_APP_LIVE_STREAM_WS_URL: process.env.REACT_APP_LIVE_STREAM_WS_URL || 'wss://ws-live-stream-dev.ulive.me',
  REACT_APP_S3_BUCKET_PRESIGN_URL: process.env.REACT_APP_S3_BUCKET_PRESIGN_URL,
  REACT_APP_S3_PRESIGN_URL: process.env.REACT_APP_S3_PRESIGN_URL,
  REACT_APP_S3_PRESIGN_URL_FOR_RECEIPT: process.env.REACT_APP_S3_PRESIGN_URL_FOR_RECEIPT,
  REACT_APP_S3_DELETE_DOC_URL: process.env.REACT_APP_S3_DELETE_DOC_URL,
  REACT_APP_S3_BUCKET_URL: `https://s3.ap-southeast-1.amazonaws.com/auito-upload-sls-${process.env.REACT_APP_DEPLOY_ENV}-document`,

  REACT_APP_FB_APP_ID: process.env.REACT_APP_FB_APP_ID || '948084332598801', // FB App - ulive.me by Dresstal (Dev)
  REACT_APP_GTM_ID: process.env.REACT_APP_GTM_ID || 'GTM-GN44P7DC', // dev gtag

  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,

  REACT_APP_IS_HIDE_SUBS_UI: process.env.REACT_APP_IS_HIDE_SUBS_UI === 'true'
};
