import { Button, Carousel, Col, Row } from 'antd';
import { CalendarOutlined, FacebookOutlined, LikeOutlined, LoginOutlined, MessageOutlined, RobotOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { IS_DEV } from 'config/env';

import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';

import { useAddHotjarScript } from 'hooks/hotjar';

import Logo from 'images/ulive-logo-wo-bg.png';

import { getLoginRoute, getOverviewRoute, getOrdersRoute, getSignUpRoute } from 'utils/routes';

import {
  GradientBgContainer,
  StyledLayout,
  StyledHeader,
  HeaderLogo,
  StyledContent,
  StyledFooter,
  FooterStyledRow,
  FooterCopyrightText,
  FooterFireIcon,
  CTABtn,
  CTALink
} from './Home.styles';
import { FirstContentTitle, FirstContentDesc, FirstContentDescContainer, FirstImg } from './Content1.styles';
import { SecondContentTitle, SecondImg } from './Content2.styles';
import { ThirdContentTitle, ThirdImg } from './Content3.styles';
import { FourthContentTitle, FourthImg } from './Content4.styles';
import { useEffect } from 'react';

const FirstContent = ({ t }) => {
  const handleOnClickSeeWhoUsing = () => window.open('https://ulive.me/#our-merchants', '_blank');

  return (
    <div>
      <div style={{ height: '100vh' }}>
        <FirstContentTitle>
          {t('pageHome:first-content-title')} <span style={{ fontSize: '1.4em' }}>🤩</span>
        </FirstContentTitle>
        <FirstContentDescContainer>
          <FirstContentDesc>
            {t('pageHome:first-content-desc-1')}{' '}
            <span style={{ fontSize: '1.5em', color: '#2C278A' }}>
              <b>500+</b>
            </span>{' '}
            {t('pageHome:first-content-desc-2')}
          </FirstContentDesc>
        </FirstContentDescContainer>
        <Button type="primary" size="large" icon={<LikeOutlined />} onClick={handleOnClickSeeWhoUsing}>
          {t('pageHome:first-content-cta')}
        </Button>
        <FirstImg />
      </div>
    </div>
  );
};

const SecondContent = ({ t }) => {
  const message = t('pageHome:second-content-cta-preset-message');
  const encodedMsg = encodeURIComponent(message);
  const handleOnClickDemo = () => window.open(`https://api.whatsapp.com/send?phone=601158681885&text=${encodedMsg}`);

  return (
    <div>
      <div style={{ height: '100vh' }}>
        <SecondImg />
        <SecondContentTitle>
          {t('pageHome:second-content-title')}{' '}
          <span style={{ fontSize: '1.2em', color: '#2C278A' }}>
            <b>{t('pageHome:second-content-title-emp')}</b>
          </span>{' '}
          {t('pageHome:second-content-title-2')} <span style={{ fontSize: '1.4em' }}>👍</span>
          <br />
          <Button type="primary" size="large" icon={<CalendarOutlined />} onClick={handleOnClickDemo} style={{ marginTop: '5%' }}>
            {t('pageHome:second-content-cta')}
          </Button>
        </SecondContentTitle>
      </div>
    </div>
  );
};

const ThirdContent = ({ t }) => {
  const handleOnClickAutomateNow = () => window.open('https://ulive.me/#how-it-works', '_blank');

  return (
    <div>
      <div style={{ height: '100vh' }}>
        <ThirdContentTitle>
          {t('pageHome:third-content-title')}{' '}
          <span style={{ fontSize: '1.2em', color: '#2C278A' }}>
            <b>{t('pageHome:third-content-title-emp')}</b>
          </span>{' '}
          <span style={{ fontSize: '1.4em' }}>💸</span>
          <br />
          <Button type="primary" size="large" icon={<RobotOutlined />} onClick={handleOnClickAutomateNow} style={{ marginTop: '4vw' }}>
            {t('pageHome:third-content-cta')}
          </Button>
        </ThirdContentTitle>
        <ThirdImg />
      </div>
    </div>
  );
};

const FourthContent = ({ t }) => {
  const handleOnClickTalkToUsNow = () => window.open('https://m.me/uliveofficial?ref=store_home', '_blank');

  return (
    <div>
      <div style={{ height: '100vh' }}>
        <FourthContentTitle>
          {t('pageHome:fourth-content-title')}{' '}
          <span style={{ fontSize: '1.2em', color: '#2C278A' }}>
            <b>{t('pageHome:fourth-content-title-emp')}</b>
          </span>{' '}
          {t('pageHome:fourth-content-title-2')}
          <span style={{ fontSize: '1.4em' }}>😍</span>
        </FourthContentTitle>
        <Button
          type="primary"
          size="large"
          icon={<MessageOutlined />}
          onClick={handleOnClickTalkToUsNow}
          style={{ marginTop: '4%', marginLeft: '16vw' }}
        >
          {t('pageHome:fourth-content-cta')}
        </Button>
        <FourthImg />
      </div>
    </div>
  );
};

const Home = ({ isLoadingAppContext, isLoggedIn, user, storeOperatorRoles }) => {
  const { t } = useTranslation(['pageHome']);
  const history = useHistory();
  useAddHotjarScript(!IS_DEV);

  useEffect(() => {
    if (isLoggedIn && !isLoadingAppContext) {
      history.push(user.role === storeOperatorRoles.PACKAGING.code ? getOrdersRoute().path : getOverviewRoute().path);
    }
  }, [isLoadingAppContext, isLoggedIn, history, user.role, storeOperatorRoles]);

  const handleOnClickLoginBtn = () => history.push(getLoginRoute().path);
  const handleOnClickContactUs = () => window.open('https://ulive.me/?source=homepage', '_blank');
  const handleOnClickGetFreeTrial = () => history.push(getSignUpRoute().path);
  const handleOnClickFollowFB = () => window.open('https://www.facebook.com/uliveofficial', '_blank');

  return (
    <GradientBgContainer>
      <StyledLayout>
        <StyledHeader>
          <Row justify="space-between" align="middle">
            <Col>
              <HeaderLogo src={Logo} alt="ulive logo" />
            </Col>
            <Col>
              <Button type="primary" icon={<LoginOutlined />} onClick={handleOnClickLoginBtn}>
                {t('pageHome:header-login-btn')}
              </Button>
            </Col>
          </Row>
        </StyledHeader>
        <StyledContent>
          <Carousel autoplay={true} dotPosition="bottom">
            <FirstContent t={t} />
            <SecondContent t={t} />
            <ThirdContent t={t} />
            <FourthContent t={t} />
          </Carousel>
        </StyledContent>
        <StyledFooter>
          <FooterStyledRow gutter={[0, 8]} justify="space-between">
            <Col span={24} lg={{ span: 12, order: 2 }}>
              <Row justify="space-between" gutter={[0, 8]}>
                <Col>
                  <div style={{ marginRight: '24px' }}>
                    <LanguageSwitcher localesToShow={['en', 'zh-CN']} mode="dark" />
                  </div>
                </Col>
                <Col>
                  <CTABtn onClick={handleOnClickContactUs}>{t('pageHome:footer-contact-us-btn')}</CTABtn>
                </Col>
                <Col>
                  <CTABtn onClick={handleOnClickGetFreeTrial}>
                    <FooterFireIcon>🔥</FooterFireIcon>
                    {t('pageHome:footer-free-trial-btn')}
                  </CTABtn>
                </Col>
                <Col>
                  <CTABtn onClick={handleOnClickFollowFB}>
                    {t('pageHome:footer-follow-fb-btn')} <FacebookOutlined />
                  </CTABtn>
                </Col>
                <Col>
                  <CTALink href="https://ulive.me/privacy-policy/" target="_blank" rel="noreferrer">
                    {t('common:privacy-policy-text')}
                  </CTALink>
                </Col>
                <Col>
                  <CTALink href="https://ulive.me/terms-of-use/" target="_blank" rel="noreferrer">
                    {t('common:terms-of-use-text')}
                  </CTALink>
                </Col>
              </Row>
            </Col>
            <Col span={24} lg={{ span: 12, order: 1 }}>
              <FooterCopyrightText>© Copyright 2022 Ulive Tech Sdn Bhd. All rights reserved.</FooterCopyrightText>
            </Col>
          </FooterStyledRow>
        </StyledFooter>
      </StyledLayout>
    </GradientBgContainer>
  );
};

export default withAppContext(Home);
