import { useState } from 'react';
import { Button, Col, Divider, message, Modal, Popover, Row, Table } from 'antd';
import { ExclamationCircleOutlined, EyeInvisibleOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useGetPaginatedShipments, getShipmentLinkedOrders, patchShipmentStatus } from 'apis/shipment';

import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import TextButton from 'components/TextButton/TextButton';
import Title from 'components/Title/Title';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';

import { useFetchConstant } from 'hooks/constants';

import { SHIPMENT_ACTION_DEACTIVATE, getFilteredConstants } from 'utils/constants';
import { getLabelOfConstant, constructAbbrWeightValue } from 'utils/general';
import { getShipmentDetailRoute, getNewShipmentRoute } from 'utils/routes';
import {
  constructColumn,
  constructColumnFilterSearch,
  handleOnAggregationTableChange,
  constructColumnFilterRadio,
  formatPaginationShowTotalDisplay
} from 'utils/table/table';

import { TitleContainer, TooltipIcon, PopoverContainer, DangerUpdateContentCol } from './Shipments.styles';

const { confirm, error } = Modal;

const getShippingFeePopover = (t, data) => {
  let content = data.freeDeliveryTargetAmount && (
    <>
      <b>{t('pageShipment:tooltip-message-free-delivery')}</b>{' '}
      <PriceDisplay prefix={`${t('pageShipment:tooltip-message-free-delivery-price')} `} amount={data.freeDeliveryTargetAmount} />
    </>
  );

  if (data.isChargedByWeight) {
    const shippingFeeListContent = data.shippingFeeWeightTiers.map((tier, idx, weightTiers) => {
      const weightText = weightTiers[idx + 1] ? (
        <Row>
          <Col span={8}>{constructAbbrWeightValue(tier.min)}</Col>
          <Col span={3}>-</Col>
          <Col>{constructAbbrWeightValue(weightTiers[idx + 1].min - 1)}</Col>
        </Row>
      ) : (
        `${constructAbbrWeightValue(tier.min)} ${t('pageShipment:tooltip-message-weight-tier')}`
      );

      return (
        <Row key={tier.min + tier.fee}>
          <Col span={16}>{weightText}</Col>
          <Col>
            <PriceDisplay amount={tier.fee} />
          </Col>
        </Row>
      );
    });

    content = (data.isChargedByWeight || content) && (
      <PopoverContainer>
        {shippingFeeListContent}
        {content && (
          <>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            {content}
          </>
        )}
      </PopoverContainer>
    );
  }
  return (
    content && (
      <Popover trigger="click" content={content}>
        <TooltipIcon />
      </Popover>
    )
  );
};

const getShippingFeeText = (record, isIncludeShippingFee) => {
  if (isIncludeShippingFee) {
    if (record.isChargedByWeight) {
      const minFee = record.shippingFeeWeightTiers.reduce((minFee, tier) => (tier.fee < minFee ? tier.fee : minFee), 99999);
      const maxFee = record.shippingFeeWeightTiers.reduce((maxFee, tier) => (tier.fee > maxFee ? tier.fee : maxFee), 0);

      if (minFee === maxFee) {
        return <PriceDisplay amount={minFee} />;
      }
      return (
        <span>
          <PriceDisplay amount={minFee} /> - <PriceDisplay amount={maxFee} />
        </span>
      );
    }
    return <PriceDisplay amount={record.shippingFlatFee} />;
  }
  return '-';
};

const constructColumns = (t, deliveryMethods, deliveryMethodsConst, shipmentStatuses, shipmentStatusesConst, handleOnEditStatusClick) => [
  {
    ...constructColumn(t('pageShipment:table-header-label'), 'label', {
      width: '30%',
      linkFunc: record => getShipmentDetailRoute(record._id).path
    }),
    ...constructColumnFilterSearch('label', t('pageShipment:table-header-action-search-shipment-label'), { hasAggregationFilter: true })
  },
  {
    ...constructColumn(t('pageShipment:table-header-delivery-method'), 'deliveryMethod', { hasAggregationSorter: true, width: '10%' }),
    ...constructColumnFilterRadio('deliveryMethod', deliveryMethods),
    render: deliveryMethod => getLabelOfConstant(deliveryMethod, deliveryMethods)
  },
  {
    ...constructColumn(t('pageShipment:table-header-status'), 'status', { hasAggregationSorter: true, width: '10%' }),
    ...constructColumnFilterRadio('status', shipmentStatuses),
    render: (status, record) => (
      <>
        <StatusIndicator isActive={shipmentStatusesConst && record.status === shipmentStatusesConst.ACTIVE.code} />{' '}
        {getLabelOfConstant(status, shipmentStatuses)}
      </>
    )
  },
  {
    ...constructColumn(t('pageShipment:table-header-fee'), 'shippingFee', { isPrice: true, hasAggregationSorter: true, width: '20%' }),
    render: (_, record) => {
      const isIncludeShippingFeeDeliveryMethods = getFilteredConstants(deliveryMethodsConst, 'isIncludeShippingFee', true);
      const isIncludeShippingFee = isIncludeShippingFeeDeliveryMethods.includes(record.deliveryMethod);
      return (
        <Row>
          <Col span={20}>{getShippingFeeText(record, isIncludeShippingFee)}</Col>
          {isIncludeShippingFee && <Col>{getShippingFeePopover(t, record)}</Col>}
        </Row>
      );
    }
  },
  {
    ...constructColumn(t('pageShipment:table-header-actions'), 'action', { width: '10%' }),
    render: (_, record) => {
      const isActive = shipmentStatusesConst && record.status === shipmentStatusesConst.ACTIVE.code;
      return (
        <TextButton
          icon={isActive ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          onClick={() => handleOnEditStatusClick(isActive, record._id)}
          text={isActive ? t('pageShipment:deactivate-button') : t('pageShipment:activate-button')}
          tooltipMessage={t('common:text-button-tooltip-message', { action: t('pageShipment:tooltip-message-update-status') })}
        />
      );
    }
  }
];

const Shipments = () => {
  const { t } = useTranslation(['common', 'pageShipment']);
  const history = useHistory();
  const [query, setQuery] = useState({ currentPage: 1, limit: 10 });

  const { isLoading: isShipmentsLoading, paginatedData: shipments, total, refetch: refetchShipments } = useGetPaginatedShipments(query);
  const { selection: shipmentStatuses, data: shipmentStatusesConst, isLoading: isShipmentStatusesLoading } = useFetchConstant('shipmentStatuses');
  const { selection: deliveryMethods, data: deliveryMethodsConst, isLoading: isDeliveryMethodsLoading } = useFetchConstant('deliveryMethods');

  const isLoading = isShipmentsLoading || isShipmentStatusesLoading || isDeliveryMethodsLoading;

  const handleOnEditStatusClick = async (isActive, selectedShipmentId) => {
    const status = isActive ? shipmentStatusesConst.INACTIVE.code : shipmentStatusesConst.ACTIVE.code;
    const { ordersToBeUnset } = await getShipmentLinkedOrders(selectedShipmentId, SHIPMENT_ACTION_DEACTIVATE);
    confirm({
      title: isActive ? t('pageShipment:confirm-modal-deactivate-status-title') : t('pageShipment:confirm-modal-activate-status-title'),
      ...(isActive &&
        ordersToBeUnset.length && {
          content: (
            <DangerUpdateContentCol>
              {t('pageShipment:confirm-modal-deactivate-status-content', { numberOfOrders: ordersToBeUnset.length })}
            </DangerUpdateContentCol>
          )
        }),
      icon: <ExclamationCircleOutlined />,
      okText: isActive ? t('pageShipment:confirm-modal-dectivate-button-text') : t('pageShipment:confirm-modal-activate-button-text'),
      cancelText: t('common:modal-cancel-text'),
      okButtonProps: { type: 'primary', danger: isActive },
      onOk() {
        patchShipmentStatus(selectedShipmentId, status)
          .then(() => {
            message.success(t('common:update-success-message'));
            refetchShipments();
          })
          .catch(ex => {
            error({
              title: ex.message
            });
          });
      },
      onCancel() {}
    });
  };

  const handleOnEditDetailsClick = selectedShipmentId => history.push(getShipmentDetailRoute(selectedShipmentId).path);

  return (
    <FullWidthContainer>
      <TitleContainer justify="space-between">
        <Title>{t('pageShipment:title')}</Title>
        <Button type="primary" shape="round" icon={<PlusOutlined />} onClick={() => history.push(getNewShipmentRoute().path)}>
          {t('pageShipment:add-new-shipment-button')}
        </Button>
      </TitleContainer>
      <Table
        size="small"
        loading={isLoading}
        rowKey={record => record._id}
        dataSource={shipments}
        columns={constructColumns(
          t,
          deliveryMethods,
          deliveryMethodsConst,
          shipmentStatuses,
          shipmentStatusesConst,
          handleOnEditStatusClick,
          handleOnEditDetailsClick
        )}
        scroll={{ x: 1000 }}
        locale={{
          triggerDesc: t('common:table-header-sort-trigger-desc'),
          triggerAsc: t('common:table-header-sort-trigger-asc'),
          cancelSort: t('common:table-header-sort-cancel-sort')
        }}
        pagination={{
          total,
          showSizeChanger: true,
          showTotal: formatPaginationShowTotalDisplay
        }}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
      />
    </FullWidthContainer>
  );
};

export default Shipments;
