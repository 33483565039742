import { useEffect, useMemo, useState } from 'react';
import { Modal, Skeleton, message } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { guard } from 'utils/general';

import { useGetOrdersForBulkPrint, patchOrderPrintedStatus, useRefetchOrders } from 'apis/order';

import { useFetchConstant } from 'hooks/constants';
import { useLocationQuery } from 'hooks/router';

import Invoice from '../components/Invoice';

const useIsCompact = () => {
  const { compact } = useLocationQuery();
  const isCompact = useMemo(() => !!compact, [compact]);

  return isCompact;
};

const useGetOrderIds = () => {
  const { ids } = useLocationQuery();
  const orderIds = useMemo(() => guard(() => ids.split(','), []), [ids]);

  return orderIds;
};

const usePrint = ({ t, isReady, orderIds }) => {
  const [isPrinted, setIsPrinted] = useState(false);
  const refetchOrders = useRefetchOrders();

  const handleOnCancelIsPrintedStatusModal = () => {
    setIsPrinted(false);
  };

  const handleOnUpdateIsPrintedStatusModal = () => {
    patchOrderPrintedStatus({ orderIds: orderIds })
      .then(() => {
        refetchOrders();
        message.success(t('common:update-success-message'));
      })
      .finally(() => {
        setIsPrinted(false);
      });
  };

  useEffect(() => {
    const handleAfterPrint = () => {
      setIsPrinted(true);
    };

    window.addEventListener('afterprint', handleAfterPrint);

    if (isReady) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }

    return () => {
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, [isReady, setIsPrinted]);

  return { isPrinted, handleOnCancelIsPrintedStatusModal, handleOnUpdateIsPrintedStatusModal };
};

const PrintBulkInvoice = () => {
  const { t } = useTranslation(['common', 'pagePrintBulkInvoice']);
  const orderIds = useGetOrderIds();
  const isCompact = useIsCompact();
  const { isLoading: isOrderLoading, data: orders } = useGetOrdersForBulkPrint(orderIds);

  const { selection: orderStatuses, isLoading: isOrderStatusesLoading } = useFetchConstant('orderStatuses');
  const { selection: paymentMethods, isLoading: isPaymentMethodsLoading } = useFetchConstant('paymentMethods');
  const { isLoading: isCountriesLoading, selection: countries } = useFetchConstant('countries', {
    labelKey: 'name',
    valueKey: 'iso2'
  });
  const { data: productTypesConst, isLoading: isProductTypesLoading } = useFetchConstant('productTypes');

  const isLoading = isOrderLoading || isOrderStatusesLoading || isPaymentMethodsLoading || isCountriesLoading || isProductTypesLoading;

  const { isPrinted, handleOnCancelIsPrintedStatusModal, handleOnUpdateIsPrintedStatusModal } = usePrint({
    t,
    isReady: !isLoading && orders,
    orderIds
  });

  return isLoading ? (
    <Skeleton active />
  ) : (
    <>
      <Helmet>
        <meta name="title" id="gtm-title" content="Order | Bulk Print Invoice" />
        <title>{t('pagePrintBulkInvoice:page-title', { isCompact: isCompact ? 1 : 0 })}</title>
      </Helmet>
      {orders.map(order => (
        <Invoice
          order={order}
          orderStatuses={orderStatuses}
          paymentMethods={paymentMethods}
          countries={countries}
          productTypesConst={productTypesConst}
          isCompact={isCompact}
        />
      ))}
      {isPrinted && (
        <Modal
          title={t('pagePrintBulkInvoice:update-is-printed-status-modal-title')}
          visible={isPrinted}
          onCancel={handleOnCancelIsPrintedStatusModal}
          onOk={handleOnUpdateIsPrintedStatusModal}
          okText={t('pagePrintBulkInvoice:update-is-printed-status-modal-ok-text')}
          cancelText={t('pagePrintBulkInvoice:update-is-printed-status-modal-cancel-text')}
        >
          {t('pagePrintBulkInvoice:update-is-printed-status-modal-content')}
        </Modal>
      )}
    </>
  );
};

export default PrintBulkInvoice;
