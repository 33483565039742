import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal, message } from 'antd';

import { patchStoreCustomerPoint } from 'apis/storecustomer';
import { constructReadableNum } from 'utils/general';

import FormInput from 'components/FormInput/FormInput';
import FormGroupSelectionInput from 'components/FormGroupSelectionInput/FormGroupSelectionInput';

const { useForm } = Form;

const newPointActionSelections = [
  { label: '+', value: 'add' },
  { label: '-', value: 'deduct' }
];

const PointUpdateModal = ({ customerId, point, onOk, onCancel, patchStoreCustomer }) => {
  const { t } = useTranslation(['common', 'pageCustomerDetails']);
  const [form] = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOnSave = e => {
    e.preventDefault();
    setIsSubmitting(true);

    form
      .validateFields()
      .then(values => {
        const payload = { point: values.action === 'add' ? values.point : -values.point, remarks: values.remarks };

        patchStoreCustomerPoint(customerId, payload)
          .then(() => {
            onOk();
            message.success(t('common:update-success-message'));
          })
          .catch(ex => {
            message.error(ex.message);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        setIsSubmitting(false);
      });
  };

  return (
    <Modal
      visible
      width="90%"
      style={{ maxWidth: '560px' }}
      title={t('pageCustomerDetails:modal-customer-point-update-modal-title')}
      okText={t('common:form-submit-update-button-text')}
      cancelText={t('common:modal-cancel-text')}
      onOk={handleOnSave}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      cancelButtonProps={{ loading: isSubmitting }}
      closable={!isSubmitting}
      maskClosable={!isSubmitting}
    >
      <p style={{ marginBottom: '24px' }}>
        {t('pageCustomerDetails:modal-customer-point-update-modal-label-current-point')}: <b>{constructReadableNum(point || 0)}</b>
      </p>
      <Form form={form} initialValues={{ action: newPointActionSelections[0].value }} style={{ width: '100%' }}>
        <FormGroupSelectionInput
          label={t('pageCustomerDetails:modal-customer-point-update-modal-label-new-points')}
          inputName="point"
          selectionName="action"
          inputType="number"
          selections={newPointActionSelections}
          requiredErrorMessage={t('pageCustomerDetails:modal-customer-point-update-modal-label-required-message-new-points')}
        />
        <FormInput
          label={t('pageCustomerDetails:modal-customer-point-update-modal-label-remarks')}
          name="remarks"
          type="textArea"
          rows={6}
          requiredErrorMessage={t('pageCustomerDetails:modal-customer-point-update-modal-label-required-message-remarks')}
        />
      </Form>
    </Modal>
  );
};

export default PointUpdateModal;
