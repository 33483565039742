import styled from '@emotion/styled';
import { Tabs } from 'antd';

export const TabsContainer = styled(Tabs)`
  width: 100%;
`;

export const StatusIndicatorContainer = styled.span`
  margin-left: ${props => props.theme.spacing.regular};
`;
