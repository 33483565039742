import React, { useEffect, useMemo, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Modal, Radio, Row, Col, Space, Form, message } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { getOrdersCSVData, getOrderProductsCSVData } from 'apis/order';

import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import CsvDownload from 'components/CsvDownload/CsvDownload';
import FormPasswordInput from 'components/FormPasswordInput/FormPasswordInput';
import OrderTagSelection from 'components/OrderTagSelection/OrderTagSelection';
import { withAppContext } from 'contexts/AppContext/AppContext';

import {
  DATE_FILTER_TYPE,
  REPORT_TYPES,
  REPORT_ORDER_FIELDS_TYPE,
  ORDER_FULL_CSV_HEADERS,
  ORDER_PACKAGING_CSV_HEADERS,
  ORDER_PRODUCTS_CSV_HEADERS,
  PASSCODE_REGEX
} from 'utils/constants';
import { guard } from 'utils/general';
import { formatToDateString, formatToDateTimeWithPMAM, getCurrentMonth, getTodayMoment, formatToMomentObject, getDayDiff } from 'utils/date';

import { FieldLabel, NoteLabel } from './ExportOrdersCSVModal.styles';

const { useForm } = Form;

const RANGE_DAY_LIMIT = 124;

const sendEventDataDownloadCsv = (params = {}) => {
  let actionData = '';
  const objectData = Object.entries(params);
  objectData.forEach((value, index) => {
    if (value[1] !== null) {
      actionData = `${actionData}${index === 0 ? '' : '@'}${value[0]}:${value[1]}`;
    }
  });
  window.dataLayer.push({ event: 'download_csv', ...params, action: actionData });
};

// TODO: Kong to revisit both order & product sold component, see if can combine if needed
// TODO: Cross check with Overview -> ProductScrollSction -> ModalDownloadCSV to see if we can combine
const ExportOrdersCSVModal = ({ onCancel, store, checkCanUserAccess, storeOperatorRoles }) => {
  const [form] = useForm();
  const { t } = useTranslation(['common', 'commonConstants', 'pageOrder']);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [dateFilterType, setDateFilterType] = useState(DATE_FILTER_TYPE.ORDER_CREATED_AT.value);
  const [reportType, setReportType] = useState(REPORT_TYPES.ORDER.value);
  const [reportOrderFieldType, setReportOrderFieldType] = useState(
    checkCanUserAccess([storeOperatorRoles.ADMIN.code, storeOperatorRoles.OPERATOR.code])
      ? REPORT_ORDER_FIELDS_TYPE.FULL.value
      : REPORT_ORDER_FIELDS_TYPE.PACKAGING.value
  );
  const [csvQuery, setCSVQuery] = useState({ startDate: formatToDateString(getCurrentMonth()), endDate: formatToDateString(getTodayMoment()) });
  const dateRangeValues = useMemo(() => [formatToMomentObject(csvQuery.startDate), formatToMomentObject(csvQuery.endDate)], [csvQuery]);
  const isOrderReportTypeSelected = useMemo(() => reportType === REPORT_TYPES.ORDER.value, [reportType]);

  const isRequirePasscode = guard(
    () =>
      reportType === REPORT_TYPES.ORDER.value &&
      reportOrderFieldType === REPORT_ORDER_FIELDS_TYPE.FULL.value &&
      store.securityConfig.hasSecurityPasscode,
    false
  );

  const csvHeaders = useMemo(() => {
    if (isOrderReportTypeSelected) {
      return reportOrderFieldType === REPORT_ORDER_FIELDS_TYPE.FULL.value ? ORDER_FULL_CSV_HEADERS : ORDER_PACKAGING_CSV_HEADERS;
    } else {
      return ORDER_PRODUCTS_CSV_HEADERS;
    }
  }, [isOrderReportTypeSelected, reportOrderFieldType]);

  const csvFileName = useMemo(() => {
    const orderDateFilterTypeLabel = guard(
      () => Object.values(DATE_FILTER_TYPE).find(dateFilterTypeObj => dateFilterTypeObj.value === dateFilterType).label
    );
    const orderReportTypeLabel = guard(
      () => Object.values(REPORT_ORDER_FIELDS_TYPE).find(reportOrderField => reportOrderField.value === reportOrderFieldType).label
    );

    return `${store.shortName}_${
      isOrderReportTypeSelected ? `Orders (${orderReportTypeLabel}) by ${orderDateFilterTypeLabel}` : `ProductsSold by ${orderDateFilterTypeLabel}`
    }_${moment(csvQuery.startDate).format('YYYYMMDD')}-${moment(csvQuery.endDate).format('YYYYMMDD')}.csv`;
  }, [isOrderReportTypeSelected, reportOrderFieldType, dateFilterType, store, csvQuery]);

  const getFomattedOrdersCSVData = async query => {
    return getOrdersCSVData(query).then(orders => {
      const totalObj = orders.reduce(
        (amountObj, order) => {
          const productTotalPrice = order.product.totalPrice;

          return {
            productTotalPrice: amountObj.productTotalPrice + productTotalPrice
          };
        },
        { productTotalPrice: 0 }
      );

      return [
        {
          orderNumber: 'Total Sales',
          product: {
            totalPrice: parseFloat(totalObj.productTotalPrice).toFixed(2)
          }
        },
        ...orders.map(order => {
          const product = order.product;

          return {
            ...order,
            purchasedDate: formatToDateTimeWithPMAM(order.purchasedDate),
            checkoutDate: formatToDateTimeWithPMAM(order.checkoutDate),
            product: {
              ...product,
              purchasedDate: formatToDateTimeWithPMAM(product.purchasedDate),
              liveStreamers: product.liveStreamers && product.liveStreamers.join(', ')
            }
          };
        })
      ];
    });
  };

  const handleOnChangeDateFilterType = e => {
    setDateFilterType(e.target.value);
  };

  const handleOnChangeReportType = e => {
    setReportType(e.target.value);
  };

  const handleOnChangeReportOrderFieldType = e => {
    setReportOrderFieldType(e.target.value);
  };

  useEffect(() => {
    if (store.isDefaultFilterProductPurchaseDate) {
      setDateFilterType(DATE_FILTER_TYPE.PRODUCT_PURCHASE_DATE.value);
    } else {
      setDateFilterType(DATE_FILTER_TYPE.ORDER_CREATED_AT.value);
    }
  }, [store]);

  const csvAsyncExportMethod = async () => {
    try {
      const { tags } = form.getFieldsValue();
      let finalQuery = {
        ...csvQuery,
        fieldType: isOrderReportTypeSelected ? reportOrderFieldType : undefined,
        dateFilterType,
        tags: tags ? tags.join(',') : ''
      };

      if (isRequirePasscode) {
        const values = await form.validateFields(['securityPasscode']);
        finalQuery.securityPasscode = values.securityPasscode;
      }
      const csvData = isOrderReportTypeSelected ? getFomattedOrdersCSVData(finalQuery) : getOrderProductsCSVData(finalQuery);
      const differentInDays = getDayDiff(csvQuery.startDate, csvQuery.endDate, true);
      sendEventDataDownloadCsv({
        page: 'order',
        category: 'order',
        report_order_field: reportOrderFieldType,
        report_type: reportType,
        dateFilterType: dateFilterType,
        days: `${differentInDays}`,
        payment_type: null,
        payment_status: null
      });
      return csvData;
    } catch (ex) {
      message.error(t('pageOrder:csv-download-passcode-form-validation-failed-message'));
    }
  };

  return (
    <Modal
      visible
      width="60%"
      maskClosable={false}
      title={t('pageOrder:csv-download-title')}
      onCancel={() => {
        onCancel();
      }}
      footer={
        <Space>
          <CsvDownload
            asyncExportMethod={csvAsyncExportMethod}
            onDataLoading={() => setIsLoadingData(true)}
            onDataLoaded={() => setIsLoadingData(false)}
            headers={csvHeaders}
            filename={csvFileName}
          >
            <Button loading={isLoadingData} type="primary" icon={<DownloadOutlined />}>
              {t('pageOrder:csv-download-button-text')}
            </Button>
          </CsvDownload>
        </Space>
      }
    >
      <Form form={form}>
        <Row>
          <Col span={24} style={{ marginBottom: '32px' }}>
            <FieldLabel margin="4px 0">{t('pageOrder:csv-download-date-text')}</FieldLabel>
            <NoteLabel>{t('pageOrder:csv-download-note-text', { dayLimit: RANGE_DAY_LIMIT })}</NoteLabel>
            <DateRangePicker
              value={dateRangeValues}
              onChange={dates => dates && setCSVQuery({ startDate: formatToDateString(dates[0]), endDate: formatToDateString(dates[1]) })}
              allowClear={false}
              extraDisabledDate={current => current > moment().endOf('day') || current.diff(csvQuery.startDate, 'days') > RANGE_DAY_LIMIT}
            />
          </Col>
          <Col span={24} style={{ marginBottom: '32px' }}>
            <FieldLabel margin="0 0 16px 0">{t('pageOrder:csv-download-date-filter-type-text')}</FieldLabel>
            <Radio.Group
              options={Object.values(DATE_FILTER_TYPE).map(dateFilterType => {
                return { ...dateFilterType, label: t(`commonConstants:${dateFilterType.transKey}`) };
              })}
              onChange={handleOnChangeDateFilterType}
              value={dateFilterType}
              optionType="button"
              buttonStyle="solid"
            />
          </Col>
          <Col span={24} style={{ marginBottom: '32px' }}>
            <FieldLabel margin="0 0 16px 0">{t('pageOrder:csv-download-report-type-text')}</FieldLabel>
            <Radio.Group
              options={Object.values(REPORT_TYPES).map(reportType => {
                return { ...reportType, label: t(`commonConstants:${reportType.transKey}`) };
              })}
              onChange={handleOnChangeReportType}
              value={reportType}
              optionType="button"
              buttonStyle="solid"
            />
          </Col>
          {reportType === REPORT_TYPES.ORDER.value && (
            <Col span={24} style={{ marginBottom: '32px' }}>
              <FieldLabel margin="0 0 16px 0">{t('pageOrder:csv-download-report-order-fields-type-text')}</FieldLabel>
              <Radio.Group
                options={Object.values(REPORT_ORDER_FIELDS_TYPE)
                  .map(reportOrderFieldsType => {
                    if (
                      reportOrderFieldsType.value === 'full' &&
                      !checkCanUserAccess([storeOperatorRoles.ADMIN.code, storeOperatorRoles.OPERATOR.code])
                    )
                      return false;
                    return { ...reportOrderFieldsType, label: t(`commonConstants:${reportOrderFieldsType.transKey}`) };
                  })
                  .filter(reportOrderFieldsType => !!reportOrderFieldsType)}
                onChange={handleOnChangeReportOrderFieldType}
                value={reportOrderFieldType}
                optionType="button"
                buttonStyle="solid"
              />
            </Col>
          )}
          <Col span={24} style={{ marginBottom: '32px' }}>
            <OrderTagSelection name="tags" />
          </Col>
          {isRequirePasscode && (
            <Col span={24}>
              {/* Using FormPasswordInput for consitency & form here for styling*/}
              <FormPasswordInput
                label={t('pageOrder:form-input-label-security-passcode')}
                name="securityPasscode"
                placeholder={t('pageOrder:form-input-placeholder-security-passcode')}
                requiredErrorMessage={t('pageOrder:form-input-required-message-security-passcode')}
                customPattern={PASSCODE_REGEX}
                customPatternErrorMessage={t('pageOrder:form-input-invalid-pattern-message-security-passcode')}
              />
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export default withAppContext(ExportOrdersCSVModal);
