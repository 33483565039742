import styled from '@emotion/styled';
import { Row } from 'antd';

export const TitleContainer = styled(Row)`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const StyledExpiryDateText = styled.div`
  color: ${props => props.theme.color.grey};
  font-size: 12px;
  font-style: italic;
`;
