import propTypes from 'prop-types';
import { Image } from 'antd';

import { encodeImageUrl } from 'utils/general';

const ImagePreview = ({ className, thumbnailImageUrl, previewImageUrl, height, width, ...props }) => {
  const thumbnailImageUrlToUse = thumbnailImageUrl || previewImageUrl;

  return (
    <Image
      className={className}
      src={encodeImageUrl(thumbnailImageUrlToUse)}
      preview={{ src: encodeImageUrl(previewImageUrl) }}
      height={height}
      width={width}
      {...props}
    />
  );
};

ImagePreview.propTypes = {
  className: propTypes.string,

  thumbnailImageUrl: propTypes.string,
  previewImageUrl: propTypes.string.isRequired,
  imageUrls: propTypes.array,

  shouldIncludePreviewImage: propTypes.bool,

  height: propTypes.oneOfType([propTypes.number, propTypes.string]),
  width: propTypes.oneOfType([propTypes.number, propTypes.string])
};

export default ImagePreview;
