import { InfoCircleOutlined, WhatsAppOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Avatar, Button, Row } from 'antd';
import MessengerIcon from 'components/Icon/MessengerIcon';

export const TitleContainer = styled(Row)`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const DownloadCSVButton = styled(Button)`
  background-color: ${props => props.theme.color.secondary};
  border-color: ${props => props.theme.color.secondary};
  color: ${props => props.theme.color.white};

  &:hover {
    background-color: ${props => props.theme.color.secondary};
    border-color: ${props => props.theme.color.secondary};
    color: ${props => props.theme.color.white};
  }
`;

export const OrderProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RemarksClickableIcon = styled(InfoCircleOutlined)`
  color: ${props => props.theme.color.primary};
  cursor: pointer;
  margin-right: 4px;
`;

export const WAClickableIcon = styled(WhatsAppOutlined)`
  color: #3dc14f;
  cursor: pointer;
  margin-right: 4px;
`;

export const FBClickableIcon = styled(MessengerIcon)`
  color: #0b88ef;
  cursor: pointer;
  margin-right: 4px;
  margin-bottom: 4px;
`;

export const UliveAccountIcon = styled(Avatar)`
  cursor: pointer;
  margin-bottom: 4px;
  border-radius: 8px;
`;

export const BalanceAmountText = styled.span`
  color: ${props => {
    if (props.balanceAmount === 0) {
      return props.theme.color.green;
    } else if (props.balanceAmount > 0) {
      return props.theme.color.red;
    } else {
      return props.theme.color.warn;
    }
  }};
`;

export const StyledTextLink = styled.span`
  color: ${props => props.theme.color.lightTertiary};
  text-decoration: none;
  cursor: pointer;
  padding: 2px;
`;

export const StyledLastUpdatedText = styled.span`
  color: ${props => props.theme.color.grey};
  font-size: 12px;
  font-style: italic;
`;

export const NameLabel = styled.span`
  cursor: pointer;
  color: ${props => (props.isUserBlacklisted ? props.theme.color.grey : props.theme.color.lightTertiary)};
`;

export const StyledShipmentDetail = styled.span`
  color: ${props => props.theme.color.grey};
  font-size: 12px;
  font-style: italic;
`;
