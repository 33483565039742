import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Avatar, Checkbox, Modal, Row, Space, Tag, Tooltip } from 'antd';
import { InfoCircleOutlined, InfoCircleFilled } from '@ant-design/icons';

import Input from 'components/Input/Input';
import FormLabel from 'components/FormLabel/FormLabel';
import Logo from 'images/u-logo.png';

import {
  SampleNotifContainer,
  SampleNotifInfoContainer,
  SampleNotifStoreName,
  SampleNotifTimeText,
  SampleNotifMessageText
} from './NewNotifiModal.styles';

const NewNotifiModal = ({ isLoading, storeName, isStoreHasMs = false, onOk, onCancel }) => {
  const { t } = useTranslation(['common', 'pageNotification']);
  const [notifMessage, setNotifMessage] = useState('');
  const [isIncludeMsUrl, setIsIncludeMsUrl] = useState(false);

  return (
    <Modal
      visible
      width="90%"
      style={{ maxWidth: '768px' }}
      okText={t('pageNotification:push-new-notif-modal-push-now-text')}
      cancelText={t('common:modal-cancel-text')}
      onOk={() => {
        onOk({ body: notifMessage, isIncludeMsUrl });
      }}
      onCancel={onCancel}
      confirmLoading={isLoading}
      cancelButtonProps={{ loading: isLoading }}
      closable={!isLoading}
      maskClosable={!isLoading}
    >
      <FormLabel>{t('pageNotification:push-new-notif-modal-input-label')}</FormLabel>
      <Input
        type="textarea"
        value={notifMessage}
        placeholder={t('pageNotification:push-new-notif-modal-input-placeholder')}
        onChange={setNotifMessage}
        showCount={true}
        maxLength={200}
        rows={6}
      />

      {isStoreHasMs && (
        <Checkbox
          checked={isIncludeMsUrl}
          onChange={e => {
            setIsIncludeMsUrl(e.target.checked);
          }}
        >
          {t('pageNotification:push-new-notif-modal-include-ms-url-checkbox-label')}{' '}
          <Tooltip title={t('pageNotification:push-new-notif-modal-include-ms-url-checkbox-tooltip')}>
            <InfoCircleFilled />
          </Tooltip>
        </Checkbox>
      )}

      <Alert
        type="warning"
        style={{ marginTop: 20 }}
        message={
          <Space direction="vertical" style={{ width: '100%' }}>
            <Row align="middle">
              <Tag color="#ffba00">
                <b>
                  <InfoCircleOutlined />
                </b>
              </Tag>
              <span>
                <b>{t('pageNotification:push-new-notif-modal-note')}:</b>
              </span>
            </Row>
            <ul style={{ marginBottom: 0 }}>
              <li>{t('pageNotification:push-new-notif-modal-note-desc-1')}</li>
              <li>{t('pageNotification:push-new-notif-modal-note-desc-2')}</li>
              <li>{t('pageNotification:push-new-notif-modal-note-desc-3')}</li>
            </ul>
            <SampleNotifContainer>
              <Avatar src={Logo} shape="square" size={44} style={{ borderRadius: '8px' }} />
              <SampleNotifInfoContainer>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <SampleNotifStoreName>{storeName}</SampleNotifStoreName>
                  <SampleNotifTimeText>{t('pageNotification:push-new-notif-modal-sample-time')}</SampleNotifTimeText>
                </div>
                <SampleNotifMessageText>
                  {!notifMessage ? t('pageNotification:push-new-notif-modal-sample-message') : notifMessage}
                </SampleNotifMessageText>
              </SampleNotifInfoContainer>
            </SampleNotifContainer>
          </Space>
        }
      />
    </Modal>
  );
};

export default NewNotifiModal;
