import { useCallback, useMemo } from 'react';
import { Result } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import { useLocationQuery } from 'hooks/router.js';

import { useRefetchCurrentUserStore } from 'apis/store';

import { getSubscriptionPlanRoute } from 'utils/routes.js';
import { checkIsStringBoolTrue } from 'utils/general.js';

import { useTimer } from 'hooks/utils.js';

import { AlertText, ContainerCard, SubTitleText } from './SubscriptionPlanPayment.styles.js';

/* ================================================== Main Component ================================================== */
const SubscriptionPlanPayment = () => {
  const { t } = useTranslation(['pageCheckoutPayment']);
  const query = useLocationQuery();
  const history = useHistory();

  const refetchStore = useRefetchCurrentUserStore();

  const isPaid = useMemo(() => checkIsStringBoolTrue(query['billplz[paid]']), [query]);

  const checkoutLink = getSubscriptionPlanRoute().path;

  const handleOnTimerEnd = useCallback(() => {
    history.push(checkoutLink);
    refetchStore();
  }, [history, checkoutLink, refetchStore]);

  const timeLeft = useTimer(5, true, handleOnTimerEnd);

  return (
    <ContainerCard>
      <Helmet>
        <meta name="title" id="gtm-title" content={isPaid ? 'Payment Success' : 'Payment Failure'} />
        <title>{isPaid ? t('pageCheckoutPayment:page-success-title') : t('pageCheckoutPayment:page-failure-title')}</title>
      </Helmet>
      <Result
        status={isPaid ? 'success' : 'error'}
        title={isPaid ? t('pageCheckoutPayment:payment-success-title') : t('pageCheckoutPayment:payment-failure-title')}
        subTitle={
          <>
            <SubTitleText>{t('pageCheckoutPayment:message-redirect-store', { timeLeft })}</SubTitleText>
            <AlertText>{t('pageCheckoutPayment:message-redirect-warning')}</AlertText>
          </>
        }
        extra={[
          <Trans i18nKey="pageCheckoutPayment:message-redirect-fallback" key="redirect-manual">
            <a href={checkoutLink} onClick={() => refetchStore()}>
              here
            </a>
          </Trans>
        ]}
      />
    </ContainerCard>
  );
};

export default SubscriptionPlanPayment;
