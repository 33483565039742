import { constructReadableNum, constructDisplayPrice } from 'utils/general';

import { CardContainer, CardTitleText, CardContentText, CardContentPendingText } from './StatisticCard.styles';

const StatisticCard = ({ title, value, pendingTitle, pendingValue, isCurrency, storeCurrency }) => {
  return (
    <CardContainer>
      <CardTitleText>{title}</CardTitleText>
      <CardContentText>
        {value && (isCurrency ? `${storeCurrency.symbol} ${constructDisplayPrice(value, storeCurrency.iso)}` : constructReadableNum(value))}
      </CardContentText>
      <CardContentPendingText>
        {pendingTitle}:{' '}
        {pendingValue &&
          (isCurrency ? `${storeCurrency.symbol} ${constructDisplayPrice(pendingValue, storeCurrency.iso)}` : constructReadableNum(pendingValue))}
      </CardContentPendingText>
    </CardContainer>
  );
};

export default StatisticCard;
