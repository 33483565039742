import styled from '@emotion/styled';

export const RootContainer = styled.div`
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const DescriptionContainer = styled.div`
  margin-bottom: 16px;
`;

export const BannerImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 12px;
`;

export const FeedbackContainer = styled.div`
  font-size: 12px;
`;
