import styled from '@emotion/styled';
import IGComingSoonPlaceholder from './images/ig-coming-soon.png';

export const SocialMediaTitleContainer = styled.div`
  display: flex;
  align-items: center !important;
`;

export const SocialMediaTitleIcon = styled.span`
  font-size: 20px;
  color: ${props => props.theme.color[props.themeColor]};
  margin-right: ${props => props.theme.spacing.xxs};
`;

export const SocialMediaTitle = styled.span`
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.color[props.themeColor]};
`;

export const ConnectionDetailsContainer = styled.div`
  margin: ${props => props.theme.spacing.xs} 0 24px 0;
`;

export const ConnectionDetailsComingSoonContainer = styled.div`
  background-image: url(${IGComingSoonPlaceholder});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  display: flex;
  height: 140px;
  justify-content: center;
  overflow: hidden;
  margin: ${props => props.theme.spacing.xs} 0 24px 0;
  width: 100%;
`;
