import api from './apiHelper';
import { useCustomQuery, useCustomPaginatedQuery } from 'hooks/reactQuery';

// =================================================== GET
export const useGetStoreCustomerSuggestions = (keyword, customerId, { shouldFetch = true } = {}) => {
  const getStoreCustomerSuggestions = async () => {
    return api.get(`/storecustomer/suggestions`, {
      params: customerId ? { customerId } : { searchKeyword: keyword }
    });
  };

  return useCustomQuery('storeCustomerSuggestions', [customerId, keyword], getStoreCustomerSuggestions, {
    shouldDefaultEmptyObject: false,
    enabled: !!shouldFetch
  });
};

export const useGetStoreCustomers = query => {
  const getPaginatedStoreCustomers = async (key, query) => {
    return api.get(`/storecustomer/paginated`, {
      params: { ...query }
    });
  };

  return useCustomPaginatedQuery('storeCustomers', getPaginatedStoreCustomers, query);
};

export const useGetStoreCustomer = (customerId, { onError, postProcessFunc } = {}) => {
  const getStoreCustomer = async () => {
    return api.get(`/storecustomer/${customerId}`);
  };

  return useCustomQuery('storeCustomer', [customerId], getStoreCustomer, {
    onError,
    shouldDefaultEmptyObject: false,
    postProcessFunc,
    enabled: !!customerId
  });
};

export const useGetStoreCustomerPoint = (customerId, { onError, postProcessFunc } = {}) => {
  const getStoreCustomerPoint = async () => {
    return api.get(`/storecustomer/${customerId}/point`);
  };

  return useCustomQuery('storeCustomerPoint', [customerId], getStoreCustomerPoint, {
    onError,
    shouldDefaultEmptyObject: false,
    postProcessFunc,
    enabled: !!customerId
  });
};

export const useGetStoreCustomerOrderSummary = (customerId, { onError, postProcessFunc } = {}) => {
  const getStoreCustomerOrderSummary = async () => {
    return api.get(`/storecustomer/${customerId}/orders/summary`);
  };

  return useCustomQuery('storeCustomerOrderSummary', [customerId], getStoreCustomerOrderSummary, {
    onError,
    shouldDefaultEmptyObject: false,
    postProcessFunc,
    enabled: !!customerId
  });
};

export const useGetStoreCustomerOrders = (customerId, query) => {
  const getStoreCustomerOrders = async (key, query) => {
    return api.get(`/storecustomer/${customerId}/orders/paginated`, {
      params: { ...query }
    });
  };

  return useCustomPaginatedQuery('storeCustomerOrders', getStoreCustomerOrders, query, {
    enabled: !!customerId
  });
};

export const useGetStoreCustomerPointLogs = (customerId, query, { onError } = {}) => {
  const getStoreCustomerPointLogs = async (key, query) => {
    return api.get(`/storecustomer/${customerId}/pointlogs/paginated`, {
      params: { ...query }
    });
  };

  return useCustomPaginatedQuery('storeCustomerPointLogs', getStoreCustomerPointLogs, query, {
    onError
  });
};

export const useGetStoreCustomerTierSuggestions = (keyword, { shouldFetch = true } = {}) => {
  const getStoreCustomerTierSuggestions = async () => {
    return api.get(`/storecustomer/tier/suggestions`, {
      params: { searchKeyword: keyword }
    });
  };

  return useCustomQuery('storeCustomerTierSuggestions', [keyword], getStoreCustomerTierSuggestions, {
    shouldDefaultEmptyObject: false,
    enabled: !!shouldFetch
  });
};

// =================================================== POST
export const postSendFBMessage = (customerId, message) => {
  return api.post(`/storecustomer/${customerId}/send-fb-message`, { message });
};

// =================================================== PATCH
export const patchStoreCustomer = (customerId, payload) => {
  return api.patch(`/storecustomer/${customerId}`, payload);
};

export const patchStoreCustomerPoint = (customerId, payload) => {
  return api.patch(`/storecustomer/${customerId}/point`, payload);
};

export const patchStoreCustomerMerge = (customerId, subCustomerId) => {
  return api.patch(`/storecustomer/${customerId}/merge`, { subCustomerId });
};
