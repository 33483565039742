import { useState } from 'react';
import { CopyrightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd';

import { withAppContext } from 'contexts/AppContext/AppContext';
import { getAccountProfileRoute } from 'utils/routes';
import { ROOT_LAYOUT_SCROLL_ID } from 'utils/scroll';
import { checkHasValue } from 'utils/general';

import Header from './components/Header/Header';
import NavMenu from './components/Menu/Menu';

import {
  AlertContainer,
  AlertMessage,
  AlertLink,
  ContentContainer,
  ContentLayout,
  ContentHeader,
  ContentBody,
  ContentFooter,
  LogoContainer,
  RootLayout,
  ContentRootLayout,
  SiderHeader,
  StyledSider
} from './StandardLayout.styles';

const profilePagePath = getAccountProfileRoute().path;

const FbTokenInvalidAlert = ({ messageText, linkText }) => {
  return (
    <AlertContainer>
      <AlertMessage>
        {messageText} <AlertLink to={profilePagePath}>{linkText}</AlertLink>.
      </AlertMessage>
    </AlertContainer>
  );
};

const StandardLayout = ({
  isLoadingAppContext,
  isLoggedIn,
  isFbTokenValid,
  user,
  children,
  isStoreConnectedToFb,
  checkCanUserAccess,
  storeOperatorRoles,
  store: {
    subscriptionConfig: { isSubscriptionStarter, isSubscriptionPaidStarter, isCustomerEnabled },
    notifConfig
  }
}) => {
  const { t } = useTranslation(['layoutStandard']);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const shouldDisplayTokenError = isLoggedIn && checkHasValue(isFbTokenValid) && !isFbTokenValid;

  return (
    <>
      {isLoadingAppContext ? (
        <Skeleton active />
      ) : (
        <RootLayout>
          {shouldDisplayTokenError && (
            <FbTokenInvalidAlert
              messageText={t('layoutStandard:alert-message-fb-token-issue-text')}
              linkText={t('layoutStandard:alert-message-fb-token-issue-link')}
            />
          )}
          <ContentRootLayout>
            <StyledSider
              breakpoint="lg"
              collapsedWidth="0"
              collapsible
              collapsed={isCollapsed}
              onCollapse={() => setIsCollapsed(!isCollapsed)}
              zeroWidthTriggerStyle={{
                top: '12px',
                color: '#2C278A',
                backgroundColor: 'transparent',
                fontSize: '24px',
                height: '32px',
                right: '-52px'
              }}
            >
              <SiderHeader>
                <LogoContainer />
              </SiderHeader>
              {!isStoreConnectedToFb && (isSubscriptionStarter || isSubscriptionPaidStarter) ? undefined : (
                <NavMenu
                  userRole={user.role}
                  checkCanUserAccess={checkCanUserAccess}
                  storeOperatorRoles={storeOperatorRoles}
                  isEnabledAppNotif={notifConfig && notifConfig.isEnabled}
                  isCustomerEnabled={isCustomerEnabled}
                />
              )}
            </StyledSider>
            <ContentLayout>
              <ContentHeader>
                <Header />
              </ContentHeader>
              <ContentBody id={ROOT_LAYOUT_SCROLL_ID}>
                <ContentContainer>{children}</ContentContainer>
              </ContentBody>
              <ContentFooter>
                {t('layoutStandard:footer-text')} <CopyrightOutlined /> {new Date().getFullYear()}
              </ContentFooter>
            </ContentLayout>
          </ContentRootLayout>
        </RootLayout>
      )}
    </>
  );
};

export default withAppContext(StandardLayout);
