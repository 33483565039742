import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';

import { checkIsMoment, formatToDateString } from 'utils/date';
import { checkHasValue } from 'utils/general';

import PriceDisplay from 'components/PriceDisplay/PriceDisplay';
import CurrencyDisplay from 'components/CurrencyDisplay/CurrencyDisplay';

import {
  StyledFilterCheckboxContainer,
  StyledFilterCheckboxGroup,
  StyledFilterCheckboxOptions,
  StyledFilterSearchContainer,
  StyledFilterSearchInput,
  StyledFilterRadioButton,
  StyledFilterRadioContainer,
  StyledFilterRadioGroup,
  StyledTextLink,
  StyledLink
} from './table.styles';

/* =========================================== Exported Functions =========================================== */
export const constructColumn = (
  title,
  dataIndex,
  {
    key,
    width,
    hasSorter,
    hasAggregationSorter,
    sorter: sorterInfo,
    linkFunc,
    emptyDisplayValue = '-',
    isDate,
    isDocument,
    isNumber,
    isPrice,
    onClick
  } = {}
) => ({
  title: isPrice ? <CurrencyDisplay prefix={title} /> : title,
  dataIndex,
  key: key || dataIndex,
  width,
  ...(hasAggregationSorter && { sorter: () => false }), // Note: To force sorting to follow as API response.
  ...(!hasAggregationSorter && hasSorter && constructColumnSorter(dataIndex, sorterInfo, { isNumber })),
  render: (data, record) => {
    switch (true) {
      case !checkHasValue(data):
        return <span>{emptyDisplayValue}</span>;

      case !!onClick:
        return <StyledTextLink onClick={() => onClick(record)}>{data}</StyledTextLink>;

      case !!linkFunc && !!linkFunc(record):
        return <StyledLink to={linkFunc(record)}>{data}</StyledLink>;

      case isDate && checkIsMoment(data):
        return <span>{formatToDateString(data)}</span>;

      case isDocument:
        return (
          <a href={data.url} target="_blank" rel="noopener noreferrer">
            <FileOutlined />
          </a>
        );
      case isPrice:
        return <PriceDisplay amount={data} />;

      default:
        return <span>{data || '-'}</span>;
    }
  }
});

export const constructColumnFilterSearch = (dataIndex, placeholder, { hasAggregationFilter } = {}) => ({
  ...constructFilter(dataIndex, 'search', { dataIndex, placeholder }, { hasAggregationFilter }),
  onFilterDropdownVisibleChange: visible =>
    visible && setTimeout(() => document.getElementById(dataIndex) && document.getElementById(dataIndex).focus())
});

export const constructColumnFilterRadio = (dataIndex, filterSelections, { hasAggregationFilter } = {}) =>
  constructFilter(dataIndex, 'radio', { filterSelections }, { hasAggregationFilter });

export const constructColumnFilterCheckbox = (dataIndex, filterOptions, { key, hasAggregationFilter } = {}) => ({
  ...constructFilter(dataIndex, 'checkbox', { filterOptions }, { key, hasAggregationFilter })
});

export const getCurrentIndexForPaginatedList = (index = 0, currentPage = 1, pageSize = 10) => {
  return (currentPage - 1) * pageSize + index;
};

export const handleOnAggregationTableChange = async ({ pagination, filters, sorter }, setNewAggregationQuery, { remainArrayFields = [] } = {}) => {
  const filter = Object.entries(filters).reduce((filterQuery, [key, values]) => {
    const value = Array.isArray(values) && !remainArrayFields.includes(key) ? values[0] : values;

    return { ...filterQuery, ...(checkHasValue(values) && { [key]: value }) };
  }, {});

  const { current, pageSize } = pagination;
  const { columnKey, order } = sorter;
  const query = {
    ...(Object.keys(filter).length > 0 && { filter }),
    ...(sorter && columnKey && { sorter: { [columnKey]: order === 'descend' ? -1 : 1 } }),
    ...(pagination && current && { limit: pageSize, currentPage: current })
  };
  setNewAggregationQuery(query);
};

export const formatPaginationShowTotalDisplay = (total, range) => `${range[0]}-${range[1]} of ${total}`;

/*---------------------------------------Local Functions-------------------------------------*/
const constructColumnSorter = (dataIndex, sorterInfo, { isNumber = false }) => ({
  sorter: (nextRecord, currentRecord) => {
    if (isNumber) {
      return nextRecord[dataIndex] - currentRecord[dataIndex];
    } else {
      const nextValue = String(nextRecord[dataIndex]).toUpperCase();
      const currentValue = String(currentRecord[dataIndex]).toUpperCase();
      return nextValue.localeCompare(currentValue);
    }
  }
});

const constructFilter = (dataIndex, type, filterComponentProps, { key, filterInfo, hasAggregationFilter } = {}) => {
  let FilterComponent, FilterIconComponent, onFilterCompareFunc;

  if (type === 'search') {
    FilterComponent = FilterSearch;
    FilterIconComponent = SearchOutlined;
    onFilterCompareFunc = (string1, string2) => string1.includes(string2);
  } else if (type === 'radio') {
    FilterComponent = FilterRadio;
    FilterIconComponent = FilterOutlined;
    onFilterCompareFunc = (string1, string2) => string1 === string2;
  } else if (type === 'checkbox') {
    FilterComponent = FilterCheckbox;
    FilterIconComponent = FilterOutlined;
    onFilterCompareFunc = (string1, string2) => string1 === string2;
  }

  const onFilterFunc = (value, record) => {
    const formattedRecordData = String(record[dataIndex]).toLowerCase();
    const formattedValue = String(value).toLowerCase();

    return hasAggregationFilter ? true : onFilterCompareFunc(formattedRecordData, formattedValue);
  };

  return {
    key: key || dataIndex,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <FilterComponent
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          onConfirm={() => confirm()}
          clearFilters={clearFilters}
          {...filterComponentProps}
        />
      );
    },
    filterIcon: filtered => <FilterIconComponent style={{ color: filtered ? '#FFC015' : '#AAA' }} />,
    onFilter: onFilterFunc,
    ...(!!filterInfo && { filteredValue: filterInfo[dataIndex] || [] })
  };
};

const FilterSearch = ({ dataIndex, placeholder, setSelectedKeys, selectedKeys, onConfirm, clearFilters }) => {
  return (
    <StyledFilterSearchContainer type="flex">
      <Col>
        <StyledFilterSearchInput
          id={dataIndex}
          value={selectedKeys[0]}
          placeholder={placeholder}
          onChange={value => setSelectedKeys(value ? [value] : [])}
          onPressEnter={onConfirm}
        />
      </Col>
      <Col>
        <FilterButtons onConfirm={onConfirm} onClearFilter={clearFilters} />
      </Col>
    </StyledFilterSearchContainer>
  );
};

const FilterRadio = ({ filterSelections, selectedKeys, setSelectedKeys, onConfirm, clearFilters }) => (
  <StyledFilterRadioContainer>
    <StyledFilterRadioGroup onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])} value={selectedKeys[0]}>
      {filterSelections.map(selection => (
        <Row key={selection.label}>
          <StyledFilterRadioButton value={selection.value}>{selection.label}</StyledFilterRadioButton>
        </Row>
      ))}
    </StyledFilterRadioGroup>
    <FilterButtons onConfirm={onConfirm} onClearFilter={clearFilters} />
  </StyledFilterRadioContainer>
);

const FilterCheckbox = ({ filterOptions, selectedKeys, setSelectedKeys, onConfirm, clearFilters }) => (
  <StyledFilterCheckboxContainer>
    <StyledFilterCheckboxGroup value={selectedKeys} onChange={values => setSelectedKeys(values)}>
      {filterOptions.map(option => (
        <Row key={option.label}>
          <StyledFilterCheckboxOptions value={option.value}>{option.label}</StyledFilterCheckboxOptions>
        </Row>
      ))}
    </StyledFilterCheckboxGroup>
    <FilterButtons onConfirm={onConfirm} onClearFilter={clearFilters} />
  </StyledFilterCheckboxContainer>
);

const FilterButtons = ({ style, onConfirm, onClearFilter }) => {
  const { t } = useTranslation(['common', 'utilsTable']);

  return (
    <Row style={style} gutter={4} type="flex" justify="start">
      <Col>
        <Button type="primary" onClick={onConfirm}>
          {t('utilsTable:filter-action-button-search-text')}
        </Button>
      </Col>
      <Col>
        <Button onClick={onClearFilter}>{t('utilsTable:filter-action-button-reset-text')}</Button>
      </Col>
    </Row>
  );
};
