import styled from '@emotion/styled';
import { Button, Card, Col, List, Menu, Row } from 'antd';

const { Item: MenuItem } = Menu;
const { Item: ListItem } = List;

export const HeaderRow = styled(Row)`
  height: 100%;
`;

export const AccountInfoMenuItem = styled(MenuItem)`
  &:hover {
    background-color: ${props => props.theme.color.white};
    cursor: default;
  }
`;

export const ProfileNameCol = styled.div`
  height: 100%;
  font-family: ${props => props.theme.fontFamily.title};
  font-size: 16px;
  margin-right: ${props => props.theme.spacing.xs};
  word-break: break-word;
  white-space: break-spaces;
  max-width: 200px;
`;

export const AvatarCol = styled(Col)`
  height: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export const ProfileEmailText = styled.span`
  color: ${props => props.theme.color.grey};
  font-size: 12px;
`;

export const StyledProfileStoreDetailsCard = styled(Card)`
  margin: ${props => props.theme.spacing.xs};
  border-width: 2px;
`;

export const ProfileStoreDetailsListItem = styled(ListItem)`
  padding: ${props => `${props.theme.spacing.xxs} ${props.theme.spacing.xs}`} !important;

  &:last-child {
    border-bottom: 1px solid ${props => props.theme.color.borderColor} !important;
  }
`;

export const StyledStoreDetailsTitleText = styled.span`
  font-size: 12px;
`;

export const StyledStoreDetailsDescText = styled.span`
  color: ${props => props.theme.color.grey};
  font-size: 12px;
`;

export const ProfileStoreDetailsTitle = styled.div`
  color: ${props => props.theme.color.tertiary};
  font-weight: ${props => props.theme.fontWeight.bolder};
`;

export const ProfileStoreDetailsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0 0 0;
`;

export const RedirectExtPlanPageBtn = styled(Button)`
  padding-bottom: 0px;
  font-size: 12px;
`;
