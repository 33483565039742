import styled from '@emotion/styled';
import { Col } from 'antd';
import Logo from 'images/ulive-logo-wo-bg.png';

export const LoginContainer = styled(Col)`
  background-color: ${props => props.theme.color.primary}00;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 80%;

  @media (min-width: 768px) {
    max-width: 600px;
    width: 58%;
  }
`;

export const LogoContainer = styled.div`
  background-image: url(${Logo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
  height: 160px;
  width: 240px;
`;

export const WelcomeText = styled.p`
  color: ${props => props.theme.color.white};
  font-size: 24px;
  font-weight: ${props => props.theme.fontWeight.light};
  margin: ${props => props.theme.spacing.regular} 0 ${props => props.theme.spacing.xl} 0;
  text-align: center;
`;

export const LoginFieldsContainer = styled.div`
  background-color: ${props => props.theme.color.white};
  border-radius: 8px;
  margin: 0 auto ${props => props.theme.spacing.md} auto;
  padding: ${props => props.theme.spacing.md};

  @media (min-width: 768px) {
    min-width: 376px;
  }
`;

export const FooterText = styled.p`
  text-align: center;
  color: ${props => props.theme.color.white};
`;
