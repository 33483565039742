import styled from '@emotion/styled';
import { Avatar, Checkbox, List, Radio } from 'antd';

export const PostWinnerToFacebookCheckbox = styled(Checkbox)`
  font-size: 16px;
  margin-bottom: 24px;
`;

export const StyledWinnerNameList = styled(List)`
  max-height: 240px;
  overflow-y: auto;
  border-color: ${props => props.theme.color.lightSecondary};
`;

export const StyledAvatarWinnerInitial = styled(Avatar)`
  color: ${props => props.theme.color.lightSecondary};
  background-color: ${props => props.theme.color.secondary};
`;

export const StyledWinnerNameText = styled.span`
  color: ${props => props.theme.color.secondary};
`;

export const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
  display: flex;
  margin-top: 24px;
`;

export const StyledRadioButton = styled(Radio.Button)`
  width: 100%;
  text-align: center;
`;
