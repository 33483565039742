import api from './apiHelper';
import { useCustomQuery, useRefetchQuery } from 'hooks/reactQuery';

export const useGetBlacklistedSocialMediaUserIds = source => {
  const getBlacklistedSocialMediaUserIds = async () => {
    return api.get(`/socialmediauser/blacklist-user-ids`, { params: { source } });
  };

  return useCustomQuery('blacklist-user-ids', [], getBlacklistedSocialMediaUserIds, {
    shouldDefaultEmptyObject: false,
    enabled: !!source
  });
};

export const useGetBlacklistedSocialMediaUsers = () => {
  const getBlacklistedSocialMediaUsers = async () => {
    return api.get(`/socialmediauser/blacklist`);
  };

  return useCustomQuery('blacklist', [], getBlacklistedSocialMediaUsers, {
    shouldDefaultEmptyObject: false
  });
};

export const useGetSocialMediaUserSuggestions = (keyword, { shouldFetch } = {}) => {
  const getSocialMediaUserSuggestions = async () => {
    return api.get(`/socialmediauser/suggestions`, {
      params: { searchKeyword: keyword }
    });
  };

  return useCustomQuery('storeCustomerSuggestions', [keyword], getSocialMediaUserSuggestions, {
    shouldDefaultEmptyObject: false,
    enabled: !!shouldFetch
  });
};

export const patchSocialMediaUserBlacklistStatus = (sourceUserId, source, isBlacklisted) => {
  return api.patch(`/socialmediauser/${sourceUserId}`, { isBlacklisted }, { params: { source } });
};

export const useRefetchBlacklistedSocialMediaUserIds = () => {
  const { refetch } = useRefetchQuery(['blacklist-user-ids']);

  return refetch;
};
