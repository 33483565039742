import { useMemo } from 'react';
import { Col, Form, Modal, Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import FormInput from 'components/FormInput/FormInput';
import { useFetchConstant } from 'hooks/constants';

import { OrderNumberText } from './CancelOrderModal.styles';

const { useForm } = Form;

const CancelOrderModal = ({ isLoading, isMultipleOrders, order, ordersCount, onCancel, onOk }) => {
  const { t } = useTranslation(['common', 'pageOrder']);
  const [form] = useForm();
  const { data: orderStatusesConst, isLoading: isOrderStatusesLoading } = useFetchConstant('orderStatuses');

  const canEdit = useMemo(() => (!isMultipleOrders ? order && order.status !== orderStatusesConst.CANCELLED.code : true), [
    order,
    orderStatusesConst,
    isMultipleOrders
  ]);

  const handleOnConfirmToCancel = () => {
    const payload = form.getFieldsValue();
    onOk(payload);
  };

  return (
    <Modal
      visible
      title={
        isMultipleOrders ? (
          <span>{t('pageOrder:modal-cancel-order-multiple-title', { itemCount: ordersCount })}</span>
        ) : canEdit ? (
          <>
            <span>{t('pageOrder:modal-cancel-order-title')}</span> <OrderNumberText>{order && order.orderNumber}</OrderNumberText> <span>?</span>
          </>
        ) : (
          <>
            <span>{t('pageOrder:modal-cancel-order-title-view-only')}</span> <OrderNumberText>{order && order.orderNumber}</OrderNumberText>
          </>
        )
      }
      okText={t('pageOrder:modal-cancel-order-ok-button-text')}
      cancelText={t('pageOrder:modal-cancel-order-cancel-button-text')}
      onCancel={onCancel}
      confirmLoading={isLoading}
      onOk={handleOnConfirmToCancel}
      footer={!canEdit ? null : undefined}
    >
      {(!isMultipleOrders && !order) || isOrderStatusesLoading ? (
        <Skeleton active />
      ) : (
        <Form form={form} initialValues={!isMultipleOrders && order} scrollToFirstError={true} style={{ width: '100%' }}>
          <Row gutter={16}>
            <Col span={24}>
              <FormInput
                label={canEdit ? t('pageOrder:modal-cancel-order-form-input-label-order-remarks') : undefined}
                name="remarks"
                placeholder={
                  isMultipleOrders
                    ? t('pageOrder:modal-cancel-order-form-input-placeholder-multiple-order-remarks')
                    : canEdit
                    ? t('pageOrder:modal-cancel-order-form-input-placeholder-order-remarks')
                    : t('pageOrder:modal-cancel-order-form-input-placeholder-order-no-remarks')
                }
                type="textArea"
                rows={8}
                disabled={!canEdit}
              />
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default CancelOrderModal;
