import styled from '@emotion/styled';
import { Row } from 'antd';

export const FormContentCommentContainer = styled(Row)`
  width: 100%;
  margin: 16px 0;
`;

export const FormActionButtonsContainer = styled(Row)`
  width: 100%;
  justify-content: flex-end;
`;
