import React, { useEffect, useState } from 'react';
import { Checkbox, Col, message, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { postNewStoreFbConnection, postRefetchStoreFbLivePosts } from 'apis/store';
import { checkHasValue, checkIsObjectEmpty, guard } from 'utils/general';
import { logError } from 'utils/logging';

import { withAppContext } from 'contexts/AppContext/AppContext';
import { withFbContext } from 'contexts/FbContext/FbContext';
import SocialMediaConnectionContent from 'components/SocialMediaConnectionContent/SocialMediaConnectionContent';
import SocialMediaLogin from 'components/SocialMediaLogin/SocialMediaLogin';
import {
  MainContainer,
  ErrorMessage,
  SuccessIcon,
  PlatformSelectionCard,
  StyledFacebookOutlined,
  StyledInstagramOutlined,
  StyledPagesSelection
} from './FBIGAccountConnection.styles';

const SUCCESS_CODES = { NO_MISSING_FB_LIVE_POSTS: 'S-000001', SYNCED_FB_LIVE_POSTS: 'S-000002' };

const refetchStoreFbLivePosts = () => {
  postRefetchStoreFbLivePosts()
    .then(resData => {
      if (resData.code === SUCCESS_CODES.SYNCED_FB_LIVE_POSTS) {
        message.success(resData.message);
      }
    })
    .catch(ex => {
      logError('Something went wrong when trying to refetch store FB live posts', ex);
      message.error(`Something went wrong when trying to refetch store FB live posts: ${ex.message}`, 5);
    });
};

const FBIGAccountConnection = ({
  isLoggedIn,
  isFbTokenValid,
  store,
  fbUser,
  getFbUserPages,
  refetchFbConnectionStatus,
  refetchStore,
  isDisabledEdit
}) => {
  const { t } = useTranslation(['common', 'compFBIGAccountConnection']);
  const [isFBIGModalOpen, setIsFBIGModalOpen] = useState(false);
  const [isLinking, setIsLinking] = useState(false);
  const [isConnectWithIG, setIsConnectWithIG] = useState(false);
  const [pagesFBIG, setPagesFBIG] = useState([]);
  const [pagesFBIGSelection, setPagesFBIGSelection] = useState([]);
  const [selectedFBIGPageId, setSelectedFBIGPageId] = useState();

  const shouldDisplayTokenError = isLoggedIn && checkHasValue(isFbTokenValid) && !isFbTokenValid;

  useEffect(() => {
    const filteredPages = isConnectWithIG ? pagesFBIG.filter(page => page.instagram && page.instagram.username) : pagesFBIG;
    const pagesSelection = filteredPages.map(filteredPage => ({
      label: `${filteredPage.name} (${filteredPage.id}) ${filteredPage.instagram ? ` (IG: ${filteredPage.instagram.username})` : ''}`,
      value: filteredPage.id,
      hasIG: !!filteredPage.instagram
    }));

    setPagesFBIGSelection(pagesSelection);
    setSelectedFBIGPageId(guard(() => pagesSelection[0].value)); // Default to first selection
  }, [isConnectWithIG, pagesFBIG]);

  const handleOnFBIGUserConnected = async () => {
    const pages = await getFbUserPages().then(fbUserPages => {
      return fbUserPages
        .filter(page => !!page.access_token)
        .map(page => {
          return {
            id: page.id,
            accessToken: page.access_token,
            name: page.name,
            instagram: page.instagram_business_account
          };
        });
    });

    setIsFBIGModalOpen(true);
    setPagesFBIG(pages);
    setSelectedFBIGPageId(guard(() => pages[0].id)); // Default to first selection
  };

  const handleOnFBIGPageModalConfirm = async () => {
    const selectedPage = pagesFBIG.find(page => {
      return page.id === selectedFBIGPageId;
    });

    const payload = {
      fbUserAuth: { id: fbUser.userID, accessToken: fbUser.accessToken },
      fbPageAuth: { id: selectedPage.id, name: selectedPage.name, accessToken: selectedPage.accessToken },
      ...(isConnectWithIG && { igPageInfo: { id: selectedPage.instagram.id, name: selectedPage.instagram.username } })
    };

    setIsLinking(true);
    await postNewStoreFbConnection(payload)
      .then(() => {
        message.success(t('compFBIGAccountConnection:modal-fb-ig-connect-successful-popup-message'));
        refetchStore();
        refetchFbConnectionStatus();
        refetchStoreFbLivePosts();
      })
      .catch(ex => {
        message.error(t('compFBIGAccountConnection:modal-fb-ig-connect-successful-link-error-message'));
        message.error(`Error message: ${ex.message}`);
      })
      .finally(() => {
        setIsFBIGModalOpen(false);
        setIsLinking(false);
      });
  };

  const handleOnFBIGPageModalCancel = async () => {
    setIsFBIGModalOpen(false);
    setIsConnectWithIG(false);
  };

  const handleOnFBIGPageSelect = newPageId => {
    setSelectedFBIGPageId(newPageId);
  };

  const handleOnIGOptionSelect = e => {
    e.preventDefault();
    const isChecked = e.target.checked;
    setIsConnectWithIG(isChecked);
  };

  return (
    <>
      <MainContainer bodyStyle={{ padding: '24px' }} isError={shouldDisplayTokenError}>
        <Row gutter={8}>
          <Col span={24} md={12}>
            <SocialMediaConnectionContent t={t} type={'fb'} storeSocialMedia={guard(() => store.socialMedia.fb)} />
          </Col>
          <Col span={24} md={12}>
            <SocialMediaConnectionContent t={t} type={'ig'} storeSocialMedia={guard(() => store.socialMedia.ig)} />
          </Col>
          <Col span={24}>
            <SocialMediaLogin onFbUserConnected={handleOnFBIGUserConnected} isDisabledEdit={isDisabledEdit} />
            {shouldDisplayTokenError && <ErrorMessage>{t('compFBIGAccountConnection:disconnected-alert-message')}</ErrorMessage>}
          </Col>
        </Row>
      </MainContainer>
      {isFBIGModalOpen && (
        <Modal
          visible={true}
          width="80%"
          style={{ maxWidth: '560px' }}
          title={
            <>
              <SuccessIcon /> <span>{t('compFBIGAccountConnection:modal-fb-ig-connect-successful-title')}</span>
            </>
          }
          okText={
            !isLinking
              ? t('compFBIGAccountConnection:modal-fb-ig-connect-successful-ok-button-text')
              : t('compFBIGAccountConnection:modal-fb-ig-connect-successful-ok-button-linking-text')
          }
          cancelText={t('compFBIGAccountConnection:modal-fb-ig-connect-successful-cancel-button-text')}
          okButtonProps={{ disabled: isLinking }}
          cancelButtonProps={{ disabled: isLinking }}
          onCancel={handleOnFBIGPageModalCancel}
          onOk={handleOnFBIGPageModalConfirm}
        >
          <Row gutter={16}>
            <Col>
              <PlatformSelectionCard>
                <StyledFacebookOutlined />
                <br />
                <Checkbox checked onChange={() => {}} />
              </PlatformSelectionCard>
            </Col>
            <Col>
              <PlatformSelectionCard>
                <StyledInstagramOutlined />
                <br />
                <Checkbox onChange={handleOnIGOptionSelect} disabled={checkIsObjectEmpty(pagesFBIGSelection.find(page => page.hasIG))} />
              </PlatformSelectionCard>
            </Col>
          </Row>
          <br />
          <p>{t('compFBIGAccountConnection:modal-fb-ig-connect-successful-instruction-title')}</p>
          <StyledPagesSelection options={pagesFBIGSelection} onChange={handleOnFBIGPageSelect} value={selectedFBIGPageId} />
        </Modal>
      )}
    </>
  );
};

export default withAppContext(withFbContext(FBIGAccountConnection));
