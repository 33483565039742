import React, { useEffect, useState } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { Card, Col, Button, Form, message, Modal, Row, Tooltip } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { postSignUp } from 'apis/auth';
import { withAppContext } from 'contexts/AppContext/AppContext';

import FormInput from 'components/FormInput/FormInput';
import FormGroupSelectionInput from 'components/FormGroupSelectionInput/FormGroupSelectionInput';
import FormSelection from 'components/FormSelection/FormSelection';
import FormPasswordInput from 'components/FormPasswordInput/FormPasswordInput';

import { useFetchConstant } from 'hooks/constants';
import { useLocationQuery } from 'hooks/router';
import { useAddHotjarScript } from 'hooks/hotjar';

import { IS_DEV } from 'config/env';

import { CAPITAL_ALPHANUMERIC_MAX_4_CHARS } from 'utils/constants';

import Logo from 'images/ulive-logo-wo-bg.png';

import {
  CardTitle,
  CursiveText,
  DescContainer,
  DescLastLineContainer,
  Description,
  FullWidthLogoRow,
  ModalSuccessMessageSignature,
  RootContainer,
  StoreShortNameAlert
} from './SignUp.styles';

const { useForm } = Form;

const useDetectCountry = () => {
  const { country: countryISOCode } = useLocationQuery();

  return { countryISOCode };
};

const SignUp = () => {
  const { t } = useTranslation(['common', 'pageSignUp']);
  const { source: signupSource } = useLocationQuery();
  const [form] = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false);

  const { countryISOCode } = useDetectCountry();
  const { selection: countries, isLoading: isLoadingCountry, data: countriesConst } = useFetchConstant('countries', {
    valueKey: 'phoneCode',
    labelKey: 'phoneCode',
    query: { country: countryISOCode }
  });
  const { selection: currencies, isLoading: isLoadingCurrencies, data: currenciesConst } = useFetchConstant('currencies', {
    valueKey: 'iso',
    labelKey: 'iso',
    query: { country: countryISOCode }
  });
  const { selection: hearAboutUsSources } = useFetchConstant('hearAboutUsSources');

  useAddHotjarScript(!IS_DEV);

  useEffect(() => {
    if (!isLoadingCountry && !isLoadingCurrencies) {
      form.setFieldsValue({ contact: { countryCode: countriesConst.MALAYSIA.phoneCode } });
      form.setFieldsValue({ store: { currencyISOCode: currenciesConst.MYR.iso } });
    }
  }, [form, isLoadingCountry, isLoadingCurrencies, countriesConst, currenciesConst]);

  const handleOnSignUpButtonClick = async e => {
    e.preventDefault();
    try {
      setIsSubmitting(true);

      const values = await form.validateFields();
      const formattedStoreShortName = values.store && values.store.shortName && String(values.store.shortName).toUpperCase();
      const newPayload = {
        ...values,
        store: {
          ...values.store,
          shortName: formattedStoreShortName,
          signupSource
        }
      };

      return postSignUp(newPayload)
        .then(() => {
          setIsSignUpSuccess(true);
          Modal.success({
            title: t('pageSignUp:success-modal-message-title'),
            content: (
              <div>
                <p>
                  {t('pageSignUp:success-modal-mesage-content')} <b>{t('pageSignUp:success-modal-message-content-bold')}</b>.
                </p>
                <ModalSuccessMessageSignature>{t('pageSignUp:success-modal-message-signature')}</ModalSuccessMessageSignature>
                <CursiveText>{t('pageSignUp:success-modal-message-team')}</CursiveText>
              </div>
            )
          });
        })
        .catch(error => {
          const errMsg = error.message;
          const isErrRelatedToShortNameTaken = errMsg.includes('short name') && errMsg.includes('taken');
          !isErrRelatedToShortNameTaken && message.error(t('pageSignUp:success-modal-fail-message', { error: errMsg }));
          if (isErrRelatedToShortNameTaken) {
            form.setFields([
              {
                name: ['store', 'shortName'],
                errors: [`${t('pageSignUp:form-short-name-error')}`]
              }
            ]);
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } catch (error) {
      setIsSubmitting(false);

      if (error.errorFields && error.errorFields.length > 0) {
        return;
      } else {
        message.error(t('pageSignUp:success-modal-unknown-fail-message'));
      }
    }
  };

  return (
    <RootContainer>
      <Helmet>
        <meta name="title" id="gtm-title" content="Sign Up" />
        <title>{t('pageSignUp:page-title')}</title>
      </Helmet>
      <FullWidthLogoRow justify="center">
        <img src={Logo} alt="ulive-logo" width="20%" />
      </FullWidthLogoRow>

      <DescContainer justify="center">
        <Description>
          {t('pageSignUp:title-description')} <CursiveText bigger>{t('pageSignUp:product-name')}</CursiveText>
        </Description>
      </DescContainer>
      <DescLastLineContainer justify="center">
        <Description>{t('pageSignUp:title-subdescription')}</Description>
      </DescLastLineContainer>
      <Card>
        <Form form={form}>
          <Row>
            <Col span={24} md={24}>
              <Card
                title={<CardTitle>{t('pageSignUp:form-card-title-account-info')}</CardTitle>}
                bordered={false}
                bodyStyle={{ padding: '24px 24px 0 24px' }}
              >
                <Row gutter={16}>
                  <Col span={24} md={12}>
                    <FormInput
                      name="username"
                      type="username"
                      label={
                        <span>
                          {t('pageSignUp:form-field-label-acc-username')}{' '}
                          <Tooltip title={t('pageSignUp:form-field-username-tips')}>
                            <InfoCircleFilled />
                          </Tooltip>
                        </span>
                      }
                      requiredErrorMessage={t('pageSignUp:form-field-required-error-message-acc-username')}
                    />
                  </Col>
                  <Col span={24} md={12}>
                    <FormPasswordInput
                      name="password"
                      label={
                        <span>
                          {t('pageSignUp:form-field-label-acc-password')}{' '}
                          <Tooltip title={t('pageSignUp:form-field-password-tips')}>
                            <InfoCircleFilled />
                          </Tooltip>
                        </span>
                      }
                      placeholder={t('pageSignUp:form-field-password-placeholder')}
                      requiredErrorMessage={t('pageSignUp:form-field-required-error-message-acc-password')}
                      checkPattern={true}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24} md={24}>
              <Card
                title={<CardTitle>{t('pageSignUp:form-card-title-personal-info')}</CardTitle>}
                bordered={false}
                bodyStyle={{ padding: '24px 24px 0 24px' }}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <FormInput
                      name="name"
                      label={t('pageSignUp:form-field-label-full-name')}
                      requiredErrorMessage={t('pageSignUp:form-field-required-error-message-full-name')}
                    />
                  </Col>
                  <Col span={24} md={12}>
                    <FormInput
                      name="email"
                      type="email"
                      label={t('pageSignUp:form-field-label-email-address')}
                      requiredErrorMessage={t('pageSignUp:form-field-required-error-message-email-address')}
                    />
                  </Col>
                  <Col span={24} md={12}>
                    <FormGroupSelectionInput
                      label={t('pageSignUp:form-field-label-contact-number')}
                      inputName={['contact', 'contactNumber']}
                      selectionName={['contact', 'countryCode']}
                      inputPlaceholder="109876543"
                      inputType="contact"
                      selections={countries}
                      requiredErrorMessage={t('pageSignUp:form-field-required-error-message-contact-number')}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card
                title={<CardTitle>{t('pageSignUp:form-card-title-store-info')}</CardTitle>}
                bordered={false}
                bodyStyle={{ padding: '24px 24px 8px 24px' }}
              >
                <Row gutter={16}>
                  <Col span={24} md={12}>
                    <FormInput
                      name={['store', 'name']}
                      label={t('pageSignUp:form-field-label-store-name')}
                      requiredErrorMessage={t('pageSignUp:form-field-required-error-message-store-name')}
                    />
                  </Col>
                  <Col span={24} md={12}>
                    <FormInput
                      name={['store', 'shortName']}
                      label={t('pageSignUp:form-field-label-short-name')}
                      placeholder="e.g.: SMPL"
                      requiredErrorMessage={t('pageSignUp:form-field-required-error-message-short-name')}
                      extraProps={{
                        maxLength: 4
                      }}
                      extraRules={[
                        {
                          pattern: CAPITAL_ALPHANUMERIC_MAX_4_CHARS,
                          message: `Short name must only contain '-', '&', and alphanumeric.`
                        }
                      ]}
                    />
                  </Col>
                  <Col span={24}>
                    <FormSelection
                      name={['store', 'currencyISOCode']}
                      label={t('pageSignUp:form-field-label-currency')}
                      selections={currencies.map(currency => ({ value: currency.value, label: `${currency.label} (${currency.symbol})` }))}
                      placeholder="e.g.: MYR"
                      requiredErrorMessage={t('pageSignUp:form-field-required-error-message-store-currency')}
                    />
                  </Col>
                  <Col span={24}>
                    <StoreShortNameAlert
                      showIcon
                      type="warning"
                      message={<b>{t('pageSignUp:store-short-name-alert-title')}</b>}
                      description={
                        <span>
                          {t('pageSignUp:store-short-name-alert-description')}
                          <br />
                          {t('pageSignUp:store-short-name-alert-example')}
                          <br />
                          <i>
                            <b>{t('pageSignUp:store-short-name-alert-note')} </b> {t('pageSignUp:store-short-name-alert-reminder')}
                          </i>
                        </span>
                      }
                    />
                  </Col>
                  <Col span={24}>
                    <FormSelection
                      name={['store', 'hearAboutUsSource']}
                      label={t('pageSignUp:form-field-label-hear-about-us-source')}
                      selections={hearAboutUsSources}
                      requiredErrorMessage={t('pageSignUp:form-field-required-error-message-hear-about-us-source')}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Button type="primary" block onClick={handleOnSignUpButtonClick} loading={isSubmitting} disabled={false}>
            {isSignUpSuccess ? t('pageSignUp:form-button-signup-success') : t('pageSignUp:form-button-signup')}
          </Button>
        </Form>
      </Card>
    </RootContainer>
  );
};

export default withAppContext(SignUp);
