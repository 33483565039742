import { useCallback } from 'react';
import { Result, Skeleton } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import { useGetFastPayPaymentStatusForExternal } from 'apis/external';

import { useLocationQuery } from 'hooks/router.js';

import { getCheckoutRoute } from 'utils/routes.js';
import { checkIsStringBoolTrue } from 'utils/general.js';

import { useTimer } from 'hooks/utils.js';

import { AlertText, ContainerCard, SubTitleText } from './CheckoutPayment.styles.js';

const PG_BILLPLZ = 'billplz';
const PG_FASTPAY = 'fastPay';
const PG_RAZERPAY = 'razerPay';

/* ================================================== Local Functions ================================================== */
const useGetPaymentPaidStatus = (orderNumber, query) => {
  const { pg: paymentGateway, accessCode } = query;
  const { isLoading: isPaymentStatusFastPayLoading, data: paymentStatusFastPay } = useGetFastPayPaymentStatusForExternal(orderNumber, accessCode, {
    shouldFetch: paymentGateway === PG_FASTPAY
  });

  const checkIsOrderPaid = () => {
    switch (true) {
      case paymentGateway === PG_BILLPLZ:
        return checkIsStringBoolTrue(query['billplz[paid]']);
      case paymentGateway === PG_FASTPAY:
        return paymentStatusFastPay && paymentStatusFastPay.isSuccess;
      case paymentGateway === PG_RAZERPAY:
        return checkIsStringBoolTrue(query['razerpay[paid]']);
      default:
        return false;
    }
  };

  return {
    isLoading: isPaymentStatusFastPayLoading || false,
    isPaid: checkIsOrderPaid()
  };
};

/* ================================================== Main Component ================================================== */
const CheckoutPayment = () => {
  const { t } = useTranslation(['pageCheckoutPayment']);
  const query = useLocationQuery();
  const history = useHistory();
  const params = useParams();
  const { isLoading, isPaid } = useGetPaymentPaidStatus(params.orderNumber, query);
  const checkoutLink = getCheckoutRoute(params.orderNumber, {
    accessCode: query.accessCode,
    hasCheckout: isPaid
  }).path;

  const handleOnTimerEnd = useCallback(() => {
    history.push(checkoutLink);
  }, [history, checkoutLink]);

  const timeLeft = useTimer(5, true, handleOnTimerEnd);

  return (
    <ContainerCard>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Helmet>
            <meta name="title" id="gtm-title" content={isPaid ? 'Payment Success' : 'Payment Failure'} />
            <title>{isPaid ? t('pageCheckoutPayment:page-success-title') : t('pageCheckoutPayment:page-failure-title')}</title>
          </Helmet>
          <Result
            status={isPaid ? 'success' : 'error'}
            title={isPaid ? t('pageCheckoutPayment:payment-success-title') : t('pageCheckoutPayment:payment-failure-title')}
            subTitle={
              <>
                <SubTitleText>{t('pageCheckoutPayment:message-redirect', { timeLeft })}</SubTitleText>
                <AlertText>{t('pageCheckoutPayment:message-redirect-warning')}</AlertText>
              </>
            }
            extra={[
              <Trans i18nKey="pageCheckoutPayment:message-redirect-fallback" key="redirect-manual">
                <a href={checkoutLink}>here</a>
              </Trans>
            ]}
          />
        </>
      )}
    </ContainerCard>
  );
};

export default CheckoutPayment;
