import styled from '@emotion/styled';
import { Modal } from 'antd';

export const RootModal = styled(Modal)`
  width: calc(100vw - 24px) !important;

  @media (min-width: 1200px) {
    width: 85vw !important;
  }
`;

export const ContentContainer = styled.div`
  width: 576px;

  @media (min-width: 768px) {
    width: 100%;
  }
`;
