import { useEffect } from "react";

import { logError } from 'utils/logging';

export const useAddHotjarScript = (shouldAdd) => {
  useEffect(() => {
    if (!shouldAdd) {
      return;
    }

    const script = document.createElement('script');
    script.innerHTML = `(function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 3104532, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.id = "hotjar-tracking"
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`;

    document.head.appendChild(script);

    return () => {
      try {
        const hotjarScript = document.getElementById('hotjar-tracking');
        // Hotjar custom addon script
        const hotjarScriptAddon = document.querySelector('script[src="https://script.hotjar.com/modules.3ccc2561e7224ffbf9991.js"]');

        document.head.removeChild(script);
        document.head.removeChild(hotjarScript);
        document.head.removeChild(hotjarScriptAddon);
      } catch (ex) {
        logError(ex.message);
        logError('something went wrong when trying to remove hotjar');
      }
    };
  }, [shouldAdd]);
};