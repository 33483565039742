import { useState, useEffect } from 'react';
import { CloseOutlined, CheckCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { ContentContainer, ContentText, CompletedButton, TitleIcon, TitleText, ViewOrdersButton } from './DeleteShipmentOptionsModal.styles';

const useGetLinkedOrders = getLinkedOrdersFn => {
  const [numberOfOrdersToBeUnset, setNumberOfOrdersToBeUnset] = useState(0);
  const [numberOfOrdersToBeMoved, setNumberOfOrdersToBeMoved] = useState(0);

  useEffect(() => {
    getLinkedOrdersFn().then(({ ordersToBeUnset, ordersToBeMoved }) => {
      setNumberOfOrdersToBeUnset(ordersToBeUnset.length);
      setNumberOfOrdersToBeMoved(ordersToBeMoved.length);
    });
  }, [getLinkedOrdersFn]);

  return { numberOfOrdersToBeUnset, numberOfOrdersToBeMoved };
};

const DeleteShipmentOptionsModal = ({ visible, onCancel, onMoveToCancel, onMoveToComplete, onViewOrders, getLinkedOrdersFn }) => {
  const { t } = useTranslation(['pageShipmentDetails']);

  const { numberOfOrdersToBeUnset, numberOfOrdersToBeMoved } = useGetLinkedOrders(getLinkedOrdersFn);
  const totalNumberOfOrders = numberOfOrdersToBeMoved + numberOfOrdersToBeUnset;
  const isViewAllButtonDisabled = totalNumberOfOrders > 10;

  return (
    <Modal
      visible={visible}
      width="fit-content"
      closable={false}
      destroyOnClose={true}
      footer={null}
      bodyStyle={{ padding: '32px 32px 24px', maxWidth: 950 }}
    >
      <TitleIcon />
      <TitleText>{t('pageShipmentDetails:delete-modal-title-text')}</TitleText>
      <ContentText>{t('pageShipmentDetails:delete-modal-content-pre-text', { totalNumberOfOrders })}</ContentText>
      <ContentText>
        <ul>
          {!!numberOfOrdersToBeUnset && (
            <li>{t('pageShipmentDetails:delete-modal-content-pending-precheckout-order-action-text', { numberOfOrdersToBeUnset })}</li>
          )}
          {!!numberOfOrdersToBeMoved && (
            <li>{t('pageShipmentDetails:delete-modal-content-checkout-paid-order-action-text', { numberOfOrdersToBeMoved })}</li>
          )}
        </ul>
      </ContentText>

      <ContentContainer gutter={[8, 8]} justify="start">
        <Col>
          <Button onClick={onCancel} icon={<CloseOutlined />}>
            {t('pageShipmentDetails:delete-modal-close-button-text')}
          </Button>
        </Col>
        <Col>
          <CompletedButton onClick={onMoveToComplete} icon={<CheckCircleOutlined />}>
            {t('pageShipmentDetails:delete-modal-complete-button-text', { numberOfOrders: numberOfOrdersToBeMoved })}
          </CompletedButton>
        </Col>
        {/* Kong - Temporary remove due to seller cancel without reading. */}
        {/* <Col>
          <CancelledButton onClick={onMoveToCancel} icon={<CloseCircleOutlined />}>
            {t('pageShipmentDetails:delete-modal-cancel-button-text', { numberOfOrders: numberOfOrdersToBeMoved })}
          </CancelledButton>
        </Col> */}
        <Col>
          <Tooltip title={isViewAllButtonDisabled && t('pageShipmentDetails:delete-option-modal-tooltip-title')} placement="right">
            <div>
              <ViewOrdersButton
                onClick={onViewOrders}
                icon={<EyeOutlined />}
                disabled={isViewAllButtonDisabled}
                style={isViewAllButtonDisabled ? { pointerEvents: 'none' } : {}}
              >
                {t('pageShipmentDetails:delete-modal-view-button-text', { numberOfOrders: totalNumberOfOrders })}
              </ViewOrdersButton>
            </div>
          </Tooltip>
        </Col>
      </ContentContainer>
    </Modal>
  );
};

export default DeleteShipmentOptionsModal;
