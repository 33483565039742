import PropTypes from 'prop-types';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { constructDisplayPriceWithCurrency } from 'utils/general';

const PriceDisplay = ({
  storeCurrency = {},
  amount,
  isHideSymbol = false,
  isDeduct = false,
  prefix = '',
  suffix = '',
  labelWhenUndefined,
  labelWhenZero,
  className
}) => {
  return (
    <span className={className}>
      {prefix}
      {constructDisplayPriceWithCurrency({
        amount,
        currencySymbol: storeCurrency.symbol,
        currencyISO: storeCurrency.iso,
        isHideSymbol,
        isDeduct,
        labelWhenUndefined,
        labelWhenZero
      })}
      {suffix}
    </span>
  );
};

PriceDisplay.propTypes = {
  storeCurrency: PropTypes.object,
  amount: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  isHideSymbol: PropTypes.bool,
  isDeduct: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  labelWhenUndefined: PropTypes.string,
  labelWhenZero: PropTypes.string
};

export default withAppContext(PriceDisplay);
