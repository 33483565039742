import styled from '@emotion/styled';

import Img1 from './images/1.png';

export const FirstContentTitle = styled.p`
  font-size: 1.5em;
  line-height: 1.5em;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 0.5em;
  text-align: left;

  @media (min-width: 390px) {
    font-size: 1.7em;
    line-height: 1.5em;
  }

  @media (min-width: 414px) {
    font-size: 2em;
  }

  @media (min-width: 768px) {
    font-size: 3em;
  }

  @media (min-width: 1200px) {
    width: 60%;
  }
`;

export const FirstContentDesc = styled.p`
  font-size: 1em;
  line-height: 1.5em;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 0.5em;
  text-align: left;

  @media (min-width: 390px) {
    font-size: 1.2em;
    line-height: 1.5em;
  }

  @media (min-width: 768px) {
    font-size: 1.5em;
  }

  @media (min-width: 1200px) {
    font-size: 2em;
    margin-bottom: 0.2em;
  }
`;

export const FirstContentDescContainer = styled.div`
  width: 100%;
  margin-bottom: 5%;

  @media (min-width: 768px) {
    width: 60%;
  }
`;

export const FirstImg = styled.div`
  display: block;
  position: absolute;
  z-index: -1; // To prevent btn event being covered

  background-image: url(${Img1});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  height: 36vh;
  width: 64vw;
  left: 66vw;
  top: 28vh;

  @media (min-width: 414px) {
    height: 28vh;
    width: 60vw;
    left: 72vw;
    top: 36vh;
  }

  @media (min-width: 576px) {
    background-image: url(${Img1});
    height: 60vh;
    width: 60vw;
    left: 80vw;
    top: 32vh;
  }

  @media (min-width: 768px) {
    height: 54vh;
    width: 60vw;
    left: 90vw;
    top: 24vh;
  }

  @media (min-width: 820px) {
    height: 60vh;
    width: 60vw;
    left: 70vw;
    top: 16vh;
  }

  @media (min-width: 1024px) {
    height: 64vh;
    width: 60vw;
    left: 80vw;
    top: 10vh;
  }

  @media (min-width: 1200px) {
    height: 72vh;
    width: 88vw;
    left: 80vw;
    top: 0vh;
  }
`;
