import styled from '@emotion/styled';
import { Modal } from 'antd';

export const OrderSummaryModal = styled(Modal)`
  width: 100vw !important;

  @media (min-width: 1200px) {
    width: 70vw !important;
  }
`;
