import { Card } from 'antd';

import { constructReadableNum, constructDisplayPrice } from 'utils/general';

import SectionTitle from '../SectionTitle/SectionTitle';

const PercentageChangedCard = ({ title, value, isCurrency, storeCurrency }) => {
  return (
    <Card
      style={{
        width: '100%',
        marginBottom: 12
      }}
    >
      <SectionTitle>{title}</SectionTitle>
      <p style={{ fontSize: 28, marginBottom: 0, fontWeight: '600' }}>
        {value && (isCurrency ? `${storeCurrency.symbol} ${constructDisplayPrice(value, storeCurrency.iso)}` : constructReadableNum(value))}
      </p>
    </Card>
  );
};

export default PercentageChangedCard;
