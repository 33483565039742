import styled from '@emotion/styled';

export const FieldLabel = styled.p`
  font-size: 16px;
  width: 100%;
  margin: ${props => props.margin || 'auto'};
`;

export const NoteLabel = styled.p`
  color: ${props => props.theme.color.grey};
  font-size: 12px;
  font-style: italic;
`;
