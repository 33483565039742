import { useState } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { constructColumn, constructColumnFilterSearch, formatPaginationShowTotalDisplay } from 'utils/table/table';

import ProductSoldModal from '../ProductSoldModal/ProductSoldModal';
import SectionTitle from '../SectionTitle/SectionTitle';
import { StyledTextLink } from './ProductsSoldTable.styles';

const constructColumns = ({ t, handleOnViewSummaryBtnClick }) => [
  {
    ...constructColumn(t('pageReporting:table-header-name'), 'label', {
      hasSorter: true,
      width: '40%'
    }),
    render: (label, record) => {
      return <StyledTextLink onClick={() => handleOnViewSummaryBtnClick(record)}>{label}</StyledTextLink>;
    },
    ...constructColumnFilterSearch('label', t('pageReporting:table-header-action-search-product'), {})
  },
  {
    ...constructColumn(t('pageReporting:table-header-keyword'), 'matchingKeyword', { hasSorter: true, width: '15%' }),
    ...constructColumnFilterSearch('matchingKeyword', t('pageReporting:table-header-action-search-product-keyword'), {})
  },
  {
    ...constructColumn(t('pageReporting:table-header-quantity-sold'), 'quantitySold', {
      hasSorter: true,
      isNumber: true,
      width: '15%'
    })
  },
  {
    ...constructColumn(t('pageReporting:table-header-total-sale'), 'totalPrice', {
      hasSorter: true,
      isPrice: true,
      isNumber: true,
      width: '15%'
    })
  }
];

const ProductsSoldTable = ({ reportingData, query }) => {
  const { t } = useTranslation(['pageReporting']);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleOnViewSummaryBtnClick = product => {
    setSelectedProduct(product);
  };

  return (
    <>
      <SectionTitle>{t('pageReporting:table-title')}</SectionTitle>
      <Table
        columns={constructColumns({ t, handleOnViewSummaryBtnClick })}
        dataSource={reportingData.products}
        pagination={{ showSizeChanger: true, showTotal: formatPaginationShowTotalDisplay }}
        scroll={{ x: 'auto' }}
      />
      {selectedProduct && (
        <ProductSoldModal
          query={{ ...query, productId: selectedProduct.productId, varianceId: selectedProduct.varianceId }}
          onClose={() => setSelectedProduct(null)}
        />
      )}
    </>
  );
};

export default ProductsSoldTable;
