import { useTranslation } from 'react-i18next';
import { LabelContainer, LabelText } from './OutOfStockLabel.styles';

const OutOfStockLabel = ({ showError }) => {
  const { t } = useTranslation(['compOutOfStockLabel']);

  return (
    <LabelContainer showError={showError}>
      <LabelText showError={showError}>{t('compOutOfStockLabel:out-of-stock')}</LabelText>
    </LabelContainer>
  );
};

export default OutOfStockLabel;
