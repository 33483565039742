import { constructDisplayPrice } from 'utils/general';

import { Sticker, ProductNameLabel } from './ProductSticker.styles';

const ProductSticker = ({ soldProduct, storeCurrency }) => {
  return (
    <Sticker>
      <p style={{ fontWeight: 'bold', lineHeight: 'normal', marginBottom: 0 }}>
        {soldProduct.customerName} | {soldProduct.orderNumber}
      </p>
      <ProductNameLabel>
        {soldProduct.productQuantity} x {soldProduct.productLabel}
      </ProductNameLabel>
      <p>
        {storeCurrency.symbol}
        {constructDisplayPrice(soldProduct.productAmount, storeCurrency.iso)}
      </p>
    </Sticker>
  );
};

export default ProductSticker;
