import { useEffect, useMemo, useState } from 'react';
import { message, Modal, Skeleton } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { patchOrderPrintedStatus, useGetOrderDetails, useRefetchOrders } from 'apis/order';

import { useFetchConstant } from 'hooks/constants';
import { useLocationQuery } from 'hooks/router';

import Invoice from '../components/Invoice';

const useIsCompact = () => {
  const { compact } = useLocationQuery();
  const isCompact = useMemo(() => !!compact, [compact]);

  return isCompact;
};

const usePrint = ({ t, isReady, orderId }) => {
  const [isPrinted, setIsPrinted] = useState(false);
  const refetchOrders = useRefetchOrders();

  const handleOnCancelIsPrintedStatusModal = () => {
    setIsPrinted(false);
  };

  const handleOnUpdateIsPrintedStatusModal = () => {
    patchOrderPrintedStatus({ orderIds: [orderId] })
      .then(() => {
        refetchOrders();
        message.success(t('common:update-success-message'));
      })
      .finally(() => {
        setIsPrinted(false);
      });
  };

  useEffect(() => {
    const handleAfterPrint = () => {
      setIsPrinted(true);
    };

    window.addEventListener('afterprint', handleAfterPrint);

    if (isReady) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }

    return () => {
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, [isReady, setIsPrinted]);

  return { isPrinted, handleOnCancelIsPrintedStatusModal, handleOnUpdateIsPrintedStatusModal };
};

const PrintSingleInvoice = () => {
  const { id: orderId } = useParams();
  const { t } = useTranslation(['common', 'pageOrderDetails']);
  const { isLoading: isOrderLoading, data: order } = useGetOrderDetails(orderId);
  const isCompact = useIsCompact();

  const { selection: orderStatuses, isLoading: isOrderStatusesLoading } = useFetchConstant('orderStatuses');
  const { selection: paymentMethods, isLoading: isPaymentMethodsLoading } = useFetchConstant('paymentMethods');
  const { isLoading: isCountriesLoading, selection: countries } = useFetchConstant('countries', {
    labelKey: 'name',
    valueKey: 'iso2'
  });
  const { data: productTypesConst, isLoading: isProductTypesLoading } = useFetchConstant('productTypes');

  const isLoading = isOrderLoading || isOrderStatusesLoading || isPaymentMethodsLoading || isCountriesLoading || isProductTypesLoading;

  const { isPrinted, handleOnCancelIsPrintedStatusModal, handleOnUpdateIsPrintedStatusModal } = usePrint({
    t,
    isReady: !isLoading && order,
    orderId
  });

  return isLoading ? (
    <Skeleton active />
  ) : (
    <>
      <Helmet>
        <meta name="title" id="gtm-title" content="Order | Print Invoice" />
        <title>
          {t('pageOrderDetails:pdf-invoice-title')} - {order.orderNumber}
        </title>
      </Helmet>
      <Invoice
        order={order}
        orderStatuses={orderStatuses}
        paymentMethods={paymentMethods}
        countries={countries}
        productTypesConst={productTypesConst}
        isCompact={isCompact}
      />
      {isPrinted && (
        <Modal
          title={t('pagePrintBulkInvoice:update-is-printed-status-modal-title')}
          visible={isPrinted}
          onCancel={handleOnCancelIsPrintedStatusModal}
          onOk={handleOnUpdateIsPrintedStatusModal}
          okText={t('pagePrintBulkInvoice:update-is-printed-status-modal-ok-text')}
          cancelText={t('pagePrintBulkInvoice:update-is-printed-status-modal-cancel-text')}
        >
          {t('pagePrintBulkInvoice:update-is-printed-status-modal-content')}
        </Modal>
      )}
    </>
  );
};

export default PrintSingleInvoice;
