import styled from '@emotion/styled';

export const SampleNotifContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 24px;
  border: 1px solid #f0f0f0;
  padding: 16px;
  margin-bottom: 16px;
`;

export const SampleNotifInfoContainer = styled.div`
  flex: 1;
  margin-left: 16px;
`;

export const SampleNotifStoreName = styled.p`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0;
`;

export const SampleNotifTimeText = styled.p`
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
    color: ${props => props.theme.color.grey};
    font-size: 12px;
    margin-bottom: 0;
  }
`;

export const SampleNotifMessageText = styled.p`
  font-size: 12px;
  margin-bottom: 0;
`;
