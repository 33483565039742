import PropTypes from 'prop-types';

const Icon = ({ src, name, width, height, onClick, style, className }) => {
  return (
    <img
      src={src}
      alt={name}
      style={{ display: 'inline-block', width: width ? `${width}px` : undefined, height: height ? `${height}px` : undefined, ...style }}
      onClick={onClick}
      className={className}
    />
  );
};

Icon.propTypes = {
  src: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string
};

export default Icon;
