import { useState, useCallback, useEffect } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import FormUpload from 'components/Upload/FormUpload/FormUpload';
import PhotoManager from 'components/PhotoManager/PhotoManager';
import { withAppContext } from 'contexts/AppContext/AppContext';

import { useGetProductItemPhotos, useGetProductPhotos, patchProduct, patchProductVariance } from 'apis/product';

const { useForm } = Form;
const { error } = Modal;

export const UploadPhotosModal = withAppContext(
  ({ store, visible, isLoading, initialPhotoFiles, initialPhotos, onOk, onCancel, onFileUploadChange, photosRef }) => {
    const { t } = useTranslation(['common', 'compUploadProductPhotos']);
    const [form] = useForm();
    const [shouldUsePhotoManager, setShouldUsePhotoManager] = useState(true);
    const [isCompressing, setIsCompressing] = useState(false);
    const [currentPhotos, setCurrentPhotos] = useState(initialPhotos || []);

    useEffect(() => {
      setShouldUsePhotoManager(!initialPhotoFiles?.length);
    }, [initialPhotoFiles]);

    const [isInit, setIsInit] = useState(false);

    const handleOnOk = useCallback(() => {
      const newPhotoFiles = form.getFieldValue('photoFiles');
      const shouldUpdate =
        JSON.stringify(newPhotoFiles) !== JSON.stringify(initialPhotoFiles) || JSON.stringify(currentPhotos) !== JSON.stringify(initialPhotos);
      onOk(shouldUpdate, newPhotoFiles);
    }, [currentPhotos, form, initialPhotoFiles, initialPhotos, onOk]);

    useEffect(() => {
      if (visible && !isInit) {
        setIsInit(true);
        form.setFieldsValue({ photoFiles: initialPhotoFiles || [] });
        setCurrentPhotos(initialPhotos || []);
      }
    }, [isInit, visible, form, initialPhotoFiles, initialPhotos]);

    return (
      <Modal
        title={t('compUploadProductPhotos:modal-title')}
        visible={visible}
        okText={t('common:form-submit-update-button-text')}
        cancelText={t('common:modal-cancel-text')}
        destroyOnClose
        onOk={handleOnOk}
        onCancel={onCancel}
        okButtonProps={{ loading: isLoading || isCompressing }}
        cancelButtonProps={{ loading: isLoading || isCompressing }}
        maskClosable={false}
        afterClose={() => {
          form.setFieldsValue({ photoFiles: [] });
          setCurrentPhotos([]);
          setIsInit(false);
        }}
      >
        {shouldUsePhotoManager ? (
          <PhotoManager
            value={currentPhotos}
            onChange={setCurrentPhotos}
            onStatusChange={setIsCompressing}
            buttonLabel={t('pageProductDetails:form-submit-cover-photo-upload-button-text')}
            fileSizeThresholdToCompressInKb={200}
            ref={photosRef}
            multiple
            shouldRemoveDocsMarkedForDelete
            storeId={store._id}
          />
        ) : (
          <Form form={form}>
            <FormUpload
              name="photoFiles"
              buttonLabel={t('compUploadProductPhotos:form-upload-button-text')}
              acceptTypes={['image/*']}
              fileSizeLimitInMb={5}
              isMultiple
              isDisplayCard
              onFileUploadChange={onFileUploadChange}
            />
          </Form>
        )}
      </Modal>
    );
  }
);

export const UploadPhotosTriggerButton = ({ isMinimal, isLoading, isDisabled, onClick }) => {
  const { t } = useTranslation(['compUploadProductPhotos']);

  return (
    <Button type="primary" icon={<UploadOutlined />} loading={isLoading} onClick={onClick} disabled={isDisabled}>
      {!isMinimal && t('compUploadProductPhotos:button-text-label-photos')}
    </Button>
  );
};

export const UploadPhotosTriggerLinkButton = ({ isLoading, isDisabled, onClick }) => {
  const { t } = useTranslation(['compUploadProductPhotos']);

  return (
    <Button type="link" icon={<UploadOutlined />} loading={isLoading} onClick={onClick} disabled={isDisabled}>
      {t('compUploadProductPhotos:button-link-text-label-photos')}
    </Button>
  );
};

export const useUploadPhotosModal = ({ updateProductPhotos }) => {
  const { t } = useTranslation(['common']);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [productId, setProductId] = useState(undefined);
  const [varianceId, setVarianceId] = useState(undefined);

  const { data: photoFiles, isLoading: isPhotoFilesLoading, refetch: refetchPhotoFiles } = useGetProductItemPhotos(
    productId,
    varianceId,
    isModalVisible
  );

  const { data: photos = [], isLoading: isPhotosLoading, refetch: refetchPhotos } = useGetProductPhotos(productId, varianceId, isModalVisible);

  const openModal = useCallback((selectedProductId, selectedVarianceId) => {
    setIsModalVisible(true);
    setProductId(selectedProductId);
    setVarianceId(selectedVarianceId);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
    setProductId(undefined);
    setVarianceId(undefined);
  }, []);

  const handleOnOk = useCallback(
    async (shouldUpdate, newPhotoFiles) => {
      if (!shouldUpdate) {
        closeModal();
      } else {
        setIsModalLoading(true);

        const updatePayload = photoFiles?.length ? { photoFiles: newPhotoFiles } : { photos: await updateProductPhotos() };

        const updatePhotoPromise = varianceId ? patchProductVariance(productId, varianceId, updatePayload) : patchProduct(productId, updatePayload);

        updatePhotoPromise
          .then(() => {
            message.success(t('common:update-success-message'));
            refetchPhotoFiles();
            refetchPhotos();
          })
          .catch(ex => {
            error({
              title: ex.message
            });
          })
          .finally(() => {
            setIsModalLoading(false);
            closeModal();
          });
      }
    },
    [t, productId, varianceId, photoFiles, refetchPhotoFiles, refetchPhotos, closeModal, updateProductPhotos]
  );

  const handleOnOpen = useCallback(
    (selectedProductId, selectedVarianceId) => () => {
      openModal(selectedProductId, selectedVarianceId);
    },
    [openModal]
  );

  const handleOnClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleOnFileUploadChange = useCallback(isUploading => {
    setIsModalLoading(isUploading);
  }, []);

  return {
    isUploadPhotosModalLoading: isModalLoading,
    isUploadPhotosTriggerBtnLoading: isPhotoFilesLoading || isPhotosLoading,
    isUploadPhotosModalVisible: isModalVisible && !isPhotoFilesLoading && !isPhotosLoading,

    photoFiles,
    photos,

    handleOnConfirmUploadPhotos: handleOnOk,
    handleOnOpenUploadPhotosModal: handleOnOpen,
    handleOnCloseUploadPhotosModal: handleOnClose,
    handleOnFileUploadPhotosModalChange: handleOnFileUploadChange
  };
};
