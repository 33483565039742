import { TrophyOutlined, TeamOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Modal, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import TextButton from 'components/TextButton/TextButton';

import { useFetchConstant } from 'hooks/constants';

import { getLuckyDrawSlotMachineRoute } from 'utils/routes';
import { constructColumn } from 'utils/table/table';
import { getLabelOfConstant } from 'utils/general';

import LuckyDrawPlayersModal from '../LuckyDrawPlayersModal/LuckyDrawPlayersModal';

const constructColumns = (t, luckyDrawStatuses, handleOnViewWinnersClick, handleOnViewAllParticipantsClick) => [
  {
    ...constructColumn(t('modalLuckyDraws:table-header-title-session'), 'title')
  },
  {
    ...constructColumn(t('modalLuckyDraws:table-header-title-keyword'), 'keyword')
  },
  {
    ...constructColumn(t('modalLuckyDraws:table-header-title-status'), 'status'),
    render: status => getLabelOfConstant(status, luckyDrawStatuses)
  },
  {
    ...constructColumn(t('modalLuckyDraws:table-header-title-players'), 'totalPlayers', { isNumber: true, width: '15%' })
  },
  {
    ...constructColumn(t('modalLuckyDraws:table-header-title-actions'), 'actions', { width: '30%' }),
    render: (text, record) => {
      return (
        <Row>
          <TextButton
            icon={<TrophyOutlined />}
            onClick={() => handleOnViewWinnersClick(record._id)}
            text={t('modalLuckyDraws:table-action-view-winners-button')}
          />
          <TextButton
            icon={<TeamOutlined />}
            onClick={() => handleOnViewAllParticipantsClick(record._id)}
            text={t('modalLuckyDraws:table-action-view-players-button')}
          />
        </Row>
      );
    }
  }
];

const LuckyDrawsModal = ({ visible, luckyDraws = [], onClose }) => {
  const { t } = useTranslation(['common', 'modalLuckyDraws']);
  const [isViewParticipantsClick, setIsViewParticipantsClick] = useState(false);
  const [selectedLuckyDraw, setSelectedLuckyDraw] = useState(false);
  const { selection: luckyDrawStatuses, isLoading: isLuckyDrawStatusesLoading } = useFetchConstant('luckyDrawStatuses');

  const isLoading = isLuckyDrawStatusesLoading;

  const handleOnViewWinnersClick = luckyDrawId => {
    window.open(`${getLuckyDrawSlotMachineRoute(luckyDrawId).path}`, '_blank');
  };

  const handleOnViewAllParticipantsClick = luckyDrawId => {
    setIsViewParticipantsClick(true);
    setSelectedLuckyDraw(luckyDrawId);
  };

  return (
    <>
      <Modal
        visible={visible}
        title={t('modalLuckyDraws:title', { totalSessions: luckyDraws.length })}
        onCancel={onClose}
        maskClosable={false}
        destroyOnClose={true}
        footer={null}
        width="70%"
      >
        <div style={{ width: '100%' }}>
          <Table
            size="small"
            loading={isLoading}
            columns={constructColumns(t, luckyDrawStatuses, handleOnViewWinnersClick, handleOnViewAllParticipantsClick)}
            dataSource={luckyDraws}
            pagination={false}
            scroll={{ x: 'fit-content' }}
          />
        </div>
      </Modal>
      {isViewParticipantsClick && (
        <LuckyDrawPlayersModal luckyDrawId={selectedLuckyDraw} visible={isViewParticipantsClick} onClose={() => setIsViewParticipantsClick(false)} />
      )}
    </>
  );
};

export default LuckyDrawsModal;
