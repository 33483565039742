import styled from '@emotion/styled';
import { Form } from 'antd';

const { Item: FormItem } = Form;

export const FormLabel = styled.p`
  font-size: 16px;
  margin-bottom: ${props => props.theme.spacing.xs};
`;

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 24px;
`;
