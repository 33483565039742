import { useState } from 'react';
import { Modal, List } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetStoreCustomerPointLogs } from 'apis/storecustomer';

import { formatToDateTimeWithPMAM } from 'utils/date';
import { constructReadableNum } from 'utils/general';

import { PointGreenText, PointRedText, StyledListItemMeta, StyledAvatarWithName } from './PointLogsModal.styles';

const PointLogsModal = ({ customerId, customerName, onClose }) => {
  const { t } = useTranslation(['common', 'pageCustomerDetails']);
  const [paginationQuery, setPaginationQuery] = useState({ currentPage: 1, limit: 10 });

  const { isLoading, paginatedData: storeCustomerPointLogs, total } = useGetStoreCustomerPointLogs(customerId, paginationQuery);

  return (
    <Modal
      width={768}
      title={t('pageCustomerDetails:modal-customer-point-log-modal-title', { customerName })}
      visible
      onCancel={onClose}
      footer={null}
    >
      <List
        loading={isLoading}
        itemLayout="vertical"
        pagination={{
          total,
          size: 'small',
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          onChange: (page, pageSize) => {
            setPaginationQuery({ currentPage: page, limit: pageSize });
          }
        }}
        dataSource={storeCustomerPointLogs}
        renderItem={(item, index) => (
          <List.Item key={item._id} style={{ ...(index === 0 && { paddingTop: 0 }) }}>
            <StyledListItemMeta
              avatar={
                <StyledAvatarWithName>
                  {item.userName ? item.userName.charAt(0).toUpperCase() : item.customerName.charAt(0).toUpperCase()}
                </StyledAvatarWithName>
              }
              title={item.userName || item.customerName}
              description={formatToDateTimeWithPMAM(item.createdAt)}
            />

            <p>
              {item.actionType === 'orderRefunded'
                ? t('pageCustomerDetails:modal-customer-point-log-modal-order-refunded-details', { orderNumber: item.orderNumber })
                : item.orderNumber
                ? t(`pageCustomerDetails:modal-customer-point-log-modal-order-${item.point > 0 ? 'earned' : 'redeemed'}-details`, {
                    orderNumber: item.orderNumber
                  })
                : t(`pageCustomerDetails:modal-customer-point-log-modal-manual-${item.point > 0 ? 'added' : 'deducted'}-details`, {
                    remarks: item.remarks
                  })}{' '}
              {item.point > 0 ? (
                <PointGreenText>{constructReadableNum(item.point)}</PointGreenText>
              ) : (
                <PointRedText>{constructReadableNum(Math.abs(item.point))}</PointRedText>
              )}
            </p>
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default PointLogsModal;
