// To chain promise, make sure you pass in array of NON-EXCUTED promises
// Eg: [Async Function, Async Function, Async Function]
// Not [Promise <Pending>, Promise <Pending>, Promise <Pending>]
export const chainPromises = promises => {
  let chainedPromise = promises[0]();
  for (let i = 1; i < promises.length; i++) {
    const promise = promises[i];
    chainedPromise = chainedPromise.then(promise);
  }
  return chainedPromise;
};
