import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { patchOrderMerging } from 'apis/order';

import { ContentContainer } from 'layouts/StandardLayout/StandardLayout.styles';
import OrderDisplay from 'components/OrderDisplay/OrderDisplay';

import { OrderSummaryModal } from './OrderMergeSummaryModal.styles';

/* =================================================== Exported Functions =================================================== */
export const useOrderMergeSummaryModal = () => {
  const [isOrderMergeSummaryModalVisible, setIsOrderMergeSummaryModalVisible] = useState(false);
  const [mergedOrder, setMergedOrder] = useState();

  const handleOnViewSummaryBtnClick = useCallback(mergedOrder => {
    setMergedOrder(mergedOrder);
    setIsOrderMergeSummaryModalVisible(true);
  }, []);

  const handleOnMergeOrderSummaryModalCancel = useCallback(() => {
    setIsOrderMergeSummaryModalVisible(false);
  }, []);

  return { isOrderMergeSummaryModalVisible, mergedOrder, handleOnViewSummaryBtnClick, handleOnMergeOrderSummaryModalCancel };
};

/* =================================================== Local Components =================================================== */
const Title = ({ t }) => {
  return (
    <>
      <EditOutlined />{' '}
      <span>
        <b>{t('pageOrder:merge-order-preview-modal-title')}</b>
      </span>
    </>
  );
};

/* =================================================== Main Component =================================================== */
const OrderMergeSummaryModal = ({
  orderStatuses,
  paymentMethods,
  countries,
  productTypesConst,
  socialMediaSourcesConst,
  mergedOrder,
  storeCustomWAMessage,
  isEmailCheckoutLinkEnabled,
  onMergeOrder,
  onCancel
}) => {
  const { t } = useTranslation(['pageOrder']);

  const [isMerging, setIsMerging] = useState(false);

  const handleOnSave = useCallback(() => {
    setIsMerging(true);

    patchOrderMerging(mergedOrder._id, { subOrderId: mergedOrder.subOrderId })
      .then(() => {
        message.info({ content: t('pageOrder:merge-order-preview-modal-success-merging-order') });
        onMergeOrder();
      })
      .catch(ex => {
        message.error({
          content: t('pageOrder:merge-order-preview-modal-error-merging-order')
        });
      })
      .finally(() => {
        setIsMerging(false);
      });
  }, [t, mergedOrder, onMergeOrder]);

  return (
    <OrderSummaryModal
      visible
      closable={false}
      maskClosable={false}
      style={{ top: 40 }}
      bodyStyle={{ overflowX: 'scroll' }}
      title={<Title t={t} />}
      okText={t('pageOrder:merge-order-preview-modal-ok-button-text')}
      cancelText={t('pageOrder:merge-order-preview-modal-cancel-button-text')}
      confirmLoading={isMerging}
      cancelButtonProps={{ loading: isMerging }}
      onCancel={onCancel}
      onOk={handleOnSave}
    >
      <ContentContainer>
        <OrderDisplay
          order={mergedOrder}
          orderStatuses={orderStatuses}
          paymentMethods={paymentMethods}
          countries={countries}
          productTypesConst={productTypesConst}
          socialMediaSourcesConst={socialMediaSourcesConst}
          storeCustomWAMessage={storeCustomWAMessage}
          isEmailCheckoutLinkEnabled={isEmailCheckoutLinkEnabled}
        />
      </ContentContainer>
    </OrderSummaryModal>
  );
};

export default OrderMergeSummaryModal;
