import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Checkbox, message, Modal, Form, Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';

import { patchUpdatePostById } from 'apis/post';
import { useGetStoreOrderTagSuggestions } from 'apis/order';

import FormMultiInputTag from 'components/FormMultiInputTag/FormMultiInputTag';

import { ContentText, OverrideOrderCheckboxLabel, RootModal, TitleIcon, TitleText } from './AddPostTagModal.styles';

const { error } = Modal;
const { useForm } = Form;

const ConfirmUpdateModal = ({ t, visible, isLoading, onOk, onCancel }) => {
  const [shouldUpdateOrderTags, setShouldUpdateOrderTags] = useState(true);

  return (
    <Modal
      visible={visible}
      confirmLoading={isLoading}
      okText={t('common:modal-ok-text')}
      cancelText={t('common:modal-cancel-text')}
      onOk={onOk(shouldUpdateOrderTags)}
      onCancel={onCancel}
    >
      <div style={{ marginBottom: '12px' }}>
        <TitleIcon />
        <TitleText>{t('common:confirm-modal-update-title')}</TitleText>
        <ContentText>{t('common:confirm-modal-update-content')}</ContentText>
      </div>
      <div>
        <Alert
          description={
            <div>
              <Checkbox checked={shouldUpdateOrderTags} onChange={() => setShouldUpdateOrderTags(!shouldUpdateOrderTags)}>
                <OverrideOrderCheckboxLabel>{t('modalOverviewPostTag:form-input-update-checkbox-order-tag-text')}</OverrideOrderCheckboxLabel>
              </Checkbox>
            </div>
          }
        />
      </div>
    </Modal>
  );
};

const useConfirmUpdateModal = ({ t, postId, setIsSubmitting, onSuccess, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedTags, setUpdatedTags] = useState({});

  const closeConfirmModal = () => {
    setIsVisible(false);
    setIsSubmitting(false);
  };

  const openConfirmModal = tags => {
    setIsVisible(true);
    setIsSubmitting(true);
    setUpdatedTags(tags);
  };

  const handleOnConfirmUpdateModalOk = shouldUpdateOrderTags => async () => {
    setIsLoading(true);
    patchUpdatePostById(postId, { tags: updatedTags, shouldUpdateOrderTags })
      .then(() => {
        message.success(t('common:update-success-message'));
        onSuccess();
        onClose();
      })
      .catch(ex => {
        error({
          title: ex.message
        });
      })
      .finally(() => {
        setIsLoading(false);
        closeConfirmModal();
      });
  };

  const handleOnConfirmUpdateModalCancel = () => {
    closeConfirmModal();
  };

  return {
    isConfirmUpdateModalVisible: isVisible,
    isConfirmUpdateModalLoading: isLoading,
    openConfirmModal,
    handleOnConfirmUpdateModalOk,
    handleOnConfirmUpdateModalCancel
  };
};

const AddPostTagModal = ({ postId, tags, onSuccess, onClose }) => {
  const { t } = useTranslation(['common', 'modalOverviewPostTag']);
  const [form] = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [searchTagKeyword, setSearchTagKeyword] = useState('');
  const delaySetSearchTagKeyword = useMemo(() => debounce(setSearchTagKeyword, 300), []);
  const { isLoading: isStoreOrderTagSuggestionsLoading, data: storeOrderTagsSuggestions } = useGetStoreOrderTagSuggestions(searchTagKeyword);

  const {
    isConfirmUpdateModalVisible,
    isConfirmUpdateModalLoading,
    openConfirmModal,
    handleOnConfirmUpdateModalOk,
    handleOnConfirmUpdateModalCancel
  } = useConfirmUpdateModal({
    t,
    postId,
    setIsSubmitting,
    onSuccess,
    onClose
  });

  const handleOnAddPostTag = () => {
    setIsSubmitting(true);
    form
      .validateFields()
      .then(values => {
        openConfirmModal(values.tags);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        setIsSubmitting(false);
      });
  };

  return (
    <RootModal
      title={t('modalOverviewPostTag:modal-title')}
      visible={true}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={onClose}
      footer={
        <Button type="primary" icon={<CheckOutlined />} loading={isSubmitting} onClick={handleOnAddPostTag}>
          {t('modalOverviewPostTag:save-button-text')}
        </Button>
      }
    >
      <Form form={form} initialValues={{ tags }}>
        <Alert style={{ marginBottom: '8px' }} type="info" message={t('modalOverviewPostTag:alert-message')} />
        <FormMultiInputTag
          name="tags"
          label={t('modalOverviewPostTag:form-input-label-tags')}
          isMultiple
          isShowDropdown
          isSearching={isStoreOrderTagSuggestionsLoading}
          onSearch={delaySetSearchTagKeyword}
          selections={storeOrderTagsSuggestions || []}
          onSelect={() => {
            setSearchTagKeyword('');
          }}
          style={{ marginBottom: '0' }}
        />
      </Form>
      <ConfirmUpdateModal
        t={t}
        visible={isConfirmUpdateModalVisible}
        isLoading={isConfirmUpdateModalLoading}
        onOk={handleOnConfirmUpdateModalOk}
        onCancel={handleOnConfirmUpdateModalCancel}
      />
    </RootModal>
  );
};

export default AddPostTagModal;
