import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { CURRENCY_FORMAT } from 'utils/constants';
import { withAppContext } from 'contexts/AppContext/AppContext';

import FormItem from 'components/FormItem/FormItem';
import CurrencyDisplay from 'components/CurrencyDisplay/CurrencyDisplay';

import { StyledFormInputNumber } from './FormInputNumber.styles';

const INPUT_TYPE_FINANCIAL = 'financial';
const INPUT_TYPE_PERCENTAGE = 'percentage';
const INPUT_TYPE_NUMBER = 'number';

const generatePrecision = ({ type, precision, currencyISO }) => {
  if (type === INPUT_TYPE_FINANCIAL && CURRENCY_FORMAT[currencyISO]) {
    return CURRENCY_FORMAT[currencyISO].decimal || CURRENCY_FORMAT[currencyISO].decimal > -1 ? CURRENCY_FORMAT[currencyISO].decimal : 2;
  }
  return precision;
};

const generateLabelNode = (type, label) => {
  if (label && type === INPUT_TYPE_FINANCIAL) {
    return <CurrencyDisplay prefix={label} />;
  } else if (label && type === INPUT_TYPE_PERCENTAGE) {
    return <>{label} (%)</>;
  }
  return label;
};

const generateNewExtraRules = (inputPrecision, extraRules, isAllowNegative) => {
  const rules = [
    {
      pattern: inputPrecision ? /^\d+\.?\d*$/ : isAllowNegative ? /^-?[0-9]+$/ : /^[0-9]*$/,
      message: inputPrecision ? `Only allow numeric input with ${inputPrecision} decimal points` : 'Only allow numeric input'
    }
  ];

  if (extraRules) {
    rules.push(...extraRules);
  }

  return rules;
};

const FormInputNumber = ({
  className,
  name,
  type,
  label,
  placeholder,

  extraRules,
  isAllowNegative,

  minValue,
  maxValue,
  formatter,
  parser,
  precision,
  disabled,

  requiredErrorMessage,

  onChange,

  storeCurrency,
  ...props
}) => {
  const inputPrecision = useMemo(() => generatePrecision({ type, precision, currencyISO: storeCurrency.iso }), [type, precision, storeCurrency]);
  const newExtraRules = useMemo(() => generateNewExtraRules(inputPrecision, extraRules, isAllowNegative), [
    extraRules,
    inputPrecision,
    isAllowNegative
  ]);
  const labelNode = useMemo(() => generateLabelNode(type, label), [type, label]);

  return (
    <>
      <FormItem name={name} label={labelNode} requiredErrorMessage={requiredErrorMessage} extraRules={newExtraRules}>
        <StyledFormInputNumber
          className={className}
          placeholder={placeholder}
          min={minValue}
          max={maxValue}
          formatter={formatter}
          parser={parser}
          precision={inputPrecision}
          disabled={disabled}
          onChange={onChange}
          {...props}
        />
      </FormItem>
    </>
  );
};

FormInputNumber.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  type: PropTypes.oneOf([INPUT_TYPE_FINANCIAL, INPUT_TYPE_PERCENTAGE, INPUT_TYPE_NUMBER]),
  placeholder: PropTypes.string,
  extraRules: PropTypes.array,
  isAllowNegative: PropTypes.bool,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  formatter: PropTypes.func,
  parser: PropTypes.func,
  precision: PropTypes.number,
  disabled: PropTypes.bool,
  requiredErrorMessage: PropTypes.string,
  onChange: PropTypes.func
};

FormInputNumber.defaultProps = {
  className: '',
  placeholder: '',
  extraRules: [],
  isAllowNegative: false,
  minValue: 0,
  formatter: undefined,
  parser: undefined,
  precision: 0,
  disabled: false,
  requiredErrorMessage: '',
  onChange: undefined
};

export default withAppContext(FormInputNumber);
