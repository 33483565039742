import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PASSWORD_REGEX } from 'utils/constants';

import { FormLabel, StyledFormItem } from './FormPasswordInput.styles';

const TYPE_CONFIRM_PASSWORD = 'confirm';

const PasswordInput = Input.Password;

const FormPasswordInput = ({
  disabled,
  extraProps,
  extraRules,
  label,
  name,
  onFocus,
  placeholder,
  checkPattern,
  customPattern,
  customPatternErrorMessage,
  requiredErrorMessage,
  type,
  compareField,
  dependencyField
}) => {
  const { t } = useTranslation(['compFormPasswordInput']);

  const generatePropsForRules = () => {
    const rules = [...extraRules];

    if (requiredErrorMessage && typeof requiredErrorMessage === 'string') {
      rules.push({
        required: true,
        message: requiredErrorMessage
      });
    }

    if (customPattern) {
      rules.push({
        pattern: customPattern,
        message: customPatternErrorMessage
      });
    } else if (checkPattern) {
      rules.push({
        pattern: PASSWORD_REGEX,
        message: t('compFormPasswordInput:rules-validation-error-message-format-requirement')
      });
    }

    if (type === TYPE_CONFIRM_PASSWORD) {
      rules.push(({ getFieldValue }) => ({
        validator: async (rule, value) => {
          if (!value || getFieldValue(compareField) === value) {
            return;
          } else {
            const err = new Error(t('compFormPasswordInput:rules-validation-error-message-confirm-password'));
            throw err;
          }
        },
        message: t('compFormPasswordInput:rules-validation-error-message-password-unmatched')
      }));
    }
    return rules;
  };
  return (
    <>
      {label && <FormLabel>{label}</FormLabel>}
      <StyledFormItem
        rules={generatePropsForRules()}
        name={name}
        onFocus={() => onFocus(name)}
        {...(dependencyField && { dependencies: [dependencyField] })}
      >
        <PasswordInput disabled={disabled} placeholder={placeholder} {...extraProps} autoComplete="new-password" />
      </StyledFormItem>
    </>
  );
};

FormPasswordInput.propTypes = {
  extraProps: PropTypes.object,
  onFocus: PropTypes.func,
  extraRules: PropTypes.array
};

FormPasswordInput.defaultProps = {
  extraProps: {},
  onFocus: () => {},
  extraRules: []
};

export default FormPasswordInput;
