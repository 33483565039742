import { CloseOutlined, DeleteOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Col, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { ActivateButton, ContentContainer, DeleteButton, DeactivateButton, TitleIcon, TitleText } from './QuickUpdateStatusModal.styles';

const QuickUpdateStatusModal = ({ visible, productsCount, onCancel, onDelete, onDeactivate, onActivate }) => {
  const { t } = useTranslation(['pageProduct']);

  return (
    <Modal visible={visible} width="fit-content" closable={false} destroyOnClose={true} footer={null} bodyStyle={{ padding: '32px 32px 24px' }}>
      <TitleIcon />
      <TitleText>{t('pageProduct:quick-update-modal-title-text', { itemCount: productsCount })}</TitleText>
      <ContentContainer gutter={[8, 8]} justify="end">
        <Col>
          <Button onClick={onCancel} icon={<CloseOutlined />}>
            {t('pageProduct:quick-update-modal-cancel-button')}
          </Button>
        </Col>
        <Col>
          <DeleteButton onClick={onDelete} icon={<DeleteOutlined />}>
            {t('pageProduct:quick-update-modal-delete-multiple-products', { itemCount: productsCount })}
          </DeleteButton>
        </Col>
        <Col>
          <DeactivateButton onClick={onDeactivate} icon={<EyeInvisibleOutlined />}>
            {t('pageProduct:quick-update-modal-deactivate-multiple-products', { itemCount: productsCount })}
          </DeactivateButton>
        </Col>
        <Col>
          <ActivateButton onClick={onActivate} icon={<EyeOutlined />}>
            {t('pageProduct:quick-update-modal-activate-multiple-products', { itemCount: productsCount })}
          </ActivateButton>
        </Col>
      </ContentContainer>
    </Modal>
  );
};

export default QuickUpdateStatusModal;
