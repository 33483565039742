import styled from '@emotion/styled';
import { Card, Row, Tabs } from 'antd';

export const StyledCard = styled(Card)`
  width: 100%;
`;

export const TabsContainer = styled(Tabs)`
  width: 100%;
`;

export const TitleContainer = styled(Row)`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const StyledTextLink = styled.span`
  color: ${props => props.theme.color.lightTertiary};
  text-decoration: none;
  cursor: pointer;
  padding: 8px 4px;
`;

export const CurrentPointsLabelText = styled.span`
  color: ${props => props.theme.color.grey};
`;

export const CurrentPointsValueText = styled.p`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 0;
`;

export const OrderProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
