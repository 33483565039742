import PropTypes from 'prop-types';

import Icon from './Icon';

import bulkPurchaseIcon from './icons/bulk-purchase.png';
import bulkPurchaseIconLinkType from './icons/bulk-purchase-link-type.png';

const ICON_STYLE_DEFAULT = 'default';
const ICON_STYLE_LINK = 'link';

const ICONS = {
  [ICON_STYLE_DEFAULT]: bulkPurchaseIcon,
  [ICON_STYLE_LINK]: bulkPurchaseIconLinkType
};

const BulkPurchaseIcon = ({ type = ICON_STYLE_DEFAULT, width, height, style, onClick, className }) => {
  return <Icon src={ICONS[type]} name="bulk-purchase" width={width} height={height} style={style} onClick={onClick} className={className} />;
};

BulkPurchaseIcon.propTypes = {
  type: PropTypes.oneOf([ICON_STYLE_DEFAULT, ICON_STYLE_LINK]),
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default BulkPurchaseIcon;
