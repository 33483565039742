import { ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Avatar, Col, message, Modal, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  useGetBlacklistedSocialMediaUsers,
  patchSocialMediaUserBlacklistStatus,
  useRefetchBlacklistedSocialMediaUserIds
} from 'apis/socialMediaUser';

import TextButton from 'components/TextButton/TextButton';

import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';
import { RootModal } from './BlacklistQuickViewModal.styles';

const { confirm, error } = Modal;

const constructColumns = ({ t, handleOnRemoveUserFromBlacklist }) => [
  {
    ...constructColumn(t('modalOverviewBlacklistedSocialMediaUsers:table-header-user-name'), 'name', { width: '60%' }),
    ...constructColumnFilterSearch('name', t('search name')),
    render: (text, record) => {
      return (
        <Row align="middle" gutter={8} wrap={false}>
          <Col>
            <Avatar shape="circle" src={record.profilePicUrl} />
          </Col>
          <Col>{record.name}</Col>
        </Row>
      );
    }
  },
  {
    ...constructColumn(t('modalOverviewBlacklistedSocialMediaUsers:table-header-user-source'), 'source', { width: '20%' })
  },

  {
    ...constructColumn(t('modalOverviewBlacklistedSocialMediaUsers:table-header-action'), 'action', { width: '20%' }),
    render: (text, record) => {
      return (
        <Row>
          <TextButton
            icon={<CloseOutlined />}
            text={t('modalOverviewBlacklistedSocialMediaUsers:table-action-button-remove')}
            onClick={() => handleOnRemoveUserFromBlacklist(record.userId, record.source)}
            tooltipMessage={t('modalOverviewBlacklistedSocialMediaUsers:table-action-tooltip-message-remove-user-from-blacklist', {
              action: t('modalOverviewBlacklistedSocialMediaUsers:table-action-tooltip-message-save-changes')
            })}
          />
        </Row>
      );
    }
  }
];

const BlacklistQuickViewModal = ({ visible, onClose }) => {
  const { t } = useTranslation(['common', 'modalOverviewBlacklistedSocialMediaUsers']);
  const { isLoading: isBlacklistedUsersLoading, data: blacklistedUsers, refetch: refetchBlacklistedUsers } = useGetBlacklistedSocialMediaUsers();

  const refetchBlacklistedSocialMediaUserIds = useRefetchBlacklistedSocialMediaUserIds();
  const isLoading = isBlacklistedUsersLoading;

  const handleOnRemoveUserFromBlacklist = (userId, source) => {
    confirm({
      title: t('modalOverviewBlacklistedSocialMediaUsers:confirm-modal-remove-user-from-blacklist-title'),
      content: t('modalOverviewBlacklistedSocialMediaUsers:confirm-modal-remove-user-from-blacklist-content'),
      okText: t('modalOverviewBlacklistedSocialMediaUsers:confirm-modal-remove-user-from-blacklist-ok-text'),
      cancelText: t('common:modal-cancel-text'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        patchSocialMediaUserBlacklistStatus(userId, source, false)
          .then(() => {
            message.success(t('common:update-success-message'));
            refetchBlacklistedUsers();
            refetchBlacklistedSocialMediaUserIds();
          })
          .catch(ex => {
            error({
              title: ex.message
            });
          });
      }
    });
  };

  return (
    <RootModal title={t('modalOverviewBlacklistedSocialMediaUsers:title')} visible={visible} onCancel={onClose} footer={null} destroyOnClose={true}>
      <Table
        size="small"
        loading={isLoading}
        rowKey={record => record._id}
        dataSource={blacklistedUsers}
        columns={constructColumns({
          t,
          handleOnRemoveUserFromBlacklist
        })}
        scroll={{ x: 'max-content' }}
        locale={{
          triggerDesc: t('common:table-header-sort-trigger-desc'),
          triggerAsc: t('common:table-header-sort-trigger-asc'),
          cancelSort: t('common:table-header-sort-cancel-sort')
        }}
        pagination={false}
      />
    </RootModal>
  );
};

export default BlacklistQuickViewModal;
