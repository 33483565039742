import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button } from 'antd';

const BUTTON_COLOR = {
  danger: 'red',
  highlight: 'secondary',
  success: 'green',
  default: 'lightTertiary'
};

const wordBreakStyle = css`
  height: fit-content;
  word-break: break-word;
  white-space: normal;
`;

export const StyledButton = styled(Button)`
  color: ${props => props.theme && props.theme.color && props.theme.color[BUTTON_COLOR[props.color]]};
  ${props => props.isWordBreak && wordBreakStyle}
`;
