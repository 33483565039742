import { forwardRef, memo, useMemo, useCallback, useImperativeHandle, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Slider, Modal } from 'antd';
import { controlStyle, buttonStyle } from './ImageCropper.styles';
import { ClassNames } from '@emotion/react';

const INIT_ZOOM = 1;
const ZOOM_STEP = 0.1;
const INIT_ROTATE = 0;
const ROTATE_STEP = 1;
const MIN_ROTATE = -180;
const MAX_ROTATE = 180;

const ImageCropper = forwardRef((props, ref) => {
  const {
    cropperRef,
    image,
    file,
    aspect,
    shape,
    grid = true,
    zoom = true,
    rotate = false,
    minZoom = 1,
    maxZoom = 3,
    fillColor = 'white',
    cropperProps,
    onCropOk,
    onCropCancel,
    modalTitle
  } = props;

  const [crop, onCropChange] = useState({ x: 0, y: 0 });
  const [cropSize, setCropSize] = useState({ width: 0, height: 0 });
  const [croppedArea, setCroppedArea] = useState();
  const [zoomVal, setZoomVal] = useState(INIT_ZOOM);
  const [rotateVal, setRotateVal] = useState(INIT_ROTATE);

  const title = useMemo(() => {
    if (modalTitle) {
      return modalTitle;
    }

    const lang = typeof window !== 'undefined' ? window.navigator.language : '';
    return lang === 'zh-CN' ? '编辑图片' : 'Edit image';
  }, [modalTitle]);

  useImperativeHandle(ref, () => ({
    rotateVal,
    setZoomVal,
    setRotateVal
  }));

  const handleMediaLoaded = useCallback(
    mediaSize => {
      const { width, height } = mediaSize;
      const ratioWidth = height * aspect;

      if (width > ratioWidth) {
        setCropSize({ width: ratioWidth, height });
      } else {
        setCropSize({ width, height: width / aspect });
      }
    },
    [aspect]
  );

  const handleCropUpdate = useCallback((_, croppedArea) => {
    setCroppedArea(croppedArea);
  }, []);

  const handleConfirmCrop = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const imgSource = document.querySelector('.photo-manager-image-crop-media');

    const { width: cropWidth, height: cropHeight, x: cropX, y: cropY } = croppedArea;

    if (rotate && rotateVal !== INIT_ROTATE) {
      const { naturalWidth: imgWidth, naturalHeight: imgHeight } = imgSource;
      const angle = rotateVal * (Math.PI / 180);

      // get container for rotated image
      const sine = Math.abs(Math.sin(angle));
      const cosine = Math.abs(Math.cos(angle));
      const squareWidth = imgWidth * cosine + imgHeight * sine;
      const squareHeight = imgHeight * cosine + imgWidth * sine;

      canvas.width = squareWidth;
      canvas.height = squareHeight;
      ctx.fillStyle = fillColor;
      ctx.fillRect(0, 0, squareWidth, squareHeight);

      // rotate container
      const squareHalfWidth = squareWidth / 2;
      const squareHalfHeight = squareHeight / 2;
      ctx.translate(squareHalfWidth, squareHalfHeight);
      ctx.rotate(angle);
      ctx.translate(-squareHalfWidth, -squareHalfHeight);

      // draw rotated image
      const imgX = (squareWidth - imgWidth) / 2;
      const imgY = (squareHeight - imgHeight) / 2;
      ctx.drawImage(imgSource, 0, 0, imgWidth, imgHeight, imgX, imgY, imgWidth, imgHeight);

      // crop rotated image
      const imgData = ctx.getImageData(0, 0, squareWidth, squareHeight);
      canvas.width = cropWidth;
      canvas.height = cropHeight;
      ctx.putImageData(imgData, -cropX, -cropY);
    } else {
      canvas.width = cropWidth;
      canvas.height = cropHeight;
      ctx.fillStyle = fillColor;
      ctx.fillRect(0, 0, cropWidth, cropHeight);

      ctx.drawImage(imgSource, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
    }

    // return cropped image when click ok on modal
    const { type, name } = file;
    canvas.toBlob(async blob => {
      const newFile = new File([blob], name, { type });
      onCropOk && onCropOk(newFile);
    }, type);
  };

  return (
    <ClassNames>
      {({ css }) => (
        <Modal title={title} visible={true} maskClosable={false} destroyOnClose onCancel={onCropCancel} onOk={handleConfirmCrop}>
          <Cropper
            {...cropperProps}
            ref={cropperRef}
            image={image}
            crop={crop}
            cropSize={cropSize}
            onCropChange={onCropChange}
            aspect={aspect}
            cropShape={shape}
            showGrid={grid}
            zoomWithScroll={zoom}
            zoom={zoomVal}
            rotation={rotateVal}
            onZoomChange={setZoomVal}
            onRotationChange={setRotateVal}
            minZoom={minZoom}
            maxZoom={maxZoom}
            onMediaLoaded={handleMediaLoaded}
            onCropComplete={handleCropUpdate}
            classes={{
              containerClassName: css({ position: 'relative !important', width: '100%', height: '40vh' }),
              mediaClassName: 'photo-manager-image-crop-media'
            }}
          />
          {zoom && (
            <section className={css(controlStyle)}>
              <button onClick={() => setZoomVal(zoomVal - ZOOM_STEP)} disabled={zoomVal - ZOOM_STEP < minZoom} className={css(buttonStyle)}>
                －
              </button>
              <Slider min={minZoom} max={maxZoom} step={ZOOM_STEP} value={zoomVal} onChange={setZoomVal} />
              <button onClick={() => setZoomVal(zoomVal + ZOOM_STEP)} disabled={zoomVal + ZOOM_STEP > maxZoom} className={css(buttonStyle)}>
                ＋
              </button>
            </section>
          )}
          {rotate && (
            <section className={css(controlStyle)}>
              <button onClick={() => setRotateVal(rotateVal - ROTATE_STEP)} disabled={rotateVal === MIN_ROTATE} className={css(buttonStyle)}>
                ↺
              </button>
              <Slider min={MIN_ROTATE} max={MAX_ROTATE} step={ROTATE_STEP} value={rotateVal} onChange={setRotateVal} />
              <button onClick={() => setRotateVal(rotateVal + ROTATE_STEP)} disabled={rotateVal === MAX_ROTATE} className={css(buttonStyle)}>
                ↻
              </button>
            </section>
          )}
        </Modal>
      )}
    </ClassNames>
  );
});

export default memo(ImageCropper);
