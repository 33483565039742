import styled from '@emotion/styled';

export const ContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 8px;

  * {
    color: #000 !important;
  }

  @media (min-width: 768px) {
    width: 768px;
  }

  @media print {
    page-break-after: always;

    &:last-child {
      page-break-after: auto;
    }
  }
`;

export const InvoiceTitle = styled.h1`
  font-size: 18px;
  font-weight: bold;
`;

export const CursiveText = styled.span`
  font-family: ${props => props.theme.fontFamily.title};
`;

export const StoreInfoContainer = styled.div`
  margin-bottom: 16px;
`;

export const StoreNameText = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
`;

export const StoreAddressText = styled.h3`
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0px;
`;
