import { useState } from 'react';
import { Button, message, Modal, Table } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { withAppContext } from 'contexts/AppContext/AppContext';

import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import TextButton from 'components/TextButton/TextButton';
import Title from 'components/Title/Title';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';

import { useGetPaginatedPromotions, patchPromotionStatus } from 'apis/promotion';

import { useFetchConstant } from 'hooks/constants';

import { formatToDateTimeWithPMAM } from 'utils/date';
import { getLabelOfConstant } from 'utils/general';
import { getPromotionDetailRoute, getNewPromotionRoute } from 'utils/routes';
import {
  constructColumn,
  constructColumnFilterSearch,
  handleOnAggregationTableChange,
  constructColumnFilterRadio,
  formatPaginationShowTotalDisplay
} from 'utils/table/table';

import { TitleContainer, StyledExpiryDateText } from './Promotions.styles';

const { confirm, error } = Modal;

const constructColumns = (
  t,
  storeCurrency,
  promotionTypes,
  promotionTypesConst,
  promotionStatuses,
  promotionStatusesConst,
  handleOnEditStatusClick
) => [
  {
    ...constructColumn(t('pagePromotion:table-header-name'), 'name', {
      linkFunc: record => getPromotionDetailRoute(record._id).path
    }),
    ...constructColumnFilterSearch('name', t('pagePromotion:table-header-action-search-promotion-name'), { hasAggregationFilter: true })
  },
  {
    ...constructColumn(t('pagePromotion:table-header-type'), 'type'),
    ...constructColumnFilterRadio('type', promotionTypes),
    render: type => getLabelOfConstant(type, promotionTypes)
  },
  {
    ...constructColumn(t('pagePromotion:table-header-quantity'), 'quantity')
  },
  {
    ...constructColumn(t('pagePromotion:table-header-code'), 'code'),
    ...constructColumnFilterSearch('code', t('pagePromotion:table-header-action-search-promotion-code'), { hasAggregationFilter: true })
  },
  {
    ...constructColumn(t('pagePromotion:table-header-discount-amount'), 'discountAmount', { width: '15%' }),
    render: (_, record) => {
      if (record.discountPercentage) {
        return `${record.discountPercentage}%`;
      }
      return <PriceDisplay storeCurrency={storeCurrency} amount={record.discountAmount} />;
    }
  },
  {
    ...constructColumn(t('pagePromotion:table-header-status'), 'status', { hasAggregationSorter: true, width: '22%' }),
    ...constructColumnFilterRadio('status', promotionStatuses),
    render: (status, record) => {
      return (
        <>
          <StatusIndicator isActive={record.status === promotionStatusesConst.ACTIVE.code} /> {getLabelOfConstant(status, promotionStatuses)}
          <StyledExpiryDateText>
            {t('pagePromotion:table-content-expiry-date', { expiryDate: formatToDateTimeWithPMAM(record.expiryDate) })}
          </StyledExpiryDateText>
        </>
      );
    }
  },
  {
    ...constructColumn(t('pagePromotion:table-header-actions'), 'action', { width: '15%' }),
    render: (_, record) => {
      const isActive = record.status === promotionStatusesConst.ACTIVE.code;
      return (
        <TextButton
          icon={isActive ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          onClick={() => handleOnEditStatusClick(isActive, record._id)}
          text={isActive ? t('pagePromotion:deactivate-button') : t('pagePromotion:activate-button')}
          tooltipMessage={t('common:text-button-tooltip-message', { action: t('pagePromotion:tooltip-message-update-status') })}
        />
      );
    }
  }
];

const Promotions = ({ storeCurrency }) => {
  const { t } = useTranslation(['common', 'pagePromotion']);
  const history = useHistory();
  const [query, setQuery] = useState({ currentPage: 1, limit: 10 });

  const { isLoading: isPromotionsLoading, paginatedData: promotions, total, refetch: refetchPromotions } = useGetPaginatedPromotions(query);
  const { selection: promotionTypes, data: promotionTypesConst, isLoading: isPromotionTypesLoading } = useFetchConstant('promotionTypes');
  const { selection: promotionStatuses, data: promotionStatusesConst, isLoading: isPromotionStatusesLoading } = useFetchConstant('promotionStatuses');

  const isLoading = isPromotionsLoading || isPromotionTypesLoading || isPromotionStatusesLoading;

  const handleOnEditStatusClick = (isActive, promotionId) => {
    const status = isActive ? promotionStatusesConst.INACTIVE.code : promotionStatusesConst.ACTIVE.code;
    const title = isActive ? t('pagePromotion:confirm-modal-deactivate-status-title') : t('pagePromotion:confirm-modal-activate-status-title');
    const okText = isActive ? t('pagePromotion:confirm-modal-dectivate-button-text') : t('pagePromotion:confirm-modal-activate-button-text');

    confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      okText,
      cancelText: t('common:modal-cancel-text'),
      onOk() {
        return patchPromotionStatus(promotionId, status)
          .then(() => {
            message.success(t('common:update-success-message'));
            refetchPromotions();
          })
          .catch(ex => {
            error({
              title: ex.message
            });
          });
      }
    });
  };

  return (
    <FullWidthContainer>
      <TitleContainer justify="space-between">
        <Title>{t('pagePromotion:title')}</Title>
        <Button type="primary" shape="round" icon={<PlusOutlined />} onClick={() => history.push(getNewPromotionRoute().path)}>
          {t('pagePromotion:add-new-promotion-button')}
        </Button>
      </TitleContainer>
      <Table
        size="small"
        loading={isLoading}
        rowKey={record => record._id}
        dataSource={promotions}
        columns={
          !isLoading
            ? constructColumns(
                t,
                storeCurrency,
                promotionTypes,
                promotionTypesConst,
                promotionStatuses,
                promotionStatusesConst,
                handleOnEditStatusClick
              )
            : []
        }
        scroll={{ x: 1000 }}
        locale={{
          triggerDesc: t('common:table-header-sort-trigger-desc'),
          triggerAsc: t('common:table-header-sort-trigger-asc'),
          cancelSort: t('common:table-header-sort-cancel-sort')
        }}
        pagination={{ total, showSizeChanger: true, showTotal: formatPaginationShowTotalDisplay }}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
      />
    </FullWidthContainer>
  );
};

export default withAppContext(Promotions);
