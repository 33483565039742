import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'components/FormItem/FormItem';

import Upload from '../Upload';

// accept ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
const FormUpload = ({
  name,
  label,
  defaultValue,
  buttonLabel,
  requiredErrorMessage,
  acceptTypes,
  fileSizeLimitInMb,

  isDisabled,
  isMultiple,
  isDisplayCard,

  onChange,
  onFileUploadChange,

  onClick,

  cropAspect,
  ...props
}) => {
  return (
    <FormItem name={name} label={label} requiredErrorMessage={requiredErrorMessage} defaultValue={defaultValue}>
      <Upload
        buttonLabel={buttonLabel}
        acceptTypes={acceptTypes}
        fileSizeLimitInMb={fileSizeLimitInMb}
        cropAspect={cropAspect}
        isDisabled={isDisabled}
        isMultiple={isMultiple}
        isDisplayCard={isDisplayCard}
        onChange={onChange}
        onFileUploadChange={onFileUploadChange}
        onClick={onClick}
        {...props}
      />
    </FormItem>
  );
};

FormUpload.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  requiredErrorMessage: PropTypes.string,
  defaultValue: PropTypes.object,
  acceptTypes: PropTypes.array,
  fileSizeLimitInMb: PropTypes.number,

  cropAspect: PropTypes.number,

  isDisabled: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isDisplayCard: PropTypes.bool,

  onChange: PropTypes.func,
  onFileUploadChange: PropTypes.func
};

export default FormUpload;
