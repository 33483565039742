import React, { useState, useMemo, useEffect, useCallback } from 'react';

import moment from 'moment';
import { Button, Col, Row, Space, message, Empty, Skeleton } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { getReportingData } from 'apis/reporting';
import { formatToDateString, getTodayMoment, getCurrentMonth, getCurrentMonthEnd, getDateFromDays, formatToMomentObject } from 'utils/date';

import Revenue from './components/Revenue/Revenue';
import RevenuePieCharts from './components/RevenuePieCharts/RevenuePieCharts';
import ProductsSoldBar from './components/ProductsSoldBar/ProductsSoldBar';
import ProductsSoldTable from './components/ProductsSoldTable/ProductsSoldTable';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';

const OrderPurchaseDate = ({ storeCurrency, securityPasscode, isPasscodeModalVisible, handleOnValidateSecurityPasscode }) => {
  const [isInit, setIsInit] = useState(false);
  const [dataQuery, setDataQuery] = useState(null);
  const [reportingData, setReportingData] = useState(null);
  const [isLoadingReportingData, setIsLoadingReportingData] = useState(false);

  const [dateQuery, setDateQuery] = useState({ startDate: formatToDateString(getDateFromDays(-6)), endDate: formatToDateString(getTodayMoment()) });
  const dateRangeValues = useMemo(() => [formatToMomentObject(dateQuery.startDate), formatToMomentObject(dateQuery.endDate)], [dateQuery]);

  const handleOnClickSearch = () => {
    setReportingData(null);
    setIsLoadingReportingData(true);
    getFormattedReportingData();
  };

  const getFormattedReportingData = useCallback(
    async passcode => {
      try {
        const dataQuery = {
          startDate: dateQuery.startDate,
          endDate: dateQuery.endDate,
          securityPasscode: passcode || securityPasscode
        };
        setDataQuery(dataQuery);

        const reportingData = await getReportingData(dataQuery);

        setReportingData(reportingData);
        setIsLoadingReportingData(false);
        handleOnValidateSecurityPasscode(true);
      } catch (ex) {
        setIsLoadingReportingData(false);
        handleOnValidateSecurityPasscode(false);
        message.error(ex.message);
      }
    },
    [dateQuery, securityPasscode, handleOnValidateSecurityPasscode]
  );

  useEffect(() => {
    if (!isInit) {
      setIsInit(true);
      setIsLoadingReportingData(true);
      getFormattedReportingData(securityPasscode);
    }
  }, [isInit, securityPasscode, getFormattedReportingData]);

  return (
    <FullWidthContainer>
      <Row justify="end" style={{ marginBottom: '16px' }}>
        <Space>
          <DateRangePicker
            value={dateRangeValues}
            onChange={dates => dates && setDateQuery({ startDate: formatToDateString(dates[0]), endDate: formatToDateString(dates[1]) })}
            allowClear={false}
            extraDisabledDate={current => current > moment().endOf('day') || current.diff(dateQuery.startDate, 'days') > 30}
            ranges={{
              Today: [getTodayMoment(), getTodayMoment()],
              'This Month': [getCurrentMonth(), getCurrentMonthEnd()],
              'Previous 30 days': [getDateFromDays(-30), getTodayMoment()]
            }}
          />
          <Button style={{ marginLeft: 10 }} type="primary" loading={isLoadingReportingData} onClick={handleOnClickSearch}>
            <SearchOutlined />
          </Button>
        </Space>
      </Row>

      {isPasscodeModalVisible || isLoadingReportingData ? (
        <Skeleton active />
      ) : !reportingData || (reportingData && reportingData.numOfOrders === 0) ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginTop: 100 }} />
      ) : (
        <Row gutter={24}>
          <Col span={24}>
            <Revenue reportingData={reportingData} storeCurrency={storeCurrency} />
          </Col>
          <Col span={24}>
            <RevenuePieCharts reportingData={reportingData} storeCurrency={storeCurrency} />
          </Col>
          <Col span={24}>
            <ProductsSoldBar reportingData={reportingData} />
          </Col>
          <Col span={24} style={{ marginTop: 30 }}>
            <ProductsSoldTable reportingData={reportingData} query={dataQuery} />
          </Col>
        </Row>
      )}
    </FullWidthContainer>
  );
};

export default OrderPurchaseDate;
