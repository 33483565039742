import { useState, useMemo } from 'react';
import { Avatar, Col, Form, List, Modal, Row, Skeleton, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import { useGetProductSuggestions } from 'apis/product';

import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import {
  PostWinnerToFacebookCheckbox,
  StyledWinnerNameList,
  StyledAvatarWinnerInitial,
  StyledWinnerNameText,
  StyledRadioGroup,
  StyledRadioButton
} from './PrizeRemarksModal.styles';

const { useForm } = Form;

const PRIZE_TYPE_REMARKS = 'remarks';
const PRIZE_TYPE_PRIZE = 'prize';

const WinnerNameList = ({ winners }) => {
  return (
    <div>
      <br />
      <StyledWinnerNameList
        bordered
        size="small"
        itemLayout="horizontal"
        dataSource={winners}
        renderItem={winner => (
          <List.Item>
            <List.Item.Meta
              avatar={
                winner.profilePicUrl ? (
                  <Avatar src={winner.profilePicUrl} />
                ) : (
                  <StyledAvatarWinnerInitial>{winner.name.charAt(0).toUpperCase()}</StyledAvatarWinnerInitial>
                )
              }
              title={<StyledWinnerNameText>{winner.name}</StyledWinnerNameText>}
            />
          </List.Item>
        )}
      />
      <br />
    </div>
  );
};

const PrizeRemarksModal = ({
  visible,
  isLoading,
  isSubmitting,
  isAllowPrize,
  isAllowPostSocialMedia,
  winners,
  productTypesConst,
  onCancel,
  onOk
}) => {
  const { t } = useTranslation(['common', 'pageLuckyDrawSlotMachine']);
  const [form] = useForm();
  const [selectedPrizeType, setSelectedPrizeType] = useState(PRIZE_TYPE_REMARKS);
  const [isPostWinnerToFBChecked, setIsPostWinnerToFBChecked] = useState(isAllowPostSocialMedia);
  const [searchKeyword, setSearchKeyword] = useState('');
  const delaySetSearchKeyword = useMemo(() => debounce(setSearchKeyword, 300), []);
  const { isLoading: isProductSuggestionsLoading, paginatedData: productSuggestions } = useGetProductSuggestions({
    limit: 100,
    filter: { label: searchKeyword },
    extraQuery: { flatVariance: true, productType: productTypesConst.GIFT.code }
  });

  const handleOnConfirmToAccept = () => {
    const winnerName = winners.length > 1 ? winners.map(winner => winner.name).join(', ') : winners[0].name;
    form
      .validateFields()
      .then(values => {
        const isSelectedPrizeProduct = selectedPrizeType === PRIZE_TYPE_PRIZE;
        const prizeProduct = isSelectedPrizeProduct && productSuggestions.find(product => product.value === values.prizeProductId);
        const prizeRemarks = isSelectedPrizeProduct ? `1 x ${prizeProduct.label}` : values.prizeRemarks;

        onOk({
          selectedPrizeType,
          prizeRemarks,
          prizeProductKeyword: prizeProduct.orderKeyword,
          isPostingWinnerToFB: isPostWinnerToFBChecked,
          winnerName
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handleOnIsPostWinnerToFBCheck = () => {
    setIsPostWinnerToFBChecked(prevState => !prevState);
  };

  const formattedProductSuggestions = useMemo(() => {
    return !productSuggestions
      ? []
      : productSuggestions.map(product => {
          const isEnoughInventory = winners.length <= product.inventory;
          return {
            ...product,
            label: !isEnoughInventory && !product.disabled ? `${product.label} (Not enough inventory)` : product.label,
            disabled: !isEnoughInventory || product.disabled
          };
        });
  }, [productSuggestions, winners]);

  return (
    <Modal
      visible={visible}
      okText={t('pageLuckyDrawSlotMachine:prize-remarks-modal-form-ok-button')}
      cancelText={t('common:modal-cancel-text')}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      cancelButtonProps={{ loading: isSubmitting }}
      onOk={handleOnConfirmToAccept}
      footer={undefined}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form form={form} scrollToFirstError={true} style={{ width: '100%' }}>
          {winners && winners.length > 1 && <WinnerNameList winners={winners} />}
          <Row gutter={16}>
            <Col span={24} style={{ marginBottom: 12 }}>
              <StyledRadioGroup
                defaultValue={selectedPrizeType}
                onChange={event => {
                  setSelectedPrizeType(event.target.value);
                }}
                buttonStyle="solid"
              >
                <StyledRadioButton value={PRIZE_TYPE_REMARKS}>
                  {t('pageLuckyDrawSlotMachine:prize-remarks-modal-form-input-remarks-label')}
                </StyledRadioButton>
                <StyledRadioButton value={PRIZE_TYPE_PRIZE}>
                  {t('pageLuckyDrawSlotMachine:prize-remarks-modal-form-input-prize-label')}
                </StyledRadioButton>
              </StyledRadioGroup>
            </Col>
            {selectedPrizeType === PRIZE_TYPE_REMARKS && (
              <Col span={24}>
                <FormInput
                  name="prizeRemarks"
                  placeholder="e.g.: 2 x Lay's Classic Potata Chips"
                  type="textArea"
                  rows={8}
                  requiredErrorMessage={t('pageLuckyDrawSlotMachine:prize-remarks-modal-form-input-remarks-required-message')}
                />
              </Col>
            )}
            {selectedPrizeType === PRIZE_TYPE_PRIZE && (
              <Col span={24}>
                {!isAllowPrize && (
                  <Alert
                    message={t('pageLuckyDrawSlotMachine:prize-remarks-modal-form-warning-banner-text')}
                    type="warning"
                    showIcon
                    style={{ marginBottom: '12px' }}
                  />
                )}
                <FormSelection
                  name="prizeProductId"
                  selections={formattedProductSuggestions}
                  isSearching={isProductSuggestionsLoading}
                  onSearch={delaySetSearchKeyword}
                  disabled={!isAllowPrize}
                  requiredErrorMessage={t('pageLuckyDrawSlotMachine:prize-remarks-modal-form-input-prize-required-message')}
                />
              </Col>
            )}
            {isAllowPostSocialMedia && (
              <Col>
                <PostWinnerToFacebookCheckbox
                  onChange={handleOnIsPostWinnerToFBCheck}
                  defaultValue={isPostWinnerToFBChecked}
                  value={isPostWinnerToFBChecked}
                  checked={isPostWinnerToFBChecked}
                >
                  {t('pageLuckyDrawSlotMachine:prize-remarks-modal-form-checkbox-post-fb')}
                </PostWinnerToFacebookCheckbox>
              </Col>
            )}
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default PrizeRemarksModal;
