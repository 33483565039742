import { useEffect, useState, useCallback } from 'react';
import { InfoCircleFilled, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Modal, Row, Skeleton, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetProductDetails } from 'apis/product';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import Variances from 'components/Variances/Variances';

const { useForm } = Form;

const QuickEditModal = ({ visible, productId, productTypesConst, onClose, onOk }) => {
  const { t } = useTranslation(['pageProduct']);
  const [form] = useForm();
  const [isLocked, setIsLocked] = useState(true);
  const [hasAddedVariance, setHasAddedVariance] = useState(false);

  const { isLoading: isProductLoading, data: product } = useGetProductDetails(productId);

  const isNormalProduct = product.type === productTypesConst.NORMAL.code;
  const shouldDisplayMainFields = (!product.variances || product.variances.length === 0) && !hasAddedVariance;

  useEffect(() => {
    form.setFieldsValue(product);
  }, [form, product]);

  const handleOnSave = useCallback(() => {
    form
      .validateFields()
      .then(values => {
        onOk({ ...values, shouldUpdateProductInventory: !isLocked });
      })
      .catch(() => {});
  }, [form, isLocked, onOk]);

  return (
    <Modal
      title={t('pageProduct:quick-edit-modal-title', { productLabel: product.label })}
      visible={visible || !isProductLoading}
      onOk={handleOnSave}
      onCancel={onClose}
      width={product.variances && product.variances.length > 0 ? 'fit-content' : '70vw'}
      destroyOnClose={true}
    >
      <Form form={form} initialValues={product} scrollToFirstError={true} style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={24} lg={12}>
            <FormInput
              label={t('pageProduct:quick-edit-modal-form-input-label-product-keyword')}
              name="keyword"
              type="keyword"
              placeholder={t('pageProduct:quick-edit-modal-form-input-placeholder-product-keyword')}
              requiredErrorMessage={t('pageProduct:quick-edit-modal-form-input-required-message-product-keyword')}
            />
          </Col>
          {shouldDisplayMainFields && (
            <Col span={24} lg={12}>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ width: '90%' }}>
                  <FormInputNumber
                    label={t('pageProduct:quick-edit-modal-form-input-label-product-inventory')}
                    name="inventory"
                    isAllowNegative={isNormalProduct}
                    minValue={isNormalProduct ? Number.MIN_SAFE_INTEGER : 0}
                    placeholder={t('pageProduct:quick-edit-modal-form-input-placeholder-product-inventory')}
                    requiredErrorMessage={t('pageProduct:quick-edit-modal-form-input-required-message-product-inventory')}
                    disabled={isLocked}
                  />
                </div>
                <Button
                  type="text"
                  icon={isLocked ? <LockOutlined /> : <UnlockOutlined />}
                  style={{ marginLeft: '4px', marginTop: '8px' }}
                  onClick={() => setIsLocked(!isLocked)}
                />
              </div>
            </Col>
          )}
          {shouldDisplayMainFields && isNormalProduct && (
            <Col span={24} lg={12}>
              <FormInputNumber
                label={t('pageProduct:quick-edit-modal-form-input-label-product-price')}
                name="price"
                type="financial"
                placeholder={t('pageProduct:quick-edit-modal-form-input-placeholder-product-price')}
                requiredErrorMessage={t('pageProduct:quick-edit-modal-form-input-required-message-product-price')}
              />
            </Col>
          )}
          {shouldDisplayMainFields && (
            <Col span={24} lg={12}>
              <FormInputNumber
                label={t('pageProduct:quick-edit-modal-form-input-label-product-cost')}
                name="cost"
                type="financial"
                placeholder="Cost"
              />
            </Col>
          )}
          {shouldDisplayMainFields && isNormalProduct && (
            <Col span={24} lg={12}>
              <FormInputNumber
                label={
                  <span>
                    {t('pageProduct:quick-edit-modal-form-input-label-product-purchase-limit')}{' '}
                    <Tooltip title={t('pageProduct:quick-edit-modal-form-input-label-product-purchase-limit-tips')}>
                      <InfoCircleFilled />
                    </Tooltip>
                  </span>
                }
                name="purchaseLimit"
                placeholder={t('pageProduct:quick-edit-modal-form-input-placeholder-product-purchase-limit')}
              />
            </Col>
          )}
        </Row>
      </Form>
      {isProductLoading && product ? (
        <Skeleton active />
      ) : (
        <Row>
          <Col span={24} xl={0}>
            <Card>
              <Variances
                productId={productId}
                product={product}
                variances={product.variances}
                isMobileView={true}
                isQuickEdit={true}
                isNormalProduct={isNormalProduct}
                onAddSuccess={isSuccess => setHasAddedVariance(isSuccess)}
              />
            </Card>
          </Col>
          <Col span={0} xl={24}>
            <Variances
              productId={productId}
              product={product}
              variances={product.variances}
              isQuickEdit={true}
              isNormalProduct={isNormalProduct}
              onAddSuccess={isSuccess => setHasAddedVariance(isSuccess)}
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default QuickEditModal;
