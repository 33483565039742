import React from 'react';
import { message, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { withAppContext } from 'contexts/AppContext/AppContext';
import { getLoginRoute, get403Route, getOverviewRoute, getAccountProfileRoute, getNewOrderRoute, getSubscriptionPlanRoute } from 'utils/routes';

const loginRoute = getLoginRoute();
const forbiddenRoute = get403Route();
const overviewRoute = getOverviewRoute();
const accountAndProfileRoute = getAccountProfileRoute();
const newOrderRoute = getNewOrderRoute();
const subscriptionPlanRoute = getSubscriptionPlanRoute();

const ProtectedRoute = ({
  accessRole,
  checkCanUserAccess,
  isLoggedIn,
  isLoadingAppContext,
  isStoreLoading,
  component,
  path,
  isStoreConnectedToFb,
  isMalaysiaLocation,
  store: {
    subscriptionConfig: { canCreateOrder, isSubscriptionStarter, isSubscriptionPaidStarter }
  },
  ...otherRouteProps
}) => {
  const isNoFbUserAllowedPath = path === accountAndProfileRoute.path || path === overviewRoute.path;
  const canUserAccessForFb = isStoreConnectedToFb || isNoFbUserAllowedPath || !(isSubscriptionStarter || isSubscriptionPaidStarter);

  const isNoCreateOrderUserRestrictedPath = path === newOrderRoute.path;
  const canUserAccessForCreateOrder = canCreateOrder || !isNoCreateOrderUserRestrictedPath;

  const canUserAccessForSubscriptionPlan = isMalaysiaLocation || path !== subscriptionPlanRoute.path;

  const isLoading = isLoadingAppContext || isStoreLoading;

  if (isLoading) {
    return <Skeleton loading={isLoading} />;
  } else if (!isLoggedIn) {
    message.error('Please login before accessing this page');
    return <Redirect to={loginRoute.path} />;
  } else if (!checkCanUserAccess(accessRole) || !canUserAccessForFb || !canUserAccessForCreateOrder || !canUserAccessForSubscriptionPlan) {
    message.error('You are not allowed to access this page');
    return <Redirect to={forbiddenRoute.path} />;
  } else {
    return <Route path={path} component={component} {...otherRouteProps} />;
  }
};

ProtectedRoute.propTypes = {
  accessRole: PropTypes.array,
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  checkCanUserAccess: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isLoadingAppContext: PropTypes.bool.isRequired
};

export default withAppContext(ProtectedRoute);
