import styled from '@emotion/styled';

export const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: start;
`;

export const StyledOrderLimit = styled.div`
  margin-bottom: 20px;
`;
