import styled from '@emotion/styled';

export const LabelContainer = styled.div`
  background-color: ${props => (props.showError ? '#ff4d4f' : '#f0f0f0')};
  border-radius: 4px;
  padding: 8px;
  text-align: center;
  margin-bottom: 24px;
`;

export const LabelText = styled.h3`
  font-size: 12px;
  line-height: 24px;
  color: ${props => (props.showError ? '#ffffff' : '#9b9b9b')};
  margin-bottom: 0;
  text-align: center;

  @media (min-width: 414px) {
    font-size: 14px;
  }

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;
