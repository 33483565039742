import AntDCom from 'components/AntdComp/AntComp';
import FormInput from 'components/FormInput/FormInput';

const LiveSessions = () => {
  return (
    <div>
      <h1>Live Sessions</h1>
      <div>Below is a direct Import Button from AntD:</div>
      <div>
        <AntDCom />
      </div>
      <div>Below is a wrapped AntD Input:</div>
      <div>
        <FormInput />
      </div>
    </div>
  );
};

export default LiveSessions;
