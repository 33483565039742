import { useMemo, useState } from 'react';
import { Modal, List } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import { useGetProductInventoryLogs } from 'apis/product';

import FormSelection from 'components/FormSelection/FormSelection';

import { formatToDateTimeWithPMAM } from 'utils/date';

import { InventoryGreenText, InventoryRedText, StyledListItemMeta, StyledAvatarWithName } from './InventoryChangeLogsModal.styles';

const InventoryChangeLogsModal = ({ productId, productLabel, variances, onClose }) => {
  const { t } = useTranslation(['common', 'pageProductDetails']);
  const [paginationQuery, setPaginationQuery] = useState({ currentPage: 1, limit: 10 });
  const [selectedVariance, setSelectedVariance] = useState(variances.length > 0 ? variances[0]._id : '');

  const { isLoading, paginatedData: productInventoryLogs, total } = useGetProductInventoryLogs({
    ...paginationQuery,
    filter: { productId, ...(selectedVariance && { varianceId: selectedVariance }) }
  });

  const varianceSelections = useMemo(() => {
    return variances.map(variance => {
      return {
        value: variance._id,
        label: variance.label
      };
    });
  }, [variances]);

  return (
    <Modal width={768} title={t('pageProductDetails:inventory-change-logs-title', { productLabel })} visible onCancel={onClose} footer={null}>
      {variances.length > 0 && (
        <FormSelection
          selections={varianceSelections}
          value={selectedVariance}
          onChange={value => {
            setSelectedVariance(value);
          }}
        />
      )}

      <List
        loading={isLoading}
        itemLayout="vertical"
        pagination={{
          total,
          size: 'small',
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          onChange: (page, pageSize) => {
            setPaginationQuery({ currentPage: page, limit: pageSize });
          }
        }}
        dataSource={productInventoryLogs}
        renderItem={(item, index) => (
          <List.Item key={item._id} style={{ ...(index === 0 && { paddingTop: 0 }) }}>
            <StyledListItemMeta
              avatar={<StyledAvatarWithName>{item.userName.charAt(0).toUpperCase()}</StyledAvatarWithName>}
              title={item.userName}
              description={formatToDateTimeWithPMAM(item.createdAt)}
            />

            {!item.oldInventory && item.oldInventory !== 0 ? (
              <Trans i18nKey="pageProductDetails:inventory-change-logs-create-details" values={{ newInventory: item.newInventory }}>
                Create inventory <InventoryGreenText>newInventory</InventoryGreenText>}
              </Trans>
            ) : (
              <Trans
                i18nKey="pageProductDetails:inventory-change-logs-update-details"
                values={{ oldInventory: item.oldInventory, newInventory: item.newInventory }}
              >
                Update Inventory from <span>oldInventory</span> to
                {item.newInventory > item.oldInventory ? (
                  <InventoryGreenText>newInventory</InventoryGreenText>
                ) : (
                  <InventoryRedText>newInventory</InventoryRedText>
                )}
              </Trans>
            )}
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default InventoryChangeLogsModal;
