import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space, Modal, Form, Tabs, message } from 'antd';

import { withAppContext } from 'contexts/AppContext/AppContext';
import { useLocationQuery } from 'hooks/router';
import { PASSCODE_REGEX, reportingTabsObj } from 'utils/constants';
import { guard } from 'utils/general';
import { getReportingRoute, getReportingOrderPurchaseDateRoute } from 'utils/routes';

import { patchMyStoreConfig } from 'apis/user';

import TextButton from 'components/TextButton/TextButton';
import FormPasswordInput from 'components/FormPasswordInput/FormPasswordInput';
import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';

import OrderPurchaseDate from './components/OrderPurchaseDate/OrderPurchaseDate';
import ProductPurchaseDate from './components/ProductPurchaseDate/ProductPurchaseDate';
import { TabsContainer } from './Reporting.styles';

const { useForm } = Form;
const { TabPane } = Tabs;
const { confirm } = Modal;

const useTab = () => {
  const query = useLocationQuery();
  const history = useHistory();
  const selectedTab = guard(() => query.tab);

  useEffect(() => {
    if (!selectedTab) {
      history.replace(getReportingOrderPurchaseDateRoute().path);
    }
  }, [selectedTab, history]);

  const handleOnTabChange = newTab => {
    history.push(getReportingRoute(newTab).path);
  };

  return { selectedTab, handleOnTabChange };
};

const SetDefaultButton = ({ t, onClick }) => {
  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'end', marginBottom: '8px' }}>
      <TextButton onClick={onClick} text={t('pageReporting:set-default-button-text')} style={{ padding: 0 }} />
    </div>
  );
};

const Reporting = ({ store, storeCurrency, refetchStore }) => {
  const history = useHistory();
  const [form] = useForm();
  const { t } = useTranslation(['common', 'pageReporting']);
  const [isInit, setIsInit] = useState(false);
  const [securityPasscode, setSecurityPasscode] = useState(null);
  const [isLoadingPasscode, setIsLoadingPasscode] = useState(false);
  const [isPasscodeModalVisible, setIsPasscodeModalVisible] = useState(false);
  const [isEnableGetData, setIsEnableGetData] = useState(false);

  const { selectedTab, handleOnTabChange } = useTab();

  const handleOnClickConfirm = async () => {
    const values = await form.validateFields(['securityPasscode']);
    setIsLoadingPasscode(true);
    setSecurityPasscode(values.securityPasscode);
    setIsEnableGetData(true);
  };

  useEffect(() => {
    if (!isInit && store && store._id) {
      setIsInit(true);

      if (!selectedTab && store.isDefaultFilterProductPurchaseDate) {
        handleOnTabChange(reportingTabsObj.PRODUCT_PURCHASE_DATE.code);
      }

      if (store.securityConfig && store.securityConfig.hasSecurityPasscode) {
        setIsPasscodeModalVisible(true);
      } else {
        setIsEnableGetData(true);
      }
    }
  }, [isInit, store, selectedTab, handleOnTabChange]);

  const handleOnValidateSecurityPasscode = isSuccess => {
    setIsLoadingPasscode(false);
    if (isSuccess) {
      setIsPasscodeModalVisible(false);
    } else {
      setIsEnableGetData(false);
    }
  };

  const handleOnFormKeyUp = event => {
    if (event.keyCode === 13) {
      handleOnClickConfirm();
    }
  };

  const handleOnSetDefaultClick = () => {
    confirm({
      title: t('pageReporting:set-default-alert-title'),
      content: t('pageReporting:set-default-alert-desc'),
      okText: t('common:modal-ok-text'),
      cancelText: t('common:modal-cancel-text'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        patchMyStoreConfig({
          isDefaultFilterProductPurchaseDate: !store.isDefaultFilterProductPurchaseDate
        })
          .then(() => {
            message.success(t('common:update-success-message'));
            refetchStore();
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.log(error);
            message.error(t('common:update-fail-message'));
          });
      }
    });
  };

  return (
    <FullWidthContainer>
      <Modal
        width="80%"
        style={{ maxWidth: '560px' }}
        visible={isPasscodeModalVisible}
        onCancel={() => history.goBack()}
        footer={
          <Space>
            <Button style={{ marginLeft: 10 }} type="primary" loading={isLoadingPasscode} onClick={handleOnClickConfirm}>
              {t('pageReporting:passcode-modal-ok-button')}
            </Button>
          </Space>
        }
      >
        <Row>
          <Col span={24}>
            <Form form={form} onKeyUp={handleOnFormKeyUp}>
              <FormPasswordInput
                label={t('pageOrder:form-input-label-security-passcode')}
                name="securityPasscode"
                placeholder={t('pageOrder:form-input-placeholder-security-passcode')}
                requiredErrorMessage={t('pageOrder:form-input-required-message-security-passcode')}
                customPattern={PASSCODE_REGEX}
                customPatternErrorMessage={t('pageOrder:form-input-invalid-pattern-message-security-passcode')}
              />
            </Form>
          </Col>
        </Row>
      </Modal>

      {isEnableGetData && (
        <TabsContainer activeKey={selectedTab} destroyInactiveTabPane onChange={handleOnTabChange}>
          <TabPane tab={t('pageReporting:tab-title-order-purchase-date')} key={reportingTabsObj.ORDER_PURCHASE_DATE.code}>
            <>
              {selectedTab === reportingTabsObj.ORDER_PURCHASE_DATE.code ? (
                <Helmet>
                  <meta name="title" id="gtm-title" content="Reporting | Order Purchase Date" />
                  <title>{t('pageReporting:page-title-order-purchase-date')}</title>
                </Helmet>
              ) : undefined}
              {store.isDefaultFilterProductPurchaseDate && <SetDefaultButton t={t} onClick={handleOnSetDefaultClick} />}
              <OrderPurchaseDate
                storeCurrency={storeCurrency}
                securityPasscode={securityPasscode}
                isPasscodeModalVisible={isPasscodeModalVisible}
                handleOnValidateSecurityPasscode={handleOnValidateSecurityPasscode}
              />
            </>
          </TabPane>
          <TabPane tab={t('pageReporting:tab-title-product-purchase-date')} key={reportingTabsObj.PRODUCT_PURCHASE_DATE.code}>
            {selectedTab === reportingTabsObj.PRODUCT_PURCHASE_DATE.code ? (
              <Helmet>
                <meta name="title" id="gtm-title" content="Reporting | Product Purchase Date" />
                <title>{t('pageReporting:page-title-product-purchase-date')}</title>
              </Helmet>
            ) : undefined}
            {!store.isDefaultFilterProductPurchaseDate && <SetDefaultButton t={t} onClick={handleOnSetDefaultClick} />}
            <ProductPurchaseDate
              storeCurrency={storeCurrency}
              securityPasscode={securityPasscode}
              isPasscodeModalVisible={isPasscodeModalVisible}
              handleOnValidateSecurityPasscode={handleOnValidateSecurityPasscode}
            />
          </TabPane>
        </TabsContainer>
      )}
    </FullWidthContainer>
  );
};

export default withAppContext(Reporting);
