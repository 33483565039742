import styled from '@emotion/styled';
import { Card, Row } from 'antd';

export const TitleContainer = styled(Row)`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const PlaceholderMessageSignature = styled.span`
  font-family: ${props => props.theme.fontFamily.title};
  font-weight: bold;
`;

export const StatisticLimitContainer = styled(Card)`
  border: 1px solid ${props => props.theme.color.primary} !important;
  color: ${props => props.theme.color.white} !important;
  background-color: ${props => props.theme.color.primary};
  text-align: center;
`;

export const StatisticFollowersContainer = styled(Card)`
  border: 1px solid ${props => props.theme.color.secondary} !important;
  color: ${props => props.theme.color.white} !important;
  background-color: ${props => props.theme.color.secondary};
  text-align: center;
`;

export const StatisticDeliveredContainer = styled(Card)`
  border: 1px solid ${props => props.theme.color.tertiary} !important;
  color: ${props => props.theme.color.white} !important;
  background-color: ${props => props.theme.color.tertiary};
  text-align: center;
`;

export const StatisticLabel = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const StatisticValue = styled.p`
  font-size: 32px;
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-bottom: 0;
`;

export const UliveText = styled.span`
  font-size: 20px;
  font-family: ${props => props.theme.fontFamily.title};
  line-height: 0;
`;
