import { CheckOutlined, RedoOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { guard } from 'utils/general';

import {
  ActionButton,
  CenterTextContainer,
  CustomModal,
  CursiveText,
  FooterText,
  Frame,
  StoreName,
  WinnerBanner,
  WinnerProfile,
  WinnerName
} from './PotentialWinnersModal.styles';
import Avatar from '../Avatar/Avatar';
import PrizeRemarksModal from '../PrizeRemarksModal/PrizeRemarksModal';
import BackgroundConfettiInBlack from '../../images/confetti-black.png';

const WinnersFrame = ({ winners }) => {
  const isMultipleWinner = winners.length > 1;

  return (
    <Row justify="center">
      {winners.map(winner => (
        <Col>
          <Frame>
            {winner.profilePicUrl ? (
              <WinnerProfile image={winner.profilePicUrl} isMultipleWinner={isMultipleWinner} />
            ) : (
              <WinnerProfile isMultipleWinner={isMultipleWinner}>
                <Avatar round name={winner.name} bgColor="#FEF8E5E0" size={isMultipleWinner ? 120 : 280} />
              </WinnerProfile>
            )}
            <WinnerBanner isMultipleWinner={isMultipleWinner} />
          </Frame>
        </Col>
      ))}
    </Row>
  );
};

const PotentialWinnersModal = ({
  store,
  visible,
  winners,
  isSubmitting,
  isAllowPostSocialMedia,
  productTypesConst,
  onAcceptWinner,
  onSkipWinner
}) => {
  const { t } = useTranslation(['common', 'pageLuckyDrawSlotMachine']);
  const [isOpenPrizeModal, setIsOpenPrizeModal] = useState();
  const isMultipleWinner = winners && winners.length > 1;

  return (
    <CustomModal
      centered
      visible={visible}
      footer={null}
      destroyOnClose={true}
      maskClosable={false}
      closable={false}
      width="auto"
      bodyStyle={{
        backgroundColor: '#2C278A01',
        backgroundImage: `url("${BackgroundConfettiInBlack}")`,
        backgroundRepeat: 'repeat',
        padding: '60px 32px',
        width: '768px',
        height: 'fit-content'
      }}
      maskStyle={{ backgroundColor: '#2C278A00', backdropFilter: 'blur(5px)' }}
    >
      <div>
        <WinnersFrame winners={winners} />
        <CenterTextContainer>
          <StoreName>{store.name}</StoreName>
          <WinnerName isMultipleWinner={isMultipleWinner}>{winners && winners.map(winner => winner.name).join(', ')}</WinnerName>
          <Space>
            <ActionButton onClick={() => setIsOpenPrizeModal(true)} shape="round" size="large" icon={<CheckOutlined />}>
              {t('pageLuckyDrawSlotMachine:winner-modal-accept-winner-button', { winnerCount: winners.length })}
            </ActionButton>
            <ActionButton onClick={onSkipWinner} shape="round" size="large" icon={<RedoOutlined />}>
              {t('pageLuckyDrawSlotMachine:winner-modal-skip-winner-button', { winnerCount: winners.length })}
            </ActionButton>
          </Space>
        </CenterTextContainer>
        <FooterText>
          powered by <CursiveText>ulive</CursiveText>
        </FooterText>
      </div>
      {isOpenPrizeModal && (
        <PrizeRemarksModal
          visible={isOpenPrizeModal}
          isSubmitting={isSubmitting}
          isAllowPrize={guard(() => store.subscriptionConfig.canCreateOrder, false)}
          isAllowPostSocialMedia={isAllowPostSocialMedia}
          winners={winners}
          productTypesConst={productTypesConst}
          onCancel={() => setIsOpenPrizeModal(false)}
          onOk={({ selectedPrizeType, prizeRemarks, prizeProductKeyword, isPostingWinnerToFB, winnerName }) => {
            onAcceptWinner({
              selectedPrizeType,
              prizeRemarks,
              prizeProductKeyword,
              isPostingWinnerToFB,
              winnerName,
              onSuccess: () => setIsOpenPrizeModal(false),
              onFail: () => setIsOpenPrizeModal(false)
            });
          }}
        />
      )}
    </CustomModal>
  );
};

export default withAppContext(PotentialWinnersModal);
