import styled from '@emotion/styled';
import { Button, Row, Col } from 'antd';

const statusColors = {
  active: { backgroundColor: '#FFEEE3', color: '#FF7212' },
  inactive: { backgroundColor: '#E9E9E9', color: '#797979' }
};

export const ApplyButton = styled(Button)`
  background-color: ${props => (!props.disabled ? (props.selected ? 'transparent' : statusColors.active.color) : '')};
  border-color: ${props => (!props.disabled ? statusColors.active.color : '')};
  color: ${props => (props.selected ? statusColors.active.color : '#FFFFFF')};

  &:hover {
    background-color: ${props => (!props.disabled ? '#FF7212D9' : '')};
    border-color: ${props => (!props.disabled ? '#FF7212D9' : '')};
  }
  &:focus {
    background-color: ${props => (!props.disabled ? (props.selected ? 'transparent' : statusColors.active.color) : '')};
    border-color: ${props => (!props.disabled ? statusColors.active.color : '')};
    color: ${props => (props.selected ? statusColors.active.color : '#FFFFFF')};
  }
`;

export const AlertContainerRow = styled(Row)`
  margin-bottom: 12px;
`;

export const VoucherContainerRow = styled(Row)`
  background-color: ${props => statusColors[props.status].backgroundColor};
  border: 1px solid ${props => statusColors[props.status].color};
  border-radius: 4px;
  padding: 12px 18px;
  margin-bottom: 12px;
`;

export const VoucherContainerInfoCol = styled(Col)``;

export const VoucherCodeDisplay = styled.p`
  margin-bottom: 0;
  font-size: 12px;
  font-weight: bold;
  color: ${props => statusColors[props.status].color};
`;

export const VoucherCodeDiscount = styled.p`
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
  color: ${props => (props.status === 'active' ? undefined : statusColors[props.status].color)};
`;

export const VoucherCodeDescription = styled.p`
  margin-bottom: ${props => (props.isLast ? 0 : '4px')};
  font-size: 12px;
  color: ${props => statusColors[props.status].color};
  font-weight: ${props => (props.shouldHighlight ? 'bold' : undefined)};
`;

export const VoucherContainerButtonCol = styled(Col)`
  display: flex;
  justify-content: end;
  align-self: center;
`;
