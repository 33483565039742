import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation, Trans } from 'react-i18next';
import { Alert, Button, Col, message, Row, Skeleton, Space, Table, Modal } from 'antd';
import { PlusOutlined, SettingOutlined, InfoCircleFilled } from '@ant-design/icons';

import { useGetNotifications, useGetNotifStatistic, postNotification } from 'apis/notification';
import { withAppContext } from 'contexts/AppContext/AppContext';

import { formatToDateTimeWithPMAM } from 'utils/date';
import { constructReadableNum } from 'utils/general';
import { constructColumn, constructColumnFilterSearch, handleOnAggregationTableChange, formatPaginationShowTotalDisplay } from 'utils/table/table';

import Title from 'components/Title/Title';
import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import NewNotifiModal from './components/NewNotifiModal/NewNotifiModal';
import NotifSettingModal from './components/NotifSettingModal/NotifSettingModal';
import LearnMoreModal from './components/LearnMoreModal/LearnMoreModal';

import {
  TitleContainer,
  StatisticLimitContainer,
  StatisticFollowersContainer,
  StatisticDeliveredContainer,
  StatisticLabel,
  StatisticValue,
  UliveText
} from './Notification.styles';

const NumOfReceiverInfo = ({ t }) => {
  return (
    <div>
      <p>{t('pageNotification:table-header-number-of-receivers-tooltip-desc')}</p>
      <ul>
        <li>{t('pageNotification:table-header-number-of-receivers-tooltip-desc-1')}</li>
        <li>{t('pageNotification:table-header-number-of-receivers-tooltip-desc-2')}</li>
      </ul>
    </div>
  );
};

const constructColumns = ({ t, handleOnNumOfReceiverTooltipClick }) => [
  {
    ...constructColumn(t('pageNotification:table-header-notify-date'), 'createdAt', { hasAggregationSorter: true, width: '20%' }),
    render: date => formatToDateTimeWithPMAM(date)
  },
  {
    ...constructColumn(t('pageNotification:table-header-message'), 'body', { width: '65%' }),
    ...constructColumnFilterSearch('body', t('pageNotification:table-header-action-search-message'), { hasAggregationFilter: true })
  },
  {
    ...constructColumn(t('pageNotification:table-header-number-of-receivers'), 'numOfSend', { width: '15%' }),
    title: (
      <span>
        {t('pageNotification:table-header-number-of-receivers')} <InfoCircleFilled onClick={handleOnNumOfReceiverTooltipClick} />
      </span>
    )
  }
];

const Notification = ({ store }) => {
  const { t } = useTranslation(['common', 'pageNotification']);
  const [query, setQuery] = useState({ currentPage: 1, limit: 10 });
  const [isNewNotifVisible, setIsNewNotifVisible] = useState(false);
  const [isNotifSettingVisible, setIsNotifSettingVisible] = useState(false);
  const [isPushingNewNotif, setIsPushingNewNotif] = useState(false);
  const [isLearnMoreModalVisible, setIsLearnMoreModalVisible] = useState(false);

  const { isLoading: isNotificationsLoading, paginatedData: notifications, total, refetch: refetchNotifications } = useGetNotifications(query);
  const { isLoading: isNotifStatisticLoading, data: notifStatistic, refetch: refetcNotifStatistic } = useGetNotifStatistic();

  const handleOnNewNotifModalOk = payload => {
    setIsPushingNewNotif(true);

    postNotification(payload)
      .then(() => {
        setIsNewNotifVisible(false);
        refetchNotifications();
        refetcNotifStatistic();
        message.success(t('pageNotification:push-new-notif-modal-success-message'));
      })
      .catch(ex => {
        message.error(ex.message);
      })
      .finally(() => {
        setIsPushingNewNotif(false);
      });
  };

  const handleOnNumOfReceiverTooltipClick = () => {
    Modal.info({
      title: t('pageNotification:table-header-number-of-receivers-tooltip-title'),
      content: <NumOfReceiverInfo t={t} />,
      okText: t('common:modal-ok-text')
    });
  };

  return (
    <FullWidthContainer>
      <Helmet>
        <meta name="title" id="gtm-title" content="Notification" />
        <title>{t('pageNotification:page-title')}</title>
      </Helmet>
      <TitleContainer justify="space-between">
        <Title>{t('pageNotification:title')}</Title>
        <Space>
          <Button type="primary" shape="round" onClick={() => setIsNotifSettingVisible(true)}>
            <SettingOutlined />
          </Button>
          <Button type="primary" onClick={() => setIsNewNotifVisible(true)}>
            <PlusOutlined />
            {t('pageNotification:button-push-new-notif')}
          </Button>
        </Space>
      </TitleContainer>
      <Alert
        message={t('pageNotification:alert-message-reach-followers')}
        type="warning"
        style={{ marginBottom: '16px' }}
        action={
          <Button size="small" type="link" onClick={() => setIsLearnMoreModalVisible(true)}>
            {t('pageNotification:alert-message-learn-more')}
          </Button>
        }
      />
      {isNotifStatisticLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24} md={12} xl={6}>
              <StatisticLimitContainer>
                <StatisticLabel>{t('pageNotification:statistic-section-limit-title')}</StatisticLabel>
                <StatisticValue>
                  {notifStatistic.todayDelivered} / {notifStatistic.dailyLimit}
                </StatisticValue>
              </StatisticLimitContainer>
            </Col>
            <Col span={24} md={12} xl={6}>
              <StatisticFollowersContainer>
                <StatisticLabel>
                  <Trans i18nKey="pageNotification:statistic-section-followers-title">
                    Followers in <UliveText>ulive</UliveText>
                  </Trans>
                </StatisticLabel>
                <StatisticValue>{notifStatistic.followers}</StatisticValue>
              </StatisticFollowersContainer>
            </Col>
            <Col span={24} md={24} xl={12} xxl={12}>
              <StatisticDeliveredContainer>
                <StatisticLabel>{t('pageNotification:statistic-section-delivered-title')}</StatisticLabel>
                <StatisticValue>{constructReadableNum(notifStatistic.totalReached)}</StatisticValue>
              </StatisticDeliveredContainer>
            </Col>
          </Row>
          <Table
            size="small"
            loading={isNotificationsLoading}
            rowKey={record => record._id}
            dataSource={notifications}
            columns={constructColumns({
              t,
              handleOnNumOfReceiverTooltipClick
            })}
            scroll={{ x: 768 }}
            locale={{
              triggerDesc: t('common:table-header-sort-trigger-desc'),
              triggerAsc: t('common:table-header-sort-trigger-asc'),
              cancelSort: t('common:table-header-sort-cancel-sort')
            }}
            pagination={{ total, showSizeChanger: true, showTotal: formatPaginationShowTotalDisplay }}
            onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
          />
        </>
      )}
      {isNewNotifVisible && (
        <NewNotifiModal
          storeName={store.name}
          isLoading={isPushingNewNotif}
          isStoreHasMs={store.subscriptionConfig.isMicrositeEnabled}
          onOk={handleOnNewNotifModalOk}
          onCancel={() => setIsNewNotifVisible(false)}
        />
      )}
      {isNotifSettingVisible && (
        <NotifSettingModal visible onOk={() => setIsNotifSettingVisible(false)} onCancel={() => setIsNotifSettingVisible(false)} />
      )}
      {isLearnMoreModalVisible && <LearnMoreModal storeShortName={store.shortName} onCancel={() => setIsLearnMoreModalVisible(false)} />}
    </FullWidthContainer>
  );
};

export default withAppContext(Notification);
