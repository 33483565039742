import React, { useState } from 'react';

import * as xlsx from 'xlsx';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Space, Upload, message, Alert, Row, Tag, Checkbox } from 'antd';
import { DownloadOutlined, InboxOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { getPaidOrdersCSVData, patchUpdateOrdersShipmentDetails } from 'apis/order';
import { PAID_ORDER_CSV_HEADERS } from 'utils/constants';

import CsvDownload from 'components/CsvDownload/CsvDownload';

const { Dragger } = Upload;

const PAID_ORDER_CSV_HEADERS_KEYS = PAID_ORDER_CSV_HEADERS.map(header => header.key);

const ACCEPTED_FILES = ['.xlsx', '.xls', '.csv'];

const handleOnBeforeUpload = (file, setUploadedShipmentCSV) => {
  const fileExtension = file.name.split('.').pop();

  if (ACCEPTED_FILES.includes(`.${fileExtension}`)) {
    const reader = new FileReader();
    reader.onload = e => {
      const excelData = e.target.result;
      const workbook = xlsx.read(excelData, { type: 'array', codepage: 65001 });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = xlsx.utils.sheet_to_json(worksheet, { header: PAID_ORDER_CSV_HEADERS_KEYS, range: 1, defval: '', raw: false });

      setUploadedShipmentCSV(jsonData);
    };
    reader.readAsArrayBuffer(file);
  }

  return false;
};

const validateOrderShipmentDetails = (t, orderShipments) => {
  orderShipments.forEach(shipmentDetails => {
    if (!shipmentDetails.orderNumber || !shipmentDetails.recipientInfo || !shipmentDetails.trackingNo || !shipmentDetails.courier) {
      throw new Error(t('pageOrder:bulk-create-modal-required-field-error'));
    }
  });
};

const BulkUploadModal = ({ onOk, onCancel }) => {
  const { t } = useTranslation(['pageOrder']);
  const [isLoadingPaidOrderCSV, setIsLoadingPaidOrderCSV] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedShipmentCSV, setUploadedShipmentCSV] = useState([]);
  const [shouldUpdateToCompleted, setShouldUpdateToCompleted] = useState(true);

  const handleOnSave = () => {
    setIsUploading(true);

    try {
      if (uploadedShipmentCSV.length === 0) {
        throw new Error(t('pageOrder:bulk-create-modal-no-order-error'));
      }

      validateOrderShipmentDetails(t, uploadedShipmentCSV);

      patchUpdateOrdersShipmentDetails({ orderShipments: uploadedShipmentCSV, shouldUpdateToCompleted: shouldUpdateToCompleted })
        .then(nonExistingOrderNumbers => {
          const nonExistingOrderNumber = Object.values(nonExistingOrderNumbers);
          const flattedNonExistingOrderNumber = nonExistingOrderNumber.flat();
          onOk();
          if (flattedNonExistingOrderNumber.length > 0) {
            throw new Error(`${flattedNonExistingOrderNumber.join(',')} order number not found`);
          }
        })
        .catch(ex => {
          message.error({
            title: t('pageOrder:bulk-create-modal-create-error'),
            content: ex.message
          });
        })
        .finally(() => {
          setIsUploading(false);
        });
    } catch (ex) {
      setIsUploading(false);
      message.error(t(ex.message));
    }
  };

  const handleOnCheckBoxChange = () => {
    setShouldUpdateToCompleted(!shouldUpdateToCompleted);
  };

  return (
    <Modal
      visible
      width="60%"
      title={t('pageOrder:bulk-create-modal-title')}
      okText={t('common:modal-ok-text')}
      cancelText={t('common:modal-cancel-text')}
      onOk={handleOnSave}
      onCancel={onCancel}
      confirmLoading={isUploading}
      cancelButtonProps={{ loading: isUploading }}
      closable={!isUploading}
      maskClosable={!isUploading}
    >
      <CsvDownload
        asyncExportMethod={getPaidOrdersCSVData}
        onDataLoading={() => setIsLoadingPaidOrderCSV(true)}
        onDataLoaded={() => setIsLoadingPaidOrderCSV(false)}
        headers={PAID_ORDER_CSV_HEADERS}
        filename={t('pageOrder:csv-download-filename')}
      >
        <Button loading={isLoadingPaidOrderCSV} type="primary" icon={<DownloadOutlined />}>
          {t('pageOrder:bulk-create-modal-sample-download')}
        </Button>
      </CsvDownload>
      <Dragger
        maxCount={1}
        accept={ACCEPTED_FILES.join(', ')}
        beforeUpload={file => handleOnBeforeUpload(file, setUploadedShipmentCSV)}
        style={{ marginTop: 20 }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('pageOrder:bulk-create-modal-input-placeholder-upload')}</p>
      </Dragger>
      <Alert
        type="warning"
        style={{ marginTop: 20 }}
        message={
          <Space direction="vertical">
            <Row align="middle">
              <Tag color="#ffba00">
                <b>
                  <InfoCircleOutlined />
                </b>
              </Tag>
              <span>
                <b>{t('pageOrder:bulk-create-modal-note')}:</b>
              </span>
            </Row>
            <ul>
              <li>{t('pageOrder:bulk-create-modal-note-desc-1')}</li>
              <li>{t('pageOrder:bulk-create-modal-note-desc-2')}</li>
              <li>{t('pageOrder:bulk-create-modal-note-desc-3')}</li>
            </ul>
          </Space>
        }
      />
      <Checkbox style={{ marginTop: 20 }} checked={shouldUpdateToCompleted} onChange={handleOnCheckBoxChange}>
        {t('pageOrder:bulk-create-modal-checkbox-text')}
      </Checkbox>
    </Modal>
  );
};

export default BulkUploadModal;
