import styled from '@emotion/styled';
import { Col, Row, Select, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

export const ProductCardContentRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
`;

export const ProductCardInfoCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ProductCardNameLabel = styled(Paragraph)`
  margin-bottom: 8px;
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const ProductCardInventoryLabel = styled.p`
  margin-bottom: 8px;
  color: ${props => props.theme.color.grey};
`;

export const ProductCardVarianceLabel = styled.p`
  margin-bottom: 4px;
  color: ${props => props.theme.color.grey};
`;

export const PositionSelection = styled(Select)`
  width: 100%;
`;

export const DeleteButton = styled(DeleteOutlined)`
  font-size: 20px;
  vertical-align: middle;
  margin-left: 8px;
`;
