import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Input as AntdInput, InputNumber } from 'antd';

import FormLabel from 'components/FormLabel/FormLabel';
import CurrencyDisplay from 'components/CurrencyDisplay/CurrencyDisplay';

const INPUT_TYPE_FINANCIAL = 'financial';
const INPUT_TYPE_PERCENTAGE = 'percentage';
const INPUT_TYPE_NUMBER = 'number';
const INPUT_TYPE_TEXTAREA = 'textarea';

const generateLabelNode = (type, label) => {
  if (label && type === INPUT_TYPE_FINANCIAL) {
    return <CurrencyDisplay prefix={label} />;
  } else if (label && type === INPUT_TYPE_PERCENTAGE) {
    return <>{label} (%)</>;
  }
  return label;
};

const InputComponent = ({ type, size, ...props }) => {
  if (type === INPUT_TYPE_TEXTAREA) {
    return <AntdInput.TextArea {...props} />;
  } else if ([INPUT_TYPE_NUMBER, INPUT_TYPE_FINANCIAL, INPUT_TYPE_PERCENTAGE].includes(type)) {
    return <InputNumber {...props} />;
  } else {
    return <AntdInput type={type} size={size} {...props} />;
  }
};

const Input = ({
  label,
  type,
  placeholder,
  prefix,
  autoComplete,
  maxLength,
  size,
  value,

  isDisabled,
  isRequired,

  onChange,
  updateFormValue,

  ...props
}) => {
  const labelNode = useMemo(() => generateLabelNode(type, label), [type, label]);

  const handleOnChange = e => {
    let value = e;
    if (![INPUT_TYPE_NUMBER, INPUT_TYPE_FINANCIAL, INPUT_TYPE_PERCENTAGE].includes(type)) {
      value = e.target.value;
    }

    onChange(value);
    updateFormValue(value);
  };

  return (
    <>
      {!!label && <FormLabel hasRequiredErrorMessage={!!isRequired}>{labelNode}</FormLabel>}
      <InputComponent
        type={type}
        value={value}
        autoComplete={autoComplete}
        placeholder={placeholder}
        prefix={prefix}
        disabled={isDisabled}
        maxLength={maxLength}
        size={size}
        onChange={handleOnChange}
        {...props}
      />
    </>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  prefix: PropTypes.node,
  autoComplete: PropTypes.string,
  isDisabled: PropTypes.bool,
  maxLength: PropTypes.number,
  size: PropTypes.oneOf(['large', 'middle', 'small']),
  onChange: PropTypes.func,
  updateFormValue: PropTypes.func
};

Input.defaultProps = {
  type: undefined,
  placeholder: '',
  prefix: undefined,
  autoComplete: undefined,
  isDisabled: false,
  size: 'middle',
  onChange: () => {},
  updateFormValue: () => {}
};

export default Input;
