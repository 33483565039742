import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { supportedLocales } from 'i18n';

import { LanguageSwitcherOption } from './LanguageSwitcher.styles';

const MODE_DARK = 'dark';
const MODE_LIGHT = 'light';

const LanguageSwitcher = ({ localesToShow, mode }) => {
  const { i18n } = useTranslation();
  const getIsLanguageSelected = language => i18n && i18n.language === language;

  const localesToDisplay =
    localesToShow && localesToShow.length > 0
      ? supportedLocales.filter(localeConfig => {
          return localesToShow.includes(localeConfig.locale);
        })
      : [];

  return (
    <span>
      {localesToDisplay.map((localeConfig, index) => {
        const margin = {
          left: '8',
          right: '8',
          ...(index === 0 && { left: undefined }),
          ...(index === localesToDisplay.length - 1 && { right: undefined })
        };

        return (
          <span key={index}>
            <LanguageSwitcherOption
              {...margin}
              mode={mode}
              selected={getIsLanguageSelected(localeConfig.locale)}
              onClick={() => i18n.changeLanguage(localeConfig.locale)}
            >
              {localeConfig.label}
            </LanguageSwitcherOption>
            {index !== localesToDisplay.length - 1 && ' | '}
          </span>
        );
      })}
    </span>
  );
};

LanguageSwitcher.propTypes = {
  localesToShow: PropTypes.array.isRequired,
  mode: PropTypes.oneOf([MODE_DARK, MODE_LIGHT])
};

LanguageSwitcher.defaultProps = {
  mode: MODE_LIGHT
};

export default LanguageSwitcher;
