import { useCallback, useMemo } from 'react';
import { Button, Col, Form, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import FormRadioButton from 'components/FormRadioButton/FormRadioButton';

const { useForm } = Form;

const PrintBulkOrdersModal = ({ ordersCount, onOk, onCancel }) => {
  const { t } = useTranslation(['pageOrder']);
  const [form] = useForm();

  const printModes = useMemo(() => {
    return [
      {
        label: t('pageOrder:modal-print-bulk-orders-print-mode-standard-label'),
        value: 'standard',
        isCompact: false
      },
      {
        label: t('pageOrder:modal-print-bulk-orders-print-mode-compact-label'),
        value: 'compact',
        isCompact: true
      }
    ];
  }, [t]);

  const handleOnUpdateStatus = useCallback(() => {
    const payload = form.getFieldsValue();
    const selectedPrintMode = printModes.find(printMode => printMode.value === payload.printMode);
    onOk(selectedPrintMode.isCompact);
  }, [form, onOk, printModes]);

  return (
    <Modal
      visible
      title={t('pageOrder:modal-print-bulk-orders-title', { itemCount: ordersCount || 1 })}
      onCancel={onCancel}
      footer={
        <>
          <Button onClick={onCancel}>{t('pageOrder:modal-print-bulk-orders-close-modal-button-text')}</Button>
          <Button type="primary" onClick={handleOnUpdateStatus}>
            {t('pageOrder:modal-print-bulk-orders-print-button-text')}
          </Button>
        </>
      }
    >
      <Form form={form} initialValues={{ printMode: printModes[0].value }} style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={24}>
            <Col span={24}>
              <FormRadioButton
                name="printMode"
                label={t('pageOrder:modal-print-bulk-orders-form-radio-print-mode-label')}
                buttonStyle="solid"
                selections={printModes}
              />
            </Col>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PrintBulkOrdersModal;
