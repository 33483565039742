import { Badge } from 'antd';
import { useTranslation } from 'react-i18next';

import { PanelContainer, StyledSelectionButton } from './PlanPeriodSelectionPanel.styles';

const SUBS_PERIOD_YEARLY_CODE = 'yearly';

const PeriodSelectionButton = ({ showBadge = false, badgeMessage = '', isSelected = false, label, onClick }) =>
  showBadge || !!badgeMessage ? (
    <Badge count={badgeMessage} offset={[16, 0]}>
      <StyledSelectionButton isSelected={isSelected} onClick={onClick}>
        {label}
      </StyledSelectionButton>
    </Badge>
  ) : (
    <StyledSelectionButton isSelected={isSelected} onClick={onClick}>
      {label}
    </StyledSelectionButton>
  );

const PlanPeriodSelectionPanel = ({ subscriptionPeriods, selectedSubscriptionPeriodConst, onChangePeriod }) => {
  const { t } = useTranslation(['pagePlan']);

  return (
    <PanelContainer>
      {subscriptionPeriods.map(subscriptionPeriod => (
        <PeriodSelectionButton
          key={subscriptionPeriod.code}
          showBadge={subscriptionPeriod.value === SUBS_PERIOD_YEARLY_CODE}
          badgeMessage={
            subscriptionPeriod.value === SUBS_PERIOD_YEARLY_CODE
              ? t('pagePlan:subscription-plan-selection-panel-badge-message-for-business-yearly')
              : ''
          }
          isSelected={selectedSubscriptionPeriodConst.code === subscriptionPeriod.value}
          label={subscriptionPeriod.label}
          onClick={() => onChangePeriod(subscriptionPeriod.value)}
        />
      ))}
    </PanelContainer>
  );
};

export default PlanPeriodSelectionPanel;
