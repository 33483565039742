import styled from '@emotion/styled';
import { Tag } from 'antd';

export const StyledDot = styled.div`
  display: inline-block;
  border-radius: 50%;
  background-color: ${props => (props.isActive === true ? props.theme.color.green : props.theme.color.red)};
  height: 8px;
  width: 8px;
`;

export const StatusTag = styled(Tag)`
  background-color: ${props => (props.isActive === true ? props.theme.color.green : props.theme.color.red)};
  border-color: ${props => (props.isActive === true ? props.theme.color.green : props.theme.color.red)};
  color: ${props => props.theme.color.white};
`;
