import { useCustomQuery, useRefetchQuery } from 'hooks/reactQuery';

import api from './apiHelper';

export const postCheckout = (query, payload) => {
  return api.post('/external/checkout', payload, { params: query });
};

export const patchCheckoutOrder = (query, payload) => {
  return api.patch('/external/checkout/add-product', payload, { params: query });
};

export const useRefetchCheckoutDetails = () => {
  const { refetch } = useRefetchQuery(['checkoutDetails']);

  return refetch;
};

export const useGetCheckoutDetails = (query = {}, { onError, postProcessFunc } = {}) => {
  const getCheckoutDetails = async () => {
    return api.get(`/external/checkout`, {
      params: { ...query }
    });
  };

  return useCustomQuery('checkoutDetails', [query], getCheckoutDetails, {
    onError,
    postProcessFunc,
    shouldDefaultEmptyObject: false
  });
};

export const useGetCheckoutOrderInvoice = (query = {}, { onError, postProcessFunc } = {}) => {
  const getCheckoutOrderInvoice = async () => {
    return api.get(`/external/order/invoice`, {
      params: { ...query }
    });
  };

  return useCustomQuery('checkoutOrderInvoice', [query], getCheckoutOrderInvoice, {
    onError,
    postProcessFunc,
    shouldDefaultEmptyObject: false
  });
};

export const useGetExternalMicrositeFeaturedProducts = (storeId, onError) => {
  const getExternalMicrositeFeaturedProducts = async () => {
    return api.get(`/external/store/${storeId}/ms-featured-products`);
  };
  return useCustomQuery('micrositeFeaturedProducts', [storeId], getExternalMicrositeFeaturedProducts, {
    onError,
    enabled: !!storeId,
    shouldDefaultEmptyObject: false
  });
};

export const useGetExternalMicrositeFeaturedProductDetails = (productId, onError) => {
  const getExternalMicrositeFeaturedProductDetails = async () => {
    return api.get(`/external/product/${productId}`);
  };
  return useCustomQuery('micrositeFeaturedProductDetails', [productId], getExternalMicrositeFeaturedProductDetails, {
    onError,
    enabled: !!productId,
    shouldDefaultEmptyObject: false
  });
};

export const useGetShipmentsForExternal = (storeId, onError) => {
  const getShipments = async () => {
    return api.get(`/external/shipment`, {
      params: { store: storeId }
    });
  };
  return useCustomQuery('availableShipments', [storeId], getShipments, {
    onError,
    enabled: !!storeId,
    shouldDefaultEmptyObject: false
  });
};

export const useGetCustomerPromosForExternal = (storeCustomerId, orderId) => {
  const getCustomerPromos = async () => {
    return api.get(`/external/customer-promo`, {
      params: { storeCustomerId, orderId }
    });
  };
  return useCustomQuery('customerPromo', [storeCustomerId], getCustomerPromos, {
    enabled: !!storeCustomerId,
    shouldDefaultEmptyObject: false
  });
};

export const useGetAvailablePaymentMethodsForExternal = (storeId, { postProcessFunc } = {}) => {
  const getPaymentMethodsForExternal = async () => {
    return api.get(`/external/payment-methods`, {
      params: {
        store: storeId
      }
    });
  };

  return useCustomQuery('storePaymentMethods', [storeId], getPaymentMethodsForExternal, {
    enabled: !!storeId,
    shouldDefaultEmptyObject: false,
    postProcessFunc
  });
};

export const useGetFastPayPaymentStatusForExternal = (orderNumber, accessCode, { shouldFetch }) => {
  const getFastPayPaymentStatusForExternal = async () => {
    return api.get(`/external/order/payment-status`, {
      params: {
        orderNumber,
        accessCode
      }
    });
  };

  return useCustomQuery('paymentStatusFastPay', [orderNumber, accessCode], getFastPayPaymentStatusForExternal, {
    enabled: shouldFetch && !!orderNumber && !!accessCode,
    shouldDefaultEmptyObject: false
  });
};

export const getCalculateShipmentFee = (orderId, shipmentId) => {
  return api.get(`/external/order/${orderId}/shipping-fee/${shipmentId}`);
};

export const getCalculatePromoDiscount = (orderId, customerPromoId) => {
  return api.get(`/external/order/${orderId}/customer-promo/${customerPromoId}`);
};

export const getApplyPromoCode = (orderId, promoCode) => {
  return api.get(`/external/order/${orderId}/promo-code/${promoCode}`);
};

export const getCheckIsTelegramExist = storeId => {
  return api.get(`/external/store/${storeId}/telegram-invite-url`);
};
