import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { Col, Row } from 'antd';
import ConfettiBackgroundImage from './images/confetti-1.png';

const hideScrolBarCss = css`
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const neon = keyframes`
  from {
    text-shadow:
    0 0 6px rgba(25,23,67,0.92),
    0 0 30px rgba(25,23,67,0.34),
    0 0 12px rgba(254,114,18,0.52),
    0 0 21px rgba(254,114,18,0.92),
    0 0 34px rgba(254,114,18,0.78),
    0 0 54px rgba(254,114,18,0.92);
  }
  to {
    text-shadow:
    0 0 6px rgba(25,23,67,0.98),
    0 0 30px rgba(25,23,67,0.42),
    0 0 12px rgba(254,114,18,0.58),
    0 0 22px rgba(254,114,18,0.84),
    0 0 38px rgba(254,114,18,0.88),
    0 0 60px rgba(254,114,18,1);
  }
`;

const glow = keyframes`
 from {
    box-shadow: 0 0 10px #fff, 0 0 20px #FFBA00, 0 0 30px #FF7212, 0 0 40px #FF7212, 0 0 50px #FF7212, 0 0 60px #FF7212, 0 0 70px #FFBA00;
  }
  
  to {
    box-shadow: 0 0 60px 2px #2196f3, 0.5rem 0.5rem 30px #26115a;
  }
`;

export const WrapperContainer = styled.div`
  background-color: #131140;
  background-image: url(${ConfettiBackgroundImage});
  background-repeat: repeat;
  height: 100vh !important;
  width: 100%;
`;

export const ContainerMask = styled.div`
  background-color: #ffffff04;
  backdrop-filter: blur(2px);
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const ScrollableWrapper = styled.div`
  overflow-x: scroll;

  @media (min-width: 1600px) {
    overflow-x: visible;
  }

  ${hideScrolBarCss}
`;

export const ContentContainer = styled.div`
  min-width: 1024px; // Set a minimum so that mobile view can scroll to view
  max-width: 1440px; // Set a maximum so that big screen / TV view can be centered view
  position: relative;
`;

export const SlotMachineContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  width: 100%;
`;

export const SlotMachineContentContainer = styled(Row)`
  margin: 0 0 0 16px;
  width: 60%;

  @media (min-width: 1440px) {
    margin: 0 0 0 12%;
    width: 50%;
  }
`;

export const SlotMachineHeader = styled(Col)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const SlotMachineBody = styled(Col)`
  width: 100%;
`;

export const SMBodyContainter = styled(Row)`
  background-color: #ffffff3a;
  backdrop-filter: blur(4px);
  margin: 0 auto;
  max-width: 90%;
  min-height: 352px;
  padding: 16px 32px;
`;

export const SlotMachineFooter = styled(Col)`
  display: flex;
  justify-content: center;
  margin-top: -32px;
  width: 100%;
`;

export const StoreName = styled.p`
  margin: 16px 0 24px 0;
  font-size: 32px;
  text-align: center;
  color: ${props => props.theme.color.primary};
  font-family: ${props => props.theme.fontFamily.title};
  animation: ${neon} 0.08s ease-in-out infinite alternate;
`;

export const ContainerWrapperGlow = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 32px 0 48px 0;
  color: ${props => props.theme.color.white};
  font-size: 32px;
  font-weight: 700;
  background-color: ${props => props.theme.color.secondary};
  background-image: linear-gradient(to bottom right, #ffba00, #ff7212);
  border-color: ${props => props.theme.color.secondary};
  border-radius: 24px;
  border-style: inset;
  padding: 24px 16px;
  border-width: 16px;
  animation: ${glow} 1s ease-in-out infinite alternate;
  text-align: center;
  cursor: pointer;
`;
