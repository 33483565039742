import React, { useState, useMemo, useEffect, useCallback } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, message, Row, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { getReportingByProductPurchaseDate } from 'apis/reporting';
import { withAppContext } from 'contexts/AppContext/AppContext';
import { formatToDateString, getTodayMoment, getCurrentMonth, getCurrentMonthEnd, getDateFromDays, formatToMomentObject } from 'utils/date';
import { constructColumn, formatPaginationShowTotalDisplay } from 'utils/table/table';

import FormInput from 'components/FormInput/FormInput';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import ProductSoldModal from './components/ProductSoldModal/ProductSoldModal';
import StatisticCard from './components/StatisticCard/StatisticCard';
import { FilterContainer, StyledTextLink } from './ProductPurchaseDate.styles';

const { useForm } = Form;

const constructColumns = ({ t, handleOnViewSummaryBtnClick }) => [
  {
    ...constructColumn(t('pageReporting:table-header-name'), 'label', {
      hasSorter: true,
      width: '40%'
    }),
    render: (label, record) => {
      return <StyledTextLink onClick={() => handleOnViewSummaryBtnClick(record)}>{label}</StyledTextLink>;
    }
  },
  {
    ...constructColumn(t('pageReporting:table-header-keyword'), 'matchingKeyword', { hasSorter: true, width: '15%' })
  },
  {
    ...constructColumn(t('pageReporting:table-header-quantity-sold'), 'quantitySold', {
      hasSorter: true,
      isNumber: true,
      width: '15%'
    })
  },
  {
    ...constructColumn(t('pageReporting:table-header-total-sale'), 'totalPrice', {
      hasSorter: true,
      isPrice: true,
      isNumber: true,
      width: '15%'
    })
  }
];

const ProductPurchaseDate = ({ storeCurrency, securityPasscode, isPasscodeModalVisible, handleOnValidateSecurityPasscode }) => {
  const [form] = useForm();
  const { t } = useTranslation(['pageReporting']);
  const [isInit, setIsInit] = useState(false);
  const [dataQuery, setDataQuery] = useState(null);
  const [reportingData, setReportingData] = useState(null);
  const [isLoadingReportingData, setIsLoadingReportingData] = useState(false);
  const [selectedProductSold, setSelectedProductSold] = useState(null);

  const [dateQuery, setDateQuery] = useState({ startDate: formatToDateString(getDateFromDays(-6)), endDate: formatToDateString(getTodayMoment()) });
  const dateRangeValues = useMemo(() => [formatToMomentObject(dateQuery.startDate), formatToMomentObject(dateQuery.endDate)], [dateQuery]);

  const handleOnClickSearch = () => {
    getFormattedReportingData();
  };

  const getFormattedReportingData = useCallback(
    async passcode => {
      try {
        setReportingData(null);
        setIsLoadingReportingData(true);

        const dataQuery = {
          startDate: dateQuery.startDate,
          endDate: dateQuery.endDate,
          searchKeyword: form.getFieldValue('searchKeyword'),
          securityPasscode: passcode || securityPasscode
        };
        setDataQuery(dataQuery);

        const reportingData = await getReportingByProductPurchaseDate(dataQuery);

        setReportingData(reportingData);
        setIsLoadingReportingData(false);
        handleOnValidateSecurityPasscode(true);
      } catch (ex) {
        setIsLoadingReportingData(false);
        handleOnValidateSecurityPasscode(false);
        message.error(ex.message);
      }
    },
    [dateQuery, securityPasscode, form, handleOnValidateSecurityPasscode]
  );

  useEffect(() => {
    if (!isInit) {
      setIsInit(true);
      setIsLoadingReportingData(true);
      getFormattedReportingData(securityPasscode);
    }
  }, [isInit, securityPasscode, getFormattedReportingData]);

  const handleOnSearchFormKeyUp = event => {
    if (event.keyCode === 13) {
      getFormattedReportingData();
    }
  };

  const handleOnViewSummaryBtnClick = product => {
    setSelectedProductSold(product);
  };

  return (
    <FullWidthContainer>
      {!isPasscodeModalVisible && (
        <>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <StatisticCard
                title={t('pageReporting:revenue-title')}
                value={reportingData ? reportingData.revenue : '-'}
                pendingTitle={t('pageReporting:pending-revenue-amount')}
                pendingValue={reportingData ? reportingData.pendingRevenue : '-'}
                isCurrency={true}
                storeCurrency={storeCurrency}
              />
            </Col>
            <Col xs={24} sm={12}>
              <StatisticCard
                title={t('pageReporting:total-number-of-orders')}
                value={reportingData ? reportingData.numOfOrders : 0}
                pendingTitle={t('pageReporting:total-number-of-pending-orders')}
                pendingValue={reportingData ? reportingData.numOfPendingOrders : 0}
              />
            </Col>
          </Row>

          <FilterContainer wrap>
            <Form form={form} onKeyUp={handleOnSearchFormKeyUp}>
              <FormInput name="searchKeyword" customMarginBottom={0} placeholder={t('pageReporting:product-sold-search-product-placeholder')} />
            </Form>
            <DateRangePicker
              value={dateRangeValues}
              onChange={dates => dates && setDateQuery({ startDate: formatToDateString(dates[0]), endDate: formatToDateString(dates[1]) })}
              allowClear={false}
              extraDisabledDate={current => current > moment().endOf('day') || current.diff(dateQuery.startDate, 'days') > 30}
              ranges={{
                Today: [getTodayMoment(), getTodayMoment()],
                'This Month': [getCurrentMonth(), getCurrentMonthEnd()],
                'Previous 30 days': [getDateFromDays(-30), getTodayMoment()]
              }}
            />
            <Button type="primary" loading={isLoadingReportingData} onClick={handleOnClickSearch}>
              <SearchOutlined />
            </Button>
          </FilterContainer>

          <Table
            loading={isLoadingReportingData}
            columns={constructColumns({ t, handleOnViewSummaryBtnClick })}
            dataSource={reportingData && reportingData.products}
            pagination={{ showSizeChanger: true, showTotal: formatPaginationShowTotalDisplay }}
          />
        </>
      )}

      {selectedProductSold && (
        <ProductSoldModal
          securityPasscode={securityPasscode}
          startDate={dataQuery.startDate}
          endDate={dataQuery.endDate}
          selectedProductSold={selectedProductSold}
          onClose={() => setSelectedProductSold(null)}
        />
      )}
    </FullWidthContainer>
  );
};

export default withAppContext(ProductPurchaseDate);
