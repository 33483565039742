import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Bar } from '@ant-design/plots';

import SectionTitle from '../SectionTitle/SectionTitle';

const barChartConfig = {
  xField: 'value',
  yField: 'label',
  seriesField: 'type',
  isStack: true,
  legend: false,
  label: {
    layout: [
      {
        type: 'interval-adjust-position'
      },
      {
        type: 'interval-hide-overlap'
      },
      {
        type: 'adjust-color'
      }
    ]
  },
  tooltip: {
    title: 'title'
  }
};

const getFormattedBarData = (data = []) => {
  let formattedData = [];

  data.forEach(item => {
    formattedData.push({
      type: 'Sold',
      label: item.matchingKeyword,
      value: item.quantitySold,
      title: `[${item.matchingKeyword}] ${item.label}`
    });
  });

  return formattedData;
};

const ProductsSoldBar = ({ reportingData }) => {
  const { t } = useTranslation(['pageReporting']);

  return (
    <div>
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <SectionTitle>{t('pageReporting:bar-top-sale-products')}</SectionTitle>
          <Bar {...barChartConfig} data={getFormattedBarData(reportingData.topProducts)} />
        </Col>
        <Col xs={24} md={12}>
          <SectionTitle>{t('pageReporting:bar-least-sale-products')}</SectionTitle>
          <Bar {...barChartConfig} data={getFormattedBarData(reportingData.leastProducts)} />
        </Col>
      </Row>
    </div>
  );
};

export default ProductsSoldBar;
