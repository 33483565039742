import { useMemo, useState } from 'react';
import { Card, Col, Form, Modal, message, Row, Skeleton, Alert } from 'antd';
import { useTranslation } from 'react-i18next';

import FormInput from 'components/FormInput/FormInput';

import { withAppContext } from 'contexts/AppContext/AppContext';
import FormSelection from 'components/FormSelection/FormSelection';
import FormLabel from 'components/FormLabel/FormLabel';
import { useGetProductsForCreateTikTokLivePost } from 'apis/product';
import { switchElementPosition } from 'utils/general';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import { postCreateTiktokLivePost, useGetCurrentStorePostsMinimal } from 'apis/post';
import { useFetchConstant } from 'hooks/constants';
import { formatToDateTimeWithDayFullString } from 'utils/date';
import {
  DeleteButton,
  LiveSessionSelect,
  LiveSessionSelection,
  LiveSessionSelectionLiveIndicator,
  PositionSelection,
  ProductCardContentRow,
  ProductCardInfoCol,
  ProductCardInventoryLabel,
  ProductCardNameLabel,
  ProductCardVarianceLabel,
  StyledFacebookOutlined,
  StyledInstagramOutlined
} from './CreateTiktokLivePostModal.styles';

const { confirm } = Modal;
const { useForm } = Form;

// ======================================== Local ======================================== //
const generatePositionSelection = maxNum => {
  const selections = [];
  for (let i = 1; i <= maxNum; i++) {
    selections.push({ label: i, value: i });
  }
  return selections;
};

const getProductSelection = (products = [], featuredProducts) => {
  let selections = [...products];
  for (let i = 0; i < featuredProducts.length; i++) {
    const featuredProduct = featuredProducts[i];
    selections = selections.filter(selection => selection.value !== featuredProduct.value);
  }
  return selections;
};

const CardActions = ({ options, position, onPositionChange, onDelete }) => {
  return (
    <Row align="middle" style={{ width: '100%' }}>
      <Col flex="auto">
        <PositionSelection options={options} value={position + 1} onChange={pos => onPositionChange(pos - 1)} />
      </Col>
      <Col>
        <DeleteButton onClick={onDelete} />
      </Col>
    </Row>
  );
};

const ProductCard = ({ product = {}, positionSelection, position, onPositionChange, onDelete }) => {
  return (
    <Card style={{ height: '100%' }} bodyStyle={{ height: '100%' }}>
      <ProductCardContentRow>
        <Col span={24}>
          <ProductCardNameLabel ellipsis={{ rows: 2, tooltip: product.label }}>{product.label}</ProductCardNameLabel>
        </Col>
        <ProductCardInfoCol span={24}>
          <FullWidthContainer>
            <ProductCardVarianceLabel>Variances: {product.numberOfVariance}</ProductCardVarianceLabel>
            <ProductCardInventoryLabel>Total Inventory: {product.totalInventory}</ProductCardInventoryLabel>
          </FullWidthContainer>
          <CardActions options={positionSelection} position={position} onPositionChange={onPositionChange(position)} onDelete={onDelete(position)} />
        </ProductCardInfoCol>
      </ProductCardContentRow>
    </Card>
  );
};

// =================================== Main Component =================================== //
const CreateTiktokLivePostModal = ({ visible, onClose, onCreated }) => {
  const { t } = useTranslation(['common', 'modalCreateTiktokLivePost']); //
  const [form] = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [selectedPostId, setSelectedPostId] = useState();

  const { data: socialMediaSourcesConst, isLoading: isSocialMediaSourcesLoading } = useFetchConstant('socialMediaSources');
  const { data: postTypesConst, isLoading: isPostTypeSelectionsLoading } = useFetchConstant('postTypes');
  const {
    data: storeLivePostSelections,
    isLoading: isStoreLivePostSelectionsLoading,
    refetch: refetchStoreLivePostSelections
  } = useGetCurrentStorePostsMinimal(postTypesConst.LIVE_VIDEO.code, socialMediaSourcesConst.FB.code);
  const { data: tiktokLivePostProducts, isLoading: isTiktokLivePostProductsLoading } = useGetProductsForCreateTikTokLivePost();

  const productPositionSelection = useMemo(() => generatePositionSelection(featuredProducts.length), [featuredProducts]);
  const productSelection = useMemo(() => getProductSelection(tiktokLivePostProducts, featuredProducts), [tiktokLivePostProducts, featuredProducts]);

  const handleOnProductSelectSelected = selectedProductId => {
    const matchingProduct = productSelection.find(product => product.value === selectedProductId);
    if (matchingProduct) {
      const newFeaturedProducts = [...featuredProducts, matchingProduct];
      setFeaturedProducts(newFeaturedProducts);

      form.setFieldsValue({ product: '' });
    }
  };

  const handleOnPositionChange = oldPosition => newPosition => {
    const newFeaturedProducts = switchElementPosition(featuredProducts, oldPosition, newPosition);
    setFeaturedProducts(newFeaturedProducts);
  };

  const handleOnDelete = position => () => {
    const title = t('modalCreateTiktokLivePost:confirm-modal-remove-featured-product-title');
    const content = t('modalCreateTiktokLivePost:confirm-modal-remove-featured-product-content');

    confirm({
      title,
      content,
      okText: t('common:modal-ok-text'),
      cancelText: t('common:modal-cancel-text'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        let newFeaturedProducts = [...featuredProducts];
        newFeaturedProducts.splice(position, 1);
        setFeaturedProducts(newFeaturedProducts);
      }
    });
  };

  const handleOnFormSave = async e => {
    e.preventDefault();

    try {
      const allFieldsValue = await form.validateFields();
      setIsSubmitting(true);

      const payload = {
        ...allFieldsValue,
        selectedPostId,
        featuredProducts: featuredProducts.map(product => product.value)
      };

      const newPost = await postCreateTiktokLivePost(payload);
      refetchStoreLivePostSelections();
      setIsSubmitting(false);
      onCreated(newPost);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsSubmitting(false);
      message.error(t('common:update-fail-message'));
    }
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  const isFormLoading = isPostTypeSelectionsLoading || isStoreLivePostSelectionsLoading || isTiktokLivePostProductsLoading;

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={t('modalCreateTiktokLivePost:modal-title')}
      okText={t('modalCreateTiktokLivePost:modal-ok-text')}
      cancelText={t('common:modal-cancel-text')}
      destroyOnClose
      width={1200}
      confirmLoading={isSubmitting}
      onOk={handleOnFormSave}
    >
      <Skeleton loading={isFormLoading}>
        <Form form={form} style={{ width: '100%' }} onFinishFailed={handleOnSaveFailed} scrollToFirstError>
          <FormInput
            label={t('modalCreateTiktokLivePost:form-input-label-title')}
            name="title"
            placeholder={t('modalCreateTiktokLivePost:form-input-placeholder-title')}
            requiredErrorMessage={t('modalCreateTiktokLivePost:form-input-required-message-title')}
          />
          <FormLabel>{t('modalCreateTiktokLivePost:form-input-label-fb-live-post-link')}</FormLabel>
          <Col span={24} style={{ display: 'flex', marginBottom: '24px' }}>
            <LiveSessionSelect
              options={
                storeLivePostSelections &&
                storeLivePostSelections.map(storePost => {
                  const socialMediaIconMapping = !isSocialMediaSourcesLoading
                    ? {
                        [socialMediaSourcesConst.FB.code]: <StyledFacebookOutlined />,
                        [socialMediaSourcesConst.IG.code]: <StyledInstagramOutlined />
                      }
                    : {};
                  return {
                    label: (
                      <LiveSessionSelection>
                        {socialMediaIconMapping[storePost.source] ? socialMediaIconMapping[storePost.source] : null}
                        {storePost.title || formatToDateTimeWithDayFullString(storePost.createdAt)}
                        {storePost.isLive && <LiveSessionSelectionLiveIndicator color="#F5222D">LIVE</LiveSessionSelectionLiveIndicator>}
                      </LiveSessionSelection>
                    ),
                    value: storePost._id,
                    searchValue: `${storePost.title || formatToDateTimeWithDayFullString(storePost.createdAt)}`
                  };
                })
              }
              value={selectedPostId}
              onChange={value => {
                setSelectedPostId(value);
              }}
              optionFilterProp="searchValue"
              showSearch={true}
            />
          </Col>
          <Row gutter={16}>
            <Col span={24}>
              <FormInput
                label={t('modalCreateTiktokLivePost:form-input-label-description')}
                name="description"
                type="textArea"
                placeholder={t('modalCreateTiktokLivePost:form-input-placeholder-description')}
                rows={8}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormLabel>{t('modalCreateTiktokLivePost:form-input-label-products')}</FormLabel>
              <Alert
                type="info"
                message={`🛍️ ${t('modalCreateTiktokLivePost:form-input-alert-message-products')}`}
                style={{ marginBottom: '24px' }}
              />
              <FormSelection
                selections={productSelection}
                onSelect={handleOnProductSelectSelected}
                placeholder={t('modalCreateTiktokLivePost:form-input-placeholder-products')}
                name="product"
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            {featuredProducts.map((product, idx) => (
              <Col key={idx} span={8}>
                <ProductCard
                  product={product}
                  positionSelection={productPositionSelection}
                  position={idx}
                  onPositionChange={handleOnPositionChange}
                  onDelete={handleOnDelete}
                />
              </Col>
            ))}
          </Row>
        </Form>
      </Skeleton>
    </Modal>
  );
};

export default withAppContext(CreateTiktokLivePostModal);
