import { CopyOutlined, DownloadOutlined, FacebookOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Divider, List, message, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { useScript } from 'hooks/utils';
import { getFormattedContact, constructReadableNum } from 'utils/general';
import { getCheckoutInvoiceRoute } from 'utils/routes';

import TextWithUrl from 'components/TextWithUrl/TextWithUrl';
import ImagePreview from 'components/ImagePreview/ImagePreview';

import CheckoutPriceDisplay from '../CheckoutPriceDisplay/CheckoutPriceDisplay';
import ProductDisplayListItem from '../ProductDisplayListItem/ProductDisplayListItem';
import {
  RootContainerModal,
  Title,
  SMSectionAlert,
  SMSectionActionContainer,
  SMSectionClickableText,
  StoreName,
  StoreDescription,
  StyledDivider,
  ThankYouText,
  OrderCancelText,
  OrderDescTitle,
  RecipientRowContainer,
  RecipientCol
} from './SuccessModal.styles';

const Address = ({ address = {} }) => {
  return (
    <Row>
      {address.addressLine1 && <Col span={24}>{address.addressLine1},</Col>}
      {address.addressLine2 && <Col span={24}>{address.addressLine2},</Col>}
      {(address.zipcode || address.city) && (
        <Col span={24}>
          {address.zipcode} {address.city},
        </Col>
      )}
      <Col span={24}>
        {address.state && `${address.state}, `}
        {address.country}.
      </Col>
    </Row>
  );
};

const SendMessageSection = ({ t, orderNumber, hasMicrositeOrder = false, storeFBPageId }) => {
  const title = `📬 ${
    hasMicrositeOrder
      ? t('pageCheckout:success-modal-message-alert-title-with-microsite-order')
      : t('pageCheckout:success-modal-message-alert-title-normal-order')
  }`;

  const handleOnClickCopyMessage = () => {
    const presetMessage = hasMicrositeOrder ? t('pageCheckout:success-modal-message-alert-preset-message-for-microsite-order', { orderNumber }) : '';
    navigator.clipboard.writeText(presetMessage);
    message.success(t('pageCheckout:success-modal-message-alert-copy-success-message'));
  };

  const handleOnClickOpenMessenger = () => {
    window.open(`https://www.facebook.com/messages/t/${storeFBPageId}`);
  };

  switch (true) {
    case hasMicrositeOrder:
      return (
        <SMSectionAlert
          message={<b>{title}</b>}
          description={
            <div>
              <p>
                <i>{t('pageCheckout:success-modal-message-alert-preset-message-for-microsite-order', { orderNumber })}</i>
                <SMSectionActionContainer>
                  <SMSectionClickableText onClick={handleOnClickCopyMessage}>
                    <CopyOutlined /> {t('pageCheckout:success-modal-message-alert-btn-copy-message')}
                  </SMSectionClickableText>
                  <SMSectionClickableText isFB={true} onClick={handleOnClickOpenMessenger}>
                    <FacebookOutlined /> {t('pageCheckout:success-modal-message-alert-btn-open-messenger')}
                  </SMSectionClickableText>
                </SMSectionActionContainer>
              </p>
            </div>
          }
          type="warning"
        />
      );
    default:
      return (
        <SMSectionAlert
          message={
            <div>
              <b>{title}</b>
              <SMSectionClickableText isFB={true} onClick={handleOnClickOpenMessenger}>
                <FacebookOutlined /> {t('pageCheckout:success-modal-message-alert-btn-open-messenger')}
              </SMSectionClickableText>
            </div>
          }
          type="warning"
        />
      );
  }
};

const TrackingLinkDisplay = ({ t, shipmentMethod, trackingNo, trackingLink, isTrackButtonReady, previewImageUrl, thumbnailImageUrl }) => {
  return (
    <>
      <Row gutter={24}>
        <Col span={16}>
          {shipmentMethod}
          {trackingNo && (
            <div>
              {t('pageCheckout:success-modal-tracking-number')}{' '}
              {trackingLink ? (
                <a href={trackingLink} target="_blank" rel="noopener noreferrer">
                  {trackingNo}
                </a>
              ) : isTrackButtonReady ? (
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() =>
                    window.TrackButton.track({
                      tracking_no: trackingNo
                    })
                  }
                >
                  {trackingNo}
                </Button>
              ) : (
                <span>{trackingNo}</span>
              )}
            </div>
          )}
        </Col>
        {previewImageUrl && (
          <Col span={8}>
            <ImagePreview height="52px" width="52px" previewImageUrl={previewImageUrl} thumbnailImageUrl={thumbnailImageUrl} />
          </Col>
        )}
      </Row>
    </>
  );
};

const SuccessModal = ({
  orderNumber,
  accessCode,
  storeDetails,
  recipientDetails,
  orderProducts,
  storeCurrency,
  shippingFee,
  productShippingFee,
  subtotalPrice,
  extraFeeAmount,
  totalDiscountAmount = 0,
  totalAmount,
  isOrderCancelled,
  hasMicrositeOrder,
  isPreserved,
  isEnabledPoint,
  pointsEarned,
  currentPoints = 0
}) => {
  const { t } = useTranslation(['common', 'pageCheckout']);

  const trackButtonStatus = useScript('//www.tracking.my/track-button.js');
  const isTrackButtonReady = trackButtonStatus === 'ready';

  return (
    <RootContainerModal style={{ top: 40 }} visible={true} footer={null} closable={false}>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <StoreName>{storeDetails.name}</StoreName>
        </Col>
        {storeDetails.bankDetails && (
          <Col span={24} style={{ textAlign: 'center' }}>
            <StoreDescription>
              {storeDetails.bankDetails.bankName} {storeDetails.bankDetails.accountNumber} {storeDetails.bankDetails.beneficiaryName}
            </StoreDescription>
          </Col>
        )}
        <StyledDivider />
        <Col span={24} style={{ textAlign: 'center' }}>
          {isOrderCancelled ? (
            <OrderCancelText>{t('pageCheckout:success-modal-title-order-cancel')}</OrderCancelText>
          ) : (
            <ThankYouText>{t('pageCheckout:success-modal-title-thank-you')}</ThankYouText>
          )}
        </Col>
        {isPreserved && (
          <Col span={24}>
            <SMSectionAlert description={<b>{t('pageCheckout:success-modal-order-preserved-banner-message')}</b>} type="success" />
          </Col>
        )}
        {!isOrderCancelled && storeDetails.fbPageId && (
          <Col span={24}>
            <SendMessageSection t={t} orderNumber={orderNumber} hasMicrositeOrder={hasMicrositeOrder} storeFBPageId={storeDetails.fbPageId} />
          </Col>
        )}
      </Row>
      <Card
        title={
          isOrderCancelled ? (
            <OrderDescTitle>
              {t('pageCheckout:success-modal-desc-cancel-text-part-1')} <b>#{orderNumber}</b>{' '}
              {t('pageCheckout:success-modal-desc-cancel-text-part-2')}
            </OrderDescTitle>
          ) : (
            <Space>
              <OrderDescTitle>
                {t('pageCheckout:success-modal-desc-success-text-part-1')}{' '}
                <b>
                  {t('pageCheckout:success-modal-desc-success-text-part-2')} #{orderNumber}
                </b>
              </OrderDescTitle>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={() => window.open(getCheckoutInvoiceRoute(orderNumber, { accessCode }).path)}
              >
                {t('pageCheckout:success-modal-download-invoice-button')}
              </Button>
            </Space>
          )
        }
        style={{ height: '100%' }}
      >
        <RecipientRowContainer gutter={16}>
          {!isOrderCancelled && (
            <>
              <RecipientCol order={1} span={24} md={{ span: 12, order: 0 }}>
                <b>{t('pageCheckout:success-modal-title-ship-to')}</b>
                <p>
                  {recipientDetails.recipientName} ({getFormattedContact(recipientDetails.recipientContact)})
                  {recipientDetails.address && <Address address={recipientDetails.address} />}
                </p>
              </RecipientCol>
              <RecipientCol order={2} span={24} md={{ span: 12, order: 2 }}>
                <b>{t('pageCheckout:success-modal-title-shipping-method')}</b> <br />
                <TrackingLinkDisplay
                  t={t}
                  shipmentMethod={recipientDetails.label}
                  trackingNo={recipientDetails.trackingNo}
                  trackingLink={recipientDetails.trackingLink}
                  isTrackButtonReady={isTrackButtonReady}
                  previewImageUrl={recipientDetails.deliveryProofV2?.original?.url || recipientDetails.deliveryProof?.url}
                  thumbnailImageUrl={recipientDetails.deliveryProofV2?.thumbnail?.url || recipientDetails.deliveryProof?.url}
                />
              </RecipientCol>
              {recipientDetails.remarks && (
                <RecipientCol order={0} span={24} md={{ span: 12, order: 1 }}>
                  <b>{t('pageCheckout:success-modal-title-shipping-remarks')}</b> <br />
                  <TextWithUrl text={recipientDetails.remarks} />
                </RecipientCol>
              )}
            </>
          )}
        </RecipientRowContainer>
        <Row>
          <StyledDivider />
          <Title>{isOrderCancelled ? t('pageCheckout:success-modal-cancel-order-products') : t('pageCheckout:success-modal-order-products')}</Title>
          <List
            style={{ width: '100%' }}
            rowKey={record => record._id}
            dataSource={orderProducts}
            renderItem={(orderProduct, index) => (
              <>
                <ProductDisplayListItem
                  label={orderProduct.label}
                  keyword={orderProduct.matchingKeyword}
                  quantity={orderProduct.quantity}
                  price={orderProduct.price}
                  total={orderProduct.priceDetails.totalAmount}
                  previewImageUrl={orderProduct.coverPhoto?.original?.url || orderProduct.coverPhotoFile?.url}
                  thumbnailImageUrl={orderProduct.coverPhoto?.thumbnail?.url || orderProduct.coverPhotoFile?.url}
                  storeCurrency={storeCurrency}
                />
                {index === orderProducts.length - 1 && (
                  <Row style={{ marginTop: 12 }} gutter={[0, 8]}>
                    <CheckoutPriceDisplay
                      label={t('pageCheckout:order-display-desc-label-subtotal')}
                      storeCurrency={storeCurrency}
                      amount={subtotalPrice}
                    />
                    <CheckoutPriceDisplay
                      label={t('pageCheckout:order-display-desc-label-shipping-fee')}
                      storeCurrency={storeCurrency}
                      amount={shippingFee}
                    />
                    {!!productShippingFee && (
                      <CheckoutPriceDisplay
                        label={t('pageCheckout:order-display-desc-label-product-shipping-fee')}
                        storeCurrency={storeCurrency}
                        amount={productShippingFee}
                      />
                    )}
                    {extraFeeAmount ? (
                      <CheckoutPriceDisplay
                        label={t('pageCheckout:order-display-desc-label-extra-fee')}
                        storeCurrency={storeCurrency}
                        amount={extraFeeAmount}
                      />
                    ) : null}
                    {totalDiscountAmount ? (
                      <CheckoutPriceDisplay
                        label={t('pageCheckout:order-display-desc-label-discount')}
                        storeCurrency={storeCurrency}
                        amount={totalDiscountAmount}
                        isDeduct
                      />
                    ) : null}
                    <CheckoutPriceDisplay
                      label={t('pageCheckout:order-display-desc-label-total')}
                      storeCurrency={storeCurrency}
                      amount={totalAmount}
                      highlight
                    />
                  </Row>
                )}
              </>
            )}
          />
        </Row>
        {isEnabledPoint && (
          <>
            {pointsEarned && pointsEarned > 0 && (
              <>
                <Row style={{ marginTop: '8px' }}>
                  <span>{t('pageCheckout:order-display-desc-label-earn-points', { points: pointsEarned })}</span>
                </Row>
                <Divider />
              </>
            )}
            <Alert
              type="info"
              message={
                <div>
                  <p style={{ fontSize: '14px', margin: '2px 0' }}>{t('pageCheckout:success-modal-current-point-alert-message')}</p>
                  <p style={{ margin: 0 }}>
                    <b style={{ fontSize: '24px' }}>{constructReadableNum(currentPoints, storeCurrency)}</b> pts
                  </p>
                </div>
              }
            />
          </>
        )}
      </Card>
    </RootContainerModal>
  );
};

export default SuccessModal;
