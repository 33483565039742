import styled from '@emotion/styled';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';

export const TitleIcon = styled(ExclamationCircleOutlined)`
  color: #faad14;
  margin-right: 16px;
  font-size: 22px;
`;

export const TitleText = styled.span`
  font-size: 16px;
`;

export const ContentContainer = styled(Row)`
  margin-top: 24px !important;
`;

export const DeleteButton = styled(Button)`
  background-color: ${props => props.theme.color.white};
  border-color: ${props => props.theme.color.red};
  color: ${props => props.theme.color.red};

  &:hover {
    background-color: ${props => props.theme.color.white};
    border-color: ${props => props.theme.color.red};
    color: ${props => props.theme.color.red};
  }
`;

export const DeactivateButton = styled(Button)`
  background-color: ${props => props.theme.color.white};
  border-color: ${props => props.theme.color.tertiary};
  color: ${props => props.theme.color.tertiary};

  &:hover {
    background-color: ${props => props.theme.color.white};
    border-color: ${props => props.theme.color.tertiary};
    color: ${props => props.theme.color.tertiary};
  }
`;

export const ActivateButton = styled(Button)`
  background-color: ${props => props.theme.color.white};
  border-color: ${props => props.theme.color.green};
  color: ${props => props.theme.color.green};

  &:hover {
    background-color: ${props => props.theme.color.white};
    border-color: ${props => props.theme.color.green};
    color: ${props => props.theme.color.green};
  }
`;
