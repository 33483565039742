import styled from '@emotion/styled';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

export const RootModal = styled(Modal)`
  width: 100vw !important;

  @media (min-width: 1200px) {
    width: 70vw !important;
  }
`;

export const ContentContainer = styled.div`
  min-width: 576px;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

export const CompletedIcon = styled(CheckCircleOutlined)`
  color: ${props => props.theme.color.green} !important;
`;

export const CancelledIcon = styled(CloseCircleOutlined)`
  color: ${props => props.theme.color.red} !important;
`;

export const UpdateStatusButton = styled(Button)`
  background-color: ${props => props.theme.color.white};
  border-color: ${props => props.theme.color.tertiary};
  color: ${props => props.theme.color.tertiary};

  &:hover {
    background-color: ${props => props.theme.color.tertiary};
    border-color: ${props => props.theme.color.tertiary};
    color: ${props => props.theme.color.white};
  }
`;

export const CancelOrderButton = styled(Button)`
  background-color: ${props => props.theme.color.white};
  border-color: ${props => props.theme.color.red};
  color: ${props => props.theme.color.red};

  &:hover {
    background-color: ${props => props.theme.color.red};
    border-color: ${props => props.theme.color.red};
    color: ${props => props.theme.color.white};
  }
`;
