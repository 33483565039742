import styled from '@emotion/styled';
import { Avatar, Card, Tag, Typography, Row } from 'antd';

const mobileOriPriceFontSize = '10px';
const tabletOriPriceFontSize = '12px';
const mobileFontSize = '12px';
const tabletFontSize = '14px';

export const StyledCard = styled(Card)`
  width: 160px !important;
  height: ${props => (props.isLiveSelling ? 'auto !important' : '100% !important')};
  border-radius: 8px;
  background-color: ${props => (props.isActive ? undefined : '#f7f7f7')};
`;

export const CardBodyRow = styled(Row)`
  height: ${props => (props.isLiveSelling ? 'auto !important' : '104px !important')};
  padding: ${props => (props.isLiveSelling ? '8px' : '8px')};

  @media (min-width: 576px) {
    height: ${props => (props.isLiveSelling ? 'auto !important' : '130px !important')};
    padding: ${props => (props.isLiveSelling ? '8px' : '12px')};
  }
`;

export const CoverPhoto = styled.div`
  background-image: ${props => `url("${props.src}")`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: black;
  height: 120px;
  width: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const ProductAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
  border-radius: 8px;

  @media (min-width: 576px) {
    width: 72px;
    height: 72px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ProductLabel = styled(Typography.Paragraph)`
  color: ${props => props.theme.color.black};
  line-height: 1.4;
  font-size: ${mobileFontSize};
  margin-bottom: ${props => (props.hasOriPrice && props.isLiveSelling ? '0' : '4px')} !important;

  @media (min-width: 768px) {
    font-size: ${tabletFontSize};
    margin-bottom: ${props => (props.hasOriPrice && props.isLiveSelling ? '0' : '8px')} !important;
  }
`;

export const KeywordLabel = styled(Typography.Paragraph)`
  color: grey;
  line-height: 1.4;
  font-size: ${mobileFontSize};
  margin-bottom: ${props => (props.hasOriPrice && props.isLiveSelling ? '0' : '4px')} !important;

  @media (min-width: 768px) {
    font-size: ${tabletFontSize};
    margin-bottom: ${props => (props.hasOriPrice && props.isLiveSelling ? '0' : '8px')} !important;
  }
`;

export const PriceAndQuantityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PriceLabel = styled(Typography.Paragraph)`
  color: ${props => (props.hasOriPrice ? props.theme.color.secondary : props.theme.color.tertiary)};
  line-height: 1.2;
  font-size: ${mobileFontSize};
  font-weight: 500;
  margin-bottom: 0 !important;

  @media (min-width: 768px) {
    font-size: ${tabletFontSize};
  }
`;

export const OriPriceLabel = styled(Typography.Paragraph)`
  color: grey;
  font-size: ${mobileOriPriceFontSize};
  text-decoration: line-through;
  margin-bottom: 4px !important;

  @media (min-width: 768px) {
    font-size: ${tabletOriPriceFontSize};
  }
`;

export const ProductTag = styled(Tag)`
  color: ${props => props.theme.color.white};
  background: ${props => (props.highlight ? props.theme.color.secondary : props.theme.color.grey)};
  line-height: 1.5;
  font-size: ${mobileFontSize};
  padding: 0 4px;
  border-radius: 16px;

  @media (min-width: 768px) {
    line-height: 20px;
    font-size: ${tabletFontSize};
    padding: 0 12px;
  }
`;
