import styled from '@emotion/styled';
import { Menu } from 'antd';

const { Item } = Menu;

export const MenuContainer = styled(Menu)`
  background-color: ${props => props.theme.color.tertiary};
  color: ${props => props.theme.color.white};
  padding: 0 ${props => props.theme.spacing.regular};
  border: 0px;
`;

export const MenuItem = styled(Item)`
  color: ${props => props.theme.color.white} !important;
  border-radius: 24px;

  &:hover {
    color: ${props => props.theme.color.tertiary} !important;
    background-color: ${props => props.theme.color.lighterPrimary};
  }
`;

export const ActiveMenuItem = styled(Item)`
  color: ${props => props.theme.color.tertiary} !important;
  border-radius: 24px;

  &:hover {
    color: ${props => props.theme.color.tertiary} !important;
    background-color: ${props => props.theme.color.lighterPrimary};
  }
`;
