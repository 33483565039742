import {
  AvatarProfilePic,
  ContainerWrapper,
  ContainerWrapperGlow,
  MainContainer,
  SlotItemContainer,
  SlotItemTextOnly,
  TextContainer
} from './Slot.styles';
import Avatar from '../Avatar/Avatar';

const Wrapper = ({ children, isGlowing }) => {
  if (isGlowing) {
    return <ContainerWrapperGlow>{children}</ContainerWrapperGlow>;
  }
  return <ContainerWrapper>{children}</ContainerWrapper>;
};

const Slot = ({ isGlowing, timer, winnerIndex, slotItems, onSpinClick }) => {
  return (
    <MainContainer>
      <Wrapper isGlowing={isGlowing}>
        <TextContainer winnerIndex={winnerIndex} timer={timer / 1000}>
          {slotItems.map((slotItem, index) => (
            <SlotItemContainer key={index} onClick={onSpinClick}>
              {index === 0 ? (
                <SlotItemTextOnly>{slotItem}</SlotItemTextOnly>
              ) : slotItem && slotItem.profilePicUrl ? (
                <AvatarProfilePic profilePicUrl={slotItem.profilePicUrl} />
              ) : (
                <Avatar name={slotItem.name} size={148} />
              )}
            </SlotItemContainer>
          ))}
        </TextContainer>
      </Wrapper>
    </MainContainer>
  );
};

export default Slot;
