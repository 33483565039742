import styled from '@emotion/styled';
import { Input, Select } from 'antd';

/** Special case - styling for input group.
 * Form item needs to be noStyle in order to get the style of compact group inputs.
 * Need to manual set marginBottom to fit FormItem styling.
 */
export const StyledSelect = styled(Select)`
  width: 40% !important;
`;

export const StyledInput = styled(Input)`
  width: 60% !important;
`;
