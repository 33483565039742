import { useEffect } from 'react';
import { Skeleton } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { withAppContext } from 'contexts/AppContext/AppContext';
import { useLocationQuery } from 'hooks/router';

import { useGetPostSoldProductStickers } from 'apis/post';

import ProductSticker from 'components/ProductSticker/ProductSticker';

const PrintProductSticker = ({ storeCurrency }) => {
  const { t } = useTranslation(['common', 'pageOverview']);

  const { postId, productId, soldProduct, isSingle } = useLocationQuery();
  const { isLoading, data: postSoldProductStickers } = useGetPostSoldProductStickers(postId, productId);

  useEffect(() => {
    if (!isLoading || isSingle) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, [isLoading, isSingle]);

  return isLoading && !isSingle ? (
    <Skeleton active />
  ) : (
    <>
      <Helmet>
        <meta name="title" id="gtm-title" content="Overview | Print Product Stickers" />
        <title>{t('pageOverview:product-sold-print-stickers-title')}</title>
      </Helmet>
      {isSingle ? (
        <ProductSticker soldProduct={JSON.parse(decodeURIComponent(soldProduct))} storeCurrency={storeCurrency} />
      ) : (
        postSoldProductStickers.map(postSoldProductSticker => <ProductSticker soldProduct={postSoldProductSticker} storeCurrency={storeCurrency} />)
      )}
    </>
  );
};

export default withAppContext(PrintProductSticker);
