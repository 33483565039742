import { useEffect, useState } from 'react';
import { Descriptions, message, Modal, Skeleton, Tooltip, Alert, Checkbox, Table } from 'antd';
import { InfoCircleFilled, DollarCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useGetOrderDetails, patchConfirmPaymentOrder, useRefetchOrders } from 'apis/order';

import Files from 'components/Files/Files';
import FilesV2 from 'components/Files/FilesV2';
import Input from 'components/Input/Input';
import Title from 'components/Title/Title';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';

import { useFetchConstant } from 'hooks/constants';
import { constructConstantLabel } from 'utils/constants';
import { checkIsArrayEmpty, getFormattedContact, checkIsObjectEmpty } from 'utils/general';
import { constructColumn } from 'utils/table/table';

const { Item: DescItem } = Descriptions;

const getBuyerText = ({ name, contact }) => `${name || ''} ${contact && contact.countryCode ? ' (' + getFormattedContact(contact) + ')' : ''}`;

const getConsolidatedAndFormattedReceiptFiles = paymentsWithReceipts => {
  let files = [];
  paymentsWithReceipts.forEach(payment => {
    files = files.concat(payment.receiptFiles);
  });

  return files;
};

const getConsolidatedAndFormattedReceipts = paymentsWithReceipts => {
  let files = [];
  paymentsWithReceipts.forEach(payment => {
    files = files.concat(payment.receipts);
  });

  return files;
};

const constructColumns = ({ t, paymentStatusesConst, paymentStatuses, paymentMethods }) => [
  {
    ...constructColumn(t('modalViewPayment:table-header-payment-date'), 'createdAt', {
      isDate: true
    }),
    render: (createdAt, record) => {
      return moment(createdAt).format('YYYY-MM-DD h:mma');
    }
  },
  {
    ...constructColumn(t('modalViewPayment:table-header-payment-status'), 'status'),
    render: (status, record) => {
      const statusColors = !checkIsObjectEmpty(paymentStatusesConst) && {
        [paymentStatusesConst.PENDING.code]: '#FFBA00',
        [paymentStatusesConst.SUCCESS.code]: '#52C41A',
        [paymentStatusesConst.FAILED.code]: '#F5222D'
      };
      return <span style={{ color: statusColors[status] }}>{constructConstantLabel(paymentStatuses, status)}</span>;
    }
  },
  {
    ...constructColumn(t('modalViewPayment:table-header-amount'), 'amount', {
      isPrice: true
    })
  },
  {
    ...constructColumn(t('modalViewPayment:table-header-payment-method'), 'method', {}),
    render: (method, record) => {
      return <span>{constructConstantLabel(paymentMethods, method)}</span>;
    }
  },
  {
    ...constructColumn(t('modalViewPayment:table-header-receipts'), 'receipts', { width: '30%' }),
    render: (receipts, record) => {
      const hasPaymentReceiptFiles = !checkIsArrayEmpty(record.receiptFiles);
      const formattedReceiptFiles = hasPaymentReceiptFiles ? getConsolidatedAndFormattedReceiptFiles([record]) : {};

      const hasPaymentReceipts = !checkIsArrayEmpty(record.receipts);
      const formattedReceipts = hasPaymentReceipts ? getConsolidatedAndFormattedReceipts([record]) : {};

      return hasPaymentReceipts || hasPaymentReceiptFiles ? (
        <div>
          {hasPaymentReceiptFiles ? <Files files={formattedReceiptFiles} photoSize={80} /> : null}
          {hasPaymentReceipts ? <FilesV2 files={formattedReceipts} photoSize={80} /> : null}
        </div>
      ) : (
        '-'
      );
    }
  }
];

const OrderViewPaymentModal = ({ orderId, store, onClose }) => {
  const { t } = useTranslation(['common', 'modalViewPayment']);

  const [isPreserveOrderChecked, setIsPreserveOrderChecked] = useState(false);
  const [isSettleBalanceChecked, setIsSettleBalanceChecked] = useState(false);
  const [isApproveCustomChecked, setIsApproveCustomChecked] = useState(false);
  const [customAmountToApprove, setCustomAmountToApprove] = useState(0);

  const { isLoading: isOrderLoading, data: order, refetch: refetchOrder } = useGetOrderDetails(orderId);
  const refetchOrders = useRefetchOrders();
  const { selection: paymentMethods, isLoading: isPaymentMethodsLoading } = useFetchConstant('paymentMethods');
  const { selection: paymentStatuses, isLoading: isPaymentStatusesLoading, data: paymentStatusesConst } = useFetchConstant('paymentStatuses');
  const { isLoading: isPaymentTypesLoading, data: paymentTypesConst } = useFetchConstant('paymentTypes');

  const isLoading = isOrderLoading || isPaymentMethodsLoading || isPaymentStatusesLoading || isPaymentTypesLoading;
  const pendingPayments =
    !isLoading && order
      ? order.payments.filter(payment => payment.type === paymentTypesConst.MANUAL.code && payment.status === paymentStatusesConst.PENDING.code)
      : [];
  const hasPendingPayments = !checkIsArrayEmpty(pendingPayments);
  const totalPendingAmounts = pendingPayments.reduce((acc, payment) => acc + payment.amount, 0);
  const showPreservedOrderIcon = hasPendingPayments && order && (order.isPreserved || order.isPreservePending);

  const handleOnConfirmPayment = () => {
    patchConfirmPaymentOrder(orderId, {
      shouldSkipCustomerPreserve: true,
      isPreserveOrderChecked,
      isSettleBalanceChecked,
      settlementAmount: customAmountToApprove
    })
      .then(() => {
        message.success(t('modalViewPayment:success-message'));
        refetchOrder();
        refetchOrders();
      })
      .catch(() => {
        message.error(t('common:update-fail-message'));
      });
    onClose();
  };

  const handleOnCustomAmountChange = val => {
    if (val !== '') {
      val = Number(val);
    }
    setCustomAmountToApprove(val);
  };

  useEffect(() => {
    if (order.balanceAmount) {
      setCustomAmountToApprove(order.balanceAmount);
    }
    if (order.isPreservePending || store.isAutoPreserveOrder) {
      setIsPreserveOrderChecked(true);
    }
  }, [order, store]);

  return (
    <Modal
      visible={!isLoading}
      cancelText={t('modalViewPayment:cancel-button-text')}
      okText={hasPendingPayments ? t('modalViewPayment:ok-button-text') : t('common:modal-ok-text')}
      onCancel={onClose}
      onOk={hasPendingPayments ? handleOnConfirmPayment : onClose}
      width="fit-content"
      title={
        <Title>
          {showPreservedOrderIcon && (
            <Tooltip title={t('pageOrder:order-preserved-icon-tooltip-message')} placement="top">
              <DollarCircleOutlined style={{ color: '#ffba00' }} />
            </Tooltip>
          )}
          {` ${order.orderNumber}`}
        </Title>
      }
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {showPreservedOrderIcon && (
            <div style={{ marginBottom: '12px' }}>
              <Alert
                icon={<ExclamationCircleOutlined />}
                type="warning"
                message={t('modalViewPayment:modal-is-order-preserved-warning-text')}
                showIcon={true}
              />
            </div>
          )}
          <Descriptions bordered size="medium" column={{ xs: 1, sm: 1, md: 3 }} style={{ marginBottom: '12px' }}>
            <DescItem label={t('modalViewPayment:desc-order-number')} span={3}>
              {order.orderNumber}
            </DescItem>
            <DescItem label={t('modalViewPayment:desc-buyer')} span={2}>
              {order.customer && getBuyerText(order.customer)}
            </DescItem>
            <DescItem label={t('modalViewPayment:desc-total-amount')} span={1}>
              <PriceDisplay amount={order.totalAmount} />{' '}
              <Tooltip
                title={
                  <span>
                    <PriceDisplay prefix={`${t('modalViewPayment:desc-total-amount-subtotal')}: `} amount={order.subtotalPrice || 0} />
                    <br />
                    <PriceDisplay
                      prefix={`${t('modalViewPayment:desc-total-amount-shipping')}: `}
                      amount={(order.shipment && order.shipment.shippingFee) || 0}
                    />
                    <br />
                    <PriceDisplay
                      prefix={`${t('modalViewPayment:desc-total-amount-extra-fee')}: `}
                      amount={(order.extraFee && order.extraFee.amount) || 0}
                    />
                    <br />
                    <PriceDisplay
                      prefix={`${t('modalViewPayment:desc-total-amount-discount')}: `}
                      amount={(order.discount && order.discount.amount) || 0}
                    />
                  </span>
                }
              >
                <InfoCircleFilled />
              </Tooltip>
            </DescItem>
          </Descriptions>
          {hasPendingPayments && (
            <div>
              <h3>
                {t('modalViewPayment:desc-balance-amount')}{' '}
                <span style={{ fontWeight: 700 }}>
                  <PriceDisplay amount={order.balanceAmount} />
                </span>
              </h3>
              <h3>
                {t('modalViewPayment:desc-pending-amount')}{' '}
                <span style={{ fontWeight: 700 }}>
                  <PriceDisplay amount={totalPendingAmounts} />
                </span>
              </h3>
              <h3>
                {t('modalViewPayment:desc-balance-after-pending-amount')}{' '}
                <span style={{ fontWeight: 700 }}>
                  <PriceDisplay amount={order.balanceAmount - totalPendingAmounts} />
                </span>
              </h3>
            </div>
          )}
          <Table
            loading={isLoading}
            rowKey={record => record._id}
            dataSource={order.payments}
            columns={constructColumns({
              t,
              paymentStatusesConst,
              paymentStatuses,
              paymentMethods
            })}
            scroll={{ x: 1200 }}
            locale={{
              triggerDesc: t('common:table-header-sort-trigger-desc'),
              triggerAsc: t('common:table-header-sort-trigger-asc'),
              cancelSort: t('common:table-header-sort-cancel-sort')
            }}
          />
          {hasPendingPayments && (
            <>
              <Checkbox
                onChange={() => setIsPreserveOrderChecked(!isPreserveOrderChecked)}
                defaultValue={isPreserveOrderChecked}
                value={isPreserveOrderChecked}
                checked={isPreserveOrderChecked}
                style={{ marginTop: '12px' }}
              >
                {t('modalViewPayment:checkbox-text-keep-order')}
              </Checkbox>
              <br />
              <Checkbox
                onChange={() => setIsSettleBalanceChecked(!isSettleBalanceChecked)}
                defaultValue={isSettleBalanceChecked}
                value={isSettleBalanceChecked}
                checked={isSettleBalanceChecked}
                style={{ marginTop: '12px' }}
                disabled={isApproveCustomChecked}
              >
                {t('modalViewPayment:checkbox-text-settle-outstanding')}
              </Checkbox>
              <br />
              <Checkbox
                onChange={() => setIsApproveCustomChecked(!isApproveCustomChecked)}
                defaultValue={isApproveCustomChecked}
                value={isApproveCustomChecked}
                checked={isApproveCustomChecked}
                style={{ marginTop: '12px' }}
                disabled={isSettleBalanceChecked}
              >
                {t('modalViewPayment:checkbox-text-approve-custom')}
              </Checkbox>
              <Tooltip title={t('modalViewPayment:tooltip-message-custom-approve-amount')}>
                <ExclamationCircleOutlined />
              </Tooltip>
              {isApproveCustomChecked && (
                <div style={{ marginTop: '12px' }}>
                  <Input
                    label={t('modalViewPayment:input-text')}
                    type="financial"
                    value={customAmountToApprove}
                    onChange={handleOnCustomAmountChange}
                    min={1}
                    max={order.balanceAmount}
                    style={{ width: '100%' }}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default OrderViewPaymentModal;
