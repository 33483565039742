import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Button, Form, message, Modal, Skeleton, Space, Spin, Table, Tabs } from 'antd';
import { CheckOutlined, KeyOutlined } from '@ant-design/icons';
import moment from 'moment';

import {
  patchStoreAccountsFromAdmin,
  patchResetPasswordFromAdmin,
  patchUliveAdminStore,
  useGetAccountDetails,
  useFetchStoreStarterOrderLimitDetailsForAdmin
} from 'apis/adminExternal';

import { guard } from 'utils/general';
import { logError } from 'utils/logging';
import { constructColumn } from 'utils/table/table';

import FormDatePicker from 'components/FormDatePicker/FormDatePicker';
import FormInput from 'components/FormInput/FormInput';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import FormSelection from 'components/FormSelection/FormSelection';
import TextButton from 'components/TextButton/TextButton';
import PhotoManager from 'components/PhotoManager/PhotoManager';
import { useFetchConstant } from 'hooks/constants';
import { useIsLoadings } from 'hooks/utils';
import TTLogoMini from 'images/tiktok-logo-mini.png';

import { StyledFooterContainer, StyledOrderLimit } from './AccountDetailsModal.styles';

const { useForm } = Form;
const { confirm } = Modal;
const { TabPane } = Tabs;

/* =============================================== Local Functions =============================================== */
// =============================================== General
const constructAccountColumns = handleOnResetPasswordClick => [
  {
    ...constructColumn('Name', ['user', 'name'], { width: '20%' })
  },
  {
    ...constructColumn('Contact Number', 'user.contact', { width: '30%' }),
    render: (status, record) => <>{`${record.user.contact.countryCode}${record.user.contact.contactNumber}`}</>
  },
  {
    ...constructColumn('Email', ['user', 'email'], { width: '20%' })
  },
  {
    ...constructColumn('Actions', 'action', { width: '30%' }),
    render: (text, record) => (
      <Space size="middle">
        <TextButton
          icon={<KeyOutlined />}
          onClick={() =>
            handleOnResetPasswordClick(
              guard(() => record.user._id),
              guard(() => record.user.username)
            )
          }
          text="Reset Password"
        />
      </Space>
    )
  }
];

// =============================================== Hook Functions
const useFetchConstants = () => {
  const { isLoading: isLoadingSubscriptionTypes, selection: subscriptionTypes, data: subscriptionTypesConst } = useFetchConstant('subscriptionTypes');
  const { isLoading: isLoadingSubscriptionPeriods, selection: subscriptionPeriods, data: subscriptionPeriodsConst } = useFetchConstant(
    'subscriptionPeriods'
  );

  const { isLoading: isLoadingConstants } = useIsLoadings([isLoadingSubscriptionTypes, isLoadingSubscriptionPeriods]);

  return { isLoadingConstants, subscriptionTypes, subscriptionTypesConst, subscriptionPeriods, subscriptionPeriodsConst };
};

const useGetFormInitialValues = (isLoading, accountDetails) => {
  const formInitialValues = useMemo(() => {
    if (isLoading) {
      return;
    }

    const subscriptionConfig = accountDetails.subscriptionConfig;
    const pgConfig = accountDetails.pgConfig;
    const broadcastConfig = accountDetails.broadcastConfig;
    const notifConfig = accountDetails.notifConfig;

    const formInitialValues = {
      status: accountDetails.status,
      subscriptionConfig: {
        subscriptionType: guard(() => subscriptionConfig.subscriptionType),
        subscriptionPeriod: guard(() => subscriptionConfig.subscriptionPeriod),
        expiryDate: moment(guard(() => subscriptionConfig.expiryDate)),
        isMicrositeEnabled: guard(() => subscriptionConfig.isMicrositeEnabled),
        isBegkuningEnabled: guard(() => subscriptionConfig.isBegkuningEnabled),
        isCommissionEnabled: guard(() => subscriptionConfig.isCommissionEnabled),
        isCustomerEnabled: guard(() => subscriptionConfig.isCustomerEnabled),
        isEmailCheckoutLinkEnabled: guard(() => subscriptionConfig.isEmailCheckoutLinkEnabled)
      },
      pgConfig: {
        billplz: { collectionId: guard(() => pgConfig.billplz.collectionId) },
        fastPay: { outletId: guard(() => pgConfig.fastPay.outletId) },
        razerPay: {
          merchantId: guard(() => pgConfig.razerPay.merchantId),
          verifyKey: guard(() => pgConfig.razerPay.verifyKey),
          secretKey: guard(() => pgConfig.razerPay.secretKey)
        }
      },
      broadcastConfig: {
        locales: guard(() => broadcastConfig.locales),
        telegram: { chatId: guard(() => broadcastConfig.telegram.chatId), inviteUrl: guard(() => broadcastConfig.telegram.inviteUrl) }
      },
      notifConfig: {
        isEnabled: guard(() => notifConfig.isEnabled),
        inviteCode: guard(() => notifConfig.inviteCode, accountDetails.shortName)
      }
    };

    return formInitialValues;
  }, [isLoading, accountDetails]);

  return formInitialValues;
};

const useGetAccountDetailsPostProcess = (form, selectedStoreId, token, isLoadingConstants, subscriptionTypesConst, subscriptionPeriodsConst) => {
  const { isLoading: isSubscriptionDetailsLoading, data: accountDetails, refetch: refetchAccountDetails } = useGetAccountDetails(
    selectedStoreId,
    token
  );

  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState();
  const [isEnabledAppNotif, setIsEnabledAppNotif] = useState();
  const [isEnableMicrosite, setIsEnableMicrosite] = useState();
  const [customMicrositeLogo, setCustomMicrositeLogo] = useState([]);
  const customMicrositeLogoRef = useRef(null);

  const accountName = guard(() => accountDetails.name);
  const accountOperators = useMemo(() => guard(() => accountDetails.operators, []), [accountDetails]);
  const isSubscriptionStarter = [subscriptionTypesConst.STARTER.code, subscriptionTypesConst.PAID_STARTER.code].includes(selectedSubscriptionType);
  const expiryDate = guard(() => accountDetails.subscriptionConfig.expiryDate);
  const hasExpired = moment(expiryDate).diff(moment(), 'days') < 0;

  const { isLoading } = useIsLoadings([isLoadingConstants, isSubscriptionDetailsLoading]);

  const formInitialValues = useGetFormInitialValues(isLoading, accountDetails);

  const handleOnChangeSubscriptionType = useCallback(e => {
    const selectedSubscriptionType = e.target.value;
    setSelectedSubscriptionType(selectedSubscriptionType);
  }, []);

  const handleOnChangeSubscriptionPeriod = useCallback(
    e => {
      const selectedSubscriptionPeriod = e.target.value;

      let addDays;

      if (selectedSubscriptionPeriod === subscriptionPeriodsConst.MONTHLY.code) {
        addDays = 30;
      } else if (selectedSubscriptionPeriod === subscriptionPeriodsConst.HALF_YEAR.code) {
        addDays = 180;
      } else if (selectedSubscriptionPeriod === subscriptionPeriodsConst.YEARLY.code) {
        addDays = 365;
      }

      form.setFieldsValue({
        subscriptionConfig: { expiryDate: moment(hasExpired ? undefined : expiryDate).add(hasExpired ? addDays - 1 : addDays, 'days') }
      });
    },
    [expiryDate, form, hasExpired, subscriptionPeriodsConst]
  );

  const handleOnChangeIsEnabledAppNotif = useCallback(e => {
    setIsEnabledAppNotif(e.target.value);
  }, []);

  const handleOnChangeIsEnableMicrosite = useCallback(e => {
    setIsEnableMicrosite(e.target.value);
  }, []);

  const handleOnChangeCustomMicrositeLogo = useCallback(logos => {
    setCustomMicrositeLogo(logos);
  }, []);

  useEffect(() => {
    if (!isSubscriptionDetailsLoading) {
      handleOnChangeSubscriptionType({ target: { value: guard(() => accountDetails.subscriptionConfig.subscriptionType) } });
      setIsEnabledAppNotif(guard(() => accountDetails.notifConfig.isEnabled));
      setIsEnableMicrosite(guard(() => accountDetails.subscriptionConfig.isMicrositeEnabled));
      setCustomMicrositeLogo(guard(() => (accountDetails.micrositeSettings.customLogo ? [accountDetails.micrositeSettings.customLogo] : [])));
    }
  }, [isSubscriptionDetailsLoading, accountDetails, handleOnChangeSubscriptionType]);

  return {
    isSubscriptionDetailsLoading,
    isSubscriptionStarter,
    isEnabledAppNotif,
    isEnableMicrosite,
    customMicrositeLogo,
    selectedSubscriptionType,
    accountName,
    accountOperators,
    formInitialValues,
    customMicrositeLogoRef,
    handleOnChangeSubscriptionType,
    handleOnChangeSubscriptionPeriod,
    handleOnChangeIsEnabledAppNotif,
    handleOnChangeIsEnableMicrosite,
    handleOnChangeCustomMicrositeLogo,
    refetchAccountDetails
  };
};

const useGetStoreStarterOrderLimitDetails = (storeId, token) => {
  const { isLoading: isLoadingOrderLimitDetails, data: orderLimitDetails } = useFetchStoreStarterOrderLimitDetailsForAdmin(storeId, token, true);

  const { totalOrder: starterTotalOrder, starterOrderLimit } = orderLimitDetails;

  return { isLoadingOrderLimitDetails, starterTotalOrder, starterOrderLimit };
};

/* =============================================== Local Components =============================================== */
const Store = ({
  token,
  storeId,
  isSubscriptionStarter,
  subscriptionTypes,
  subscriptionPeriods,
  disabledSubscriptionPeriods,
  starterTotalOrder,
  starterOrderLimit,
  isEnableMicrosite,
  customMicrositeLogo,
  customMicrositeLogoRef,
  onChangeSubscriptionType,
  onChangeSubscriptionPeriod,
  onChangeIsEnableMicrosite,
  onChangeCustomMicrositeLogo,
  refetchStoreAccounts,
  refetchAccountDetails
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const handleOnSwitchAdminStore = () => {
    confirm({
      title: 'Switch admin to this store',
      content: 'Are you sure you want to switch admin to this store?',
      okText: "Yes, I'm sure",
      onOk() {
        setIsUpdating(true);
        patchUliveAdminStore(storeId, token)
          .then(() => {
            message.success(`You have switched admin to this store!`);
            refetchStoreAccounts();
            refetchAccountDetails();
          })
          .catch(ex => {
            logError(ex);
            message.error(`Something went wrong when switching admin to this store: ${ex.message}`);
          })
          .finally(() => {
            setIsUpdating(false);
          });
      }
    });
  };

  return (
    <>
      <Button type="primary" style={{ marginBottom: '16px' }} loading={isUpdating} onClick={handleOnSwitchAdminStore}>
        Switch admin to this store
      </Button>
      <FormRadioButton
        name="status"
        label="Account Activation Status"
        selections={[
          { value: 'active', label: 'Active' },
          { value: 'inactive', label: 'Inactive' }
        ]}
        buttonStyle="solid"
      />
      <StyledOrderLimit>{`Order Limit: ${isSubscriptionStarter ? `${starterTotalOrder} / ${starterOrderLimit}` : '∞ / ∞'}`}</StyledOrderLimit>
      <FormRadioButton
        label="Plan"
        name={['subscriptionConfig', 'subscriptionType']}
        selections={subscriptionTypes}
        buttonStyle="solid"
        onChange={onChangeSubscriptionType}
      />
      <FormRadioButton
        label="Period"
        name={['subscriptionConfig', 'subscriptionPeriod']}
        selections={subscriptionPeriods}
        disableValues={disabledSubscriptionPeriods}
        buttonStyle="solid"
        onClick={onChangeSubscriptionPeriod}
      />
      <FormDatePicker name={['subscriptionConfig', 'expiryDate']} label="Expires" disableDateType="past" />
      <FormRadioButton
        name={['subscriptionConfig', 'isMicrositeEnabled']}
        label="Microsite"
        selections={[
          { value: true, label: 'Active' },
          { value: false, label: 'Inactive' }
        ]}
        buttonStyle="solid"
        onChange={onChangeIsEnableMicrosite}
      />
      <FormRadioButton
        name={['subscriptionConfig', 'isBegkuningEnabled']}
        label={
          <span>
            <img
              src={TTLogoMini}
              style={{ marginRight: '4px' }}
              width="14px"
              height="14px"
              alt="TikTok - supporting begkuning for live sellers to sell on TikTok"
            />
            Beg Kuning
          </span>
        }
        selections={[
          { value: true, label: 'Active' },
          { value: false, label: 'Inactive' }
        ]}
        buttonStyle="solid"
      />
      <FormRadioButton
        name={['subscriptionConfig', 'isCustomerEnabled']}
        label="Customer Module"
        selections={[
          { value: true, label: 'Active' },
          { value: false, label: 'Inactive' }
        ]}
        buttonStyle="solid"
      />
      {isEnableMicrosite && (
        <PhotoManager
          value={customMicrositeLogo}
          onChange={onChangeCustomMicrositeLogo}
          label="Custom microsite logo"
          buttonLabel="Upload"
          fileSizeThresholdToCompressInKb={200}
          cropAspect={1}
          ref={customMicrositeLogoRef}
          storeId={storeId}
          shouldRemoveDocsMarkedForDelete
        />
      )}
      <FormRadioButton
        name={['subscriptionConfig', 'isCommissionEnabled']}
        label="Commission"
        selections={[
          { value: true, label: 'Active' },
          { value: false, label: 'Inactive' }
        ]}
        buttonStyle="solid"
      />
      <FormRadioButton
        name={['subscriptionConfig', 'isEmailCheckoutLinkEnabled']}
        label="Email Checkout Link"
        selections={[
          { value: true, label: 'Active' },
          { value: false, label: 'Inactive' }
        ]}
        buttonStyle="solid"
      />
    </>
  );
};

const Sellers = ({ operators, handleOnResetPasswordClick }) => {
  return <Table columns={constructAccountColumns(handleOnResetPasswordClick)} dataSource={operators} pagination={false} scroll={{ x: 600 }} />;
};

const PaymentGateway = () => {
  return (
    <>
      <FormInput name={['pgConfig', 'billplz', 'collectionId']} label="Billplz" placeholder="Billplz Collection ID" />
      <FormInput name={['pgConfig', 'fastPay', 'outletId']} label="FastPay" placeholder="FastPay Outlet ID" />
      <FormInput name={['pgConfig', 'razerPay', 'merchantId']} label="RazerPay Merchant ID" placeholder="RazerPay Merchant ID" />
      <FormInput name={['pgConfig', 'razerPay', 'verifyKey']} label="RazerPay Verify Key" placeholder="RazerPay Verify Key" />
      <FormInput name={['pgConfig', 'razerPay', 'secretKey']} label="RazerPay Secret Key" placeholder="RazerPay Secret Key" />
    </>
  );
};

const Boardcast = ({ locales, isEnabledAppNotif, onChangeIsEnabledAppNotif }) => {
  return (
    <>
      <FormInput name={['broadcastConfig', 'telegram', 'chatId']} label="Chat ID" placeholder="Chat ID" />
      <FormInput name={['broadcastConfig', 'telegram', 'inviteUrl']} label="Invite URL" placeholder="Invite URL" />
      <FormSelection name={['broadcastConfig', 'locales']} label="Supported Language" selections={locales} isMultiple isAllowClear />
      <FormRadioButton
        name={['notifConfig', 'isEnabled']}
        label="App Notification"
        selections={[
          { value: true, label: 'Active' },
          { value: false, label: 'Inactive' }
        ]}
        buttonStyle="solid"
        onChange={onChangeIsEnabledAppNotif}
      />
      {isEnabledAppNotif && <FormInput name={['notifConfig', 'inviteCode']} label="Invite Code" placeholder="Invite Code" />}
    </>
  );
};

/* =============================================== Main Component =============================================== */
const AccountDetailsModal = ({ selectedStoreId, token, locales, refetchStoreAccounts, onModalCancelClick, onModalOkClick }) => {
  const [form] = useForm();

  const [isUpdating, setIsUpdating] = useState(false);

  const { isLoadingConstants, subscriptionTypes, subscriptionTypesConst, subscriptionPeriods, subscriptionPeriodsConst } = useFetchConstants();
  const {
    isSubscriptionDetailsLoading,
    isSubscriptionStarter,
    isEnabledAppNotif,
    isEnableMicrosite,
    customMicrositeLogo,
    selectedSubscriptionType,
    accountName,
    accountOperators,
    formInitialValues,
    customMicrositeLogoRef,
    handleOnChangeSubscriptionType,
    handleOnChangeSubscriptionPeriod,
    handleOnChangeIsEnabledAppNotif,
    handleOnChangeIsEnableMicrosite,
    handleOnChangeCustomMicrositeLogo,
    refetchAccountDetails
  } = useGetAccountDetailsPostProcess(form, selectedStoreId, token, isLoadingConstants, subscriptionTypesConst, subscriptionPeriodsConst);
  const { isLoadingOrderLimitDetails, starterTotalOrder, starterOrderLimit } = useGetStoreStarterOrderLimitDetails(selectedStoreId, token);

  const { isLoading } = useIsLoadings([isLoadingConstants, isSubscriptionDetailsLoading, isLoadingOrderLimitDetails]);

  const disabledSubscriptionPeriods = useMemo(
    () =>
      subscriptionPeriods.reduce((result, subscriptionPeriod) => {
        if (!subscriptionPeriod.allowedType.includes(selectedSubscriptionType)) {
          result.push(subscriptionPeriod.value);
        }

        return result;
      }, []),
    [selectedSubscriptionType, subscriptionPeriods]
  );

  const handleOnResetPasswordClick = (selectedUserId, selectedUserName) => {
    confirm({
      title: 'Reset password',
      content: 'Are you sure you want to reset this user password?',
      okText: "Yes, I'm sure",
      onOk() {
        setIsUpdating(true);
        patchResetPasswordFromAdmin(selectedUserId, token)
          .then(() => {
            message.success(`You have reset this user ${selectedUserName} password!`);
          })
          .catch(ex => {
            logError(ex);
            message.error(`Something went wrong when resetting user ${selectedUserName} password: ${ex.message}`);
          })
          .finally(() => {
            setIsUpdating(false);
          });
      }
    });
  };

  const handleOnSave = useCallback(async () => {
    setIsUpdating(true);

    const payload = await form.validateFields().catch(() => {});

    const subscriptionConfig = payload.subscriptionConfig;
    const pgConfig = payload.pgConfig;
    const broadcastConfig = payload.broadcastConfig;
    const notifConfig = payload.notifConfig;
    const [customMicrositeLogo] = customMicrositeLogoRef?.current ? await customMicrositeLogoRef.current.update() : [undefined];

    const formattedPayload = {
      status: payload.status,
      ...(!!subscriptionConfig && {
        subscriptionConfig: {
          subscriptionType: guard(() => subscriptionConfig.subscriptionType),
          subscriptionPeriod: guard(() => subscriptionConfig.subscriptionPeriod),
          expiryDate: guard(() => subscriptionConfig.expiryDate),
          isMicrositeEnabled: guard(() => subscriptionConfig.isMicrositeEnabled),
          isBegkuningEnabled: guard(() => subscriptionConfig.isBegkuningEnabled),
          isCommissionEnabled: guard(() => subscriptionConfig.isCommissionEnabled),
          isCustomerEnabled: guard(() => subscriptionConfig.isCustomerEnabled),
          isEmailCheckoutLinkEnabled: guard(() => subscriptionConfig.isEmailCheckoutLinkEnabled)
        }
      }),
      ...(!!pgConfig && {
        pgConfig: {
          billplz: { collectionId: guard(() => pgConfig.billplz.collectionId) },
          fastPay: { outletId: guard(() => pgConfig.fastPay.outletId) },
          razerPay: {
            merchantId: guard(() => pgConfig.razerPay.merchantId),
            verifyKey: guard(() => pgConfig.razerPay.verifyKey),
            secretKey: guard(() => pgConfig.razerPay.secretKey)
          }
        }
      }),
      ...(!!broadcastConfig && {
        broadcastConfig: {
          locales: guard(() => broadcastConfig.locales),
          ...((broadcastConfig.telegram.chatId || broadcastConfig.telegram.inviteUrl) && {
            telegram: {
              chatId: guard(() => broadcastConfig.telegram.chatId),
              inviteUrl: guard(() => broadcastConfig.telegram.inviteUrl)
            }
          })
        }
      }),
      ...(!!notifConfig && {
        notifConfig: {
          isEnabled: guard(() => notifConfig.isEnabled),
          ...(notifConfig.isEnabled && {
            inviteCode: guard(() => notifConfig.inviteCode)
          })
        }
      }),
      ...(!!customMicrositeLogo && {
        micrositeSettings: {
          customLogo: customMicrositeLogo
        }
      })
    };

    await patchStoreAccountsFromAdmin(selectedStoreId, formattedPayload, token)
      .then(() => {
        message.success('Updated!');
      })
      .catch(ex => {
        logError(ex);
        message.error(`Something went wrong when updating status: ${ex.message}`);
      });

    refetchStoreAccounts();

    setIsUpdating(false);
  }, [form, selectedStoreId, token, refetchStoreAccounts, customMicrositeLogoRef]);

  return (
    <Modal
      title={accountName}
      visible={true}
      footer={
        <StyledFooterContainer>
          <Button type="primary" icon={<CheckOutlined />} shape="round" value="Save" size="middle" onClick={handleOnSave} loading={isUpdating}>
            Save
          </Button>
        </StyledFooterContainer>
      }
      onOk={onModalOkClick}
      onCancel={onModalCancelClick}
      width="70%"
      bodyStyle={{ paddingTop: '8px' }}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Spin tip="Loading..." spinning={isUpdating}>
          <Form form={form} initialValues={formInitialValues} scrollToFirstError style={{ width: '100%' }}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Store" key="1">
                <Store
                  token={token}
                  storeId={selectedStoreId}
                  isSubscriptionStarter={isSubscriptionStarter}
                  subscriptionTypes={subscriptionTypes}
                  subscriptionPeriods={subscriptionPeriods}
                  disabledSubscriptionPeriods={disabledSubscriptionPeriods}
                  starterTotalOrder={starterTotalOrder}
                  starterOrderLimit={starterOrderLimit}
                  isEnableMicrosite={isEnableMicrosite}
                  customMicrositeLogo={customMicrositeLogo}
                  customMicrositeLogoRef={customMicrositeLogoRef}
                  onChangeSubscriptionType={handleOnChangeSubscriptionType}
                  onChangeSubscriptionPeriod={handleOnChangeSubscriptionPeriod}
                  onChangeIsEnableMicrosite={handleOnChangeIsEnableMicrosite}
                  onChangeCustomMicrositeLogo={handleOnChangeCustomMicrositeLogo}
                  refetchStoreAccounts={refetchStoreAccounts}
                  refetchAccountDetails={refetchAccountDetails}
                />
              </TabPane>
              <TabPane tab="Sellers" key="2">
                <Sellers operators={accountOperators} handleOnResetPasswordClick={handleOnResetPasswordClick} />
              </TabPane>
              <TabPane tab="Payment Gateway" key="3">
                <PaymentGateway />
              </TabPane>
              <TabPane tab="Broadcast" key="4">
                <Boardcast locales={locales} isEnabledAppNotif={isEnabledAppNotif} onChangeIsEnabledAppNotif={handleOnChangeIsEnabledAppNotif} />
              </TabPane>
            </Tabs>
          </Form>
        </Spin>
      )}
    </Modal>
  );
};

export default AccountDetailsModal;
