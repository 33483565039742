import React, { useEffect, useState } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { Card, Col, Button, Form, message, Modal, Row, Tooltip } from 'antd';
import { Helmet } from 'react-helmet-async';

import { postSignUp } from 'apis/auth';
import { withAppContext } from 'contexts/AppContext/AppContext';

import FormInput from 'components/FormInput/FormInput';
import FormGroupSelectionInput from 'components/FormGroupSelectionInput/FormGroupSelectionInput';
import FormSelection from 'components/FormSelection/FormSelection';
import FormPasswordInput from 'components/FormPasswordInput/FormPasswordInput';

import { useFetchConstant } from 'hooks/constants';
import { useLocationQuery } from 'hooks/router';
import { useAddHotjarScript } from 'hooks/hotjar';

import { IS_DEV } from 'config/env';

import { CAPITAL_ALPHANUMERIC_MAX_4_CHARS, CONTACT_NUMBER_REGEX, PASSWORD_REGEX, USERNAME_REGEX } from 'utils/constants';

import Logo from 'images/ulive-logo-wo-bg.png';

import {
  CardTitle,
  CursiveText,
  DescContainer,
  DescLastLineContainer,
  Description,
  FullWidthLogoRow,
  ModalSuccessMessageSignature,
  RootContainer,
  StoreShortNameAlert
} from './SignUp.styles';

const { useForm } = Form;

const useDetectCountry = () => {
  const { country: countryISOCode } = useLocationQuery();

  return { countryISOCode };
};

const SignUpChina = () => {
  const { source: signupSource } = useLocationQuery();
  const [form] = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false);

  const { countryISOCode } = useDetectCountry();
  const { selection: countries, isLoading: isLoadingCountry, data: countriesConst } = useFetchConstant('countries', {
    valueKey: 'phoneCode',
    labelKey: 'phoneCode',
    query: { country: countryISOCode }
  });
  const { selection: currencies, isLoading: isLoadingCurrencies, data: currenciesConst } = useFetchConstant('currencies', {
    valueKey: 'iso',
    labelKey: 'iso',
    query: { country: countryISOCode }
  });

  useAddHotjarScript(!IS_DEV);

  useEffect(() => {
    if (!isLoadingCountry && !isLoadingCurrencies) {
      form.setFieldsValue({ contact: { countryCode: countriesConst.CHINA.phoneCode } });
      form.setFieldsValue({ store: { currencyISOCode: currenciesConst.RMB.iso } });
    }
  }, [form, isLoadingCountry, isLoadingCurrencies, countriesConst, currenciesConst]);

  const handleOnSignUpButtonClick = async e => {
    e.preventDefault();
    try {
      setIsSubmitting(true);

      const values = await form.validateFields();
      const formattedStoreShortName = values.store && values.store.shortName && String(values.store.shortName).toUpperCase();
      const newPayload = {
        ...values,
        email: `${values.username}@ulive-dummy-placeholder.com`, // Note: China rarely use email, that's why put a placeholder
        store: {
          ...values.store,
          shortName: formattedStoreShortName,
          signupSource: signupSource || 'cn'
        }
      };

      return postSignUp(newPayload)
        .then(() => {
          setIsSignUpSuccess(true);
          Modal.success({
            title: '成功注册！',
            content: (
              <div>
                <p>
                  您已成功注册。 我们的团队目前正在为您准备帐户。 我们将在 <b>三个工作日与你联系</b>.
                </p>
                <ModalSuccessMessageSignature>谢谢，</ModalSuccessMessageSignature>
                <CursiveText>ulive账号管理团队</CursiveText>
              </div>
            )
          });
        })
        .catch(error => {
          const errMsg = error.message;
          const isErrRelatedToShortNameTaken = errMsg.includes('short name') && errMsg.includes('taken');
          !isErrRelatedToShortNameTaken && message.error(('无法注册。', { error: errMsg }));
          if (isErrRelatedToShortNameTaken) {
            form.setFields([
              {
                name: ['store', 'shortName'],
                errors: ['该简称已被使用。 请尝试另一个。']
              }
            ]);
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } catch (error) {
      setIsSubmitting(false);

      if (error.errorFields && error.errorFields.length > 0) {
        return;
      } else {
        message.error('注册时出了点问题。');
      }
    }
  };

  return (
    <RootContainer>
      <Helmet>
        <meta name="title" id="gtm-title" content="Sign Up" />
        <title>注册</title>
      </Helmet>
      <FullWidthLogoRow justify="center">
        <img src={Logo} alt="ulive-logo" width="20%" />
      </FullWidthLogoRow>

      <DescContainer justify="center">
        <Description>
          注册 <CursiveText bigger>ulive</CursiveText> 账号
        </Description>
      </DescContainer>
      <DescLastLineContainer justify="center">
        <Description>助您轻松管理和拓展您的电商直播以及其他形式线上销售业务。</Description>
      </DescLastLineContainer>
      <Card>
        <Form form={form}>
          <Row>
            <Col span={24} md={24}>
              <Card title={<CardTitle>帐户信息</CardTitle>} bordered={false} bodyStyle={{ padding: '24px 24px 0 24px' }}>
                <Row gutter={16}>
                  <Col span={24} md={12}>
                    <FormInput
                      name="username"
                      label={
                        <span>
                          用户名称{' '}
                          <Tooltip title="用户名只能设置一次。 将来无法进行任何更改。用户名需要6-20个字符字母数字，可包括'.'，'_'或'-'。">
                            <InfoCircleFilled />
                          </Tooltip>
                        </span>
                      }
                      requiredErrorMessage="请填写您的帐户用户名。"
                      extraRules={[
                        {
                          pattern: USERNAME_REGEX,
                          message: '用户名必须符合注册要求。'
                        }
                      ]}
                    />
                  </Col>
                  <Col span={24} md={12}>
                    <FormPasswordInput
                      name="password"
                      label={
                        <span>
                          密码{' '}
                          <Tooltip title="密码需要8-20个字符的字母数字。">
                            <InfoCircleFilled />
                          </Tooltip>
                        </span>
                      }
                      placeholder="例如：L0ng3rP@ssw0rd"
                      requiredErrorMessage="请填写您的密码。"
                      extraRules={[
                        {
                          pattern: PASSWORD_REGEX,
                          message: '密码需要8-20个字符的字母数字。'
                        }
                      ]}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24} md={24}>
              <Card title={<CardTitle>个人信息</CardTitle>} bordered={false} bodyStyle={{ padding: '24px 24px 0 24px' }}>
                <Row gutter={16}>
                  <Col span={24} md={12}>
                    <FormInput name="name" label="姓名" requiredErrorMessage="请填写您的姓名。" />
                  </Col>
                  <Col span={24} md={12}>
                    <FormGroupSelectionInput
                      label="微信号码"
                      inputName={['contact', 'contactNumber']}
                      selectionName={['contact', 'countryCode']}
                      inputPlaceholder="109876543"
                      selections={countries}
                      requiredErrorMessage="请填写您的联系电话。"
                      overrideExtraRules={[
                        {
                          pattern: CONTACT_NUMBER_REGEX,
                          message: '微信号码只能包含数字。'
                        }
                      ]}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card title={<CardTitle>店铺信息</CardTitle>} bordered={false} bodyStyle={{ padding: '24px 24px 8px 24px' }}>
                <Row gutter={16}>
                  <Col span={24} md={10}>
                    <FormInput name={['store', 'name']} label="店铺名称" requiredErrorMessage="请填写您的店铺名称。" />
                  </Col>
                  <Col span={24} md={14}>
                    <FormInput
                      name={['store', 'shortName']}
                      label="店铺简称 (唯一标识符， 字母简称)"
                      placeholder="例如：SMPL"
                      requiredErrorMessage="请填写您的店铺简称。"
                      extraProps={{
                        maxLength: 4
                      }}
                      extraRules={[
                        {
                          pattern: CAPITAL_ALPHANUMERIC_MAX_4_CHARS,
                          message: `店铺简称只能包含“-”、“&”和字母数字。`
                        }
                      ]}
                    />
                  </Col>
                  <Col span={24}>
                    <FormSelection
                      name={['store', 'currencyISOCode']}
                      label="店铺计价货币"
                      selections={currencies.map(currency => ({ value: currency.value, label: `${currency.label} (${currency.symbol})` }))}
                      placeholder="例如：RMB"
                      requiredErrorMessage="请选择您的店铺货币。"
                    />
                  </Col>
                  <Col span={24}>
                    <StoreShortNameAlert
                      showIcon
                      type="warning"
                      message={<b>为什么我们需要一个商店简称？</b>}
                      description={
                        <span>
                          商店简称是商店的唯一标识符，通常最多使用4个字符。 这将会影响您的订单号。
                          <br />
                          订单号例子: SMPL-OR-0001
                          <br />
                          <i>
                            <b>注意： </b> 一旦注册了，商店简称将不能再做更改。
                          </i>
                        </span>
                      }
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Button type="primary" block onClick={handleOnSignUpButtonClick} loading={isSubmitting} disabled={false}>
            {isSignUpSuccess ? '您已成功注册！' : '注册'}
          </Button>
        </Form>
      </Card>
    </RootContainer>
  );
};

export default withAppContext(SignUpChina);
