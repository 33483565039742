export const controlStyle = `
  display: flex;
  align-items: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;

  &:first-of-type {
    margin-top: 16px;
  }

  &:last-of-type {
    margin-bottom: -8px;
  }

  button + div:only-of-type {
    flex: 1;
    margin: 0 8px;
  }
`;

export const buttonStyle = `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  padding: 0;
  font-size: 18px;
  font-style: normal;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;

  &[disabled] {
    cursor: default;
  }
`;
