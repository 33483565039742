import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Col, Row } from 'antd';

const hideScrolBarCss = css`
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const WinnerContainer = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute !important;
  min-width: ${props => (props.hasEnded ? '480px' : '320px')};
  right: ${props => (props.hasEnded ? '240px' : '20px')};
  top: 40px;
  width: 30%;

  @media (min-width: 1440px) {
    right: ${props => (props.hasEnded ? '240px' : '40px')};
    width: 30%;
  }

  @media (min-width: 1600px) {
    right: ${props => (props.hasEnded ? '280px' : '-160px')};
  }
`;

export const SettingsContainer = styled(Row)`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const ContainerMask = styled.div`
  backdrop-filter: blur(10px);
  background-color: ${props => props.theme.color.white}04;
  padding: 10%;
  min-height: ${props => (props.hasEnded ? '88vh' : '72vh')};
`;

export const HeaderBanner = styled.img`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const WinnerListContainer = styled(Row)`
  height: 100%;
  max-height: ${props => (props.hasEnded ? '52vh' : '480px')};
  overflow-y: scroll;

  ${hideScrolBarCss}
`;

export const EndedMessageContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const EndedMessage = styled.p`
  margin: 0;
  font-size: 32px;
  text-align: center;
  color: ${props => props.theme.color.primary};
  font-family: ${props => props.theme.fontFamily.title};
  text-shadow: 0 0 6px rgba(25, 23, 67, 0.98), 0 0 30px rgba(25, 23, 67, 0.42), 0 0 12px rgba(254, 114, 18, 0.58), 0 0 22px rgba(254, 114, 18, 0.84),
    0 0 38px rgba(254, 114, 18, 0.88), 0 0 60px rgba(254, 114, 18, 1);
`;

export const EndedSubMessage = styled.p`
  font-size: 24px;
  text-align: center;
  margin: 0;
  color: ${props => props.theme.color.white};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-shadow: 0 0 6px rgba(25, 23, 67, 0.98), 0 0 30px rgba(25, 23, 67, 0.42), 0 0 12px rgba(254, 114, 18, 0.58), 0 0 22px rgba(254, 114, 18, 0.84),
    0 0 38px rgba(254, 114, 18, 0.88), 0 0 60px rgba(254, 114, 18, 1);
`;

export const WinnerDetailsContainer = styled(Col)`
  backdrop-filter: blur(10px);
  background-color: ${props => props.theme.color.white}E4;
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 2%;
  padding: 2% 4%;
`;

export const NoWinnersContainer = styled(Col)`
  justify-content: center;
  display: flex;
  align-items: center;
  height: 52vh;
`;

export const WinnerRecordContainer = styled(Row)`
  flex-direction: row;
  width: 100%;
`;

export const WinnerAvatar = styled.div`
  background-image: ${props => props.profilePicUrl && `url("${props.profilePicUrl}")`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 40px;
  margin-right: ${props => props.theme.spacing.xs};
  width: 40px;
`;

export const WinnerName = styled.p`
  color: ${props => props.theme.color.tertiary};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin: 0;
`;

export const WinnerPrizeRemarks = styled.p`
  color: ${props => props.theme.color.grey};
  font-weight: ${props => props.theme.fontWeight.regular};
  font-size: 14px;
  font-style: italic;
  margin: 0;
  margin-top: 4px;
`;

export const EndButton = styled(Button)`
  margin-top: 24px;
  background-color: ${props => props.theme.color.tertiary};
  border-color: ${props => props.theme.color.tertiary};
  color: ${props => props.theme.color.white};
`;
