import { useCallback, useMemo, useState } from 'react';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Form, message, Modal, Row, Skeleton, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useGetPromotionCustomers, deletePromotionCustomer, postCreateNewCustomer } from 'apis/promotion';
import { useGetStoreCustomerSuggestions } from 'apis/storecustomer';

import { useFetchConstant } from 'hooks/constants';

import { constructColumn, constructColumnFilterSearch, formatPaginationShowTotalDisplay } from 'utils/table/table';
import { getFormattedContact, guard } from 'utils/general';

import FormSelection from 'components/FormSelection/FormSelection';
import TextButton from 'components/TextButton/TextButton';

import { StyledFacebookOutlined, StyledInstagramOutlined, StyledTikTokOutlined } from './CustomerList.styles';

const { confirm, error } = Modal;
const { useForm } = Form;

const constructCustomerSuggestionLabel = ({ buyer: storeCustomer, isSourceFacebook, isSourceInstagram, isSourceTikTok }) => {
  const { name, email, contact } = storeCustomer;

  const constructNameSuffix = () => {
    let suffix = '';

    const emailSuffix = guard(() => email || '', '');
    const contactNumberSuffix = guard(() => (contact ? getFormattedContact(contact) : ''), '');

    if (emailSuffix && contactNumberSuffix) {
      suffix = ` (${emailSuffix} | ${contactNumberSuffix})`;
    } else if (emailSuffix || contactNumberSuffix) {
      suffix = emailSuffix ? ` (${emailSuffix})` : ` (${contactNumberSuffix})`;
    }
    return suffix;
  };

  const constructedName = `${name}${constructNameSuffix()}`;

  switch (true) {
    case isSourceFacebook:
      return (
        <span>
          <StyledFacebookOutlined /> {constructedName}
        </span>
      );
    case isSourceInstagram:
      return (
        <span>
          <StyledInstagramOutlined /> {constructedName}
        </span>
      );
    case isSourceTikTok:
      return (
        <span>
          <StyledTikTokOutlined /> {constructedName}
        </span>
      );
    default:
      return constructedName;
  }
};

const constructColumns = ({ t, handleOnRemoveBtnClick }) => {
  const columns = [
    {
      ...constructColumn(t('pagePromotionDetails:table-header-promotion-customer'), 'customer', { width: '40%' }),
      ...constructColumnFilterSearch('customer', t('pagePromotionDetails:table-filter-name-placeholder')),
      render: (customer, record) => {
        return (
          <>
            {record.profilePicUrl && (
              <>
                <Avatar size="small" src={`${record.profilePicUrl}`} />{' '}
              </>
            )}
            <span>{customer}</span>
          </>
        );
      }
    },
    {
      ...constructColumn(t('pagePromotionDetails:table-header-promotion-status'), 'isAvailable'),
      render: isAvailable => <p>{t(`pagePromotionDetails:table-content-status-${isAvailable ? 'available' : 'applied'}`)}</p>
    },
    {
      ...constructColumn(t('pagePromotionDetails:table-header-promotion-actions'), 'actions', { width: '30%' }),
      render: (text, record) => {
        return (
          <Row>
            <TextButton
              icon={<CloseOutlined />}
              onClick={() => handleOnRemoveBtnClick(record.customerPromoId)}
              text={t('pagePromotionDetails:table-action-remove-promotion')}
              disabled={!record.isAvailable}
            />
          </Row>
        );
      }
    }
  ];
  return columns;
};

const GiveawayVoucherModal = ({ t, promotionId, onCancel, refetchPromotionCustomers }) => {
  const [form] = useForm();

  const [buyerSearchKeyword, setBuyerSearchKeyword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: socialMediaSourcesConst, isLoading: isSocialMediaSourcesLoading } = useFetchConstant('socialMediaSources');
  const { isLoading: isBuyersLoading, data: buyers } = useGetStoreCustomerSuggestions(buyerSearchKeyword);

  const buyersSelection = useMemo(
    () =>
      isSocialMediaSourcesLoading || isBuyersLoading || !buyers
        ? []
        : buyers.map(buyer => ({
            ...buyer,
            value: buyer._id,
            label: constructCustomerSuggestionLabel({
              buyer,
              isSourceFacebook: buyer.source && buyer.source === socialMediaSourcesConst.FB.code,
              isSourceInstagram: buyer.source && buyer.source === socialMediaSourcesConst.IG.code,
              isSourceTikTok: buyer.source && buyer.source === socialMediaSourcesConst.TT.code
            })
          })),
    [isBuyersLoading, isSocialMediaSourcesLoading, buyers, socialMediaSourcesConst]
  );

  const handleOnSaveCustomer = async () => {
    if (!isSubmitting) {
      const payload = await form.validateFields();
      const newPayload = { promotionId, customerId: payload.customerId };
      setIsSubmitting(true);
      await postCreateNewCustomer(newPayload)
        .then(() => {
          message.success(t('common:create-success-message'));
          refetchPromotionCustomers();
        })
        .catch(ex => {
          message.error(`Something went wrong when creating customer: ${ex.message}`);
        })
        .finally(() => {
          setIsSubmitting(false);
          onCancel();
        });
    }
  };

  return (
    <Modal visible onCancel={onCancel} onOk={handleOnSaveCustomer} title="Add New Customers">
      <Form form={form}>
        <FormSelection
          label={t('pagePromotionDetails:giveaway-voucher-for-customer-selection-label')}
          name="customerId"
          selections={buyersSelection}
          filterOption={false}
          onSearch={value => setBuyerSearchKeyword(value)}
          requiredErrorMessage={t('pagePromotionDetails:error-modal-customer-selection-message')}
          customMarginBottom={0}
        />
      </Form>
    </Modal>
  );
};

const CustomerList = ({ promotionId }) => {
  const { t } = useTranslation(['common', 'pagePromotionDetails']);

  const [isAddNewCustomer, setIsAddNewCustomer] = useState(false);

  const promotionCustomersProcessFunc = useCallback(promotionCustomers => {
    return promotionCustomers.map(promotionCustomer => {
      const sourceUserName =
        promotionCustomer.source && promotionCustomer.sourceUserName
          ? ` (${promotionCustomer.source.toUpperCase()}: ${promotionCustomer.sourceUserName})`
          : '';
      const contactNumber = promotionCustomer.contact ? ` (${getFormattedContact(promotionCustomer.contact)})` : '';
      return {
        ...promotionCustomer,
        customer: `${promotionCustomer.name}${sourceUserName}${contactNumber}`
      };
    });
  }, []);

  const { isLoading: isPromotionCustomersLoading, data: promotionCustomers, refetch: refetchPromotionCustomers } = useGetPromotionCustomers(
    promotionId,
    {},
    { postProcessFunc: promotionCustomersProcessFunc }
  );

  const handleOnRemoveBtnClick = customerPromoId => {
    confirm({
      title: t('pagePromotionDetails:confirm-modal-promotion-remove-title'),
      icon: <ExclamationCircleOutlined />,
      okText: t('pagePromotionDetails:confirm-modal-promotion-remove-button-text'),
      cancelText: t('common:modal-cancel-text'),
      onOk() {
        deletePromotionCustomer(promotionId, customerPromoId)
          .then(() => {
            message.success(t('common:remove-success-message'));
            refetchPromotionCustomers();
          })
          .catch(ex => {
            error({
              title: ex.message
            });
          });
      },
      onCancel() {}
    });
  };

  const handleOnAddCustomerClick = () => {
    setIsAddNewCustomer(true);
  };

  const handleOnAddCustomerClose = () => {
    setIsAddNewCustomer(false);
  };

  return (
    <>
      {isPromotionCustomersLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Button style={{ marginBottom: '8px' }} type="primary" shape="round" icon={<PlusOutlined />} onClick={() => handleOnAddCustomerClick()}>
            {t('pagePromotionDetails:giveaway-voucher-for-customer')}
          </Button>
          <Table
            bordered
            size="small"
            columns={constructColumns({ t, handleOnRemoveBtnClick })}
            dataSource={promotionCustomers}
            pagination={{ showSizeChanger: true, showTotal: formatPaginationShowTotalDisplay, defaultPageSize: 50 }}
          />
        </>
      )}
      {isAddNewCustomer && (
        <GiveawayVoucherModal
          t={t}
          promotionId={promotionId}
          onCancel={handleOnAddCustomerClose}
          refetchPromotionCustomers={refetchPromotionCustomers}
        />
      )}
    </>
  );
};

export default CustomerList;
