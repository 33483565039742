import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { BellOutlined, ControlOutlined, DesktopOutlined, FileTextOutlined, ShopOutlined, TagsOutlined, UserOutlined } from '@ant-design/icons';

import {
  buildBaseUri,
  getCustomersRoute,
  getOverviewRoute,
  getOrdersRoute,
  getLiveSessionsRoute,
  getProductsRoute,
  getNotificationRoute,
  getSettingsRoute,
  getReportingRoute
} from 'utils/routes';

import { ActiveMenuItem, MenuContainer, MenuItem } from './Menu.styles';

const overviewRoute = { ...getOverviewRoute() };
const ordersRoute = { ...getOrdersRoute() };
const liveSessionsRoute = { ...getLiveSessionsRoute() };
const productsRoute = { ...getProductsRoute() };
const notificationRoute = { ...getNotificationRoute() };
const customersRoute = { ...getCustomersRoute() };
const settingsRoute = { ...getSettingsRoute() };
const reportingRoute = { ...getReportingRoute() };

const getCurrentPathMenuKey = pathname => {
  switch (true) {
    case pathname.startsWith(overviewRoute.path):
      return overviewRoute.path;
    case pathname.startsWith(ordersRoute.path):
      return ordersRoute.path;
    case pathname.startsWith(liveSessionsRoute.path):
      return liveSessionsRoute.path;
    case pathname.startsWith(productsRoute.path):
      return productsRoute.path;
    case pathname.startsWith(notificationRoute.path):
      return notificationRoute.path;
    case pathname.startsWith(customersRoute.path):
      return customersRoute.path;
    case pathname.startsWith(settingsRoute.path):
      return settingsRoute.path;
    case pathname.startsWith(reportingRoute.path):
      return reportingRoute.path;

    default:
      return '';
  }
};

const NavMenu = ({ userRole, checkCanUserAccess, storeOperatorRoles, isEnabledAppNotif, isCustomerEnabled }) => {
  const history = useHistory();
  const { t } = useTranslation(['layoutStandard']);
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState('');

  const allNonOwnerRoles = [
    storeOperatorRoles.ADMIN.code,
    storeOperatorRoles.OPERATOR.code,
    storeOperatorRoles.LIVE_STREAMER.code,
    storeOperatorRoles.PACKAGING.code
  ];

  const menuItems = [
    {
      key: overviewRoute.path,
      name: t('layoutStandard:menu-item-label-overview'),
      icon: <DesktopOutlined />,
      canUserAccess: checkCanUserAccess([storeOperatorRoles.ADMIN.code, storeOperatorRoles.OPERATOR.code, storeOperatorRoles.LIVE_STREAMER.code])
    },
    {
      key: productsRoute.path,
      name: t('layoutStandard:menu-item-label-products'),
      icon: <ShopOutlined />,
      canUserAccess: checkCanUserAccess(allNonOwnerRoles)
    },
    {
      key: ordersRoute.path,
      name: t('layoutStandard:menu-item-label-orders'),
      icon: <TagsOutlined />,
      canUserAccess: checkCanUserAccess(allNonOwnerRoles)
    },
    {
      key: customersRoute.path,
      name: t('layoutStandard:menu-item-label-customers'),
      icon: <UserOutlined />,
      canUserAccess: isCustomerEnabled && checkCanUserAccess([storeOperatorRoles.ADMIN.code, storeOperatorRoles.OPERATOR.code])
    },
    {
      key: notificationRoute.path,
      name: t('layoutStandard:menu-item-label-notification'),
      icon: <BellOutlined />,
      canUserAccess: isEnabledAppNotif && checkCanUserAccess([storeOperatorRoles.ADMIN.code, storeOperatorRoles.OPERATOR.code])
    },
    {
      key: settingsRoute.path,
      name: t('layoutStandard:menu-item-label-settings'),
      icon: <ControlOutlined />,
      canUserAccess: checkCanUserAccess([storeOperatorRoles.ADMIN.code, storeOperatorRoles.OPERATOR.code])
    },
    {
      key: reportingRoute.path,
      name: t('layoutStandard:menu-item-label-reporting'),
      icon: <FileTextOutlined />,
      canUserAccess: checkCanUserAccess([storeOperatorRoles.ADMIN.code])
    }
  ];

  useEffect(() => {
    const currentKey = getCurrentPathMenuKey(location.pathname);
    if (selectedKey !== currentKey) {
      setSelectedKey(currentKey);
    }
  }, [location, selectedKey]);

  const getMenuItem = ({ key, name, icon }) => {
    return selectedKey === key ? (
      <ActiveMenuItem key={key} icon={icon}>
        {name}
      </ActiveMenuItem>
    ) : (
      <MenuItem key={key} icon={icon}>
        {name}
      </MenuItem>
    );
  };

  const handleOnMenuItemClick = ({ key }) => {
    setSelectedKey(key);
    if (key === buildBaseUri()) {
      // TODO: Once have authentication, add this.
      // onLogout();
      const defaultRoutePath = userRole === storeOperatorRoles.PACKAGING.code ? ordersRoute.path : overviewRoute.path;
      setSelectedKey(defaultRoutePath);
      history.push(defaultRoutePath);
    }
    history.push(key);
  };

  return (
    <MenuContainer
      onClick={handleOnMenuItemClick}
      style={{ width: '100%' }}
      mode="inline"
      defaultSelectedKeys={[selectedKey]}
      selectedKeys={[selectedKey]}
    >
      {menuItems.filter(menuItem => menuItem.canUserAccess).map(menuItem => getMenuItem(menuItem))}
    </MenuContainer>
  );
};

export default NavMenu;
