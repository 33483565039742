import { useMemo, useState, useEffect, useRef } from 'react';
import { Button, Col, Form, Modal, message, Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  deleteOrderManualPayment,
  patchOrderManualPayment,
  postCreateOrderPayment,
  useGetOrderPaymentDetails,
  useRefetchOrderDetails,
  useRefetchOrderPayments
} from 'apis/order';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import FormUpload from 'components/Upload/FormUpload/FormUpload';
import PhotoManager from 'components/PhotoManager/PhotoManager';

import { useFetchConstant } from 'hooks/constants';
import { constructConstantLabel } from 'utils/constants';
import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import FormSelection from 'components/FormSelection/FormSelection';

import { withAppContext } from 'contexts/AppContext/AppContext';

const { useForm } = Form;

const PaymentInfoModal = ({ store, visible, orderId, paymentId, balanceAmount, onClose }) => {
  const { t } = useTranslation(['common', 'modalPaymentInfo']);
  const isNew = !paymentId;
  const [form] = useForm();
  const receiptsRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isLoading: isPaymentMethodsLoading, selection: paymentMethods, data: paymentMethodsConst } = useFetchConstant('paymentMethods');
  const { isLoading: isPaymentStatusesLoading, selection: paymentStatuses, data: paymentStatusesConst } = useFetchConstant('paymentStatuses');
  const { isLoading: isPaymentDetailsLoading, data: paymentDetails, refetch: refetchOrderPaymentDetails } = useGetOrderPaymentDetails(
    orderId,
    paymentId
  );
  const [currentPhotos, setCurrentPhotos] = useState(paymentDetails?.receipts?.length ? paymentDetails.receipts : []);
  const [isCompressing, setIsCompressing] = useState(false);
  const shouldUsePhotoManager = !paymentDetails?.receiptsFiles?.length;

  useEffect(() => {
    if (!currentPhotos.length && paymentDetails?.receipts?.length) {
      setCurrentPhotos(paymentDetails.receipts);
    }
  }, [currentPhotos, paymentDetails]);

  const refetchOrderPayments = useRefetchOrderPayments();
  const refetchOrderDetails = useRefetchOrderDetails();

  const paymentGatewayPaymentMethods = useMemo(
    () =>
      paymentMethodsConst
        ? Object.values(paymentMethodsConst).filter(paymentMethodConst => {
            return !!paymentMethodConst.paymentGateway;
          })
        : [],
    [paymentMethodsConst]
  );

  const isPaymentGatewayPayment = useMemo(() => {
    return !!paymentGatewayPaymentMethods.find(paymentMethodConst => {
      return paymentMethodConst.code === paymentDetails.method;
    });
  }, [paymentDetails, paymentGatewayPaymentMethods]);

  const canEdit = useMemo(() => isNew || !isPaymentGatewayPayment, [isPaymentGatewayPayment, isNew]);

  const handleOnFormSave = async e => {
    e.preventDefault();

    try {
      const allFieldsValue = await form.validateFields();
      const receipts = await receiptsRef.current.update();
      setIsSubmitting(true);
      if (isNew) {
        postCreateOrderPayment(orderId, {
          ...allFieldsValue,
          receipts
        })
          .then(() => {
            message.success(t('common:add-success-message'));
            refetchOrderPaymentDetails();
            refetchOrderPayments();
            refetchOrderDetails();
            onClose();
          })
          .catch(ex => {
            message.error(ex.message);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      } else {
        patchOrderManualPayment(orderId, paymentId, {
          ...allFieldsValue,
          receipts
        })
          .then(() => {
            message.success(t('common:update-success-message'));
            refetchOrderPaymentDetails();
            refetchOrderPayments();
            refetchOrderDetails();
            onClose();
          })
          .catch(ex => {
            message.error(`${t('common:update-fail-message')} due to ${ex.message}`);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsSubmitting(false);
      message.error(t('common:update-fail-message'));
    }
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  const handleOnDeleteClick = () => {
    const itemNameForLabel = !!paymentDetails.refId ? paymentDetails.refId : constructConstantLabel(paymentMethods, paymentDetails.method);
    setIsSubmitting(true);
    Modal.confirm({
      title: <span>{t('common:confirm-modal-delete-title', { itemName: itemNameForLabel })}</span>,
      content: <p>{t('common:confirm-modal-delete-content')}</p>,
      onOk: () =>
        deleteOrderManualPayment(orderId, paymentId)
          .then(() => {
            message.success(
              t('common:delete-success-message', {
                itemName: itemNameForLabel
              })
            );
            refetchOrderPaymentDetails();
            refetchOrderPayments();
            refetchOrderDetails();
            onClose();
          })
          .catch(ex => {
            message.error(ex.message);
          }),
      onCancel: () => setIsSubmitting(false),
      okText: t('common:form-submit-delete-button-text'),
      cancelText: t('common:modal-cancel-text')
    });
  };

  const isLoading = isPaymentMethodsLoading || isPaymentDetailsLoading || isPaymentStatusesLoading;

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={
        isNew
          ? t('modalPaymentInfo:new-title')
          : t('modalPaymentInfo:edit-title', {
              ref: !!paymentDetails.refId ? paymentDetails.refId : constructConstantLabel(paymentMethods, paymentDetails.method)
            })
      }
      okText={isNew ? t('modalPaymentInfo:new-ok-text') : t('modalPaymentInfo:edit-ok-text')}
      destroyOnClose={true}
      footer={
        <Row justify="end" gutter={[8, 8]}>
          <Col>
            <Button loading={isLoading || isSubmitting || isCompressing} type="default" icon={<CloseOutlined />} onClick={onClose}>
              {t('common:modal-cancel-text')}
            </Button>
          </Col>
          {!isNew && !isPaymentGatewayPayment && (
            <Col>
              <Button loading={isLoading || isSubmitting || isCompressing} type="danger" icon={<DeleteOutlined />} onClick={handleOnDeleteClick}>
                {t('modalPaymentInfo:delete-text')}
              </Button>
            </Col>
          )}
          {(isNew || !isPaymentGatewayPayment) && (
            <Col>
              <Button loading={isLoading || isSubmitting || isCompressing} type="primary" icon={<CheckOutlined />} onClick={handleOnFormSave}>
                {isNew ? t('modalPaymentInfo:new-ok-text') : t('modalPaymentInfo:edit-ok-text')}
              </Button>
            </Col>
          )}
        </Row>
      }
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          initialValues={{ status: paymentStatusesConst.PENDING.code, amount: balanceAmount, ...paymentDetails }}
          scrollToFirstError={true}
          style={{ width: '100%' }}
          onFinishFailed={handleOnSaveFailed}
        >
          <div>
            {!isNew && (
              <FormInput
                label={t('modalPaymentInfo:form-input-label-ref-id')}
                name="refId"
                placeholder={t('modalPaymentInfo:form-input-placeholder-ref-id')}
                requiredErrorMessage={t('modalPaymentInfo:form-input-required-message-ref-id')}
                disabled={!canEdit}
              />
            )}
            <FormInputNumber
              label={t('modalPaymentInfo:form-input-label-amount')}
              name="amount"
              type="financial"
              placeholder={t('modalPaymentInfo:form-input-placeholder-amount')}
              requiredErrorMessage={t('modalPaymentInfo:form-input-required-message-amount')}
              disabled={!canEdit}
            />
            {!isNew && (
              <FormSelection
                label={t('modalPaymentInfo:form-input-label-status')}
                name="status"
                requiredErrorMessage={t('modalPaymentInfo:form-input-required-message-status')}
                selections={paymentStatuses}
                disabled={!canEdit}
              />
            )}
            <FormRadioButton
              label={t('modalPaymentInfo:form-input-label-payment-method')}
              name="method"
              selections={paymentMethods}
              disableValues={paymentGatewayPaymentMethods.map(onlinePaymentMethod => onlinePaymentMethod.code)}
              requiredErrorMessage={t('modalPaymentInfo:form-input-required-message-payment-method')}
              buttonStyle="solid"
              shouldDisable={!canEdit}
            />
            {shouldUsePhotoManager ? (
              <PhotoManager
                value={currentPhotos}
                onChange={setCurrentPhotos}
                onStatusChange={setIsCompressing}
                buttonLabel={t('pageCheckout:form-submit-upload-button-text')}
                fileSizeThresholdToCompressInKb={400}
                ref={receiptsRef}
                multiple
                shouldRemoveDocsMarkedForDelete
                allowPdf
                storeId={store._id}
              />
            ) : (
              <FormUpload
                name="receiptFiles"
                buttonLabel={t('modalPaymentInfo:form-upload-button-receipts')}
                acceptTypes={['image/*', '.pdf']}
                fileSizeLimitInMb={10}
                isMultiple
                isDisabled={!canEdit}
              />
            )}
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default withAppContext(PaymentInfoModal);
