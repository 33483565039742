import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Card, Col, Form, message, Row, Skeleton, Table } from 'antd';
import { CheckOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import { useGetProductShipmentShippingFeeTiers, patchShipmentShippingFeeTiers } from 'apis/product';
import { useGetShipments } from 'apis/shipment';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormCheckboxToggle from 'components/Checkbox/FormCheckboxToggle/FormCheckboxToggle';
import FormSelection from 'components/FormSelection/FormSelection';
import TextButton from 'components/TextButton/TextButton';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';

import { constructColumn } from 'utils/table/table';

const { useForm } = Form;

const defaultData = {
  minQuantity: 2
};

const getNewDefaultData = (data = []) => {
  if (data) {
    const lastData = data[data.length - 1];
    if (lastData) {
      return { minQuantity: lastData.minQuantity + 1, fee: lastData.fee };
    }
  }
  return defaultData;
};

const constructColumns = ({ t, editingKey, handleOnClickEditTier, handleOnClickSaveTier, handleOnClickConfirmDeleteTier }) => [
  {
    ...constructColumn(t('pageProductDetails:shipment-table-header-min-quantity'), 'minQuantity', { width: '10%' }),
    editable: true
  },
  {
    ...constructColumn(t('pageProductDetails:shipment-table-header-fee'), 'fee', { width: '10%' }),
    editable: true,
    render: text => <PriceDisplay amount={text} />
  },
  {
    ...constructColumn(t('pageProductDetails:shipment-table-header-actions'), 'actions', { width: '15%' }),
    render: (okText, record, index) => {
      const editable = index === editingKey;
      return editable ? (
        <div>
          <TextButton
            icon={<CheckOutlined />}
            onClick={e => handleOnClickSaveTier(e, index)}
            text={t('pageProductDetails:shipment-text-button-update')}
            tooltipMessage={t('common:text-button-tooltip-message', { action: t('pageProductDetails:shipment-tooltip-message-action-update') })}
            htmlType="submit"
          />
          <TextButton
            icon={<DeleteOutlined />}
            onClick={() => handleOnClickConfirmDeleteTier(index)}
            text={t('pageProductDetails:shipment-text-button-remove')}
            tooltipMessage={t('common:text-button-tooltip-message', { action: t('pageProductDetails:shipment-tooltip-message-action-remove') })}
          />
        </div>
      ) : (
        <div>
          <TextButton
            icon={<EditOutlined />}
            onClick={() => handleOnClickEditTier(record, index)}
            text={t('pageProductDetails:shipment-text-button-edit')}
            tooltipMessage={t('common:text-button-tooltip-message', { action: t('pageProductDetails:shipment-tooltip-message-action-edit') })}
            disabled={editingKey !== ''}
          />
        </div>
      );
    }
  }
];

const useShipmentMethods = () => {
  const { isLoading: isShipmentsLoading, data: shipments } = useGetShipments();

  const shipmentMethods = useMemo(
    () =>
      isShipmentsLoading
        ? []
        : shipments
            .map(shipment => ({
              value: shipment._id,
              label: `${shipment.label} (${shipment.deliveryMethod})`
            }))
            .sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1)),
    [isShipmentsLoading, shipments]
  );

  return { isLoading: isShipmentsLoading, shipmentMethods };
};

const ShipmentSettings = ({ productId, onError = () => {} }) => {
  const { t } = useTranslation(['common', 'pageProductDetails']);
  const [form] = useForm();

  const [isDataInit, setIsDataInit] = useState(false);
  const [selectedShipmentId, setSelectedShipmentId] = useState();
  const [data, setData] = useState([]);
  const [shippingFeeTiersErrorMsg, setShippingFeeTiersErrorMsg] = useState('');
  const [editingKey, setEditingKey] = useState('');
  const [isProductShipmentEnabled, setIsProductShipmentEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isLoading: isShipmentMethodsLoading, shipmentMethods } = useShipmentMethods();
  const { isLoading: isShippingFeeTiersLoading, data: shippingFeeTiers, refetch: refetchShippingFeeTiers } = useGetProductShipmentShippingFeeTiers(
    productId,
    selectedShipmentId
  );

  useEffect(() => {
    if (!isShipmentMethodsLoading && shipmentMethods && shipmentMethods.length > 0 && !selectedShipmentId) {
      const defaultShipmentId = shipmentMethods[0].value;
      setSelectedShipmentId(defaultShipmentId);
      form.setFieldsValue({ shipmentMethod: defaultShipmentId });
    }
  }, [form, isShipmentMethodsLoading, selectedShipmentId, shipmentMethods]);

  useEffect(() => {
    if (!isDataInit && !isShippingFeeTiersLoading && selectedShipmentId) {
      const newIsProductShipmentEnabled = !!shippingFeeTiers;
      const defaultData = newIsProductShipmentEnabled ? shippingFeeTiers : [];

      setData(defaultData);
      setShippingFeeTiersErrorMsg('');
      setIsProductShipmentEnabled(newIsProductShipmentEnabled);
      form.setFieldsValue({ isProductShipmentEnabled: newIsProductShipmentEnabled });

      setIsDataInit(true);
    }
  }, [isDataInit, selectedShipmentId, isShippingFeeTiersLoading, shippingFeeTiers, form]);

  const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    const getInputNode = () => {
      switch (inputType) {
        case 'number':
          return (
            <FormInputNumber
              name={dataIndex}
              requiredErrorMessage={t('pageProductDetails:shipment-cell-form-error-message', { itemName: title.toLowerCase() })}
              placeholder="1"
              minValue={1}
            />
          );

        case 'price':
          return (
            <FormInputNumber
              name={dataIndex}
              type="financial"
              requiredErrorMessage={t('pageProductDetails:shipment-cell-form-error-message', { itemName: title.toLowerCase() })}
              placeholder="100.00"
            />
          );
        default:
          return (
            <FormInput
              name={dataIndex}
              requiredErrorMessage={t('pageProductDetails:shipment-cell-form-error-message', { itemName: title.toLowerCase() })}
              placeholder={title}
            />
          );
      }
    };

    return <td {...restProps}>{editing ? getInputNode() : children}</td>;
  };

  const handleOnClickAddTier = () => {
    const newDefaultData = getNewDefaultData(data);
    const newData = [...data, newDefaultData];
    setData(newData);
    setShippingFeeTiersErrorMsg('');
    form.setFieldsValue(newDefaultData);
    setEditingKey(newData.length - 1);
  };

  const handleOnClickEditTier = (record, index) => {
    form.setFieldsValue({
      ...record
    });
    setEditingKey(index);
  };

  const handleOnClickSaveTier = async (e, index) => {
    e.preventDefault();
    try {
      const { minQuantity, fee } = await form.validateFields();
      const currentPurchaseTier = { minQuantity: Number(minQuantity), fee: Number(fee) };
      const newData = [...data];
      if (
        !newData.find(data => data.minQuantity === currentPurchaseTier.minQuantity) ||
        newData.findIndex(data => data.minQuantity === currentPurchaseTier.minQuantity) === index
      ) {
        newData.splice(index, 1, currentPurchaseTier);
        const sortedNewData = newData.sort((dataA, dataB) => dataA.minQuantity - dataB.minQuantity);
        setData(sortedNewData);
        setEditingKey('');
        setShippingFeeTiersErrorMsg('');
      } else {
        setShippingFeeTiersErrorMsg(t('pageProductDetails:shipment-same-condition'));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      message.error(t('common:update-fail-message'));
    }
  };

  const handleOnClickConfirmDeleteTier = index => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
    setEditingKey('');
    setShippingFeeTiersErrorMsg('');
  };

  const getEditableCols = () => {
    const columns = constructColumns({
      t,
      editingKey,
      handleOnClickEditTier,
      handleOnClickSaveTier,
      handleOnClickConfirmDeleteTier
    });

    return columns.map(col => {
      if (!col.editable) {
        return col;
      }

      const getInputType = () => {
        switch (col.dataIndex) {
          case 'minQuantity':
            return 'number';

          case 'fee':
            return 'price';

          default:
            return 'text';
        }
      };

      return {
        ...col,
        onCell: (record, index) => {
          return {
            record,
            key: index,
            inputType: getInputType(col.dataIndex),
            dataIndex: col.dataIndex,
            title: col.title,
            editing: index === editingKey
          };
        }
      };
    });
  };

  const handleOnSaveSettings = values => {
    const { isProductShipmentEnabled, shipmentMethod } = values;
    setIsSubmitting(true);
    patchShipmentShippingFeeTiers(productId, shipmentMethod, isProductShipmentEnabled ? data : [])
      .then(() => {
        message.success(t('common:update-success-message'));
        refetchShippingFeeTiers();
      })
      .catch(ex => {
        message.error(ex.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  return (
    <Form form={form} onFinish={handleOnSaveSettings} onFinishFailed={handleOnSaveFailed}>
      <FormSelection
        label={t('pageProductDetails:shipment-method-selections-label')}
        name={['shipmentMethod']}
        selections={shipmentMethods}
        onChange={value => {
          setSelectedShipmentId(value);
          setIsDataInit(false);
        }}
        isAllowClear={false}
      />
      <Card>
        {isShippingFeeTiersLoading ? (
          <Skeleton active />
        ) : (
          <>
            <FormCheckboxToggle
              name="isProductShipmentEnabled"
              label={t('pageProductDetails:shipment-checkbox-text')}
              onChange={() => setIsProductShipmentEnabled(!isProductShipmentEnabled)}
            />
            {isProductShipmentEnabled && (
              <>
                <Row gutter={[0, 16]}>
                  <Button type="primary" onClick={handleOnClickAddTier} icon={<PlusOutlined />} disabled={editingKey !== ''}>
                    {t('pageProductDetails:shipment-add-button')}
                  </Button>
                </Row>
                <Table
                  rowKey={record => record.minQuantity}
                  size="small"
                  components={{
                    body: {
                      cell: EditableCell
                    }
                  }}
                  bordered
                  dataSource={data}
                  columns={getEditableCols()}
                  scroll={{ x: 'max-content' }}
                  locale={{
                    triggerDesc: t('common:table-header-sort-trigger-desc'),
                    triggerAsc: t('common:table-header-sort-trigger-asc'),
                    cancelSort: t('common:table-header-sort-cancel-sort')
                  }}
                  pagination={false}
                />
                {shippingFeeTiersErrorMsg && <Alert type="error" message={shippingFeeTiersErrorMsg} style={{ marginTop: 10 }} />}
              </>
            )}
          </>
        )}
        <Row gutter={8} style={{ margin: '16px 0px' }}>
          <Col>
            <Button htmlType="submit" type="primary" icon={<CheckOutlined />} loading={isSubmitting}>
              {t('pageProductDetails:shipment-update-button-text')}
            </Button>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default ShipmentSettings;
