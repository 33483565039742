import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { DeleteOutlined, PicRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import TextButton from 'components/TextButton/TextButton';

import { constructDisplayPriceWithCurrency } from 'utils/general';

const PromotionInfoItem = ({ promotion, storeCurrency, isInvalidVoucher = false, extraStyle, onRemoveClick }) => {
  const { t } = useTranslation(['common', 'pageOrderDetails']);

  const getDiscountText = promotion => {
    return promotion.discountPercentage ? `${promotion.discountPercentage}%` : `${storeCurrency.symbol}${promotion.discountAmount}`;
  };

  const getDescription = promotion => {
    return (
      <>
        {!!promotion.minSpendingAmount &&
          t('pageOrderDetails:promotion-text-order-promotion-min-spend', {
            priceWithCurrency: constructDisplayPriceWithCurrency({
              amount: promotion.minSpendingAmount,
              currencySymbol: storeCurrency.symbol,
              currencyISO: storeCurrency.iso
            })
          })}
        {!!promotion.minSpendingAmount && !!promotion.maxDiscountAmount && ' '}
        {!!promotion.maxDiscountAmount &&
          t('pageOrderDetails:promotion-text-order-promotion-capped', {
            priceWithCurrency: constructDisplayPriceWithCurrency({
              amount: promotion.maxDiscountAmount,
              currencySymbol: storeCurrency.symbol,
              currencyISO: storeCurrency.iso
            })
          })}
      </>
    );
  };

  return (
    <Alert
      message={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontSize: '14px' }}>
            {promotion.code} | {promotion.name}
          </span>
          <b>
            {getDiscountText(promotion)} {t('pageOrderDetails:promotion-text-order-promotion-off')}
          </b>
        </div>
      }
      style={{ alignItems: 'center', ...(isInvalidVoucher && { background: '#E9E9E9', borderColor: '#797979' }), ...extraStyle }}
      description={isInvalidVoucher ? t('pageOrderDetails:promotion-text-order-promotion-invalid') : getDescription(promotion)}
      type="warning"
      showIcon
      icon={<PicRightOutlined />}
      action={
        <TextButton
          icon={<DeleteOutlined />}
          onClick={() => {
            onRemoveClick(promotion.promotionId || promotion._id);
          }}
          text={t('pageOrderDetails:promotion-text-button-remove')}
          tooltipMessage={t('common:text-button-tooltip-message', {
            action: t('pageOrderDetails:promotion-text-button-remove-tooltip-message')
          })}
        />
      }
    />
  );
};

PromotionInfoItem.propTypes = {
  promotion: PropTypes.object,
  storeCurrency: PropTypes.object,
  onRemoveClick: PropTypes.func
};

export default PromotionInfoItem;
