import { useState, useMemo, useCallback, useEffect } from 'react';

// React hook for loading scripts
// Source: https://usehooks.com/useScript/

const SCRIPT_STATUS_IDLE = 'idle';
const SCRIPT_STATUS_LOADING = 'loading';
const SCRIPT_STATUS_READY = 'ready';
const SCRIPT_STATUS_ERROR = 'error';

export const useScript = src => {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? SCRIPT_STATUS_LOADING : SCRIPT_STATUS_IDLE);

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus(SCRIPT_STATUS_IDLE);
        return;
      }

      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`);

      if (!script) {
        // Create script
        script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.setAttribute('data-status', SCRIPT_STATUS_LOADING);
        // Add script to document body
        document.body.appendChild(script);

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = event => {
          script.setAttribute('data-status', event.type === 'load' ? SCRIPT_STATUS_READY : SCRIPT_STATUS_ERROR);
        };

        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status'));
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = event => {
        setStatus(event.type === 'load' ? SCRIPT_STATUS_READY : SCRIPT_STATUS_ERROR);
      };

      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);

      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [src] // Only re-run effect if script src changes
  );

  return status;
};

// ========================================== Local Storage
export const useLocalStorage = (key, { isValueJson = false, isSession = false } = {}) => {
  const [value, setValue] = useState();
  const [isInit, setIsInit] = useState(false);
  const storageApi = useMemo(() => (!isSession ? window.localStorage : window.sessionStorage), [isSession]);

  useEffect(() => {
    if (!isInit) {
      const value = storageApi.getItem(key);
      if (value) {
        setValue(isValueJson ? JSON.parse(value) : value);
      }
      setIsInit(true);
    }
  }, [key, isInit, isValueJson, storageApi]);

  const updateValue = useCallback(
    newValue => {
      const formattedNewValue = isValueJson ? JSON.stringify(newValue) : newValue;
      storageApi.setItem(key, formattedNewValue);
      setValue(newValue);
    },
    [key, isValueJson, storageApi]
  );

  const clearValue = useCallback(() => {
    storageApi.removeItem(key);
    setValue(undefined);
  }, [key, storageApi]);

  return { value, isInit, updateValue, clearValue };
};

// ========================================== Routes
export const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

// ========================================== Loading
export const useIsLoadings = isLoadingsInArray => {
  const isLoading = useMemo(() => isLoadingsInArray.reduce((isLoading, currentIsLoading) => isLoading || currentIsLoading, false), [
    isLoadingsInArray
  ]);

  return { isLoading };
};

// ========================================== Timer
export const useTimer = (timeInSec, isEnabled, onTimerEnd) => {
  const [timeLeft, setTimeLeft] = useState(timeInSec);

  useEffect(() => {
    if (timeLeft <= 0) {
      onTimerEnd();
      return () => {};
    }

    if (isEnabled) {
      const timeout = setTimeout(() => {
        setTimeLeft(prevTimeLeft => {
          return prevTimeLeft - 1;
        });
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [timeLeft, isEnabled, onTimerEnd]);

  return timeLeft;
};
