import styled from '@emotion/styled';
import { Avatar, List } from 'antd';

export const StyledListItemMeta = styled(List.Item.Meta)`
  margin-bottom: 12px !important;

  h4 {
    margin-bottom: 0 !important;
  }
`;

export const StyledAvatarWithName = styled(Avatar)`
  margin-top: 4px;
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.tertiary};
`;

export const InventoryGreenText = styled.b`
  color: ${props => props.theme.color.green};
`;

export const InventoryRedText = styled.b`
  color: ${props => props.theme.color.red};
`;
