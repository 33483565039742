import styled from '@emotion/styled';
import { Table } from 'antd';

export const InputContainer = styled.div`
  margin-bottom: -${props => props.theme.spacing.regular};
`;

export const StyledTable = styled(Table)`
  width: max-content;

  @media (min-width: 992px) {
    width: 100%;
  }
`;

export const UploadPhotoTableColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
