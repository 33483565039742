import queryString from 'query-string';

import { checkIsObjectEmpty } from './general';
import { customerDetailsTabsObj, settingTabsObj, productDetailsTabsObj, promotionDetailsTabsObj, reportingTabsObj } from './constants';

/** -------------------------------------Roles ------------------------------------- */
const ROLE_ADMIN = 'admin';
const ROLE_OPERATOR = 'operator';
const ROLE_LIVE_STREAMER = 'liveStreamer';
const ROLE_PACKAGING = 'packaging';

const allNonOwnerRoles = [ROLE_ADMIN, ROLE_OPERATOR, ROLE_LIVE_STREAMER, ROLE_PACKAGING];
/** -------------------------------------Route------------------------------------- */
const getRoute = (path, role, queryParams) => {
  if (checkIsObjectEmpty(queryParams)) {
    return { path, role };
  }

  const pathWithQuery = `${path}?${queryString.stringify(queryParams)}`;
  return { path: pathWithQuery, role };
};

export const buildBaseUri = () => '/';

/** -------------------------------------Error Routes------------------------------------- */
export const get403Route = () => getRoute('/403');
export const get404Route = () => getRoute('/404');

/** -------------------------------------Admin External Routes------------------------------------- */
export const getAdminAccountsRoute = () => getRoute(`/admin/external/accounts`);

/** -------------------------------------External Routes------------------------------------- */
export const getHomeRoute = () => getRoute('/home');
export const getLoginRoute = ({ reason } = {}) => getRoute('/login', undefined, reason ? { reason } : undefined);
export const getSignUpRoute = () => getRoute('/signup');
export const getSignUpChinaRoute = () => getRoute('/signup/cn');

export const getCheckoutRoute = (orderNumber = ':orderNumber', { accessCode, hasCheckout } = {}) =>
  getRoute(`/checkout/${orderNumber}`, undefined, accessCode || hasCheckout ? { accessCode, hasCheckout } : undefined);
export const getCheckoutPaymentRoute = (orderNumber = ':orderNumber') => getRoute(`/checkout/${orderNumber}/payment`);
export const getCheckoutInvoiceRoute = (orderNumber = ':orderNumber', { accessCode } = {}) =>
  getRoute(`/checkout/${orderNumber}/invoice`, undefined, accessCode ? { accessCode } : undefined);

export const getSubscriptionPlanPaymentRoute = (storeId = ':storeId') => getRoute(`/plan/checkout/${storeId}/payment`);

/** -------------------------------------Internal Routes------------------------------------- */
export const getOverviewRoute = () => getRoute('/overview', [ROLE_ADMIN, ROLE_OPERATOR, ROLE_LIVE_STREAMER]);
export const getPrintProductStickerRoute = (queryParams = {}) =>
  getRoute('/print-product-sticker', [ROLE_ADMIN, ROLE_OPERATOR, ROLE_LIVE_STREAMER], queryParams);

export const getLuckyDrawRoute = () => getRoute('/luckydraws', [ROLE_ADMIN, ROLE_OPERATOR, ROLE_LIVE_STREAMER]);
export const getLuckyDrawSlotMachineRoute = (id = ':id') =>
  getRoute(`/luckydraws/${id}/slotmachine`, [ROLE_ADMIN, ROLE_OPERATOR, ROLE_LIVE_STREAMER]);

export const getOrdersRoute = () => getRoute('/orders', allNonOwnerRoles);
export const getNewOrderRoute = () => getRoute('/orders/new', [ROLE_ADMIN, ROLE_OPERATOR, ROLE_LIVE_STREAMER]);
export const getOrderDetailRoute = (id = ':id') => getRoute(`/orders/${id}`, [ROLE_ADMIN, ROLE_OPERATOR, ROLE_LIVE_STREAMER]);
export const getOrderDetailEditRoute = (id = ':id') => getRoute(`/orders/${id}/edit`, [ROLE_ADMIN, ROLE_OPERATOR, ROLE_LIVE_STREAMER]);
export const getOrderDisplayPrintRoute = (id = ':id', queryParams = {}) =>
  getRoute(`/orders/${id}/print`, [ROLE_ADMIN, ROLE_OPERATOR, ROLE_PACKAGING], queryParams);
export const getBulkPrintOrdersRoute = (idsInArray, isCompact, queryParams = {}) =>
  getRoute(`/orders/print-bulk`, [ROLE_ADMIN, ROLE_OPERATOR, ROLE_PACKAGING], {
    ...queryParams,
    ...(isCompact && { compact: true }),
    ...(idsInArray && { ids: idsInArray.join(',') })
  });

export const getLiveSessionsRoute = () => getRoute('/lives', allNonOwnerRoles);
export const getNewLiveSessionRoute = () => getRoute('/lives/new', allNonOwnerRoles);
export const getLiveSessionDetailRoute = (id = ':id') => getRoute(`/lives/${id}`, allNonOwnerRoles);

export const getProductDetailsRoute = (id = ':id', queryTab) => {
  if (queryTab) {
    return getRoute(`/products/${id}?tab=${queryTab}`, [ROLE_ADMIN, ROLE_OPERATOR, ROLE_LIVE_STREAMER]);
  }
  return getRoute(`/products/${id}`, [ROLE_ADMIN, ROLE_OPERATOR, ROLE_LIVE_STREAMER]);
};
export const getProductsRoute = () => getRoute('/products', allNonOwnerRoles);
export const getNewProductRoute = () => getRoute('/products/new', [ROLE_ADMIN, ROLE_OPERATOR, ROLE_LIVE_STREAMER]);
export const getProductGeneralDetailsRoute = (id = ':id') => getProductDetailsRoute(id, productDetailsTabsObj.GENERAL.code);
export const getProductMicrositeDetailsRoute = (id = ':id') => getProductDetailsRoute(id, productDetailsTabsObj.MICROSITE.code);

export const getGiftDetailsRoute = (id = ':id', queryTab) => {
  if (queryTab) {
    return getRoute(`/gifts/${id}?tab=${queryTab}`, [ROLE_ADMIN, ROLE_OPERATOR, ROLE_LIVE_STREAMER]);
  }
  return getRoute(`/gifts/${id}`, [ROLE_ADMIN, ROLE_OPERATOR, ROLE_LIVE_STREAMER]);
};
export const getNewGiftRoute = () => getRoute('/gifts/new', [ROLE_ADMIN, ROLE_OPERATOR, ROLE_LIVE_STREAMER]);
export const getGiftGeneralDetailsRoute = (id = ':id') => getGiftDetailsRoute(id, productDetailsTabsObj.GENERAL.code);

export const getNotificationRoute = () => getRoute('/notification', [ROLE_ADMIN, ROLE_OPERATOR]);

export const getCustomersRoute = () => getRoute('/customers', [ROLE_ADMIN, ROLE_OPERATOR]);
export const getCustomerDetailsRoute = (id = ':id', queryTab) => {
  if (queryTab) {
    return getRoute(`/customers/${id}?tab=${queryTab}`, [ROLE_ADMIN, ROLE_OPERATOR]);
  }
  return getRoute(`/customers/${id}`, [ROLE_ADMIN, ROLE_OPERATOR]);
};
export const getCustomerGeneralDetailsRoute = (id = ':id') => getCustomerDetailsRoute(id, customerDetailsTabsObj.GENERAL.code);
export const getCustomerOrderDetailsRoute = (id = ':id') => getCustomerDetailsRoute(id, customerDetailsTabsObj.ORDERS.code);

export const getSettingsRoute = queryTab => {
  if (queryTab) {
    return getRoute(`/settings?tab=${queryTab}`, [ROLE_ADMIN, ROLE_OPERATOR]);
  }
  return getRoute('/settings', [ROLE_ADMIN, ROLE_OPERATOR]);
};

export const getShipmentsRoute = () => getSettingsRoute(settingTabsObj.SHIPMENT.code);
export const getNewShipmentRoute = () => getRoute('/settings/shipments/new', [ROLE_ADMIN, ROLE_OPERATOR]);
export const getShipmentDetailRoute = (id = ':id') => getRoute(`/settings/shipments/${id}`, [ROLE_ADMIN, ROLE_OPERATOR]);

export const getPromotionsRoute = () => getSettingsRoute(settingTabsObj.PROMOTION.code);
export const getNewPromotionRoute = () => getRoute('/settings/promotions/new', [ROLE_ADMIN, ROLE_OPERATOR]);
export const getPromotionDetailRoute = (id = ':id', queryTab) => {
  if (queryTab) {
    return getRoute(`/settings/promotions/${id}?tab=${queryTab}`, [ROLE_ADMIN, ROLE_OPERATOR]);
  }
  return getRoute(`/settings/promotions/${id}`, [ROLE_ADMIN, ROLE_OPERATOR]);
};
export const getPromotionGeneralDetailsRoute = (id = ':id') => getPromotionDetailRoute(id, promotionDetailsTabsObj.GENERAL.code);
export const getMicrositeSettingsRoute = () => getSettingsRoute(settingTabsObj.MICROSITE.code);

export const getAccountProfileRoute = () => getRoute('/me');

export const getTeamRoute = () => getRoute('/team', []);
export const getNewTeamRoute = () => getRoute('/team/new', []);
export const getTeamDetailRoute = (id = ':id') => getRoute(`/team/${id}`, []);

export const getSubscriptionPlanRoute = () => getRoute('/plans', []);

export const getReportingProductSoldRoute = () => getRoute('/reporting/product-sold', [ROLE_ADMIN]);
export const getReportingRoute = queryTab => {
  if (queryTab) {
    return getRoute(`/reporting?tab=${queryTab}`, [ROLE_ADMIN]);
  }
  return getRoute('/reporting', [ROLE_ADMIN]);
};
export const getReportingOrderPurchaseDateRoute = () => getReportingRoute(reportingTabsObj.ORDER_PURCHASE_DATE.code);
