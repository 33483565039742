import { FacebookOutlined, InstagramOutlined, UserOutlined } from '@ant-design/icons';
import { Statistic } from 'antd';

import { useTranslation } from 'react-i18next';

import { checkIsObjectEmpty } from 'utils/general';

import {
  ConnectionDetailsContainer,
  ConnectionDetailsComingSoonContainer,
  SocialMediaTitleContainer,
  SocialMediaTitleIcon,
  SocialMediaTitle
} from './SocialMediaConnectionContent.styles';

const SOCIAL_MEDIAS = {
  fb: { title: 'Facebook', icon: <FacebookOutlined />, themeColor: 'socialMediaFB' },
  ig: { title: 'Instagram', icon: <InstagramOutlined />, themeColor: 'socialMediaIG' }
};

const constructCustomValueStyle = hasConnected => ({
  fontSize: '14px',
  fontStyle: hasConnected ? 'normal' : 'italic',
  wordBreak: 'break-word'
});

const SocialMediaConnectionContent = ({ type, storeSocialMedia, isComingSoon = false }) => {
  const { t } = useTranslation(['compSocialMediaConnectionContent']);
  const hasConnected = !checkIsObjectEmpty(storeSocialMedia);

  return (
    <div>
      <SocialMediaTitleContainer>
        <SocialMediaTitleIcon themeColor={SOCIAL_MEDIAS[type].themeColor}>{SOCIAL_MEDIAS[type].icon}</SocialMediaTitleIcon>
        <SocialMediaTitle themeColor={SOCIAL_MEDIAS[type].themeColor}>{SOCIAL_MEDIAS[type].title}</SocialMediaTitle>
      </SocialMediaTitleContainer>
      {isComingSoon ? (
        <ConnectionDetailsComingSoonContainer />
      ) : (
        <ConnectionDetailsContainer>
          <Statistic
            title={
              <span>
                <UserOutlined /> {t('compSocialMediaConnectionContent:admin-user-label')}
              </span>
            }
            value={hasConnected ? `${storeSocialMedia.name} (${storeSocialMedia.userId})` : t('compSocialMediaConnectionContent:not-connected-label')}
            valueStyle={constructCustomValueStyle(hasConnected)}
          />
          <br />
          <Statistic
            title={
              <span>
                {SOCIAL_MEDIAS[type].icon} {t('compSocialMediaConnectionContent:business-page-label')}
              </span>
            }
            value={
              hasConnected ? `${storeSocialMedia.page.name} (${storeSocialMedia.page.id})` : t('compSocialMediaConnectionContent:not-connected-label')
            }
            valueStyle={constructCustomValueStyle(hasConnected)}
          />
        </ConnectionDetailsContainer>
      )}
    </div>
  );
};

export default SocialMediaConnectionContent;
