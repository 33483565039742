import { Col, Popover, Row, List, Button, Tooltip } from 'antd';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';

import { constructDisplayPrice } from 'utils/general';

import {
  AOICAddBtn,
  AOICCard,
  AOICHeader,
  AOICItemDesc,
  AOICItemDivider,
  AOICItemPrice,
  AOICItemTitle,
  CurrencySymbolText,
  PlanAddOnsTitle,
  PlanAddOnsDesc,
  PlanAddOnsContainer,
  PlanCard,
  PlanCardBadgeRibbon,
  PlanCardBody,
  PlanCardContainer,
  PlanCardContentPaymentCol,
  PlanCardContentRow,
  PlanCardFooterPriceDisplayContainer,
  PlanCardFooterSubsPeriodText,
  PlanCardHeader,
  PlanDetailsPopoverList,
  PlanDetailsList,
  PlanPriceAmountText,
  PlanPriceDisplayContainer,
  PlanSelectAndPayButtonContainer,
  PlanSubsPeriodText,
  PlanSubsPriceText,
  PlanTitle,
  PopoverTitleText,
  PopoverContentText,
  StyledCheckOutlined,
  StyledInfoCircleOutlined
} from './Plan.styles';

const { Item: ListItem } = List;
const { Meta: ListItemMeta } = ListItem;

const SUBS_PERIOD_YEARLY_CODE = 'yearly';

const constructPlanRibbonText = (t, { isCurrentPlan, isBestValuePlan, isSelectedYearly }) => {
  switch (true) {
    case isCurrentPlan:
      return t('pagePlan:user-current-plan');
    case isSelectedYearly:
      return `❤️‍🔥 ${t('pagePlan:most-popular-plan')}`;
    case isBestValuePlan:
      return `⭐️ ${t('pagePlan:best-value-plan')}`;
    default:
      return '';
  }
};

const AddOnsItemCard = ({ t, isSelected, label, price, desc, onClick }) => {
  return (
    <AOICCard isSelected={isSelected} hoverable bodyStyle={{ padding: '16px 24px' }} onClick={onClick}>
      <AOICHeader>
        <div>
          <AOICItemTitle>{label}</AOICItemTitle>
          <AOICItemPrice>
            <PriceDisplay amount={price} />
          </AOICItemPrice>
        </div>
        <AOICAddBtn selected={isSelected} onClick={onClick}>
          {isSelected ? t('pagePlan:subscription-plan-add-ons-added-button-text') : t('pagePlan:subscription-plan-add-ons-add-button-text')}
        </AOICAddBtn>
      </AOICHeader>
      <AOICItemDivider />
      <AOICItemDesc>{desc}</AOICItemDesc>
    </AOICCard>
  );
};

const Plan = ({
  t,
  isBestValuePlan,
  isCurrentPlan,
  isShowLessDetails = true,
  allowPurchase = true,
  hasAddOns = true,
  storeCurrency,
  selectedSubscriptionPeriod,
  title,
  planPrice,
  planSubscriptionPeriod = '',
  planDesc,
  planDescItems,
  planAddOns = [],
  newTotalPrice,
  newNextExpiryDate,
  newSelectedPlanPrice,
  newSelectedPlanAddOnsDetails,
  loadingPayment,
  disablePayment,
  disablePaymentMessage,
  handleOnClickButtonPay
}) => {
  const { iso: currencyISO, symbol: currencySymbol } = storeCurrency;
  const isSelectedYearly = selectedSubscriptionPeriod === SUBS_PERIOD_YEARLY_CODE;
  const shouldShowRibbon = isCurrentPlan || isBestValuePlan || isSelectedYearly;
  const ribbonText = constructPlanRibbonText(t, {
    isCurrentPlan,
    isBestValuePlan,
    isSelectedYearly
  });

  return (
    <PlanCardContainer>
      <PlanCardBadgeRibbon shouldDisplay={shouldShowRibbon} text={ribbonText} color={isCurrentPlan ? '#FF7212' : '#2C278A'}>
        <PlanCard isCurrentPlan={isCurrentPlan} bodyStyle={{ height: '100%' }}>
          <PlanCardContentRow gutter={16}>
            <Col span={24}>
              <PlanCardHeader>
                <PlanTitle> {title}</PlanTitle>
                <PlanSubsPriceText>
                  <PriceDisplay amount={planPrice} />
                  {planSubscriptionPeriod && <PlanSubsPeriodText> {planSubscriptionPeriod}</PlanSubsPeriodText>}
                </PlanSubsPriceText>
              </PlanCardHeader>
              <PlanCardBody>
                {isShowLessDetails ? (
                  <div>
                    <PlanDetailsList
                      header={
                        <div>
                          {planDesc}{' '}
                          <Popover
                            trigger="click"
                            content={
                              <PlanDetailsPopoverList
                                size="small"
                                dataSource={planDescItems}
                                renderItem={item => (
                                  <ListItem>
                                    <ListItemMeta avatar={<StyledCheckOutlined />} title={item} />
                                  </ListItem>
                                )}
                              />
                            }
                          >
                            <StyledInfoCircleOutlined />
                          </Popover>
                        </div>
                      }
                      size="small"
                      dataSource={planDescItems}
                    />
                  </div>
                ) : (
                  <div>
                    <PlanDetailsList
                      header={planDesc}
                      size="small"
                      dataSource={planDescItems}
                      renderItem={item => (
                        <ListItem>
                          <ListItemMeta avatar={<StyledCheckOutlined />} title={item} />
                        </ListItem>
                      )}
                    />
                  </div>
                )}
                {hasAddOns && (
                  <PlanAddOnsContainer>
                    <PlanAddOnsTitle>
                      {t('pagePlan:subscription-plan-add-ons-title')}
                      <PlanAddOnsDesc>{t('pagePlan:subscription-plan-add-ons-title-desc')}</PlanAddOnsDesc>
                    </PlanAddOnsTitle>
                    {planAddOns.map(({ code, label, desc, price, isSelected, onClick }) => (
                      <AddOnsItemCard t={t} key={code} isSelected={isSelected} label={label} price={price} desc={desc} onClick={onClick} />
                    ))}
                  </PlanAddOnsContainer>
                )}
              </PlanCardBody>
            </Col>
            <PlanCardContentPaymentCol span={24} shouldDisplay={allowPurchase}>
              <PlanCardFooterPriceDisplayContainer>
                <PlanPriceDisplayContainer>
                  <CurrencySymbolText>{currencySymbol}</CurrencySymbolText>
                  <PlanPriceAmountText>{constructDisplayPrice(newTotalPrice, currencyISO)}</PlanPriceAmountText>{' '}
                  {planSubscriptionPeriod && <PlanCardFooterSubsPeriodText>{planSubscriptionPeriod}</PlanCardFooterSubsPeriodText>}
                </PlanPriceDisplayContainer>
                {newSelectedPlanAddOnsDetails && (
                  <Popover
                    trigger="click"
                    content={
                      <>
                        <Row gutter={8} justify="space-between">
                          <Col span={16}>
                            <PopoverTitleText>{title}</PopoverTitleText>
                            <PopoverContentText>
                              {t('pagePlan:business-plan-total-price-popover-plan-expiry-date', { expiryDate: newNextExpiryDate })}
                            </PopoverContentText>
                          </Col>
                          <Col span={8}>
                            <PopoverTitleText>
                              <PriceDisplay amount={newSelectedPlanPrice} />
                            </PopoverTitleText>
                          </Col>
                        </Row>
                        {newSelectedPlanAddOnsDetails.map(
                          newSelectedPlanAddOnsDetail =>
                            newSelectedPlanAddOnsDetail.isSelected && (
                              <Row gutter={8} justify="space-between" key={newSelectedPlanAddOnsDetail.code}>
                                <Col span={16}>
                                  <PopoverTitleText>{t('pagePlan:business-plan-total-price-popover-add-ons-microsite-title')}</PopoverTitleText>
                                  <PopoverContentText>
                                    {t('pagePlan:business-plan-total-price-popover-plan-expiry-date', { expiryDate: newNextExpiryDate })}
                                  </PopoverContentText>
                                </Col>
                                <Col span={8}>
                                  <PopoverTitleText>{<PriceDisplay amount={newSelectedPlanAddOnsDetail.priceLeft} />}</PopoverTitleText>
                                </Col>
                              </Row>
                            )
                        )}
                      </>
                    }
                  >
                    <StyledInfoCircleOutlined />
                  </Popover>
                )}
              </PlanCardFooterPriceDisplayContainer>
              {allowPurchase && (
                <>
                  <br />
                  <PlanSelectAndPayButtonContainer>
                    <Tooltip title={disablePayment && disablePaymentMessage} placement="bottom">
                      <Button type="primary" block loading={loadingPayment} onClick={handleOnClickButtonPay} disabled={disablePayment}>
                        {isCurrentPlan ? t('pagePlan:subscription-plan-update-plan-text') : t('pagePlan:subscription-plan-select-plan-text')}
                      </Button>
                    </Tooltip>
                  </PlanSelectAndPayButtonContainer>
                </>
              )}
            </PlanCardContentPaymentCol>
          </PlanCardContentRow>
        </PlanCard>
      </PlanCardBadgeRibbon>
    </PlanCardContainer>
  );
};

export default Plan;
