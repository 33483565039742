import { useCustomQuery, useCustomPaginatedQuery, useRefetchQuery } from 'hooks/reactQuery';
import api from './apiHelper';

export const useGetPaginatedStoreAccountsForAdmin = (query, onError) => {
  const getStoreAccounts = async (key, query) => {
    return api.get(`/admin/external/accounts/paginated`, {
      params: { ...query }
    });
  };

  return useCustomPaginatedQuery('storeAccounts', getStoreAccounts, query, {
    onError
  });
};

export const useGetAccountDetails = (storeId, token) => {
  const getAccountDetails = async () => {
    return api.get(`/admin/external/accounts/${storeId}`, { params: { token } });
  };

  return useCustomQuery('adminAccountDetails', [storeId], getAccountDetails, {
    enabled: !!storeId
  });
};

export const useFetchStoreStarterOrderLimitDetailsForAdmin = (storeId, token, shouldStartFetch = true) => {
  const getStoreStarterOrderLimitDetails = async () => {
    return api.get(`/admin/external/accounts/${storeId}/order-limit`, { params: { token } });
  };
  return useCustomQuery('storeOrderLimit', [storeId], getStoreStarterOrderLimitDetails, {
    enabled: shouldStartFetch && !!storeId
  });
};

export const patchStoreAccountsFromAdmin = (
  storeId,
  { status, subscriptionConfig, pgConfig, broadcastConfig, notifConfig, micrositeSettings },
  token
) => {
  return api.patch(
    `/admin/external/accounts/${storeId}`,
    { status, subscriptionConfig, pgConfig, broadcastConfig, notifConfig, micrositeSettings },
    { params: { token } }
  );
};

export const patchResetPasswordFromAdmin = (userId, token) => {
  return api.patch(`/admin/external/accounts/${userId}/reset-password`, null, { params: { token } });
};

export const patchUliveAdminStore = (storeId, token) => {
  return api.patch(`/admin/external/accounts/${storeId}/ulive-admin`, null, { params: { token } });
};

export const useRefetchStoreAccounts = () => {
  const { refetch } = useRefetchQuery(['storeAccounts']);

  return refetch;
};
