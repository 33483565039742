import styled from '@emotion/styled';
import Img2 from './images/2.png';

export const SecondContentTitle = styled.p`
  font-size: 1.2em;
  line-height: 1.5em;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 0.5em;
  margin-top: 32vh;
  text-align: left;
  padding: 0 2px;

  @media (min-width: 390px) {
    font-size: 1.4em;
    line-height: 1.5em;
    margin-top: 38vh;
  }

  @media (min-width: 414px) {
    font-size: 1.3em;
    margin-top: 32vh;
  }

  @media (min-width: 768px) {
    font-size: 2.5em;
    margin-bottom: 0.5em;
    margin-left: 4vw;
    margin-top: 44vh;
  }

  @media (min-width: 820px) {
    font-size: 2.5em;
    margin-bottom: 0.5em;
    margin-left: 0;
    margin-top: 44vh;
  }

  @media (min-width: 1024px) {
    font-size: 2.5em;
    margin-bottom: 0.5em;
    margin-left: 36vw;
    margin-top: 8vh;
  }

  @media (min-width: 1200px) {
    margin-left: 40vw;
    margin-right: 4vw;
  }
`;

export const SecondImg = styled.div`
  display: block;
  position: absolute;
  z-index: -1; // To prevent btn event being covered

  background-image: url(${Img2});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  height: 32vh;
  width: 64vw;
  top: 0vh;

  @media (min-width: 768px) {
    height: 48vh;
    width: 52vw;
    top: 2vh;
  }

  @media (min-width: 820px) {
    height: 48vh;
    width: 56vw;
    top: 2vh;
  }

  @media (min-width: 1024px) {
    height: 52vh;
    width: 32vw;
    top: 16px;
  }

  @media (min-width: 1200px) {
    margin-left: -20vw;
    height: 80vh;
    width: 80vw;
    top: 0vh;
  }
`;
