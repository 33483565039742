import { useState, useMemo } from 'react';

import { Button, Modal, message, Tooltip, Skeleton, Popover, List, Col, Space } from 'antd';
import { DownloadOutlined, SendOutlined, TagOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { PiSticker } from 'react-icons/pi';

import { postPublishSoldProductsComment } from 'apis/post';

import { PRODUCT_SOLD_SORT_TYPE } from 'utils/constants';
import { logError } from 'utils/logging';

import ModalDownloadCSV from './ModalDownloadCSV/ModalDownloadCSV';
import PrintProductStickerModal, { usePrintProductStickerModal } from './PrintProductStickerModal/PrintProductStickerModal';

import {
  DownloadCSVButton,
  SortingSelection,
  HorizontalScrollableContainer,
  ProductSoldHeaderRow,
  LoadingContainer,
  CardContainer,
  CardHeaderSection,
  HeaderContentContainer,
  QtyLeftContainer,
  CardContentSection,
  NameText,
  KeywordText,
  CardFooterSection,
  QtySoldText
} from './ProductScrollSection.styles';

/* ================================================== Local Components ================================================== */
const ProductCard = ({
  t,
  name,
  qtyLeft,
  qtySold,
  keyword,
  isAllowBumpProductSold,
  isPostingComment,
  variances,
  onBumpProductSold,
  onOpenProductSold,
  handleOnStickerBtnClick
}) => {
  return (
    <CardContainer>
      <CardHeaderSection>
        <HeaderContentContainer>
          {!variances ? (
            <Tooltip title={t('pageOverview:product-sold-section-qty-left-tooltip-message')}>
              <QtyLeftContainer qtyLeft={qtyLeft}>{Number(qtyLeft) > 999 ? '>999' : qtyLeft}</QtyLeftContainer>
            </Tooltip>
          ) : (
            <Popover
              title={t('pageOverview:product-sold-section-qty-left-popover-title')}
              trigger="hover"
              content={variances.map(variance => (
                <p key={variance.keyword}>
                  <TagOutlined /> [{keyword}
                  {variance.keyword}] {variance.label} x {variance.quantityLeft}
                </p>
              ))}
            >
              <QtyLeftContainer qtyLeft={qtyLeft}>
                <TagOutlined />
                {Number(qtyLeft) > 999 ? ' >999' : ` ${qtyLeft}`}
              </QtyLeftContainer>
            </Popover>
          )}

          <KeywordText>{keyword}</KeywordText>
        </HeaderContentContainer>
      </CardHeaderSection>
      <CardContentSection>
        <NameText>{name}</NameText>
      </CardContentSection>
      <CardFooterSection>
        <QtySoldText>{`${t('pageOverview:product-sold-section-sold-text')}: ${qtySold}`}</QtySoldText>
        <Space>
          <Button
            type="primary"
            ghost
            shape="circle"
            icon={<PiSticker style={{ fontSize: '20px', marginTop: '2px' }} />}
            loading={isPostingComment}
            onClick={handleOnStickerBtnClick}
          />
          {isAllowBumpProductSold && (
            <Button
              type="primary"
              ghost
              shape="circle"
              icon={<SendOutlined />}
              loading={isPostingComment}
              onClick={!variances ? onBumpProductSold : onOpenProductSold}
            />
          )}
        </Space>
      </CardFooterSection>
    </CardContainer>
  );
};

/* ================================================== Main Component ================================================== */
const ProductScrollSection = ({
  isLoading,
  postData,
  soldProducts = [],
  storeShortName,
  storeSecurityConfig,
  storeCurrency,
  isAllowDownloadCSV,
  isSupportPostComment
}) => {
  const { t } = useTranslation(['common', 'commonConstants', 'pageOverview']);
  const [isPostingComment, setIsPostingComment] = useState(false);
  const [shouldShowModalDownloadCSV, setShouldShowModalDownloadCSV] = useState(false);
  const [isProductSoldModalVisible, setIsProductSoldModalVisible] = useState(false);
  const [productSoldVariances, setProductSoldVariances] = useState([]);
  const [productSoldSortType, setProductSoldSortType] = useState(PRODUCT_SOLD_SORT_TYPE.LATEST.value);

  const {
    isPrintProductStickerModalVisible,
    setIsPrintProductStickerModalVisible,
    selectedPostId,
    selectedProductId,
    handleOnStickerBtnClick
  } = usePrintProductStickerModal();

  const productSoldSortTypeOptions = useMemo(
    () =>
      Object.values(PRODUCT_SOLD_SORT_TYPE).map(productSoldSortType => ({
        ...productSoldSortType,
        label: t(`commonConstants:${productSoldSortType.transKey}`)
      })),
    [t]
  );
  const sortedSoldProducts = useMemo(
    () =>
      productSoldSortType === PRODUCT_SOLD_SORT_TYPE.ALPHABET.value
        ? [...soldProducts].sort((soldProductA, soldProductB) =>
            soldProductA.keyword > soldProductB.keyword ? 1 : soldProductB.keyword > soldProductA.keyword ? -1 : 0
          )
        : soldProducts,
    [productSoldSortType, soldProducts]
  );

  const handleOnClickDownloadCSV = () => {
    setShouldShowModalDownloadCSV(true);
  };

  const handleOnCloseModalDownloadCSV = () => {
    setShouldShowModalDownloadCSV(false);
  };

  const handleOnBumpProductSold = productVarianceId => {
    setIsPostingComment(true);
    Modal.confirm({
      title: <span>{t('pageOverview:product-sold-section-confirm-message')}</span>,
      onOk: () =>
        postPublishSoldProductsComment(postData._id, productVarianceId)
          .then(() => {
            message.success(t('pageOverview:live-comment-post-success-message'));
            setIsPostingComment(false);
          })
          .catch(error => {
            logError(error);
            message.error(t('pageOverview:live-comment-post-fail-message'));
            setIsPostingComment(false);
          }),
      onCancel: () => setIsPostingComment(false),
      okText: t('pageOverview:product-sold-section-confirm-button'),
      cancelText: t('common:modal-cancel-text')
    });
  };

  const handleOnShowProductSoldModal = soldProductVariances => {
    setIsProductSoldModalVisible(true);

    setProductSoldVariances(soldProductVariances.filter(variance => variance.quantitySold > 0));
  };

  const handleOnProductSoldModalCancel = () => {
    setIsProductSoldModalVisible(false);
  };

  const handleOnChangeProductSoldSortType = value => {
    setProductSoldSortType(value);
  };

  return (
    <>
      <ProductSoldHeaderRow justify="space-between">
        <Col>
          {isAllowDownloadCSV && (
            <DownloadCSVButton type="secondary" icon={<DownloadOutlined />} onClick={handleOnClickDownloadCSV}>
              {t('pageOverview:product-sold-section-csv-download-button-text')}
            </DownloadCSVButton>
          )}
        </Col>
        <Col>
          <SortingSelection options={productSoldSortTypeOptions} value={productSoldSortType} onChange={handleOnChangeProductSoldSortType} />
        </Col>
      </ProductSoldHeaderRow>

      <HorizontalScrollableContainer>
        {isLoading && sortedSoldProducts.length === 0 ? (
          <LoadingContainer>
            <Skeleton active />
          </LoadingContainer>
        ) : (
          sortedSoldProducts.map(soldProduct => (
            <ProductCard
              key={soldProduct.productVarianceId}
              t={t}
              name={soldProduct.label}
              qtyLeft={soldProduct.quantityLeft}
              qtySold={soldProduct.quantitySold}
              keyword={soldProduct.keyword}
              isPostingComment={isPostingComment}
              isAllowBumpProductSold={isSupportPostComment}
              variances={soldProduct.variances}
              onBumpProductSold={() => handleOnBumpProductSold(soldProduct.productId)}
              onOpenProductSold={() => handleOnShowProductSoldModal(soldProduct.variances)}
              handleOnStickerBtnClick={() => handleOnStickerBtnClick(postData._id, soldProduct.productId)}
            />
          ))
        )}

        {isProductSoldModalVisible && (
          <Modal
            title={t('pageOverview:product-sold-list-title')}
            visible={isProductSoldModalVisible}
            onCancel={handleOnProductSoldModalCancel}
            footer={null}
          >
            <List
              itemLayout="horizontal"
              dataSource={productSoldVariances}
              renderItem={variance => (
                <List.Item
                  actions={[
                    <Button
                      type="link"
                      shape="round"
                      ghost
                      loading={isPostingComment}
                      onClick={() => handleOnBumpProductSold(variance.productVarianceId)}
                    >
                      {t('pageOverview:product-sold-list-publish-text')}
                      <SendOutlined />
                    </Button>
                  ]}
                >
                  <span style={{ fontSize: '16px' }}>{`[${variance.keyword}] ${variance.label} x ${variance.quantitySold}`}</span>
                </List.Item>
              )}
            />
          </Modal>
        )}
      </HorizontalScrollableContainer>
      {shouldShowModalDownloadCSV && (
        <ModalDownloadCSV
          postData={postData}
          storeShortName={storeShortName}
          storeSecurityConfig={storeSecurityConfig}
          onCancel={handleOnCloseModalDownloadCSV}
        />
      )}
      {isPrintProductStickerModalVisible && (
        <PrintProductStickerModal
          isPrintProductStickerModalVisible={isPrintProductStickerModalVisible}
          setIsPrintProductStickerModalVisible={setIsPrintProductStickerModalVisible}
          postId={selectedPostId}
          productId={selectedProductId}
          storeCurrency={storeCurrency}
        />
      )}
    </>
  );
};

export default ProductScrollSection;
