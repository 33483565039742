import styled from '@emotion/styled';
import { Modal } from 'antd';

export const RootModal = styled(Modal)`
  width: 100% !important;

  @media (min-width: 768px) {
    width: 60% !important;
  }
`;
