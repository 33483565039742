import { Tag } from 'antd';

const CustomerTier = ({ emoji, label, style }) => {
  return (
    <Tag color="#f1f5fc" style={{ ...style, color: 'black' }}>
      {emoji} {label}
    </Tag>
  );
};

export default CustomerTier;
