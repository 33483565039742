import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Card, Col, Checkbox, Form, message, Row, Skeleton, Tooltip, Empty, Alert } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useTranslation, Trans } from 'react-i18next';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { useGetMyProfileDetails, patchMyProfileDetails, patchMySecurityConfig } from 'apis/user';
import { patchStoreBegkuningSettings, useGetStoreBegkuningSettings } from 'apis/store';
import { resetPassword } from 'apis/auth';

import FBIGAccountConnection from 'components/FBIGAccountConnection/FBIGAccountConnection';
import TiktokAccountConnection from 'components/TiktokAccountConnection/TiktokAccountConnection';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormCheckboxToggle from 'components/Checkbox/FormCheckboxToggle/FormCheckboxToggle';
import FormGroupSelectionInput from 'components/FormGroupSelectionInput/FormGroupSelectionInput';
import FormPasswordInput from 'components/FormPasswordInput/FormPasswordInput';
import FormSelection from 'components/FormSelection/FormSelection';
import FormUpload from 'components/Upload/FormUpload/FormUpload';
import ProfileAvatar from 'components/ProfileAvatar/ProfileAvatar';
import PhotoManager from 'components/PhotoManager/PhotoManager';

import { useFetchConstant } from 'hooks/constants';
import { guard } from 'utils/general';
import { PASSCODE_REGEX } from 'utils/constants';

import {
  CardTitle,
  CursiveText,
  FullHeightRow,
  FullWidthCard,
  FullWidthForm,
  ProfileAvatarCol,
  ProfileNameCol,
  ProfileSubtext,
  ResetPasswordAlert,
  RootContainer,
  SMCContainer,
  SMCDesc,
  SMCTitleContainer,
  SMCTitleIcon,
  SMCTitle
} from './AccountProfile.styles';

const { useForm } = Form;

const SectionCard = ({ title, paddingBottom, bordered = false, children }) => (
  <Card title={<CardTitle>{title}</CardTitle>} bordered={bordered} bodyStyle={{ padding: 24, paddingBottom }}>
    {children}
  </Card>
);

const SocialMediaConnection = ({ t, children }) => {
  return (
    <SMCContainer bodyStyle={{ padding: '24px' }}>
      <SMCTitleContainer>
        <SMCTitleIcon />
        <SMCTitle>{t('pageAccountProfile:social-media-title')}</SMCTitle>
      </SMCTitleContainer>
      <SMCDesc>{t('pageAccountProfile:social-media-desc')}</SMCDesc>
      {children}
    </SMCContainer>
  );
};

const useSecuritySettings = ({ form, t, isMyProfileDetailsLoading, myProfileDetails, refetchMyProfileDetails, refetchStore }) => {
  const [isInit, setIsInit] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isRequireSecurityPasscode, setIsRequireSecurityPasscode] = useState(false);
  const hasExistingSecurityPasscode = guard(() => myProfileDetails.store.securityConfig.hasSecurityPasscode, false);

  useEffect(() => {
    if (!isInit && !isMyProfileDetailsLoading) {
      setIsRequireSecurityPasscode(!!hasExistingSecurityPasscode);
      setIsInit(true);
    }
  }, [isInit, isMyProfileDetailsLoading, hasExistingSecurityPasscode]);

  const handleOnUpdateSecuritySettings = useCallback(
    async e => {
      e.preventDefault();
      try {
        const SECURITY_CONFIG_FORM_FIELDS = [
          ['securityConfig', 'securityPasscode'],
          ['securityConfig', 'existingSecurityPasscode']
        ];
        const values = await form.validateFields(SECURITY_CONFIG_FORM_FIELDS);

        setIsUpdating(true);
        patchMySecurityConfig(values.securityConfig)
          .then(() => {
            message.success(t('pageAccountProfile:security-settings-update-success'));
            refetchMyProfileDetails();
            refetchStore();
            form.resetFields(SECURITY_CONFIG_FORM_FIELDS);
          })
          .catch(error => {
            message.error(t('pageAccountProfile:security-settings-update-fail', { message: error.message }));
          })
          .finally(() => {
            setIsUpdating(false);
          });
      } catch (ex) {
        message.error(t('pageAccountProfile:security-settings-form-update-fail'));
      }
    },
    [form, t, refetchMyProfileDetails, refetchStore]
  );

  return {
    isRequireSecurityPasscode,
    isUpdating,
    hasExistingSecurityPasscode,
    setIsRequireSecurityPasscode,
    handleOnUpdateSecuritySettings
  };
};

const useAutoCancellationSettings = ({ isMyProfileDetailsLoading, myProfileDetails }) => {
  const [isInit, setIsInit] = useState(false);
  const [isAllowAutoCancelUnpaidOrders, setIsAllowAutoCancelUnpaidOrders] = useState(false);

  const hasAutoCancelUnpaidOrders = guard(() => !!myProfileDetails.store.daysToCancelUnpaidOrders, false);

  useEffect(() => {
    if (!isInit && !isMyProfileDetailsLoading) {
      setIsAllowAutoCancelUnpaidOrders(!!hasAutoCancelUnpaidOrders);
      setIsInit(true);
    }
  }, [isInit, isMyProfileDetailsLoading, hasAutoCancelUnpaidOrders]);

  return { isAllowAutoCancelUnpaidOrders, setIsAllowAutoCancelUnpaidOrders };
};

const AccountProfile = ({ user, store, refetchStore, onLogout, isStoreConnectedToFb, checkCanUserAccess, storeOperatorRoles }) => {
  const [form] = useForm();
  const logoRef = useRef(null);
  const qrsRef = useRef(null);
  const { t } = useTranslation(['pageAccountProfile']);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [isLimitVouchersNumber, setIsLimitVouchersNumber] = useState(false);
  const [currentLogo, setCurrentLogo] = useState([]);
  const [currentQrs, setCurrentQrs] = useState([]);

  const isSubscriptionStarter = guard(() => store.subscriptionConfig.isSubscriptionStarter);

  const { isLoading: isMyProfileDetailsLoading, data: myProfileDetails, refetch: refetchMyProfileDetails } = useGetMyProfileDetails();
  const { isLoading: isBegkuningSettingsLoading, data: begkuningSettings, refetch: refetchBegkuningSettings } = useGetStoreBegkuningSettings({
    shouldFetch: store.subscriptionConfig.isBegkuningEnabled
  });
  const shouldUsePhotoManagerForLogo = !myProfileDetails.store?.logo;
  const shouldUsePhotoManagerForQrs = !myProfileDetails.store?.eWalletQrFiles?.length;

  useEffect(() => {
    if (!isMyProfileDetailsLoading) {
      setCurrentLogo(myProfileDetails?.store?.logoV2 ? [myProfileDetails.store.logoV2] : []);
      setCurrentQrs(myProfileDetails?.store?.eWalletQrs ? myProfileDetails.store.eWalletQrs : []);
    }
  }, [isMyProfileDetailsLoading, myProfileDetails]);

  const {
    isRequireSecurityPasscode,
    isUpdating: isSecurityConfigUpdating,
    hasExistingSecurityPasscode,

    setIsRequireSecurityPasscode,
    handleOnUpdateSecuritySettings
  } = useSecuritySettings({ form, t, isMyProfileDetailsLoading, myProfileDetails, refetchMyProfileDetails, refetchStore });
  const { isAllowAutoCancelUnpaidOrders, setIsAllowAutoCancelUnpaidOrders } = useAutoCancellationSettings({
    isMyProfileDetailsLoading,
    myProfileDetails
  });

  const { selection: phoneCodes } = useFetchConstant('countries', {
    valueKey: 'phoneCode',
    labelKey: 'phoneCode'
  });
  const { selection: currencies } = useFetchConstant('currencies', { valueKey: 'iso', labelKey: 'iso' });

  const { selection: countries } = useFetchConstant('countries', {
    labelKey: 'name',
    valueKey: 'iso2'
  });

  useEffect(() => {
    if (myProfileDetails && myProfileDetails.store && myProfileDetails.store.maxVouchersPerOrder) {
      setIsLimitVouchersNumber(true);
    } else {
      setIsLimitVouchersNumber(false);
    }
  }, [myProfileDetails]);

  const accountProfileFormInitialValue = useMemo(() => {
    const existingUserData = myProfileDetails && myProfileDetails.user;
    return {
      ...myProfileDetails,
      user: {
        ...existingUserData,
        contact:
          existingUserData && existingUserData.contact && existingUserData.contact.countryCode
            ? existingUserData.contact
            : {
                countryCode: phoneCodes && phoneCodes[0] && phoneCodes[0].value
              }
      }
    };
  }, [phoneCodes, myProfileDetails]);

  const isLoading = isMyProfileDetailsLoading || isBegkuningSettingsLoading;
  const isStoreLocked = store.isLockProfileAndRev;
  const isDisabledEdit = !checkCanUserAccess([storeOperatorRoles.ADMIN.code, storeOperatorRoles.OPERATOR.code]);
  const hasPgConfig = guard(() => myProfileDetails.store.hasPgConfig, false);

  const handleOnUpdateProfileDetails = async e => {
    e.preventDefault();
    try {
      setIsUpdatingProfile(true);
      const values = await form.validateFields([
        ['user', 'name'],
        ['user', 'email'],
        ['user', 'contact', 'countryCode'],
        ['user', 'contact', 'contactNumber'],
        ['store', 'name'],
        ['store', 'logo'],
        ['store', 'bankDetails', 'accountNumber'],
        ['store', 'bankDetails', 'bankName'],
        ['store', 'bankDetails', 'beneficiaryName'],
        ['store', 'contact', 'countryCode'],
        ['store', 'contact', 'contactNumber'],
        ['store', 'notifEmail'],
        ['store', 'customWAMessage'],
        ['store', 'customerNote'],
        ['store', 'customWAMessage'],
        ['store', 'customEndingMessageOrderSummary'],
        ['store', 'eWalletQrFiles'],
        ['store', 'isManualPaymentEnabled'],
        ['store', 'isAllowCommentReducePurchaseQuantity'],
        ['store', 'isAllowCustomerPreserveOrder'],
        ['store', 'isAutoPreserveOrder'],
        ['store', 'daysToCancelUnpaidOrders'],
        ['store', 'maxVouchersPerOrder'],
        ['store', 'isDefaultFilterProductPurchaseDate'],
        ['store', 'isRequirePaymentReceipt'],
        ['store', 'isRequireEmailAddress'],
        ['store', 'isDisableAutoReplySoldOut'],
        ['store', 'address', 'addressLine1'],
        ['store', 'address', 'addressLine2'],
        ['store', 'address', 'city'],
        ['store', 'address', 'zipcode'],
        ['store', 'address', 'state'],
        ['store', 'address', 'country']
      ]);

      if (!isLimitVouchersNumber) {
        delete values.store.maxVouchersPerOrder;
      }
      if (!isAllowAutoCancelUnpaidOrders) {
        delete values.store.daysToCancelUnpaidOrders;
      }

      const [logoV2] = (shouldUsePhotoManagerForLogo && (await logoRef.current.update())) || [null];
      const eWalletQrs = (shouldUsePhotoManagerForQrs && (await qrsRef.current.update())) || null;

      return patchMyProfileDetails({
        ...values,
        store: {
          ...values.store,
          contact: values.store.contact.countryCode && values.store.contact.contactNumber ? values.store.contact : null,
          logoV2: logoV2 ? logoV2 : null,
          eWalletQrs
        }
      })
        .then(() => {
          message.success(t('pageAccountProfile:profile-update-success-message'));
          refetchMyProfileDetails();
          refetchStore();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
          message.error(t('pageAccountProfile:profile-update-fail-message'));
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error(t('pageAccountProfile:profile-update-fail-message'));
    } finally {
      setIsUpdatingProfile(false);
    }
  };

  const handleOnResetPassword = async e => {
    e.preventDefault();
    try {
      const values = await form.validateFields(['oldPassword', 'password', 'confirmPassword']);
      return resetPassword(values).then(() => {
        message.success(t('pageAccountProfile:password-reset-success-message'));
        onLogout();
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      message.error(t('pageAccountProfile:password-reset-fail-message'));
    }
  };

  const handleOnAutoLinkPostChange = isAutoLinkPost => {
    setIsUpdatingProfile(true);
    patchStoreBegkuningSettings({ isAutoLinkPost })
      .then(() => {
        message.success(t('common:update-success-message'));
        refetchBegkuningSettings();
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        message.error(t('common:update-fail-message'));
      })
      .finally(() => {
        setIsUpdatingProfile(false);
      });
  };

  return (
    <RootContainer>
      <Helmet>
        <meta name="title" id="gtm-title" content="Account Profile" />
        <title>{t('pageAccountProfile:page-title')}</title>
      </Helmet>
      {!isStoreConnectedToFb && isSubscriptionStarter && (
        <Alert
          message={
            <Trans i18nKey="pageAccountProfile:profile-warning-banner-title">
              <b>Note</b>
            </Trans>
          }
          description={
            <>
              {t('pageAccountProfile:profile-warning-banner-desc')}{' '}
              <CursiveText>{t('pageAccountProfile:profile-warning-banner-desc-product-name')}</CursiveText>
            </>
          }
          type="warning"
          showIcon
          style={{ width: '100%', marginBottom: '12px' }}
        />
      )}
      <FullWidthCard>
        <FullHeightRow gutter={32}>
          <Col span={24} xl={10}>
            <Row gutter={8} align="middle">
              <ProfileAvatarCol>
                <ProfileAvatar size={48} src={store.logoV2?.thumbnail?.url || store.logo?.url} fallbackName={user.name} />
              </ProfileAvatarCol>
              <Col>
                <ProfileNameCol>
                  <span>{store.name}</span>
                </ProfileNameCol>
                <span>{user.name}</span>
                <br />
                <ProfileSubtext>
                  {user.email && user.email}
                  {user.username ? ` | ${user.username}` : ''}
                </ProfileSubtext>
              </Col>
            </Row>
            <SocialMediaConnection t={t}>
              <FBIGAccountConnection isDisabledEdit={isDisabledEdit} />
              {!!store.subscriptionConfig.isBegkuningEnabled && (
                <TiktokAccountConnection
                  // settings get from API so when update, here will reflect correctly
                  begkuningSettings={begkuningSettings}
                  isDisabledEdit={isDisabledEdit}
                  isUpdating={isUpdatingProfile}
                  onSetAutoLinkPost={handleOnAutoLinkPostChange}
                />
              )}
            </SocialMediaConnection>
          </Col>
          <Col span={24} xl={14}>
            {isLoading ? (
              <Skeleton active />
            ) : (
              <FullWidthForm form={form} initialValues={accountProfileFormInitialValue} scrollToFirstError={true}>
                <Row>
                  <Col span={24}>
                    <SectionCard title={t('pageAccountProfile:form-card-title-personal-info')} paddingBottom={0}>
                      <Row gutter={16}>
                        <Col span={24}>
                          <FormInput
                            name={['user', 'name']}
                            label={t('pageAccountProfile:form-field-label-full-name')}
                            requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-full-name')}
                            disabled={isStoreLocked || isDisabledEdit}
                          />
                        </Col>
                        <Col span={24} lg={12}>
                          <FormInput
                            type="email"
                            name={['user', 'email']}
                            label={t('pageAccountProfile:form-field-label-email-address')}
                            requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-email-address')}
                            disabled={isStoreLocked || isDisabledEdit}
                          />
                        </Col>
                        <Col span={24} lg={12}>
                          <FormGroupSelectionInput
                            label={t('pageAccountProfile:form-field-label-contact-number')}
                            inputName={['user', 'contact', 'contactNumber']}
                            selectionName={['user', 'contact', 'countryCode']}
                            inputPlaceholder="109876543"
                            inputType="contact"
                            selections={phoneCodes}
                            requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-contact-number')}
                            disabled={isStoreLocked || isDisabledEdit}
                          />
                        </Col>
                      </Row>
                    </SectionCard>
                  </Col>
                  <Col span={24}>
                    <SectionCard title={t('pageAccountProfile:form-card-title-store-info')} paddingBottom={8}>
                      <Row gutter={16}>
                        <Col span={24} lg={12}>
                          <FormInput
                            name={['store', 'name']}
                            label={t('pageAccountProfile:form-field-label-store-name')}
                            requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-store-name')}
                            disabled={isStoreLocked || isDisabledEdit}
                          />
                        </Col>
                        <Col span={24} lg={12}>
                          <FormInput
                            name={['store', 'shortName']}
                            label={t('pageAccountProfile:form-field-label-short-name')}
                            requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-short-name')}
                            disabled={true}
                          />
                        </Col>
                        <Col md={12} span={24}>
                          <FormSelection
                            name={['store', 'currency', 'iso']}
                            label={t('pageAccountProfile:form-field-label-store-currency')}
                            requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-short-name')}
                            selections={currencies}
                            disabled={true}
                          />
                        </Col>
                        <Col md={12} span={24}>
                          <FormGroupSelectionInput
                            label={t('pageAccountProfile:form-field-label-store-contact-number')}
                            inputName={['store', 'contact', 'contactNumber']}
                            selectionName={['store', 'contact', 'countryCode']}
                            inputPlaceholder="109876543"
                            inputType="contact"
                            selections={phoneCodes}
                            disabled={isStoreLocked || isDisabledEdit}
                          />
                        </Col>
                        <Col md={12} span={24}>
                          <FormInput
                            name={['store', 'notifEmail']}
                            type="email"
                            requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-email-address')}
                            label={
                              <>
                                {t('pageAccountProfile:form-field-label-store-notif-email')}{' '}
                                <Tooltip title={t('pageAccountProfile:form-field-tooltip-store-notif-email')}>
                                  <InfoCircleFilled />
                                </Tooltip>
                              </>
                            }
                            disabled={isStoreLocked || isDisabledEdit}
                          />
                        </Col>
                        <Col span={24}>
                          <FormInput
                            name={['store', 'address', 'addressLine1']}
                            label={t('pageAccountProfile:form-field-label-store-address-line-1')}
                            disabled={isStoreLocked || isDisabledEdit}
                          />
                        </Col>
                        <Col span={24}>
                          <FormInput
                            name={['store', 'address', 'addressLine2']}
                            label={t('pageAccountProfile:form-field-label-store-address-line-2')}
                            disabled={isStoreLocked || isDisabledEdit}
                          />
                        </Col>
                        <Col span={24} lg={12}>
                          <FormInput
                            name={['store', 'address', 'city']}
                            label={t('pageAccountProfile:form-field-label-store-city')}
                            disabled={isStoreLocked || isDisabledEdit}
                          />
                        </Col>
                        <Col span={24} lg={12}>
                          <FormInput
                            name={['store', 'address', 'zipcode']}
                            label={t('pageAccountProfile:form-field-label-store-zipcode')}
                            disabled={isStoreLocked || isDisabledEdit}
                          />
                        </Col>
                        <Col span={24} lg={12}>
                          <FormInput
                            name={['store', 'address', 'state']}
                            label={t('pageAccountProfile:form-field-label-store-state')}
                            disabled={isStoreLocked || isDisabledEdit}
                          />
                        </Col>
                        <Col span={24} lg={12}>
                          <FormSelection
                            name={['store', 'address', 'country']}
                            label={t('pageAccountProfile:form-field-label-store-country')}
                            disabled={isStoreLocked || isDisabledEdit}
                            selections={countries}
                          />
                        </Col>
                        <Col span={24}>
                          {shouldUsePhotoManagerForLogo ? (
                            <PhotoManager
                              value={currentLogo}
                              onChange={setCurrentLogo}
                              label={t('pageAccountProfile:form-field-label-store-logo')}
                              buttonLabel={t('pageAccountProfile:form-field-button-label-store-logo')}
                              fileSizeThresholdToCompressInKb={200}
                              cropAspect={1}
                              ref={logoRef}
                              disabled={isStoreLocked || isDisabledEdit}
                              storeId={store._id}
                              shouldRemoveDocsMarkedForDelete
                            />
                          ) : (
                            <FormUpload
                              name={['store', 'logo']}
                              label={t('pageAccountProfile:form-field-label-store-logo')}
                              buttonLabel={t('pageAccountProfile:form-field-button-label-store-logo')}
                              acceptTypes={['image/*']}
                              fileSizeLimitInMb={5}
                              cropAspect={1}
                              isDisplayCard
                              isDisabled={isDisabledEdit}
                            />
                          )}
                        </Col>
                      </Row>
                    </SectionCard>
                  </Col>
                  <Col span={24}>
                    <SectionCard title={t('pageAccountProfile:form-card-title-store-bank-info')} paddingBottom={8}>
                      <Row gutter={16}>
                        <Col span={24} lg={12}>
                          <FormInput
                            name={['store', 'bankDetails', 'accountNumber']}
                            label={t('pageAccountProfile:form-field-label-account-number')}
                            requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-account-number')}
                            disabled={isStoreLocked || isDisabledEdit}
                          />
                        </Col>
                        <Col span={24} lg={12}>
                          <FormInput
                            name={['store', 'bankDetails', 'bankName']}
                            label={t('pageAccountProfile:form-field-label-bank-name')}
                            requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-bank-name')}
                            disabled={isStoreLocked || isDisabledEdit}
                          />
                        </Col>
                        <Col span={24} lg={12}>
                          <FormInput
                            name={['store', 'bankDetails', 'beneficiaryName']}
                            label={t('pageAccountProfile:form-field-label-beneficiary-name')}
                            requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-beneficiary-name')}
                            disabled={isStoreLocked || isDisabledEdit}
                          />
                        </Col>
                      </Row>
                    </SectionCard>
                  </Col>
                  <Col span={24}>
                    <SectionCard title={t('pageAccountProfile:form-card-title-store-customer-note-info')} paddingBottom={8}>
                      <Row gutter={16}>
                        <Col span={24}>
                          <FormInput
                            label={t('pageAccountProfile:form-field-label-customer-note')}
                            name={['store', 'customerNote']}
                            placeholder={t('pageAccountProfile:form-field-placeholder-customer-note')}
                            type="textArea"
                            rows={8}
                            disabled={isDisabledEdit}
                          />
                        </Col>
                        <Col span={24}>
                          <FormInput
                            label={t('pageAccountProfile:form-field-label-custom-whatsapp-message')}
                            name={['store', 'customWAMessage']}
                            placeholder={t('pageAccountProfile:form-field-placeholder-whatsapp-message')}
                            type="textArea"
                            extraProps={{ showCount: true, maxLength: 200 }}
                            rows={6}
                            disabled={isDisabledEdit}
                          />
                        </Col>
                        <Col span={24}>
                          <FormInput
                            label={t('pageAccountProfile:form-field-label-custom-ending-message-order-summary')}
                            name={['store', 'customEndingMessageOrderSummary']}
                            placeholder={t('pageAccountProfile:form-field-placeholder-custom-ending-message-order-summary')}
                            type="textArea"
                            rows={6}
                            extraProps={{ showCount: true, maxLength: 1000 }}
                            disabled={isDisabledEdit}
                          />
                        </Col>
                      </Row>
                    </SectionCard>
                  </Col>
                  <Col span={24}>
                    <SectionCard title={t('pageAccountProfile:form-card-title-store-ewallet-info')} paddingBottom={8}>
                      {!isStoreLocked || (store.eWalletQrFiles && store.eWalletQrFiles.length > 0) ? (
                        <Row gutter={16}>
                          <Col span={24}>
                            {shouldUsePhotoManagerForQrs ? (
                              <PhotoManager
                                value={currentQrs}
                                onChange={setCurrentQrs}
                                buttonLabel={t('pageAccountProfile:form-submit-upload-button-text')}
                                fileSizeThresholdToCompressInKb={200}
                                ref={qrsRef}
                                disabled={isStoreLocked || isDisabledEdit}
                                multiple
                                storeId={store._id}
                                shouldRemoveDocsMarkedForDelete
                              />
                            ) : (
                              <FormUpload
                                name={['store', 'eWalletQrFiles']}
                                buttonLabel={t('pageAccountProfile:form-submit-upload-button-text')}
                                acceptTypes={['image/*']}
                                fileSizeLimitInMb={10}
                                isMultiple
                                isDisplayCard
                                isDisabled={isStoreLocked || isDisabledEdit}
                              />
                            )}
                          </Col>
                        </Row>
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </SectionCard>
                  </Col>
                  <Col span={24}>
                    <SectionCard title={t('pageAccountProfile:form-card-title-store-payment-config')} paddingBottom={8}>
                      {hasPgConfig && (
                        <FormCheckboxToggle
                          name={['store', 'isManualPaymentEnabled']}
                          label={t('pageAccountProfile:form-field-label-manual-payment')}
                          disabled={isDisabledEdit}
                        />
                      )}
                      <FormCheckboxToggle
                        name={['store', 'isAllowCommentReducePurchaseQuantity']}
                        label={t('pageAccountProfile:form-field-label-comment-reduce-purchase-quantity')}
                        disabled={isDisabledEdit}
                      />
                      <FormCheckboxToggle
                        name={['store', 'isAllowCustomerPreserveOrder']}
                        label={t('pageAccountProfile:form-field-label-preserve-order')}
                        disabled={isDisabledEdit}
                        onChange={() => {
                          form.setFieldsValue({
                            store: { isAutoPreserveOrder: false }
                          });
                        }}
                      />
                      <FormCheckboxToggle
                        name={['store', 'isAutoPreserveOrder']}
                        label={t('pageAccountProfile:form-field-label-auto-preserve-order')}
                        disabled={isDisabledEdit}
                        onChange={() => {
                          form.setFieldsValue({
                            store: { isAllowCustomerPreserveOrder: false }
                          });
                        }}
                      />
                      <FormCheckboxToggle
                        checked={isLimitVouchersNumber}
                        label={t('pageAccountProfile:form-field-label-is-limit-number-of-vouchers')}
                        onChange={e => setIsLimitVouchersNumber(e.target.checked)}
                        disabled={isDisabledEdit}
                      />
                      {isLimitVouchersNumber && (
                        <FormInputNumber
                          label={
                            <span>
                              {t('pageAccountProfile:form-field-label-number-of-vouchers-limit')}{' '}
                              <Tooltip title={t('pageAccountProfile:form-field-tooltip-number-of-vouchers-limit')}>
                                <InfoCircleFilled />
                              </Tooltip>
                            </span>
                          }
                          name={['store', 'maxVouchersPerOrder']}
                          requiredErrorMessage={t('pageAccountProfile:form-input-required-error-message-number-of-vouchers-limit')}
                          minValue={2}
                          disabled={isDisabledEdit}
                        />
                      )}
                      <FormCheckboxToggle
                        checked={isAllowAutoCancelUnpaidOrders}
                        label={t('pageAccountProfile:form-field-label-cancel-unpaid-order')}
                        onChange={e => {
                          setIsAllowAutoCancelUnpaidOrders(e.target.checked);
                        }}
                        disabled={isDisabledEdit}
                      />
                      {isAllowAutoCancelUnpaidOrders && (
                        <FormInputNumber
                          name={['store', 'daysToCancelUnpaidOrders']}
                          label={
                            <span>
                              {t('pageAccountProfile:form-field-label-days-to-cancel-unpaid-order')}
                              <Tooltip title={t('pageAccountProfile:form-field-label-days-to-cancel-unpaid-order-tips')}>
                                <InfoCircleFilled style={{ marginLeft: '4px' }} />
                              </Tooltip>
                            </span>
                          }
                          requiredErrorMessage={t('pageAccountProfile:form-input-required-error-message-cancel-unpaid-order')}
                          minValue={1}
                          disabled={isDisabledEdit}
                        />
                      )}
                      <FormCheckboxToggle
                        name={['store', 'isDefaultFilterProductPurchaseDate']}
                        label={t('pageAccountProfile:form-field-label-download-default-filter-checkbox-text')}
                        disabled={isDisabledEdit}
                      />
                      <FormCheckboxToggle
                        name={['store', 'isDisableAutoReplySoldOut']}
                        label={t('pageAccountProfile:form-field-label-disable-auto-reply-sold-out-checkbox-text')}
                        disabled={isDisabledEdit}
                      />
                      <FormCheckboxToggle
                        name={['store', 'isRequirePaymentReceipt']}
                        label={t('pageAccountProfile:form-field-label-require-payment-receipt-checkbox-text')}
                        disabled={isDisabledEdit}
                      />
                      <FormCheckboxToggle
                        name={['store', 'isRequireEmailAddress']}
                        label={t('pageAccountProfile:form-field-label-require-email-address-checkbox-text')}
                        disabled={isDisabledEdit}
                      />
                    </SectionCard>
                  </Col>
                  {!isStoreLocked && (
                    <Form.Item style={{ width: '100%' }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        disabled={isDisabledEdit}
                        onClick={handleOnUpdateProfileDetails}
                        loading={isUpdatingProfile}
                      >
                        {t('pageAccountProfile:form-button-update-profile')}
                      </Button>
                    </Form.Item>
                  )}
                  {!isStoreLocked && (
                    <Col span={24} style={{ marginTop: 24 }}>
                      <SectionCard title={t('pageAccountProfile:form-card-title-reset-password')} bordered>
                        <Row gutter={16} style={{ marginBottom: '24px' }}>
                          <Col span={24} lg={12}>
                            <FormPasswordInput
                              name="oldPassword"
                              label={t('pageAccountProfile:form-field-label-old-password')}
                              placeholder={t('pageAccountProfile:form-field-password-placeholder')}
                              requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-old-password')}
                              dependencyField="password"
                              extraProps={{
                                onPressEnter: handleOnResetPassword
                              }}
                            />
                            <FormPasswordInput
                              name="password"
                              label={
                                <span>
                                  {t('pageAccountProfile:form-field-label-new-password')}{' '}
                                  <Tooltip title={t('pageAccountProfile:form-field-password-tips')}>
                                    <InfoCircleFilled />
                                  </Tooltip>
                                </span>
                              }
                              placeholder={t('pageAccountProfile:form-field-password-placeholder')}
                              requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-new-password')}
                              checkPattern={true}
                              extraProps={{
                                onPressEnter: handleOnResetPassword
                              }}
                            />
                            <FormPasswordInput
                              type="confirm"
                              name="confirmPassword"
                              label={t('pageAccountProfile:form-field-label-confirm-password')}
                              placeholder={t('pageAccountProfile:form-field-password-placeholder')}
                              requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-confirm-password')}
                              compareField="password"
                              dependencyField="password"
                              extraProps={{
                                onPressEnter: handleOnResetPassword
                              }}
                            />
                          </Col>
                          <Col span={24} lg={12}>
                            <ResetPasswordAlert
                              type="warning"
                              showIcon
                              message={<b>{t('pageAccountProfile:reset-password-alert-title')}</b>}
                              description={<span>{t('pageAccountProfile:reset-password-alert-description')}</span>}
                            />
                          </Col>
                        </Row>
                        <Button type="primary" block onClick={handleOnResetPassword}>
                          {t('pageAccountProfile:form-button-reset-password')}
                        </Button>
                      </SectionCard>
                    </Col>
                  )}
                  {checkCanUserAccess([storeOperatorRoles.ADMIN.code]) && (
                    <Col span={24} style={{ marginTop: 24 }}>
                      <SectionCard title={t('pageAccountProfile:form-card-title-security-settings')} bordered>
                        <Row gutter={16} style={{ marginBottom: '24px' }}>
                          <Col span={24}>
                            <Checkbox
                              onChange={() => setIsRequireSecurityPasscode(prevIsRequireSecurityPasscode => !prevIsRequireSecurityPasscode)}
                              value={isRequireSecurityPasscode}
                              checked={isRequireSecurityPasscode}
                              style={{ marginBottom: '8px' }}
                            >
                              {t('pageAccountProfile:checkbox-download-csv-passcode')}
                            </Checkbox>
                            {hasExistingSecurityPasscode && (
                              <FormPasswordInput
                                label={t('pageAccountProfile:form-input-label-download-csv-existing-passcode')}
                                name={['securityConfig', 'existingSecurityPasscode']}
                                placeholder={t('pageAccountProfile:form-input-placeholder-download-csv-existing-passcode')}
                                requiredErrorMessage={t('pageAccountProfile:form-input-required-message-download-csv-existing-passcode')}
                                customPattern={PASSCODE_REGEX}
                                customPatternErrorMessage={t('pageAccountProfile:form-input-invalid-pattern-message-download-csv-passcode')}
                                extraProps={{
                                  onPressEnter: handleOnUpdateSecuritySettings
                                }}
                              />
                            )}
                            {isRequireSecurityPasscode && (
                              <FormPasswordInput
                                label={t('pageAccountProfile:form-input-label-download-csv-passcode')}
                                name={['securityConfig', 'securityPasscode']}
                                placeholder={t('pageAccountProfile:form-input-placeholder-download-csv-passcode')}
                                requiredErrorMessage={t('pageAccountProfile:form-input-required-message-download-csv-passcode')}
                                customPattern={PASSCODE_REGEX}
                                customPatternErrorMessage={t('pageAccountProfile:form-input-invalid-pattern-message-download-csv-passcode')}
                                extraProps={{
                                  onPressEnter: handleOnUpdateSecuritySettings
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Button type="primary" block onClick={handleOnUpdateSecuritySettings} loading={isSecurityConfigUpdating}>
                          {t('pageAccountProfile:form-button-update-security-settings')}
                        </Button>
                      </SectionCard>
                    </Col>
                  )}
                </Row>
              </FullWidthForm>
            )}
          </Col>
        </FullHeightRow>
      </FullWidthCard>
    </RootContainer>
  );
};

export default withAppContext(AccountProfile);
