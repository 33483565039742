import { useEffect, useState } from 'react';
import { Button, Col, Form, message, Modal, Row, Skeleton } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined, ShopOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { patchStoreBegkuningSettings, useGetStoreBegkuningSettings } from 'apis/store';

import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import FormInput from 'components/FormInput/FormInput';
import FormCheckboxToggle from 'components/Checkbox/FormCheckboxToggle/FormCheckboxToggle';

const { confirm } = Modal;
const { useForm } = Form;
const { error } = message;

const AUITO_BK_BASE_URL = '.begkuning.ai';

const generateBegkuningUrl = (subdomainName, isWithProtocol = false) => {
  return `${isWithProtocol ? 'https://' : ''}${subdomainName}${AUITO_BK_BASE_URL}`;
};

const BegkuningSettings = () => {
  const { t } = useTranslation(['common', 'pageBegkuningSettings']);
  const [form] = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isLoading: isBegkuningSettingsLoading, data: begkuningSettings, refetch: refetchBegkuningSettings } = useGetStoreBegkuningSettings();

  const isLoading = isBegkuningSettingsLoading;
  const isNew = !begkuningSettings || !begkuningSettings.subdomainName;

  useEffect(() => {
    if (!isLoading && begkuningSettings) {
      form.setFieldsValue(begkuningSettings);
    }
  }, [isLoading, form, begkuningSettings]);

  const handleOnSave = async e => {
    setIsSubmitting(true);
    e.preventDefault();

    try {
      const payload = await form.validateFields();

      if (isNew) {
        confirm({
          title: t('pageBegkuningSettings:confirm-modal-create-begkuning-title'),
          content: (
            <Row gutter={[0, 8]}>
              <Col span={24} style={{ fontWeight: 'bold' }}>
                {generateBegkuningUrl(payload.subdomainName)}
              </Col>
              <Col span={24}>{t('pageBegkuningSettings:confirm-modal-create-begkuning-content')}</Col>
            </Row>
          ),
          okText: t('common:modal-ok-text'),
          cancelText: t('common:modal-cancel-text'),
          icon: <ExclamationCircleOutlined />,
          onOk: async () => {
            patchStoreBegkuningSettings(payload)
              .then(() => {
                refetchBegkuningSettings();
                message.success(t('common:create-success-message'));
              })
              .catch(ex => {
                error(ex.message);
              })
              .finally(() => {
                setIsSubmitting(false);
              });
          },
          onCancel() {
            setIsSubmitting(false);
          }
        });
      } else {
        patchStoreBegkuningSettings(payload)
          .then(() => {
            refetchBegkuningSettings();
            message.success(t('common:update-success-message'));
          })
          .catch(ex => {
            error(ex.message);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    } catch (error) {
      error.errorFields.forEach(field => message.error(field.errors[0]));
      setIsSubmitting(false);
    }
  };

  return (
    <FullWidthContainer>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Form form={form} defaultValue={begkuningSettings} scrollToFirstError={true}>
          <Row>
            <Col span={24} md={14}>
              <FormInput
                name="subdomainName"
                label={
                  isNew ? (
                    t('pageBegkuningSettings:form-field-label-domain-name')
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>{t('pageBegkuningSettings:form-field-label-domain-name')}</span>
                      <a
                        style={{ fontSize: '14px' }}
                        href={generateBegkuningUrl(begkuningSettings.subdomainName, true)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          {t('pageBegkuningSettings:text-view-begkuning')} <ShopOutlined />
                        </span>
                      </a>
                    </div>
                  )
                }
                placeholder="domain-name"
                requiredErrorMessage={isNew ? t('pageBegkuningSettings:form-field-required-error-message-subdomain-name') : undefined}
                extraProps={{ addonAfter: AUITO_BK_BASE_URL }}
                type="domain"
                disabled={!isNew}
              />
            </Col>
          </Row>
          {!isNew && (
            <Row>
              <Col span={24} md={14}>
                <FormCheckboxToggle name="isAutoLinkPost" label={t('pageBegkuningSettings:form-field-label-auto-link-post')} />
              </Col>
            </Row>
          )}
          <Button type="primary" icon={<CheckOutlined />} onClick={handleOnSave} loading={isSubmitting}>
            {isNew ? t('pageBegkuningSettings:button-create-begkuning') : t('pageBegkuningSettings:button-update-settings')}
          </Button>
        </Form>
      )}
    </FullWidthContainer>
  );
};

export default BegkuningSettings;
