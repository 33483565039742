import PropTypes from 'prop-types';

import Icon from './Icon';

import shippingFeeIcon from './icons/shipping-fee.png';
import shippingFeeIconLinkType from './icons/shipping-fee-link-type.png';
import shippingFeeBtnIconType from './icons/shipping-fee-btn-icon-type.png';

const ICON_STYLE_DEFAULT = 'default';
const ICON_STYLE_LINK = 'link';
const ICON_STYLE_BTN = 'button';

const ICONS = {
  [ICON_STYLE_DEFAULT]: shippingFeeIcon,
  [ICON_STYLE_LINK]: shippingFeeIconLinkType,
  [ICON_STYLE_BTN]: shippingFeeBtnIconType
};

const ShippingFeeIcon = ({ type = ICON_STYLE_DEFAULT, width, height, style, onClick, className }) => {
  return <Icon src={ICONS[type]} name="shipping-fee" width={width} height={height} style={style} onClick={onClick} className={className} />;
};

ShippingFeeIcon.propTypes = {
  type: PropTypes.oneOf([ICON_STYLE_DEFAULT, ICON_STYLE_LINK, ICON_STYLE_BTN]),
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default ShippingFeeIcon;
