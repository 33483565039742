import styled from '@emotion/styled';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';

export const ProductLabelText = styled.p`
  margin-top: 8px;
  margin-bottom: 0;
  color: #000000;
  font-weight: bold;
`;

export const ProductDescText = styled.p`
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: normal;
`;

export const InfoText = styled.p`
  margin-bottom: 0;
  color: #797979;
  font-size: 12px;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FinalPrice = styled(PriceDisplay)`
  color: ${props => (props.hasOriPrice ? props.theme.color.secondary : props.theme.color.grey)};
  font-size: 12px;
`;

export const OriPrice = styled(PriceDisplay)`
  font-size: 12px;
  text-decoration: line-through;
`;
