import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { StyledButton } from './BackButton.styles';

const BackButton = ({ backLink, onClick }) => {
  const history = useHistory();

  return <StyledButton icon={<ArrowLeftOutlined />} onClick={backLink ? () => history.push(backLink) : onClick} />;
};

export default BackButton;
