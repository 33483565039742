import api from './apiHelper';
import { useCustomQuery, useCustomPaginatedQuery, useRefetchQuery } from 'hooks/reactQuery';

export const postCreatePromotion = payload => {
  return api.post('/promotion', payload);
};

export const postCreateNewCustomer = payload => {
  return api.post('/customer-promo/new', payload);
};

export const useGetPromotionDetails = (promotionId, query = {}, onError) => {
  const getPromotionDetails = async () => {
    return api.get(`/promotion/${promotionId}`, {
      params: { ...query }
    });
  };

  return useCustomQuery('promotionDetails', [promotionId, query], getPromotionDetails, {
    onError,
    enabled: !!promotionId
  });
};

export const useGetPromotions = (query, onError) => {
  const getPromotions = async () => {
    return api.get(`/promotion`, {
      params: { ...query }
    });
  };

  return useCustomQuery('promotions', [query], getPromotions, {
    onError,
    shouldDefaultEmptyObject: false
  });
};

export const useGetPaginatedPromotions = (query, onError) => {
  const getPromotions = async (key, query) => {
    return api.get(`/promotion/paginated`, {
      params: { ...query }
    });
  };

  return useCustomPaginatedQuery('promotions', getPromotions, query, {
    onError
  });
};

export const useGetPromotionSuggestions = (query, { shouldFetch }, { postProcessFunc, onError }) => {
  const getPromotions = async () => {
    return api.get(`/promotion/suggestions`, {
      params: { ...query }
    });
  };

  return useCustomQuery('promotions', [query], getPromotions, {
    onError,
    enabled: !!shouldFetch,
    shouldDefaultEmptyObject: false,
    postProcessFunc
  });
};

export const putPromotion = (promotionId, payload) => {
  return api.put(`/promotion/${promotionId}`, payload);
};

export const patchPromotionStatus = (promotionId, status) => {
  return api.patch(`/promotion/${promotionId}/status`, { status });
};

export const deletePromotion = (promotionId, orderAction) => {
  return api.delete(`/promotion/${promotionId}`, { params: { orderAction } });
};

export const useRefetchPromotions = () => {
  const { refetch } = useRefetchQuery(['promotions']);

  return refetch;
};

export const useGetPromotionCustomers = (promotionId, query = {}, { postProcessFunc, onError }) => {
  const getPromotionCustomers = async () => {
    return api.get(`/promotion/${promotionId}/customers`, {
      params: { ...query }
    });
  };

  return useCustomQuery('promotionCustomers', [promotionId, query], getPromotionCustomers, {
    onError,
    enabled: !!promotionId,
    postProcessFunc
  });
};

export const deletePromotionCustomer = (promotionId, customerPromoId) => {
  return api.delete(`/promotion/${promotionId}/customer/${customerPromoId}`);
};
