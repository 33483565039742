import { useState, useMemo } from 'react';
import { TeamOutlined } from '@ant-design/icons';
import { Col, Form, Modal, Row, Skeleton, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import FormInput from 'components/FormInput/FormInput';
import TextButton from 'components/TextButton/TextButton';

import { useGetLuckyDrawById } from 'apis/luckyDraw';

import { BREAK_LINE_REGEX } from 'utils/constants';

import { ScrollableContainer } from './LuckyDrawDetailsModal.styles';
import LuckyDrawPlayersModal from '../LuckyDrawPlayersModal/LuckyDrawPlayersModal';

const { useForm } = Form;

const convertPlayersStringToArray = (players = '') => {
  return players
    .replace(BREAK_LINE_REGEX, '\n')
    .split('\n')
    .filter(player => !!player);
};

const PlayersDisplayContainer = ({ players }) => {
  return (
    <ScrollableContainer>
      {players.map(player => (
        <Tag>{player}</Tag>
      ))}
    </ScrollableContainer>
  );
};

const LuckyDrawDetailsModal = ({ isLoading, visible, luckyDrawId, onClose, onOk }) => {
  const { t } = useTranslation(['common', 'modalLuckyDrawDetails']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isViewParticipantsClick, setIsViewParticipantsClick] = useState(false);
  const { data: luckyDrawDetails, isLoading: isLuckyDrawDetailsLoading, refetch: refetchLuckyDraw } = useGetLuckyDrawById(luckyDrawId);

  const [form] = useForm();
  const isNew = useMemo(() => !luckyDrawId, [luckyDrawId]);
  const isModalContentLoading = isLoading || isLuckyDrawDetailsLoading;

  const handleOnOkClick = e => {
    e.preventDefault();
    setIsSubmitting(true);
    //IMPORTANT: For modal, form validation cannot use async await, must use .then and .catch
    form
      .validateFields()
      .then(values => {
        const extraPlayers = convertPlayersStringToArray(values.participants);
        if (isNew) {
          onOk({
            isNew,
            payload: { ...values, players: extraPlayers },
            onSuccess: () => {
              setIsSubmitting(false);
              form.resetFields();
            },
            onFail: () => {
              setIsSubmitting(false);
            }
          });
        } else {
          Modal.confirm({
            title: <span>{t('modalLuckyDrawDetails:add-player-confirm-message')}</span>,
            content: <PlayersDisplayContainer players={extraPlayers} />,
            onOk: () =>
              onOk({
                isNew,
                payload: extraPlayers,
                onSuccess: () => {
                  refetchLuckyDraw();
                  setIsSubmitting(false);
                  form.resetFields();
                },
                onFail: () => {
                  setIsSubmitting(false);
                }
              }),
            okText: t('modalLuckyDrawDetails:add-player-ok-button'),
            cancelText: t('common:modal-cancel-text')
          });
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal
        width="70%"
        visible={visible}
        title={isNew ? t('modalLuckyDrawDetails:title-create') : t('modalLuckyDrawDetails:title-update')}
        okText={isNew ? t('modalLuckyDrawDetails:modal-create-ok-button') : t('modalLuckyDrawDetails:modal-update-ok-button')}
        cancelText={t('common:modal-cancel-text')}
        onCancel={onClose}
        confirmLoading={isLoading || isSubmitting}
        onOk={handleOnOkClick}
        maskClosable={false}
        destroyOnClose={true}
      >
        {isModalContentLoading ? (
          <Skeleton active />
        ) : (
          <Form form={form} initialValues={luckyDrawDetails} scrollToFirstError={true} style={{ width: '100%' }}>
            <Row gutter={16}>
              <Col span={24} lg={12}>
                <FormInput
                  label={t('modalLuckyDrawDetails:form-label-name')}
                  name="title"
                  placeholder="e.g.: First 5K Subscribers!"
                  requiredErrorMessage={t('modalLuckyDrawDetails:form-input-required-message-name-label')}
                  disabled={!isNew}
                />
              </Col>
              <Col span={24} lg={12}>
                <FormInput
                  label={t('modalLuckyDrawDetails:form-label-keyword')}
                  name="keyword"
                  type="luckyDrawKeyword"
                  placeholder={`e.g.: LUCKY${new Date().getFullYear()}`}
                  requiredErrorMessage={t('modalLuckyDrawDetails:form-input-required-message-keyword-label')}
                  disabled={!isNew}
                />
              </Col>
              <Col span={24}>
                <FormInput
                  label={t('modalLuckyDrawDetails:form-label-participants')}
                  name="participants"
                  placeholder={`John<ENTER>Lucas<ENTER>Dallas<ENTER>Sandra`}
                  type="textArea"
                  rows={8}
                />
              </Col>
              {!isNew && (
                <Col span={24}>
                  <TextButton
                    icon={<TeamOutlined />}
                    onClick={() => setIsViewParticipantsClick(true)}
                    text={t('modalLuckyDrawDetails:view-participants-button')}
                    tooltipMessage={t('common:text-button-tooltip-message', {
                      action: t('modalLuckyDrawDetails:view-participants-tooltip')
                    })}
                  />
                </Col>
              )}
            </Row>
          </Form>
        )}
      </Modal>
      {isViewParticipantsClick && (
        <LuckyDrawPlayersModal luckyDrawId={luckyDrawId} visible={isViewParticipantsClick} onClose={() => setIsViewParticipantsClick(false)} />
      )}
    </>
  );
};

export default LuckyDrawDetailsModal;
