import styled from '@emotion/styled';
import { Row, Col } from 'antd';

export const MainContainer = styled.div`
  background-color: white !important;
  height: 100vh !important;
  width: 100% !important;
  background-image: none;
`;

export const StyledRow = styled(Row)`
  margin: auto;
  padding: 40px;
  width: 100%;
  height: 100vh !important;
`;

export const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
`;

export const Image = styled.img`
  width: 100%;
  height: 200px;

  @media (min-width: 768px) {
    height: 400px;
  }
`;

export const ErrorContainer = styled.div`
  margin: auto;
  height: 300px;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    height: 400px;
  }
`;

export const ErrorTitle = styled.span`
  color: #434e59;
  font-size: 56px;
  font-weight: 600;
  line-height: 56px;
  margin-bottom: 24px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 72px;
    line-height: 72px;
  }
`;

export const ErrorSubtitle = styled.span`
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 28px;
  }
`;
