import { Col, Row } from 'antd';
import { Pie } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';

import { constructDisplayPrice } from 'utils/general';

import { PieChartTitleText } from './RevenuePieCharts.styles';

const statusToColorMapping = {
  Pending: '#ffc107',
  'Pre-Checkout': '#ff9800',
  Checkout: '#2196f3',
  Paid: '#4caf50',
  Completed: '#009688',
  Cancelled: '#607d8b'
};

const getPieChartData = data => {
  let formattedData = [];
  for (const type in data) {
    formattedData.push({
      type: type === '-' ? 'N/A' : type,
      value: data[type]
    });
  }
  return formattedData;
};

const pieChartConfig = {
  appendPadding: 10,
  angleField: 'value',
  colorField: 'type',
  radius: 0.6,
  legend: {
    layout: 'vertical',
    position: 'right'
  },
  label: {
    type: 'outer',
    content: '{percentage}',
    style: { textAlign: 'center' }
  },
  interactions: [
    {
      type: 'element-selected'
    },
    {
      type: 'element-active'
    }
  ]
};

const RevenuePieCharts = ({ reportingData, storeCurrency }) => {
  const { t } = useTranslation(['pageReporting']);

  return (
    <div>
      <Row gutter={24} style={{ margin: '40px 0' }}>
        <Col xs={24} md={12} xl={8} style={{ height: '300px' }}>
          <Pie {...pieChartConfig} data={getPieChartData(reportingData.shipments)} />
          <PieChartTitleText>{t('pageReporting:pie-number-of-orders-by-shipment')}</PieChartTitleText>
        </Col>
        <Col xs={24} md={12} xl={8} style={{ height: '300px' }}>
          <Pie
            {...pieChartConfig}
            tooltip={{
              formatter: datum => {
                return {
                  name: datum.type,
                  value: `${datum.value}, ${storeCurrency.symbol} ${constructDisplayPrice(
                    reportingData.statusesRevenue[datum.type],
                    storeCurrency.iso
                  )}`
                };
              }
            }}
            color={({ type }) => statusToColorMapping[type]}
            data={getPieChartData(reportingData.statuses)}
          />
          <PieChartTitleText>{t('pageReporting:pie-number-of-orders-by-status')}</PieChartTitleText>
        </Col>
        <Col xs={24} md={12} xl={8} style={{ height: '300px' }}>
          <Pie {...pieChartConfig} data={getPieChartData(reportingData.countries)} />
          <PieChartTitleText>{t('pageReporting:pie-number-of-orders-by-country')}</PieChartTitleText>
        </Col>
      </Row>
    </div>
  );
};

export default RevenuePieCharts;
