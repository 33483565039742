import styled from '@emotion/styled';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';

export const ContentText = styled.div`
  font-size: 14px;
  margin: 8px 0 0 38px;
`;

export const TitleIcon = styled(ExclamationCircleOutlined)`
  color: #faad14;
  margin-right: 16px;
  font-size: 22px;
`;

export const TitleText = styled.span`
  font-size: 16px;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin: 16px 0 0 8px;
`;

export const VarianceContainer = styled.div`
  margin-top: 32px;
  width: 100%;

  @media (min-width: 1200px) {
    margin-top: 0;
  }
`;

export const OverrideOrderCheckboxLabel = styled.span`
  font-size: 12px;
`;
