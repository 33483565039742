import styled from '@emotion/styled';

const MODE_DARK = 'dark';

const getTextColor = (props, mode, isSelected) => {
  switch (mode) {
    case MODE_DARK:
      return isSelected ? props.theme.color.tertiary : props.theme.color.white;
    default:
      return isSelected ? props.theme.color.secondary : props.theme.color.black;
  }
};

export const LanguageSwitcherOption = styled.span`
  cursor: pointer;
  font-weight: ${props => (props.selected ? props.theme.fontWeight.bold : props.theme.fontWeight.regular)};
  color: ${props => getTextColor(props, props.mode, props.selected)};
  margin-right: ${props => props.right || 0}px;
  margin-left: ${props => props.left || 0}px;

  &:hover {
    color: ${props => (props.mode === MODE_DARK ? props.theme.color.tertiary : props.theme.color.secondary)};
  }
`;
