import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import posthog from 'posthog-js';

import { AppContextProvider } from 'contexts/AppContext/AppContext';
import { FbContextProvider } from 'contexts/FbContext/FbContext';

import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

import StandardLayout from 'layouts/StandardLayout/StandardLayout';
import ExternalLayout from 'layouts/ExternalLayout/ExternalLayout';

import AccountProfile from 'pages/AccountProfile/AccountProfile';
import Checkout from 'pages/Checkout/Checkout';
import CheckoutPayment from 'pages/Checkout/CheckoutPayment';
import Forbidden403 from 'pages/403/Forbidden403';
import NotFound404 from 'pages/404/NotFound404';
import Overview from 'pages/Overview/Overview';
import Orders from 'pages/Orders/Orders';
import OrderDetails from 'pages/OrderDetails/OrderDetails';
import LiveSessions from 'pages/LiveSessions/LiveSessions';
import Login from 'pages/Login/Login';
import Home from 'pages/Home/Home';
import LuckyDrawSlotMachine from 'pages/LuckyDrawSlotMachine/LuckyDrawSlotMachine';
import Products from 'pages/Products/Products';
import ProductDetails from 'pages/ProductDetails/ProductDetails';
import GiftDetails from 'pages/ProductDetails/ProductDetails';
import Notification from 'pages/Notification/Notification';
import Customers from 'pages/Customers/Customers';
import CustomerDetails from 'pages/CustomerDetails/CustomerDetails';
import Settings from 'pages/Settings/Settings';
import ShipmentDetails from 'pages/Settings/components/Shipments/components/ShipmentDetails/ShipmentDetails';
import PromotionDetails from 'pages/Settings/components/Promotions/components/PromotionDetails/PromotionDetails';
import SignUp from 'pages/SignUp/SignUp';
import SignUpChina from 'pages/SignUp/SignUpChina';
import PrintSingleInvoice from 'pages/PrintInvoice/PrintSingleInvoice/PrintSingleInvoice';
import PrintCheckoutInvoice from 'pages/PrintInvoice/PrintCheckoutInvoice/PrintCheckoutInvoice';
import PrintBulkInvoice from 'pages/PrintInvoice/PrintBulkInvoice/PrintBulkInvoice';
import PrintProductSticker from 'pages/PrintProductSticker/PrintProductSticker';
import AdminAccountValidation from 'pages/AdminAccountValidation/AdminAccountValidation';
import Reporting from 'pages/Reporting/Reporting';
import TeamProfile from 'pages/Team/Team';
import TeamDetails from 'pages/TeamDetails/TeamDetails';
import SubscriptionPlan from 'pages/SubscriptionPlan/SubscriptionPlan';
import SubscriptionPlanPayment from 'pages/SubscriptionPlanPayment/SubscriptionPlanPayment';

import {
  get403Route,
  get404Route,
  getHomeRoute,
  getAccountProfileRoute,
  getTeamRoute,
  getSubscriptionPlanRoute,
  getSubscriptionPlanPaymentRoute,
  getNewTeamRoute,
  getTeamDetailRoute,
  getLiveSessionsRoute,
  getLoginRoute,
  getNewProductRoute,
  getOrdersRoute,
  getNewOrderRoute,
  getOrderDetailRoute,
  getOrderDisplayPrintRoute,
  getBulkPrintOrdersRoute,
  getOverviewRoute,
  getPrintProductStickerRoute,
  getProductDetailsRoute,
  getProductsRoute,
  getNewGiftRoute,
  getGiftDetailsRoute,
  getNotificationRoute,
  getCustomersRoute,
  getCustomerDetailsRoute,
  getSettingsRoute,
  getNewShipmentRoute,
  getShipmentDetailRoute,
  getNewPromotionRoute,
  getPromotionDetailRoute,
  getSignUpRoute,
  getSignUpChinaRoute,
  getCheckoutRoute,
  getCheckoutPaymentRoute,
  getCheckoutInvoiceRoute,
  getAdminAccountsRoute,
  getLuckyDrawSlotMachineRoute,
  getReportingRoute
} from 'utils/routes';
import { init as initErrAndPerf } from 'utils/errorAndPerfLogging';

import './index.css';
import './i18n';
import reportWebVitals from './reportWebVitals';

// All translation files are required here to prevent the loading issue when opening a new page
// that have not loaded before
const ALL_TRANSLATIONS_FILES = [
  'common',
  'commonConstants',
  'compCurrencyDisplay',
  'compFBIGAccountConnection',
  'compFormPasswordInput',
  'compSocialMediaConnectionContent',
  'compSocialMediaLogin',
  'compTiktokAccountConnection',
  'compUploadPostPhotos',
  'compUploadProductPhotos',
  'layoutStandard',
  'modalCreateTiktokLivePost',
  'modalCustomerRemarks',
  'modalFBMessage',
  'modalLuckyDrawDetails',
  'modalLuckyDraws',
  'modalOrderUpdateShipment',
  'modalOverviewBlacklistedSocialMediaUsers',
  'modalOverviewCreateOrder',
  'modalOverviewIGMessages',
  'modalOverviewLiveStreamer',
  'modalOverviewPostTag',
  'modalOverviewProductKeyword',
  'modalOverviewProductQuickEdit',
  'modalPaymentInfo',
  'modalQuickAddProduct',
  'modalTiktokBumpProducts',
  'modalViewOrderSummary',
  'modalViewPayment',
  'pageAccountProfile',
  'pageApp',
  'pageBegkuningSettings',
  'pageCheckout',
  'pageCheckoutPayment',
  'pageCustomerDetails',
  'pageCustomers',
  'pageHome',
  'pageLogin',
  'pageLuckyDrawSlotMachine',
  'pageMicrositeSettings',
  'pageNotification',
  'pageOrder',
  'pageOrderDetails',
  'pageOverview',
  'pagePlan',
  'pagePrintBulkInvoice',
  'pageProduct',
  'pageProductDetails',
  'pagePromotion',
  'pagePromotionDetails',
  'pageReporting',
  'pageShipment',
  'pageShipmentDetails',
  'pageSignUp',
  'pageTeam',
  'pageTeamDetails',
  'utilsTable'
];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 2 * 1000 // 2 seconds
    }
  }
});
initErrAndPerf();
// posthog.init('phc_DlECAAcwO49QLynD8Wr2RqA7HHLRtXREuhEdYzxgM4Q', { api_host: 'https://us.posthog.com' });

const forbiddenRoute = get403Route();
const notFoundRoute = get404Route();
const homeRoute = getHomeRoute();
const accountAndProfileRoute = getAccountProfileRoute();
const accountAndTeamRoute = getTeamRoute();
const subscriptionPlanRoute = getSubscriptionPlanRoute();
const subscriptionPlanPaymentRoute = getSubscriptionPlanPaymentRoute();
const newTeamRoute = getNewTeamRoute();
const teamDetailsRoute = getTeamDetailRoute();
const overviewRoute = getOverviewRoute();
const printProductStickerRoute = getPrintProductStickerRoute();
const ordersRoute = getOrdersRoute();
const newOrdersRoute = getNewOrderRoute();
const orderDetailRoute = getOrderDetailRoute();
const orderDisplayPrintRoute = getOrderDisplayPrintRoute();
const ordersBulkPrintRoute = getBulkPrintOrdersRoute();
const liveSessionsRoute = getLiveSessionsRoute();
const loginRoute = getLoginRoute();
const productsRoute = getProductsRoute();
const newProductsRoute = getNewProductRoute();
const productDetailsRoute = getProductDetailsRoute();
const newGiftsRoute = getNewGiftRoute();
const giftDetailsRoute = getGiftDetailsRoute();
const notificationRoute = getNotificationRoute();
const customersRoute = getCustomersRoute();
const customerDetailsRoute = getCustomerDetailsRoute();
const settingsRoute = getSettingsRoute();
const newShipmentRoute = getNewShipmentRoute();
const shipmentDetailRoute = getShipmentDetailRoute();
const newPromotionRoute = getNewPromotionRoute();
const promotionDetailRoute = getPromotionDetailRoute();
const signUpRoute = getSignUpRoute();
const signUpChinaRoute = getSignUpChinaRoute();
const checkoutRoute = getCheckoutRoute();
const checkoutPaymentRoute = getCheckoutPaymentRoute();
const checkoutInvoiceRoute = getCheckoutInvoiceRoute();
const adminAccountsValidationRoute = getAdminAccountsRoute();
const luckyDrawSlotMachineRoute = getLuckyDrawSlotMachineRoute();
const reportingRoute = getReportingRoute();

const pathsInStandardLayout = [
  accountAndProfileRoute.path,
  accountAndTeamRoute.path,
  subscriptionPlanRoute.path,
  overviewRoute.path,
  productsRoute.path,
  newProductsRoute.path,
  productDetailsRoute.path,
  newGiftsRoute.path,
  giftDetailsRoute.path,
  ordersRoute.path,
  newOrdersRoute.path,
  orderDetailRoute.path,
  liveSessionsRoute.path,
  notificationRoute.path,
  customersRoute.path,
  customerDetailsRoute.path,
  settingsRoute.path,
  newShipmentRoute.path,
  shipmentDetailRoute.path,
  reportingRoute.path
];

const pathsInExternalLayout = [homeRoute.path, loginRoute.path, signUpRoute.path, checkoutRoute.path, subscriptionPlanPaymentRoute.path];

const InternalAppSwitch = () => {
  useEffect(() => {
    // Only track internal app usage
    posthog.init('phc_DlECAAcwO49QLynD8Wr2RqA7HHLRtXREuhEdYzxgM4Q', { api_host: 'https://us.posthog.com' });
  }, []);

  return (
    <StandardLayout>
      <Switch>
        {/* --------------------------- Account & Profile --------------------------- */}
        <ProtectedRoute exact path={accountAndProfileRoute.path} accessRole={accountAndProfileRoute.role} component={AccountProfile} />
        <ProtectedRoute exact path={accountAndTeamRoute.path} accessRole={accountAndTeamRoute.role} component={TeamProfile} />
        <ProtectedRoute exact path={subscriptionPlanRoute.path} accessRole={subscriptionPlanRoute.role} component={SubscriptionPlan} />
        <ProtectedRoute exact path={newTeamRoute.path} accessRole={newTeamRoute.role} component={TeamDetails} />
        <ProtectedRoute exact path={teamDetailsRoute.path} accessRole={teamDetailsRoute.role} component={TeamDetails} />

        {/* --------------------------- Overview --------------------------- */}
        <ProtectedRoute exact path={overviewRoute.path} accessRole={overviewRoute.role} component={Overview} />

        {/* --------------------------- Products --------------------------- */}
        <ProtectedRoute exact path={productsRoute.path} accessRole={productsRoute.role} component={Products} />
        <ProtectedRoute exact path={newProductsRoute.path} accessRole={newProductsRoute.role} component={ProductDetails} />
        <ProtectedRoute exact path={productDetailsRoute.path} accessRole={productDetailsRoute.role} component={ProductDetails} />

        {/* --------------------------- Gifts --------------------------- */}
        <ProtectedRoute exact path={newGiftsRoute.path} accessRole={newGiftsRoute.role} component={GiftDetails} />
        <ProtectedRoute exact path={giftDetailsRoute.path} accessRole={giftDetailsRoute.role} component={GiftDetails} />

        {/* --------------------------- Orders --------------------------- */}
        <ProtectedRoute exact path={ordersRoute.path} accessRole={ordersRoute.role} component={Orders} />
        <ProtectedRoute exact path={newOrdersRoute.path} accessRole={newOrdersRoute.role} component={OrderDetails} />
        <ProtectedRoute exact path={orderDetailRoute.path} accessRole={orderDetailRoute.role} component={OrderDetails} />

        {/* --------------------------- Live Sessions --------------------------- */}
        <ProtectedRoute exact path={liveSessionsRoute.path} accessRole={liveSessionsRoute.role} component={LiveSessions} />

        {/* --------------------------- Customers --------------------------- */}
        <ProtectedRoute exact path={customersRoute.path} accessRole={customersRoute.role} component={Customers} />
        <ProtectedRoute exact path={customerDetailsRoute.path} accessRole={customerDetailsRoute.role} component={CustomerDetails} />

        {/* --------------------------- Notification --------------------------- */}
        <ProtectedRoute exact path={notificationRoute.path} accessRole={notificationRoute.role} component={Notification} />

        {/* --------------------------- Settings --------------------------- */}
        <ProtectedRoute exact path={settingsRoute.path} accessRole={settingsRoute.role} component={Settings} />

        {/* --------------------------- Settings - Shipments --------------------------- */}
        <ProtectedRoute exact path={newShipmentRoute.path} accessRole={newShipmentRoute.role} component={ShipmentDetails} />
        <ProtectedRoute exact path={shipmentDetailRoute.path} accessRole={shipmentDetailRoute.role} component={ShipmentDetails} />

        {/* --------------------------- Reporting --------------------------- */}
        <ProtectedRoute exact path={reportingRoute.path} accessRole={reportingRoute.role} component={Reporting} />

        {/* --------------------------- Settings - Promotions --------------------------- */}
        <ProtectedRoute exact path={newPromotionRoute.path} accessRole={newPromotionRoute.role} component={PromotionDetails} />
        <ProtectedRoute exact path={promotionDetailRoute.path} accessRole={promotionDetailRoute.role} component={PromotionDetails} />
      </Switch>
    </StandardLayout>
  );
};

const ExternalAppSwitch = () => {
  return (
    <Switch>
      <Route exact path={homeRoute.path} accessRole={homeRoute.role} component={Home} />
      <Route exact path={checkoutInvoiceRoute.path} accessRole={checkoutInvoiceRoute.role} component={PrintCheckoutInvoice} />
      <ExternalLayout>
        <Route exact path={loginRoute.path} accessRole={loginRoute.role} component={Login} />
        <Route exact path={signUpRoute.path} accessRole={signUpRoute.role} component={SignUp} />
        <Route exact path={signUpChinaRoute.path} accessRole={signUpChinaRoute.role} component={SignUpChina} />
        <Route exact path={checkoutRoute.path} accessRole={checkoutRoute.role} component={Checkout} />
        <Route exact path={checkoutPaymentRoute.path} accessRole={checkoutPaymentRoute.role} component={CheckoutPayment} />
        <Route exact path={subscriptionPlanPaymentRoute.path} accessRole={subscriptionPlanPaymentRoute.role} component={SubscriptionPlanPayment} />
      </ExternalLayout>
    </Switch>
  );
};

const Main = () => {
  useTranslation(ALL_TRANSLATIONS_FILES);

  return (
    <Router>
      <ScrollToTop />
      <AppContextProvider>
        <FbContextProvider>
          <Switch>
            <Redirect exact from="/" to={homeRoute.path} />
            <ProtectedRoute
              exact
              path={luckyDrawSlotMachineRoute.path}
              accessRole={luckyDrawSlotMachineRoute.role}
              component={LuckyDrawSlotMachine}
            />
            <ProtectedRoute exact path={orderDisplayPrintRoute.path} accessRole={orderDisplayPrintRoute.role} component={PrintSingleInvoice} />
            <ProtectedRoute exact path={ordersBulkPrintRoute.path} accessRole={ordersBulkPrintRoute.role} component={PrintBulkInvoice} />
            <ProtectedRoute exact path={printProductStickerRoute.path} accessRole={printProductStickerRoute.role} component={PrintProductSticker} />
            <Route exact path={forbiddenRoute.path} component={Forbidden403} />
            <Route exact path={notFoundRoute.path} component={NotFound404} />
            <Route exact path={adminAccountsValidationRoute.path} accessRole={adminAccountsValidationRoute.role} component={AdminAccountValidation} />
            <Route path={pathsInStandardLayout}>
              <InternalAppSwitch />
            </Route>
            <Route path={pathsInExternalLayout}>
              <ExternalAppSwitch />
            </Route>
            <Redirect from="*" to={notFoundRoute.path} />
          </Switch>
        </FbContextProvider>
      </AppContextProvider>
    </Router>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <Main />
        </QueryClientProvider>
      </HelmetProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

reportWebVitals();
