import styled from '@emotion/styled';
import { TagOutlined } from '@ant-design/icons';

export const FormActionButtonsContainer = styled.div`
  text-align: right;
`;

export const TooltipIcon = styled(TagOutlined)`
  cursor: pointer;
  color: ${props => props.theme.color.lightTertiary};
`;
