import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import FormItem from 'components/FormItem/FormItem';

import { FullWidthDatePicker } from './FormDatePicker.styles';

const dateFormat = 'YYYY-MM-DD';
const DISABLE_DATE_TYPE_PAST = 'past';
const DISABLE_DATE_TYPE_FUTURE = 'future';

const FormDatePicker = ({ disabled, extraProps, label, name, placeholder, onFocus, requiredErrorMessage, disableDateType }) => {
  const getDisabledDate = current => {
    if (disableDateType === DISABLE_DATE_TYPE_FUTURE) {
      return current && current > moment().endOf('day');
    } else if (disableDateType === DISABLE_DATE_TYPE_PAST) {
      return current && current <= moment().startOf('day');
    }
    return undefined;
  };

  return (
    <>
      <FormItem requiredErrorMessage={requiredErrorMessage} name={name} label={label}>
        <FullWidthDatePicker disabled={disabled} format={dateFormat} placeholder={placeholder} {...extraProps} disabledDate={getDisabledDate} />
      </FormItem>
    </>
  );
};

FormDatePicker.propTypes = {
  extraProps: PropTypes.object,
  disableDateType: PropTypes.oneOf([DISABLE_DATE_TYPE_PAST, DISABLE_DATE_TYPE_FUTURE])
};

FormDatePicker.defaultProps = {
  extraProps: {}
};

export default FormDatePicker;
