import styled from '@emotion/styled';
import { List } from 'antd';
import { InfoCircleOutlined, WhatsAppOutlined } from '@ant-design/icons';
import MessengerIcon from 'components/Icon/MessengerIcon';

export const ListItem = styled(List.Item)`
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.s};
`;

export const SubListItem = styled(List.Item)`
  padding: 8px ${props => props.theme.spacing.s};
  background-color: #fbfbfb;
  border: none !important;
`;

export const FinalListItem = styled(List.Item)`
  padding: ${props => props.theme.spacing.s};
  background-color: ${props => props.theme.color.lighterTertiary};
  border: none !important;
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: 16px;
`;

export const PricingContentContainer = styled.div`
  width: 20%;
  text-align: right;
`;

export const ShipmentRemarks = styled.span`
  font-style: italic;
  color: ${props => props.theme.color.grey};
`;

export const OrderProductPurchaseDateContainer = styled.div`
  font-size: 12px;
`;

export const RemarksClickableIcon = styled(InfoCircleOutlined)`
  color: ${props => props.theme.color.primary};
  cursor: pointer;
  margin-right: 4px;
`;

export const WAClickableIcon = styled(WhatsAppOutlined)`
  color: #3dc14f;
  cursor: pointer;
  margin-right: 4px;
`;

export const FBClickableIcon = styled(MessengerIcon)`
  color: #0b88ef;
  cursor: pointer;
  margin-bottom: 4px;
`;
