import { useEffect, useState, useRef } from 'react';
import { CheckOutlined, DeleteOutlined, ExclamationCircleOutlined, InfoCircleFilled, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Form, Modal, message, Row, Tooltip, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { deleteProduct, postCreateProduct, patchProduct, useRefetchProducts } from 'apis/product';

import FormCheckboxToggle from 'components/Checkbox/FormCheckboxToggle/FormCheckboxToggle';
import FormLabel from 'components/FormLabel/FormLabel';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import Title from 'components/Title/Title';
import FormUpload from 'components/Upload/FormUpload/FormUpload';
import Variances from 'components/Variances/Variances';
import PhotoManager from 'components/PhotoManager/PhotoManager';
import { UploadPhotosModal, UploadPhotosTriggerButton, useUploadPhotosModal } from 'components/UploadProductPhotos/UploadProductPhotos';

import { getProductGeneralDetailsRoute, getProductsRoute, getGiftGeneralDetailsRoute } from 'utils/routes';

import { VarianceContainer, OverrideOrderCheckboxLabel, StyledCheckbox, TitleIcon, TitleText, ContentText } from './GeneralSettings.styles';
import { withAppContext } from 'contexts/AppContext/AppContext';

const { useForm } = Form;
const { confirm, error } = Modal;

const ConfirmUpdateModal = ({ t, visible, isLoading, onOk, onCancel }) => {
  const [shouldUpdateOrderPrices, setShouldUpdateOrderPrices] = useState(false);
  const [shouldUpdateOrderLabels, setShouldUpdateOrderLabels] = useState(false);
  const [shouldUpdateOrderCosts, setShouldUpdateOrderCosts] = useState(false);
  const [shouldUpdateOrderWeight, setShouldUpdateOrderWeight] = useState(false);
  const [shouldUpdateOrderPhotos, setShouldUpdateOrderPhotos] = useState(false);
  const [shouldUpdateOrderDesc, setShouldUpdateOrderDesc] = useState(false);

  return (
    <Modal
      visible={visible}
      confirmLoading={isLoading}
      okText={t('common:modal-ok-text')}
      cancelText={t('common:modal-cancel-text')}
      onOk={onOk(
        shouldUpdateOrderPrices,
        shouldUpdateOrderLabels,
        shouldUpdateOrderCosts,
        shouldUpdateOrderWeight,
        shouldUpdateOrderPhotos,
        shouldUpdateOrderDesc
      )}
      onCancel={onCancel}
    >
      <div style={{ marginBottom: '12px' }}>
        <TitleIcon />
        <TitleText>{t('common:confirm-modal-update-title')}</TitleText>
        <ContentText>{t('common:confirm-modal-update-content')}</ContentText>
      </div>
      <div>
        <Alert
          message={t('pageProductDetails:form-overwrite-title')}
          description={
            <div>
              <StyledCheckbox value={shouldUpdateOrderPrices} onChange={() => setShouldUpdateOrderPrices(!shouldUpdateOrderPrices)}>
                <OverrideOrderCheckboxLabel>{t('pageProductDetails:form-submit-update-checkbox-price-text')}</OverrideOrderCheckboxLabel>
              </StyledCheckbox>
              <StyledCheckbox value={shouldUpdateOrderLabels} onChange={() => setShouldUpdateOrderLabels(!shouldUpdateOrderLabels)}>
                <OverrideOrderCheckboxLabel>{t('pageProductDetails:form-submit-update-checkbox-name-text')}</OverrideOrderCheckboxLabel>
              </StyledCheckbox>
              <StyledCheckbox value={shouldUpdateOrderCosts} onChange={() => setShouldUpdateOrderCosts(!shouldUpdateOrderCosts)}>
                <OverrideOrderCheckboxLabel>{t('pageProductDetails:form-submit-update-checkbox-cost-text')}</OverrideOrderCheckboxLabel>
              </StyledCheckbox>
              <StyledCheckbox value={shouldUpdateOrderWeight} onChange={() => setShouldUpdateOrderWeight(!shouldUpdateOrderWeight)}>
                <OverrideOrderCheckboxLabel>{t('pageProductDetails:form-submit-update-checkbox-weight-text')}</OverrideOrderCheckboxLabel>
              </StyledCheckbox>
              <StyledCheckbox value={shouldUpdateOrderPhotos} onChange={() => setShouldUpdateOrderPhotos(!shouldUpdateOrderPhotos)}>
                <OverrideOrderCheckboxLabel>{t('pageProductDetails:form-submit-update-checkbox-photo-text')}</OverrideOrderCheckboxLabel>
              </StyledCheckbox>
              <StyledCheckbox value={shouldUpdateOrderDesc} onChange={() => setShouldUpdateOrderDesc(!shouldUpdateOrderDesc)}>
                <OverrideOrderCheckboxLabel>{t('pageProductDetails:form-submit-update-checkbox-desc-text')}</OverrideOrderCheckboxLabel>
              </StyledCheckbox>
            </div>
          }
        />
      </div>
    </Modal>
  );
};

const useConfirmUpdateModal = ({
  t,
  productId,
  updatePayload,
  shouldUpdateProductInventory,
  refetchProductDetails,
  setIsSubmitting,
  updateCoverPhoto
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const closeConfirmModal = () => {
    setIsVisible(false);
    setIsSubmitting(false);
  };

  const openConfirmModal = () => {
    setIsVisible(true);
    setIsSubmitting(true);
  };

  const handleOnConfirmUpdateModalOk = (
    shouldUpdateOrderPrices,
    shouldUpdateOrderLabels,
    shouldUpdateOrderCosts,
    shouldUpdateOrderWeight,
    shouldUpdateOrderPhotos,
    shouldUpdateOrderDesc
  ) => async () => {
    setIsLoading(true);
    const coverPhoto = await updateCoverPhoto();
    patchProduct(productId, {
      ...updatePayload,
      coverPhoto,
      shouldUpdateOrderPrices,
      shouldUpdateOrderLabels,
      shouldUpdateOrderCosts,
      shouldUpdateOrderWeight,
      shouldUpdateOrderPhotos,
      shouldUpdateOrderDesc,
      shouldUpdateProductInventory
    })
      .then(() => {
        message.success(t('common:update-success-message'));
        refetchProductDetails();
      })
      .catch(ex => {
        error({
          title: ex.message
        });
      })
      .finally(() => {
        setIsLoading(false);
        closeConfirmModal();
      });
  };

  const handleOnConfirmUpdateModalCancel = () => {
    closeConfirmModal();
  };

  return {
    isConfirmUpdateModalVisible: isVisible,
    isConfirmUpdateModalLoading: isLoading,
    openConfirmModal,
    handleOnConfirmUpdateModalOk,
    handleOnConfirmUpdateModalCancel
  };
};

const GeneralSettings = ({
  isLoading,
  isEditMode,
  isNormalProduct,
  product,
  refetchProductDetails,
  productTypesConst,
  productStatuses,
  productStatusesConst,
  store
}) => {
  const { t } = useTranslation(['common', 'pageProductDetails']);
  const history = useHistory();
  const [form] = useForm();
  const coverPhotoRef = useRef(null);
  const photosRef = useRef(null);
  const [shouldDisplayFormField, setShouldDisplayFormField] = useState(true);
  const [isAllowPreOrderChecked, setIsAllowPreOrderChecked] = useState(product.isAllowPreOrder);
  const [isCreateButtonClicked, setIsCreateButtonClicked] = useState(false);
  const [isLocked, setIsLocked] = useState(isEditMode);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updatePayload, setUpdatePayload] = useState({});
  const shouldUsePhotoManagerForCoverPhoto = product ? !product.coverPhotoFile : true;
  const [currentCoverPhoto, setCurrentCoverPhoto] = useState(product.coverPhoto ? [product.coverPhoto] : []);

  const refetchProducts = useRefetchProducts();

  const updateCoverPhoto = async () => {
    if (shouldUsePhotoManagerForCoverPhoto) {
      const [coverPhoto] = await coverPhotoRef.current.update();
      return coverPhoto || null;
    }

    return null;
  };

  const updateProductPhotos = async () => {
    return await photosRef.current?.update();
  };

  const {
    isConfirmUpdateModalVisible,
    isConfirmUpdateModalLoading,
    openConfirmModal,
    handleOnConfirmUpdateModalOk,
    handleOnConfirmUpdateModalCancel
  } = useConfirmUpdateModal({
    t,
    productId: product._id,
    updatePayload,
    shouldUpdateProductInventory: !isLocked,
    refetchProductDetails,
    setIsSubmitting,
    updateCoverPhoto
  });

  const {
    isUploadPhotosModalLoading,
    isUploadPhotosTriggerBtnLoading,
    isUploadPhotosModalVisible,

    photoFiles,
    photos,

    handleOnOpenUploadPhotosModal,
    handleOnCloseUploadPhotosModal,
    handleOnConfirmUploadPhotos,
    handleOnFileUploadPhotosModalChange
  } = useUploadPhotosModal({ updateProductPhotos });

  useEffect(() => {
    const isEditingMainProduct = isEditMode && (!product.variances || !product.variances.length > 0);
    const shouldDisplayStatus = !isLoading && (!isEditMode || isEditingMainProduct);
    setShouldDisplayFormField(shouldDisplayStatus);
    if (!isEditMode) {
      form.setFieldsValue({
        status: productStatusesConst && productStatusesConst.ACTIVE.code
      });
    }
  }, [isEditMode, isLoading, form, product, productStatusesConst]);

  const handleOnSave = async values => {
    try {
      const payload = {
        ...values,
        ...(!isNormalProduct && { price: 0 }),
        ...(!values.isAllowPreOrder && { maxPreOrderLimit: null }),
        type: productTypesConst[isNormalProduct ? 'NORMAL' : 'GIFT'].code
      };
      if (isEditMode) {
        setUpdatePayload(payload);
        openConfirmModal();
      } else {
        setIsSubmitting(true);
        const coverPhoto = await updateCoverPhoto();
        postCreateProduct({ ...payload, coverPhoto })
          .then(newProduct => {
            refetchProducts();
            history.push(isNormalProduct ? getProductGeneralDetailsRoute(newProduct._id).path : getGiftGeneralDetailsRoute(newProduct._id).path);
            setIsCreateButtonClicked(true);
            setIsSubmitting(false);
          })
          .catch(ex => {
            setIsSubmitting(false);
            error({
              title: t('pageProductDetails:error-modal-product-created-message'),
              content: ex.message
            });
          });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsSubmitting(false);
      message.error(t('common:update-fail-message'));
    }
  };

  const handleOnDelete = () => {
    setIsSubmitting(true);
    confirm({
      title: t('common:confirm-modal-delete-title', { itemName: t('pageProductDetails:item-name-lowercase') }),
      content: t('common:confirm-modal-delete-content'),
      icon: <ExclamationCircleOutlined />,
      okText: t('common:modal-ok-text'),
      cancelText: t('common:modal-cancel-text'),
      onOk() {
        deleteProduct(product._id)
          .then(() => {
            message.success(t('common:delete-success-message', { itemName: t('pageProductDetails:item-name') }));
            setIsSubmitting(false);
            history.push(getProductsRoute().path);
          })
          .catch(ex => {
            setIsSubmitting(false);
            error({
              title: ex.message
            });
          });
      },
      onCancel() {
        setIsSubmitting(false);
      }
    });
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Form
            form={form}
            initialValues={product}
            scrollToFirstError={true}
            style={{ width: '100%' }}
            onFinish={handleOnSave}
            onFinishFailed={handleOnSaveFailed}
          >
            <Card>
              <Row gutter={16}>
                <Col span={24} lg={12}>
                  <Row gutter={16}>
                    <Col span={24} md={isEditMode ? 12 : 24}>
                      <FormInput
                        label={t('pageProductDetails:form-input-label-product-label')}
                        name="label"
                        placeholder={t('pageProductDetails:form-input-placeholder-product-label')}
                        requiredErrorMessage={t('pageProductDetails:form-input-required-message-product-label')}
                      />
                    </Col>
                    <Col span={24} md={isEditMode || !isNormalProduct ? 12 : 8}>
                      <FormInput
                        label={t('pageProductDetails:form-input-label-product-keyword')}
                        name="keyword"
                        type="keyword"
                        placeholder={t('pageProductDetails:form-input-placeholder-product-keyword')}
                        requiredErrorMessage={t('pageProductDetails:form-input-required-message-product-keyword')}
                      />
                    </Col>
                    {shouldDisplayFormField && isNormalProduct && (
                      <Col span={24} md={isEditMode ? 12 : 8}>
                        <FormInputNumber
                          label={t('pageProductDetails:form-input-label-product-price')}
                          name="price"
                          type="financial"
                          placeholder={t('pageProductDetails:form-input-placeholder-product-price')}
                          requiredErrorMessage={t('pageProductDetails:form-input-required-message-product-price')}
                        />
                      </Col>
                    )}
                    {shouldDisplayFormField && (
                      <Col span={24} md={isEditMode || !isNormalProduct ? 12 : 8}>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                          <div style={{ width: isEditMode ? '90%' : '100%' }}>
                            <FormInputNumber
                              label={t('pageProductDetails:form-input-label-product-inventory')}
                              name="inventory"
                              isAllowNegative={true}
                              minValue={Number.MIN_SAFE_INTEGER}
                              placeholder={t('pageProductDetails:form-input-placeholder-product-inventory')}
                              requiredErrorMessage={t('pageProductDetails:form-input-required-message-product-inventory')}
                              disabled={isLocked}
                            />
                          </div>
                          {isEditMode && (
                            <Button
                              type="text"
                              icon={isLocked ? <LockOutlined /> : <UnlockOutlined />}
                              style={{ marginLeft: '4px', marginTop: '8px' }}
                              onClick={() => setIsLocked(!isLocked)}
                            />
                          )}
                        </div>
                      </Col>
                    )}
                    {shouldDisplayFormField && (
                      <>
                        <Col span={24} md={isNormalProduct ? 8 : 12}>
                          <FormInputNumber
                            label={t('pageProductDetails:form-input-label-product-cost')}
                            name="cost"
                            type="financial"
                            placeholder={t('pageProductDetails:form-input-placeholder-product-cost')}
                          />
                        </Col>
                        {isNormalProduct && (
                          <Col span={24} md={8}>
                            <FormInputNumber
                              label={t('pageProductDetails:form-input-label-product-ori-price')}
                              name="oriPrice"
                              type="financial"
                              placeholder={t('pageProductDetails:form-input-placeholder-product-ori-price')}
                            />
                          </Col>
                        )}
                        <Col span={24} md={isNormalProduct ? 8 : 12}>
                          <FormInputNumber
                            label={t('pageProductDetails:form-input-label-product-ori-inventory')}
                            name="oriInventory"
                            isAllowNegative={true}
                            minValue={Number.MIN_SAFE_INTEGER}
                            placeholder={t('pageProductDetails:form-input-placeholder-product-ori-inventory')}
                          />
                        </Col>
                      </>
                    )}
                    <Col span={24} md={8} lg={isNormalProduct ? 24 : 12}>
                      <FormRadioButton
                        label={t('pageProductDetails:form-input-label-product-status')}
                        name="status"
                        selections={productStatuses}
                        buttonStyle="solid"
                      />
                    </Col>
                    {shouldDisplayFormField && (
                      <Col span={24} md={12}>
                        <FormInputNumber
                          label={
                            <span>
                              {t('pageProductDetails:form-input-label-product-purchase-limit')}{' '}
                              <Tooltip title={t('pageProductDetails:form-input-label-product-purchase-limit-tips')}>
                                <InfoCircleFilled />
                              </Tooltip>
                            </span>
                          }
                          name="purchaseLimit"
                          placeholder={t('pageProductDetails:form-input-placeholder-product-purchase-limit')}
                          extraRules={[
                            { type: 'number', min: 1, message: t('pageProductDetails:form-input-required-message-product-purchase-limit') }
                          ]}
                        />
                      </Col>
                    )}
                    {shouldDisplayFormField && (
                      <Col span={24} md={12}>
                        <FormInputNumber
                          label={
                            <span>
                              {t('pageProductDetails:form-input-label-product-min-purchase-limit')}{' '}
                              <Tooltip title={t('pageProductDetails:form-input-label-product-min-purchase-limit-tips')}>
                                <InfoCircleFilled />
                              </Tooltip>
                            </span>
                          }
                          name="minPurchaseLimit"
                          placeholder={t('pageProductDetails:form-input-placeholder-product-min-purchase-limit')}
                          extraRules={[
                            { type: 'number', min: 2, message: t('pageProductDetails:form-input-required-message-product-min-purchase-limit') }
                          ]}
                        />
                      </Col>
                    )}
                    {isNormalProduct && (
                      <Col span={24} md={12}>
                        <FormCheckboxToggle
                          name="isAllowPreOrder"
                          label={
                            <span>
                              {t('pageProductDetails:form-input-label-product-allow-pre-order')}{' '}
                              <Tooltip title={t('pageProductDetails:form-input-label-product-allow-pre-order-tips')}>
                                <InfoCircleFilled />
                              </Tooltip>
                            </span>
                          }
                          formItemStyle={{ ...(isAllowPreOrderChecked && { marginBottom: 0 }) }}
                          onChange={e => setIsAllowPreOrderChecked(e.target.checked)}
                        />
                        {isAllowPreOrderChecked && (
                          <Col span={24} style={{ padding: 0 }}>
                            <FormInputNumber
                              name="maxPreOrderLimit"
                              placeholder={t('pageProductDetails:form-input-placeholder-product-max-pre-order-limit')}
                              extraRules={[
                                { type: 'number', min: 1, message: t('pageProductDetails:form-input-required-message-product-max-pre-order-limit') }
                              ]}
                            />
                          </Col>
                        )}
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col span={24} lg={12}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <FormInput
                        label={t('pageProductDetails:form-input-label-product-description')}
                        name="description"
                        placeholder={t('pageProductDetails:form-input-placeholder-product-description')}
                        type="textArea"
                        rows={6}
                      />
                    </Col>
                    {shouldDisplayFormField && (
                      <>
                        <Col span={24} md={12}>
                          <FormInputNumber
                            label={t('pageProductDetails:form-input-label-product-weight')}
                            name={['weight', 'amount']}
                            placeholder={t('pageProductDetails:form-input-placeholder-product-weight')}
                            precision={2}
                          />
                        </Col>
                        <Col span={24} md={12}>
                          <FormInput
                            label={t('pageProductDetails:form-input-label-product-sku')}
                            name="sku"
                            placeholder={t('pageProductDetails:form-input-placeholder-product-sku')}
                          />
                        </Col>
                      </>
                    )}
                    <Col span={24} md={12}>
                      {shouldUsePhotoManagerForCoverPhoto ? (
                        <PhotoManager
                          value={currentCoverPhoto}
                          onChange={setCurrentCoverPhoto}
                          label={t('pageProductDetails:form-input-label-product-cover-photo')}
                          buttonLabel={t('pageProductDetails:form-submit-cover-photo-upload-button-text')}
                          fileSizeThresholdToCompressInKb={200}
                          ref={coverPhotoRef}
                          storeId={store._id}
                          isHorizontalAlign={true}
                        />
                      ) : (
                        <FormUpload
                          name="coverPhotoFile"
                          label={t('pageProductDetails:form-input-label-product-cover-photo')}
                          buttonLabel={t('pageProductDetails:form-submit-cover-photo-upload-button-text')}
                          acceptTypes={['image/*']}
                          fileSizeLimitInMb={10}
                          isDisplayCard
                        />
                      )}
                    </Col>
                    {shouldDisplayFormField && isEditMode && (
                      <Col span={24} md={12} style={{ justifyContent: 'center', flexDirection: 'column' }}>
                        <FormLabel>{t('pageProductDetails:button-label-photos')}</FormLabel>
                        <UploadPhotosTriggerButton isLoading={isUploadPhotosTriggerBtnLoading} onClick={handleOnOpenUploadPhotosModal(product._id)} />
                      </Col>
                    )}
                    <Col span={24} style={{ marginTop: '16px' }}>
                      <FormInput
                        label={t('pageProductDetails:form-input-label-product-internal-note')}
                        name="internalNote"
                        placeholder={t('pageProductDetails:form-input-placeholder-product-internal-note')}
                        type="textArea"
                        rows={6}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Row gutter={8} style={{ margin: '16px 0px' }}>
              <Col>
                <Button htmlType="submit" type="primary" icon={<CheckOutlined />} loading={isSubmitting} disabled={isSubmitting}>
                  {isEditMode ? t('pageProductDetails:form-submit-update-button-text') : t('pageProductDetails:form-submit-create-button-text')}
                </Button>{' '}
                {isEditMode && (
                  <Button type="ghost" icon={<DeleteOutlined />} onClick={handleOnDelete} loading={isSubmitting}>
                    {t('pageProductDetails:form-submit-delete-button-text')}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Col>
        {isEditMode && product && (
          <VarianceContainer>
            <Divider />
            <Title style={{ marginLeft: 8 }}>{t('pageProductDetails:title-variance-section')}</Title>
            <Col span={24} xl={0}>
              <Card style={{ marginTop: '16px' }}>
                <Variances
                  isMobileView={true}
                  productId={product._id}
                  product={product}
                  showNewRow={isCreateButtonClicked}
                  variances={product.variances}
                  isNormalProduct={isNormalProduct}
                  onAddSuccess={isSuccess => setShouldDisplayFormField(!isSuccess)}
                />
              </Card>
            </Col>
            <Col span={0} xl={24}>
              <Variances
                productId={product._id}
                product={product}
                showNewRow={isCreateButtonClicked}
                variances={product.variances}
                isNormalProduct={isNormalProduct}
                onAddSuccess={isSuccess => setShouldDisplayFormField(!isSuccess)}
              />
            </Col>
          </VarianceContainer>
        )}
      </Row>
      <ConfirmUpdateModal
        t={t}
        visible={isConfirmUpdateModalVisible}
        isLoading={isConfirmUpdateModalLoading}
        onOk={handleOnConfirmUpdateModalOk}
        onCancel={handleOnConfirmUpdateModalCancel}
      />
      {shouldDisplayFormField && (
        <UploadPhotosModal
          visible={isUploadPhotosModalVisible}
          isLoading={isUploadPhotosModalLoading}
          initialPhotoFiles={photoFiles}
          initialPhotos={photos}
          onOk={handleOnConfirmUploadPhotos}
          onCancel={handleOnCloseUploadPhotosModal}
          onFileUploadChange={handleOnFileUploadPhotosModalChange}
          photosRef={photosRef}
        />
      )}
    </>
  );
};

export default withAppContext(GeneralSettings);
