import api from './apiHelper';
import { useCustomQuery, useCustomPaginatedQuery, useRefetchQuery } from 'hooks/reactQuery';

export const useGetNotifications = (query, onError) => {
  const getNotifications = async (key, query) => {
    return api.get(`/notif/paginated`, {
      params: { ...query }
    });
  };

  return useCustomPaginatedQuery('notifications', getNotifications, query, {
    onError
  });
};

export const useGetNotifStatistic = onError => {
  const getNotifStatistic = async () => {
    return api.get(`/notif/statistic`);
  };

  return useCustomQuery('notifStatistic', [], getNotifStatistic, {
    onError
  });
};

export const postNotification = payload => {
  return api.post('/notif', payload);
};

export const useRefetchNotifications = () => {
  const { refetch } = useRefetchQuery(['notifications']);
  return refetch;
};

export const useRefetcNotifStatistic = () => {
  const { refetch } = useRefetchQuery(['notifStatistic']);
  return refetch;
};
