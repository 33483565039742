import axios from 'axios';
import { getIsUserLoggedIn, getAuthToken } from 'utils/auth';
import {
  REACT_APP_S3_BUCKET_PRESIGN_URL,
  REACT_APP_S3_PRESIGN_URL,
  REACT_APP_S3_PRESIGN_URL_FOR_RECEIPT,
  REACT_APP_S3_DELETE_DOC_URL
} from 'config/env';

export const getAuthHeader = () => {
  if (getIsUserLoggedIn()) {
    return {
      Authorization: getAuthToken()
    };
  }
  return {};
};

export const getPresignedPostData = selectedFile => {
  return axios
    .post(REACT_APP_S3_BUCKET_PRESIGN_URL, {
      name: selectedFile.name,
      type: selectedFile.type
    })
    .then(res => res.data);
};

export const uploadFile = async (item, isReceipt) => {
  const { data } = (
    await axios.post(
      isReceipt ? REACT_APP_S3_PRESIGN_URL_FOR_RECEIPT : REACT_APP_S3_PRESIGN_URL,
      {
        name: item.name,
        type: item.type,
        storeId: item.storeId
      },
      isReceipt
        ? {}
        : {
            headers: {
              ...getAuthHeader()
            }
          }
    )
  ).data;

  const { fields } = data;
  const formData = new FormData();
  Object.entries(fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append('file', item.file);

  const url = data.url;

  return axios.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    withCredentials: false
  });
};

export const deleteDocuments = keys => {
  return axios.delete(REACT_APP_S3_DELETE_DOC_URL, {
    data: { keys },
    headers: {
      ...getAuthHeader()
    }
  });
};
