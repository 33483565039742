import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';

import { StyledDateRangePicker } from './DateRangePicker.styles';

const DateRangePicker = ({
  value,
  placeholder,

  isDisabled,

  extraDisabledDate,
  onChange,
  ...props
}) => {
  return (
    <Col span={24}>
      <StyledDateRangePicker
        value={value}
        placeholder={placeholder}
        disabledDate={calendarDate => extraDisabledDate(calendarDate)}
        disabled={isDisabled}
        onChange={onChange}
        {...props}
      />
    </Col>
  );
};

DateRangePicker.propTypes = {
  value: PropTypes.array,
  placeholder: PropTypes.array,
  isDisabled: PropTypes.bool,
  extraDisabledDate: PropTypes.func,
  onChange: PropTypes.func
};

DateRangePicker.defaultProps = {
  value: [undefined, undefined],
  placeholder: ['Start Date', 'End Date'],
  isDisabled: false,
  extraDisabledDate: () => false,
  onChange: () => {}
};

export default DateRangePicker;
