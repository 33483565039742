import api from './apiHelper';
import { useCustomQuery, useCustomPaginatedQuery, useRefetchQuery } from 'hooks/reactQuery';

export const postCreateShipment = payload => {
  return api.post('/shipment', payload);
};

export const useGetShipmentDetails = (shipmentId, query = {}, onError) => {
  const getShipmentDetails = async () => {
    return api.get(`/shipment/${shipmentId}`, {
      params: { ...query }
    });
  };

  return useCustomQuery('shipmentDetails', [shipmentId, query], getShipmentDetails, {
    onError,
    enabled: !!shipmentId
  });
};

export const useGetShipments = (query, onError) => {
  const getShipments = async () => {
    return api.get(`/shipment`, {
      params: { ...query }
    });
  };

  return useCustomQuery('shipments', [query], getShipments, {
    onError,
    shouldDefaultEmptyObject: false
  });
};

export const useGetPaginatedShipments = (query, onError) => {
  const getShipments = async (key, query) => {
    return api.get(`/shipment/paginated`, {
      params: { ...query }
    });
  };

  return useCustomPaginatedQuery('shipments', getShipments, query, {
    onError
  });
};

export const getShipmentLinkedOrders = (shipmentId, action) => {
  return api.get(`/shipment/${shipmentId}/linked-order`, {
    params: { action }
  });
};

export const patchShipment = (shipmentId, payload) => {
  return api.patch(`/shipment/${shipmentId}`, payload);
};

export const patchShipmentStatus = (shipmentId, status) => {
  return api.patch(`/shipment/${shipmentId}/status`, { status });
};

export const deleteShipment = (shipmentId, orderAction) => {
  return api.delete(`/shipment/${shipmentId}`, { params: { orderAction } });
};

export const useRefetchShipments = () => {
  const { refetch } = useRefetchQuery(['shipments']);

  return refetch;
};
