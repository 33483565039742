import { useEffect } from 'react';
import { Row, Skeleton, Tabs } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useGetPromotionDetails } from 'apis/promotion';

import BackButton from 'components/BackButton/BackButton';
import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import Title from 'components/Title/Title';

import GeneralSettings from './components/GeneralSettings/GeneralSettings';
import CustomerList from './components/CustomerList/CustomerList';

import { useFetchConstant } from 'hooks/constants';
import { useLocationQuery } from 'hooks/router';

import { promotionDetailsTabsObj } from 'utils/constants';
import { getLabelOfConstant, guard } from 'utils/general';
import { getPromotionDetailRoute, getPromotionGeneralDetailsRoute, getPromotionsRoute } from 'utils/routes';

import { StatusIndicatorContainer, TabsContainer } from './PromotionDetails.styles';

const { TabPane } = Tabs;

const useTab = promotionId => {
  const query = useLocationQuery();
  const history = useHistory();
  const selectedTab = guard(() => query.tab);

  useEffect(() => {
    if (!selectedTab && promotionId) {
      history.replace(getPromotionGeneralDetailsRoute(promotionId).path);
    }
  }, [selectedTab, history, promotionId]);

  const handleOnTabChange = newTab => {
    if (promotionId) {
      history.push(getPromotionDetailRoute(promotionId, newTab).path);
    }
  };

  return { selectedTab, handleOnTabChange };
};

const PromotionDetails = () => {
  const { t } = useTranslation(['common', 'pagePromotionDetails']);
  const { id: promotionId } = useParams();
  const { selectedTab, handleOnTabChange } = useTab(promotionId);

  const { isLoading: isPromotionLoading, data: promotion, refetch: refetchPromotionDetails } = useGetPromotionDetails(promotionId);

  const { selection: promotionTypes, data: promotionTypesConst, isLoading: isPromotionTypesLoading } = useFetchConstant('promotionTypes');
  const { selection: promotionStatuses, data: promotionStatusesConst, isLoading: isPromotionStatusesLoading } = useFetchConstant('promotionStatuses');

  const isLoading = isPromotionLoading || isPromotionTypesLoading || isPromotionStatusesLoading;
  const isEditMode = !!promotionId;

  useEffect(() => {
    if (promotionId && selectedTab === promotionDetailsTabsObj.GENERAL.code) {
      refetchPromotionDetails();
    }
  }, [promotionId, selectedTab, refetchPromotionDetails]);

  return (
    <FullWidthContainer>
      <>
        <Row type="flex" align="middle" style={{ marginBottom: '16px' }}>
          <BackButton backLink={getPromotionsRoute().path} />
          <Title>{isEditMode ? `${promotion.name} (${promotion.code})` : t('pagePromotionDetails:title')}</Title>
          <StatusIndicatorContainer>
            {isEditMode && promotion && promotionStatusesConst && (
              <StatusIndicator
                isActive={promotion.status === promotionStatusesConst.ACTIVE.code}
                text={getLabelOfConstant(promotion.status, promotionStatuses)}
              />
            )}
          </StatusIndicatorContainer>
        </Row>
        <TabsContainer activeKey={selectedTab} destroyInactiveTabPane onChange={handleOnTabChange}>
          <TabPane tab={t('pagePromotionDetails:tab-title-general')} key={promotionDetailsTabsObj.GENERAL.code}>
            {isLoading ? (
              <Skeleton active />
            ) : (
              <>
                <Helmet>
                  <meta name="title" id="gtm-title" content={isEditMode ? 'Promotion Details | General' : 'New Promotion'} />
                  <title>
                    {isEditMode
                      ? t('pagePromotionDetails:page-details-title-general', { promotion: promotion.name })
                      : t('pagePromotionDetails:page-new-title')}
                  </title>
                </Helmet>
                <GeneralSettings
                  isEditMode={isEditMode}
                  promotion={promotion}
                  refetchPromotionDetails={refetchPromotionDetails}
                  promotionTypes={promotionTypes}
                  promotionTypesConst={promotionTypesConst}
                  promotionStatuses={promotionStatuses}
                  promotionStatusesConst={promotionStatusesConst}
                />
              </>
            )}
          </TabPane>
          {isEditMode && promotionTypesConst && promotion.type === promotionTypesConst.VOUCHER.code && (
            <TabPane tab={t('pagePromotionDetails:tab-title-customer')} key={promotionDetailsTabsObj.CUSTOMER.code}>
              <Helmet>
                <meta name="title" id="gtm-title" content="Promotion Details | Customer List" />
                <title>
                  {isEditMode
                    ? t('pagePromotionDetails:page-details-title-customer-list', { promotion: promotion.name })
                    : t('pagePromotionDetails:page-new-title')}
                </title>
              </Helmet>
              <CustomerList promotionId={promotionId} />
            </TabPane>
          )}
        </TabsContainer>
      </>
    </FullWidthContainer>
  );
};

export default PromotionDetails;
