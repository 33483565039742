import { useMemo } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Form, message, Progress, Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetMyProfileDetails, patchMyProfileDetails } from 'apis/user';

import { withAppContext } from 'contexts/AppContext/AppContext';

import FBIGAccountConnection from 'components/FBIGAccountConnection/FBIGAccountConnection';
import FormInput from 'components/FormInput/FormInput';

import { useFetchConstant } from 'hooks/constants';

import { CompletedIcon, ProgressContainer, ProgressTitle, StyledCollapse, StyledPanel, StyledPanelTitle } from './ProfileAccountSetup.styles';

const { useForm } = Form;

const URL_REF_IG_LINK = 'https://help.instagram.com/570895513091465';

const SectionCard = ({ title, paddingBottom, bordered = false, children }) => (
  <Card title={<span style={{ color: '#2C278A' }}>{title}</span>} bordered={bordered} bodyStyle={{ padding: 24, paddingBottom }}>
    {children}
  </Card>
);

const StoreInfoForm = ({ onSubmitSuccess, isConnectedSocialMedia }) => {
  const [form] = useForm();
  const { t } = useTranslation(['pageAccountProfile']);

  const { isLoading: isMyProfileDetailsLoading, data: myProfileDetails, refetch: refetchMyProfileDetails } = useGetMyProfileDetails();
  const { selection: phoneCodes } = useFetchConstant('countries', {
    valueKey: 'phoneCode',
    labelKey: 'phoneCode'
  });

  const accountProfileFormInitialValue = useMemo(() => {
    const existingUserData = myProfileDetails && myProfileDetails.user;
    return {
      ...myProfileDetails,
      user: {
        ...existingUserData,
        contact:
          existingUserData && existingUserData.contact && existingUserData.contact.countryCode
            ? existingUserData.contact
            : {
                countryCode: phoneCodes && phoneCodes[0] && phoneCodes[0].value
              }
      }
    };
  }, [phoneCodes, myProfileDetails]);

  const handleOnUpdateProfileDetails = async () => {
    form
      .validateFields()
      .then(values =>
        patchMyProfileDetails({
          ...accountProfileFormInitialValue,
          store: {
            ...accountProfileFormInitialValue.store,
            ...values.store
          }
        })
          .then(() => {
            message.success(
              isConnectedSocialMedia ? t('pageOverview:profile-acc-setup-success') : t('pageAccountProfile:profile-update-success-message')
            );
            refetchMyProfileDetails();
            onSubmitSuccess();
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.log(error);
            message.error(t('pageAccountProfile:profile-update-fail-message'));
          })
      )
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        message.error(t('pageAccountProfile:profile-update-fail-message'));
      });
  };

  return (
    <Card bodyStyle={{ padding: '8px 16px' }}>
      {isMyProfileDetailsLoading ? (
        <Skeleton active />
      ) : (
        <Form form={form} initialValues={accountProfileFormInitialValue} scrollToFirstError={true}>
          <Row gutter={16}>
            <Col span={24}>
              <SectionCard title={t('pageAccountProfile:form-card-title-store-bank-info')} paddingBottom={8}>
                <Row gutter={16}>
                  <Col span={24} md={8}>
                    <FormInput
                      name={['store', 'bankDetails', 'accountNumber']}
                      label={t('pageAccountProfile:form-field-label-account-number')}
                      requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-account-number')}
                    />
                  </Col>
                  <Col span={24} md={8}>
                    <FormInput
                      name={['store', 'bankDetails', 'bankName']}
                      label={t('pageAccountProfile:form-field-label-bank-name')}
                      requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-bank-name')}
                    />
                  </Col>
                  <Col span={24} md={8}>
                    <FormInput
                      name={['store', 'bankDetails', 'beneficiaryName']}
                      label={t('pageAccountProfile:form-field-label-beneficiary-name')}
                      requiredErrorMessage={t('pageAccountProfile:form-field-required-error-message-beneficiary-name')}
                    />
                  </Col>
                </Row>
              </SectionCard>
            </Col>
          </Row>
          <Form.Item style={{ width: '100%' }}>
            <Button type="primary" htmlType="submit" block onClick={handleOnUpdateProfileDetails}>
              {t('pageAccountProfile:form-button-update-profile')}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Card>
  );
};

const SocialMediaConnectionSection = ({ t, isConnected }) => {
  return (
    <Card>
      <Row gutter={24}>
        <Col span={24} md={12}>
          <p>
            {isConnected
              ? t('pageOverview:profile-acc-setup-connection-section-main-message-connected')
              : t('pageOverview:profile-acc-setup-connection-section-main-message-notconnected')}
          </p>
          <FBIGAccountConnection />
        </Col>
        <Col span={24} md={12}>
          <Alert
            type="info"
            message={
              <span>
                <b>{t('pageOverview:profile-acc-setup-connection-section-info-1-title')}</b>
              </span>
            }
            description={t('pageOverview:profile-acc-setup-connection-section-info-1-message')}
            showIcon
          />
          <br />
          <Alert
            type="info"
            message={
              <span>
                <b>{t('pageOverview:profile-acc-setup-connection-section-info-2-title')}</b>
              </span>
            }
            description={
              <>
                <span>{t('pageOverview:profile-acc-setup-connection-section-info-2-message')}</span>
                <br />
                <a href={URL_REF_IG_LINK} target="_blank" rel="noreferrer">
                  {t('pageOverview:profile-acc-setup-connection-section-info-2-link')}
                </a>
              </>
            }
            showIcon
          />
        </Col>
      </Row>
    </Card>
  );
};

const ProfileAccountSetup = ({ store, isStoreConnectedToSocialMedia, isStoreRequiredInfoFilled, refetchStore }) => {
  const { t } = useTranslation(['common', 'pageOverview']);

  const setupItems = [
    {
      key: 'connect-social-media',
      title: t('pageOverview:profile-acc-setup-panel-2-title'),
      isCompleted: isStoreConnectedToSocialMedia,
      content: <SocialMediaConnectionSection t={t} isConnected={isStoreConnectedToSocialMedia} />
    },
    {
      key: 'update-store-info',
      title: t('pageOverview:profile-acc-setup-panel-1-title'),
      isCompleted: isStoreRequiredInfoFilled,
      content: <StoreInfoForm onSubmitSuccess={refetchStore} isConnectedSocialMedia={isStoreConnectedToSocialMedia} />
    }
  ];

  const progressPercentage = (setupItems.filter(setupItem => setupItem.isCompleted).length / setupItems.length) * 100;
  return (
    <div>
      <ProgressContainer>
        <Progress type="circle" percent={progressPercentage} width={60} strokeWidth={8} strokeColor="#52C41A" />
        <ProgressTitle>{t('pageOverview:profile-acc-setup-progress-title')}</ProgressTitle>
      </ProgressContainer>
      <StyledCollapse
        size="large"
        defaultActiveKey={[isStoreConnectedToSocialMedia ? setupItems[1].key : setupItems[0].key]}
        bordered={false}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      >
        {setupItems.map(setupItem => (
          <StyledPanel
            isCompleted={setupItem.isCompleted}
            header={<StyledPanelTitle>{setupItem.title}</StyledPanelTitle>}
            key={setupItem.key}
            extra={setupItem.isCompleted ? <CompletedIcon /> : null}
          >
            {setupItem.content}
          </StyledPanel>
        ))}
      </StyledCollapse>
    </div>
  );
};

export default withAppContext(ProfileAccountSetup);
