import { useEffect, useMemo, useState } from 'react';
import { Row, Skeleton, Tabs } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { useGetProductDetails } from 'apis/product';

import { withAppContext } from 'contexts/AppContext/AppContext';

import BackButton from 'components/BackButton/BackButton';
import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import TextButton from 'components/TextButton/TextButton';
import Title from 'components/Title/Title';

import GeneralSettings from './components/GeneralSettings/GeneralSettings';
import BulkPurchaseSettings from './components/BulkPurchaseSettings/BulkPurchaseSettings';
import ShipmentSettings from './components/ShipmentSettings/ShipmentSettings';
import MicrositeSettings from './components/MicrositeSettings/MicrositeSettings';
import InventoryChangeLogsModal from './components/InventoryChangeLogsModal/InventoryChangeLogsModal';

import { useFetchConstant } from 'hooks/constants';
import { useLocationQuery } from 'hooks/router';

import { productDetailsTabsObj } from 'utils/constants';
import { getLabelOfConstant, guard } from 'utils/general';
import {
  getProductsRoute,
  getProductGeneralDetailsRoute,
  getProductDetailsRoute,
  getGiftGeneralDetailsRoute,
  getGiftDetailsRoute
} from 'utils/routes';

import { StatusIndicatorContainer, TabsContainer } from './ProductDetails.styles';

const { TabPane } = Tabs;

const useTab = (productId, isNormalProduct) => {
  const query = useLocationQuery();
  const history = useHistory();
  const selectedTab = guard(() => query.tab);

  useEffect(() => {
    if (!selectedTab && productId) {
      history.replace(isNormalProduct ? getProductGeneralDetailsRoute(productId).path : getGiftGeneralDetailsRoute(productId).path);
    }
  }, [selectedTab, history, productId, isNormalProduct]);

  const handleOnTabChange = newTab => {
    if (productId) {
      history.push(isNormalProduct ? getProductDetailsRoute(productId, newTab).path : getGiftDetailsRoute(productId, newTab).path);
    }
  };

  return { selectedTab, handleOnTabChange };
};

const ProductDetails = ({ isMobile, store }) => {
  const { t } = useTranslation(['common', 'pageProductDetails']);
  const { id: productId } = useParams();
  const { pathname: routhPathName } = useLocation();
  const isNormalProduct = routhPathName.includes('products');
  const { selectedTab, handleOnTabChange } = useTab(productId, isNormalProduct);
  const [isChangeLogsModalVisible, setIsChangeLogsModalVisible] = useState(false);

  const { isLoading: isProductLoading, data: product, refetch: refetchProductDetails } = useGetProductDetails(productId);
  const { data: productTypesConst, isLoading: isProductTypesLoading } = useFetchConstant('productTypes');
  const { selection: productStatuses, data: productStatusesConst, isLoading: isProductStatusesLoading } = useFetchConstant('productStatuses');

  const isEditMode = useMemo(() => !!productId, [productId]);
  const isLoading = isProductLoading || isProductTypesLoading || isProductStatusesLoading;
  const hasValidMicrosite = useMemo(() => guard(() => store.subscriptionConfig.isMicrositeEnabled && !!store.micrositeSettings), [
    store.subscriptionConfig.isMicrositeEnabled,
    store.micrositeSettings
  ]);

  useEffect(() => {
    if (productId && selectedTab === productDetailsTabsObj.GENERAL.code) {
      refetchProductDetails();
    }
  }, [productId, selectedTab, refetchProductDetails]);

  return (
    <FullWidthContainer>
      <>
        <Row type="flex" align="middle" style={{ marginBottom: '16px' }}>
          <BackButton backLink={getProductsRoute().path} />
          <Title>
            {isEditMode
              ? product.label
                ? product.label
                : t('pageProductDetails:title-edit-loading')
              : t(`pageProductDetails:title${isNormalProduct ? '' : '-gift'}`)}
          </Title>
          <StatusIndicatorContainer>
            {isEditMode && product.status && productStatusesConst && (
              <StatusIndicator
                isActive={product.status === productStatusesConst.ACTIVE.code}
                text={getLabelOfConstant(product.status, productStatuses)}
              />
            )}
          </StatusIndicatorContainer>
        </Row>
        <TabsContainer
          activeKey={selectedTab}
          destroyInactiveTabPane
          tabBarExtraContent={
            isEditMode ? (
              <TextButton
                onClick={() => setIsChangeLogsModalVisible(true)}
                icon={<HistoryOutlined />}
                text={isMobile ? '' : t('pageProductDetails:inventory-change-logs-button')}
              />
            ) : null
          }
          onChange={handleOnTabChange}
        >
          <TabPane tab={t('pageProductDetails:tab-title-general')} key={productDetailsTabsObj.GENERAL.code}>
            {isLoading ? (
              <Skeleton active />
            ) : (
              <>
                <Helmet>
                  <meta
                    name="title"
                    id="gtm-title"
                    content={isEditMode ? `${isNormalProduct ? 'Product' : 'Gift'} Details | General` : `New ${isNormalProduct ? 'Product' : 'Gift'}`}
                  />
                  <title>
                    {isEditMode
                      ? t(`pageProductDetails:page-details${isNormalProduct ? '' : '-gift'}-title-general`, { productName: product.label })
                      : t(`pageProductDetails:page-new${isNormalProduct ? '' : '-gift'}-title`)}
                  </title>
                </Helmet>
                <GeneralSettings
                  isLoading={isLoading}
                  isEditMode={isEditMode}
                  isNormalProduct={isNormalProduct}
                  product={product}
                  refetchProductDetails={refetchProductDetails}
                  productTypesConst={productTypesConst}
                  productStatuses={productStatuses}
                  productStatusesConst={productStatusesConst}
                />
              </>
            )}
          </TabPane>
          {hasValidMicrosite && isNormalProduct && (
            <TabPane tab={t('pageProductDetails:tab-title-microsite')} key={productDetailsTabsObj.MICROSITE.code} disabled={!isEditMode}>
              <Helmet>
                <meta name="title" id="gtm-title" content={isEditMode ? 'Product Details | Microsite' : 'New Product'} />
                <title>
                  {isEditMode
                    ? t('pageProductDetails:page-details-title-microsite', { productName: product.label })
                    : t('pageProductDetails:page-new-title')}
                </title>
              </Helmet>
              <MicrositeSettings productId={productId} />
            </TabPane>
          )}
          {isNormalProduct && (
            <TabPane tab={t('pageProductDetails:tab-title-bulkpurchase')} key={productDetailsTabsObj.BULK_PURCHASE.code} disabled={!isEditMode}>
              <Helmet>
                <meta name="title" id="gtm-title" content="Product Details | Bulk Purchase" />
                <title>
                  {isEditMode
                    ? t('pageProductDetails:page-details-title-bulkpurchase', { productName: product.label })
                    : t('pageProductDetails:page-new-title')}
                </title>
              </Helmet>
              <BulkPurchaseSettings productId={productId} />
            </TabPane>
          )}
          <TabPane tab={t('pageProductDetails:tab-title-shipment')} key={productDetailsTabsObj.SHIPMENT.code} disabled={!isEditMode}>
            <Helmet>
              <meta name="title" id="gtm-title" content="Product Details | Shipment" />
              <title>
                {isEditMode
                  ? t('pageProductDetails:page-details-title-shipment', { productName: product.label })
                  : t('pageProductDetails:page-new-title')}
              </title>
            </Helmet>
            <ShipmentSettings productId={productId} />
          </TabPane>
        </TabsContainer>
        {isChangeLogsModalVisible && (
          <InventoryChangeLogsModal
            productId={productId}
            productLabel={product.label}
            variances={product.variances}
            onClose={() => setIsChangeLogsModalVisible(false)}
          />
        )}
      </>
    </FullWidthContainer>
  );
};

export default withAppContext(ProductDetails);
