import styled from '@emotion/styled';
import { Col } from 'antd';

export const PriceLabelCol = styled(Col)`
  font-weight: ${props => (props.highlight ? 700 : undefined)};
  color: ${props => (props.warn ? props.theme.color.warn : undefined)};
`;

export const PriceValueCol = styled(Col)`
  text-align: right;
  font-weight: ${props => (props.highlight ? 700 : undefined)};
  color: ${props => (props.warn ? props.theme.color.warn : undefined)};
`;
