import styled from '@emotion/styled';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const ContentText = styled.div`
  font-size: 14px;
  margin: 8px 0 0 38px;
`;

export const RootModal = styled(Modal)`
  width: 100% !important;

  @media (min-width: 768px) {
    width: 60% !important;
  }
`;

export const TitleIcon = styled(ExclamationCircleOutlined)`
  color: #faad14;
  margin-right: 16px;
  font-size: 22px;
`;

export const TitleText = styled.span`
  font-size: 16px;
`;

export const OverrideOrderCheckboxLabel = styled.span`
  font-size: 12px;
`;
