import { useMemo, useState, useCallback } from 'react';
import { Avatar, Modal, message, Row, Skeleton, Tooltip } from 'antd';
import { Helmet } from 'react-helmet-async';
import { EditOutlined, CopyOutlined, PrinterOutlined, DollarCircleOutlined, MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';

import { useGetStoreCustomerSuggestions } from 'apis/storecustomer';
import { useGetSocialMediaUserSuggestions } from 'apis/socialMediaUser';
import {
  postCreateOrder,
  postGetOrderSummary,
  postSendOrderCheckoutLinkViaEmail,
  patchCancelOrder,
  patchOrder,
  useGetOrderDetails,
  useGetOrderPayments,
  useRefetchOrders
} from 'apis/order';
import { useGetProductSuggestions } from 'apis/product';
import { useGetShipments } from 'apis/shipment';
import { useGetPromotionSuggestions } from 'apis/promotion';
import { useRefetchStoreOrderLimit } from 'apis/store';

import { withAppContext } from 'contexts/AppContext/AppContext';

import BackButton from 'components/BackButton/BackButton';
import CancelOrderModal from 'components/CancelOrderModal/CancelOrderModal';
import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import OrderDisplay from 'components/OrderDisplay/OrderDisplay';
import OrderSplitModal, { useOrderSplitModal } from './components/OrderSplitModal/OrderSplitModal';
import TextButton from 'components/TextButton/TextButton';
import Title from 'components/Title/Title';

import { useFetchConstant } from 'hooks/constants';
import { getOrderExtCheckoutLink } from 'utils/constants';
import { getOrdersRoute, getOrderDetailRoute, getOrderDisplayPrintRoute } from 'utils/routes';
import { getFormattedContact, guard, constructDisplayPriceWithCurrency } from 'utils/general';

import OrderForm from './components/OrderForm/OrderForm';
import {
  ContentContainer,
  OrderSummaryModal,
  SuccessIcon,
  StyledFacebookOutlined,
  StyledInstagramOutlined,
  StyledTikTokOutlined
} from './OrderDetails.styles';

const { confirm, error } = Modal;

const constructCustomerSuggestionLabel = ({ buyer: storeCustomer, isSourceFacebook, isSourceInstagram, isSourceTikTok }) => {
  const { name, email, contact, tier } = storeCustomer;

  const constructNameSuffix = () => {
    let suffix = '';

    const emailSuffix = guard(() => email || '', '');
    const contactNumberSuffix = guard(() => (contact ? getFormattedContact(contact) : ''), '');

    if (emailSuffix && contactNumberSuffix) {
      suffix = ` (${emailSuffix} | ${contactNumberSuffix})`;
    } else if (emailSuffix || contactNumberSuffix) {
      suffix = emailSuffix ? ` (${emailSuffix})` : ` (${contactNumberSuffix})`;
    }
    return suffix;
  };

  const customerTier = tier ? `${tier.emoji} ${tier.label} | ` : '';
  const nameWithSuffix = `${name}${constructNameSuffix()}`;

  switch (true) {
    case isSourceFacebook:
      return (
        <span>
          {customerTier} <StyledFacebookOutlined /> {nameWithSuffix}
        </span>
      );
    case isSourceInstagram:
      return (
        <span>
          {customerTier} <StyledInstagramOutlined /> {nameWithSuffix}
        </span>
      );
    case isSourceTikTok:
      return (
        <span>
          {customerTier} <StyledTikTokOutlined /> {nameWithSuffix}
        </span>
      );
    default:
      return `${customerTier}${nameWithSuffix}`;
  }
};

const constructSocialMediaUserSuggestionLabel = ({ socialMediaUser, isSourceFacebook, isSourceInstagram, isSourceTikTok }) => {
  const { name, profilePicUrl } = socialMediaUser;

  return (
    <span>
      {isSourceFacebook && <StyledFacebookOutlined />}
      {isSourceInstagram && <StyledInstagramOutlined />}
      {isSourceTikTok && <StyledTikTokOutlined />} {name} {profilePicUrl && <Avatar size="small" src={profilePicUrl} />}
    </span>
  );
};

const useFetchConstants = ({ currencyISO }) => {
  const { data: orderStatusesConst, selection: orderStatuses, isLoading: isOrderStatusesLoading } = useFetchConstant('orderStatuses');
  const { data: socialMediaSourcesConst, isLoading: isSocialMediaSourcesLoading } = useFetchConstant('socialMediaSources');
  const { data: shipmentStatusesConst, isLoading: isShipmentStatusesLoading } = useFetchConstant('shipmentStatuses');
  const { selection: paymentMethods, isLoading: isPaymentMethodsLoading } = useFetchConstant('paymentMethods');
  const { data: paymentStatusesConst, selection: paymentStatuses, isLoading: isPaymentStatusesLoading } = useFetchConstant('paymentStatuses');
  const { isLoading: isCountryCodesLoading, selection: countryCodes } = useFetchConstant('countries', {
    labelKey: 'phoneCode',
    valueKey: 'phoneCode',
    query: { currency: currencyISO }
  });
  const { isLoading: isCountriesLoading, selection: countries } = useFetchConstant('countries', {
    labelKey: 'name',
    valueKey: 'iso2',
    query: { currency: currencyISO }
  });
  const { data: productTypesConst, isLoading: isProductTypesLoading } = useFetchConstant('productTypes');

  const isLoading =
    isSocialMediaSourcesLoading ||
    isOrderStatusesLoading ||
    isShipmentStatusesLoading ||
    isPaymentMethodsLoading ||
    isPaymentStatusesLoading ||
    isCountryCodesLoading ||
    isCountriesLoading ||
    isProductTypesLoading;

  return {
    isLoading,

    isOrderStatusesLoading,
    orderStatusesConst,
    orderStatuses,

    isSocialMediaSourcesLoading,
    socialMediaSourcesConst,

    isShipmentStatusesLoading,
    shipmentStatusesConst,

    productTypesConst,
    paymentMethods,

    paymentStatusesConst,
    paymentStatuses,

    countryCodes,
    countries
  };
};

const useSubmitDataLoading = () => {
  const [isGeneratingPresaveSummary, setIsGeneratingPresaveSummary] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  return { isGeneratingPresaveSummary, isCancelling, isSaving, setIsGeneratingPresaveSummary, setIsCancelling, setIsSaving };
};

const OrderDetails = ({ store, storeCurrency, checkCanUserAccess, storeOperatorRoles }) => {
  const { t } = useTranslation(['common', 'pageOrderDetails', 'pageCheckout']);
  const history = useHistory();
  const { id: orderId } = useParams();
  const isEditMode = useMemo(() => !!orderId, [orderId]);

  const [isEditBtnClicked, setIsEditBtnClicked] = useState(false);
  const [isCancelOrderClicked, setIsCancelOrderClicked] = useState(false);
  const [orderSummary, setOrderSummary] = useState({});
  const [orderPayload, setOrderPayload] = useState({});
  const [searchKeyword, setSearchKeyword] = useState('');
  const [buyerSearchKeyword, setBuyerSearchKeyword] = useState('');
  const [socialMediaUserSearchKeyword, setSocialMediaUserSearchKeyword] = useState('');
  const [invalidVoucherIds, setInvalidVoucherIds] = useState([]);
  const [isSendingCheckoutLinkViaEmail, setIsSendingCheckoutLinkViaEmail] = useState(false);
  const [isSentCheckoutLinkViaEmail, setIsSentCheckoutLinkViaEmail] = useState(false);

  const delaySetSearchKeyword = useMemo(() => debounce(setSearchKeyword, 300), []);
  const delaySetBuyerSearchKeyword = useMemo(() => debounce(setBuyerSearchKeyword, 300), []);
  const delaySetSocialMediaUserSearchKeyword = useMemo(() => debounce(setSocialMediaUserSearchKeyword, 300), []);

  const isEmailCheckoutLinkEnabled = guard(() => store.subscriptionConfig.isEmailCheckoutLinkEnabled, false);

  const {
    isLoading: isConstantLoading,

    isOrderStatusesLoading,
    orderStatusesConst,
    orderStatuses,

    isSocialMediaSourcesLoading,
    socialMediaSourcesConst,

    isShipmentStatusesLoading,
    shipmentStatusesConst,

    paymentMethods,

    paymentStatusesConst,
    paymentStatuses,

    countryCodes,
    countries,

    productTypesConst
  } = useFetchConstants({ currencyISO: storeCurrency.iso });
  const { isLoading: isOrderLoading, data: order, refetch: refetchOrder } = useGetOrderDetails(orderId);
  const { isLoading: isPaymentDetailsLoading, data: orderPayments } = useGetOrderPayments(orderId);
  const { isLoading: isShipmentsLoading, data: shipments } = useGetShipments();

  const {
    isOrderSplitModalVisible,
    handleOnSplitOrderBtnClick: onSplitOrderBtnClick,
    handleOnSplitOrderModalCancel: onSplitOrderModalCancel
  } = useOrderSplitModal();

  const { isLoading: isProductSuggestionsLoading, paginatedData: productSuggestions } = useGetProductSuggestions({
    currentPage: 1,
    limit: 100,
    filter: { label: searchKeyword },
    extraQuery: { flatVariance: true },
    sorter: { _id: -1 }
  });

  const promotionSuggestionsProcessFunc = useCallback(
    promotions => {
      return promotions.map(promotion => {
        const minSpendDesc = promotion.minSpendingAmount
          ? t('pageOrderDetails:promotion-text-order-promotion-min-spend', {
              priceWithCurrency: constructDisplayPriceWithCurrency({
                amount: promotion.minSpendingAmount,
                currencySymbol: storeCurrency.symbol,
                currencyISO: storeCurrency.iso
              })
            })
          : '';
        const maxDiscountDesc = promotion.maxDiscountAmount
          ? t('pageOrderDetails:promotion-text-order-promotion-capped', {
              priceWithCurrency: constructDisplayPriceWithCurrency({
                amount: promotion.maxDiscountAmount,
                currencySymbol: storeCurrency.symbol,
                currencyISO: storeCurrency.iso
              })
            })
          : '';
        const labelDesc = minSpendDesc || maxDiscountDesc ? `(${minSpendDesc}${minSpendDesc && maxDiscountDesc ? ' ' : ''}${maxDiscountDesc})` : '';

        return {
          ...promotion,
          label: `${promotion.name} ${labelDesc}`,
          value: promotion._id
        };
      });
    },
    [t, storeCurrency]
  );
  const { isLoading: isPromotionSuggestionsLoading, data: promotionSuggestions } = useGetPromotionSuggestions(
    {
      orderId,
      customerId: order && order.customer && order.customer._id,
      isActiveOnly: true
    },
    { shouldFetch: isEditMode ? order && order.customer && order.customer._id : true },
    { postProcessFunc: promotionSuggestionsProcessFunc }
  );
  const { isLoading: isBuyersLoading, data: buyers, refetch: refetchStoreCustomerSuggestions } = useGetStoreCustomerSuggestions(
    buyerSearchKeyword,
    order && order.customer && order.customer._id,
    { shouldFetch: isEditMode ? order && order.customer && order.customer._id : true }
  );
  const { isLoading: isSocialMediaUsersLoading, data: socialMediaUsers } = useGetSocialMediaUserSuggestions(socialMediaUserSearchKeyword, {
    shouldFetch: !isEditMode
  });
  const refetchOrders = useRefetchOrders();
  const refetchStoreOrderLimit = useRefetchStoreOrderLimit();

  const { isGeneratingPresaveSummary, isCancelling, isSaving, setIsGeneratingPresaveSummary, setIsCancelling, setIsSaving } = useSubmitDataLoading();

  const isLoading = isConstantLoading || isOrderLoading || isPaymentDetailsLoading || isShipmentsLoading;
  const isAllowPrintInvoice = checkCanUserAccess([
    storeOperatorRoles.ADMIN.code,
    storeOperatorRoles.OPERATOR.code,
    storeOperatorRoles.PACKAGING.code
  ]);

  const postId = useMemo(() => history && history.location && history.location.state && history.location.state.postId, [history]);

  const shipmentMethods = useMemo(
    () =>
      isShipmentsLoading || isShipmentStatusesLoading
        ? []
        : shipments
            .map(shipment => ({
              value: shipment._id,
              label: `${shipment.label} (${shipment.deliveryMethod})`,
              disabled: shipment.status !== shipmentStatusesConst.ACTIVE.code
            }))
            .sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1)),
    [isShipmentsLoading, isShipmentStatusesLoading, shipments, shipmentStatusesConst]
  );

  const buyersSelection = useMemo(
    () =>
      isSocialMediaSourcesLoading || isBuyersLoading || !buyers
        ? []
        : buyers.map(buyer => ({
            ...buyer,
            value: buyer._id,
            label: constructCustomerSuggestionLabel({
              buyer,
              isSourceFacebook: buyer.source && buyer.source === socialMediaSourcesConst.FB.code,
              isSourceInstagram: buyer.source && buyer.source === socialMediaSourcesConst.IG.code,
              isSourceTikTok: buyer.source && buyer.source === socialMediaSourcesConst.TT.code
            })
          })),
    [isBuyersLoading, isSocialMediaSourcesLoading, buyers, socialMediaSourcesConst]
  );

  const socialMediaUsersSelection = useMemo(
    () =>
      isSocialMediaSourcesLoading || isSocialMediaUsersLoading || !socialMediaUsers
        ? []
        : socialMediaUsers.map(socialMediaUser => ({
            ...socialMediaUser,
            value: socialMediaUser._id,
            label: constructSocialMediaUserSuggestionLabel({
              socialMediaUser,
              isSourceFacebook: socialMediaUser.source && socialMediaUser.source === socialMediaSourcesConst.FB.code,
              isSourceInstagram: socialMediaUser.source && socialMediaUser.source === socialMediaSourcesConst.IG.code,
              isSourceTikTok: socialMediaUser.source && socialMediaUser.source === socialMediaSourcesConst.TT.code
            })
          })),
    [isSocialMediaUsersLoading, isSocialMediaSourcesLoading, socialMediaUsers, socialMediaSourcesConst]
  );

  const orderStatusesWOCancelled = useMemo(
    () => (isOrderStatusesLoading ? [] : orderStatuses.filter(orderStatus => orderStatus.value !== orderStatusesConst.CANCELLED.code)),
    [isOrderStatusesLoading, orderStatuses, orderStatusesConst]
  );

  const handleOnSave = values => {
    setIsGeneratingPresaveSummary(true);
    const payload = {
      customer: values.customer,
      products: values.products,
      remarks: values.remarks,
      tags: values.tags,
      shipment: values.shipment,
      status: values.status,
      payment: values.payment && values.payment.method ? values.payment : undefined,
      orderNumber: values.orderNumber,
      discount: values.discount,
      extraFee: values.extraFee,
      promotionDetails: values.promotionDetails,
      point: order.point
    };
    setOrderPayload(payload);
    postGetOrderSummary(payload)
      .then(orderSummary => {
        setOrderSummary(orderSummary);
      })
      .catch(ex => {
        error({
          title: t('pageOrderDetails:error-modal-order-created-message'),
          content: ex.message
        });
      })
      .finally(() => {
        setIsGeneratingPresaveSummary(false);
      });
  };

  const handleOnCancelOrderButtonClick = () => {
    setIsCancelOrderClicked(true);
  };

  const handleOnConfirmCancelOrder = payload => {
    setIsCancelling(true);
    patchCancelOrder(orderId, payload)
      .then(() => {
        message.success(t('pageOrder:modal-cancel-order-success-message'));
        setIsCancelling(false);
        setIsCancelOrderClicked(false);
        refetchOrders();
        history.push(getOrdersRoute().path);
      })
      .catch(ex => {
        message.error(t('common:update-fail-message'));
        setIsCancelling(false);
      });
  };

  const handleOnConfirmSplitOrder = useCallback(() => {
    refetchOrders();
    history.push(getOrdersRoute().path);
  }, [history, refetchOrders]);

  const resetOrderFormStates = () => {
    setOrderSummary({});
    setOrderPayload({});
    setBuyerSearchKeyword('');
    setIsEditBtnClicked(false);
  };

  const handleOnCopyCheckoutLinkButtonClick = () => {
    const { orderNumber, accessCode } = order;
    const link = getOrderExtCheckoutLink(orderNumber, accessCode);
    navigator.clipboard.writeText(link);
    message.success(t('pageOrder:copy-success-message'));
  };

  const handleOnSendCheckoutLinkViaEmailButtonClick = () => {
    setIsSendingCheckoutLinkViaEmail(true);
    postSendOrderCheckoutLinkViaEmail(order._id)
      .then(() => {
        setIsSentCheckoutLinkViaEmail(true);
        message.success(t('pageOrderDetails:send-checkout-link-via-email-success-message'));
      })
      .catch(ex => {
        message.error(t('pageOrderDetails:send-checkout-link-via-email-error-message'));
      })
      .finally(() => {
        setIsSendingCheckoutLinkViaEmail(false);
      });
  };

  const handlePromoErrorMessage = ex => {
    const invalidVoucherId = ex.substring(ex.indexOf('[') + 1, ex.lastIndexOf('] -'));
    if (invalidVoucherId) {
      setInvalidVoucherIds([...invalidVoucherIds, invalidVoucherId]);
    }

    if (
      ex.includes('Unable to apply this promotion because your spending amount does not meet the minimum criteria.') ||
      ex.includes('You can only redeem a maximum of')
    ) {
      return t('pageCheckout:voucher-modal-error-message-promo-updated');
    } else if (ex.includes('This promotion is no longer stackable.')) {
      return t('pageCheckout:voucher-modal-error-message-promo-unstackable');
    } else if (
      ex.includes('Customer Promo not found') ||
      ex.includes('This promotion is no longer available.') ||
      ex.includes('This promotion is expired.')
    ) {
      return t('pageCheckout:voucher-modal-error-message-promo-expired');
    } else {
      return ex;
    }
  };

  const checkIsProductsChanged = (oldProducts, newProducts) => {
    if (oldProducts.length !== newProducts.length) {
      return true;
    }

    return oldProducts.some((oldProduct, index) => {
      return (
        oldProduct._id !== newProducts[index].orderProductId ||
        oldProduct.matchingKeyword !== newProducts[index].keyword ||
        oldProduct.quantity !== newProducts[index].quantity
      );
    });
  };

  const handleOnConfirm = () => {
    setIsSaving(true);
    let apiCallPromise;

    if (isEditMode) {
      apiCallPromise = patchOrder(orderId, {
        ...orderPayload,
        postId,
        isProductsChanged: checkIsProductsChanged(order.products, orderPayload.products)
      })
        .then(updatedOrder => {
          message.success(t('common:update-success-message'));
          refetchOrder(updatedOrder);
          refetchOrders();
          refetchStoreCustomerSuggestions();
          resetOrderFormStates();
        })
        .catch(ex => {
          error({
            title: handlePromoErrorMessage(ex.message)
          });
        });
    } else {
      apiCallPromise = postCreateOrder(orderPayload)
        .then(newOrder => {
          refetchOrders();
          refetchStoreCustomerSuggestions();
          confirm({
            title: t('common:add-success-message'),
            icon: <SuccessIcon />,
            okText: t('pageOrderDetails:confirm-modal-order-created-ok-button-text'),
            cancelText: t('pageOrderDetails:confirm-modal-order-created-cancel-button-text'),
            onOk() {
              resetOrderFormStates();
              history.push(getOrderDetailRoute(newOrder._id).path);
              refetchStoreOrderLimit();
            },
            onCancel() {
              resetOrderFormStates();
              history.push(getOrdersRoute().path);
              refetchStoreOrderLimit();
            }
          });
        })
        .catch(ex => {
          error({
            title: t('pageOrderDetails:error-modal-order-created-message'),
            content: handlePromoErrorMessage(ex.message)
          });
        });
    }

    apiCallPromise.finally(() => {
      setIsSaving(false);
    });
  };

  return (
    <FullWidthContainer>
      <Helmet>
        <meta name="title" id="gtm-title" content={isEditMode ? 'Order Details' : 'New Order'} />
        <title>
          {isEditMode ? t('pageOrderDetails:page-details-title', { orderNumber: order.orderNumber }) : t('pageOrderDetails:page-new-title')}
        </title>
      </Helmet>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Row type="flex" justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
            <Row type="flex" align="middle" style={{ marginBottom: '8px' }}>
              <BackButton backLink={!isEditBtnClicked && getOrdersRoute().path} onClick={() => setIsEditBtnClicked(false)} />
              <Title>
                {(order.isPreserved || (order.status === orderStatusesConst.CHECKOUT.code && order.isPreservePending)) && (
                  <Tooltip title={t('pageOrderDetails:order-preserved-icon-tooltip-message')} placement="top">
                    <DollarCircleOutlined style={{ color: '#ffba00' }} />
                  </Tooltip>
                )}
                {isEditMode ? ` ${order.orderNumber}` : t('pageOrderDetails:title')}
              </Title>
            </Row>
            <Row>
              {isEditMode && (
                <>
                  {order.accessCode && (
                    <TextButton
                      icon={<CopyOutlined />}
                      onClick={handleOnCopyCheckoutLinkButtonClick}
                      text={t('pageOrder:table-action-button-copy-checkout-link')}
                    />
                  )}
                  {isAllowPrintInvoice && (
                    <>
                      <TextButton
                        icon={<PrinterOutlined />}
                        onClick={() => window.open(getOrderDisplayPrintRoute(order._id).path)}
                        text={t('pageOrderDetails:pdf-invoice-print')}
                      />
                      <TextButton
                        icon={<PrinterOutlined />}
                        onClick={() => window.open(getOrderDisplayPrintRoute(order._id, { compact: true }).path)}
                        text={t('pageOrderDetails:pdf-invoice-print-compact')}
                      />
                    </>
                  )}
                  {isEmailCheckoutLinkEnabled && order.accessCode && (
                    <TextButton
                      icon={<MailOutlined />}
                      onClick={handleOnSendCheckoutLinkViaEmailButtonClick}
                      text={t('pageOrderDetails:send-checkout-link-via-email-button')}
                      disabled={isSentCheckoutLinkViaEmail}
                      loading={isSendingCheckoutLinkViaEmail}
                    />
                  )}
                </>
              )}
            </Row>
          </Row>
          <OrderForm
            data={order}
            orderPayments={orderPayments}
            isLoading={isLoading}
            isButtonSaveLoading={isGeneratingPresaveSummary}
            isNew={!isEditMode}
            isProductSuggestionsLoading={isProductSuggestionsLoading}
            isPromotionSuggestionsLoading={isPromotionSuggestionsLoading}
            orderId={orderId}
            setIsGeneratingPresaveSummary={setIsGeneratingPresaveSummary}
            onCancel={handleOnCancelOrderButtonClick}
            onSave={handleOnSave}
            onSplitOrderBtnClick={onSplitOrderBtnClick}
            onSearchBuyer={delaySetBuyerSearchKeyword}
            onSearchSocialMediaUser={delaySetSocialMediaUserSearchKeyword}
            onSearchProduct={delaySetSearchKeyword}
            orderStatuses={orderStatusesWOCancelled}
            orderStatusesConst={orderStatusesConst}
            paymentMethods={paymentMethods}
            paymentStatuses={paymentStatuses}
            paymentStatusesConst={paymentStatusesConst}
            shipmentMethods={shipmentMethods}
            countryCodes={countryCodes}
            countries={countries}
            buyersSelection={buyersSelection}
            socialMediaUsersSelection={socialMediaUsersSelection}
            productSuggestions={productSuggestions}
            promotionSuggestions={promotionSuggestions}
            invalidVoucherIds={invalidVoucherIds}
            storeCurrency={storeCurrency}
          />
          {orderSummary && Object.keys(orderSummary).length > 0 && (
            <OrderSummaryModal
              visible={true}
              closable={false}
              maskClosable={false}
              style={{ top: 40 }}
              bodyStyle={{ overflowX: 'scroll' }}
              title={
                <>
                  <EditOutlined />{' '}
                  <span>
                    <b>{t('pageOrderDetails:modal-order-summary-title')}</b>
                  </span>
                </>
              }
              okText={t('pageOrderDetails:modal-order-summary-ok-button-text')}
              cancelText={t('pageOrderDetails:modal-order-summary-cancel-button-text')}
              onCancel={() => setOrderSummary({})}
              onOk={handleOnConfirm}
              confirmLoading={isSaving}
            >
              <ContentContainer>
                <OrderDisplay
                  order={orderSummary}
                  orderStatuses={orderStatuses}
                  paymentMethods={paymentMethods}
                  countries={countries}
                  productTypesConst={productTypesConst}
                  socialMediaSourcesConst={socialMediaSourcesConst}
                  storeCustomWAMessage={store.customWAMessage}
                  isAllowPrintInvoice={isAllowPrintInvoice}
                  isEmailCheckoutLinkEnabled={isEmailCheckoutLinkEnabled}
                />
              </ContentContainer>
            </OrderSummaryModal>
          )}
          {isCancelOrderClicked && (
            <CancelOrderModal
              isLoading={isCancelling}
              order={order}
              onCancel={() => setIsCancelOrderClicked(false)}
              onOk={handleOnConfirmCancelOrder}
            />
          )}
          {isOrderSplitModalVisible && (
            <OrderSplitModal
              orderStatuses={orderStatuses}
              orderId={orderId}
              orderStatus={order.status}
              orderProducts={order.products}
              onSplitOrderSuccess={handleOnConfirmSplitOrder}
              onCancel={onSplitOrderModalCancel}
            />
          )}
        </>
      )}
    </FullWidthContainer>
  );
};

export default withAppContext(OrderDetails);
