import { Col } from 'antd';
import styled from '@emotion/styled';

export const ContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  * {
    color: #000 !important;
  }

  @media (min-width: 768px) {
    width: 768px;
  }

  @media print {
    page-break-after: always;

    &:last-child {
      page-break-after: auto;
    }
  }
`;

export const HeaderContainer = styled.div`
  text-align: center;
  padding: 24px 24px 8px;
`;

export const StoreInfoContainer = styled.div`
  margin-bottom: 16px;
`;

export const StoreNameText = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
`;

export const StoreAddressText = styled.h3`
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0px;
`;

export const DateText = styled.p`
  font-size: 12px;
`;

export const ShipmentContainer = styled.div`
  text-align: left;
  border-bottom: 1px dashed #000000;
  border-top: 1px dashed #000000;
  padding: 8px 0px;
`;

export const ShipmentDetailText = styled.p`
  font-size: 14px;
  margin-bottom: 4px;
`;

export const ShipmentDetailContact = styled.p`
  font-size: 16px;
  font-weight: 700;
`;

export const InvoiceContentContainer = styled.div`
  padding: 0 8px;
`;

export const OrderInfoTitleText = styled.p`
  margin-bottom: 0px;
  padding: 0 16px;
`;

export const PriceBreakdownContainer = styled.div`
  text-align: left;
  border-bottom: 1px dashed #000000;
  border-top: 1px dashed #000000;
  padding: 8px 16px;
`;

export const PriceBreakdownItem = styled(Col)`
  text-align: right;
`;
