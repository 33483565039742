import { useEffect, useState } from 'react';
import { Button, Col, Form, Popover, Row, Space } from 'antd';
import { CheckOutlined, CloseOutlined, SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

import { logError } from 'utils/logging';

import {
  ContainerMask,
  EndButton,
  EndedMessageContainer,
  EndedMessage,
  EndedSubMessage,
  HeaderBanner,
  NoWinnersContainer,
  SettingsContainer,
  WinnerAvatar,
  WinnerContainer,
  WinnerDetailsContainer,
  WinnerListContainer,
  WinnerName,
  WinnerPrizeRemarks,
  WinnerRecordContainer
} from './WinnerBoard.styles';
import Avatar from '../Avatar/Avatar';
import WinnerBoardHeaderImg from '../../images/winner-board-header.png';
import NoWinnerPlaceholder from '../../images/podium.png';

const { useForm } = Form;

const WinnerToSpinForm = ({ form, numOfPotentialWinners, maxPotentialWinners, onSave, onClose }) => {
  const { t } = useTranslation(['pageLuckyDrawSlotMachine']);

  const handleOnSave = async e => {
    e.preventDefault();
    form
      .validateFields()
      .then(values => {
        onSave(values.numOfPotentialWinners);
      })
      .catch(error => {
        logError(error);
      });
  };

  return (
    <Form form={form} initialValues={{ numOfPotentialWinners }} scrollToFirstError={true} style={{ width: 'fit-content' }}>
      <Row gutter={16}>
        <Col span={16}>
          <FormInputNumber
            name="numOfPotentialWinners"
            minValue={1}
            extraRules={[
              {
                type: 'number',
                max: maxPotentialWinners,
                message: t('pageLuckyDrawSlotMachine:config-form-field-max-potential-winner-error-message', { maxNum: maxPotentialWinners })
              }
            ]}
          />
        </Col>
        <Col span={8}>
          <Space>
            <Button type="primary" icon={<CheckOutlined />} onClick={handleOnSave} />
            <Button icon={<CloseOutlined />} onClick={onClose} />
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

const WinnerRecord = ({ name, profilePicUrl, prizeRemarks }) => {
  return (
    <WinnerRecordContainer align="middle" gutter={8}>
      <Col span={4}>
        {profilePicUrl ? <WinnerAvatar profilePicUrl={profilePicUrl} /> : <Avatar round name={name} size={40} bgColor="#FEF8E5E0" />}
      </Col>
      <Col span={20}>
        <WinnerName>{name}</WinnerName>
        {prizeRemarks && <WinnerPrizeRemarks>{prizeRemarks}</WinnerPrizeRemarks>}
      </Col>
    </WinnerRecordContainer>
  );
};

const LuckyDrawConfig = ({ maxNumOfPotentialWinners, totalPotentialWinners, onSetLuckyDrawConfig }) => {
  const { t } = useTranslation(['pageLuckyDrawSlotMachine']);
  const [form] = useForm();
  const [showConfig, setShowConfig] = useState(false);
  const [numOfPotentialWinners, setNumOfPotentialWinners] = useState(1);

  useEffect(() => {
    if (totalPotentialWinners > 0) {
      setNumOfPotentialWinners(totalPotentialWinners);
    }
  }, [totalPotentialWinners]);

  const closeConfigPopover = () => {
    form.resetFields();
    setShowConfig(false);
  };

  const handleOnSaveConfigSuccess = () => {
    setNumOfPotentialWinners(totalPotentialWinners);
    closeConfigPopover();
  };

  const handleOnSaveConfigFail = () => {
    setNumOfPotentialWinners(totalPotentialWinners);
  };

  const handleOnSaveConfig = async newNumOfPotentialWinners => {
    if (newNumOfPotentialWinners !== numOfPotentialWinners) {
      setNumOfPotentialWinners(newNumOfPotentialWinners && newNumOfPotentialWinners > 1 ? newNumOfPotentialWinners : 1);
      await onSetLuckyDrawConfig({
        numOfPotentialWinners: newNumOfPotentialWinners,
        onSuccess: handleOnSaveConfigSuccess,
        onFail: handleOnSaveConfigFail
      });
    } else {
      closeConfigPopover();
    }
  };

  return (
    <SettingsContainer justify="end">
      <Popover
        style={{ maxWidth: '320px' }}
        placement="bottom"
        arrowPointAtCenter
        content={
          <div>
            <WinnerToSpinForm
              form={form}
              maxPotentialWinners={maxNumOfPotentialWinners}
              numOfPotentialWinners={numOfPotentialWinners}
              onSave={handleOnSaveConfig}
              onClose={closeConfigPopover}
            />
          </div>
        }
        title={t('pageLuckyDrawSlotMachine:config-title')}
        trigger="click"
        visible={showConfig}
        onVisibleChange={isVisible => {
          if (!isVisible) {
            form.resetFields();
          }

          setShowConfig(isVisible);
        }}
      >
        <Button icon={<SettingOutlined />}>
          {`${t('pageLuckyDrawSlotMachine:config-label-1')}`}
          <b> {numOfPotentialWinners} </b>
          {`${t('pageLuckyDrawSlotMachine:config-label-2', { numOfPotentialWinners })}`}
        </Button>
      </Popover>
    </SettingsContainer>
  );
};

const WinnerBoard = ({
  winners,
  isLuckyDrawEnded = false,
  isAllowConfig,
  maxNumOfPotentialWinners,
  totalParticipants = 0,
  totalPotentialWinners = 1,
  onSetLuckyDrawConfig,
  onEndLuckyDraw
}) => {
  const { t } = useTranslation(['common', 'pageLuckyDrawSlotMachine']);

  return (
    <WinnerContainer hasEnded={isLuckyDrawEnded}>
      {!isLuckyDrawEnded && isAllowConfig && (
        <LuckyDrawConfig
          maxNumOfPotentialWinners={maxNumOfPotentialWinners}
          totalPotentialWinners={totalPotentialWinners}
          onSetLuckyDrawConfig={onSetLuckyDrawConfig}
        />
      )}
      <ContainerMask hasEnded={isLuckyDrawEnded}>
        <HeaderBanner src={WinnerBoardHeaderImg} alt="winner board" />
        {isLuckyDrawEnded && (
          <EndedMessageContainer>
            <EndedMessage>
              <b>
                {totalParticipants} {t('pageLuckyDrawSlotMachine:winner-board-ended-message-part-1')}
              </b>
            </EndedMessage>
            <EndedSubMessage>{t('pageLuckyDrawSlotMachine:winner-board-ended-message-part-2')}</EndedSubMessage>
          </EndedMessageContainer>
        )}
        <WinnerListContainer hasEnded={isLuckyDrawEnded}>
          {winners && winners.length > 0 ? (
            winners.map(winner => (
              <WinnerDetailsContainer key={winner._id} span={24}>
                <WinnerRecord name={winner.name} profilePicUrl={winner.profilePicUrl} prizeRemarks={winner.prizeRemarks} />
              </WinnerDetailsContainer>
            ))
          ) : (
            <NoWinnersContainer span={24}>
              <img src={NoWinnerPlaceholder} alt="winner placeholder" width="320px" />
            </NoWinnersContainer>
          )}
        </WinnerListContainer>
      </ContainerMask>
      {!isLuckyDrawEnded && (
        <EndButton block onClick={onEndLuckyDraw}>
          {t('pageLuckyDrawSlotMachine:winner-board-end-button')}
        </EndButton>
      )}
    </WinnerContainer>
  );
};

export default WinnerBoard;
