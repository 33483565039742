import { useMemo, useState } from 'react';
import { Alert, Modal, Col, message, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import PromotionInfoItem from 'components/PromotionInfoItem/PromotionInfoItem';

import { getApplyPromoCode } from 'apis/external';
import { constructDisplayPriceWithCurrency } from 'utils/general';

import {
  AlertContainerRow,
  VoucherContainerRow,
  VoucherContainerInfoCol,
  VoucherCodeDisplay,
  VoucherCodeDiscount,
  VoucherCodeDescription,
  VoucherContainerButtonCol,
  ApplyButton
} from './VoucherModal.styles';

const Voucher = ({ t, voucher, isRedeemable, isSelected, isInvalidVoucher = false, onApply }) => {
  const status = isRedeemable && !isInvalidVoucher ? 'active' : 'inactive';
  const discountText = voucher.discountPercentage ? `${voucher.discountPercentage}%` : `${voucher.storeCurrency.symbol}${voucher.discountAmount}`;

  return (
    <VoucherContainerRow justify="center" gutter={8} status={status}>
      <VoucherContainerInfoCol span={16}>
        <VoucherCodeDisplay status={status}>
          {voucher.code} | {voucher.name}
        </VoucherCodeDisplay>
        <VoucherCodeDiscount status={status}>{discountText} off</VoucherCodeDiscount>
        <VoucherCodeDescription status="active" shouldHighlight>
          {voucher.isStackable && <>{t('pageCheckout:voucher-modal-desc-apply-together-with-other-vouchers')}</>}
        </VoucherCodeDescription>
        <VoucherCodeDescription status="inactive" shouldHighlight>
          {!!voucher.minSpendingAmount &&
            t('pageCheckout:voucher-modal-desc-min-spend', {
              priceWithCurrency: constructDisplayPriceWithCurrency({
                amount: voucher.minSpendingAmount,
                currencySymbol: voucher.storeCurrency.symbol,
                currencyISO: voucher.storeCurrency.iso
              })
            })}
          {!!voucher.minSpendingAmount && !!voucher.maxDiscountAmount && ' '}
          {!!voucher.maxDiscountAmount &&
            t('pageCheckout:voucher-modal-desc-capped', {
              priceWithCurrency: constructDisplayPriceWithCurrency({
                amount: voucher.maxDiscountAmount,
                currencySymbol: voucher.storeCurrency.symbol,
                currencyISO: voucher.storeCurrency.iso
              })
            })}
        </VoucherCodeDescription>
        <VoucherCodeDescription status="inactive" isLast>
          {isInvalidVoucher
            ? t('pageCheckout:voucher-modal-desc-invalid')
            : voucher.isExpired
            ? t('pageCheckout:voucher-modal-desc-expired')
            : t('pageCheckout:voucher-modal-desc-valid-till', { expiryDate: moment(voucher.expiryDate).format('MMMM D, YYYY') })}
        </VoucherCodeDescription>
      </VoucherContainerInfoCol>
      <VoucherContainerButtonCol span={8}>
        <ApplyButton
          type="primary"
          disabled={!isRedeemable || (isInvalidVoucher && !isSelected)}
          selected={isSelected}
          onClick={() => onApply(voucher)}
        >
          {isSelected
            ? isInvalidVoucher
              ? t('pageCheckout:voucher-modal-apply-button-text-remove')
              : t('pageCheckout:voucher-modal-apply-button-text-applied')
            : t('pageCheckout:voucher-modal-apply-button-text-apply')}
        </ApplyButton>
      </VoucherContainerButtonCol>
    </VoucherContainerRow>
  );
};

const VoucherModal = ({
  orderId,
  storeCurrency,
  vouchers,
  selectedVouchers,
  maxStackVocuherCount,
  invalidVoucherIds,
  onApplyVouchers,
  onCancel,
  removeSelfFromInvalidVoucherIds
}) => {
  const { t } = useTranslation(['common', 'pageCheckout']);
  const [localSelectedVouchers, setLocalSelectedVouchers] = useState(selectedVouchers);
  const [promoCodeInput, setPromoCodeInput] = useState();
  const [isSearchingPromoCode, setIsSearchingPromoCode] = useState(false);
  const sortedVouchers = useMemo(
    () =>
      vouchers
        .sort((a, b) => (a.isExpired && !b.isExpired ? 1 : !a.isExpired && b.isExpired ? -1 : 0))
        .sort((a, b) => (!a.isRedeemable && b.isRedeemable ? 1 : a.isRedeemable && !b.isRedeemable ? -1 : 0)),
    [vouchers]
  );

  const hasStackableVoucher = vouchers.some(selectedVoucher => selectedVoucher.isStackable);
  const localSelectedPromoCodes = localSelectedVouchers.filter(voucher => voucher.type === 'promoCode');

  const handleOnApplyPromoCode = async promoCode => {
    if (promoCode) {
      const isApplied = localSelectedVouchers.some(selectedVoucher => selectedVoucher.code === promoCode.toUpperCase());
      if (!isApplied) {
        setIsSearchingPromoCode(true);

        await getApplyPromoCode(orderId, promoCode)
          .then(promotion => {
            setPromoCodeInput();
            handleOnApplyLocalVoucher(promotion);
            removeSelfFromInvalidVoucherIds(promotion._id);
          })
          .catch(ex => {
            if (ex.message === 'This promotion is no longer available.') {
              message.error(t('pageCheckout:voucher-modal-error-message-promo-unavailable'));
            } else if (ex.message.includes('You can only redeem a maximum of')) {
              const maxPromoCount = ex.message.match(/\d+/)[0];
              const promoCode = ex.message.substring(ex.message.indexOf('[') + 1, ex.message.lastIndexOf(']'));
              message.error(t('pageCheckout:voucher-modal-error-message-promo-max-redeem', { maxPromoCount, promoCode }));
            } else {
              message.error(ex.message);
            }
          })
          .finally(() => {
            setIsSearchingPromoCode(false);
          });
      }
    }
  };

  const handleOnApplyLocalVoucher = voucher => {
    const isApplied = localSelectedVouchers.some(selectedVoucher => selectedVoucher._id === voucher._id);

    if (!isApplied && maxStackVocuherCount && localSelectedVouchers.length === maxStackVocuherCount) {
      return message.error(t('pageCheckout:voucher-modal-error-message-max-voucher', { maxStackVocuherCount }));
    }

    if (voucher.isStackable) {
      const hasUnstackableVoucher = localSelectedVouchers.some(selectedVoucher => !selectedVoucher.isStackable);

      if (isApplied) {
        setLocalSelectedVouchers(localSelectedVouchers.filter(selectedVoucher => selectedVoucher._id !== voucher._id));
      } else {
        setLocalSelectedVouchers(hasUnstackableVoucher ? [voucher] : [...localSelectedVouchers, voucher]);
      }
    } else {
      setLocalSelectedVouchers(isApplied ? [] : [voucher]);
    }
  };

  return (
    <Modal
      title={t('pageCheckout:voucher-modal-title')}
      visible={true}
      okText={t('pageCheckout:voucher-modal-button-done')}
      cancelText={t('pageCheckout:voucher-modal-button-cancel')}
      onOk={() => onApplyVouchers(localSelectedVouchers)}
      onCancel={onCancel}
      closable={false}
      bodyStyle={{ overflowY: 'scroll', height: '72vh' }}
      style={{ top: '4vh' }}
    >
      <>
        {t('pageCheckout:voucher-modal-input-label-promotional-code')}
        <Input.Search
          placeholder=""
          enterButton={t('pageCheckout:voucher-modal-apply-button-text-apply')}
          style={{ margin: '12px 0 16px' }}
          value={promoCodeInput}
          loading={isSearchingPromoCode}
          onSearch={handleOnApplyPromoCode}
          onChange={e => setPromoCodeInput(e.target.value)}
        />
        {localSelectedPromoCodes.length > 0 &&
          localSelectedPromoCodes.map((promotion, index) => (
            <PromotionInfoItem
              promotion={promotion}
              storeCurrency={storeCurrency}
              isInvalidVoucher={invalidVoucherIds.includes(promotion._id)}
              extraStyle={{ ...(index !== localSelectedPromoCodes.length - 1 && { marginBottom: 16 }) }}
              onRemoveClick={() => {
                handleOnApplyLocalVoucher(promotion);
              }}
            />
          ))}
      </>
      <br />
      <hr style={{ borderTop: '0px' }} />
      <br />
      {hasStackableVoucher && (
        <AlertContainerRow>
          <Col span={24}>
            <Alert
              message={
                maxStackVocuherCount
                  ? t('pageCheckout:voucher-modal-desc-limited-stackable-vouchers', { maxStackVocuherCount })
                  : t('pageCheckout:voucher-modal-desc-unlimited-stackable-vouchers')
              }
              type="info"
              showIcon
            />
          </Col>
        </AlertContainerRow>
      )}
      {sortedVouchers.length > 0 ? (
        sortedVouchers.map(voucher => (
          <Voucher
            t={t}
            key={voucher._id}
            voucher={voucher}
            isRedeemable={voucher.isRedeemable}
            isSelected={localSelectedVouchers.some(selectedVoucher => selectedVoucher._id === voucher._id)}
            isInvalidVoucher={invalidVoucherIds.includes(voucher._id)}
            onApply={handleOnApplyLocalVoucher}
          />
        ))
      ) : (
        <Alert
          message={t('pageCheckout:voucher-modal-empty-vouchers-alert-message')}
          description={t('pageCheckout:voucher-modal-empty-vouchers-alert-desc')}
          type="warning"
          showIcon
        />
      )}
    </Modal>
  );
};

export default VoucherModal;
