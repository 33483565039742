import React from 'react';
import propsTypes from 'prop-types';

import { message } from 'antd';
import { logError } from 'utils/logging';
import { writeFile, utils } from 'xlsx';

const XlsxDownload = ({
  asyncExportMethod,
  filename,
  sheetName,
  rowsBeforeHeader,
  headers,
  rowsAfterHeader,
  formatData,
  dataRowLimit,
  children,
  disable,
  onDataLoading,
  onDataLoaded
}) => {
  return (
    <>
      <div
        onClick={async () => {
          if (disable) {
            return;
          }
          onDataLoading();
          const xlsxData = await asyncExportMethod().catch(err => {
            logError(`Something went wrong when fetching Xlsx Data: ${err.message}`);
            message.error(`Something went wrong when fetching Xlsx Data: ${err.message}`);

            return null;
          });

          let newXlsxData;
          if (dataRowLimit && xlsxData.length > dataRowLimit) {
            newXlsxData = xlsxData.slice(0, dataRowLimit);
          } else {
            newXlsxData = xlsxData;
          }

          const formattedDataRows = newXlsxData.map(formatData);
          const worksheet = utils.aoa_to_sheet([...rowsBeforeHeader, headers, ...rowsAfterHeader, ...formattedDataRows]);
          const workbook = utils.book_new();
          utils.book_append_sheet(workbook, worksheet, sheetName);

          writeFile(workbook, filename);
          onDataLoaded();
        }}
      >
        {children}
      </div>
    </>
  );
};

export default XlsxDownload;

XlsxDownload.defaultProps = {
  children: undefined,
  asyncExportMethod: () => null,
  sheetName: 'Sheet 1',
  rowsBeforeHeader: [],
  headers: [],
  rowsAfterHeader: [],
  formatData: () => [],
  disable: false
};

XlsxDownload.propTypes = {
  children: propsTypes.node,
  asyncExportMethod: propsTypes.func.isRequired,
  sheetName: propsTypes.string,
  rowsBeforeHeader: propsTypes.array,
  headers: propsTypes.array.isRequired,
  rowsAfterHeader: propsTypes.array,
  formatData: propsTypes.func.isRequired,
  disable: propsTypes.bool
};
