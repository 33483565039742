import { useMemo, useState } from 'react';
import { Card, Col, Form, Modal, message, Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import { postCreateProduct } from 'apis/product';
import { useFetchConstant } from 'hooks/constants';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

import { withAppContext } from 'contexts/AppContext/AppContext';

const { useForm } = Form;
const { error } = Modal;

const generateRandomKeyword = length => {
  const uppercaseAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let randomKeyword = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * uppercaseAlphabet.length);
    randomKeyword += uppercaseAlphabet.charAt(randomIndex);
  }

  return randomKeyword;
};

const QuickAddProductModal = ({ visible, onClose, onProductCreated }) => {
  const { t } = useTranslation(['common', 'modalQuickAddProduct']);
  const [form] = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: productStatusesConst, isLoading: isProductStatusesLoading } = useFetchConstant('productStatuses');

  const formInitialValues = useMemo(() => {
    return {
      keyword: generateRandomKeyword(8),
      inventory: 1
    };
  }, []);

  const handleOnFormSave = async e => {
    e.preventDefault();

    try {
      const allFieldsValue = await form.validateFields();
      setIsSubmitting(true);

      const payload = {
        ...allFieldsValue,
        status: productStatusesConst.ACTIVE.code,
        weight: {}
      };
      await postCreateProduct(payload)
        .then(newProduct => {
          setIsSubmitting(false);
          onProductCreated(newProduct);
        })
        .catch(ex => {
          setIsSubmitting(false);
          error({
            title: t('modalQuickAddProduct:error-modal-product-created-message'),
            content: ex.message
          });
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsSubmitting(false);
      message.error(t('common:update-fail-message'));
    }
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  const isLoading = isSubmitting || isProductStatusesLoading;

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={t('modalQuickAddProduct:modal-title')}
      okText={t('modalQuickAddProduct:modal-ok-text')}
      cancelText={t('common:modal-cancel-text')}
      destroyOnClose
      width={700}
      onOk={handleOnFormSave}
    >
      <Skeleton loading={isLoading}>
        <Card>
          <Form form={form} initialValues={formInitialValues} style={{ width: '100%' }} onFinishFailed={handleOnSaveFailed} scrollToFirstError>
            <FormInput
              label={t('modalQuickAddProduct:form-input-label-product-name')}
              name="label"
              placeholder={t('modalQuickAddProduct:form-input-placeholder-product-name')}
              requiredErrorMessage={t('modalQuickAddProduct:form-input-required-message-product-name')}
            />
            <Row gutter={16}>
              <Col span={8}>
                <FormInput
                  label={t('modalQuickAddProduct:form-input-label-keyword')}
                  name="keyword"
                  type="keyword"
                  placeholder={t('modalQuickAddProduct:form-input-placeholder-keyword')}
                  requiredErrorMessage={t('modalQuickAddProduct:form-input-required-message-keyword')}
                />
              </Col>
              <Col span={8}>
                <FormInputNumber
                  label={t('modalQuickAddProduct:form-input-label-price')}
                  name="price"
                  type="financial"
                  placeholder={t('modalQuickAddProduct:form-input-placeholder-price')}
                  requiredErrorMessage={t('modalQuickAddProduct:form-input-required-message-price')}
                />
              </Col>
              <Col span={8}>
                <FormInputNumber
                  label={t('modalQuickAddProduct:form-input-label-inventory')}
                  name="inventory"
                  type="number"
                  min={1}
                  placeholder={t('modalQuickAddProduct:form-input-placeholder-inventory')}
                  requiredErrorMessage={t('modalQuickAddProduct:form-input-required-message-inventory')}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Skeleton>
    </Modal>
  );
};

export default withAppContext(QuickAddProductModal);
