import React from 'react';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { REACT_APP_API_ENDPOINT } from 'config/env';
import { guard } from 'utils/general';

import { withAppContext } from 'contexts/AppContext/AppContext';

import FormCheckboxToggle from 'components/Checkbox/FormCheckboxToggle/FormCheckboxToggle';

import TiktokConnectionContent from './components/TiktokConnectionContent/TiktokConnectionContent';
import { MainContainer } from './TiktokAccountConnection.styles';

const TiktokAccountConnection = ({ store, begkuningSettings, isDisabledEdit, isUpdating, onSetAutoLinkPost }) => {
  const { t } = useTranslation(['compTiktokAccountConnection']);
  const storeTiktok = guard(() => store.socialMedia.tt);

  return (
    <MainContainer bodyStyle={{ padding: '24px' }}>
      <Row gutter={8}>
        <Col span={24}>
          <TiktokConnectionContent tiktokUser={storeTiktok} />
        </Col>
        {begkuningSettings && (
          <Col span={24}>
            <FormCheckboxToggle
              defaultChecked={begkuningSettings.isAutoLinkPost}
              label={t('compTiktokAccountConnection:form-field-label-auto-link-post')}
              onChange={e => {
                onSetAutoLinkPost(e.target.checked);
              }}
              disabled={isDisabledEdit || isUpdating}
            />
          </Col>
        )}
        <Col span={24}>
          <Button
            type="primary"
            block
            loading={isUpdating}
            disabled={isDisabledEdit}
            onClick={() => {
              window.location.href = `${REACT_APP_API_ENDPOINT}/tiktok/${store._id}/oauth`;
            }}
          >
            {!storeTiktok ? t('compTiktokAccountConnection:button-connect-text') : t('compTiktokAccountConnection:button-reconnect-text')}
          </Button>
        </Col>
      </Row>
    </MainContainer>
  );
};

export default withAppContext(TiktokAccountConnection);
