import styled from '@emotion/styled';
import { Alert, Col, Divider, Modal, Row } from 'antd';

export const RootContainerModal = styled(Modal)`
  width: 90% !important;

  @media (min-width: 768px) {
    width: 70% !important;
  }
  @media (min-width: 992px) {
    width: 80% !important;
  }
  @media (min-width: 1440px) {
    width: 60% !important;
  }
`;

export const SMSectionAlert = styled(Alert)`
  margin-bottom: 16px;
`;

export const SMSectionActionContainer = styled.div`
  display: inline-block;
`;

export const SMSectionClickableText = styled.span`
  border-bottom: 1px solid ${props => (props.isFB ? props.theme.color.tertiary : '#000')};
  color: ${props => (props.isFB ? props.theme.color.tertiary : '#000')};
  cursor: pointer;
  display: inline-block;
  margin-left: 8px;
  padding-bottom: 4px;
`;

export const Title = styled(Row)`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: 18px;
  margin-bottom: 8px;
`;

export const StoreName = styled.p`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-family: ${props => props.theme.fontFamily.title};
  color: ${props => props.theme.color.tertiary};
  font-size: 24px;
  margin-bottom: 0px;
`;

export const StoreDescription = styled.p`
  font-size: 14px;
  margin-bottom: 0px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const StyledDivider = styled(Divider)`
  color: ${props => props.theme.color.lightGrey};
  margin-bottom: 8px;
  margin-top: 15px;
`;

export const ThankYouText = styled.p`
  font-family: ${props => props.theme.fontFamily.title};
  color: ${props => props.theme.color.primary};
  font-size: 24px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    font-size: 32px;
  }
`;

export const OrderCancelText = styled.p`
  font-family: ${props => props.theme.fontFamily.title};
  color: ${props => props.theme.color.red};
  font-size: 24px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    font-size: 32px;
  }
`;

export const OrderDescTitle = styled.span`
  word-wrap: break-word;
  white-space: normal;
`;

export const RecipientRowContainer = styled(Row)`
  margin-bottom: 32px;
`;

export const RecipientCol = styled(Col)`
  white-space: pre-wrap;
`;
