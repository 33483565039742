import { useState } from 'react';
import { ExclamationCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { message, Modal, Form, Skeleton, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetAllOperators } from 'apis/store';
import { patchUpdatePostById } from 'apis/post';

import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';

import { RootModal } from './AddLiveStreamerModal.styles';

const { confirm, error } = Modal;
const { useForm } = Form;

const AddLiveStreamerModal = ({ hasCommissionEnabled, liveStreamerRemarks, postId, onClose, onSuccess }) => {
  const { t } = useTranslation(['common', 'modalOverviewLiveStreamer']);
  const [form] = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isLoading: isOperatorLoading, data: users } = useGetAllOperators({ shouldFetchLiveStreamersOnly: true });

  const isLoading = isOperatorLoading;

  const formattedUsers = !isLoading
    ? users.map(user => ({
        label: user.name,
        value: user._id
      }))
    : [];

  const handleOnConfirmSelectedLiveStreamer = updatedFormValues => {
    setIsSubmitting(true);
    patchUpdatePostById(postId, { liveStreamers: updatedFormValues.liveStreamers, liveStreamerRemarks: updatedFormValues.liveStreamerRemarks })
      .then(() => {
        message.success(t('common:update-success-message'));
        onSuccess();
        setIsSubmitting(false);
      })
      .catch(ex => {
        setIsSubmitting(false);
        error({
          title: ex.message
        });
      })
      .finally(() => {
        onClose();
      });
  };

  const handleOnAddLiveStreamer = () => {
    form
      .validateFields()
      .then(values => {
        confirm({
          title: t('modalOverviewLiveStreamer:add-live-streamer-confirm-pop-up-title'),
          content: t('modalOverviewLiveStreamer:add-live-streamer-confirm-pop-up-content'),
          okText: t('modalOverviewLiveStreamer:add-live-streamer-confirm-pop-up-ok-button-text'),
          cancelText: t('common:modal-cancel-text'),
          icon: <ExclamationCircleOutlined />,
          onOk() {
            handleOnConfirmSelectedLiveStreamer(values);
          }
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        setIsSubmitting(false);
      });
  };

  return (
    <RootModal
      title={t('modalOverviewLiveStreamer:modal-title')}
      visible={true}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={onClose}
      footer={
        <Button type="primary" icon={<CheckOutlined />} loading={isSubmitting} onClick={handleOnAddLiveStreamer}>
          {t('modalOverviewLiveStreamer:save-button-text')}
        </Button>
      }
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <Form form={form} initialValues={{ liveStreamerRemarks }}>
          {hasCommissionEnabled && (
            <FormSelection
              isMultiple={true}
              allowClear
              style={{
                width: '100%'
              }}
              name="liveStreamers"
              label={t('modalOverviewLiveStreamer:form-selection-label-select-live-streamer')}
              selections={formattedUsers}
              placeholder={t('modalOverviewLiveStreamer:live-streamer-selection-placeholder')}
            />
          )}
          <FormInput name="liveStreamerRemarks" label={t('modalOverviewLiveStreamer:form-input-live-streamer-remark')} customMarginBottom={0} />
        </Form>
      )}
    </RootModal>
  );
};

export default AddLiveStreamerModal;
