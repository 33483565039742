import api from './apiHelper';
import { useCustomQuery } from 'hooks/reactQuery';

export const getReportingData = query => {
  return api.get('/reporting', { params: query });
};

export const getReportingByProductPurchaseDate = query => {
  return api.get('/reporting/product-purchase-date', { params: query });
};

export const useGetProductSoldDetails = query => {
  const getProductSoldDetails = async () => {
    return api.get(`/reporting/product-sold-details`, {
      params: query
    });
  };

  return useCustomQuery('productSoldDetails', [query], getProductSoldDetails, {
    shouldDefaultEmptyObject: false,
    enabled: !!query
  });
};

export const useGetProductSoldDetailsByOrders = payload => {
  const getProductSoldDetailsByOrders = async () => {
    // Need to use post because payload might be huge due to order ids
    return api.post(`/reporting/product-sold-details-orders`, payload);
  };

  return useCustomQuery('productSoldDetailsOrders', [payload], getProductSoldDetailsByOrders, {
    shouldDefaultEmptyObject: false,
    enabled: !!payload
  });
};
