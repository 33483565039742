import { Col, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import ImagePreview from 'components/ImagePreview/ImagePreview';

import { InfoText, ProductLabelText, ProductDescText, PriceContainer, FinalPrice, OriPrice } from './ProductDetailsModal.styles';

const ProductDetailsModal = ({ selectedProductDetails, storeCurrency, onCancel }) => {
  const { t } = useTranslation(['common', 'pageCheckout']);
  const { label, description, keyword, quantity, total, oriPrice, previewImageUrl, thumbnailImageUrl } = selectedProductDetails;

  return (
    <Modal
      visible
      footer={null}
      okText={t('pageCheckout:points-modal-button-confirm')}
      cancelText={t('common:modal-cancel-text')}
      onCancel={onCancel}
    >
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          {!!thumbnailImageUrl && <ImagePreview width="70%" previewImageUrl={previewImageUrl} thumbnailImageUrl={thumbnailImageUrl} />}
        </Col>
        <Col span={24}>
          <ProductLabelText>{label}</ProductLabelText>
        </Col>
        <Col span={24}>
          <InfoText>
            {quantity}x {keyword}
          </InfoText>
        </Col>
        <Col span={24}>
          <ProductDescText>{description}</ProductDescText>
        </Col>
        <Col span={24}>
          <PriceContainer>
            <FinalPrice storeCurrency={storeCurrency} amount={total} hasOriPrice={!!oriPrice} />
            {!!oriPrice && <OriPrice storeCurrency={storeCurrency} amount={oriPrice * quantity} />}
          </PriceContainer>
        </Col>
      </Row>
    </Modal>
  );
};

export default ProductDetailsModal;
