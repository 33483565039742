import styled from '@emotion/styled';
import { Button } from 'antd';

export const PanelContainer = styled.div`
  border-radius: 24px;
  display: flex;
  padding: ${props => props.theme.spacing.xxs};
  width: fit-content;
  justify-content: space-evenly;
  background-color: ${props => props.theme.color.lighterTertiary};
  margin: ${props => props.theme.spacing.regular} auto;
`;

export const StyledSelectionButton = styled(Button)`
  border-radius: 24px;
  color: ${props => (props.isSelected ? props.theme.color.white : props.theme.color.tertiary)} !important;
  background-color: ${props => (props.isSelected ? props.theme.color.tertiary : props.theme.color.lighterTertiary)} !important;
  border-color: ${props => (props.isSelected ? props.theme.color.tertiary : props.theme.color.lighterTertiary)} !important;

  &:hover {
    color: ${props => !props.isSelected && props.theme.color.tertiary} !important;
    background-color: ${props => !props.isSelected && props.theme.color.white} !important;
    border-color: ${props => !props.isSelected && props.theme.color.white} !important;
  }
`;
