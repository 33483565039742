import styled from '@emotion/styled';
import { Button, Col, Row, Select } from 'antd';

export const DownloadCSVButton = styled(Button)`
  border-color: #2c278a;
  color: #2c278a;
  margin-bottom: 8px;
`;

export const SortingSelection = styled(Select)`
  margin-bottom: 8px;
`;

export const HorizontalScrollableContainer = styled(Col)`
  overflow: auto;
  padding-bottom: 24px;
  white-space: nowrap;
  width: 100%;
`;

export const ProductSoldHeaderRow = styled(Row)`
  width: 100%;
`;

export const LoadingContainer = styled.div`
  height: 240px;
  width: 100%;
`;

export const CardContainer = styled.div`
  display: inline-block;
  margin-right: 16px;
  height: 240px;
  width: 224px;
  border: 1px solid ${props => props.theme.color.borderColor};

  &:hover {
    box-shadow: 2px 2px 6px ${props => props.theme.color.borderColor};
  }
`;

export const CardHeaderSection = styled.div`
  height: 30%;
`;

export const HeaderContentContainer = styled.div`
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  height: 70%;
  justify-content: space-between;
  padding: 16px;
`;

export const QtyLeftContainer = styled.div`
  border-radius: 50%;
  background-color: ${props => (props.qtyLeft > 0 ? props.theme.color.tertiary : props.theme.color.red)};
  color: ${props => props.theme.color.white};
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  width: 64px;
`;

export const CardContentSection = styled.div`
  height: 40%;
  border-bottom: 1px solid ${props => props.theme.color.borderColor};
`;

export const NameText = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 16px 24px 0 24px;
  width: 100%;
  white-space: normal;
`;

export const KeywordText = styled.span`
  font-weight: 700;
  font-size: 18px;
`;

export const CardFooterSection = styled.div`
  height: 30%;
  display: flex;
  align-items: center;
  padding: 0 24px;
`;

export const QtySoldText = styled.span`
  width: 100%;
  white-space: normal;
`;
