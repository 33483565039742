import { useState } from 'react';
import { List, Avatar, Image, message, Modal, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import TextButton from 'components/TextButton/TextButton';
import ImagePlaceholder from 'images/no-image-placeholder.jpg';

import { useGetIGMessages, postStartLiveChat } from 'apis/igMessage';
import { useFetchConstant } from 'hooks/constants';
import { useIsLoadings } from 'hooks/utils';
import { formatToDateTimeWithPMAM } from 'utils/date';

import { RootModal } from './IGMessagesQuickViewModal.styles';

const { TextArea } = Input;

const useFetchConstants = () => {
  const { data: igMessageTypesConst, isLoading: isIGMessageTypesLoading } = useFetchConstant('igMessageTypes');
  const { data: igMessageContentTypeConst, isLoading: isIGMessageContentTypeLoading } = useFetchConstant('igMessageContentType');

  const { isLoading: isConstantLoading } = useIsLoadings([isIGMessageTypesLoading, isIGMessageContentTypeLoading]);

  return { isConstantLoading, igMessageTypesConst, igMessageContentTypeConst };
};

const IGMessageStoryMention = ({ t, igMessage, igMessageContentTypeConst }) => {
  return (
    <List.Item>
      <List.Item.Meta
        avatar={<Avatar size={48} icon={<UserOutlined />} />}
        title={t('modalOverviewIGMessages:list-item-title-story-mention')}
        description={
          <div>
            <span>{t('modalOverviewIGMessages:list-item-desc-story-mention')}</span>
            {igMessage.contentType === igMessageContentTypeConst.EXPIRED.code ? (
              <>
                <br />
                <span>
                  <i>{t('modalOverviewIGMessages:list-item-desc-expired')}</i>
                </span>
              </>
            ) : null}
            <br />
            <span style={{ fontSize: '12px' }}>{formatToDateTimeWithPMAM(igMessage.messageAt)}</span>
          </div>
        }
      />
      <div>
        {igMessage.contentType === igMessageContentTypeConst.EXPIRED.code && (
          <Image src={ImagePlaceholder} preview={false} alt="expired story placeholder" width={130} height={230} />
        )}
        {igMessage.contentType === igMessageContentTypeConst.IMAGE.code && (
          <Image src={igMessage.cdnUrl} alt="IG story mention (Photo)" width={130} height={230} fallback={ImagePlaceholder} />
        )}
        {igMessage.contentType === igMessageContentTypeConst.VIDEO.code && (
          <video src={igMessage.cdnUrl} alt="IG story mention (Video)" width={130} height={230} controls />
        )}
      </div>
    </List.Item>
  );
};

const IGMessageStoryReply = ({ t, igMessage }) => {
  return (
    <List.Item key={igMessage._id}>
      <List.Item.Meta
        avatar={<Avatar size={48} icon={<UserOutlined />} />}
        title={t('modalOverviewIGMessages:list-item-title-story-reply')}
        description={
          <div>
            <span>
              {igMessage.message
                ? t('modalOverviewIGMessages:list-item-desc-story-reply', { message: igMessage.message })
                : t('modalOverviewIGMessages:list-item-desc-story-reply-empty-message')}
            </span>
            <br />
            <span style={{ fontSize: '12px' }}>{formatToDateTimeWithPMAM(igMessage.messageAt)}</span>
          </div>
        }
      />
    </List.Item>
  );
};

const IGMessageLiveAgent = ({ t, igMessage, onStartLiveChatClick }) => {
  const [liveChatMessage, setLiveChatMessage] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <List.Item key={igMessage._id}>
      <List.Item.Meta
        avatar={<Avatar size={48} icon={<UserOutlined />} />}
        title={t('modalOverviewIGMessages:list-item-title-live-agent')}
        description={
          <div>
            <span>
              {igMessage.replyMessage
                ? t('modalOverviewIGMessages:list-item-desc-live-agent-with-reply', { message: igMessage.replyMessage })
                : t('modalOverviewIGMessages:list-item-desc-live-agent')}
            </span>
            <br />
            <span style={{ fontSize: '12px' }}>{formatToDateTimeWithPMAM(igMessage.messageAt)}</span>
          </div>
        }
      />
      <div>
        {!igMessage.replyMessage && (
          <TextButton
            text={t('modalOverviewIGMessages:list-item-button-live-agent')}
            onClick={() => {
              setIsModalVisible(true);
            }}
          />
        )}
      </div>
      <Modal
        visible={isModalVisible}
        title={t('modalOverviewIGMessages:modal-live-agent-title')}
        okButtonProps={{ disabled: !liveChatMessage }}
        okText={t('modalOverviewIGMessages:modal-live-agent-button-text')}
        onOk={() => {
          onStartLiveChatClick(igMessage._id, liveChatMessage);
          setIsModalVisible(false);
        }}
        cancelText={t('common:modal-cancel-text')}
        destroyOnClose
      >
        <TextArea
          placeholder={t('modalOverviewIGMessages:modal-live-agent-text-area-placeholder')}
          onChange={e => {
            setLiveChatMessage(e.target.value);
          }}
        />
      </Modal>
    </List.Item>
  );
};

const IGMessageNormal = ({ t, igMessage, igMessageContentTypeConst }) => {
  return (
    <List.Item key={igMessage._id}>
      <List.Item.Meta
        avatar={<Avatar size={48} icon={<UserOutlined />} />}
        title={t('modalOverviewIGMessages:list-item-title-normal')}
        description={
          <div>
            <span>
              {!igMessage.contentType
                ? t('modalOverviewIGMessages:list-item-desc-normal', { message: igMessage.message })
                : t('modalOverviewIGMessages:list-item-desc-normal-attachment')}
            </span>
            {igMessage.contentType === igMessageContentTypeConst.EXPIRED.code ? (
              <>
                <br />
                <span>
                  <i>{t('modalOverviewIGMessages:list-item-desc-normal-attachment-expired')}</i>
                </span>
              </>
            ) : null}
            <br />
            <span style={{ fontSize: '12px' }}>{formatToDateTimeWithPMAM(igMessage.messageAt)}</span>
          </div>
        }
      />
      <div>
        {igMessage.contentType === igMessageContentTypeConst.EXPIRED.code && (
          <Image src={ImagePlaceholder} preview={false} alt="expired or deleted attachmenet" width={130} height={230} />
        )}
        {igMessage.contentType === igMessageContentTypeConst.IMAGE.code && (
          <Image src={igMessage.cdnUrl} alt="image attachment" width={130} height={230} fallback={ImagePlaceholder} />
        )}
        {igMessage.contentType === igMessageContentTypeConst.VIDEO.code && (
          <video src={igMessage.cdnUrl} alt="video attachment" width={130} height={230} controls />
        )}
        {igMessage.contentType === igMessageContentTypeConst.UNKNOWN.code && (
          <a href={igMessage.cdnUrl} target="_blank" rel="noreferrer">
            {t('modalOverviewIGMessages:list-item-link-unknown-text')}
          </a>
        )}
      </div>
    </List.Item>
  );
};

const IGMessagesQuickViewModal = ({ visible, onClose }) => {
  const { t } = useTranslation(['common', 'modalOverviewIGMessages', 'pageOverview']);
  const { isConstantLoading, igMessageTypesConst, igMessageContentTypeConst } = useFetchConstants();
  const { isLoading: isIGMessagesLoading, data: igMessages, refetch: refetchIGMessages } = useGetIGMessages();

  const { isLoading } = useIsLoadings([isConstantLoading, isIGMessagesLoading]);

  const handleOnStartLiveChat = async (id, liveChatMessage) => {
    message.info('Starting live chat');
    await postStartLiveChat({ id, message: liveChatMessage })
      .then(() => {
        message.success('You have started live chat. You can continue chat in IG Inbox.');
        refetchIGMessages();
      })
      .catch(ex => {
        message.error(t('common:update-fail-message-reason', { errMessage: ex.message }));
      });
  };

  return (
    <RootModal title={t('modalOverviewIGMessages:title')} visible={visible} onCancel={onClose} footer={null} destroyOnClose={true}>
      <List
        loading={isLoading}
        itemLayout="horizontal"
        dataSource={igMessages}
        renderItem={igMessage => {
          if (isLoading) {
            return null;
          }
          if (igMessage.type === igMessageTypesConst.STORY_MENTION.code) {
            return <IGMessageStoryMention t={t} igMessage={igMessage} igMessageContentTypeConst={igMessageContentTypeConst} />;
          } else if (igMessage.type === igMessageTypesConst.STORY_REPLY.code) {
            return <IGMessageStoryReply t={t} igMessage={igMessage} />;
          } else if (igMessage.type === igMessageTypesConst.LIVE_AGENT.code) {
            return <IGMessageLiveAgent t={t} igMessage={igMessage} onStartLiveChatClick={handleOnStartLiveChat} />;
          } else if (igMessage.type === igMessageTypesConst.MESSAGE.code) {
            return <IGMessageNormal t={t} igMessage={igMessage} igMessageContentTypeConst={igMessageContentTypeConst} />;
          }
        }}
      />
    </RootModal>
  );
};

export default IGMessagesQuickViewModal;
