import { useCallback, useEffect, useState, useRef, createRef, useMemo } from 'react';
import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InfoCircleFilled,
  LockOutlined,
  PlusOutlined,
  SyncOutlined,
  UnlockOutlined
} from '@ant-design/icons';
import { Button, Form, message, Modal, Row, Space, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import TextButton from 'components/TextButton/TextButton';
import FormUpload from 'components/Upload/FormUpload/FormUpload';
import AllKeywordSettings from 'components/AllKeywordSettings/AllKeywordSettings';
import PhotoManager from 'components/PhotoManager/PhotoManager';
import { UploadPhotosModal, UploadPhotosTriggerLinkButton, useUploadPhotosModal } from 'components/UploadProductPhotos/UploadProductPhotos';
import BulkCreateModal, { useBulkCreateModal } from './BulkCreateModal/BulkCreateModal';
import BulkUpdateModal, { useBulkUpdateModal } from './BulkUpdateModal/BulkUpdateModal';

import { deleteProductVariance, patchProductVariance, postCreateProductVariance, getProductDetails, useRefetchProductDetails } from 'apis/product';
import { useFetchConstant } from 'hooks/constants';
import { withAppContext } from 'contexts/AppContext/AppContext';

import { guard, flatOneLevelArray, wait } from 'utils/general';
import { constructColumn } from 'utils/table/table';
import { scrollToBottom } from 'utils/scroll';

import { InputContainer, StyledTable, UploadPhotoTableColumn } from './Variances.styles';

const { useForm } = Form;
const { confirm, error } = Modal;

const NEW_DATA_ID = 'new';
const TABLE_PAGE_SIZE_DEFAULT = 20;

const scrollToBottomDelay = () => {
  wait(0.1).then(() => {
    scrollToBottom(true);
  });
};

const WrappedPhotoManager = withAppContext(({ store, coverPhoto, coverPhotoRef, setEditedVarianceIds, record, ...props }) => {
  const [currentCoverPhoto, setCurrentCoverPhoto] = useState(coverPhoto ? [coverPhoto] : []);

  const handleChange = value => {
    setEditedVarianceIds(prevIds => {
      if (!prevIds.includes(record._id)) {
        return [...prevIds, record._id];
      }
      return prevIds;
    });
    setCurrentCoverPhoto(value);
  };

  return <PhotoManager value={currentCoverPhoto} onChange={handleChange} ref={coverPhotoRef} storeId={store._id} {...props} />;
});

const constructColumns = ({
  t,
  productId,
  editedVarianceIds,

  isLocked,
  isMobileView,
  isQuickEdit,
  isNormalProduct,
  isUploadPhotosTriggerBtnLoading,

  setIsLocked,
  productStatuses,
  productStatusesConst,
  coverPhotoRefs,
  handleOnSaveClick,
  handleOnConfirmDeleteClick,
  handleOnCopyButtonClick,
  handleOnOpenUploadPhotosModal,
  setEditedVarianceIds
}) => [
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-label'), 'label', { width: '200px' }),
    render: (label, record) => {
      return (
        <>
          {record.isNew && (
            <Tag color="blue" icon={<SyncOutlined spin />} style={{ position: 'absolute', top: '12px', width: '88%' }}>
              {t('pageProductDetails:variance-new-tag')}
            </Tag>
          )}
          <InputContainer>
            <FormInput
              name={`${record._id}_name`}
              requiredErrorMessage={t('pageProductDetails:variance-cell-form-error-message-label', { itemName: 'variance name' })}
              placeholder={t('pageProductDetails:variance-cell-placeholder-product')}
              suffix={
                <>
                  {record.isLimitApplied && (
                    <Tag color="geekblue" style={{ marginBottom: '4px' }}>
                      {t('pageProductDetails:variance-limit-tag')}
                    </Tag>
                  )}
                  <StatusIndicator isActive={record[`${record._id}_status`] === productStatusesConst.ACTIVE.code} />
                </>
              }
            />
          </InputContainer>
        </>
      );
    }
  },
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-keyword'), 'keyword', { width: '150px' }),
    render: (label, record) => (
      <InputContainer>
        <FormInput
          name={`${record._id}_keyword`}
          type="keyword"
          requiredErrorMessage={t('pageProductDetails:variance-cell-form-error-message-keyword', { itemName: 'keyword' })}
          placeholder={t('pageProductDetails:variance-cell-placeholder-keyword')}
        />
      </InputContainer>
    )
  },
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-inventory'), 'inventory', { width: '150px' }),
    render: (text, record) => (
      <InputContainer>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ width: '90%' }}>
            <FormInputNumber
              name={`${record._id}_inventory`}
              isAllowNegative={isNormalProduct}
              minValue={isNormalProduct ? Number.MIN_SAFE_INTEGER : 0}
              requiredErrorMessage={t('pageProductDetails:variance-cell-form-error-message-inventory')}
              placeholder="20"
              disabled={!record.isNew && isLocked}
            />
          </div>
          {!record.isNew && (
            <Button
              type="text"
              icon={isLocked ? <LockOutlined /> : <UnlockOutlined />}
              style={{ marginLeft: '4px', marginBottom: '24px' }}
              onClick={() => setIsLocked(!isLocked)}
            />
          )}
        </div>
      </InputContainer>
    )
  },
  ...(isNormalProduct
    ? [
        {
          ...constructColumn(t('pageProductDetails:variance-table-header-price'), 'price', { width: '150px', isPrice: true }),
          render: (text, record) => (
            <InputContainer>
              <FormInputNumber
                name={`${record._id}_price`}
                type="financial"
                requiredErrorMessage={t('pageProductDetails:variance-cell-form-error-message-price')}
                placeholder="100.00"
              />
            </InputContainer>
          )
        }
      ]
    : []),
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-status'), 'status', { width: '150px' }),
    render: (text, record) => (
      <InputContainer>
        <FormRadioButton name={`${record._id}_status`} selections={productStatuses} buttonStyle="solid" defaultValue="active" />
      </InputContainer>
    )
  },
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-photos'), 'coverPhotoFile', { width: '180px' }),
    render: (text, record) => (
      <InputContainer>
        {!record[`${record._id}_coverPhotoFile`] ? (
          <UploadPhotoTableColumn>
            {t('pageProductDetails:variance-table-header-cover-photo')}
            <WrappedPhotoManager
              coverPhoto={record.coverPhoto || record[`${record._id}_coverPhoto`]}
              buttonLabel={t('pageProductDetails:form-submit-cover-photo-upload-button-text')}
              fileSizeThresholdToCompressInKb={200}
              coverPhotoRef={coverPhotoRefs[record._id]}
              setEditedVarianceIds={setEditedVarianceIds}
              record={record}
            />
            <UploadPhotosTriggerLinkButton
              isLoading={isUploadPhotosTriggerBtnLoading}
              isDisabled={record.isNew}
              onClick={handleOnOpenUploadPhotosModal(productId, record._id)}
            />
          </UploadPhotoTableColumn>
        ) : (
          <FormUpload
            name={`${record._id}_coverPhotoFile`}
            buttonLabel={t('pageProductDetails:variance-form-submit-cover-photo-upload-button-text')}
            acceptTypes={['image/*']}
            fileSizeLimitInMb={10}
            isDisplayCard
          />
        )}
      </InputContainer>
    )
  },
  {
    ...constructColumn(
      <span>
        {t('pageProductDetails:variance-table-header-purchase-limit')}{' '}
        <Tooltip title={t('pageProductDetails:variance-table-header-purchase-limit-tips')}>
          <InfoCircleFilled />
        </Tooltip>
      </span>,
      'purchaseLimit',
      { width: '120px' }
    ),
    render: (text, record) => (
      <InputContainer>
        <FormInputNumber
          name={`${record._id}_purchaseLimit`}
          placeholder={t('pageProductDetails:variance-cell-placeholder-purchase-limit')}
          extraProps={{ autoSize: true }}
          extraRules={[{ type: 'number', min: 1, message: t('pageProductDetails:variance-cell-form-error-message-purchase-limit') }]}
        />
      </InputContainer>
    )
  },
  ...(isNormalProduct
    ? [
        {
          ...constructColumn(
            <span>
              {t('pageProductDetails:variance-table-header-min-purchase-limit')}{' '}
              <Tooltip title={t('pageProductDetails:variance-table-header-min-purchase-limit-tips')}>
                <InfoCircleFilled />
              </Tooltip>
            </span>,
            'minPurchaseLimit',
            { width: '120px' }
          ),
          render: (text, record) => (
            <InputContainer>
              <FormInputNumber
                name={`${record._id}_minPurchaseLimit`}
                placeholder={t('pageProductDetails:variance-cell-placeholder-min-purchase-limit')}
                extraProps={{ autoSize: true }}
                extraRules={[{ type: 'number', min: 2, message: t('pageProductDetails:variance-cell-form-error-message-min-purchase-limit') }]}
              />
            </InputContainer>
          )
        }
      ]
    : []),
  ...(!isQuickEdit
    ? [
        {
          ...constructColumn(t('pageProductDetails:variance-table-header-sku'), 'sku', { width: '150px' }),
          render: (text, record) => (
            <InputContainer>
              <FormInput
                type="textArea"
                name={`${record._id}_sku`}
                placeholder={t('pageProductDetails:variance-cell-placeholder-sku')}
                extraProps={{ autoSize: true }}
              />
            </InputContainer>
          )
        },
        {
          ...constructColumn(t('pageProductDetails:variance-table-header-desc'), 'description', { width: '150px' }),
          render: (text, record) => (
            <InputContainer>
              <FormInput
                type="textArea"
                name={`${record._id}_description`}
                placeholder={t('pageProductDetails:variance-cell-placeholder-description')}
                extraProps={{ autoSize: true }}
              />
            </InputContainer>
          )
        }
      ]
    : []),
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-weight'), ['weight', 'amount'], { width: '150px' }),
    render: (text, record) => (
      <InputContainer>
        <FormInputNumber name={`${record._id}_weight.amount`} placeholder="20" precision={2} />
      </InputContainer>
    )
  },

  ...(!isQuickEdit
    ? [
        {
          ...constructColumn(t('pageProductDetails:variance-table-header-ori-inventory'), 'oriInventory', { width: '120px' }),
          render: (text, record) => (
            <InputContainer>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ width: '90%' }}>
                  <FormInputNumber
                    name={`${record._id}_oriInventory`}
                    isAllowNegative={isNormalProduct}
                    minValue={isNormalProduct ? Number.MIN_SAFE_INTEGER : 0}
                    placeholder="20"
                  />
                </div>
              </div>
            </InputContainer>
          )
        }
      ]
    : []),
  ...(isNormalProduct && !isQuickEdit
    ? [
        {
          ...constructColumn(t('pageProductDetails:variance-table-header-ori-price'), 'oriPrice', { width: '120px', isPrice: true }),
          render: (text, record) => (
            <InputContainer>
              <FormInputNumber name={`${record._id}_oriPrice`} type="financial" placeholder="100.00" />
            </InputContainer>
          )
        }
      ]
    : []),
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-cost'), 'cost', { width: '120px', isPrice: true }),
    render: (text, record) => (
      <InputContainer>
        <FormInputNumber name={`${record._id}_cost`} type="financial" placeholder="100.00" />
      </InputContainer>
    )
  },
  ...(!isQuickEdit
    ? [
        {
          ...constructColumn(t('pageProductDetails:variance-table-header-internal-note'), 'internalNote', { width: '150px' }),
          render: (text, record) => (
            <InputContainer>
              <FormInput
                type="textArea"
                name={`${record._id}_internalNote`}
                placeholder={t('pageProductDetails:variance-cell-placeholder-internal-note')}
                extraProps={{ autoSize: true }}
              />
            </InputContainer>
          )
        }
      ]
    : []),
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-actions'), 'actions', { width: isMobileView ? '108px' : '144px' }),
    fixed: 'right',
    render: (text, record) => {
      return (
        <Row>
          <TextButton
            size="medium"
            icon={<CheckOutlined />}
            disabled={!editedVarianceIds.includes(record._id)}
            onClick={() => handleOnSaveClick(record)}
            text={
              editedVarianceIds.length > 1
                ? t('pageProductDetails:variance-text-button-update-all')
                : t('pageProductDetails:variance-text-button-update')
            }
          />
          <TextButton
            size="medium"
            icon={<DeleteOutlined />}
            onClick={() => handleOnConfirmDeleteClick(record)}
            text={t('pageProductDetails:variance-text-button-remove')}
          />
          <TextButton
            size="medium"
            icon={<CopyOutlined />}
            onClick={() => handleOnCopyButtonClick(record)}
            text={t('pageProductDetails:variance-text-button-duplicate')}
          />
        </Row>
      );
    }
  }
];

const useTablePagination = tableDataTotal => {
  const [shouldScrollToLastPage, setShouldScrollToLastPage] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);

  const updateTableToCurrentPage = currentPage => {
    setCurrentPageNumber(currentPage);
    scrollToBottomDelay();
  };

  const updateTableToLastPage = useCallback(
    tableTotalPage => {
      if (shouldScrollToLastPage || currentPageNumber > tableTotalPage) {
        setCurrentPageNumber(tableTotalPage);

        if (shouldScrollToLastPage) {
          scrollToBottomDelay();
          setShouldScrollToLastPage(false);
        }
      }
    },
    [shouldScrollToLastPage, currentPageNumber]
  );

  const handleOnUpdateTablePageSize = (_, updatedPageSize) => {
    setPageSize(updatedPageSize);
  };

  useEffect(() => {
    const tableTotalPage = Math.ceil(tableDataTotal / pageSize);

    updateTableToLastPage(tableTotalPage);
  }, [tableDataTotal, pageSize, updateTableToLastPage]);

  return { currentPageNumber, pageSize, setShouldScrollToLastPage, onUpdateTablePageSize: handleOnUpdateTablePageSize, updateTableToCurrentPage };
};

const Variances = ({
  store,
  productId,
  product = {},
  variances = [],
  isMobileView = false,
  onAddSuccess = () => {},
  showNewRow = false,
  isQuickEdit = false,
  isNormalProduct = true
}) => {
  const { t } = useTranslation(['common', 'pageProductDetails']);
  const [form] = useForm();
  const photosRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState(variances);
  const [coverPhotoRefs, setCoverPhotoRefs] = useState({});
  const [isLocked, setIsLocked] = useState(true);
  const [editedVarianceIds, setEditedVarianceIds] = useState([]);

  useEffect(() => {
    const mappedRefs = Object.values(data).reduce((acc, cur) => {
      acc[cur._id] = coverPhotoRefs[cur._id] ? coverPhotoRefs[cur._id] : createRef();
      return acc;
    }, {});

    if (Object.keys(coverPhotoRefs).toString() !== Object.keys(mappedRefs).toString()) {
      setCoverPhotoRefs(mappedRefs);
    }
  }, [coverPhotoRefs, data]);

  const hasSavedVariances = useMemo(() => data.some(tempData => tempData._id !== NEW_DATA_ID), [data]);
  const existingData = useMemo(() => data.filter(tempData => tempData._id !== NEW_DATA_ID), [data]);

  const { selection: productStatuses, data: productStatusesConst } = useFetchConstant('productStatuses');
  const refetchProductDetails = useRefetchProductDetails();

  const updateProductPhotos = async () => {
    return await photosRef.current?.update();
  };

  const { isBulkCreateModalVisible, setIsBulkCreateModalVisible, handleOnBulkCreateBtnClick } = useBulkCreateModal();
  const { isBulkUpdateModalVisible, setIsBulkUpdateModalVisible, handleOnBulkUpdateBtnClick } = useBulkUpdateModal();

  const {
    isUploadPhotosModalLoading,
    isUploadPhotosTriggerBtnLoading,
    isUploadPhotosModalVisible,

    photoFiles,
    photos,

    handleOnOpenUploadPhotosModal,
    handleOnCloseUploadPhotosModal,
    handleOnConfirmUploadPhotos,
    handleOnFileUploadPhotosModalChange
  } = useUploadPhotosModal({ updateProductPhotos });

  const { currentPageNumber, pageSize, setShouldScrollToLastPage, onUpdateTablePageSize, updateTableToCurrentPage } = useTablePagination(data.length);

  const createNewVarianceRow = useCallback(() => {
    const sampleData = {
      _id: NEW_DATA_ID,
      [`${NEW_DATA_ID}_purchaseLimit`]: product.purchaseLimit || undefined,
      [`${NEW_DATA_ID}_inventory`]: product.inventory || 0,
      [`${NEW_DATA_ID}_weight.amount`]: (product.weight && product.weight.amount) || 0,
      [`${NEW_DATA_ID}_price`]: product.price || (variances && variances.length > 0 && variances[0].price) || (isNormalProduct ? 1 : 0),
      [`${NEW_DATA_ID}_oriPrice`]: product.oriPrice || (variances && variances.length > 0 && variances[0].oriPrice) || undefined,
      [`${NEW_DATA_ID}_status`]: productStatusesConst.ACTIVE.code,
      isNew: true
    };

    setData(prevData => {
      const prevNewData = prevData.filter(d => d.isNew);
      const hasNewPrevData = prevNewData.length > 0;
      if (hasNewPrevData) {
        return prevData;
      } else {
        form.setFieldsValue(sampleData);
        return [...prevData, sampleData];
      }
    });
  }, [form, product, productStatusesConst, variances, isNormalProduct]);

  useEffect(() => {
    if (showNewRow) {
      createNewVarianceRow();
    } else {
      if (variances.length > 0) {
        const formattedVariances = variances.map(variance => {
          return {
            _id: variance._id,
            [`${variance._id}_name`]: variance.label,
            [`${variance._id}_keyword`]: variance.keyword,
            [`${variance._id}_purchaseLimit`]: variance.purchaseLimit,
            [`${variance._id}_minPurchaseLimit`]: variance.minPurchaseLimit,
            [`${variance._id}_sku`]: variance.sku,
            [`${variance._id}_description`]: variance.description,
            [`${variance._id}_weight.amount`]: variance.weight && variance.weight.amount,
            [`${variance._id}_inventory`]: variance.inventory,
            [`${variance._id}_oriInventory`]: variance.oriInventory,
            [`${variance._id}_price`]: variance.price,
            [`${variance._id}_cost`]: variance.cost,
            [`${variance._id}_oriPrice`]: variance.oriPrice,
            [`${variance._id}_status`]: variance.status,
            [`${variance._id}_coverPhotoFile`]: variance.coverPhotoFile,
            [`${variance._id}_coverPhoto`]: variance.coverPhoto,
            [`${variance._id}_internalNote`]: variance.internalNote
          };
        });
        formattedVariances.forEach(formattedVariance => form.setFieldsValue(formattedVariance));
        setData(formattedVariances);
      }
    }
  }, [variances, showNewRow, createNewVarianceRow, form]);

  const handleOnSaveSuccess = () => {
    getProductDetails(productId).then(updatedProduct => {
      const formattedVariances = updatedProduct.variances.map(variance => {
        return {
          _id: variance._id,
          [`${variance._id}_name`]: variance.label,
          [`${variance._id}_keyword`]: variance.keyword,
          [`${variance._id}_purchaseLimit`]: variance.purchaseLimit,
          [`${variance._id}_minPurchaseLimit`]: variance.minPurchaseLimit,
          [`${variance._id}_sku`]: variance.sku,
          [`${variance._id}_description`]: variance.description,
          [`${variance._id}_weight.amount`]: variance.weight && variance.weight.amount,
          [`${variance._id}_inventory`]: variance.inventory,
          [`${variance._id}_oriInventory`]: variance.oriInventory,
          [`${variance._id}_price`]: variance.price,
          [`${variance._id}_cost`]: variance.cost,
          [`${variance._id}_oriPrice`]: variance.oriPrice,
          [`${variance._id}_status`]: variance.status,
          [`${variance._id}_coverPhotoFile`]: variance.coverPhotoFile,
          [`${variance._id}_coverPhoto`]: variance.coverPhoto,
          [`${variance._id}_internalNote`]: variance.internalNote
        };
      });
      setData(formattedVariances);
      formattedVariances.forEach(formattedVariance => form.setFieldsValue(formattedVariance));
      setEditedVarianceIds([]);
      setIsSubmitting(false);
    });
  };

  const handleOnAddButtonClick = () => {
    createNewVarianceRow();
    setShouldScrollToLastPage(true);
  };

  const handleOnCopyButtonClick = currentSelectedVariance => {
    const oldDataId = currentSelectedVariance._id;
    const newData = {
      _id: NEW_DATA_ID,
      [`${NEW_DATA_ID}_name`]: currentSelectedVariance[`${oldDataId}_name`],
      [`${NEW_DATA_ID}_keyword`]: currentSelectedVariance[`${oldDataId}_keyword`],
      [`${NEW_DATA_ID}_purchaseLimit`]: currentSelectedVariance[`${oldDataId}_purchaseLimit`],
      [`${NEW_DATA_ID}_minPurchaseLimit`]: currentSelectedVariance[`${oldDataId}_minPurchaseLimit`],
      [`${NEW_DATA_ID}_sku`]: currentSelectedVariance[`${oldDataId}_sku`],
      [`${NEW_DATA_ID}_description`]: currentSelectedVariance[`${oldDataId}_description`],
      [`${NEW_DATA_ID}_weight.amount`]: currentSelectedVariance[`${oldDataId}_weight.amount`],
      [`${NEW_DATA_ID}_inventory`]: currentSelectedVariance[`${oldDataId}_inventory`],
      [`${NEW_DATA_ID}_oriInventory`]: currentSelectedVariance[`${oldDataId}_oriInventory`],
      [`${NEW_DATA_ID}_price`]: currentSelectedVariance[`${oldDataId}_price`],
      [`${NEW_DATA_ID}_cost`]: currentSelectedVariance[`${oldDataId}_cost`],
      [`${NEW_DATA_ID}_oriPrice`]: currentSelectedVariance[`${oldDataId}_oriPrice`],
      [`${NEW_DATA_ID}_status`]: currentSelectedVariance[`${oldDataId}_status`],
      [`${NEW_DATA_ID}_coverPhoto`]: currentSelectedVariance[`${oldDataId}_coverPhoto`],
      [`${NEW_DATA_ID}_internalNote`]: currentSelectedVariance[`${oldDataId}_internalNote`],
      isNew: true
    };

    const hasCopiedData = !!data.find(d => d.isNew);
    if (!hasCopiedData) {
      form.setFieldsValue(newData);
      setData([...data, newData]);
    }

    setShouldScrollToLastPage(true);
  };

  const handleOnSaveClick = async () => {
    try {
      setIsSubmitting(true);
      const fieldsToValidate = flatOneLevelArray(
        editedVarianceIds.map(editedVarianceId => {
          return [
            `${editedVarianceId}_name`,
            `${editedVarianceId}_keyword`,
            `${editedVarianceId}_purchaseLimit`,
            `${editedVarianceId}_minPurchaseLimit`,
            `${editedVarianceId}_sku`,
            `${editedVarianceId}_description`,
            `${editedVarianceId}_weight.amount`,
            `${editedVarianceId}_inventory`,
            `${editedVarianceId}_oriInventory`,
            `${editedVarianceId}_price`,
            `${editedVarianceId}_cost`,
            `${editedVarianceId}_oriPrice`,
            `${editedVarianceId}_status`,
            `${editedVarianceId}_coverPhotoFile`,
            `${editedVarianceId}_internalNote`
          ];
        })
      );
      const validatedVarianceFields = await form.validateFields(fieldsToValidate);
      const failedVarianceIds = [];
      await Promise.all(
        editedVarianceIds.map(async editedVarianceId => {
          const isNew = editedVarianceId === NEW_DATA_ID;
          const [coverPhoto] = coverPhotoRefs[editedVarianceId]?.current ? await coverPhotoRefs[editedVarianceId].current.update() : [];

          const payload = {
            label: validatedVarianceFields[`${editedVarianceId}_name`],
            keyword: String(validatedVarianceFields[`${editedVarianceId}_keyword`]).toUpperCase(),
            purchaseLimit: validatedVarianceFields[`${editedVarianceId}_purchaseLimit`],
            minPurchaseLimit: validatedVarianceFields[`${editedVarianceId}_minPurchaseLimit`],
            sku: validatedVarianceFields[`${editedVarianceId}_sku`],
            description: validatedVarianceFields[`${editedVarianceId}_description`],
            weight: { amount: validatedVarianceFields[`${editedVarianceId}_weight.amount`] },
            ...((!isLocked || isNew) && { inventory: validatedVarianceFields[`${editedVarianceId}_inventory`] }),
            oriInventory: validatedVarianceFields[`${editedVarianceId}_oriInventory`],
            price: isNormalProduct ? validatedVarianceFields[`${editedVarianceId}_price`] : 0,
            cost: validatedVarianceFields[`${editedVarianceId}_cost`],
            oriPrice: validatedVarianceFields[`${editedVarianceId}_oriPrice`],
            status: validatedVarianceFields[`${editedVarianceId}_status`],
            coverPhotoFile: validatedVarianceFields[`${editedVarianceId}_coverPhotoFile`],
            coverPhoto: coverPhoto ? coverPhoto : null,
            internalNote: validatedVarianceFields[`${editedVarianceId}_internalNote`]
          };

          if (isNew) {
            return postCreateProductVariance(productId, payload)
              .then(() => {
                message.success({
                  content: t('pageProductDetails:variance-create-success-message', { keyword: payload.keyword })
                });
                onAddSuccess(true);
              })
              .catch(ex => {
                failedVarianceIds.push(NEW_DATA_ID);
                const errMsg = ex.message;
                if (errMsg.includes('keyword') && errMsg.includes('exist')) {
                  form.setFields([
                    {
                      name: [`${payload._id}_keyword`],
                      errors: [`${errMsg}`]
                    }
                  ]);
                }
                message.error({
                  content: t('pageProductDetails:variance-create-fail-message', { message: ex.message, keyword: payload.keyword })
                });
              });
          } else {
            return patchProductVariance(productId, editedVarianceId, payload)
              .then(() => {
                message.success({
                  content: t('pageProductDetails:variance-update-success-message', { keyword: payload.keyword })
                });
              })
              .catch(ex => {
                failedVarianceIds.push(editedVarianceId);
                message.error({
                  content: t('pageProductDetails:variance-update-fail-message', { message: ex.message, keyword: payload.keyword })
                });
              });
          }
        })
      ).then(() => {
        if (failedVarianceIds.length === editedVarianceIds.length) {
          setIsSubmitting(false);
        } else {
          handleOnSaveSuccess();
        }
      });
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log(error);
      setIsSubmitting(false);
      message.error(t('common:update-fail-message'));
    }
  };

  const handleOnConfirmDeleteClick = record => {
    const isNew = record.isNew;
    const varianceId = record._id;
    if (isNew) {
      setData(prevData => {
        return prevData.filter(variance => {
          return variance._id !== varianceId;
        });
      });
      setEditedVarianceIds(prevEditedVarianceIds => {
        const newVarianceIds = prevEditedVarianceIds.filter(prevEditedVarianceId => prevEditedVarianceId !== varianceId);
        if (prevEditedVarianceIds.length !== newVarianceIds.length) {
          return newVarianceIds;
        }
        return prevEditedVarianceIds;
      });
    } else {
      setIsSubmitting(true);
      confirm({
        title: t('common:confirm-modal-delete-title', { itemName: t('pageProductDetails:item-name-variance-lowercase') }),
        content: t('common:confirm-modal-delete-content'),
        okText: t('common:modal-ok-text'),
        cancelText: t('common:modal-cancel-text'),
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteProductVariance(productId, varianceId)
            .then(() => {
              setIsSubmitting(false);
              message.success(
                t('common:delete-success-message', {
                  itemName: t('pageProductDetails:variance-delete-success-message', { keyword: record[`${varianceId}_keyword`] })
                })
              );
              refetchProductDetails();
              setData(prevData => {
                return prevData.filter(variance => {
                  return variance._id !== varianceId;
                });
              });
            })
            .catch(ex => {
              setIsSubmitting(false);
              error({
                title: ex.message
              });
            });
        },
        onCancel() {
          setIsSubmitting(false);
        }
      });
    }
  };

  return (
    <>
      <Form
        form={form}
        initialValues={data}
        component={false}
        onFieldsChange={changedFields => {
          const changedVarianceIds = changedFields.map(changedField => {
            return guard(() => changedField.name[0].split('_')[0]);
          });

          setEditedVarianceIds(prevEditedVarianceIds => {
            const newChangedVarianceIds = changedVarianceIds.filter(changedVarianceId => {
              return changedVarianceId && !prevEditedVarianceIds.includes(changedVarianceId);
            });
            if (newChangedVarianceIds.length > 0) {
              return [...prevEditedVarianceIds, ...newChangedVarianceIds];
            }
            return prevEditedVarianceIds;
          });
        }}
      >
        <Row gutter={[0, 16]} justify="space-between">
          {hasSavedVariances && <AllKeywordSettings productId={productId} />}
          <Space style={{ marginLeft: 'auto' }}>
            <Button type="primary" onClick={handleOnBulkUpdateBtnClick} icon={<EditOutlined />}>
              {t('pageProductDetails:variance-bulk-update-button')}
            </Button>
            {!isQuickEdit && (
              <Button type="primary" onClick={handleOnBulkCreateBtnClick} icon={<PlusOutlined />}>
                {t('pageProductDetails:variance-bulk-create-button')}
              </Button>
            )}
            <Button type="primary" onClick={handleOnAddButtonClick} icon={<PlusOutlined />}>
              {t('pageProductDetails:variance-add-button')}
            </Button>
          </Space>
        </Row>
        <StyledTable
          loading={isSubmitting}
          rowKey={record => record._id}
          bordered
          dataSource={data}
          columns={constructColumns({
            t,
            productId,
            editedVarianceIds,

            isLocked,
            isMobileView,
            isQuickEdit,
            isNormalProduct,
            isUploadPhotosTriggerBtnLoading,

            productStatuses,
            productStatusesConst,
            coverPhotoRefs,

            setIsLocked,
            handleOnSaveClick,
            handleOnConfirmDeleteClick,
            handleOnCopyButtonClick,
            handleOnOpenUploadPhotosModal,
            setEditedVarianceIds
          })}
          scroll={{ x: 1024 }}
          locale={{
            triggerDesc: t('common:table-header-sort-trigger-desc'),
            triggerAsc: t('common:table-header-sort-trigger-asc'),
            cancelSort: t('common:table-header-sort-cancel-sort')
          }}
          pagination={{
            showSizeChanger: true,
            current: currentPageNumber,
            pageSize,
            onChange: updateTableToCurrentPage,
            onShowSizeChange: onUpdateTablePageSize
          }}
        />
      </Form>
      {!isQuickEdit && (
        <UploadPhotosModal
          visible={isUploadPhotosModalVisible}
          isLoading={isUploadPhotosModalLoading}
          initialPhotoFiles={photoFiles}
          initialPhotos={photos}
          onOk={handleOnConfirmUploadPhotos}
          onCancel={handleOnCloseUploadPhotosModal}
          onFileUploadChange={handleOnFileUploadPhotosModalChange}
          photosRef={photosRef}
        />
      )}
      {isBulkCreateModalVisible && (
        <BulkCreateModal
          productId={productId}
          isBulkCreateModalVisible={isBulkCreateModalVisible}
          setIsBulkCreateModalVisible={setIsBulkCreateModalVisible}
          handleOnSaveSuccess={handleOnSaveSuccess}
        />
      )}
      {isBulkUpdateModalVisible && (
        <BulkUpdateModal
          productId={productId}
          variances={existingData}
          isBulkUpdateModalVisible={isBulkUpdateModalVisible}
          setIsBulkUpdateModalVisible={setIsBulkUpdateModalVisible}
          handleOnSaveSuccess={handleOnSaveSuccess}
        />
      )}
    </>
  );
};

export default Variances;
