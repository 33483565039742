import { useState } from 'react';
import { PushpinOutlined } from '@ant-design/icons';
import { Alert, Button, Modal, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { PiSticker } from 'react-icons/pi';

import { useGetPostSoldProductStickers } from 'apis/post';

import TextButton from 'components/TextButton/TextButton';
import ProductSticker from 'components/ProductSticker/ProductSticker';

import { constructColumn } from 'utils/table/table';
import { getPrintProductStickerRoute } from 'utils/routes';

export const usePrintProductStickerModal = () => {
  const [isPrintProductStickerModalVisible, setIsPrintProductStickerModalVisible] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState();
  const [selectedProductId, setSelectedProductId] = useState();

  const handleOnStickerBtnClick = (postId, productVarianceId) => {
    setIsPrintProductStickerModalVisible(true);
    setSelectedPostId(postId);
    setSelectedProductId(productVarianceId);
  };

  return {
    isPrintProductStickerModalVisible,
    setIsPrintProductStickerModalVisible,

    selectedPostId,
    selectedProductId,

    handleOnStickerBtnClick
  };
};

const constructColumns = ({ t }) => [
  {
    ...constructColumn(null, 'customerName', { width: '50%' })
  },
  {
    ...constructColumn({ width: '30%' }),
    render: (text, record) => {
      return (
        <div>
          {record.productQuantity} x {record.productKeyword}
        </div>
      );
    }
  },
  {
    ...constructColumn(null, null, { width: '20%' }),
    render: (text, record) => {
      return (
        <Row>
          <TextButton
            icon={<PiSticker style={{ marginBottom: '-2px', marginRight: '8px' }} />}
            text={t('pageOverview:product-sold-print-stickers-modal-print-button')}
            onClick={() => window.open(getPrintProductStickerRoute({ soldProduct: encodeURIComponent(JSON.stringify(record)), isSingle: true }).path)}
          />
        </Row>
      );
    }
  }
];

const PrintProductStickerModal = ({ isPrintProductStickerModalVisible, setIsPrintProductStickerModalVisible, postId, productId, storeCurrency }) => {
  const { t } = useTranslation(['common', 'pageOverview']);
  const [isOpenSampleModal, setIsOpenSampleModal] = useState(false);

  const { isLoading, data: postSoldProductStickers } = useGetPostSoldProductStickers(postId, productId);

  const handleOnPrintProductStickerModalCancel = () => {
    setIsPrintProductStickerModalVisible(false);
  };

  return (
    <Modal
      width={680}
      title={t('pageOverview:product-sold-print-stickers-modal-title')}
      visible={isPrintProductStickerModalVisible}
      footer={
        <>
          <Button onClick={handleOnPrintProductStickerModalCancel}>{t('common:modal-cancel-text')}</Button>
          <Button
            type="primary"
            icon={<PiSticker style={{ marginBottom: '-2px', marginRight: '8px' }} />}
            onClick={() => window.open(getPrintProductStickerRoute({ postId, productId }).path)}
            loading={isLoading}
          >
            {t('pageOverview:product-sold-print-stickers-modal-print-all-button')}
          </Button>
        </>
      }
      onCancel={handleOnPrintProductStickerModalCancel}
    >
      <Alert
        type="warning"
        style={{ marginBottom: 20 }}
        icon={<PushpinOutlined />}
        showIcon
        message={
          <Row justify="space-between" align="middle" wrap={false}>
            <span>{t('pageOverview:product-sold-print-stickers-modal-desc')}</span>
            <TextButton
              text={t('pageOverview:product-sold-print-stickers-modal-view-sample-button')}
              onClick={() => {
                setIsOpenSampleModal(true);
              }}
            />
          </Row>
        }
      />
      <Table
        size="small"
        loading={isLoading}
        rowKey={record => record._id}
        dataSource={postSoldProductStickers}
        showHeader={false}
        columns={constructColumns({ t })}
        scroll={{ x: 'max-content' }}
        locale={{
          triggerDesc: t('common:table-header-sort-trigger-desc'),
          triggerAsc: t('common:table-header-sort-trigger-asc'),
          cancelSort: t('common:table-header-sort-cancel-sort')
        }}
        pagination={false}
      />

      {isOpenSampleModal && (
        <Modal
          width={576}
          title={t('pageOverview:product-sold-print-stickers-sample-modal-title')}
          visible={true}
          footer={false}
          onCancel={() => {
            setIsOpenSampleModal(false);
          }}
        >
          <p style={{ marginBottom: '24px' }}>{t('pageOverview:product-sold-print-stickers-sample-modal-desc')}</p>
          <Row justify="center">
            <ProductSticker
              soldProduct={{
                customerName: 'John',
                orderNumber: '#ULIV-OR-0000',
                productQuantity: 2,
                productLabel: t('pageOverview:product-sold-print-stickers-sample-modal-sample-product-name'),
                productAmount: 8
              }}
              storeCurrency={storeCurrency}
            />
          </Row>
        </Modal>
      )}
    </Modal>
  );
};

export default PrintProductStickerModal;
