import { Statistic } from 'antd';
import { FaTiktok } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { checkIsObjectEmpty } from 'utils/general';

import { ConnectionDetailsContainer, SocialMediaTitleContainer, SocialMediaTitleIcon, SocialMediaTitle } from './TiktokConnectionContent.styles';
import { UserOutlined } from '@ant-design/icons';

const constructCustomValueStyle = hasConnected => ({
  fontSize: '14px',
  fontStyle: hasConnected ? 'normal' : 'italic',
  wordBreak: 'break-word'
});

const TiktokConnectionContent = ({ tiktokUser }) => {
  const { t } = useTranslation(['compTiktokAccountConnection']);
  const hasConnected = !checkIsObjectEmpty(tiktokUser);

  return (
    <div>
      <SocialMediaTitleContainer>
        <SocialMediaTitleIcon>
          <FaTiktok />
        </SocialMediaTitleIcon>
        <SocialMediaTitle>TikTok</SocialMediaTitle>
      </SocialMediaTitleContainer>
      <ConnectionDetailsContainer>
        <Statistic
          title={
            <span>
              <UserOutlined /> {t('compTiktokAccountConnection:account-label')}
            </span>
          }
          value={hasConnected ? `${tiktokUser.name} | @${tiktokUser.username}` : t('compTiktokAccountConnection:not-connected-label')}
          valueStyle={constructCustomValueStyle(hasConnected)}
        />
      </ConnectionDetailsContainer>
    </div>
  );
};

export default TiktokConnectionContent;
