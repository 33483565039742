import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Modal, Radio, Row, Skeleton, Space } from 'antd';
import moment from 'moment';

import { getOrderPaymentsCSVDate } from 'apis/order';

import { useFetchConstant } from 'hooks/constants';

import FormCheckbox from 'components/Checkbox/FormCheckbox/FormCheckbox';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import CsvDownload from 'components/CsvDownload/CsvDownload';
import FormPasswordInput from 'components/FormPasswordInput/FormPasswordInput';
import OrderTagSelection from 'components/OrderTagSelection/OrderTagSelection';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { guard } from 'utils/general';
import {
  PASSCODE_REGEX,
  PAYMENT_FIELDS_TYPES,
  PAYMENT_ONLY_CSV_HEADERS,
  PAYMENT_ORDERS_CSV_HEADERS,
  PAYMENT_PRODUCT_SOLD_CSV_HEADERS
} from 'utils/constants';
import { formatToDateString, getCurrentMonth, getTodayMoment, formatToMomentObject, getDayDiff } from 'utils/date';

import { FieldLabel, NoteLabel } from './ExportPaymentsCSVModal.styles';

const { useForm } = Form;

const RANGE_DAY_LIMIT = 124;

const useFetchConstants = () => {
  const { selection: paymentTypes, isLoading: isPaymentTypesLoading } = useFetchConstant('paymentTypes');
  const { selection: paymentStatuses, isLoading: isPaymentStatusesLoading } = useFetchConstant('paymentStatuses');

  return { isLoading: isPaymentTypesLoading || isPaymentStatusesLoading, paymentTypes, paymentStatuses };
};

const sendEventDataDownloadCsv = (params = {}) => {
  let actionData = '';
  const objectData = Object.entries(params);
  objectData.forEach((value, index) => {
    if (value[1] !== null) {
      actionData = `${actionData}${index === 0 ? '' : '@'}${value[0]}:${value[1]}`;
    }
  });

  window.dataLayer.push({ event: 'download_csv', ...params, action: actionData });
};

const getOrderPaymentSumAmount = amount => {
  if (typeof amount === 'string') {
    return amount
      .split(', ')
      .map(Number)
      .reduce((sumAmount, amount) => sumAmount + amount, 0);
  }

  return amount || 0;
};

const ExportPaymentsCSVModal = ({ onCancel, store }) => {
  const { t } = useTranslation(['common', 'pageOrder']);
  const [form] = useForm();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [paymentFieldType, setPaymentFieldType] = useState(PAYMENT_FIELDS_TYPES.PAYMENT_ONLY.value);
  const [dateQuery, setDateQuery] = useState({ startDate: formatToDateString(getCurrentMonth()), endDate: formatToDateString(getTodayMoment()) });

  const { isLoading: isLoadingConstants, paymentTypes, paymentStatuses } = useFetchConstants();

  const isRequirePasscode =
    guard(() => store.securityConfig.hasSecurityPasscode, false) && paymentFieldType !== PAYMENT_FIELDS_TYPES.PAYMENT_ONLY.value;

  const dateRangeValues = useMemo(() => [formatToMomentObject(dateQuery.startDate), formatToMomentObject(dateQuery.endDate)], [dateQuery]);

  const csvHeaders = useMemo(() => {
    return paymentFieldType === PAYMENT_FIELDS_TYPES.PAYMENT_ONLY.value
      ? PAYMENT_ONLY_CSV_HEADERS
      : paymentFieldType === PAYMENT_FIELDS_TYPES.PAYMENT_ORDERS.value
      ? PAYMENT_ORDERS_CSV_HEADERS
      : PAYMENT_PRODUCT_SOLD_CSV_HEADERS;
  }, [paymentFieldType]);

  const getFormattedPaymentsCSVData = async () => {
    try {
      const values = await form.validateFields();
      const finalQuery = {
        ...dateQuery,
        statuses: values.statuses,
        types: values.types,
        fieldType: paymentFieldType,
        tags: values.tags ? values.tags.join(',') : '',
        securityPasscode: values.securityPasscode
      };

      const orderPayments = await getOrderPaymentsCSVDate(finalQuery).then(orderPayments => {
        if (paymentFieldType === PAYMENT_FIELDS_TYPES.PAYMENT_PRODUCT_SOLD.value) {
          const totalObj = orderPayments.reduce(
            (amountObj, orderPayment) => {
              const amount = getOrderPaymentSumAmount(orderPayment.amount);

              return {
                quantity: amountObj.quantity + orderPayment.quantity,
                amount: amountObj.amount + amount
              };
            },
            { quantity: 0, amount: 0 }
          );

          return [
            {
              name: 'Total',
              quantity: totalObj.quantity,
              amount: parseFloat(totalObj.amount).toFixed(2)
            },
            ...orderPayments
          ];
        } else {
          const totalObj = orderPayments.reduce(
            (amountObj, orderPayment) => {
              const settlementAmount = getOrderPaymentSumAmount(orderPayment.settlementAmount);
              const amount = getOrderPaymentSumAmount(orderPayment.amount);
              const subtotalPrice = getOrderPaymentSumAmount(orderPayment.subtotalPrice);
              const shippingFee = getOrderPaymentSumAmount(orderPayment.shippingFee);
              const extraFee = getOrderPaymentSumAmount(orderPayment.extraFee);
              const discount = getOrderPaymentSumAmount(orderPayment.discount);

              return {
                settlementAmount: amountObj.settlementAmount + settlementAmount,
                amount: amountObj.amount + amount,
                subtotalPrice: amountObj.subtotalPrice + subtotalPrice,
                shippingFee: amountObj.shippingFee + shippingFee,
                extraFee: amountObj.extraFee + extraFee,
                discount: amountObj.discount + discount
              };
            },
            { settlementAmount: 0, amount: 0, subtotalPrice: 0, shippingFee: 0, extraFee: 0, discount: 0 }
          );

          return [
            {
              orderNumber: 'Total',
              settlementAmount: parseFloat(totalObj.settlementAmount).toFixed(2),
              amount: parseFloat(totalObj.amount).toFixed(2),
              subtotalPrice: parseFloat(totalObj.subtotalPrice).toFixed(2),
              shippingFee: parseFloat(totalObj.shippingFee).toFixed(2),
              extraFee: parseFloat(totalObj.extraFee).toFixed(2),
              discount: parseFloat(totalObj.discount).toFixed(2)
            },
            ...orderPayments.map(orderPayment => {
              return {
                ...orderPayment,
                customer: `${orderPayment.customerName}${orderPayment.customerContact ? ` (${orderPayment.customerContact})` : ''}${
                  orderPayment.customerAddress ? ` ${orderPayment.customerAddress}` : ''
                }`
              };
            })
          ];
        }
      });

      const differentInDays = getDayDiff(dateQuery.startDate, dateQuery.endDate, true);
      sendEventDataDownloadCsv({
        page: 'order',
        category: 'payment',
        payment_type: values.types.join(','),
        payment_status: values.statuses.join(','),
        payment_field_type: `${paymentFieldType}`,
        days: `${differentInDays}`,
        report_order_field: null,
        report_type: null
      });
      return orderPayments;
    } catch (ex) {
      message.error(t('pageOrder:csv-download-passcode-form-validation-failed-message'));
    }
  };

  const handleOnChangePaymentFieldType = e => {
    setPaymentFieldType(e.target.value);
  };

  return (
    <Modal
      visible
      width="fit-content"
      style={{ maxWidth: '60%' }}
      title={t('pageOrder:csv-payments-download-title')}
      maskClosable={false}
      onCancel={onCancel}
      footer={
        <Space>
          <CsvDownload
            asyncExportMethod={getFormattedPaymentsCSVData}
            onDataLoading={() => setIsLoadingData(true)}
            onDataLoaded={() => setIsLoadingData(false)}
            headers={csvHeaders}
            filename={`${store.shortName}_Payments_${moment(dateQuery.startDate).format('YYYYMMDD')}-${moment(dateQuery.endDate).format(
              'YYYYMMDD'
            )}.csv`}
          >
            <Button loading={isLoadingConstants || isLoadingData} type="primary" icon={<DownloadOutlined />}>
              {t('pageOrder:csv-download-button-text')}
            </Button>
          </CsvDownload>
        </Space>
      }
    >
      {isLoadingConstants ? (
        <Skeleton loading />
      ) : (
        <Form
          form={form}
          initialValues={{
            types: paymentTypes.map(paymentType => paymentType.value),
            statuses: paymentStatuses.map(paymentStatus => paymentStatus.value)
          }}
        >
          <Row>
            <FieldLabel margin="4px 0">{t('pageOrder:csv-payments-download-date-text')}</FieldLabel>
            <NoteLabel>{t('pageOrder:csv-payments-download-note-text', { dayLimit: RANGE_DAY_LIMIT })}</NoteLabel>
            <DateRangePicker
              value={dateRangeValues}
              onChange={dates => dates && setDateQuery({ startDate: formatToDateString(dates[0]), endDate: formatToDateString(dates[1]) })}
              allowClear={false}
              extraDisabledDate={current => current > moment().endOf('day') || current.diff(dateQuery.startDate, 'days') > RANGE_DAY_LIMIT}
              style={{ marginBottom: '16px' }}
            />
            <FormCheckbox
              name="types"
              label={t('pageOrder:csv-payments-download-types-text')}
              requiredErrorMessage={t('pageOrder:csv-payments-download-selection-required-message')}
              options={paymentTypes}
            />
            <FormCheckbox
              name="statuses"
              label={t('pageOrder:csv-payments-download-statuses-text')}
              requiredErrorMessage={t('pageOrder:csv-payments-download-selection-required-message')}
              options={paymentStatuses}
            />
            <Col span={24} style={{ marginBottom: '32px' }}>
              <FieldLabel margin="0 0 16px 0">{t('pageOrder:csv-payments-download-field-type-text')}</FieldLabel>
              <Radio.Group
                options={Object.values(PAYMENT_FIELDS_TYPES).map(paymentFieldType => {
                  return { ...paymentFieldType, label: t(`commonConstants:${paymentFieldType.transKey}`) };
                })}
                onChange={handleOnChangePaymentFieldType}
                value={paymentFieldType}
                optionType="button"
                buttonStyle="solid"
              />
            </Col>
            <Col span={24} style={{ marginBottom: '32px' }}>
              <OrderTagSelection name="tags" />
            </Col>
            {isRequirePasscode && (
              <Col span={24}>
                <FormPasswordInput
                  label={t('pageOrder:form-input-label-security-passcode')}
                  name="securityPasscode"
                  placeholder={t('pageOrder:form-input-placeholder-security-passcode')}
                  requiredErrorMessage={t('pageOrder:form-input-required-message-security-passcode')}
                  customPattern={PASSCODE_REGEX}
                  customPatternErrorMessage={t('pageOrder:form-input-invalid-pattern-message-security-passcode')}
                />
              </Col>
            )}
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default withAppContext(ExportPaymentsCSVModal);
