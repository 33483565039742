import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'components/FormItem/FormItem';
import Checkbox from '../Checkbox';

const FormCheckbox = ({
  name,
  label,
  defaultValue,
  options,

  requiredErrorMessage,
  extraRules = [],

  isDisabled,

  onChange
}) => {
  return (
    <FormItem name={name} label={label} requiredErrorMessage={requiredErrorMessage} extraRules={extraRules} defaultValue={defaultValue}>
      <Checkbox options={options} isDisabled={isDisabled} onChange={onChange} />
    </FormItem>
  );
};

FormCheckbox.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.array,
  options: PropTypes.array,
  requiredErrorMessage: PropTypes.string,
  extraRules: PropTypes.array,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default FormCheckbox;
