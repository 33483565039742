import { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined, EyeOutlined, UploadOutlined, DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Spin, Typography, Button } from 'antd';
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';
import { ClassNames } from '@emotion/react';
import Dropzone from 'react-dropzone';
import { uploadFile, deleteDocuments } from 'apis/s3Upload';
import { REACT_APP_S3_BUCKET_URL } from 'config/env';
import { encodeImageUrl } from 'utils/general';
import ImageCropper from './ImageCropper';
import { ValidDropzoneStyle, InvalidDropzoneStyle } from './PhotoManager.styles';

const { Text } = Typography;

const byNew = i => i.isNew && !i.isMarkedForDelete;
const byMarkedForDelete = i => !i.isNew && i.isMarkedForDelete;
const byNoChange = i => !i.isNew && !i.isMarkedForDelete;

const PhotoManager = forwardRef(
  (
    {
      storeId,
      value,
      buttonLabel,
      onClick,
      onChange,
      onStatusChange,
      disabled,
      multiple,
      fileSizeThresholdToCompressInKb,
      maxFileSizeInKb,
      shouldRemoveDocsMarkedForDelete,
      label,
      allowPdf,
      cropAspect,
      required,
      errorMessage,
      isHorizontalAlign
    },
    ref
  ) => {
    const [compressingCount, setCompressingCount] = useState(0);
    const [hasError, setHasError] = useState(false);
    const [imageToCrop, setImageToCrop] = useState();
    const [fileToCrop, setFileToCrop] = useState();
    const [showCropper, setShowCropper] = useState(false);
    const isTextListMode = allowPdf;
    const isCropperEnabled = !!cropAspect;
    const isMultiple = multiple;

    useEffect(() => {
      onStatusChange && onStatusChange(compressingCount > 0);
    }, [onStatusChange, compressingCount]);

    const handleUpdate = useCallback(async () => {
      const itemsToUpload = value.filter(byNew);

      await Promise.all([
        ...itemsToUpload.map(item => uploadFile({ ...item.original, storeId }, allowPdf)),
        ...itemsToUpload.map(item => uploadFile({ ...item.thumbnail, storeId }, allowPdf))
      ]);

      if (shouldRemoveDocsMarkedForDelete) {
        const keysToDelete = value.filter(byMarkedForDelete).reduce((acc, cur) => [...acc, cur.original.name, cur.thumbnail.name], []);
        keysToDelete.length && deleteDocuments(keysToDelete);
      }

      const newValue = [
        ...itemsToUpload.map(item => ({
          uid: item.uid,
          name: item.name,
          original: {
            name: `${storeId}/${item.original.name}`,
            url: `${REACT_APP_S3_BUCKET_URL}/${storeId}/${item.original.name}`
          },
          thumbnail: {
            name: `${storeId}/${item.thumbnail.name}`,
            url: `${REACT_APP_S3_BUCKET_URL}/${storeId}/${item.thumbnail.name}`
          }
        })),
        ...value.filter(byNoChange)
      ];

      onChange && onChange(newValue);

      return newValue;
    }, [value, shouldRemoveDocsMarkedForDelete, onChange, allowPdf, storeId]);

    const handleValidate = useCallback(() => {
      if (required && !value.filter(byNew).length) {
        setHasError(true);
        return false;
      }

      return true;
    }, [required, value]);

    useImperativeHandle(
      ref,
      () => ({
        update: handleUpdate,
        validate: handleValidate
      }),
      [handleUpdate, handleValidate]
    );

    const addNewFiles = async files => {
      if (files?.length) {
        const newItems = [];
        setCompressingCount(files.length);

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const uid = uuidv4();
          const fileName = file.name.split('.')[0].replace(/[&/\\#,+()$~%.'":*?<>{}]/g, ''); // Remove special characters

          if (file.type === 'application/pdf') {
            const url = URL.createObjectURL(file);
            const pdfFile = {
              name: `${uid}_${fileName}.pdf`,
              file,
              url,
              type: 'application/pdf'
            };

            newItems.push({
              uid,
              name: file.name,
              original: pdfFile,
              thumbnail: pdfFile,
              isNew: true
            });
          } else {
            const originalOptions = {
              maxSizeMB: maxFileSizeInKb / 1024,
              useWebWorker: true,
              initialQuality: 0.7,
              fileType: 'image/jpeg'
            };

            const thumbnailOptions = {
              maxWidthOrHeight: 340,
              useWebWorker: true,
              initialQuality: 0.8,
              fileType: 'image/jpeg'
            };

            try {
              const originalImage = file.size / 1024 > fileSizeThresholdToCompressInKb ? await imageCompression(file, originalOptions) : file;

              const thumbnailImage = await imageCompression(file, thumbnailOptions);

              newItems.push({
                uid,
                name: file.name,
                original: {
                  name: `${uid}_${fileName}.original.jpg`,
                  file: originalImage,
                  url: URL.createObjectURL(originalImage),
                  type: 'image/jpeg'
                },
                thumbnail: {
                  name: `${uid}_${fileName}.thumbnail.jpg`,
                  file: thumbnailImage,
                  url: URL.createObjectURL(thumbnailImage),
                  type: 'image/jpeg'
                },
                isNew: true
              });
            } catch (error) {
              // eslint-disable-next-line no-console
              console.warn(error);
            }
          }
        }

        const newValue = [...newItems, ...(isMultiple ? value : value.map(item => ({ ...item, isMarkedForDelete: true })))];

        onChange && onChange(newValue);

        setCompressingCount(0);
        setHasError(false);
      }
    };

    const handleSelectFiles = async files => {
      if (isCropperEnabled && files[0]) {
        const file = files[0];
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          if (typeof reader.result === 'string') {
            setImageToCrop(reader.result);
            setShowCropper(true);
          }
        });
        reader.readAsDataURL(file);
        setFileToCrop(file);
      } else {
        await addNewFiles(files);
      }
    };

    const handleDeletePhoto = index => {
      // clone array of object without referencing to the original object
      const newValue = JSON.parse(JSON.stringify(value));
      newValue[index].isMarkedForDelete = true;
      onChange && onChange(newValue);
    };

    const handleCropOk = croppedFile => {
      addNewFiles([croppedFile]);
      setShowCropper(false);
    };

    const handleCropCancel = () => {
      setShowCropper(false);
    };

    const renderDropzone = ({ isDragAccept, isDragReject }) => {
      return (
        <ClassNames>
          {({ css }) => (
            <>
              {isDragAccept ? <div className={css(ValidDropzoneStyle)}>Dropping files...</div> : null}
              {isDragReject ? <div className={css(InvalidDropzoneStyle)}>Invalid files</div> : null}
            </>
          )}
        </ClassNames>
      );
    };

    const renderPictureMode = () => {
      return (
        <div className="ant-upload-list-picture-card" style={isHorizontalAlign ? { display: 'flex', flexWrap: 'wrap' } : {}}>
          {compressingCount > 0
            ? new Array(compressingCount).fill(1)?.map((value, index) => (
                <div key={index} className="ant-upload-list-picture-card-container">
                  <div className="ant-upload-list-item ant-upload-list-item-list-type-picture-card">
                    <div className="ant-upload-list-item-info">
                      <span className="ant-upload-list-item-thumbnail">
                        <Spin />
                      </span>
                    </div>
                  </div>
                </div>
              ))
            : null}
          {value?.map((item, index) =>
            !item.isMarkedForDelete ? (
              <div className="ant-upload-list-picture-card-container" key={item.uid}>
                <div className="ant-upload-list-item ant-upload-list-item-list-type-picture-card">
                  <div className="ant-upload-list-item-info">
                    <span className="ant-upload-list-item-span">
                      <div className="ant-upload-list-item-thumbnail">
                        <img src={encodeImageUrl(item.thumbnail.url)} alt={item.thumbnail.name} className="ant-upload-list-item-image" />
                      </div>
                    </span>
                  </div>
                  <span className="ant-upload-list-item-actions" style={{ display: 'flex' }}>
                    <a href={encodeImageUrl(item.original.url)} target="_blank" rel="noreferrer">
                      <EyeOutlined />
                    </a>
                    <Button
                      type="text"
                      size="small"
                      className="ant-upload-list-item-card-actions-btn"
                      onClick={() => handleDeletePhoto(index)}
                      icon={<DeleteOutlined />}
                    />
                  </span>
                </div>
              </div>
            ) : null
          )}
          <Dropzone
            disabled={disabled}
            multiple={isMultiple}
            accept={{ 'image/*': [] }}
            onDrop={handleSelectFiles}
            onFileDialogOpen={typeof onClick === 'function' ? onClick : undefined}
          >
            {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
              <div {...getRootProps()}>
                <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
                  <label
                    tabIndex={0}
                    className="ant-upload"
                    role="button"
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    <div>
                      <input {...getInputProps()} accept="image/*" />
                      <PlusOutlined />
                      <Text style={{ marginTop: '8px', display: 'block' }}>{buttonLabel}</Text>
                    </div>
                  </label>
                </div>
                {renderDropzone({ isDragAccept, isDragReject })}
              </div>
            )}
          </Dropzone>
        </div>
      );
    };

    const renderTextMode = () => {
      return (
        <div className="ant-upload-list-text">
          <Dropzone
            disabled={disabled}
            multiple={isMultiple}
            accept={{ 'image/*': [], 'application/pdf': [] }}
            onDrop={handleSelectFiles}
            onFileDialogOpen={typeof onClick === 'function' ? onClick : undefined}
          >
            {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
              <div {...getRootProps()}>
                <span className="ant-upload ant-upload-select ant-upload-select-text">
                  <input {...getInputProps()} accept="image/*, .pdf" />
                  <Button disabled={disabled} type="primary" icon={<UploadOutlined />}>
                    {buttonLabel}
                  </Button>
                </span>
                {renderDropzone({ isDragAccept, isDragReject })}
              </div>
            )}
          </Dropzone>
          {value.map((item, index) =>
            !item.isMarkedForDelete ? (
              <div className="ant-upload-list-text-container">
                <div className="ant-upload-list-item ant-upload-list-item-list-type-text">
                  <div className="ant-upload-list-item-info">
                    <span className="ant-upload-span">
                      <div className="ant-upload-text-icon">
                        <PaperClipOutlined />
                      </div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="ant-upload-list-item-name"
                        title={item.name}
                        href={encodeImageUrl(item.original.url)}
                      >
                        {item.name}
                      </a>
                      <span className="ant-upload-list-item-card-actions">
                        <Button
                          type="text"
                          size="small"
                          className="ant-upload-list-item-card-actions-btn"
                          onClick={() => handleDeletePhoto(index)}
                          icon={<DeleteOutlined />}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ) : null
          )}
        </div>
      );
    };

    return (
      <>
        {label ? <Typography.Paragraph style={{ fontSize: '1rem' }}>{label}</Typography.Paragraph> : null}
        {isTextListMode ? renderTextMode() : renderPictureMode()}
        {hasError ? <Text type="danger">{errorMessage}</Text> : null}
        {showCropper ? (
          <ImageCropper image={imageToCrop} file={fileToCrop} aspect={cropAspect} onCropOk={handleCropOk} onCropCancel={handleCropCancel} />
        ) : null}
      </>
    );
  }
);

PhotoManager.defaultProps = {
  value: [],
  buttonLabel: 'Upload images / documents',
  disabled: false,
  multiple: false,
  fileSizeThresholdToCompressInKb: 512,
  maxFileSizeInKb: 512,
  shouldRemoveDocsMarkedForDelete: false,
  allowPdf: false,
  required: false,
  isHorizontalAlign: false
};

PhotoManager.propTypes = {
  value: PropTypes.array,
  buttonLabel: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  shouldRemoveDocsMarkedForDelete: PropTypes.bool,
  beforeUpload: PropTypes.func,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onStatusChange: PropTypes.func,
  fileSizeThresholdToCompressInKb: PropTypes.number,
  allowPdf: PropTypes.bool,
  cropAspect: PropTypes.number,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  storeId: PropTypes.string,
  isHorizontalAlign: PropTypes.bool
};

export default PhotoManager;
