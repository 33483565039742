import { useState } from 'react';
import { Col, Form, message, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

const { useForm } = Form;
const { confirm } = Modal;

export const useBulkCreateModal = () => {
  const [isBulkCreateModalVisible, setIsBulkCreateModalVisible] = useState(false);

  const handleOnBulkCreateBtnClick = () => {
    setIsBulkCreateModalVisible(true);
  };

  return {
    isBulkCreateModalVisible,
    setIsBulkCreateModalVisible,
    handleOnBulkCreateBtnClick
  };
};

const BulkCreateModal = ({ setIsBulkCreateModalVisible, onOk }) => {
  const { t } = useTranslation(['common', 'pageProductDetails']);

  const [form] = useForm();

  const handleOnAddBtnClick = () => {
    form
      .validateFields()
      .then(values => {
        const { fromQuantity, toQuantity, unitPrice } = values;
        if (fromQuantity > toQuantity) {
          return message.error(t('pageProductDetails:bulk-tier-bulk-create-modal-error-message-invalid-range'));
        }

        confirm({
          content: t('pageProductDetails:bulk-tier-bulk-create-modal-confirm-message', { fromQuantity, toQuantity, unitPrice }),
          okText: t('common:modal-ok-text'),
          cancelText: t('common:modal-cancel-text'),
          onOk() {
            onOk({ fromQuantity: Number(fromQuantity), toQuantity: Number(toQuantity), unitPrice: Number(unitPrice) });
          }
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handleOnBulkCreateModalCancel = () => {
    setIsBulkCreateModalVisible(false);
  };

  return (
    <Modal
      visible
      width="90%"
      style={{ maxWidth: '768px' }}
      title={t('pageProductDetails:bulk-tier-bulk-create-modal-title')}
      okText={t('pageProductDetails:bulk-tier-bulk-create-modal-add-button')}
      onOk={handleOnAddBtnClick}
      onCancel={handleOnBulkCreateModalCancel}
    >
      <Form form={form}>
        <Row gutter={24}>
          <Col span={24} md={8}>
            <FormInputNumber
              label={t('pageProductDetails:bulk-tier-bulk-create-modal-label-from-quantity')}
              name="fromQuantity"
              minValue={2}
              placeholder={t('pageProductDetails:bulk-tier-bulk-create-modal-placeholder-from-quantity')}
              requiredErrorMessage={t('pageProductDetails:bulk-tier-bulk-create-modal-error-message-from-quantity')}
            />
          </Col>
          <Col span={24} md={8}>
            <FormInputNumber
              label={t('pageProductDetails:bulk-tier-bulk-create-modal-label-to-quantity')}
              name="toQuantity"
              minValue={3}
              placeholder={t('pageProductDetails:bulk-tier-bulk-create-modal-placeholder-to-quantity')}
              requiredErrorMessage={t('pageProductDetails:bulk-tier-bulk-create-modal-error-message-to-quantity')}
            />
          </Col>
          <Col span={24} md={8}>
            <FormInputNumber
              label={t('pageProductDetails:bulk-tier-bulk-create-modal-label-unit-price')}
              name="unitPrice"
              type="financial"
              placeholder={t('pageProductDetails:bulk-tier-bulk-create-modal-placeholder-unit-price')}
              requiredErrorMessage={t('pageProductDetails:bulk-tier-bulk-create-modal-error-message-unit-price')}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BulkCreateModal;
