import { useCallback, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { message } from 'antd';
import throttle from 'lodash.throttle';

const useDisplayError = (shouldDisplayError, error, prefixErrorMessage) => {
  const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);

  if (!isErrorDisplayed && shouldDisplayError && !!error) {
    setIsErrorDisplayed(true);
    console.error(error); /* eslint no-console: ["error", { allow: ["error"] }] */

    const baseErrorMessage = error.message || 'Something went wrong while retriving data, please contact our technical support';
    const errorMessage = `${prefixErrorMessage}${baseErrorMessage}`;
    message.error(errorMessage);
  }
};

export const useRefetchQuery = keys => {
  const queryClient = useQueryClient();
  const refetch = useCallback(
    (extraKeys = []) => {
      queryClient.invalidateQueries([...keys, ...extraKeys]);
    },
    [queryClient, keys]
  );

  return { refetch };
};

export const useCustomQuery = (
  key,
  paramsInArray = [],
  apiFunction,
  {
    shouldDisplayError = true,
    shouldDefaultEmptyObject = true,
    prefixErrorMessage = '',
    throttleRefetchTimeInMs = 0,
    postProcessFunc = apiResData => apiResData,
    ...queryOptions
  } = {}
) => {
  const keysInQuery = useMemo(() => [key, ...paramsInArray], [key, paramsInArray]);
  const wrappedApiFunc = useCallback(async () => {
    return apiFunction(...keysInQuery).then(postProcessFunc);
  }, [apiFunction, keysInQuery, postProcessFunc]);

  const {
    data,
    isFetching, // isFetching is the loading state that we always expected
    isLoading, // We deconstruct isLoading to prevent overriding our custom field when return
    error,
    refetch,
    ...returnedParams
  } = useQuery(keysInQuery, wrappedApiFunc, queryOptions);

  useDisplayError(shouldDisplayError, error, prefixErrorMessage);
  const returnedData = useMemo(() => data || (shouldDefaultEmptyObject ? {} : data), [shouldDefaultEmptyObject, data]);

  const wrappedRefetch = useMemo(() => {
    if (throttleRefetchTimeInMs) {
      return throttle(refetch, throttleRefetchTimeInMs);
    }
    return refetch;
  }, [refetch, throttleRefetchTimeInMs]);

  // isFetching is alaways true even when it is not fetching.
  return { isLoading: isFetching, data: returnedData, error, refetch: wrappedRefetch, ...returnedParams };
};

export const useCustomPaginatedQuery = (
  key,
  apiFunction,
  { extraQuery = {}, filter, sorter, currentPage = 1, limit = 10 } = {},
  {
    shouldDisplayError = true,
    shouldDefaultEmptyObject = true,
    prefixErrorMessage = '',
    extraKeys = [],
    throttleRefetchTimeInMs = 0,
    ...queryOptions
  } = {}
) => {
  const queryToFetch = {
    ...(filter && { filter: encodeURIComponent(JSON.stringify(filter)) }),
    ...(sorter && { sort: encodeURIComponent(JSON.stringify(sorter)) }),
    pagination: encodeURIComponent(JSON.stringify({ limit, currentPage: currentPage })),
    ...extraQuery
  };
  const queryKeys = [key, ...extraKeys, filter, sorter, currentPage, limit]; // extraKeys is a temporary hack to make pagination fetch unique without affecting the data
  const {
    data,
    error,
    isFetching, // isFetching is the loading state that we always expected
    isLoading, // We deconstruct isLoading to prevent overriding our custom field when return
    refetch,
    ...returnedParams
  } = useQuery(queryKeys, async () => apiFunction(key, queryToFetch), {
    ...queryOptions,
    keepPreviousData: true
  });

  const wrappedRefetch = useMemo(() => {
    if (throttleRefetchTimeInMs) {
      return throttle(refetch, throttleRefetchTimeInMs);
    }
    return refetch;
  }, [refetch, throttleRefetchTimeInMs]);

  useDisplayError(shouldDisplayError, error, prefixErrorMessage);

  return {
    isLoading: isFetching,
    paginatedData: data && data[key], // This is why extraKeys is needed
    total: data && data.totalCount,
    error,
    refetch: wrappedRefetch,
    ...returnedParams
  };
};
