import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { withAppContext } from 'contexts/AppContext/AppContext';

const CurrencyDisplay = ({ prefix = '', storeCurrency }) => {
  const { t } = useTranslation(['compCurrencyDisplay']);
  const { symbol: currencySymbol } = storeCurrency;

  return <span>{t('compCurrencyDisplay:table-col-title-display', { prefix, currencySymbol })}</span>;
};

CurrencyDisplay.propTypes = {
  prefix: PropTypes.string.isRequired,
  storeCurrency: PropTypes.object.isRequired
};

export default withAppContext(CurrencyDisplay);
