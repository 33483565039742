import styled from '@emotion/styled';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const CardTitle = styled.span`
  color: ${props => props.theme.color.tertiary};
`;

export const ContentText = styled.div`
  font-size: 14px;
  margin: 8px 0 0 38px;
`;

export const TitleIcon = styled(ExclamationCircleOutlined)`
  color: #faad14;
  margin-right: 16px;
  font-size: 22px;
`;

export const TitleText = styled.span`
  font-size: 16px;
`;
