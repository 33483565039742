import PriceDisplay from 'components/PriceDisplay/PriceDisplay';

import { PriceLabelCol, PriceValueCol } from './CheckoutPriceDisplay.styles';

const CheckoutPriceDisplay = ({
  label,
  storeCurrency,
  amount,
  isDeduct = false,
  labelWhenUndefined,
  labelWhenZero,
  highlight = false,
  warn = false
}) => {
  return (
    <>
      <PriceLabelCol span={14} highlight={highlight} warn={warn}>{`${label}: `}</PriceLabelCol>
      <PriceValueCol span={10} highlight={highlight} warn={warn}>
        <PriceDisplay
          storeCurrency={storeCurrency}
          amount={amount}
          isDeduct={isDeduct}
          labelWhenUndefined={labelWhenUndefined}
          labelWhenZero={labelWhenZero}
        />
      </PriceValueCol>
    </>
  );
};

export default CheckoutPriceDisplay;
