import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DiffOutlined,
  FileTextOutlined,
  TagsOutlined,
  PicRightOutlined,
  TrophyOutlined,
  DisconnectOutlined,
  ExclamationCircleOutlined,
  CalculatorOutlined,
  DeleteRowOutlined
} from '@ant-design/icons';
import { Avatar, Popconfirm, Row, Table, Tooltip, Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import { guard } from 'utils/general';
import { constructColumn, constructColumnFilterSearch, handleOnAggregationTableChange } from 'utils/table/table';

import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import TextButton from 'components/TextButton/TextButton';
import TicketIcon from 'components/Icon/TicketIcon';
import Title from 'components/Title/Title';

import { BuyEventContainer, BuyEventIconContainer, NameContainer, NameLabel, TitleContainerRow } from './TiktokPostBuyEvents.styles';
import { patchSocialMediaUserBlacklistStatus, useGetBlacklistedSocialMediaUserIds } from 'apis/socialMediaUser';
import { DEFAULT_PAGINATION_QUERY } from 'utils/constants';
import { useGetPaginatedPostBuyEvents, useRefetchPostStatistic } from 'apis/post';
import { patchRemoveOrderItem, useRefetchPostOrders } from 'apis/order';
import { useRefetchProducts } from 'apis/product';
import { useRefetchStoreOrderLimit } from 'apis/store';

const SYSTEM_REMARK_CODE_MAPPING = {
  '-2': {
    titleKey: 'pageOverview:comment-system-remark-title-remark',
    messageKey: 'pageOverview:comment-system-remark-unknown-error',
    DisplayIconComp: ExclamationCircleOutlined
  },
  100: {
    1: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-comment-issue-repeat',
      DisplayIconComp: DiffOutlined
    },
    2: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-comment-issue-pattern-mismatch',
      DisplayIconComp: ExclamationCircleOutlined
    }
  },
  101: {
    1: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-product-setting-keyword-not-found',
      DisplayIconComp: ExclamationCircleOutlined
    },
    2: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-product-setting-insufficient-inventory',
      DisplayIconComp: ExclamationCircleOutlined
    },
    3: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-product-setting-sold-out',
      DisplayIconComp: ExclamationCircleOutlined
    },
    4: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-product-setting-min-purchase-limit-not-reach',
      DisplayIconComp: ExclamationCircleOutlined
    },
    5: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-product-setting-max-purchase-limit-reach',
      DisplayIconComp: ExclamationCircleOutlined
    }
  },
  102: {
    1: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-user-setting-user-blacklist',
      DisplayIconComp: ExclamationCircleOutlined
    }
  },
  103: {
    1: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-product-setting-quantity-change',
      DisplayIconComp: CalculatorOutlined
    }
  },
  104: {
    1: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-store-setting-disabled-reduce-purchase-quantity',
      DisplayIconComp: ExclamationCircleOutlined
    }
  },
  200: {
    1: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-sm-issue-incomplete-info',
      DisplayIconComp: ExclamationCircleOutlined
    }
  },
  201: {
    '-2': {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-unknown-error',
      DisplayIconComp: DisconnectOutlined
    },
    '-1': {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-unexpected-error',
      DisplayIconComp: DisconnectOutlined
    },
    10: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-thread-controlled',
      DisplayIconComp: DisconnectOutlined
    },
    190: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-access-token',
      DisplayIconComp: DisconnectOutlined
    },
    100: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-invalid-param',
      DisplayIconComp: DisconnectOutlined
    },
    105: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-exceed-max-param',
      DisplayIconComp: DisconnectOutlined
    },
    200: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-permission',
      DisplayIconComp: DisconnectOutlined
    },
    551: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-person-unavailable',
      DisplayIconComp: DisconnectOutlined
    },
    613: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-api-rate-limit',
      DisplayIconComp: DisconnectOutlined
    },
    2022: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-app-temp-disabled',
      DisplayIconComp: DisconnectOutlined
    },
    10900: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-activity-replied',
      DisplayIconComp: DisconnectOutlined
    },
    10901: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-activity-reply-time-expired',
      DisplayIconComp: DisconnectOutlined
    },
    10903: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-user-cant-reply',
      DisplayIconComp: DisconnectOutlined
    },
    10904: {
      titleKey: 'pageOverview:comment-system-remark-title-unable-to-send-link',
      messageKey: 'pageOverview:comment-system-remark-sm-fb-page-message-disabled',
      DisplayIconComp: DisconnectOutlined
    }
  },
  300: {
    1: {
      titleKey: 'pageOverview:comment-system-remark-title-remark',
      messageKey: 'pageOverview:comment-system-remark-system-setting-order-limit-reached',
      DisplayIconComp: ExclamationCircleOutlined
    }
  }
};

class SystemRemark {
  constructor(code, subcode, metadata) {
    this.code = code;
    this.subcode = subcode;
    this.metadata = metadata;

    this.systemRemarkInfo = guard(
      () => (this.subcode ? SYSTEM_REMARK_CODE_MAPPING[this.code][this.subcode] : SYSTEM_REMARK_CODE_MAPPING[this.code]),
      SYSTEM_REMARK_CODE_MAPPING['-2']
    );
  }

  getDisplayIconComp() {
    return this.systemRemarkInfo.DisplayIconComp;
  }

  formatMessage(t) {
    const quantityChanged = guard(() => this.metadata.quantityChanged);

    return t(this.systemRemarkInfo.messageKey, {
      code: this.code,
      subcode: this.subcode,
      productKeyword: guard(() => this.metadata.productKeyword),
      ...(quantityChanged && {
        isIncreased: quantityChanged > 0 ? 1 : 0,
        quantityChanged: Math.abs(quantityChanged)
      })
    });
  }

  formatTitle(t) {
    return t(this.systemRemarkInfo.titleKey);
  }
}

const formatPostBuyEvents = (postBuyEvents, blacklist) =>
  postBuyEvents.map(buyEvent => ({ ...buyEvent, isUserBlacklisted: blacklist && blacklist.includes(buyEvent.sourceUserId) }));

const TableAlertInfo = ({ t, systemRemarks }) => {
  const hasMultiRemarks = systemRemarks.length > 1;
  const firstSystemRemarkObj = new SystemRemark(systemRemarks[0].code, systemRemarks[0].subcode, systemRemarks[0].metadata);

  const DisplayIconComp = hasMultiRemarks ? ExclamationCircleOutlined : firstSystemRemarkObj.getDisplayIconComp();

  return (
    <BuyEventIconContainer
      canClick
      onClick={() => {
        Modal.info({
          title: <b>{hasMultiRemarks ? t('pageOverview:comment-system-remark-title-remarks') : firstSystemRemarkObj.formatTitle(t)}</b>,
          content: systemRemarks.map((systemRemark, index) => {
            const systemRemarkObj = new SystemRemark(systemRemark.code, systemRemark.subcode, systemRemark.metadata);

            return (
              <p key={index} style={{ marginBottom: '4px' }}>
                {systemRemarkObj.formatMessage(t)}
              </p>
            );
          })
        });
      }}
    >
      <DisplayIconComp style={{ color: '#ff7212' }} />
    </BuyEventIconContainer>
  );
};

const BuyEventItemIcon = ({ t, buyEvent }) => {
  if (buyEvent.orderId || buyEvent.luckyDrawId || buyEvent.customerPromoId) {
    return (
      <>
        {buyEvent.orderId && (
          <BuyEventIconContainer>
            <TagsOutlined style={{ color: '#ff7212' }} />
          </BuyEventIconContainer>
        )}
        {buyEvent.luckyDrawId && (
          <BuyEventIconContainer>
            {buyEvent.isWinner ? <TrophyOutlined style={{ color: '#ff7212' }} /> : <TicketIcon width={20} height={20} />}
          </BuyEventIconContainer>
        )}
        {buyEvent.customerPromoId && (
          <BuyEventIconContainer>
            <PicRightOutlined style={{ color: '#ff7212' }} />
          </BuyEventIconContainer>
        )}
        {buyEvent.systemRemarks && <TableAlertInfo t={t} systemRemarks={buyEvent.systemRemarks} />}
      </>
    );
  } else if (buyEvent.systemRemarks) {
    return <TableAlertInfo t={t} systemRemarks={buyEvent.systemRemarks} />;
  }

  return null;
};

const constructColumns = ({ t, source, onViewOrderClick, onRemoveOrderItem, onBlacklistModalConfirm }) => [
  {
    ...constructColumn(t('pageOverview:live-comment-table-header-username'), 'name', { width: '30%', emptyDisplayValue: 'Unknown User Name' }),
    render: (text, record) => {
      const title = !record.isUserBlacklisted
        ? t('pageOverview:live-comment-blacklist-title-blacklist')
        : t('pageOverview:live-comment-blacklist-title-unblacklist');
      const okText = !record.isUserBlacklisted
        ? t('pageOverview:live-comment-blacklist-button-blacklist')
        : t('pageOverview:live-comment-blacklist-button-unblacklist');
      const cancelText = t('common:modal-cancel-text');

      return (
        <Popconfirm
          title={title}
          okText={okText}
          cancelText={cancelText}
          onConfirm={onBlacklistModalConfirm(record.sourceUserId, source, !record.isUserBlacklisted)}
        >
          <NameContainer>
            <Tooltip title={record.isUserBlacklisted ? t('pageOverview:live-comment-blacklist-tooltip') : undefined} zIndex="1055">
              {record.userProfilePicUrl && (
                <>
                  <Avatar size="small" src={`${record.userProfilePicUrl}`} />{' '}
                </>
              )}
              <NameLabel isUserBlacklisted={record.isUserBlacklisted}>{text}</NameLabel>
            </Tooltip>
          </NameContainer>
        </Popconfirm>
      );
    },
    ...constructColumnFilterSearch('name', t('pageOverview:live-comment-table-header-search-username'), { hasAggregationFilter: true })
  },
  {
    ...constructColumn(t('pageOverview:live-event-table-header-activity'), 'matchingKeywordAndQuantity', { width: '50%' }),
    render: (text, record) => {
      return (
        <BuyEventContainer>
          <BuyEventItemIcon t={t} buyEvent={record} />
          {text}
        </BuyEventContainer>
      );
    },
    ...constructColumnFilterSearch('matchingKeywordAndQuantity', t('pageOverview:live-event-table-header-search-activity'), {
      hasAggregationFilter: true
    })
  },
  {
    ...constructColumn(t('pageOverview:live-comment-table-header-actions'), 'action', { width: '20%' }),
    render: (text, record) => {
      return (
        <Row>
          <TextButton
            color="highlight"
            icon={<FileTextOutlined />}
            onClick={() => onViewOrderClick(record.orderId)}
            tooltipMessage={t('common:text-button-tooltip-message', {
              action: t('pageOverview:live-comment-table-action-tooltip-message-view-order')
            })}
          />

          <Popconfirm
            title={t('pageOverview:live-event-pop-remove-order-item-title')}
            okText={t('pageOverview:live-event-pop-remove-order-item-button')}
            cancelText={t('common:modal-cancel-text')}
            onConfirm={() => {
              onRemoveOrderItem(record.orderId, record.quantity, record.postBuyEventId);
            }}
          >
            <TextButton
              color="highlight"
              icon={<DeleteRowOutlined />}
              tooltipMessage={t('common:text-button-tooltip-message', {
                action: t('pageOverview:live-event-table-action-tooltip-message-remove-order-item')
              })}
            />
          </Popconfirm>
        </Row>
      );
    }
  }
];

const usePostBuyEvents = ({ postId, isLoadingBlacklist, blacklist, refetchAfterOrderChanged }) => {
  const defaultPageSize = 100;
  const refetchInterval = 5000;

  const [query, setQuery] = useState({ ...DEFAULT_PAGINATION_QUERY, limit: defaultPageSize });
  const [shouldRefetchAfterOrderChanged, setShouldRefetchAfterOrderChanged] = useState(false);

  const {
    isLoading: isPostBuyEventsLoading,
    paginatedData: postBuyEvents,
    total: totalPostBuyEventCount,
    refetch: refetchPostBuyEvents
  } = useGetPaginatedPostBuyEvents(postId, query, {
    refetchInterval,
    onSucess: () => {
      setShouldRefetchAfterOrderChanged(true);
    }
  });

  const formattedPostBuyEvents = useMemo(() => {
    // Init & change page & blacklist change
    if (postBuyEvents && blacklist) {
      return formatPostBuyEvents(postBuyEvents, blacklist);
    }

    return [];
  }, [postBuyEvents, blacklist]);

  useEffect(() => {
    if (shouldRefetchAfterOrderChanged) {
      refetchAfterOrderChanged();
      setShouldRefetchAfterOrderChanged(false);
    }
  }, [shouldRefetchAfterOrderChanged, refetchAfterOrderChanged]);

  const updateCurrentPage = useCallback(newPage => {
    setQuery(prevQuery => ({ ...prevQuery, currentPage: newPage }));
  }, []);

  const updateQuery = useCallback(newQuery => {
    setQuery(newQuery);
  }, []);

  useEffect(() => {
    refetchPostBuyEvents();
  }, [query, refetchPostBuyEvents]);

  return {
    postBuyEvents: formattedPostBuyEvents,
    isPostBuyEventsLoading,
    totalPostBuyEventCount,

    defaultPageSize,
    currentPage: query.currentPage,

    updateCurrentPage,
    updateQuery,
    refetchPostBuyEvents
  };
};

const useRefetchForOrderChange = () => {
  const refetchPostOrders = useRefetchPostOrders();
  const refetchProducts = useRefetchProducts();
  const refetchPostStatistic = useRefetchPostStatistic();
  const refetchStoreOrderLimit = useRefetchStoreOrderLimit();

  const refetchAfterOrderChanged = useCallback(() => {
    refetchPostOrders();
    refetchProducts();
    refetchPostStatistic();
    refetchStoreOrderLimit();
  }, [refetchPostOrders, refetchProducts, refetchPostStatistic, refetchStoreOrderLimit]);

  return refetchAfterOrderChanged;
};

/* ================================================== Main Component ================================================== */
const TiktokPostBuyEvents = ({ postId, source, hasValidSelectedPost, onViewOrderClick }) => {
  const { t } = useTranslation(['common', 'pageOverview']);
  const refTitleContainer = useRef(null);
  const [isRemovingOrderItem, setIsRemovingOrderItem] = useState(false);

  const refetchAfterOrderChanged = useRefetchForOrderChange();
  const { data: blacklist, isLoading: isLoadingBlacklist, refetch: refetchBlacklist } = useGetBlacklistedSocialMediaUserIds(source);
  const {
    postBuyEvents,
    isPostBuyEventsLoading,
    totalPostBuyEventCount,

    defaultPageSize,
    currentPage,

    updateCurrentPage,
    updateQuery,
    refetchPostBuyEvents
  } = usePostBuyEvents({
    postId,
    isLoadingBlacklist,
    blacklist,
    refetchAfterOrderChanged
  });

  const handleOnBlacklistModalConfirm = useCallback(
    (sourceUserId, source, shouldBlacklistUser) => () => {
      patchSocialMediaUserBlacklistStatus(sourceUserId, source, shouldBlacklistUser).then(() => {
        refetchBlacklist();
      });
    },
    [refetchBlacklist]
  );

  const handleOnRemoveOrderItem = useCallback(
    (orderId, quantityToReduce, postBuyEventId) => {
      setIsRemovingOrderItem(true);

      patchRemoveOrderItem(orderId, postBuyEventId, { quantityToReduce }).then(() => {
        refetchPostBuyEvents();
        refetchAfterOrderChanged();

        message.success(t('common:update-success-message'));
        setIsRemovingOrderItem(false);
      });
    },
    [t, refetchPostBuyEvents, refetchAfterOrderChanged]
  );

  const handleOnTableChange = useCallback(
    (pagination, filters, sorter) => {
      handleOnAggregationTableChange({ pagination: { ...pagination }, filters, sorter }, updateQuery);
    },
    [updateQuery]
  );

  return (
    <>
      <TitleContainerRow ref={refTitleContainer} justify="space-between">
        <Title>{t('pageOverview:live-event-section-title')}</Title>
      </TitleContainerRow>
      {hasValidSelectedPost ? (
        <FullWidthContainer>
          <Table
            size="small"
            rowKey={record => record.commentId}
            dataSource={postBuyEvents}
            loading={isPostBuyEventsLoading || isRemovingOrderItem}
            columns={constructColumns({
              t,
              source,
              onViewOrderClick,
              onRemoveOrderItem: handleOnRemoveOrderItem,
              onBlacklistModalConfirm: handleOnBlacklistModalConfirm
            })}
            scroll={{ x: '100%', y: 560 }}
            pagination={{ total: totalPostBuyEventCount, current: currentPage, onChange: updateCurrentPage, defaultPageSize }}
            onChange={handleOnTableChange}
            locale={{
              triggerDesc: t('common:table-header-sort-trigger-desc'),
              triggerAsc: t('common:table-header-sort-trigger-asc'),
              cancelSort: t('common:table-header-sort-cancel-sort')
            }}
          />
        </FullWidthContainer>
      ) : (
        <div>{t('pageOverview:live-section-no-live-post-message')}</div>
      )}
    </>
  );
};

export default TiktokPostBuyEvents;
