import { useEffect } from 'react';
import { Skeleton } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetCheckoutOrderInvoice } from 'apis/external';

import { useFetchConstant } from 'hooks/constants';
import { useLocationQuery } from 'hooks/router.js';

import Invoice from '../components/Invoice';

const PrintCheckoutInvoice = () => {
  const { orderNumber } = useParams();
  const { accessCode } = useLocationQuery();

  const { t } = useTranslation(['common', 'pageOrderDetails']);
  const { isLoading: isOrderLoading, data: order } = useGetCheckoutOrderInvoice({ orderNumber, accessCode });

  const { selection: orderStatuses, isLoading: isOrderStatusesLoading } = useFetchConstant('orderStatuses');
  const { selection: paymentMethods, isLoading: isPaymentMethodsLoading } = useFetchConstant('paymentMethods');
  const { isLoading: isCountriesLoading, selection: countries } = useFetchConstant('countries', {
    labelKey: 'name',
    valueKey: 'iso2'
  });
  const { data: productTypesConst, isLoading: isProductTypesLoading } = useFetchConstant('productTypes');

  const isLoading = isOrderLoading || isOrderStatusesLoading || isPaymentMethodsLoading || isCountriesLoading || isProductTypesLoading;

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, [isLoading]);

  return isLoading ? (
    <Skeleton active />
  ) : (
    <>
      <Helmet>
        <meta name="title" id="gtm-title" content="Checkout | Download Invoice" />
        <title>
          {t('pageOrderDetails:pdf-invoice-title')} - {order.orderNumber}
        </title>
      </Helmet>
      <Invoice
        order={order}
        orderStatuses={orderStatuses}
        paymentMethods={paymentMethods}
        countries={countries}
        productTypesConst={productTypesConst}
        isCompact={true}
      />
    </>
  );
};

export default PrintCheckoutInvoice;
