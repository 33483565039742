import styled from '@emotion/styled';

export const SocialMediaTitleContainer = styled.div`
  display: flex;
  align-items: center !important;
`;

export const SocialMediaTitleIcon = styled.span`
  font-size: 20px;
  color: ${props => props.theme.color.socialMediaTT};
  margin-right: ${props => props.theme.spacing.xxs};
`;

export const SocialMediaTitle = styled.span`
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.color.socialMediaTT};
`;

export const ConnectionDetailsContainer = styled.div`
  margin: ${props => props.theme.spacing.xs} 0 24px 0;
`;
