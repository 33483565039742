import { Input, Select } from 'antd';
import PropTypes from 'prop-types';

import FormItem from 'components/FormItem/FormItem';

import { CONTACT_NUMBER_REGEX } from 'utils/constants';

import { StyledSelect, StyledInput } from './FormGroupSelectionInput.styles';
import { checkIsArrayEmpty } from 'utils/general';

const InputGroup = Input.Group;

const { Option } = Select;

const FormGroupSelectionInput = ({
  disabled,
  extraProps,
  inputDefaultValue,
  inputPlaceholder,
  inputName,
  inputType,
  label,
  name,
  onFocus,
  selectionName,
  selections,
  selectionPlaceholder,
  selectionDefaultValue,
  requiredErrorMessage,
  extraRules,
  overrideExtraRules
}) => {
  const generatePropsForRules = () => {
    const rules = [];
    if (requiredErrorMessage && typeof requiredErrorMessage === 'string') {
      rules.push({
        required: true,
        message: requiredErrorMessage
      });
    }

    if (!checkIsArrayEmpty(overrideExtraRules)) {
      rules.push(...overrideExtraRules);
    } else {
      if (inputType === 'contact') {
        rules.push({
          pattern: CONTACT_NUMBER_REGEX,
          message: `${label} must only contain numbers.`
        });
      }

      if (!checkIsArrayEmpty(extraRules)) {
        rules.push(...extraRules);
      }
    }
    return rules;
  };

  return (
    <>
      <FormItem label={label} requiredErrorMessage={requiredErrorMessage}>
        <InputGroup compact>
          <FormItem noStyle name={selectionName} onFocus={() => onFocus(name)}>
            <StyledSelect placeholder={selectionPlaceholder} defaultValue={selectionDefaultValue} disabled={disabled}>
              {selections.map(selection => (
                <Option key={selection.value} value={selection.value}>
                  {selection.label || selection.value}
                </Option>
              ))}
            </StyledSelect>
          </FormItem>
          <FormItem noStyle name={inputName} extraRules={generatePropsForRules()} onFocus={() => onFocus(name)}>
            <StyledInput placeholder={inputPlaceholder} defaultValue={inputDefaultValue} type={inputType} {...extraProps} disabled={disabled} />
          </FormItem>
        </InputGroup>
      </FormItem>
    </>
  );
};

export default FormGroupSelectionInput;

FormGroupSelectionInput.propTypes = {
  disabled: PropTypes.bool,
  extraProps: PropTypes.object,
  onFocus: PropTypes.func,
  requiredErrorMessage: PropTypes.string,
  selections: PropTypes.array,
  extraRules: PropTypes.array,
  overrideExtraRules: PropTypes.array
};

FormGroupSelectionInput.defaultProps = {
  disabled: false,
  extraProps: {},
  onFocus: () => {},
  selections: [],
  extraRules: [],
  overrideExtraRules: []
};
