import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'apis/apiHelper';
import { useCustomQuery } from 'hooks/reactQuery';
import { guard } from 'utils/general';

export const useFetchConstant = (name, { valueKey = 'code', labelKey = 'label', extraCustomKey = '', query = {} } = {}) => {
  const { t, i18n } = useTranslation(['commonConstants']);

  const getConstant = () => {
    return api.get(`/constants/${name}`, { params: query });
  };

  const postTranslationProcessFn = useCallback(
    apiResData => {
      const constantData = Object.entries(apiResData).reduce((newConstant, [constantObjKey, constantObj]) => {
        const fallbackLabel = constantObj[labelKey];

        const translationLabel = guard(() => {
          const transKey = `${name}-${valueKey}-${constantObj[valueKey]}`;
          const transLabel = t(`commonConstants:${transKey}`);

          if (transLabel === transKey) {
            return undefined;
          }

          return transLabel;
        });

        return {
          ...newConstant,
          [constantObjKey]: {
            ...constantObj,
            [labelKey]: translationLabel || fallbackLabel,
            oriLabel: fallbackLabel
          }
        };
      }, {});

      return constantData;
    },
    [t, name, valueKey, labelKey]
  );

  const { isLoading, data, ...returnedParams } = useCustomQuery(`constant${name}${extraCustomKey}`, [name, query, i18n.language], getConstant, {
    shouldDefaultEmptyObject: false,
    prefixErrorMessage: `Error while getting ${name}.\n`,
    staleTime: Infinity,
    postProcessFunc: postTranslationProcessFn
  });

  const isReadyToConstructSelection = !isLoading && !!data;
  const isConstructingPhoneCodeSelection = name === 'countries' && query.isFetchingAllCountries && valueKey === 'phoneCode';

  const selection = useMemo(() => constructSelections(data, valueKey, labelKey, isReadyToConstructSelection, isConstructingPhoneCodeSelection), [
    data,
    valueKey,
    labelKey,
    isReadyToConstructSelection,
    isConstructingPhoneCodeSelection
  ]);

  return { isLoading: !isReadyToConstructSelection, selection, data, ...returnedParams };
};

const constructSelections = (data, valueKey, labelKey, isReady, isCountryPhoneCode) => {
  if (isReady) {
    const dataValues = Object.values(data);
    if (isCountryPhoneCode) {
      return dataValues.reduce((uniqueCountryCodes, country) => {
        if (!uniqueCountryCodes.find(countryCode => countryCode.phoneCode === country.phoneCode)) {
          uniqueCountryCodes.push({
            ...country,
            value: country[valueKey],
            label: country[labelKey]
          });
        }
        return uniqueCountryCodes;
      }, []);
    } else {
      return dataValues.map(selection => ({
        ...selection,
        value: selection[valueKey],
        label: selection[labelKey]
      }));
    }
  }
  return [];
};
