import { useMemo, useState, useEffect, useCallback, useLayoutEffect, createContext } from 'react';
import { message } from 'antd';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { useHistory } from 'react-router';
import posthog from 'posthog-js';

import { postLogin, postVerify } from 'apis/auth';
import { updateApiHeaders } from 'apis/apiHelper';
import { useGetCurrentUserStore, useFetchStoreStarterOrderLimitDetails, getCheckIsFbConnected } from 'apis/store';
import { useFetchConstant } from 'hooks/constants';
import { useIsLoadings } from 'hooks/utils';

import { addMoment } from 'utils/date';
import { guard } from 'utils/general';
import { getLoginRoute } from 'utils/routes';
import { MOBILE_MAX_WIDTH } from 'utils/constants';
import { getIsUserLoggedIn, getUser, getAuthToken, setUserAuthObj, logoutChannel, logoutUser } from 'utils/auth';
import { addUserContext as addErrAndPerfUserContext, removeUserContext as removeErrAndPerfUserContext } from 'utils/errorAndPerfLogging';

const AppContext = createContext();

/* ==================================================== Local Functions ==================================================== */
const useTheme = () => {
  const originalTheme = useMemo(
    () => ({
      color: {
        primary: '#FFBA00',
        lightPrimary: '#FFCC55',
        lighterPrimary: '#FEF8E5',
        secondary: '#FF7212',
        lightSecondary: '#FDD8BE',
        tertiary: '#2C278A',
        lightTertiary: '#2196f3',
        lighterTertiary: '#F1F5FC',
        grey: '#797979',
        lightGrey: '#FAFAFA',
        green: '#52C41A',
        red: '#F5222D',
        warn: '#D4B106',
        white: '#FFFFFF',
        borderColor: '#E9E9E9',
        socialMediaFB: '#0165E1',
        socialMediaIG: '#E1306C',
        socialMediaTT: '#000000'
      },
      fontFamily: {
        title: "Pacifico, 'cursive'"
      },
      fontWeight: {
        light: 200,
        regular: 400,
        bold: 600,
        bolder: 700
      },
      spacing: {
        xxs: '4px',
        xs: '8px',
        s: '12px',
        regular: '16px',
        md: '32px',
        lg: '40px',
        xl: '48px',
        xxl: '60px',
        xxxl: '104px'
      }
    }),
    []
  );
  const [theme] = useState(originalTheme);

  return theme;
};

const useWindowSize = () => {
  const [isMobile, setIsMobile] = useState([0, 0]);

  useLayoutEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= MOBILE_MAX_WIDTH);
    };

    window.addEventListener('resize', checkIsMobile);
    checkIsMobile();
  }, []);

  return { isMobile };
};

const useListenBroadcastLogout = () => {
  useEffect(() => {
    const onLogoutMessage = message => {
      logoutUser(false);
      window.location.replace(getLoginRoute({ reason: message.errorCode }).path);
    };

    logoutChannel.addEventListener('message', onLogoutMessage);

    return () => logoutChannel.removeEventListener('message', onLogoutMessage);
  }, []);
};

const useSubscriptionConfig = (subscriptionTypesConst, subscriptionPeriodsConst, subscriptionConfig) => {
  const formattedSubscriptionConfig = useMemo(() => {
    const hasSubscriptionConfig = !!subscriptionConfig;

    const isSubscriptionStarter = guard(() => subscriptionConfig.subscriptionType === subscriptionTypesConst.STARTER.code, false);
    const isSubscriptionPaidStarter = guard(() => subscriptionConfig.subscriptionType === subscriptionTypesConst.PAID_STARTER.code, false);
    const isSubscriptionBusiness = guard(() => subscriptionConfig.subscriptionType === subscriptionTypesConst.BUSINESS.code, false);
    const isSubscriptionMonthly = guard(() => subscriptionConfig.subscriptionPeriod === subscriptionPeriodsConst.MONTHLY.code, true);
    const isSubscriptionYearly = guard(() => subscriptionConfig.subscriptionPeriod === subscriptionPeriodsConst.YEARLY.code, true);
    const isCommissionEnabled = guard(() => subscriptionConfig.isCommissionEnabled, false);
    const isMicrositeEnabled = guard(() => subscriptionConfig.isMicrositeEnabled, false);
    const isCustomerEnabled = guard(() => subscriptionConfig.isCustomerEnabled, false);
    const expiryDate = guard(() => subscriptionConfig.expiryDate);
    const expiryDateDisplay = guard(() => (isSubscriptionStarter ? addMoment(expiryDate, 1, 'day') : expiryDate));

    return {
      hasSubscriptionConfig,
      isSubscriptionStarter,
      isSubscriptionPaidStarter,
      isSubscriptionBusiness,
      isSubscriptionMonthly,
      isSubscriptionYearly,
      isCommissionEnabled,
      isMicrositeEnabled,
      isCustomerEnabled,
      expiryDate,
      expiryDateDisplay
    };
  }, [subscriptionTypesConst, subscriptionPeriodsConst, subscriptionConfig]);

  const { isLoading: isLoadingOrderLimitDetails, data: orderLimitDetails } = useFetchStoreStarterOrderLimitDetails(
    formattedSubscriptionConfig.isSubscriptionStarter
  );

  const formattedOrderLimitDetails = useMemo(() => {
    const { canCreateOrder = true, totalOrder: starterTotalOrder = 0, starterOrderLimit = 0 } = orderLimitDetails || {};

    return { isLoadingOrderLimitDetails, canCreateOrder, starterTotalOrder, starterOrderLimit };
  }, [isLoadingOrderLimitDetails, orderLimitDetails]);

  const newSubscriptionConfig = useMemo(() => {
    return {
      ...subscriptionConfig,
      ...formattedSubscriptionConfig,
      ...formattedOrderLimitDetails
    };
  }, [formattedOrderLimitDetails, formattedSubscriptionConfig, subscriptionConfig]);

  return newSubscriptionConfig;
};

/* ==================================================== Main Function ==================================================== */
export const AppContextProvider = ({ children }) => {
  const history = useHistory();
  const theme = useTheme();
  useListenBroadcastLogout();

  const [user, setUser] = useState({});
  const [token, setToken] = useState('');
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isFbTokenValid, setIsFbTokenValid] = useState();

  const { data: storeOperatorRoles, isLoading: isLoadingRoles } = useFetchConstant('storeOperatorRoles');
  const { data: subscriptionTypesConst } = useFetchConstant('subscriptionTypes', { extraCustomKey: 'AppContext' });
  const { data: subscriptionPeriodsConst } = useFetchConstant('subscriptionPeriods', { extraCustomKey: 'AppContext' });
  const { data: currenciesConst } = useFetchConstant('currencies', { extraCustomKey: 'AppContext' });
  const { isLoading: isLoadingStore, data: currentUserStore, refetch: refetchStore } = useGetCurrentUserStore(!!user._id);

  const { isMobile } = useWindowSize();

  const storeCurrency = useMemo(
    () => ({
      iso: guard(() => currentUserStore.currency.iso, ''),
      symbol: guard(() => currentUserStore.currency.symbol, '')
    }),
    [currentUserStore]
  );
  const isStoreConnectedToFb = useMemo(() => guard(() => !!currentUserStore.socialMedia.fb, false), [currentUserStore.socialMedia]);
  const isStoreConnectedToIg = useMemo(() => guard(() => !!currentUserStore.socialMedia.ig, false), [currentUserStore.socialMedia]);
  const isMalaysiaLocation = guard(() => storeCurrency.iso === currenciesConst.MYR.iso, false);

  const { isLoading: isLoadingAppContext } = useIsLoadings([isLoadingUser, isLoadingRoles]);

  const isLoggedIn = useMemo(() => !!user && !!user.username, [user]);

  const subscriptionConfig = useSubscriptionConfig(subscriptionTypesConst, subscriptionPeriodsConst, currentUserStore.subscriptionConfig);
  const newStore = useMemo(() => {
    return { ...currentUserStore, subscriptionConfig };
  }, [currentUserStore, subscriptionConfig]);

  const checkCanUserAccess = accessRole => {
    if (!getIsUserLoggedIn()) return false;
    if (user.role === storeOperatorRoles.OWNER.code) return true;
    return !accessRole || accessRole.includes(user.role);
  };

  const fetchFbConnectionStatus = useCallback(() => {
    return getCheckIsFbConnected()
      .then(() => {
        setIsFbTokenValid(true);
      })
      .catch(() => {
        setIsFbTokenValid(false);
      });
  }, []);

  const onLogin = useCallback(
    async ({ username, password }) => {
      const userData = await postLogin(username, password);

      setUserAuthObj(userData);
      updateApiHeaders(); // update API header ASAP to ensure subsequent API call is updated
      setUser(userData.user);
      addErrAndPerfUserContext({ id: userData.user._id, username: userData.user.username });

      await fetchFbConnectionStatus();

      return userData;
    },
    [fetchFbConnectionStatus]
  );

  const onLogout = useCallback(() => {
    logoutUser();
    setUser({});
    updateApiHeaders();
    history.push(getLoginRoute().path);
    setIsFbTokenValid();

    removeErrAndPerfUserContext();
    posthog.reset();
  }, [history]);

  const mountUserData = useCallback(async () => {
    if (getIsUserLoggedIn()) {
      await postVerify()
        .then(() => {
          const storedToken = getAuthToken();
          const storedUser = getUser();
          setUser(storedUser);
          setToken(storedToken);

          window.dataLayer.push({
            event: 'user_load',
            username: `${storedUser.username}`
          });

          addErrAndPerfUserContext({ id: storedUser._id, username: storedUser.username });
          fetchFbConnectionStatus();
        })
        .catch(() => {
          onLogout();
          message.error('Your session has expired. Please log in again.');
          history.push(getLoginRoute().path);
        });
    }
    setIsLoadingUser(false);
  }, [history, onLogout, fetchFbConnectionStatus]);

  useEffect(() => {
    mountUserData();
  }, [mountUserData]);

  useEffect(() => {
    if (!isLoadingUser && !isLoadingStore) {
      posthog.identify(
        user.username, // Required. Replace 'distinct_id' with your user's unique identifier
        { storeShortName: newStore.shortName }
      );
    }
  }, [user, newStore, isLoadingUser, isLoadingStore]);

  return (
    <AppContext.Provider
      value={{
        user,
        token,
        store: newStore,
        storeCurrency,
        refetchStore,
        onLogin,
        onLogout,
        isMobile,
        isLoggedIn,
        isLoadingAppContext,
        isStoreLoading: isLoadingStore,
        isFbTokenValid,
        isStoreConnectedToFb,
        isStoreConnectedToIg,
        isMalaysiaLocation,
        refetchFbConnectionStatus: fetchFbConnectionStatus,
        checkCanUserAccess,
        storeOperatorRoles
      }}
    >
      <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
    </AppContext.Provider>
  );
};

export const AppContextConsumer = AppContext.Consumer;

export const withAppContext = Component => {
  const AppContextComponent = props => <AppContextConsumer>{appContextProps => <Component {...appContextProps} {...props} />}</AppContextConsumer>;
  return AppContextComponent;
};
