import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { withAppContext } from 'contexts/AppContext/AppContext';
import { withFbContext, FB_LOGIN_STATUS_CONNECTED } from 'contexts/FbContext/FbContext';

const SCOPES = {
  public_profile: 'To read seller basic profile information',
  email: 'To read seller email',
  pages_show_list: "To read seller's managing pages",
  pages_read_engagement: "To read live videos of the seller's page",
  pages_manage_metadata: 'To receive webhooks from FB',
  pages_read_user_content: 'To read comment with more details from commenter',
  pages_messaging: 'To send private replies to buyer when comment',
  pages_manage_engagement: 'To allow seller to post comment from system',
  business_management: 'To manage pages that connected with a business account',
  instagram_basic: 'Basic Instagram',
  instagram_manage_comments: 'To read comments from webhook and post comments for static posts',
  instagram_manage_messages: 'To private reply customers'
};

const scopesInString = Object.keys(SCOPES).join(',');

const SocialMediaLogin = ({ store, isFbLoaded, isFbUserConnected, loginFb, onFbUserConnected, isDisabledEdit }) => {
  const { t } = useTranslation(['common', 'compSocialMediaLogin']);

  const handleOnFbLoginButtonClick = e => {
    e.preventDefault();
    loginFb(
      response => {
        if (response.status === FB_LOGIN_STATUS_CONNECTED) {
          onFbUserConnected();
        }
      },
      {
        scope: scopesInString,
        return_scopes: true
      }
    );
  };

  return isFbLoaded ? (
    <Button type="primary" onClick={handleOnFbLoginButtonClick} block disabled={isDisabledEdit}>
      {!isFbUserConnected ? t('compSocialMediaLogin:button-connect-text') : t('compSocialMediaLogin:button-reconnect-text')}
    </Button>
  ) : (
    <div>{t('compSocialMediaLogin:loading-text')}</div>
  );
};

export default withAppContext(withFbContext(SocialMediaLogin));
