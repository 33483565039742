import styled from '@emotion/styled';
import { Row, Tabs } from 'antd';

import BGImg from './bg-image.png';

export const TitleContainer = styled(Row)`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const TabsContainer = styled(Tabs)`
  width: 100%;
`;

export const PlaceholderBgContainer = styled.div`
  background-image: url(${BGImg});
  width: 100%;
  height: calc(100vh - 118px); // NOTE: 118px = header height + footer height
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(8px);
`;

export const PlaceholderMessageContainer = styled.div`
  background-color: ${props => props.theme.color.lighterTertiary}; /* Fallback color */
  background-color: ${props => props.theme.color.lighterTertiary}99; /* Primary color w/opacity/see-through */
  color: ${props => props.theme.color.tertiary};
  font-size: 16px;
  border: 3px solid ${props => props.theme.color.tertiary};
  position: absolute;
  top: 20vh;
  width: 80%;
  padding: 20px;
  text-align: center;

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 1200px) {
    width: 30%;
  }
`;

export const PlaceholderMessageSignature = styled.span`
  font-family: ${props => props.theme.fontFamily.title};
  font-weight: bold;
`;
