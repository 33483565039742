import { useEffect, useState } from 'react';
import { CheckOutlined, DeleteOutlined, ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Modal, message, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import FormCheckboxToggle from 'components/Checkbox/FormCheckboxToggle/FormCheckboxToggle';
import FormDatePicker from 'components/FormDatePicker/FormDatePicker';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';

import { deletePromotion, postCreatePromotion, putPromotion, useRefetchPromotions } from 'apis/promotion';

import { useFetchConstant } from 'hooks/constants';

import { getPromotionDetailRoute, getPromotionsRoute } from 'utils/routes';

import { SuccessIcon } from './GeneralSettings.styles';

const { useForm } = Form;
const { confirm, error } = Modal;

const GeneralSettings = ({
  isEditMode,
  promotion,
  refetchPromotionDetails,
  promotionTypes,
  promotionTypesConst,
  promotionStatuses,
  promotionStatusesConst
}) => {
  const { t } = useTranslation(['common', 'pagePromotionDetails']);
  const history = useHistory();
  const [form] = useForm();
  const { id: promotionId } = useParams();
  const [selectedUnit, setSelectedUnit] = useState('');
  const [isMinSpendingAmountChecked, setIsMinSpendingAmountChecked] = useState(false);
  const [isMaxDiscountAmountChecked, setIsMaxDiscountAmountChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const refetchPromotions = useRefetchPromotions();
  const { selection: discountUnits, data: discountUnitsConst, isLoading: isDiscountUnitsLoading } = useFetchConstant('discountUnits');

  const isLoading = isDiscountUnitsLoading;
  const isUnitAmount = !isLoading && selectedUnit === discountUnitsConst.AMOUNT.code;

  useEffect(() => {
    if (!isLoading && !isInit) {
      if (!isEditMode) {
        form.setFieldsValue({
          type: promotionTypesConst.VOUCHER.code,
          status: promotionStatusesConst.ACTIVE.code,
          unit: discountUnitsConst.AMOUNT.code
        });
        setSelectedUnit(discountUnitsConst.AMOUNT.code);
        setIsInit(true);
      } else if (promotion) {
        const unit = promotion.discountAmount ? discountUnitsConst.AMOUNT.code : discountUnitsConst.PERCENTAGE.code;
        const isMinSpendingAmountChecked = !!promotion.minSpendingAmount;
        const isMaxDiscountAmountChecked = !!promotion.maxDiscountAmount;

        form.setFieldsValue({
          ...promotion,
          expiryDate: moment(promotion.expiryDate),
          unit,
          isMinSpendingAmountChecked,
          isMaxDiscountAmountChecked
        });
        setSelectedUnit(unit);
        setIsMinSpendingAmountChecked(isMinSpendingAmountChecked);
        setIsMaxDiscountAmountChecked(isMaxDiscountAmountChecked);
        setIsInit(true);
      }
    }
  }, [isLoading, isEditMode, isInit, form, promotion, discountUnitsConst, promotionTypesConst, promotionStatusesConst]);

  const handleOnSave = async values => {
    setIsSubmitting(true);
    const payload = {
      ...values
    };

    if (isEditMode) {
      confirm({
        title: t('common:confirm-modal-update-title'),
        icon: <ExclamationCircleOutlined />,
        okText: t('common:modal-ok-text'),
        cancelText: t('common:modal-cancel-text'),
        onOk() {
          return putPromotion(promotionId, payload)
            .then(() => {
              message.success(t('common:update-success-message'));
              refetchPromotionDetails();
            })
            .catch(ex => {
              error({
                title: ex.message
              });
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        },
        onCancel() {
          setIsSubmitting(false);
        }
      });
    } else {
      postCreatePromotion(payload)
        .then(newPromotion => {
          refetchPromotions();
          confirm({
            title: t('common:add-success-message'),
            icon: <SuccessIcon />,
            okText: t('pagePromotionDetails:confirm-modal-promotion-created-ok-button-text'),
            cancelText: t('pagePromotionDetails:confirm-modal-promotion-created-cancel-button-text'),
            onOk() {
              history.push(getPromotionDetailRoute(newPromotion._id).path);
            },
            onCancel() {
              history.push(getPromotionsRoute().path);
            }
          });
        })
        .catch(ex => {
          error({
            title: t('pagePromotionDetails:error-modal-promotion-created-message'),
            content: ex.message
          });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const handleOnDelete = () => {
    setIsSubmitting(true);
    confirm({
      title: t('common:confirm-modal-delete-title', { itemName: t('pagePromotionDetails:item-name-lowercase') }),
      content: t('common:confirm-modal-delete-content'),
      icon: <ExclamationCircleOutlined />,
      okText: t('common:modal-ok-text'),
      cancelText: t('common:modal-cancel-text'),
      onOk() {
        return deletePromotion(promotion._id)
          .then(() => {
            message.success(t('common:delete-success-message', { itemName: t('pagePromotionDetails:item-name') }));
            history.push(getPromotionsRoute().path);
          })
          .catch(ex => {
            error({
              title: ex.message
            });
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      },
      onCancel() {
        setIsSubmitting(false);
      }
    });
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  return (
    <>
      <Form form={form} scrollToFirstError={true} style={{ width: '100%' }} onFinish={handleOnSave} onFinishFailed={handleOnSaveFailed}>
        <Row gutter={[24, 24]}>
          <Col span={24} xl={12}>
            <Card>
              <Row gutter={24}>
                <Col span={24} xl={12}>
                  <FormInput
                    label={t('pagePromotionDetails:form-input-label-promotion-name')}
                    name="name"
                    placeholder={t('pagePromotionDetails:form-input-placeholder-promotion-name')}
                    requiredErrorMessage={t('pagePromotionDetails:form-input-required-message-promotion-name')}
                  />
                </Col>
                <Col span={24} xl={12}>
                  <FormInput
                    label={
                      <>
                        {t('pagePromotionDetails:form-input-label-promotion-code')}{' '}
                        <Tooltip title={t('pagePromotionDetails:form-input-help-text-promotion-code')}>
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </>
                    }
                    name="code"
                    type="luckyDrawKeyword"
                    placeholder={t('pagePromotionDetails:form-input-placeholder-promotion-code')}
                    requiredErrorMessage={t('pagePromotionDetails:form-input-required-message-promotion-code')}
                    disabled={isEditMode}
                  />
                </Col>
                <Col span={24} xl={12}>
                  <FormRadioButton
                    label={
                      <>
                        {t('pagePromotionDetails:form-input-label-promotion-type')}{' '}
                        <Tooltip
                          title={() => {
                            return (
                              <>
                                {t('pagePromotionDetails:form-input-help-text-promotion-type-title')}
                                <ul>
                                  <li>
                                    <b>{t('pagePromotionDetails:form-input-help-text-promotion-type-voucher-title')} </b>
                                    {t('pagePromotionDetails:form-input-help-text-promotion-type-voucher-desc')}
                                  </li>
                                  <li>
                                    <b>{t('pagePromotionDetails:form-input-help-text-promotion-type-promo-title')} </b>
                                    {t('pagePromotionDetails:form-input-help-text-promotion-type-promo-desc')}
                                  </li>
                                </ul>
                              </>
                            );
                          }}
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </>
                    }
                    name="type"
                    selections={promotionTypes}
                    buttonStyle="solid"
                    requiredErrorMessage={t('pagePromotionDetails:form-input-required-message-promotion-type')}
                  />
                </Col>
                <Col span={24} xl={12}>
                  <FormRadioButton
                    label={t('pagePromotionDetails:form-input-label-promotion-status')}
                    name="status"
                    selections={promotionStatuses}
                    buttonStyle="solid"
                  />
                </Col>
                <Col span={24} xl={12}>
                  <FormInputNumber
                    label={t('pagePromotionDetails:form-input-label-promotion-quantity')}
                    name="quantity"
                    requiredErrorMessage={t('pagePromotionDetails:form-input-required-message-promotion-quantity')}
                  />
                </Col>
                <Col span={24} xl={12}>
                  <FormRadioButton
                    label={t('pagePromotionDetails:form-input-label-promotion-discount-unit')}
                    name="unit"
                    value={selectedUnit}
                    selections={discountUnits}
                    buttonStyle="solid"
                    onChange={e => setSelectedUnit(e.target.value)}
                  />
                </Col>
                <Col span={24} xl={12}>
                  <FormInputNumber
                    label={
                      isUnitAmount
                        ? t('pagePromotionDetails:form-input-label-promotion-discount-amount')
                        : t('pagePromotionDetails:form-input-label-promotion-discount-percentage')
                    }
                    name={isUnitAmount ? 'discountAmount' : 'discountPercentage'}
                    type={isUnitAmount ? 'financial' : 'percentage'}
                    requiredErrorMessage={
                      isUnitAmount
                        ? t('pagePromotionDetails:form-input-required-message-promotion-discount-amount')
                        : t('pagePromotionDetails:form-input-required-message-promotion-discount-percentage')
                    }
                    maxValue={isUnitAmount ? undefined : 99}
                    minValue={0.01}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} xl={12}>
            <Card title={t('pagePromotionDetails:card-title-rule')}>
              <Row gutter={24}>
                <Col span={24} xxl={12}>
                  <FormDatePicker
                    label={
                      <>
                        {t('pagePromotionDetails:form-input-label-promotion-expiry')}{' '}
                        <Tooltip title={t('pagePromotionDetails:form-input-help-text-promotion-expiry')}>
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </>
                    }
                    name="expiryDate"
                    selections={promotionStatuses}
                    buttonStyle="solid"
                    requiredErrorMessage={t('pagePromotionDetails:form-input-required-message-promotion-expiry-date')}
                    disableDateType="past"
                  />
                </Col>
                <Col span={24} xxl={12}>
                  <FormInputNumber
                    label={t('pagePromotionDetails:form-input-label-promotion-quantity-per-customer')}
                    name="quantityPerCustomer"
                    requiredErrorMessage={t('pagePromotionDetails:form-input-required-message-promotion-quantity-per-customer')}
                    minValue={1}
                  />
                </Col>
                <Col span={24}>
                  <FormCheckboxToggle
                    name="isMinSpendingAmountChecked"
                    label={t('pagePromotionDetails:form-checkbox-label-promotion-min-spending-amount')}
                    onChange={e => setIsMinSpendingAmountChecked(e.target.checked)}
                  />
                </Col>
                {isMinSpendingAmountChecked && (
                  <Col span={24}>
                    <FormInputNumber
                      label={t('pagePromotionDetails:form-input-label-promotion-min-spending-amount')}
                      name="minSpendingAmount"
                      type="financial"
                      requiredErrorMessage={t('pagePromotionDetails:form-input-required-message-promotion-min-spending-amount')}
                    />
                  </Col>
                )}
                <Col span={24}>
                  <FormCheckboxToggle
                    name="isMaxDiscountAmountChecked"
                    label={t('pagePromotionDetails:form-checkbox-label-promotion-max-discount-amount')}
                    onChange={e => setIsMaxDiscountAmountChecked(e.target.checked)}
                  />
                </Col>
                {isMaxDiscountAmountChecked && (
                  <Col span={24}>
                    <FormInputNumber
                      label={t('pagePromotionDetails:form-input-label-promotion-max-discount-amount')}
                      name="maxDiscountAmount"
                      type="financial"
                      requiredErrorMessage={t('pagePromotionDetails:form-input-required-message-promotion-max-discount-amount')}
                    />
                  </Col>
                )}
                <Col span={24}>
                  <FormCheckboxToggle name="isStackable" label={t('pagePromotionDetails:form-checkbox-label-promotion-is-stackable')} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row gutter={8} style={{ margin: '16px 0px' }}>
          <Col>
            <Button htmlType="submit" type="primary" icon={<CheckOutlined />} loading={isSubmitting}>
              {isEditMode ? t('common:form-submit-update-button-text') : t('common:form-submit-create-button-text')}
            </Button>
          </Col>
          {isEditMode && (
            <Col>
              <Button type="ghost" icon={<DeleteOutlined />} onClick={handleOnDelete} loading={isSubmitting}>
                {t('common:form-submit-delete-button-text')}
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </>
  );
};

export default GeneralSettings;
