import styled from '@emotion/styled';
import { Modal, Row } from 'antd';

export const StyledModal = styled(Modal)`
  width: 100% !important;

  @media (min-width: 768px) {
    width: 80% !important;
  }

  @media (min-width: 1200px) {
    width: 50% !important;
  }
`;

export const InfoContainer = styled.div`
  margin-bottom: 24px;
`;

export const CommentContainer = styled(Row)`
  width: 100%;
  background-color: ${props => props.theme.color.lighterTertiary};
  padding: ${props => props.theme.spacing.regular};
  color: ${props => props.theme.color.tertiary};
  font-size: 16px;
  margin-bottom: 12px;
`;

export const ErrorText = styled.p`
  color: ${props => props.theme.color.red};
`;
