import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as AntdCheckbox } from 'antd';

import { CheckboxSelectionContainer } from './Checkbox.styles';

const CheckboxGroup = AntdCheckbox.Group;

const Checkbox = ({
  value: checkedValues,
  options,

  isDisabled = false,

  isVertical = false,

  onChange = () => {},
  updateFormValue = () => {},

  ...props
}) => {
  const handleOnChange = values => {
    onChange(values);
    updateFormValue(values);
  };

  return (
    <CheckboxGroup disabled={isDisabled} onChange={handleOnChange} value={checkedValues} {...props}>
      <CheckboxSelectionContainer isVertical={isVertical}>
        {options.map(option => {
          return (
            <AntdCheckbox key={option.value} value={option.value} style={{ marginLeft: isVertical ? 0 : undefined }}>
              {option.label || option.value}
            </AntdCheckbox>
          );
        })}
      </CheckboxSelectionContainer>
    </CheckboxGroup>
  );
};

Checkbox.propTypes = {
  value: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,

  isDisabled: PropTypes.bool,

  isVertical: PropTypes.bool,

  onChange: PropTypes.func,
  updateFormValue: PropTypes.func
};

export default Checkbox;
