import { useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { Col, Row, Modal, Form, Skeleton, message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetShipments } from 'apis/shipment';
import { patchOrderShipment, useGetOrderDetails } from 'apis/order';

import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import FormUpload from 'components/Upload/FormUpload/FormUpload';
import PhotoManager from 'components/PhotoManager/PhotoManager';

import { useFetchConstant } from 'hooks/constants';
import { StyledOrderNumber } from './OrderUpdateShipmentModal.styles';
import { withAppContext } from 'contexts/AppContext/AppContext';

const { useForm } = Form;

const OrderUpdateShipmentModal = ({ store, orderId, onCancel }) => {
  const { t } = useTranslation(['common', 'pageOrder', 'modalOrderUpdateShipment']);
  const [form] = useForm();
  const deliveryProofRef = useRef(null);
  const { isLoading: isShipmentsLoading, data: shipments } = useGetShipments();
  const { data: shipmentStatusesConst, isLoading: isShipmentStatusesLoading } = useFetchConstant('shipmentStatuses');
  const { isLoading: isOrderLoading, data: order, refetch: refetchOrder } = useGetOrderDetails(orderId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentDeliveryProof, setCurrentDeliveryProof] = useState([]);
  const shouldUsePhotoManager = order ? !order.deliveryProof : true;

  useEffect(() => {
    if (!currentDeliveryProof.length && order?.shipment?.deliveryProofV2) {
      setCurrentDeliveryProof([order.shipment.deliveryProofV2]);
    }
  }, [currentDeliveryProof, order]);

  const shipmentMethods = useMemo(
    () =>
      isShipmentsLoading || isShipmentStatusesLoading
        ? []
        : shipments
            .map(shipment => ({
              value: shipment._id,
              label: `${shipment.label} (${shipment.deliveryMethod})`,
              disabled: shipment.status !== shipmentStatusesConst.ACTIVE.code
            }))
            .sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1)),
    [isShipmentsLoading, isShipmentStatusesLoading, shipments, shipmentStatusesConst]
  );

  const updateDeliveryProof = useCallback(async () => {
    if (shouldUsePhotoManager) {
      const [deliveryProof] = await deliveryProofRef.current.update();
      return deliveryProof || null;
    }

    return null;
  }, [shouldUsePhotoManager]);

  const handleOnSave = useCallback(() => {
    setIsSubmitting(true);
    form
      .validateFields()
      .then(async values => {
        const deliveryProofV2 = await updateDeliveryProof();
        patchOrderShipment(orderId, {
          ...values.shipment,
          deliveryProofV2
        })
          .then(() => {
            message.success(t('common:update-success-message'));
            refetchOrder();
          })
          .catch(() => {
            message.error(t('common:update-fail-message'));
          });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        message.error(t('common:update-fail-message'));
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [form, updateDeliveryProof, orderId, t, refetchOrder]);

  return (
    <Modal
      visible
      width="fit-content"
      style={{ maxWidth: '60%' }}
      maskClosable={false}
      onCancel={onCancel}
      okText={t('modalOrderUpdateShipment:modal-ok-button-text')}
      okButtonProps={{ loading: isSubmitting }}
      onOk={handleOnSave}
      title={
        <div>
          {t('modalOrderUpdateShipment:modal-title')} <StyledOrderNumber>{`${order.orderNumber}`}</StyledOrderNumber>
        </div>
      }
    >
      {isOrderLoading ? (
        <Skeleton active />
      ) : (
        <Form form={form} initialValues={order}>
          <Row gutter={16}>
            <Col span={24}>
              <FormSelection
                label={t('modalOrderUpdateShipment:form-input-label-shipment-shipping-method')}
                name={['shipment', 'method']}
                selections={shipmentMethods}
                isDisabled
              />
            </Col>
            <Col span={24}>
              <FormInput
                label={t('modalOrderUpdateShipment:form-input-label-shipment-remarks')}
                name={['shipment', 'remarks']}
                placeholder={t('modalOrderUpdateShipment:form-input-placeholder-shipment-remarks')}
                type="textArea"
              />
            </Col>
            <Col span={24} md={12}>
              <FormInput label={t('modalOrderUpdateShipment:form-input-label-shipment-courier')} name={['shipment', 'courier']} />
            </Col>
            <Col span={24} md={12}>
              <FormInput
                label={t('modalOrderUpdateShipment:form-input-label-shipment-tracking-no')}
                name={['shipment', 'trackingNo']}
                placeholder={'e.g.: VNUG7G'}
              />
            </Col>
            <Col span={24} md={12}>
              <FormInput
                label={t('modalOrderUpdateShipment:form-input-label-shipment-tracking-link')}
                name={['shipment', 'trackingLink']}
                placeholder={'e.g.: https://www.tracking.my/flash/VNUG7G'}
              />
            </Col>
            <Col span={24} md={12}>
              {shouldUsePhotoManager ? (
                <PhotoManager
                  value={currentDeliveryProof}
                  onChange={setCurrentDeliveryProof}
                  label={t('modalOrderUpdateShipment:form-input-label-shipment-delivery-proof')}
                  buttonLabel={t('modalOrderUpdateShipment:form-submit-upload-button-text')}
                  fileSizeThresholdToCompressInKb={200}
                  ref={deliveryProofRef}
                  shouldRemoveDocsMarkedForDelete
                  storeId={store._id}
                />
              ) : (
                <FormUpload
                  name={['shipment', 'deliveryProof']}
                  label={t('modalOrderUpdateShipment:form-input-label-shipment-delivery-proof')}
                  buttonLabel={t('modalOrderUpdateShipment:form-submit-upload-button-text')}
                  acceptTypes={['image/*']}
                  fileSizeLimitInMb={10}
                  isDisplayCard
                />
              )}
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default withAppContext(OrderUpdateShipmentModal);
