import { CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Badge, Button, Card, Col, Divider, List, Row } from 'antd';

const { Ribbon } = Badge;

const addOnApplyStatus = {
  selected: { borderColor: '#FF7212', backgroundColor: '#FFFFFF', color: '#FF7212' },
  default: { borderColor: '#FF7212', backgroundColor: '#FF7212', color: '#FFFFFF' },
  disabled: { borderColor: '#F5F5F5', backgroundColor: '#F5F5F5', color: '#C1C1C1' }
};

export const PlanCardContainer = styled.div`
  height: 100%;

  .ant-ribbon-wrapper {
    // Have to force the ribbon wrapper to have full height for card height consistency
    height: 100%;
  }
`;

export const PlanCardBadgeRibbon = styled(Ribbon)`
  display: ${props => (props.shouldDisplay ? 'block' : 'none')};
`;

export const PlanCardContentRow = styled(Row)`
  justify-content: space-between;
  align-content: space-between;
  height: 100%;
`;

export const PlanCardContentPaymentCol = styled(Col)`
  display: ${props => (props.shouldDisplay ? 'block' : 'flex')};
  align-items: end;
  height: 118px;
`;

export const PlanCard = styled(Card)`
  border: ${props => (props.isCurrentPlan ? `2px solid ${props.theme.color.secondary}` : `1px solid ${props.theme.color.borderColor}`)};
  background-color: ${props => (props.disabled ? '#F5F5F5' : '')};
  height: 100%;
`;

export const PlanCardBody = styled.div`
  height: 100%;
`;

export const PlanCardHeader = styled.div`
  font-weight: ${props => props.theme.fontWeight.bolder};
  font-size: 20px;
`;

export const PlanTitle = styled.p`
  margin-bottom: 0px;
  font-size: 14px;
`;

export const PlanSubsPriceText = styled.p`
  margin-bottom: 0px;
  font-size: 24px;
  color: ${props => props.theme.color.tertiary};
`;

export const PlanSubsPeriodText = styled.span`
  color: ${props => props.theme.color.grey};
  font-size: 12px;
  font-weight: normal;
`;

export const PlanDetailsPopoverList = styled(List)`
  padding: 0;
  width: 240px;
`;

export const PlanDetailsList = styled(List)`
  padding: 0;
`;

export const StyledCheckOutlined = styled(CheckOutlined)`
  color: ${props => props.theme.color.secondary};
`;

export const PlanAddOnsContainer = styled.div`
  margin: ${props => props.theme.spacing.regular} 0;
`;

export const PlanAddOnsTitle = styled.div`
  margin: 0 ${props => props.theme.spacing.xxs};
  font-weight: ${props => props.theme.fontWeight.bolder};
  font-size: 16px;
`;

export const PlanAddOnsDesc = styled.p`
  font-size: 12px;
  color: ${props => props.theme.color.grey};
`;

export const AOICCard = styled(Card)`
  border: ${props => (props.isSelected ? `2px solid ${props.theme.color.secondary}` : '2px solid #F5F5F5')};
  margin-bottom: ${props => props.theme.spacing.xxs};

  &:hover {
    border: ${props => (props.isSelected ? `2px solid ${props.theme.color.secondary}` : '2px solid #F5F5F5')};
  }
`;

export const AOICHeader = styled.div`
  align-items: top;
  display: flex;
  justify-content: space-between;
`;

export const AOICItemTitle = styled.p`
  margin: 0;
  font-weight: bold;
`;

export const AOICItemPrice = styled.p`
  color: ${props => props.theme.color.tertiary};
  margin: 0;
  font-weight: bold;
`;

export const AOICItemDivider = styled(Divider)`
  margin: ${props => props.theme.spacing.xxs} 0;
`;

export const AOICItemDesc = styled.p`
  margin: 0;
  font-size: 12px;
  color: ${props => props.theme.color.grey};
`;

export const AOICAddBtn = styled(Button)`
  font-size: 12px;
  border-radius: 4px;
  padding: ${props => props.theme.spacing.xxs} ${props => props.theme.spacing.s};
  background-color: ${props => (props.selected ? addOnApplyStatus.selected.backgroundColor : addOnApplyStatus.default.backgroundColor)}!important;
  border-color: ${props => (props.selected ? addOnApplyStatus.selected.borderColor : addOnApplyStatus.default.borderColor)}!important;
  color: ${props => (props.selected ? addOnApplyStatus.selected.color : addOnApplyStatus.default.color)}!important;
`;

export const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${props => props.theme.color.lightTertiary};
  margin-left: 4px;
`;

export const PopoverTitleText = styled.p`
  margin-bottom: 0px;
  font-weight: bold;
`;

export const PopoverContentText = styled.p`
  font-size: 12px;
`;

export const PlanCardFooterPriceDisplayContainer = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.color.borderColor};
  border-radius: 24px;
  width: 100%;
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.s};
  justify-content: space-between;
  align-items: center;

  @media (min-width: 768px) {
    padding: ${props => props.theme.spacing.xs} 24px;
  }
`;

export const PlanPriceDisplayContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const CurrencySymbolText = styled.span`
  color: ${props => props.theme.color.grey};
  font-size: 12px;
`;

export const PlanPriceAmountText = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0 ${props => props.theme.spacing.xs} 0 ${props => props.theme.spacing.xxs};
`;

export const PlanCardFooterSubsPeriodText = styled.span`
  color: ${props => props.theme.color.grey};
  font-size: 12px;
  min-width: 60px;
`;

export const PlanSelectAndPayButtonContainer = styled.div`
  width: 100%;
`;
