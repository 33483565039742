import styled from '@emotion/styled';
import { TagOutlined } from '@ant-design/icons';
import { Row } from 'antd';

export const FormContentContainer = styled(Row)`
  width: 100% !important;
  display: block;
  text-align: left;
  padding: 24px 0px 8px 0px;
`;

export const FormActionButtonsContainer = styled.div`
  text-align: right;
`;

export const TooltipIcon = styled(TagOutlined)`
  cursor: pointer;
  color: ${props => props.theme.color.lightTertiary};
`;
