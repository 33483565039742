export const ValidDropzoneStyle = `
  display: flex;
  border: 2px dashed #333;
  position: absolute;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: 1.2rem;
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
`;

export const InvalidDropzoneStyle = `
  display: flex;
  border: 2px dashed #ff1744;
  position: absolute;
  align-items: center;
  justify-content: center;
  color: #ff1744;
  font-size: 1.2rem;
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
`;
