import { useCustomQuery, useRefetchQuery } from 'hooks/reactQuery';

import api from './apiHelper';

export const postLuckyDrawSession = (postId, payload) => {
  return api.post(`/luckyDraw/post/${postId}`, payload);
};

export const postLuckyDrawExtraPlayers = (luckyDrawId, payload) => {
  return api.post(`/luckyDraw/${luckyDrawId}/players`, payload);
};

export const postLuckyDrawWinner = (luckyDrawId, { isAccepted, selectedPrizeType, prizeRemarks, prizeProductKeyword, numOfPotentialWinners }) => {
  const payload = {
    isAccepted,
    selectedPrizeType,
    prizeRemarks,
    prizeProductKeyword,
    numOfPotentialWinners
  };

  return api.post(`/luckyDraw/${luckyDrawId}/winner`, payload);
};

export const postLuckyDrawConfig = (luckyDrawId, { numOfPotentialWinners }) => {
  const payload = { numOfPotentialWinners };
  return api.post(`/luckyDraw/${luckyDrawId}/config`, payload);
};

export const useGetLuckyDrawsByPostId = (postId, query, onError) => {
  const getLuckyDraws = async () => {
    return api.get(`/luckyDraw/post/${postId}`, {
      params: { ...query }
    });
  };

  return useCustomQuery('luckyDraws', [postId, query], getLuckyDraws, {
    onError,
    enabled: !!postId,
    shouldDefaultEmptyObject: false
  });
};

export const useGetLuckyDrawById = (luckyDrawId, query, onError) => {
  const getLuckyDraw = async () => {
    return api.get(`/luckyDraw/${luckyDrawId}`, {
      params: { ...query }
    });
  };

  return useCustomQuery('luckyDraw', [luckyDrawId, query], getLuckyDraw, {
    onError,
    enabled: !!luckyDrawId
  });
};

export const useRefetchLuckyDraw = () => {
  const { refetch } = useRefetchQuery(['luckyDraw']);

  return refetch;
};

export const useGetLuckyDrawMetaDataById = (luckyDrawId, onError) => {
  const getLuckyDrawMetaData = async () => {
    if (!!luckyDrawId) {
      // NOTE: This is due to lucky draw id might be undefined when refetch
      return api.get(`/luckyDraw/${luckyDrawId}/metadata`);
    }
  };

  return useCustomQuery('luckyDrawMetaData', [luckyDrawId], getLuckyDrawMetaData, {
    onError,
    enabled: !!luckyDrawId
  });
};

export const useGetLuckyDrawByIdForSlotMachine = (luckyDrawId, query, { onError, postProcessFunc } = {}) => {
  const getLuckyDrawForSlotMachine = async () => {
    return api.get(`/luckyDraw/${luckyDrawId}/slot-machine`, {
      params: { ...query }
    });
  };

  return useCustomQuery('luckyDrawForSlotMachine', [luckyDrawId, query], getLuckyDrawForSlotMachine, {
    onError,
    postProcessFunc,
    enabled: !!luckyDrawId
  });
};

export const patchLuckyDrawStartRolling = luckyDrawId => {
  return api.patch(`/luckyDraw/${luckyDrawId}/status/rolling`);
};

export const patchLuckyDrawEnd = luckyDrawId => {
  return api.patch(`/luckyDraw/${luckyDrawId}/status/end`);
};

export const patchDeleteLuckyDrawPlayers = (luckyDrawId, deletedPlayers) => {
  return api.patch(`/luckyDraw/${luckyDrawId}/delete-players`, deletedPlayers);
};
