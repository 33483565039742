import { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Alert, Button, Card, Col, Form, Modal, message, Tooltip, Row, Skeleton, Tag } from 'antd';
import { PlusOutlined, QuestionCircleFilled } from '@ant-design/icons';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { patchStoreCustomerConfig, useGetStoreCustomerConfig } from 'apis/store';

import FormCheckboxToggle from 'components/Checkbox/FormCheckboxToggle/FormCheckboxToggle';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import CustomerTier from 'components/CustomerTier/CustomerTier';

import { SectionTitle } from './CustomerSettingModal.styles';

import { CURRENCY_FORMAT } from 'utils/constants';

const { useForm } = Form;

const DEFAULT_CUSTOMER_TIER_EMOJI = '😀';

const CustomerLoyaltySection = ({ t, storeCurrency, isAllowPointChecked, setIsAllowPointChecked }) => {
  const baseCurrencyMultiplier = CURRENCY_FORMAT[storeCurrency.iso].baseCurrencyMultiplier;
  const baseCurrencyAmount = 1 * baseCurrencyMultiplier;

  return (
    <>
      <SectionTitle>{t('pageCustomers:customer-setting-modal-title-customer-loyalty')}</SectionTitle>
      <hr style={{ borderTop: '0px' }} />
      <Row style={{ padding: '0 16px' }}>
        <Col span={24}>
          <FormCheckboxToggle
            name="isEnabled"
            label={t('pageCustomers:customer-setting-modal-label-allow-point')}
            formItemStyle={{ marginBottom: isAllowPointChecked ? 0 : '24px' }}
            onChange={e => setIsAllowPointChecked(e.target.checked)}
          />
        </Col>
        {isAllowPointChecked && (
          <Col span={24}>
            <Card style={{ marginBottom: '24px' }}>
              <Alert style={{ marginBottom: '8px' }} type="info" message={t('pageCustomers:customer-setting-modal-alert-message')} />
              <FormInputNumber
                name="earnRate"
                label={
                  <>
                    {t('pageCustomers:customer-setting-modal-label-point-earn-rate', { currency: storeCurrency.symbol })}{' '}
                    <Tooltip title={t('pageCustomers:customer-setting-modal-tooltip-message-point-earn-rate', { currency: storeCurrency.symbol })}>
                      <QuestionCircleFilled />
                    </Tooltip>
                  </>
                }
                min={1}
                requiredErrorMessage={t('pageCustomers:customer-setting-modal-required-error-message-point-earn-rate', {
                  currency: storeCurrency.symbol
                })}
              />
              <FormInputNumber
                name="redeemRate"
                label={
                  <>
                    {t('pageCustomers:customer-setting-modal-label-point-redeem-rate', {
                      currency: storeCurrency.symbol,
                      amount: baseCurrencyAmount
                    })}{' '}
                    <Tooltip
                      title={t('pageCustomers:customer-setting-modal-tooltip-message-point-redeem-rate', {
                        currency: storeCurrency.symbol,
                        amount: baseCurrencyAmount
                      })}
                    >
                      <QuestionCircleFilled />
                    </Tooltip>
                  </>
                }
                min={1}
                requiredErrorMessage={t('pageCustomers:customer-setting-modal-required-error-message-point-redeem-rate', {
                  currency: storeCurrency.symbol,
                  amount: baseCurrencyAmount
                })}
              />
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

const MembershipTierSection = ({
  t,
  selectedEmoji,
  setSelectedEmoji,
  customerTiers,
  handleOnAddCustomerTierBtnClick,
  handleOnRemoveCustomerTierBtnClick
}) => {
  const [isOpenEmojiPicker, setIsOpenEmojiPicker] = useState(false);

  const onHandleSelectEmoji = item => {
    setSelectedEmoji(item.native);
    setIsOpenEmojiPicker(false);
  };

  return (
    <>
      <SectionTitle>{t('pageCustomers:customer-setting-modal-title-membership-tier')}</SectionTitle>
      <hr style={{ borderTop: '0px' }} />
      <Alert
        type="warning"
        showIcon
        style={{ margin: '0 32px 36px' }}
        message={<b>{t('pageCustomers:customer-setting-modal-membership-tier-note-title')}</b>}
        description={
          <>
            <p>{t('pageCustomers:customer-setting-modal-membership-tier-note-desc')}</p>
            <p>
              <i>
                <Trans i18nKey="pageCustomers:customer-setting-modal-membership-tier-note-example-1">
                  <b>Example 1:</b> You may set a tier
                  <CustomerTier emoji={'⭐'} label={3} style={{ marginRight: 0 }} />
                  that indicates very good customers. You can tag this on customers that always make payment on time.
                </Trans>
              </i>
            </p>
            <i>
              <Trans i18nKey="pageCustomers:customer-setting-modal-membership-tier-note-example-2">
                <b>Example 2:</b> You may set a tier <CustomerTier emoji={'💎'} label={'VVIP'} style={{ marginRight: 0 }} /> that indicates your VVIP.
                You can tag this on customers that you consider as the top tier customers.
              </Trans>
            </i>
          </>
        }
      />
      <Row style={{ padding: '0 16px' }}>
        <Col span={24}>
          <Row gutter={16}>
            <Col>
              <Button style={{ borderRadius: 4 }} onClick={() => setIsOpenEmojiPicker(!isOpenEmojiPicker)}>
                {selectedEmoji}
              </Button>
              {isOpenEmojiPicker && (
                <div style={{ position: 'absolute', zIndex: 1 }}>
                  <Picker data={data} onEmojiSelect={onHandleSelectEmoji} onClickOutside={() => setIsOpenEmojiPicker(false)} />
                </div>
              )}
            </Col>
            <Col flex="auto">
              <FormInput
                name={['customerTier', 'label']}
                placeholder={t('pageCustomers:customer-setting-modal-form-field-placeholder-customer-tier')}
                extraProps={{ onPressEnter: handleOnAddCustomerTierBtnClick }}
              />
            </Col>
            <Col>
              <Button icon={<PlusOutlined />} onClick={handleOnAddCustomerTierBtnClick}>
                {t('pageCustomers:customer-setting-modal-button-add-customer-tier')}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {customerTiers.map(customerTier => {
            return (
              <Tag closable onClose={() => handleOnRemoveCustomerTierBtnClick(customerTier)}>
                {customerTier.emoji} {customerTier.label}
              </Tag>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

const CustomerSettingModal = ({ storeCurrency, onOk, onCancel }) => {
  const { t } = useTranslation(['common', 'pageCustomers']);
  const [form] = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAllowPointChecked, setIsAllowPointChecked] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(DEFAULT_CUSTOMER_TIER_EMOJI);
  const [customerTiers, setCustomerTiers] = useState([]);

  const { isLoading, data: customerConfig } = useGetStoreCustomerConfig();

  useEffect(() => {
    if (isLoading) return;
    if (customerConfig.pointConfig) {
      form.setFieldsValue(customerConfig.pointConfig);
      if (customerConfig.pointConfig && customerConfig.pointConfig.isEnabled) {
        setIsAllowPointChecked(true);
      }
    }

    if (customerConfig.customerTiers) {
      setCustomerTiers(customerConfig.customerTiers);
    }
  }, [isLoading, form, customerConfig]);

  const handleOnSave = e => {
    e.preventDefault();
    setIsSubmitting(true);

    form
      .validateFields()
      .then(values => {
        patchStoreCustomerConfig({ pointConfig: values, customerTiers })
          .then(() => {
            onOk();
            message.success(t('common:update-success-message'));
          })
          .catch(ex => {
            message.error(ex.message);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        setIsSubmitting(false);
      });
  };

  const handleOnAddCustomerTierBtnClick = () => {
    const newCustomerTierLabel = form.getFieldValue(['customerTier', 'label']);

    if (newCustomerTierLabel) {
      const isDuplicatedTier = customerTiers.find(
        customerTier => customerTier.emoji === selectedEmoji && customerTier.label.toLowerCase() === newCustomerTierLabel.toLowerCase()
      );

      if (isDuplicatedTier) {
        return message.error(t('pageCustomers:customer-setting-modal-error-message-duplicated-tier'));
      }

      const newCustomerTiers = [...customerTiers, { emoji: selectedEmoji, label: newCustomerTierLabel }];
      setCustomerTiers(newCustomerTiers);

      setSelectedEmoji(DEFAULT_CUSTOMER_TIER_EMOJI);
      form.setFieldsValue({ customerTier: { label: undefined } });
    }
  };

  const handleOnRemoveCustomerTierBtnClick = customerTierToRemove => {
    const newCustomerTiers = customerTiers.filter(customerTier => {
      if (customerTierToRemove._id) return customerTier?._id !== customerTierToRemove._id;
      return !(customerTier.emoji === customerTierToRemove.emoji && customerTier.label.toLowerCase() === customerTierToRemove.label.toLowerCase());
    });
    setCustomerTiers(newCustomerTiers);
  };

  return (
    <Modal
      visible
      width="90%"
      style={{ maxWidth: '768px' }}
      title={t('pageCustomers:customer-setting-modal-title')}
      okText={t('common:form-submit-create-button-text')}
      cancelText={t('common:modal-cancel-text')}
      onOk={handleOnSave}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      cancelButtonProps={{ loading: isSubmitting }}
      closable={!isSubmitting}
      maskClosable={!isSubmitting}
    >
      {isLoading ? (
        <Skeleton loading />
      ) : (
        <Form form={form} scrollToFirstError={true} style={{ width: '100%' }}>
          <CustomerLoyaltySection
            t={t}
            storeCurrency={storeCurrency}
            isAllowPointChecked={isAllowPointChecked}
            setIsAllowPointChecked={setIsAllowPointChecked}
          />
          <MembershipTierSection
            t={t}
            selectedEmoji={selectedEmoji}
            setSelectedEmoji={setSelectedEmoji}
            customerTiers={customerTiers}
            handleOnAddCustomerTierBtnClick={handleOnAddCustomerTierBtnClick}
            handleOnRemoveCustomerTierBtnClick={handleOnRemoveCustomerTierBtnClick}
          />
        </Form>
      )}
    </Modal>
  );
};

export default CustomerSettingModal;
