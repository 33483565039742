import { useMemo, useState } from 'react';
import { CloseOutlined, EditOutlined, TagsOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Skeleton, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { patchCancelOrder, patchOrderStatus, useGetOrderDetails, useRefetchOrders, patchConfirmPaymentOrder } from 'apis/order';

import CancelOrderModal from 'components/CancelOrderModal/CancelOrderModal';
import OrderDisplay from 'components/OrderDisplay/OrderDisplay';
import Title from 'components/Title/Title';
import StatusUpdateModal from 'components/StatusUpdateModal/StatusUpdateModal';

import { useFetchConstant } from 'hooks/constants';
import { getOrderDetailRoute } from 'utils/routes';
import { guard } from 'utils/general';

import {
  CancelOrderButton,
  CancelledIcon,
  CompletedIcon,
  ContentContainer,
  RootModal,
  UpdateStatusButton
} from './OrderSummaryAndProductsModal.styles';

const OrderSummaryAndProductsModal = ({
  orderId,
  onCancel,
  isViewProductsOnly,
  postId,
  storeSecurityConfig,
  storeCustomWAMessage,
  hasActionAccess = true,
  hasInvoiceAccess = true,
  isEmailCheckoutLinkEnabled = false
}) => {
  const { t } = useTranslation(['common', 'pageOrder', 'modalViewOrderSummary']);
  const history = useHistory();
  const [isUpdateStatusClicked, setIsUpdateStatusClicked] = useState(false);
  const [isCancelOrderClicked, setIsCancelOrderClicked] = useState(false);
  const [isUpdatingOrderStatus, setIsUpdatingOrderStatus] = useState(false);
  const [isCancellingOrder, setIsCancellingOrder] = useState(false);
  const { isLoading: isOrderLoading, data: order, refetch: refetchOrder } = useGetOrderDetails(orderId);
  const refetchOrders = useRefetchOrders();

  const { selection: orderStatuses, data: orderStatusesConst, isLoading: isOrderStatusesLoading } = useFetchConstant('orderStatuses');
  const { selection: paymentMethods, isLoading: isPaymentMethodsLoading } = useFetchConstant('paymentMethods');
  const { isLoading: isCountriesLoading, selection: countries } = useFetchConstant('countries', {
    labelKey: 'name',
    valueKey: 'iso2'
  });
  const { data: productTypesConst, isLoading: isProductTypesLoading } = useFetchConstant('productTypes');
  const { data: socialMediaSourcesConst, isLoading: isSocialMediaSourcesLoading } = useFetchConstant('socialMediaSources');

  const isLoading =
    isOrderLoading || isOrderStatusesLoading || isPaymentMethodsLoading || isCountriesLoading || isProductTypesLoading || isSocialMediaSourcesLoading;
  const isRequirePasscode = guard(() => order.status === orderStatusesConst.COMPLETED.code && storeSecurityConfig.hasSecurityPasscode);
  const shouldActionButtonsDisplay = useMemo(() => !!orderId && !isLoading && order.status !== orderStatusesConst.CANCELLED.code && hasActionAccess, [
    orderId,
    order,
    orderStatusesConst,
    isLoading,
    hasActionAccess
  ]);

  const getTitleIcon = () => {
    if (isOrderStatusesLoading) {
      return;
    }
    switch (order.status) {
      case orderStatusesConst.COMPLETED.code:
        return <CompletedIcon />;
      case orderStatusesConst.CANCELLED.code:
        return <CancelledIcon />;
      default:
    }
    if (order.isPreserved || (order.status === orderStatusesConst.CHECKOUT.code && order.isPreservePending)) {
      return (
        <Tooltip title={t('pageOrder:order-preserved-icon-tooltip-message')} placement="top">
          <DollarCircleOutlined style={{ color: '#ffba00' }} />
        </Tooltip>
      );
    }
  };

  const handleOnCloseStatusModalClicked = () => {
    setIsUpdateStatusClicked(false);
  };

  const handleOnSubmitStatusModalClicked = payload => {
    const { isSettleBalanceChecked, ...otherPayload } = payload;

    setIsUpdatingOrderStatus(true);
    patchOrderStatus(order._id, otherPayload)
      .then(async () => {
        setIsUpdatingOrderStatus(false);
        if ([orderStatusesConst.PAID.code, orderStatusesConst.COMPLETED.code].includes(otherPayload.status)) {
          await patchConfirmPaymentOrder(order._id, { isSettleBalanceChecked });
        }
        message.success(t('pageOrder:modal-update-status-success-message'));
        refetchOrder();
        refetchOrders();
        setIsUpdateStatusClicked(false);
      })
      .catch(ex => {
        setIsUpdatingOrderStatus(false);
        message.error(t('common:update-fail-message'));
      });
  };

  const handleOnConfirmCancelOrder = payload => {
    setIsCancellingOrder(true);
    patchCancelOrder(orderId, payload)
      .then(() => {
        message.success(t('pageOrder:modal-cancel-order-success-message'));
        setIsCancellingOrder(false);
        refetchOrder();
        refetchOrders();
        setIsCancelOrderClicked(false);
      })
      .catch(ex => {
        message.error(t('common:update-fail-message'));
        setIsCancellingOrder(false);
      });
  };

  const handleOnClickEditOrderButton = () => {
    if (!!postId) {
      history.push({ pathname: getOrderDetailRoute(orderId).path, state: { postId } });
    } else {
      history.push(getOrderDetailRoute(orderId).path);
    }
  };

  return (
    <RootModal
      visible={true}
      onCancel={onCancel}
      style={{ top: 40 }}
      bodyStyle={{ overflowX: 'scroll' }}
      footer={
        shouldActionButtonsDisplay ? (
          <Row justify="end" gutter={[8, 8]}>
            {order.status !== orderStatusesConst.COMPLETED.code && (
              <Col>
                <CancelOrderButton icon={<CloseOutlined />} onClick={() => setIsCancelOrderClicked(true)}>
                  {t('modalViewOrderSummary:modal-view-summary-cancel-button-text')}
                </CancelOrderButton>
              </Col>
            )}
            <Col>
              <UpdateStatusButton onClick={() => setIsUpdateStatusClicked(true)} icon={<TagsOutlined />}>
                {t('modalViewOrderSummary:modal-view-summary-status-button-text')}
              </UpdateStatusButton>
            </Col>
            {order.status !== orderStatusesConst.COMPLETED.code && (
              <Col>
                <Button type="primary" onClick={handleOnClickEditOrderButton} icon={<EditOutlined />}>
                  {t('modalViewOrderSummary:modal-view-summary-ok-button-text')}
                </Button>
              </Col>
            )}
          </Row>
        ) : null
      }
      title={
        !isLoading && (
          <Title>
            {!isOrderStatusesLoading && getTitleIcon()}
            {` ${order.orderNumber}`}
          </Title>
        )
      }
    >
      {isLoading && !isCancelOrderClicked ? (
        <Skeleton active />
      ) : (
        <ContentContainer>
          <OrderDisplay
            order={order}
            orderStatuses={orderStatuses}
            paymentMethods={paymentMethods}
            countries={countries}
            productTypesConst={productTypesConst}
            socialMediaSourcesConst={socialMediaSourcesConst}
            storeCustomWAMessage={storeCustomWAMessage}
            isViewProductsOnly={isViewProductsOnly}
            isAllowPrintInvoice={hasInvoiceAccess}
            isAllowDisplayCheckoutLink={hasActionAccess}
            isEmailCheckoutLinkEnabled={isEmailCheckoutLinkEnabled}
          />
        </ContentContainer>
      )}
      {isUpdateStatusClicked && (
        <StatusUpdateModal
          isLoading={isUpdatingOrderStatus}
          order={order}
          onCancel={handleOnCloseStatusModalClicked}
          onOk={handleOnSubmitStatusModalClicked}
          isRequirePasscode={isRequirePasscode}
        />
      )}
      {isCancelOrderClicked && (
        <CancelOrderModal
          isLoading={isCancellingOrder}
          order={order}
          onCancel={() => setIsCancelOrderClicked(false)}
          onOk={handleOnConfirmCancelOrder}
        />
      )}
    </RootModal>
  );
};

export default OrderSummaryAndProductsModal;
