import { Empty, Image } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { checkIsArrayEmpty, formatReceipts, guard } from 'utils/general';

import { ImageContainer, ImageFilesContainer, PDFLabel, RootContainer } from './Files.styles';

const Files = ({ files, shouldShowEmpty, showIcon, photoSize = 32, hasDifferentSizes }) => {
  const { imageFiles, pdfFiles } = formatReceipts(files);

  return (
    <>
      {guard(() => files.length > 0) ? (
        <RootContainer>
          {!checkIsArrayEmpty(imageFiles) && (
            <ImageFilesContainer>
              {imageFiles.map(imageFile => (
                <ImageContainer key={`${imageFile.uid}`}>
                  <Image width={photoSize} src={imageFile.thumbnail?.url} preview={{ src: imageFile.original?.url }} />
                </ImageContainer>
              ))}
            </ImageFilesContainer>
          )}
          {pdfFiles.map(pdfFile => (
            <a key={pdfFile.uid} href={pdfFile.original.url} target="_blank" rel="noopener noreferrer">
              <PDFLabel>
                {showIcon && <PaperClipOutlined />} {pdfFile.name}
              </PDFLabel>
            </a>
          ))}
        </RootContainer>
      ) : (
        <>{shouldShowEmpty && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}</>
      )}
    </>
  );
};

Files.propTypes = {
  files: PropTypes.array,
  shouldShowEmpty: PropTypes.bool,
  showIcon: PropTypes.bool
};

Files.defaultProps = {
  files: undefined,
  shouldShowEmpty: true,
  showIcon: true
};

export default Files;
