import styled from '@emotion/styled';
import { Alert, Row } from 'antd';

export const CardTitle = styled.span`
  color: ${props => props.theme.color.tertiary};
`;

export const CursiveText = styled.span`
  font-family: Pacifico, 'cursive';
  font-size: ${props => (props.bigger ? '24px' : '16px')};
`;

export const RootContainer = styled(Row)`
  width: 90%;
  margin: ${props => props.theme.spacing.md} auto;

  @media (min-width: 768px) {
    width: 70%;
  }
  @media (min-width: 992px) {
    width: 60%;
  }
  @media (min-width: 1440px) {
    width: 40%;
  }
`;

export const FullWidthLogoRow = styled(Row)`
  width: 100%;
`;

export const DescContainer = styled(Row)`
  width: 100%;
  margin-top: ${props => props.theme.spacing.regular};
`;

export const DescLastLineContainer = styled(Row)`
  width: 100%;
  margin-bottom: 24px;
`;

export const Description = styled.span`
  color: white;
  font-size: 16px;
  text-align: center;
`;

export const ModalSuccessMessageSignature = styled.p`
  margin: 16px 0 4px 0;
`;

export const StoreShortNameAlert = styled(Alert)`
  margin-bottom: 24px;
`;
