import styled from '@emotion/styled';
import { Col } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

export const StatusIndicatorContainer = styled.span`
  margin-left: ${props => props.theme.spacing.regular};
`;

export const SuccessIcon = styled(CheckCircleOutlined)`
  color: #52c41a !important;
`;

export const DangerUpdateContentCol = styled(Col)`
  margin-top: 16px;
  color: #f5222d;
`;
