import styled from '@emotion/styled';
import { Layout, Row } from 'antd';

const { Header, Footer, Content } = Layout;

// 140deg, #090722, #2c278a, #6561ac - blue based
// #03a9f4, #ff7410, #5b53ff - colorful
// #f27121,#FF7212,#FFBA00 - orange based
export const GradientBgContainer = styled.div`
  /* background: linear-gradient(140deg, #090722, #2c278a, #2196f3); */
  background: linear-gradient(140deg, #03a9f4, #ff7410, #5b53ff);
  height: 100vh;
  padding: 0 10% 10%;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 100vh;
  }
`;

export const StyledLayout = styled(Layout)`
  background-color: transparent;
`;

export const StyledHeader = styled(Header)`
  background: transparent;
  color: #ffffff;
  padding: 0;
`;

export const HeaderLogo = styled.img`
  width: auto;
  height: 40px;

  @media (min-width: 768px) {
    height: 48px;
  }
`;

export const StyledContent = styled(Content)`
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 8px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

  display: block;
  height: calc(100vh - 204px);
  padding: 8vw;
  width: 100%;

  .ant-carousel {
    height: 100% !important;
  }

  .slick-slider {
    height: 100% !important;
  }

  .slick-dots {
    @media (min-width: 1200px) {
      margin-left: -4vw;
    }
  }

  @media (min-width: 768px) {
    padding: 4vw;
  }

  @media (min-width: 820px) {
    padding: 8vh 8vw 4vh 8vw;
  }

  @media (min-width: 992px) {
    height: calc(100vh - 134px);
  }

  @media (min-width: 1200px) {
    padding: 8vh 0vw 8vh 8vw;
  }
`;

export const StyledFooter = styled(Footer)`
  background: transparent;
  color: #ffffff;
  padding: 24px 0 16px 0;
  height: 140px;

  display: flex;
  align-items: end;

  @media (min-width: 992px) {
    height: 80px;
  }
`;

export const FooterStyledRow = styled(Row)`
  width: 100%;
  margin: 4vh 0 0 0;
`;

export const FooterCopyrightText = styled.span`
  font-size: 0.7em;

  @media (min-width: 390px) {
    font-size: 0.8em;
  }

  @media (min-width: 992px) {
    font-size: 1em;
  }
`;

export const CTABtn = styled.span`
  cursor: pointer;

  &:hover {
    padding-bottom: 4px;
    border-bottom: 1px solid #ffffff;
  }
`;

export const CTALink = styled.a`
  color: white !important;

  &:hover {
    padding-bottom: 4px;
    border-bottom: 1px solid #ffffff;
  }
`;

export const FooterFireIcon = styled.span`
  font-size: 1.5em;
  line-height: 0.8em;
  margin-right: 4px;
`;
