import { EyeOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, message, Row, Space, Tooltip, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { postLuckyDrawSession, postLuckyDrawExtraPlayers, useGetLuckyDrawsByPostId, useGetLuckyDrawMetaDataById } from 'apis/luckyDraw';
import { useFetchConstant } from 'hooks/constants';
import { getLuckyDrawSlotMachineRoute } from 'utils/routes';

import {
  Container,
  LuckyDrawButton,
  LuckyDrawIcon,
  LuckyDrawSessionBanner,
  LDSBannerMainText,
  LDSBannerNoOfParticipantsText,
  LDSBannerRollNowText,
  LDSBannerAddParticipantsText
} from './LuckyDrawSession.styles';
import LuckyDrawDetailsModal from '../LuckyDrawDetailsModal/LuckyDrawDetailsModal';
import LuckyDrawPlayersModal from '../LuckyDrawPlayersModal/LuckyDrawPlayersModal';
import LuckyDrawsModal from '../LuckyDrawsModal/LuckyDrawsModal';

const { error } = Modal;

const LuckyDrawBanner = ({ luckyDrawId, totalPlayers = 0, isLuckyDrawRolling, onAddParticipantsClick, onViewParticipantsClick }) => {
  const { t } = useTranslation(['common', 'pageOverview']);
  const handleOnRollNowClick = e => {
    e.preventDefault();
    window.open(`${getLuckyDrawSlotMachineRoute(luckyDrawId).path}`, '_blank');
  };

  return (
    <LuckyDrawSessionBanner gutter={8} align="middle">
      <Col>
        <LDSBannerMainText>
          <span style={{ marginRight: '4px' }}>🎊</span>
          {` ${t('pageOverview:lucky-draw-banner-text-part-1')}`}{' '}
          <LDSBannerNoOfParticipantsText onClick={onViewParticipantsClick}>{totalPlayers}</LDSBannerNoOfParticipantsText>{' '}
          {t('pageOverview:lucky-draw-banner-text-part-2', { totalPlayers })}
        </LDSBannerMainText>
      </Col>
      <Col>
        <Row gutter={12}>
          <Col>
            <LDSBannerRollNowText onClick={handleOnRollNowClick}>
              {isLuckyDrawRolling ? `> ${t('pageOverview:lucky-draw-view-now-button')}` : `> ${t('pageOverview:lucky-draw-roll-now-button')}`}
            </LDSBannerRollNowText>
          </Col>
          <Col>
            <LDSBannerAddParticipantsText onClick={onAddParticipantsClick}>{`+ ${t(
              'pageOverview:lucky-draw-add-participants-button'
            )}`}</LDSBannerAddParticipantsText>
          </Col>
        </Row>
      </Col>
    </LuckyDrawSessionBanner>
  );
};

const LuckyDrawSection = ({ postId }) => {
  const { t } = useTranslation(['common', 'pageOverview']);
  const { data: luckyDraws, isLoading: isLuckyDrawsLoading, refetch: refetchLuckyDraws } = useGetLuckyDrawsByPostId(postId);
  const { data: luckyDrawStatusesConst, isLoading: isLuckyDrawStatusesLoading } = useFetchConstant('luckyDrawStatuses');
  const onGoingLuckyDraw = useMemo(
    () =>
      !isLuckyDrawsLoading &&
      !isLuckyDrawStatusesLoading &&
      luckyDraws &&
      luckyDraws.find(luckyDraw => luckyDraw.status !== luckyDrawStatusesConst.ENDED.code),
    [isLuckyDrawsLoading, isLuckyDrawStatusesLoading, luckyDraws, luckyDrawStatusesConst]
  );
  const {
    data: onGoingLuckyDrawMetaData,
    isLoading: isOnGoingLuckyDrawMetaDataLoading,
    refetch: refetchOnGoingLuckyDrawMetaData
  } = useGetLuckyDrawMetaDataById(onGoingLuckyDraw && onGoingLuckyDraw._id);
  const totalPlayers = useMemo(() => onGoingLuckyDrawMetaData && onGoingLuckyDrawMetaData.totalPlayers, [onGoingLuckyDrawMetaData]);
  const [isOpenLuckyDrawModal, setIsOpenLuckyDrawModal] = useState(false);
  const [isOpenParticipantsModal, setIsOpenParticipantsModal] = useState(false);
  const [isOpenViewAllLuckyDrawsModal, setIsOpenViewAllLuckyDrawsModal] = useState(false);

  const isNew = useMemo(() => !onGoingLuckyDraw, [onGoingLuckyDraw]);
  const isOnGoingLuckyDrawEnded = useMemo(
    () => !isOnGoingLuckyDrawMetaDataLoading && !isLuckyDrawStatusesLoading && onGoingLuckyDrawMetaData.status === luckyDrawStatusesConst.ENDED.code,
    [isLuckyDrawStatusesLoading, isOnGoingLuckyDrawMetaDataLoading, luckyDrawStatusesConst, onGoingLuckyDrawMetaData]
  );
  const isLoading = isLuckyDrawsLoading || isLuckyDrawStatusesLoading;

  useEffect(() => {
    if (!isOnGoingLuckyDrawMetaDataLoading && !isNew && !isOnGoingLuckyDrawEnded) {
      const refetchMetaDataTimer = setTimeout(() => {
        refetchOnGoingLuckyDrawMetaData();
      }, 5000);
      return () => clearTimeout(refetchMetaDataTimer);
    }
  }, [isNew, isOnGoingLuckyDrawEnded, isOnGoingLuckyDrawMetaDataLoading, refetchOnGoingLuckyDrawMetaData]);

  useEffect(() => {
    if (isOnGoingLuckyDrawEnded) {
      refetchLuckyDraws();
    }
  }, [isOnGoingLuckyDrawEnded, refetchLuckyDraws]);

  const handleOnStartLuckyDrawClick = useCallback(
    e => {
      if (postId) {
        setIsOpenLuckyDrawModal(true);
      } else {
        error({
          title: t('pageOverview:lucky-draw-no-live-post-error-modal-title'),
          content: t('pageOverview:lucky-draw-no-live-post-error-modal-message')
        });
      }
    },
    [postId, t]
  );

  const handleOnLuckyModalCancelClick = useCallback(e => {
    e.preventDefault();
    setIsOpenLuckyDrawModal(false);
  }, []);

  const handleOnLuckyDrawModalOkClick = useCallback(
    ({ isNew, payload, onSuccess, onFail }) => {
      if (isNew) {
        postLuckyDrawSession(postId, payload)
          .then(luckyDraw => {
            message.success(t('pageOverview:lucky-draw-create-success-message'));
            refetchLuckyDraws();
            refetchOnGoingLuckyDrawMetaData();
            setIsOpenLuckyDrawModal(false);
            onSuccess();
          })
          .catch(err => {
            message.error(err.message);
            onFail();
          });
      } else {
        postLuckyDrawExtraPlayers(onGoingLuckyDraw._id, payload)
          .then(luckyDraw => {
            message.success(t('common:update-success-message'));
            refetchLuckyDraws();
            refetchOnGoingLuckyDrawMetaData();
            setIsOpenLuckyDrawModal(false);
            onSuccess();
          })
          .catch(err => {
            message.error(err.message);
            onFail();
          });
      }
    },
    [onGoingLuckyDraw, postId, refetchLuckyDraws, refetchOnGoingLuckyDrawMetaData, t]
  );

  return (
    <Container>
      {!isNew && !isOnGoingLuckyDrawEnded ? (
        <LuckyDrawBanner
          luckyDrawId={onGoingLuckyDraw._id}
          totalPlayers={totalPlayers}
          isLuckyDrawRolling={
            onGoingLuckyDrawMetaData && luckyDrawStatusesConst && onGoingLuckyDrawMetaData.status === luckyDrawStatusesConst.ROLLING.code
          }
          onAddParticipantsClick={() => setIsOpenLuckyDrawModal(true)}
          onViewParticipantsClick={() => setIsOpenParticipantsModal(true)}
        />
      ) : (
        <Space>
          <LuckyDrawButton type="primary" icon={<LuckyDrawIcon />} onClick={handleOnStartLuckyDrawClick}>
            {t('pageOverview:lucky-draw-start-button')}
          </LuckyDrawButton>
          {luckyDraws && luckyDraws.length > 0 && (
            <Tooltip title={t('common:text-button-tooltip-message', { action: t('pageOverview:lucky-draw-view-tooltip-message') })}>
              <Button type="primary" icon={<EyeOutlined />} onClick={() => setIsOpenViewAllLuckyDrawsModal(true)} />
            </Tooltip>
          )}
        </Space>
      )}
      {isOpenLuckyDrawModal && (
        <LuckyDrawDetailsModal
          isLoading={isLoading}
          visible={isOpenLuckyDrawModal}
          luckyDrawId={onGoingLuckyDraw && onGoingLuckyDraw._id}
          onClose={handleOnLuckyModalCancelClick}
          onOk={handleOnLuckyDrawModalOkClick}
        />
      )}
      {!isLoading && isOpenViewAllLuckyDrawsModal && (
        <LuckyDrawsModal visible={isOpenViewAllLuckyDrawsModal} luckyDraws={luckyDraws} onClose={() => setIsOpenViewAllLuckyDrawsModal(false)} />
      )}
      {isOpenParticipantsModal && (
        <LuckyDrawPlayersModal
          luckyDrawId={onGoingLuckyDraw && onGoingLuckyDraw._id}
          visible={isOpenParticipantsModal}
          onClose={() => setIsOpenParticipantsModal(false)}
        />
      )}
    </Container>
  );
};

export default LuckyDrawSection;
