import styled from '@emotion/styled';
import { CheckCircleOutlined, FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import { Card, Select } from 'antd';

export const MainContainer = styled(Card)`
  margin: ${props => props.theme.spacing.regular} 0;
  border: ${props => (props.isError ? `2px solid ${props.theme.color.red}` : `1px solid ${props.theme.color.borderColor}`)};
`;

export const ErrorMessage = styled.h3`
  color: ${props => props.theme.color.red};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-align: center;
  margin-top: ${props => props.theme.spacing.xxs};
`;

export const SuccessIcon = styled(CheckCircleOutlined)`
  color: ${props => props.theme.color.green} !important;
`;

export const PlatformSelectionCard = styled(Card)`
  text-align: center;
`;

export const StyledFacebookOutlined = styled(FacebookOutlined)`
  color: ${props => props.theme.color.socialMediaFB};
  font-size: 24px;
`;

export const StyledInstagramOutlined = styled(InstagramOutlined)`
  color: ${props => props.theme.color.socialMediaIG};
  font-size: 24px;
`;

export const StyledPagesSelection = styled(Select)`
  width: 100%;
`;
