import styled from '@emotion/styled';
import { FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import { FaTiktok } from 'react-icons/fa';

export const StyledFacebookOutlined = styled(FacebookOutlined)`
  color: ${props => props.theme.color.socialMediaFB};
`;

export const StyledInstagramOutlined = styled(InstagramOutlined)`
  color: ${props => props.theme.color.socialMediaIG};
`;

export const StyledTikTokOutlined = styled(FaTiktok)`
  color: ${props => props.theme.color.socialMediaTT};
`;
