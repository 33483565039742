import { useEffect, useState, useCallback, useMemo } from 'react';
import { Button, Card, Form, List, message, Modal, Alert, Row } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import { useGetProductSuggestions } from 'apis/product';
import { postCreateLiveOrder, useRefetchPostOrders } from 'apis/order';
import { useRefetchProducts } from 'apis/product';
import { useRefetchPostStatistic } from 'apis/post';
import { useRefetchStoreOrderLimit } from 'apis/store';

import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormSelection from 'components/FormSelection/FormSelection';
import TextButton from 'components/TextButton/TextButton';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';
import QuickAddProductModal from 'components/QuickAddProductModal/QuickAddProductModal';

import { InfoContainer, CommentContainer, ErrorText, StyledModal } from './AddOrderModal.styles';

const { useForm } = Form;
const { error } = Modal;

const ERR_CODE_CREATE_ORDER_DUP = 'O-100001';

const createOrderErrorHandler = (ex, t, onAfterCreateOrder) => {
  // TODO: See how are we going to fit dynamic error with FE in the system for future usage
  // Use case: Display different error message based on locale, having metadata to display in FE too
  if (ex.code === ERR_CODE_CREATE_ORDER_DUP) {
    onAfterCreateOrder(ex.metadata.orderId);
  }

  error({
    title: t('modalOverviewCreateOrder:error-modal-order-created-message'),
    content: ex.message
  });
};

const useQuickAddProductModal = () => {
  const refetchProducts = useRefetchProducts();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newlyCreatedProductId, setNewlyCreatedProductId] = useState(null);

  const handleOnOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleOnCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOnCreated = newProduct => {
    refetchProducts();
    setNewlyCreatedProductId(newProduct._id);
    handleOnCloseModal();
  };

  return {
    showQuickAddProductModal: isModalVisible,
    newlyCreatedProductId,
    setNewlyCreatedProductId,
    onOpenQuickAddProductModal: handleOnOpenModal,
    onCloseQuickAddProductModal: handleOnCloseModal,
    handleOnProductCreated: handleOnCreated
  };
};

const AddOrderModal = ({ visible, comment, alertInfo, onClose, onSaveSuccessful }) => {
  const { t } = useTranslation(['common', 'modalOverviewCreateOrder']);
  const [form] = useForm();
  const [addedProducts, setAddedProducts] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState('');
  const delaySetSearchKeyword = useMemo(() => debounce(setSearchKeyword, 300), []);

  const { isLoading: isProductSuggestionsLoading, paginatedData: productSuggestions } = useGetProductSuggestions({
    currentPage: 1,
    limit: 100,
    filter: { label: searchKeyword },
    extraQuery: { flatVariance: true },
    sorter: { _id: -1 }
  });
  const {
    showQuickAddProductModal,
    newlyCreatedProductId,
    setNewlyCreatedProductId,
    onOpenQuickAddProductModal,
    onCloseQuickAddProductModal,
    handleOnProductCreated
  } = useQuickAddProductModal();

  const refetchPostOrders = useRefetchPostOrders();
  const refetchProducts = useRefetchProducts();
  const refetchPostStatistic = useRefetchPostStatistic();
  const refetchStoreOrderLimit = useRefetchStoreOrderLimit();

  useEffect(() => {
    const addNewProductToList = () => {
      handleOnProductSelected(newlyCreatedProductId);
      setNewlyCreatedProductId(null);
    };

    const isNewlyAddedProductInSuggestions = productSuggestions && !!productSuggestions.find(product => product.value === newlyCreatedProductId);
    if (isNewlyAddedProductInSuggestions) {
      addNewProductToList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newlyCreatedProductId, productSuggestions]);

  const handleOnProductSelected = suggestionValue => {
    const selectedSuggestedProduct = productSuggestions.find(product => product.value === suggestionValue);
    if (selectedSuggestedProduct) {
      const foundProductInAddedProducts = addedProducts.find(addedProduct => addedProduct._id === selectedSuggestedProduct._id);
      if (foundProductInAddedProducts && foundProductInAddedProducts.label) {
        message.error(t('modalOverviewCreateOrder:form-error-message-add-same-product'));

        form.setFieldsValue({
          orderProduct: ''
        });
      } else {
        setAddedProducts(currentProducts => {
          return [...currentProducts, { ...selectedSuggestedProduct, quantity: 1 }];
        });

        form.setFieldsValue({
          orderProduct: '',
          [`${selectedSuggestedProduct.value}-quantity`]: 1
        });
      }
    }

    setSearchKeyword('');
  };

  const handleOnProductDeleteClick = selectedProductId => {
    setAddedProducts(currentProducts => {
      const newProductsList = currentProducts.filter(addedProduct => addedProduct._id !== selectedProductId);
      const selectedProduct = currentProducts.find(addedProduct => addedProduct._id === selectedProductId);

      form.setFieldsValue({
        [`${selectedProduct.value}-quantity`]: 0
      });

      return newProductsList;
    });
  };

  const handleOnProductQtyChange = (qty, selectedProductId) => {
    setAddedProducts(currentProducts => {
      const currentIdx = currentProducts.findIndex(currentProduct => currentProduct._id === selectedProductId);
      currentProducts[currentIdx] = {
        ...currentProducts[currentIdx],
        quantity: qty
      };

      return [...currentProducts];
    });
  };

  const formatProductsPayload = products => {
    return products.map(product => ({
      keyword: product.orderKeyword,
      quantity: product.quantity
    }));
  };

  const onAfterCreateOrder = useCallback(
    newOrderId => {
      refetchPostOrders();
      refetchProducts();
      refetchPostStatistic();
      refetchStoreOrderLimit();
      onSaveSuccessful(newOrderId);
    },
    [onSaveSuccessful, refetchPostOrders, refetchPostStatistic, refetchProducts, refetchStoreOrderLimit]
  );

  const handleOnSaveClick = () => {
    if (addedProducts.length > 0) {
      setIsSaving(true);
      const formattedProductsPayload = formatProductsPayload(addedProducts);
      const orderPayload = {
        products: formattedProductsPayload,
        postId: comment.postId,
        commentId: comment.commentId
      };
      postCreateLiveOrder(orderPayload)
        .then(newOrder => {
          message.success(t('modalOverviewCreateOrder:success-message'));
          onAfterCreateOrder(newOrder._id);
        })
        .catch(ex => {
          createOrderErrorHandler(ex, t, onAfterCreateOrder);
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      message.error(t('modalOverviewCreateOrder:form-error-message-save-no-product-added'));
    }
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  return (
    <StyledModal
      visible={visible}
      cancelText={t('modalOverviewCreateOrder:cancel-button-text')}
      okText={t('modalOverviewCreateOrder:ok-button-text')}
      onCancel={onClose}
      onOk={handleOnSaveClick}
      confirmLoading={isSaving}
      cancelButtonProps={{ loading: isSaving }}
    >
      <Form form={form} scrollToFirstError={true} style={{ width: '100%' }} onFinishFailed={handleOnSaveFailed}>
        <h3>{t('modalOverviewCreateOrder:modal-title')}</h3>
        <InfoContainer>
          <CommentContainer>
            {comment.name} - <i>"{comment.message}"</i>
          </CommentContainer>
          {!!alertInfo && <Alert message={alertInfo.localizedMessage} type={alertInfo.alertType} showIcon />}
        </InfoContainer>
        <Card>
          <FormSelection
            label={
              <Row justify="space-between" align="middle">
                {t('modalOverviewCreateOrder:form-input-label-add-order-product')}
                <Button icon={<PlusOutlined />} type="link" onClick={onOpenQuickAddProductModal}>
                  {t('modalOverviewCreateOrder:modal-label-quick-add-order-product')}
                </Button>
              </Row>
            }
            name="orderProduct"
            filterOption={false}
            onSearch={delaySetSearchKeyword}
            selections={!!productSuggestions ? productSuggestions : []}
            isSearching={isProductSuggestionsLoading}
            onChange={handleOnProductSelected}
          />
          <Card>
            <List
              rowKey={record => record._id}
              dataSource={addedProducts}
              renderItem={addedProduct => (
                <List.Item
                  actions={[
                    <TextButton
                      icon={<DeleteOutlined />}
                      onClick={() => handleOnProductDeleteClick(addedProduct._id)}
                      text={t('modalOverviewCreateOrder:product-text-button-remove')}
                      tooltipMessage={t('common:text-button-tooltip-message', {
                        action: t('modalOverviewCreateOrder:product-tooltip-message-action-remove')
                      })}
                    />
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <>
                        {addedProduct.inventory < 1 && <ErrorText>Please restock this product to make order</ErrorText>}
                        {addedProduct.label}
                      </>
                    }
                    description={
                      <PriceDisplay
                        prefix={`${addedProduct.orderKeyword} | ${addedProduct.inventory > 0 ? addedProduct.quantity : 0} x `}
                        amount={addedProduct.price || 0}
                      />
                    }
                  />
                  <div style={{ width: '10%', marginBottom: '-24px' }}>
                    <FormInputNumber
                      name={`${addedProduct.value}-quantity`}
                      placeholder={t('modalOverviewCreateOrder:product-input-placeholder-product-quantity')}
                      requiredErrorMessage={t('modalOverviewCreateOrder:product-input-required-message-product-quantity')}
                      value={addedProduct.quantity}
                      onChange={value => handleOnProductQtyChange(value, addedProduct._id)}
                      minValue={1}
                      maxValue={addedProduct.inventory}
                      disabled={addedProduct.inventory < 1}
                    />
                  </div>
                </List.Item>
              )}
            />
          </Card>
        </Card>
        {showQuickAddProductModal && (
          <QuickAddProductModal visible={showQuickAddProductModal} onClose={onCloseQuickAddProductModal} onProductCreated={handleOnProductCreated} />
        )}
      </Form>
    </StyledModal>
  );
};

export default AddOrderModal;
