import { useState } from 'react';
import { Row, Col, Card, Button, Modal, Form, message, Skeleton, Tooltip, Alert } from 'antd';
import { CheckOutlined, DeleteOutlined, ExclamationCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { postCreateOperator, useRefetchOperators, useGetOperatorDetails, deleteOperator, patchOperator } from 'apis/store';
import { withAppContext } from 'contexts/AppContext/AppContext';

import BackButton from 'components/BackButton/BackButton';
import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import Title from 'components/Title/Title';
import FormInput from 'components/FormInput/FormInput';
import FormGroupSelectionInput from 'components/FormGroupSelectionInput/FormGroupSelectionInput';
import FormSelection from 'components/FormSelection/FormSelection';
import FormPasswordInput from 'components/FormPasswordInput/FormPasswordInput';

import { useFetchConstant } from 'hooks/constants';

import { getTeamRoute, getTeamDetailRoute } from 'utils/routes';
import { guard } from 'utils/general';

import { CardTitle, TitleIcon, TitleText, ContentText } from './TeamDetails.styles';

const { confirm, error } = Modal;

const SectionCard = ({ title, paddingBottom, bordered = false, children }) => (
  <Card title={<CardTitle>{title}</CardTitle>} bordered={bordered} bodyStyle={{ padding: 24, paddingBottom }}>
    {children}
  </Card>
);

const ConfirmUpdateModal = ({ t, visible, isLoading, onOk, onCancel }) => {
  return (
    <Modal
      visible={visible}
      confirmLoading={isLoading}
      okText={t('common:modal-ok-text')}
      cancelText={t('common:modal-cancel-text')}
      onOk={onOk()}
      onCancel={onCancel}
    >
      <div style={{ marginBottom: '12px' }}>
        <TitleIcon />
        <TitleText>{t('common:confirm-modal-update-title')}</TitleText>
        <ContentText>{t('common:confirm-modal-update-content')}</ContentText>
      </div>
    </Modal>
  );
};

const useConfirmUpdateModal = ({ t, userId, updatePayload, refetchOperatorDetails, setIsSubmitting }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const closeConfirmModal = () => {
    setIsVisible(false);
    setIsSubmitting(false);
  };

  const openConfirmModal = () => {
    setIsVisible(true);
    setIsSubmitting(true);
  };

  const handleOnConfirmUpdateModalOk = () => () => {
    setIsLoading(true);
    patchOperator(userId, { ...updatePayload })
      .then(() => {
        message.success(t('common:update-success-message'));
        refetchOperatorDetails();
      })
      .catch(ex => {
        error({
          title: ex.message
        });
      })
      .finally(() => {
        setIsLoading(false);
        closeConfirmModal();
      });
  };

  const handleOnConfirmUpdateModalCancel = () => {
    closeConfirmModal();
  };

  return {
    isConfirmUpdateModalVisible: isVisible,
    isConfirmUpdateModalLoading: isLoading,
    openConfirmModal,
    handleOnConfirmUpdateModalOk,
    handleOnConfirmUpdateModalCancel
  };
};

const AccountInfo = ({ t, isEditMode, formattedRoleSelections, isOwner }) => {
  return (
    <SectionCard title={t('pageTeamDetails:section-card-account-info-title')}>
      <Row gutter={[8, 0]}>
        <Col span={24} md={12}>
          <FormInput
            name="username"
            label={t('pageTeamDetails:section-card-account-info-username')}
            placeholder="e.g: user_1"
            requiredErrorMessage={t('pageTeamDetails:form-field-required-error-message-username')}
            disabled={isEditMode}
          />
        </Col>
        <Col span={24} md={12}>
          <FormSelection
            name="role"
            label={t('pageTeamDetails:section-card-account-info-role')}
            selections={formattedRoleSelections}
            requiredErrorMessage={t('pageTeamDetails:form-field-required-error-message-role')}
            placeholder="e.g: Live Streamer"
            isDisabled={isEditMode ? isOwner : undefined}
          />
        </Col>
        {(!isEditMode || !isOwner) && (
          <Col span={24}>
            {isEditMode && (
              <Alert
                type="warning"
                showIcon
                style={{ margin: '16px 0' }}
                message={<b>{t('pageTeamDetails:reset-password-alert-title')}</b>}
                description={<span>{t('pageTeamDetails:reset-password-alert-description')}</span>}
              />
            )}
            <FormPasswordInput
              name="password"
              label={
                <span>
                  {t('pageTeamDetails:section-card-account-info-new-password')}{' '}
                  <Tooltip title={t('pageTeamDetails:form-field-password-tips')}>
                    <InfoCircleFilled />
                  </Tooltip>
                </span>
              }
              placeholder={t('pageTeamDetails:form-field-password-placeholder')}
              requiredErrorMessage={isEditMode ? '' : t('pageTeamDetails:form-field-required-error-message-new-password')}
              checkPattern={true}
            />
            <FormPasswordInput
              type="confirm"
              name="confirmPassword"
              label={t('pageTeamDetails:section-card-account-info-confirm-password')}
              placeholder={t('pageTeamDetails:form-field-password-placeholder')}
              compareField="password"
              dependencyField="password"
              extraRules={[
                ({ getFieldValue }) => ({
                  validator: async (rule, value) => {
                    if (!value && (!isEditMode || !!getFieldValue('password'))) {
                      throw new Error(t('pageTeamDetails:form-field-required-error-message-confirm-password'));
                    }
                  }
                })
              ]}
            />
          </Col>
        )}
      </Row>
    </SectionCard>
  );
};

const BasicInfo = ({ t, countries }) => {
  return (
    <SectionCard title={t('pageTeamDetails:section-card-personal-info-title')}>
      <Row gutter={[8, 0]}>
        <Col span={24} md={12}>
          <FormInput
            name="name"
            label={t('pageTeamDetails:section-card-personal-info-name')}
            placeholder="e.g: John"
            requiredErrorMessage={t('pageTeamDetails:form-field-required-error-message-full-name')}
          />
        </Col>
        <Col span={24} md={12}>
          <FormGroupSelectionInput
            name="contact"
            label={t('pageTeamDetails:section-card-personal-info-contact')}
            inputName={['contact', 'contactNumber']}
            selectionName={['contact', 'countryCode']}
            inputPlaceholder="109876543"
            inputType="contact"
            selections={countries}
            requiredErrorMessage={t('pageTeamDetails:form-field-required-error-message-contact')}
          />
        </Col>
        <Col span={24} md={12}>
          <FormInput
            type="email"
            name="email"
            label={t('pageTeamDetails:section-card-personal-info-email')}
            placeholder="e.g: example@gmail.com"
            requiredErrorMessage={t('pageTeamDetails:form-field-required-error-message-email-address')}
          />
        </Col>
      </Row>
    </SectionCard>
  );
};

const TeamDetails = () => {
  const { t } = useTranslation(['common', 'pageAccountProfile', 'pageTeamDetaiils']);
  const { id: userId } = useParams();
  const history = useHistory();
  const { isLoading: isOperatorDetailsLoading, data: operator, refetch: refetchOperatorDetails } = useGetOperatorDetails(userId);
  const { isLoading: isRolesLoading, data: storeOperatorRoles, selection: roleSelections } = useFetchConstant('storeOperatorRoles');
  const { selection: countries } = useFetchConstant('countries', { valueKey: 'phoneCode', labelKey: 'phoneCode' });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updatePayload, setUpdatePayload] = useState({});

  const isEditMode = !!userId;
  const refetchOperators = useRefetchOperators();

  const isLoading = isOperatorDetailsLoading || isRolesLoading;

  const formattedRoleSelections = roleSelections.map(roleSelection => ({
    ...roleSelection,
    disabled: !roleSelection.isAssignable
  }));

  const {
    isConfirmUpdateModalVisible,
    isConfirmUpdateModalLoading,
    openConfirmModal,
    handleOnConfirmUpdateModalOk,
    handleOnConfirmUpdateModalCancel
  } = useConfirmUpdateModal({
    t,
    userId,
    updatePayload,
    refetchOperatorDetails,
    setIsSubmitting
  });

  const handleOnSave = payload => {
    if (isEditMode) {
      setUpdatePayload(payload);
      openConfirmModal();
    } else {
      setIsSubmitting(true);
      postCreateOperator(payload)
        .then(newOperator => {
          refetchOperators();
          history.push(getTeamDetailRoute(newOperator._id).path);
          setIsSubmitting(false);
        })
        .catch(ex => {
          setIsSubmitting(false);
          error({
            title: t('pageTeamDetails:error-modal-operator-created-message'),
            content: ex.message
          });
        });
    }
  };

  const handleOnDelete = () => {
    setIsSubmitting(true);
    confirm({
      title: t('common:confirm-modal-delete-title', { itemName: t('pageTeamDetails:item-name-lowercase') }),
      content: t('common:confirm-modal-delete-content'),
      icon: <ExclamationCircleOutlined />,
      okText: t('common:modal-ok-text'),
      cancelText: t('common:modal-cancel-text'),
      onOk() {
        deleteOperator(operator._id)
          .then(() => {
            message.success(t('common:delete-success-message', { itemName: t('pageTeamDetails:item-name') }));
            setIsSubmitting(false);
            history.push(getTeamRoute().path);
          })
          .catch(ex => {
            setIsSubmitting(false);
            error({
              title: ex.message
            });
          });
      },
      onCancel() {
        setIsSubmitting(false);
      }
    });
  };

  return (
    <FullWidthContainer>
      <>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <Form onFinish={handleOnSave} initialValues={operator}>
            <Row type="flex" align="middle" style={{ marginBottom: '16px' }}>
              <BackButton backLink={getTeamRoute().path} />
              <Title>{isEditMode ? operator.name : t('pageTeamDetails:page-title')}</Title>
            </Row>
            <Card>
              <BasicInfo t={t} countries={countries} />
              <AccountInfo
                t={t}
                isEditMode={isEditMode}
                formattedRoleSelections={formattedRoleSelections}
                isOwner={guard(() => operator.role === storeOperatorRoles.OWNER.code, false)}
              />
            </Card>
            <Row gutter={8} style={{ margin: '16px 0px' }}>
              <Col>
                <Button htmlType="submit" type="primary" icon={<CheckOutlined />} loading={isSubmitting}>
                  {isEditMode ? t('pageTeamDetails:form-submit-update-button-text') : t('pageTeamDetails:form-submit-create-button-text')}
                </Button>{' '}
                {isEditMode && (
                  <Button type="ghost" icon={<DeleteOutlined />} onClick={handleOnDelete} loading={isSubmitting}>
                    {t('pageTeamDetails:form-submit-delete-button-text')}
                  </Button>
                )}
              </Col>
            </Row>
            <ConfirmUpdateModal
              t={t}
              visible={isConfirmUpdateModalVisible}
              isLoading={isConfirmUpdateModalLoading}
              onOk={handleOnConfirmUpdateModalOk}
              onCancel={handleOnConfirmUpdateModalCancel}
            />
          </Form>
        )}
      </>
    </FullWidthContainer>
  );
};

export default withAppContext(TeamDetails);
