import { useEffect, useMemo, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, message, Modal, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { withAppContext } from 'contexts/AppContext/AppContext';
import FormCheckboxToggle from 'components/Checkbox/FormCheckboxToggle/FormCheckboxToggle';
import FormSelection from 'components/FormSelection/FormSelection';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

import { patchMicrositeSettings, useGetProductMicrositeSettings } from 'apis/product';
import { useGetStoreMicrositeSettings } from 'apis/store';

import { constructColumn } from 'utils/table/table';
import { guard } from 'utils/general';

const { useForm } = Form;
const { error } = Modal;

const PriceCol = ({ form, t, record, idx }) => {
  const [isInit, setIsInit] = useState(false);
  const [isCustomPrice, setIsCustomPrice] = useState(false);

  useEffect(() => {
    if (!isInit) {
      setIsCustomPrice(record.msIsCustomPrice);

      if (!record.msIsCustomPrice) {
        const { items: oldItems } = form.getFieldsValue();
        const newItems = [...oldItems];
        newItems[idx].msPrice = record.price;

        form.setFieldsValue({
          items: newItems
        });
      }

      setIsInit(true);
    }
  }, [isInit, form, idx, record]);

  const handleOnCustomPriceCheckboxChange = e => {
    const isChecked = e.target.checked;
    setIsCustomPrice(isChecked);

    if (!isChecked) {
      const { items: oldItems } = form.getFieldsValue();
      const newItems = [...oldItems];
      newItems[idx].msPrice = record.price;

      form.setFieldsValue({
        items: newItems
      });
    }
  };

  return (
    <Row gutter={16}>
      <Col>
        <FormInputNumber
          name={['items', idx, 'msPrice']}
          type="financial"
          requiredErrorMessage={isCustomPrice ? t('pageProductDetails:variance-cell-form-error-message-price', { itemName: 'msPrice' }) : undefined}
          placeholder="100.00"
          disabled={!isCustomPrice}
        />
      </Col>
      <Col>
        <FormCheckboxToggle
          name={['items', idx, 'msIsCustomPrice']}
          label={t('pageProductDetails:variance-table-checkbox-msIsCustomPrice')}
          onChange={handleOnCustomPriceCheckboxChange}
        />
      </Col>
    </Row>
  );
};

const constructColumns = ({ form, t }) => [
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-label'), 'label', { width: '30%' })
  },
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-sku'), 'sku', { width: '10%' })
  },
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-desc'), 'description', { width: '30%' })
  },
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-price'), 'msPrice', { width: '', isPrice: true }),
    render: (text, record, idx) => <PriceCol form={form} t={t} record={record} idx={idx} />
  }
];

const ItemsTable = ({ form, t, isLoading, items }) => {
  return (
    <Table
      loading={isLoading}
      rowKey={record => record._id}
      size="small"
      bordered
      dataSource={items}
      columns={constructColumns({ form, t })}
      locale={{
        triggerDesc: t('common:table-header-sort-trigger-desc'),
        triggerAsc: t('common:table-header-sort-trigger-asc'),
        cancelSort: t('common:table-header-sort-cancel-sort')
      }}
      pagination={false}
    />
  );
};

const constructPayload = (fieldValues, data, storeCategories) => {
  if (fieldValues.msIsEnabled) {
    const msCategories = fieldValues.msCategories.map(category => {
      const msCategoryToUpdate = storeCategories.find(cat => cat._id === category);

      return {
        storeMsCategoryId: msCategoryToUpdate._id,
        label: msCategoryToUpdate.label
      };
    });
    return {
      msIsEnabled: fieldValues.msIsEnabled,
      msCategories,
      items: fieldValues.items.map((item, idx) => {
        return {
          productId: data.items[idx].productId,
          varianceId: data.items[idx].varianceId,
          msPrice: item.msPrice,
          msIsCustomPrice: !!item.msIsCustomPrice
        };
      })
    };
  }
  return { ...fieldValues };
};

const constructCategorySelections = categories => {
  return categories.map(category => ({ label: category.label, value: category._id }));
};

const MicrositeSettings = ({ productId, refetchStore }) => {
  const { t } = useTranslation(['common', 'pageProductDetails']);
  const [form] = useForm();

  const [isMicrositeEnabled, setIsMicrositeEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isLoading: isMicrositeSettingsLoading, data: micrositeSettings } = useGetProductMicrositeSettings(productId);
  const { data: storeMicrositeSettings } = useGetStoreMicrositeSettings();

  const categorySelections = useMemo(() => (storeMicrositeSettings ? constructCategorySelections(storeMicrositeSettings.categories) : []), [
    storeMicrositeSettings
  ]);

  useEffect(() => {
    if (micrositeSettings && micrositeSettings.msIsEnabled) {
      form.setFieldsValue({ ...micrositeSettings, msCategories: guard(() => micrositeSettings.msCategories.map(cat => cat.storeMsCategoryId), []) });
      setIsMicrositeEnabled(micrositeSettings.msIsEnabled);
    }
  }, [form, micrositeSettings]);

  const handleOnSave = values => {
    setIsSubmitting(true);
    const payload = constructPayload(values, micrositeSettings, storeMicrositeSettings.categories);

    patchMicrositeSettings(productId, payload)
      .then(() => {
        message.success(t('common:update-success-message'));
        refetchStore();
      })
      .catch(ex => {
        error({
          title: ex.message
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  return (
    <Form
      form={form}
      scrollToFirstError={true}
      style={{ width: '100%' }}
      onFinish={handleOnSave}
      onFinishFailed={handleOnSaveFailed}
      initialValues={{ msCategories: [] }}
    >
      <Card>
        <FormCheckboxToggle
          name="msIsEnabled"
          label={t('pageProductDetails:microsite-checbox-text')}
          onChange={() => setIsMicrositeEnabled(!isMicrositeEnabled)}
        />
        {isMicrositeEnabled && (
          <>
            <FormSelection
              name="msCategories"
              label={t('pageProductDetails:microsite-form-input-label-category')}
              isMultiple={true}
              selections={categorySelections}
            />
            <ItemsTable form={form} t={t} isLoading={isMicrositeSettingsLoading} items={micrositeSettings.items} />
          </>
        )}
        <Row gutter={8} style={{ margin: '16px 0px' }}>
          <Col>
            <Button htmlType="submit" type="primary" icon={<CheckOutlined />} loading={isSubmitting}>
              {t('pageProductDetails:microsite-update-button-text')}
            </Button>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default withAppContext(MicrositeSettings);
