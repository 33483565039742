import { useState } from 'react';
import { Button, Col, Form, message, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { patchProductVariance } from 'apis/product';

import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

const { useForm } = Form;

export const useBulkUpdateModal = () => {
  const [isBulkUpdateModalVisible, setIsBulkUpdateModalVisible] = useState(false);

  const handleOnBulkUpdateBtnClick = () => {
    setIsBulkUpdateModalVisible(true);
  };

  return {
    isBulkUpdateModalVisible,
    setIsBulkUpdateModalVisible,
    handleOnBulkUpdateBtnClick
  };
};

const BulkUpdateModal = ({ productId, variances, isBulkUpdateModalVisible, setIsBulkUpdateModalVisible, handleOnSaveSuccess }) => {
  const { t } = useTranslation(['pageProductDetails']);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form] = useForm();

  const handleOnBulkUpdateModalSave = async () => {
    if (!isSubmitting) {
      const payload = await form.validateFields();
      setIsSubmitting(true);
      if (!payload.price && !payload.inventory && !payload.weight.amount) {
        setIsSubmitting(false);
        return message.error(t('pageProductDetails:variance-bulk-update-modal-error-message'));
      }

      await Promise.all(
        variances.map(async variance => {
          return patchProductVariance(productId, variance._id, payload)
            .then(() => {
              message.success({
                content: t('pageProductDetails:variance-update-success-message', { keyword: variance[`${variance._id}_keyword`] })
              });
            })
            .catch(ex => {
              message.error({
                content: t('pageProductDetails:variance-update-fail-message', { message: ex.message, keyword: variance[`${variance._id}_keyword`] })
              });
            });
        })
      ).then(() => {
        setIsSubmitting(false);
        setIsBulkUpdateModalVisible(false);
        handleOnSaveSuccess();
      });
    }
  };

  const handleOnBulkUpdateModalCancel = () => {
    setIsBulkUpdateModalVisible(false);
  };

  return (
    <Modal
      width="90%"
      style={{ maxWidth: '768px' }}
      title={t('pageProductDetails:variance-bulk-update-modal-title')}
      visible={isBulkUpdateModalVisible}
      onCancel={handleOnBulkUpdateModalCancel}
      footer={
        <Row justify="end" gutter={[8, 8]}>
          <Col>
            <Button type="ghost" onClick={handleOnBulkUpdateModalCancel}>
              {t('pageProductDetails:variance-bulk-update-modal-cancel-button')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" loading={isSubmitting} onClick={handleOnBulkUpdateModalSave}>
              {t('pageProductDetails:variance-bulk-update-modal-update-all-button')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Form form={form} scrollToFirstError={true} style={{ width: '100%' }}>
        <Row gutter={24}>
          <Col span={24} md={8}>
            <FormInputNumber
              label={t('pageProductDetails:variance-bulk-update-modal-label-price')}
              name="price"
              type="financial"
              placeholder={t('pageProductDetails:variance-bulk-update-modal-placeholder-price')}
            />
          </Col>
          <Col span={24} md={8}>
            <FormInputNumber
              label={t('pageProductDetails:variance-bulk-update-modal-label-inventory')}
              name="inventory"
              isAllowNegative={true}
              minValue={0}
              placeholder={t('pageProductDetails:variance-bulk-update-modal-placeholder-inventory')}
            />
          </Col>
          <Col span={24} md={8}>
            <FormInputNumber
              label={t('pageProductDetails:variance-bulk-update-modal-label-weight')}
              name={['weight', 'amount']}
              placeholder={t('pageProductDetails:variance-bulk-update-modal-placeholder-weight')}
              precision={2}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BulkUpdateModal;
