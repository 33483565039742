import PropTypes from 'prop-types';

import { StyledP } from './Paragraph.styles';

const TYPES_NARROW = 'narrow';
const TYPES_SMALL = 'small';
const TYPES_DEFAULT = 'default';

const Paragraph = ({ type, style, children }) => {
  return (
    <StyledP type={type} style={style}>
      {children}
    </StyledP>
  );
};

Paragraph.defaultProps = {
  type: TYPES_DEFAULT,
  children: undefined
};

Paragraph.propTypes = {
  type: PropTypes.oneOf([TYPES_NARROW, TYPES_SMALL, TYPES_DEFAULT]),
  children: PropTypes.node
};

export default Paragraph;
