import { useEffect, useRef } from 'react';
import avatar from 'animal-avatar-generator';

const Avatar = ({ name = 'ulive', size = 280, round = false, bgColor }) => {
  const userAvatar = useRef();
  useEffect(() => {
    if (userAvatar && userAvatar.current) {
      const GeneratedAvatar = avatar(name, {
        size,
        round,
        avatarColors: ['#FFBA00', '#FFCC55', '#FEF8E5', '#FF7212', '#FDD8BE', '#2C278A', '#2196f3', '#F1F5FC'],
        backgroundColors: bgColor ? [bgColor] : ['transparent']
      });
      userAvatar.current.innerHTML = GeneratedAvatar;
    }
  }, [bgColor, name, round, size]);
  return <div ref={userAvatar}></div>;
};

export default Avatar;
