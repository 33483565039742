import React, { useState } from 'react';

import * as xlsx from 'xlsx';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Space, Upload, message, Alert, Row, Tag } from 'antd';
import { DownloadOutlined, InboxOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { postCreateBulkProducts } from 'apis/product';
import { checkHasValue } from 'utils/general';

import CsvDownload from 'components/CsvDownload/CsvDownload';

const { Dragger } = Upload;

const getHeaderLabelByKey = key => {
  const headerConst = csvSampleHeaders.find(header => header.key === key);
  return headerConst.label;
};

const csvSampleHeaders = [
  { label: 'Name', key: 'label' },
  { label: 'Keyword', key: 'keyword' },
  { label: 'Main Product Keyword', key: 'mainProductKeyword' },
  { label: 'Price', key: 'price' },
  { label: 'Cost', key: 'cost' },
  { label: 'Inventory', key: 'inventory' },
  { label: 'Original Inventory', key: 'oriInventory' },
  { label: 'Weight', key: 'weight' },
  { label: 'SKU', key: 'sku' },
  { label: 'Description', key: 'description' },
  { label: 'Purchase Limit', key: 'purchaseLimit' },
  { label: 'Pre-Order Limit', key: 'maxPreOrderLimit' }
];

const csvSampleHeaderKeys = csvSampleHeaders.map(header => header.key);

const csvSampleProducts = async () => {
  return [
    {
      label: 'Product without variance',
      keyword: 'PROD',
      mainProductKeyword: '',
      price: '20',
      cost: '10',
      inventory: '100',
      weight: '30',
      sku: 'P123',
      description: 'This is an example of how a product without variance data looks like',
      purchaseLimit: '10',
      maxPreOrderLimit: '10',
      oriInventory: '100'
    },
    {
      label: 'Product with variance',
      keyword: 'PRV',
      mainProductKeyword: '',
      price: '',
      cost: '',
      inventory: '',
      weight: '',
      sku: '',
      description: 'Example of a main product with variance',
      purchaseLimit: '',
      maxPreOrderLimit: '-1 (for unlimited)',
      oriInventory: ''
    },
    {
      label: 'Product Variance 1',
      keyword: '1',
      mainProductKeyword: 'PRV',
      price: '30',
      cost: '10',
      inventory: '50',
      weight: '100',
      sku: 'PV1',
      description: 'Example of a variance data',
      purchaseLimit: '2',
      maxPreOrderLimit: '',
      oriInventory: '50'
    },
    {
      label: 'Product Variance 2',
      keyword: '2',
      mainProductKeyword: 'PRV',
      price: '35',
      cost: '15',
      inventory: '30',
      weight: '120',
      sku: 'PV2',
      description: 'Example of a variance data',
      purchaseLimit: '5',
      maxPreOrderLimit: '',
      oriInventory: '30'
    }
  ];
};

const requiredFields = ['label', 'price', 'inventory', 'weight'];

const formattedProductData = ({ t, product, isRequiredOnlyLabel, isVariance }) => {
  if (isRequiredOnlyLabel) {
    if (!product.label) {
      throw new Error(t('pageProduct:bulk-create-modal-required-field-error', { field: getHeaderLabelByKey('label'), keyword: product.keyword }));
    }
  } else {
    requiredFields.forEach(field => {
      if (!product[field]) {
        throw new Error(t('pageProduct:bulk-create-modal-required-field-error', { field: getHeaderLabelByKey(field), keyword: product.keyword }));
      }
    });
  }

  if (
    checkHasValue(product.purchaseLimit) &&
    product.purchaseLimit !== '' &&
    (isNaN(parseInt(product.purchaseLimit)) || product.purchaseLimit <= 0)
  ) {
    throw new Error(t('pageProduct:bulk-create-modal-purchase-limit-number-error', { keyword: product.keyword }));
  }

  if (product.price && isNaN(parseInt(product.price))) {
    throw new Error(t('pageProduct:bulk-create-modal-price-error', { keyword: product.keyword }));
  }

  if (product.cost && isNaN(parseInt(product.cost))) {
    throw new Error(t('pageProduct:bulk-create-modal-cost-error', { keyword: product.keyword }));
  }

  return {
    ...product,
    ...{
      label: product.label || '',
      price: product.price || '0',
      cost: product.cost || '',
      inventory: product.inventory || '0',
      weight: { amount: product.weight || '0' }
    },
    ...(isVariance && { msPrice: '0' })
  };
};

const BulkCreateModal = ({ onOk, onCancel }) => {
  const { t } = useTranslation(['pageProduct']);
  const [isLoadingSampleProducts, setIsLoadingSampleProducts] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedCsvProducts, setUploadedCsvProducts] = useState([]);

  const acceptedFiles = ['.xlsx', '.xls', '.csv'];
  const draggerProps = {
    maxCount: 1,
    accept: acceptedFiles.join(', '),
    beforeUpload: file => {
      const fileExtension = file.name.split('.').pop();

      if (acceptedFiles.includes(`.${fileExtension}`)) {
        const reader = new FileReader();
        reader.onload = e => {
          const excelData = e.target.result;
          const workbook = xlsx.read(excelData, { type: 'array', codepage: 65001 });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = xlsx.utils.sheet_to_json(worksheet, { header: csvSampleHeaderKeys, range: 1, defval: '', raw: false });

          setUploadedCsvProducts(jsonData);
        };
        reader.readAsArrayBuffer(file);
      }

      return false;
    }
  };

  const handleOnSave = () => {
    let formattedCsvProducts = [];
    setIsUploading(true);

    try {
      if (uploadedCsvProducts.length === 0) {
        throw new Error(t('pageProduct:bulk-create-modal-no-product-error'));
      }

      uploadedCsvProducts.forEach((csvProduct, index) => {
        if (!csvProduct.keyword) {
          throw new Error(t('pageProduct:bulk-create-modal-required-keyword-error', { index: index + 2 }));
        } else if (csvProduct.mainProductKeyword) {
          const mainProductIndex = formattedCsvProducts.findIndex(
            product => product.keyword.toUpperCase() === csvProduct.mainProductKeyword.toUpperCase()
          );
          if (mainProductIndex < 0) {
            throw new Error(t('pageProduct:bulk-create-modal-required-main-product-error', { mainProductKeyword: csvProduct.mainProductKeyword }));
          } else if (!formattedCsvProducts[mainProductIndex].variances) {
            formattedCsvProducts[mainProductIndex].variances = [];
          }

          const isVarianceKeywordExists = formattedCsvProducts[mainProductIndex].variances.find(
            variance => variance.keyword.toUpperCase() === csvProduct.keyword.toUpperCase()
          );
          if (isVarianceKeywordExists) {
            throw new Error(
              t('pageProduct:bulk-create-modal-duplicated-variance-keyword-error', {
                varianceKeyword: csvProduct.keyword,
                mainProductKeyword: csvProduct.mainProductKeyword
              })
            );
          }

          const formattedVariance = formattedProductData({ t, product: csvProduct, isRequiredOnlyLabel: false, isVariance: true });
          formattedCsvProducts[mainProductIndex].variances.push(formattedVariance);
        } else {
          const isProductKeywordExists = formattedCsvProducts.find(product => product.keyword.toUpperCase() === csvProduct.keyword.toUpperCase());
          if (isProductKeywordExists) {
            throw new Error(t('pageProduct:bulk-create-modal-duplicated-keyword-error', { keyword: csvProduct.keyword }));
          }

          const hasVariance = uploadedCsvProducts.find(product => product.mainProductKeyword.toUpperCase() === csvProduct.keyword.toUpperCase());
          const formattedProduct = formattedProductData({ t, product: csvProduct, isRequiredOnlyLabel: !!hasVariance, isVariance: false });
          formattedCsvProducts.push(formattedProduct);
        }
      });
    } catch (ex) {
      setIsUploading(false);
      return message.error(t(ex.message));
    }

    postCreateBulkProducts(formattedCsvProducts)
      .then(res => {
        onOk();
      })
      .catch(ex => {
        message.error({
          title: t('pageProduct:bulk-create-modal-create-error'),
          content: ex.message
        });
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return (
    <Modal
      visible
      width="60%"
      okText={t('common:modal-ok-text')}
      cancelText={t('common:modal-cancel-text')}
      onOk={handleOnSave}
      onCancel={onCancel}
      confirmLoading={isUploading}
      cancelButtonProps={{ loading: isUploading }}
      closable={!isUploading}
      maskClosable={!isUploading}
    >
      <CsvDownload
        asyncExportMethod={csvSampleProducts}
        onDataLoading={() => setIsLoadingSampleProducts(true)}
        onDataLoaded={() => setIsLoadingSampleProducts(false)}
        headers={csvSampleHeaders}
        filename="Sample Bulk Upload Product"
      >
        <Button loading={isLoadingSampleProducts} type="primary" icon={<DownloadOutlined />}>
          {t('pageProduct:bulk-create-modal-download-sample-button-text')}
        </Button>
      </CsvDownload>

      <Dragger {...draggerProps} style={{ marginTop: 20 }}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('pageProduct:bulk-create-modal-input-placeholder-upload')}</p>
      </Dragger>
      <Alert
        type="warning"
        style={{ marginTop: 20 }}
        message={
          <Space direction="vertical">
            <Row align="middle">
              <Tag color="#ffba00">
                <b>
                  <InfoCircleOutlined />
                </b>
              </Tag>
              <span>
                <b>{t('pageProduct:bulk-create-modal-note')}:</b>
              </span>
            </Row>
            <ul>
              <li>{t('pageProduct:bulk-create-modal-note-desc-1')}</li>
              <li>{t('pageProduct:bulk-create-modal-note-desc-2')}</li>
              <li>{t('pageProduct:bulk-create-modal-note-desc-3')}</li>
              <li>{t('pageProduct:bulk-create-modal-note-desc-4')}</li>
              <li>{t('pageProduct:bulk-create-modal-note-desc-5')}</li>
              <li>{t('pageProduct:bulk-create-modal-note-desc-6')}</li>
            </ul>
          </Space>
        }
      />
    </Modal>
  );
};

export default BulkCreateModal;
