import api from './apiHelper';
import { useCustomQuery, useRefetchQuery } from 'hooks/reactQuery';

export const useGetMyProfileDetails = () => {
  const getOwnProfileDetails = async () => {
    return api.get(`/user/me`);
  };

  return useCustomQuery('myProfile', [], getOwnProfileDetails);
};

export const patchMyProfileDetails = payload => {
  return api.patch('/user/me', payload);
};

export const patchMyStoreConfig = payload => {
  return api.patch('/user/me/store-config', payload);
};

export const patchMySecurityConfig = payload => {
  return api.patch('/user/me/security', payload);
};

export const useRefetchMyProfile = () => {
  const { refetch } = useRefetchQuery(['myProfile']);

  return refetch;
};
