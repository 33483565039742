import React from 'react';
import { Checkbox } from 'antd';

import FormItem from 'components/FormItem/FormItem';
import { Label } from './FormCheckboxToggle.styles';

const FormCheckboxToggle = ({ disabled, label, name, formItemStyle, ...props }) => {
  return (
    <FormItem name={name} valuePropName="checked" style={formItemStyle}>
      <Checkbox disabled={disabled} {...props}>
        {!!label && <Label>{label}</Label>}
      </Checkbox>
    </FormItem>
  );
};

export default FormCheckboxToggle;
