import moment from 'moment';
import { useMemo, useState } from 'react';
import { Button, Row, Skeleton, Space, Table, DatePicker } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useGetProductSoldDetails } from 'apis/reporting';
import { useFetchConstant } from 'hooks/constants';

import Title from 'components/Title/Title';
import CsvDownload from 'components/CsvDownload/CsvDownload';

import { formatToDateTimeWithPMAM } from 'utils/date';
import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';
import { getOrderDetailRoute } from 'utils/routes';
import { formatToDateTimeString, formatToMomentObject } from 'utils/date';

import { PRODUCT_SOLD_CSV_HEADERS } from 'utils/constants';

import { ContentContainer, RootModal } from './ProductSoldModal.styles';

const constructColumns = ({ t, orderStatusesConst }) => [
  {
    ...constructColumn(t('pageReporting:product-modal-header-name'), 'label', {
      hasSorter: true,
      width: '20%'
    })
  },
  {
    ...constructColumn(t('pageReporting:product-modal-header-keyword'), 'matchingKeyword', { hasSorter: true, width: '10%' })
  },
  {
    ...constructColumn(t('pageReporting:product-modal-header-purchased-date'), 'purchasedDate', {
      hasSorter: true,
      width: '20%'
    }),
    render: purchasedDate => <span>{formatToDateTimeWithPMAM(purchasedDate)}</span>
  },
  {
    ...constructColumn(t('pageReporting:product-modal-header-quantity-sold'), 'quantitySold', {
      hasSorter: true,
      isNumber: true,
      width: '10%'
    })
  },
  {
    ...constructColumn(t('pageReporting:product-modal-header-total-price'), 'totalPrice', {
      hasSorter: true,
      isNumber: true,
      isPrice: true,
      width: '10%'
    })
  },
  {
    ...constructColumn(t('pageReporting:product-modal-header-order-number'), 'orderNumber', {
      hasSorter: true,
      width: '15%'
    }),
    ...constructColumnFilterSearch('orderNumber', t('pageReporting:product-modal-header-action-search-order-number'), {}),
    render: (label, record) => {
      return record.status === orderStatusesConst.CANCELLED.oriLabel || record.status === orderStatusesConst.COMPLETED.oriLabel ? (
        <p style={{ marginBottom: 0 }}>
          {label}
          <br />
          <small>{record.status}</small>
        </p>
      ) : (
        <a style={{ marginBottom: 0 }} href={getOrderDetailRoute(record.orderId).path} target="_blank" rel="noopener noreferrer">
          {label}
          <br />
          <small>{record.status}</small>
        </a>
      );
    }
  },
  {
    ...constructColumn(t('pageReporting:product-modal-header-buyer-name'), 'buyerName', {
      hasSorter: true,
      width: '20%'
    }),
    ...constructColumnFilterSearch('buyerName', t('pageReporting:product-modal-header-action-search-buyer-name'), {})
  }
];

const ProductSoldModal = ({ query, onClose }) => {
  const { t } = useTranslation(['pageReporting']);
  const [dateTimeQuery, setDateTimeQuery] = useState(null);

  const { isLoading: isOrderStatusesLoading, data: orderStatusesConst } = useFetchConstant('orderStatuses');
  const { isLoading: isLoadingProductSold, data: productSoldDetails } = useGetProductSoldDetails({ ...query, ...dateTimeQuery });
  const startDateTime = useMemo(() => dateTimeQuery && formatToMomentObject(dateTimeQuery.startDateTime), [dateTimeQuery]);

  const isLoading = isOrderStatusesLoading || isLoadingProductSold;

  const handleOnChangeDateTime = dateTime => {
    dateTime ? setDateTimeQuery({ startDateTime: formatToDateTimeString(dateTime) }) : setDateTimeQuery(null);
  };

  const asyncExportMethod = async () => {
    return productSoldDetails.map(productSoldDetail => {
      return { ...productSoldDetail, purchasedDate: formatToDateTimeWithPMAM(productSoldDetail.purchasedDate) };
    });
  };

  return (
    <RootModal
      visible
      onCancel={onClose}
      bodyStyle={{ overflowX: 'auto' }}
      title={
        <Row justify="space-between" align="middle" style={{ marginRight: 24 }}>
          <Title>{t('pageReporting:product-modal-title')}</Title>
          <Space>
            <span>{t('pageReporting:product-modal-filter-date-title')}</span>
            <DatePicker
              showTime={{ format: 'HH:mm' }}
              value={startDateTime}
              onChange={handleOnChangeDateTime}
              disabledDate={current => current < moment(query.startDate).startOf('day') || current > moment(query.endDate).endOf('day')}
            />
            <CsvDownload
              asyncExportMethod={asyncExportMethod}
              headers={PRODUCT_SOLD_CSV_HEADERS}
              onDataLoading={() => {}}
              onDataLoaded={() => {}}
              filename={
                dateTimeQuery
                  ? `Product_Sold_${dateTimeQuery.startDateTime}-${formatToDateTimeString(moment(query.endDate).endOf('day'))}.csv`
                  : `Product_Sold_${query.startDate}-${query.endDate}.csv`
              }
            >
              <Button style={{ marginLeft: 10 }} type="primary" onClick={() => {}}>
                <DownloadOutlined />
              </Button>
            </CsvDownload>
          </Space>
        </Row>
      }
      footer={null}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <ContentContainer>
          <Table columns={constructColumns({ t, orderStatusesConst })} dataSource={productSoldDetails} />
        </ContentContainer>
      )}
    </RootModal>
  );
};

export default ProductSoldModal;
