import styled from '@emotion/styled';
import { Space } from 'antd';

export const FilterContainer = styled(Space)`
  width: 100%;
  margin-bottom: 8px !important;

  & > :first-child {
    flex-grow: 1;
  }
`;

export const StyledTextLink = styled.span`
  color: ${props => props.theme.color.lightTertiary};
  text-decoration: none;
  cursor: pointer;
  padding: 8px 4px;
`;
