import styled from '@emotion/styled';
import Img3 from './images/3.png';

import MImg3 from './images/3-mobile.png';

export const ThirdContentTitle = styled.p`
  font-size: 1.5em;
  line-height: 1.5em;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 0.5em;
  text-align: left;
  padding: 0 2px;

  @media (min-width: 390px) {
    font-size: 1.8em;
    line-height: 1.5em;
  }

  @media (min-width: 414px) {
    font-size: 1.6em;
  }

  @media (min-width: 768px) {
    font-size: 2.5em;
    margin-bottom: 0.5em;
    margin-left: 4vw;
    margin-top: 4vh;
  }

  @media (min-width: 820px) {
    font-size: 2.5em;
    margin-bottom: 0.5em;
    margin-left: 2vw;
  }

  @media (min-width: 1024px) {
    font-size: 2.5em;
    margin-bottom: 0.5em;
    margin-right: 16vw;
    margin-top: 0vh;
  }

  @media (min-width: 1200px) {
    margin-left: 0vw;
    margin-right: 36vw;
  }
`;

export const ThirdImg = styled.div`
  display: block;
  position: absolute;
  z-index: -1; // To prevent btn event being covered
  background-image: url(${MImg3});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  margin-left: -1vw;
  height: 40vh;
  width: 68vw;
  top: 24vh;

  @media (min-width: 414px) {
    margin-left: 0;
    height: 40vh;
    width: 66vw;
    top: 26vh;
  }

  @media (min-width: 768px) {
    margin-left: 12vw;
    height: 64vh;
    width: 60vw;
    top: 16vh;
  }

  @media (min-width: 820px) {
    margin-left: 0;
    height: 64vh;
    width: 60vw;
    top: 16vh;
  }

  @media (min-width: 1024px) {
    background-image: url(${Img3});
    margin-left: 14vw;
    height: 56vh;
    width: 52vw;
    top: 16vh;
  }

  @media (min-width: 1200px) {
    margin-left: 2vw;
    height: 72vh;
    width: 80vw;
    top: 4vh;
  }
`;
