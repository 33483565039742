import PropTypes from 'prop-types';

import Icon from './Icon';

import messengerIcon from './icons/messenger.png';

const MessengerIcon = ({ width, height, style, onClick, className }) => {
  return <Icon src={messengerIcon} name="messenger" width={width} height={height} style={style} onClick={onClick} className={className} />;
};

MessengerIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default MessengerIcon;
